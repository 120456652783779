"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
const _ = require('lodash');

//# from the default tinymce config
/*
const DEFAULT_ATTRIBUTES = ["id","class","style","title","dir","lang","xml::lang","onclick","ondblclick","onmousedown","onmouseup","onmouseover","onmousemove","onmouseout","onkeypress","onkeydown","onkeyup"];

const VALID_ELEMENTS = {
    allowedTags: [
        "strong","b","em","i","strike","u","p"
        ,"li","br"
        ,"sub","sup","blockquote"
        ,"tbody","thead","tfoot"
        ,"caption","div"
        ,"span","code","pre","address","h1","h2","h3","h4","h5","h6"
        ,"dd","dl","dt","cite","abbr","acronym"
        ,"bdo"
        ,"button"
        ,"dfn","fieldset"
        ,"legend","noscript"
        ,"kbd"
        ,"small"
        ,"samp"
        ,"tt","var","big"
    ]
    , allowedAttributes: {

        a: ["rel","rev","charset","hreflang","tabindex","accesskey","type","name","href","target","title","class","onfocus","onblur"]
        , ol:["type","compact"]
        , ul:["type","compact"]
        , img: ["longdesc","usemap","src","border","alt=","title","hspace","vspace","width","height","align"]

        , table: ["border=0","cellspacing","cellpadding","width","frame","rules","height","align","summary","bgcolor","background","bordercolor"]
        , tr: ["rowspan","width","height","align","valign","bgcolor","background","bordercolor"]
        , td: ["colspan","rowspan","width","height","align","valign","bgcolor","background","bordercolor","scope"]
        , th: ["colspan","rowspan","width","height","align","valign","scope"]
        , hr: ["size","noshade"]
        , font: ["face","size","color"]
        , del: ["datetime","cite"]
        , ins: ["datetime","cite"]
        , object: ["classid","width","height","codebase","*"]
        , param: ["name","value","_value"]
        , embed: ["type","width","height","src","*"]
        , script: ["src","type"]
        , map: ["name"]
        , area: ["shape","coords","href","alt","target"]
        , col: ["align","char","charoff","span","valign","width"]
        , colgroup: ["align","char","charoff","span","valign","width"]

        , form: ["action","accept","accept-charset","enctype","method"]
        , input: ["accept","alt","checked","disabled","maxlength","name","readonly","size","src","type","value"]
        , label: ["for"]
        , optgroup: ["label","disabled"]
        , option: ["disabled","label","selected","value"]
        , q: ["cite"]
        , select: ["disabled","multiple","name","size"]
        , textarea: ["cols","rows","disabled","name","readonly"]
        , iframe: ["align","class","frameborder","height","id","allow","allowFullScreen","longdesc","marginheight","marginwidth","name","scrolling","src","style","title","width"]
        , video: ["class","src","border=0","alt","title","hspace","vspace","width","height","align","onmouseover","onmouseout","name","poster","id","controls"]
        , audio: ["class","src","border=0","alt","title","hspace","vspace","width","height","align","onmouseover","onmouseout","name","poster","id","controls"]
        , source: ["class","src","alt","title","name","type","id"]
    }

}
*/

const DEFAULT_ATTRIBUTES = ["id", "class", "style", "title", "dir", "lang", "xml::lang"];
const allowedTags = ["strong", "b", "em", "i", "strike", "u", "p", "li", "br", "sub", "sup", "blockquote", "tbody", "thead", "tfoot", "caption", "div", "span", "code", "pre", "address", "h1", "h2", "h3", "h4", "h5", "h6", "dd", "dl", "dt", "cite", "abbr", "acronym", "bdo", "button", "dfn", "fieldset", "legend", "noscript", "kbd", "small", "samp", "tt", "var", "big"];
const allowedAttributes = {
  a: ["rel", "rev", "charset", "hreflang", "tabindex", "accesskey", "type", "name", "href", "target", "title", "class"],
  ol: ["type", "compact"],
  ul: ["type", "compact"],
  img: ["longdesc", "usemap", "src", "border", "alt=", "title", "hspace", "vspace", "width", "height", "align"],
  table: ["border=0", "cellspacing", "cellpadding", "width", "frame", "rules", "height", "align", "summary", "bgcolor", "background", "bordercolor"],
  tr: ["rowspan", "width", "height", "align", "valign", "bgcolor", "background", "bordercolor"],
  td: ["colspan", "rowspan", "width", "height", "align", "valign", "bgcolor", "background", "bordercolor", "scope"],
  th: ["colspan", "rowspan", "width", "height", "align", "valign", "scope"],
  hr: ["size", "noshade"],
  font: ["face", "size", "color"],
  del: ["datetime", "cite"],
  ins: ["datetime", "cite"],
  object: ["classid", "width", "height", "codebase", "*"],
  param: ["name", "value", "_value"],
  embed: ["type", "width", "height", "src", "*"],
  script: ["src", "type"],
  map: ["name"],
  area: ["shape", "coords", "href", "alt", "target"],
  col: ["align", "char", "charoff", "span", "valign", "width"],
  colgroup: ["align", "char", "charoff", "span", "valign", "width"],
  form: ["action", "accept", "accept-charset", "enctype", "method"],
  input: ["accept", "alt", "checked", "disabled", "maxlength", "name", "readonly", "size", "src", "type", "value"],
  label: ["for"],
  optgroup: ["label", "disabled"],
  option: ["disabled", "label", "selected", "value"],
  q: ["cite"],
  select: ["disabled", "multiple", "name", "size"],
  textarea: ["cols", "rows", "disabled", "name", "readonly"],
  iframe: ["align", "class", "frameborder", "height", "id", "allow", "allowFullScreen", "longdesc", "marginheight", "marginwidth", "name", "scrolling", "src", "style", "title", "width"],
  video: ["class", "src", "border=0", "alt", "title", "hspace", "vspace", "width", "height", "align", "name", "poster", "id", "controls"],
  audio: ["class", "src", "border=0", "alt", "title", "hspace", "vspace", "width", "height", "align", "name", "poster", "id", "controls"],
  source: ["class", "src", "alt", "title", "name", "type", "id"]
};

//# definition used for sanitize-html
const VALID_ELEMENTS_FACTORY = disallowedTags => {
  const filteredAllowedAttributes = _.omit(allowedAttributes, disallowedTags);
  const filteredAllowedTags = _.difference(allowedTags, disallowedTags);
  return {
    allowedTags: filteredAllowedTags.concat(Object.keys(filteredAllowedAttributes)),
    allowedAttributes: _objectSpread(_objectSpread({}, filteredAllowedTags.reduce((collector, tag) => {
      collector[tag] = DEFAULT_ATTRIBUTES;
      return collector;
    }, {})), Object.keys(filteredAllowedAttributes).reduce((collector, tag) => {
      collector[tag] = filteredAllowedAttributes[tag].concat(DEFAULT_ATTRIBUTES);
      return collector;
    }, {}))
  };
};
module.exports = {
  COMPONENT_NAME: 'rubyComponentFieldRichTextEditor',
  FORM_COMPONENT_NAME: 'RichTextEditor',
  META_SUFFIX: '__meta',
  VALID_ELEMENTS_FACTORY
};