"use strict";

var defaultState = {};

/* jshint -W074 */
module.exports = function (state, action) {
  //var actionTypes = this.getAction().TYPES;

  //# Iniitalize the state
  if (!state) {
    state = defaultState;
  }

  //var payload = _.get(action, 'payload');

  switch (action.type) {
    default:
      return state;
  }
};