"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
var _rubyMemoize = require("@rubyapps/ruby-memoize");
var _memoizee = _interopRequireDefault(require("memoizee"));
var _reduxBatchedActions = require("redux-batched-actions");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
const mixinUtils = require('@rubyapps/ruby-component-mixin-utils');
//# methods in mixin so we can override
module.exports = {
  hasUnsavedChanges: function () {
    const editPageMixin = require('@rubyapps/ruby-component-mixin-edit-page/src/client/index');
    const formValue = this.formValue({
      omitFieldPicker: this.omitInfoAndControlFieldsPicker
    });
    const editedFormData = formValue;
    const pristineFormData = this.getState().pristineFormData;
    const filteredPristineFormData = _lodash.default.pick(pristineFormData, Object.keys(editedFormData));

    /*
    const pristineFormData = _.pick(
        (this.getState().pristineFormData || {})
        , Object.keys(editedFormData)
    );
    */

    const flattenedEditedFormData = this.flattenedObject(editedFormData);
    const flattenedPristineFormData = this.flattenedObject(filteredPristineFormData);

    //# Custom isEqual customizer to treat empty string values as null so that the isEqual is compatible
    //# This is because certain string fields in the UI will default to empty string but it's a null
    const formDataIsDifferent = !_lodash.default.isEqual(flattenedPristineFormData, flattenedEditedFormData);

    //# get nearest loadingIndicator and use that as an indication of whether the form is still loading
    //# if the form is still loading, we want to report that it has no unsaved changes
    const parentEditPageComponent = this.findAncestorBy(node => mixinUtils.doesComponentHaveMixin(node, editPageMixin));
    const loadingIndicator = parentEditPageComponent ? parentEditPageComponent.getRubyComponentAtPath('> .rubyComponentLoadingIndicator') : undefined;
    const isLoading = loadingIndicator && loadingIndicator.getState().visible;
    return !isLoading && formDataIsDifferent;
  }
  //# used by the saveButton in formJS
  ,
  hasNoUnsavedChanges: function () {
    return !this.hasUnsavedChanges();
  }

  //# TODO: update drafts mixin to override this to check for user perms 
  ,
  canEdit: (0, _memoizee.default)(function () {
    //# TODO: ISSUE: the canEdit permission check is specific to the content model
    //# we *should* update this to work with other models
    if (this.props.key == 'content') {
      //# TODO: 20170301 - we'll have to check if user ruby_permissions array
      //#     has changed if we want to accomodate dynamically updating the 
      //#     form.canEdit value while the form is active
      //#     and the user's role is changing
      const currTimestamp = new Date();
      const canEditTimestamp = this._canEditTimestamp;
      //# just to be safe, we invalidate the cached canEdit value
      //# after a timeout
      if (canEditTimestamp && currTimestamp - canEditTimestamp < 5000) {
        this._canEditTimestamp = currTimestamp;
        return this._canEdit;
      }

      //# if form has id, check the 'edit' + 'id' permission
      //# if form doesn't have id, check the 'add' permission
      const parentEditPage = this.getParent();
      const pageId = _lodash.default.get(parentEditPage.getState(), 'routeParams.id');
      const permission = pageId ? {
        template: _lodash.default.get(this, 'props.hiddenValues.template_keyword'),
        ruby_client: true,
        subsite: true,
        model: this.props.key //# TODO: might want to change the <Form> component to store 'content' as model instead of key
        //# don't know what else is expecting key to be 'content'
        ,
        action: 'edit',
        id: pageId
        //: TODO: need to actually pass in id if available
      } : {
        template: _lodash.default.get(this, 'props.hiddenValues.template_keyword'),
        ruby_client: true,
        subsite: true,
        model: this.props.key,
        action: 'add'
      };
      const canEdit = this.doesUserHavePermission(permission);
      this._canEdit = canEdit;
      this._canEditTimestamp = currTimestamp;
      return canEdit;
    } else {
      return true;
    }
  }, _objectSpread(_objectSpread({}, _rubyMemoize.defaultMemoizeeOptions), {}, {
    maxAge: 1000,
    preFetch: false
  })),
  formValueToLocalState: function (formValue, dispatchOrCollect, entireFormValue, options) {
    let shouldDispatchOurselves = false;
    let actionCollector = [];
    if (!dispatchOrCollect) {
      if (options.batch) {
        const collectAction = value => {
          actionCollector.push(value);
        };
        dispatchOrCollect = collectAction;
        shouldDispatchOurselves = true;
      } else {
        dispatchOrCollect = this.getStore().dispatch;
      }
    }
    if (!entireFormValue) {
      //# entireFormValue is typically formValue, but still 
      //# allow users to override if they must provide some wholly different entireFormValue
      entireFormValue = formValue;
    }
    return this._formValueToLocalState(formValue, dispatchOrCollect, false, entireFormValue, options).then(res => {
      if (shouldDispatchOurselves) {
        this.getStore().dispatch((0, _reduxBatchedActions.batchActions)(actionCollector));
      }
      return res;
    });
  }
};