"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _dropdown = _interopRequireDefault(require("@rubyapps/ruby-styles/src/client/styleObjects/dropdown"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const styles = {
  SelectField_fullWidth: _extends({}, _dropdown.default, {
    style: {
      width: '100%'
    }
  }),
  SelectField_autoWidth: _extends({}, _dropdown.default, {
    style: {
      width: 'auto',
      minWidth: 100
    }
  })
};
var _default = styles;
exports.default = _default;