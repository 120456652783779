"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
var bunyanManager = require('../common/bunyanManager');
var configManager = require('../common/configManager');
var bunyanManagerInstance = Object.create(bunyanManager);
const queryString = require('query-string');
const query = location.search ? queryString.parse(location.search) : {};
const {
  logger: loggerQuery
} = query;
const VALID_LOG_LEVELS = ['error', 'warn', 'info', 'debug', 'trace'];
let loggerConfig_fromQuery;
if (loggerQuery) {
  if (loggerQuery.charAt(0) == '{') {
    try {
      loggerConfig_fromQuery = JSON.parse(loggerQuery);
    } catch (err) {
      //# ignore if error
    }
  } else if (VALID_LOG_LEVELS.includes(loggerQuery)) {
    loggerConfig_fromQuery = {
      level: loggerQuery
    };
  }
}
var config = _extends({}, configManager.getConfig_forEnv('client'), loggerConfig_fromQuery || {});
var rootLogger = bunyanManagerInstance.createLogger(config);
rootLogger._bunyanManager = bunyanManagerInstance;
rootLogger.getLogger = function (options) {
  var filteredOptions = configManager.prepareConfig_forEnv(options, 'client');
  return bunyanManagerInstance.getLogger(filteredOptions);
};
rootLogger.redirectConsole_toLogger = function (logger) {
  bunyanManagerInstance.redirectConsole_toLogger(logger);
};
if (typeof window != 'undefined') {
  window.rubyLogger = bunyanManagerInstance;
}

//# hacky workaround to prevent react from mapping warnings to console.error
//# node_modules/fbjs/lib/warning
const console__error = console.error;
console.error = function (message) {
  for (var _len = arguments.length, rest = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    rest[_key - 1] = arguments[_key];
  }
  if (typeof message == "string" && message.indexOf("Warning:") == 0) {
    return console.warn(message, ...rest);
  }
  console__error(message, ...rest);
};
module.exports = rootLogger;