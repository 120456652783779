"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initialState;
var _immutable = _interopRequireDefault(require("immutable"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function initialState() {
  let idCol = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'id';
  return _immutable.default.fromJS({
    currentPosition: {
      xScrollChangePosition: 0,
      yScrollChangePosition: 0,
      renderedStartDisplayIndex: 0,
      renderedEndDisplayIndex: 20,
      visibleDataLength: 20,
      tableHeight: 500,
      tableWidth: 300,
      rowHeight: 20,
      defaultColumnWidth: 80,
      infiniteScrollLoadTreshold: 50
    },
    renderedData: [],
    expandedRows: _immutable.default.Map(),
    idColumn: idCol
  });
}