"use strict";

var _reduxBatchedActions = require("redux-batched-actions");
var _reactRouter = require("react-router");
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const _ = require('lodash');
const BluebirdPromise = require('bluebird');
const ReactRouterRedux = require('react-router-redux');
const routerReducer = ReactRouterRedux.routerReducer;
const routerMiddleware = ReactRouterRedux.routerMiddleware;
const allRoutesReducer = require('./allRoutes/reducer');

//== STORE INITIALIZATION ========================================================//
//
var {
  applyMiddleware,
  combineReducers,
  compose,
  createStore
} = require('redux');
const thunkMiddleware = require('redux-thunk');
const LoggerMiddleware = require('./LoggerMiddleware').default;
const routerMiddlewareInstance = routerMiddleware(_reactRouter.browserHistory);
function initializeStore_usingConfig(config) {
  var self = this;
  this._rootComponent = config.rootComponent;
  let rootComponent = this._rootComponent;
  const {
    isDevMode
  } = config;
  let middlewares = [
  //LoggerMiddleware,
  thunkMiddleware, routerMiddlewareInstance].concat(rootComponent.getMiddlewares_flatMapped());
  const middlewareEnhancer = applyMiddleware.apply(null, middlewares);
  const shouldEnhanceStoreWithDevTools = isDevMode && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__;
  const maybeDevToolsEnhancer = shouldEnhanceStoreWithDevTools ? window.__REDUX_DEVTOOLS_EXTENSION__() : _.identity;
  const storeEnhancer = compose(middlewareEnhancer, maybeDevToolsEnhancer);
  const createStoreWithEnhancers = storeEnhancer(createStore);
  var rootComponentReducers = rootComponent.getReducerByKey();
  var allReducers = _extends({
    routing: routerReducer,
    routeParams: allRoutesReducer
  }, rootComponentReducers);
  this._store = createStoreWithEnhancers((0, _reduxBatchedActions.enableBatching)(combineReducers(allReducers)));
  rootComponent.setReduxManager(this);
  return new BluebirdPromise(function (resolve, reject) {
    resolve(self);
  });
}

//# http://stackoverflow.com/questions/32968016/how-to-dynamically-load-reducers-for-code-splitting-in-a-redux-application
function rebuildReducer() {
  const rootComponent = this._rootComponent;
  const store = this._store;
  const rootComponentReducers = rootComponent.getReducerByKey();
  const allReducers = _extends({
    routing: routerReducer,
    routeParams: allRoutesReducer
  }, rootComponentReducers);
  store.replaceReducer((0, _reduxBatchedActions.enableBatching)(combineReducers(allReducers)));
}
var rubyReduxManager = {
  _rootComponent: undefined,
  _store: undefined

  //== Initialization ==========================//
  ,
  init: initializeStore_usingConfig,
  rebuildReducer: rebuildReducer

  //== Accessors ===============================//
  ,
  getStore: function () {
    return this._store;
  }
};
module.exports = rubyReduxManager;