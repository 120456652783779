"use strict";

const requestTrackerMixin = {
  mixinName: 'rubyComponentMixinRequestTracker',
  action: require('./action'),
  reducer: require('./reducer'),
  getInitialState: () => ({
    isLoading: false,
    requestObject: null,
    response: null
  })
};
module.exports = requestTrackerMixin;