"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GRIDDLE_MOVE_ROW_WITH_ID_TO_CHILD_OF_ROW_WITH_ID = exports.GRIDDLE_MOVE_ROW_WITH_ID = exports.GRIDDLE_MOVE_ROW = exports.GRIDDLE_EXPAND_ROW = exports.GRIDDLE_COLLAPSE_ROW = void 0;
const GRIDDLE_COLLAPSE_ROW = 'GRIDDLE_COLLAPSE_ROW';
exports.GRIDDLE_COLLAPSE_ROW = GRIDDLE_COLLAPSE_ROW;
const GRIDDLE_EXPAND_ROW = 'GRIDDLE_EXPAND_ROW';
exports.GRIDDLE_EXPAND_ROW = GRIDDLE_EXPAND_ROW;
const GRIDDLE_MOVE_ROW = 'GRIDDLE_MOVE_ROW';
exports.GRIDDLE_MOVE_ROW = GRIDDLE_MOVE_ROW;
const GRIDDLE_MOVE_ROW_WITH_ID = 'GRIDDLE_MOVE_ROW_WITH_ID';
exports.GRIDDLE_MOVE_ROW_WITH_ID = GRIDDLE_MOVE_ROW_WITH_ID;
const GRIDDLE_MOVE_ROW_WITH_ID_TO_CHILD_OF_ROW_WITH_ID = 'GRIDDLE_MOVE_ROW_WITH_ID_TO_CHILD_OF_ROW_WITH_ID';
exports.GRIDDLE_MOVE_ROW_WITH_ID_TO_CHILD_OF_ROW_WITH_ID = GRIDDLE_MOVE_ROW_WITH_ID_TO_CHILD_OF_ROW_WITH_ID;