"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const request = require('@rubyapps/ruby-superagent');
const _ = require('lodash');
const url = require('url');
const urljoin = require('url-join');
const LIFTED_OPTIONS = ['id', 'templateKey', 'editorName', 'subsiteUrl', 'rubyClientUrl', 'title', 'showEditAction', 'pageName', 'selectedLanguage', 'delegateActions', 'additionalHeaderElements'];
const TYPES = {
  OPEN_CONTENT_COMPARE_MODAL: '@@ruby-app/content-compare/OPEN_CONTENT_COMPARE_MODAL',
  CLOSE_CONTENT_COMPARE_MODAL: '@@ruby-app/content-compare/CLOSE_CONTENT_COMPARE_MODAL',
  RESET_CONTENT_COMPARE_MODAL: '@@ruby-app/confirm-dialog/RESET_CONTENT_COMPARE_MODAL',
  SET_COMPARE_DATA: '@@ruby-app/content-compare/SET_COMPARE_DATA',
  SET_CONFIG: '@@ruby-app/content-compare/SET_CONFIG',
  SET_IS_LOADING: '@@ruby-app/content-compare/SET_IS_LOADING',
  CLEAR_IS_LOADING: '@@ruby-app/content-compare/CLEAR_IS_LOADING',
  CLEAR_URLS: '@@ruby-app/content-compare/CLEAR_URLS',
  SET_SELECTED_LANGUAGE: '@@ruby-app/content-compare/SET_SELECTED_LANGUAGE',
  SHOW_CONTENT_APPROVAL_ACTIONS: '@@ruby-app/content-compare/SHOW_CONTENT_APPROVAL_ACTIONS'
};
var generators = {
  /*
   * options = {
   *      formData:
   *      editUrl: <Object> || <string>
   *      version_data_id:
   *      id:
   *      templateKey:
   *      selectedLanguage:
   * }
   * */
  retrievePreviewData_thenOpen_usingOptions: function (options) {
    //# TODO
    const self = this;
    const actions = this.getAction().generators;
    const {
      id,
      templateKey,
      formData,
      version_data_id,
      selectedLanguage
    } = options;
    const {
      frontendSettingsComponent
    } = this.getDependencies();
    const frontendSettings = frontendSettingsComponent.getState();
    const {
      restApiRoot
    } = frontendSettings;
    const previewURL = urljoin(restApiRoot, 'previews');
    let previewData = {
      entity_id: id ? id : 0,
      templateKey
    };
    let pageName;
    if (formData) {
      previewData.raw_data = JSON.stringify(_.assign(id ? {
        id
      } : {}, formData));
      pageName = formData.name;
    } else if (version_data_id) {
      previewData.version_data_id = version_data_id;
    }
    return (dispatch, getState) => {
      let requestPromise;
      requestPromise = request.post(previewURL).send(previewData);
      dispatch(actions.setConfig(_extends({
        showContentApprovalActions: false,
        additionalHeaderElements: []
      }, _.pick(options, LIFTED_OPTIONS), {
        pageName: options.pageName || pageName,
        isComparingMode: false,
        options
      })));
      dispatch(actions.setIsLoading());
      dispatch(actions.open_contentCompare_modal());
      return requestPromise.then(function success(response) {
        const data = response.body;
        const preview_guid = data.preview_guid;
        const preview_version_data_id = data.version_rec.version_data_id;
        const previewPageURL = urljoin(window.location.protocol + '//' + window.location.host, '/content/secure-preview/page/', preview_guid) + '?version_data_id=' + preview_version_data_id + (selectedLanguage ? '&lang=' + selectedLanguage : '');
        dispatch(actions.clearIsLoading());
        dispatch({
          type: TYPES.SET_COMPARE_DATA,
          payload: {
            urls: {
              previewPage: previewPageURL
            }
          }
        });
      }, function error(err) {
        dispatch(actions.clearIsLoading());
        self.showErrorNotification({
          error: err
        });
      });
    };
  }

  //# TODO: move to draft plugin via a mixin and register against this component (similar to edit page)
  ,
  retrieveReviewData_thenOpen_usingOptions: function (options) {
    const selfState = this.getState();
    const self = this;
    const actions = this.getAction().generators;
    const {
      compareURL,
      compareURLData,
      labels
    } = options;
    const selectedLanguage = options.selectedLanguage ? options.selectedLanguage : selfState.selectedLanguage;
    const parentID = self.getID();
    const childNotification = self.findChildByID(`${parentID}.ruby_notifications`);
    const childNotificationActions = childNotification ? childNotification.getAction().generators : null;
    return (dispatch, getState) => {
      let requestPromise;
      let pageName;
      if (compareURLData) {
        //# 20170228 - probably deprecated. We're creating the version record with new formData before this gets called... actually this might get called for content review
        //requestPromise = request.post(`${compareURL}&lang=${selectedLanguage}`, compareURLData);
        requestPromise = request.post(compareURL).query({
          lang: selectedLanguage
        }).send(compareURLData);
        pageName = _.get(compareURLData, 'updatedData.name');
      } else {
        //requestPromise = request.get(`${compareURL}&lang=${selectedLanguage}`);
        requestPromise = request.post(compareURL).query({
          lang: selectedLanguage
        });
      }
      dispatch(actions.setConfig(_extends(_.pick(options, LIFTED_OPTIONS)
      //# TODO: remove delegateActions, editorName once we can get rid of 'delegateReject' etc
      , {
        pageName: options.pageName || pageName,
        isComparingMode: true,
        options
      })));
      dispatch(actions.setIsLoading());
      dispatch(actions.open_contentCompare_modal());
      return requestPromise.then(function success(response) {
        let urls = response.body.urls;
        let docs = response.body.docs;
        let message = response.body.message;
        dispatch(actions.clearIsLoading());
        dispatch({
          type: TYPES.SET_COMPARE_DATA,
          payload: {
            urls,
            docs,
            labels
          }
        });
        if (childNotificationActions && message) {
          dispatch(childNotificationActions.showNotification(message));
        }

        //# TODO: remove once we dont need the legacy content approval plugin (or we've migrated the functionality)
        if (options.showContentApprovalActions) {
          dispatch({
            type: TYPES.SHOW_CONTENT_APPROVAL_ACTIONS
          });
        }
        self.getState();
      }, function error(err) {
        dispatch(actions.clearIsLoading());
        self.showErrorNotification({
          error: err
        });
      });
    };
  }

  //# usually called only because of a language change
  ,
  refreshReviewData_forSelectedLanguage: function (selectedLanguage) {
    const selfState = this.getState();
    const self = this;
    const actions = this.getAction().generators;
    const {
      compareURL,
      compareURLData,
      labels
    } = selfState.options;
    return (dispatch, getState) => {
      dispatch(actions.setIsLoading());
      let requestPromise;
      let pageName;
      if (compareURLData) {
        //requestPromise = request.post(`${compareURL}&lang=${selectedLanguage}`, compareURLData);
        requestPromise = request.post(compareURL).query({
          lang: selectedLanguage
        }).send(compareURLData);
        pageName = _.get(compareURLData, 'updatedData.name');
      } else {
        //requestPromise = request.get(`${compareURL}&lang=${selectedLanguage}`);
        requestPromise = request.post(compareURL).query({
          lang: selectedLanguage
        });
      }
      return requestPromise.then(function success(response) {
        let urls = response.body.urls;
        let docs = response.body.docs;
        let message = response.body.message;
        dispatch(actions.clearIsLoading());
        dispatch({
          type: TYPES.SET_COMPARE_DATA,
          payload: {
            urls,
            docs,
            labels
          }
        });
      }, function error(err) {
        dispatch(actions.clearIsLoading());
        self.showErrorNotification({
          error: err
        });
      });
    };
  }

  /*
   * options = {
   *      formData:
   *      versionDataId:
   *      id:
   *      templateKey:
   *      baseEndpoint: //# the api endpoint for the item
   *      selectedLanguage:
   * }
   * */
  //# creates a version data id for the raw data
  ,
  reviewFormDataWithVersionDataId_usingOptions: function (options) {
    const self = this;
    const actions = this.getAction().generators;
    const {
      id,
      baseEndpoint,
      formData,
      selectedLanguage,
      templateKey,
      versionDataId
    } = options;
    const {
      frontendSettingsComponent
    } = this.getDependencies();
    const frontendSettings = frontendSettingsComponent.getState();
    const {
      restApiRoot
    } = frontendSettings;
    const previewURL = urljoin(restApiRoot, 'previews');
    let previewData = {
      entity_id: id ? id : 0,
      templateKey,
      raw_data: JSON.stringify(_.assign(id ? {
        id
      } : {}, formData))
    };
    if (!versionDataId) {
      // creating a page
      return actions.retrievePreviewData_thenOpen_usingOptions(options);
    }
    return (dispatch, getState) => {
      const pageName = formData.name;
      return request.post(previewURL).send(previewData).then(function success(response) {
        const data = response.body;
        const preview_guid = data.preview_guid;
        const preview_version_data_id = data.version_rec.version_data_id;
        const parsedBaseEndpoint = url.parse(baseEndpoint);
        let compareURL = urljoin(parsedBaseEndpoint.pathname, '/compare-versions', `?versionDataId=${preview_version_data_id}&basisVersionDataId=${versionDataId}`);
        dispatch(actions.retrieveReviewData_thenOpen_usingOptions({
          compareURL,
          formData //# include formData so we can access it in the connector
          //# this is used to determine which languages are active
          ,
          id: id,
          pageName,
          selectedLanguage,
          templateKey: templateKey
        }));
      }, function error(err) {
        self.showErrorNotification({
          error: err
        });
      });
    };
  },
  setConfig: function (options) {
    return {
      type: TYPES.SET_CONFIG,
      payload: options
    };
  },
  setIsLoading: function () {
    return {
      type: TYPES.SET_IS_LOADING
    };
  },
  clearIsLoading: function () {
    return {
      type: TYPES.CLEAR_IS_LOADING
    };
  },
  _setSelectedLanguage: function (selectedLanguage) {
    return {
      type: TYPES.SET_SELECTED_LANGUAGE,
      payload: {
        selectedLanguage
      }
    };
  },
  setSelectedLanguage: function (selectedLanguage) {
    const actions = this.getAction().generators;
    const selfState = this.getState();
    return dispatch => {
      dispatch(actions._setSelectedLanguage(selectedLanguage));
      if (selfState.isComparingMode) {
        dispatch(actions.refreshReviewData_forSelectedLanguage(selectedLanguage));
      }
    };
  },
  open_contentCompare_modal: function () {
    return {
      type: TYPES.OPEN_CONTENT_COMPARE_MODAL
    };
  },
  close_contentCompare_modal: function () {
    return {
      type: TYPES.CLOSE_CONTENT_COMPARE_MODAL
    };
  },
  reset: function () {
    return {
      type: TYPES.RESET_CONTENT_COMPARE_MODAL
    };
  },
  closeAndReset: function () {
    const actions = this.getAction().generators;
    return dispatch => {
      dispatch(actions.close_contentCompare_modal());
      setTimeout(() => {
        dispatch(actions.reset());
      }, 500);
    };
  },
  clearUrls: function () {
    return {
      type: TYPES.CLEAR_URLS
    };
  }
};
module.exports = {
  TYPES: TYPES,
  generators: generators
};