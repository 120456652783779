"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
var _reduxBatchedActions = require("redux-batched-actions");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const uuid = require('node-uuid');
function newID() {
  return uuid.v1();
}
module.exports = function () {
  const id = this.getID();
  const TYPES = {
    APPEND_ITEM_OF_TYPE: `@ruby-app/${id}/APPEND_ITEM_OF_TYPE`,
    APPEND_ITEM_OF_TYPE_IGNORED: `@ruby-app/${id}/APPEND_ITEM_OF_TYPE_IGNORED`,
    DELETE_ITEM_WITH_ID: `@ruby-app/${id}/DELETE_ITEM_WITH_ID`,
    MOVE_ITEM: `@ruby-app/${id}/MOVE_ITEM`,
    UPDATE_ITEM_ORDER: `@ruby-app/${id}/UPDATE_ITEM_ORDER`,
    REPLACE_STATE: `@ruby-app/${id}/REPLACE_STATE`

    //# Options handling
    ,
    ADD_OPTIONS: `@ruby-app/${id}/ADD_OPTIONS`

    //# Overridden action types
    ,
    RESET_STORE: `@ruby-app/${id}/RESET_STORE`

    //, SET_FIELD_ERROR_MESSAGE_BY_KEY: `@ruby-app/${id}/SET_FIELD_ERROR_MESSAGE_BY_KEY`
  };

  return {
    TYPES,
    generators: {
      appendItemOfType: function (type) {
        const actions = this.getAction().generators;
        const {
          childrenOrderById,
          error
        } = this.getState();
        const hasParentScopedError = !_lodash.default.isNil(_lodash.default.get(error, 'message'));
        const batchActionsArray = [];
        if (this.props.limit && childrenOrderById.length >= this.props.limit) {
          return {
            type: TYPES.APPEND_ITEM_OF_TYPE_IGNORED
          };
        } else {
          return dispatch => {
            batchActionsArray.push(actions._appendItemOfType__pure(type));
            dispatch((0, _reduxBatchedActions.batchActions)(batchActionsArray));
            this.updateChildren(true, dispatch);
            dispatch(actions.updateUserModifiedTimestamp());
            /* 
            //# 20171130 - enable this if we want to automatically pop open
            //# the module dialog
            const newlyAppendedChild = _.last(this.getChildren());
            const newlyAppendedChild_actions = newlyAppendedChild.getAction().generators;
            if (newlyAppendedChild_actions.onNewlyAdded) {
                dispatch(newlyAddedChild_actions.onNewlyAdded());
            }
            */

            const selfErrorMessage = this.revalidateSelfValueAndReflectError();
            this.refreshParentErrors && this.refreshParentErrors();
          };
        }
      },
      _appendItemOfType__pure: function (type) {
        const actions = this.getAction().generators;
        const {
          childrenOrderById
        } = this.getState();
        if (this.props.limit && childrenOrderById.length >= this.props.limit) {
          return {
            type: TYPES.APPEND_ITEM_OF_TYPE_IGNORED
          };
        } else {
          const newChildId = newID();
          return {
            type: TYPES.APPEND_ITEM_OF_TYPE,
            payload: {
              type,
              id: newChildId
            }
          };
        }
      }

      //# NOTE: currently not being used. Can we deprecate?
      ,
      appendItemOfType_andId: function (type, newId) {
        const actions = this.getAction().generators;
        const {
          childrenOrderById,
          error
        } = this.getState();
        const hasParentScopedError = !_lodash.default.isNil(_lodash.default.get(error, 'message'));
        const batchActionsArray = [];
        return dispatch => {
          batchActionsArray.push(actions._appendItemOfType_andId__pure(type, newId));
          dispatch((0, _reduxBatchedActions.batchActions)(batchActionsArray));
          this.updateChildren(true, dispatch);
          dispatch(actions.updateUserModifiedTimestamp());
          const selfErrorMessage = this.revalidateSelfValueAndReflectError();
          this.refreshParentErrors && this.refreshParentErrors();
        };
      },
      _appendItemOfType_andId__pure: function (type, newId) {
        const actions = this.getAction().generators;
        const {
          childrenOrderById
        } = this.getState();
        if (this.props.limit && childrenOrderById.length >= this.props.limit) {
          return {
            type: TYPES.APPEND_ITEM_OF_TYPE_IGNORED
          };
        } else {
          const newChildId = newId;
          return {
            type: TYPES.APPEND_ITEM_OF_TYPE,
            payload: {
              type,
              id: newChildId
            }
          };
        }
      },
      deleteItemWithId: function (id) {
        const actions = this.getAction().generators;
        return dispatch => {
          dispatch(actions._deleteItemWithId__pure(id));
          this.updateChildren();
          dispatch(actions.updateUserModifiedTimestamp());
          const selfError = this.revalidateSelfValueAndReflectError();
          this.refreshParentErrors && this.refreshParentErrors();
        };
      },
      _deleteItemWithId__pure: function (id) {
        const actions = this.getAction().generators;
        return {
          type: TYPES.DELETE_ITEM_WITH_ID,
          payload: {
            id
          }
        };
      },
      updateItemsWithListOfModules: function (moduleList) {
        //# some of the items might be selected, others won't be
        //# need to get a list of modules already created and see if we need to amend it
        const selfState = this.getState();
        const selfAction = this.getAction();
        const {
          childrenOrderById,
          childrenIdToTypeMap
        } = selfState;
        const actions = this.getAction().generators;
        const existingModuleIdsByType = childrenOrderById.reduce((collector, childId) => {
          const typeForChild = childrenIdToTypeMap[childId];
          if (collector.typeForChild) {
            collector[typeForChild].push(childId);
          } else {
            collector[typeForChild] = [childId];
          }
          return collector;
        }, {});
        const moduleListOrderWithId = moduleList.map(module => {
          const foundId = _idForModuleType(module.type) || newID();
          return _extends({
            id: foundId
          }, module);
        });
        const moduleListByType = moduleListOrderWithId.reduce((collector, module) => {
          collector[module.type] = module;
          return collector;
        }, {});
        const modulesToAddByType = _lodash.default.omit(moduleListByType, Object.keys(existingModuleIdsByType));
        const idsToDeleteByType = _lodash.default.omit(existingModuleIdsByType, Object.keys(moduleListByType));
        const batchActionsArray = [selfAction.generators.addOptions(moduleList)];
        _lodash.default.each(modulesToAddByType, (moduleToAdd, moduleType) => {
          const newChildId = moduleToAdd.id;
          const typeToAdd = moduleToAdd.type;
          batchActionsArray.push(selfAction.generators._appendItemOfType_andId__pure(typeToAdd, newChildId));
        });
        _lodash.default.forEach(idsToDeleteByType, idsToDeleteArr => {
          idsToDeleteArr.forEach(idToDelete => {
            batchActionsArray.push(selfAction.generators._deleteItemWithId__pure(idToDelete));
          });
        });

        //# update item order
        const newChildrenOrderById = moduleListOrderWithId.map(module => module.id);
        batchActionsArray.push(selfAction.generators.updateItemOrder(newChildrenOrderById));
        return dispatch => {
          dispatch((0, _reduxBatchedActions.batchActions)(batchActionsArray));
          this.updateChildren();
          dispatch(actions.updateUserModifiedTimestamp());
          dispatch(selfAction.generators.requestRerender());
          const hasAdditionsOrDeletions = Object.keys(modulesToAddByType).length + Object.keys(idsToDeleteByType).length;
          if (hasAdditionsOrDeletions) {
            const selfErrorMessage = this.revalidateSelfValueAndReflectError();
            this.refreshParentErrors && this.refreshParentErrors();
          }
        };
        function _idForModuleType(needleModuleType) {
          return _lodash.default.reduce(childrenIdToTypeMap, (foundId, haystackModuleType, moduleId) => {
            if (foundId) {
              return foundId;
            }
            if (haystackModuleType == needleModuleType) {
              return moduleId;
            }
            return foundId;
          }, undefined);
        }
      }
      //# DEPRECATED: we can just update the order wholesale
      ,
      moveItem_withId_toBetweenIDs: function (id, betweenIDs) {
        return {
          type: TYPES.MOVE_ITEM,
          payload: {
            id,
            betweenIDs
          }
        };
      },
      updateItemOrder: function (itemOrder) {
        const actions = this.getAction().generators;
        return (0, _reduxBatchedActions.batchActions)([actions._updateItemOrder__pure(itemOrder), actions.updateUserModifiedTimestamp()]);
      },
      _updateItemOrder__pure: function (itemOrder) {
        return {
          type: TYPES.UPDATE_ITEM_ORDER,
          payload: {
            itemOrder
          }
        };
      },
      replaceState: function (withState) {
        const actions = this.getAction().generators;
        return {
          type: TYPES.REPLACE_STATE,
          payload: withState
        };
      },
      addOptions: function (options) {
        return {
          type: TYPES.ADD_OPTIONS,
          payload: {
            options
          }
        };
      }
      //# Overridden field actions
      ,
      resetStore: function () {
        return {
          type: TYPES.RESET_STORE
        };
      }
      /* 20170517 - DEPRECATED - howard
      , setFieldErrorMessageByKey: function(message, key) {
          return {
              type: TYPES.SET_FIELD_ERROR_MESSAGE_BY_KEY
              , payload: {
                  error: {
                      message
                  }
                  , key
              }
          };
      }
      , setFieldErrorObjectByKey: function(error, key) {
          return {
              type: TYPES.SET_FIELD_ERROR_MESSAGE_BY_KEY
              , payload: {
                  error
                  , key
              }
          };
      }
      */
    }
  };
};