"use strict";

const PROFILERS = {
  memoizee: require('memoizee/profile')
};
if (typeof Window != 'undefined') {
  Window.PROFILERS = PROFILERS;
}
if (typeof global != 'undefined') {
  global.PROFILERS = PROFILERS;
}