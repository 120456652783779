"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const {
  filterTagsToWhereFilterUsingTemplateKeyword
} = require('../../common/utils/filterTagsToWhereFilter');

/*
 *  filterTags_byDataPath_byMatch = {
 *      "dataPath": {
 *          "is": [
 *              {... filter tag ...}
 *              , {... filter tag ...}
 *          ]
 *      }
 *  }
 */
function savedSearchToWhereFilter(savedSearch, templateKey) {
  const filterTags = _lodash.default.get(savedSearch, 'filterTags', []);
  return filterTagsToWhereFilterUsingTemplateKeyword(filterTags, templateKey);
}
var _default = savedSearchToWhereFilter;
exports.default = _default;