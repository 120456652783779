"use strict";

function typesWithID(id) {
  return {
    SET_PASSWORD_DISPLAY: `@@ruby-app/${id}/SET_PASSWORD_DISPLAY`
  };
}
const generators = {
  showPassword: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SET_PASSWORD_DISPLAY,
      payload: {
        showPassword: true
      }
    };
  },
  hidePassword: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SET_PASSWORD_DISPLAY,
      payload: {
        showPassword: false
      }
    };
  },
  togglePasswordDisplay: function () {
    const {
      generators
    } = this.getAction();
    const {
      selfSelector
    } = this.getDependencies();
    return (dispatch, getState) => {
      const selfState = selfSelector(getState());
      if (selfState.showPassword) dispatch(generators.hidePassword());else dispatch(generators.showPassword());
    };
  }
};
module.exports = function () {
  return {
    TYPES: typesWithID(this.getID()),
    generators
  };
};