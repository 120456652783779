"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const {
  DIALOG_FORM_TEMPLATE_TYPE,
  DIALOG_FORM_TEMPLATE_KEY
} = require('../constants');
var _default = {
  "templateType": DIALOG_FORM_TEMPLATE_TYPE,
  "key": DIALOG_FORM_TEMPLATE_KEY,
  "name": DIALOG_FORM_TEMPLATE_KEY,
  "form": {
    "componentName": "Form",
    "name": " Dialog",
    "key": "contentReportDialogForm"
    //, "modelType": "content"
    ,
    "enabledFormDiff": false,
    "children": [{
      "componentName": "DynamicForm",
      "options": [{
        "value": [{
          "componentName": "Url",
          "label": "Link URL",
          "key": "href"
        }, {
          "componentName": "Text",
          "label": "Link Title (Hover Text)",
          "key": "title"
        }],
        "query": {
          "where": {
            "and": [{
              "/referenced_content_id": null
            }]
          }
        }
      }, {
        "value": [
        //# NOTE: there's a bug with AutopopulatedText
        //# where it'll cause a max callstack issue
        //# So for now, we use Text
        {
          "label": "",
          "componentName": "ExpandedData",
          "key": "referenced_content",
          "mode": "edit",
          "returnRaw": true,
          "url": "/ruby/api/v2/content/:referenced_content_id?url=true",
          "children": [{
            "componentName": "Url",
            "label": "Link Url",
            "key": "url"
          }, {
            "componentName": "Text",
            "label": "Link Title (Hover Text)",
            "key": "name"
          }]
        }],
        "query": {
          "where": {
            "and": [{
              "/referenced_content_id": {
                neq: null
              }
            }]
          }
        }
      }]
    }, {
      "componentName": "Toggle",
      "label": "Open this link in a new window",
      "key": "new_window"
    }, {
      "componentName": "Dropdown",
      "label": "Search site content",
      "optionToHTML": "${data.text} (Template: ${data.templateName})",
      "key": "referenced_content_id",
      "data_type": "number",
      "url": "/ruby/api/v2/content/options?ruby_client_fk=3&content_subsite_fk=1&fields=template_keyword&fields=url&templateTypes=sitemap_template&templateTypes=structured_data_template"
    }]
  }
};
exports.default = _default;