"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
var _serverTransforms = require("./serverTransforms");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
const Validator = require('@rubyapps/ruby-validator-wrapper');
const rubyWords = require('@rubyapps/ruby-words');
const TEMPLATE_CONSTANTS = require('@rubyapps/ruby-component-plugin-template-editor/src/common/constants');
const fieldTypesThatDontAutopopulate = {
  Repeater: true
};
const JSON_INDENTATION_SPACES = 4;
const customValidationsByKey = {
  'unique': function (_ref) {
    let {
      value,
      key,
      instances,
      stateParams: shouldBeUnique,
      currInstance,
      selfModule
    } = _ref;
    if (shouldBeUnique) {
      const currState = selfModule.getState();
      const copiedFields = _lodash.default.cloneDeep(currState.fields);
      const copiedCurrInstanceProps = _lodash.default.get(copiedFields, ['children', 'value', 'instances', currInstance.id, 'instanceProps']);
      copiedCurrInstanceProps[key] = _objectSpread(_objectSpread({}, copiedCurrInstanceProps[key]), {}, {
        value
      });
      const updatedState = _objectSpread(_objectSpread({}, currState), {}, {
        fields: copiedFields
      });
      const updatedForm = selfModule.formValueFromLocalState(updatedState);
      let hasDuplicateKey = false;

      // perform post-order traverse to check if duplicate keys exists
      // returned value is an array of children's keys from the current node
      function formDFS(node) {
        // leaf node
        if (!node.children) {
          return node[key] ? [node[key]] : [];
        }

        // collect all the children's keys from the current node
        // if there are duplicates, it means it's not unique
        const childrenKeys = [];
        for (const child of node.children) {
          const childKeys = formDFS(child);
          childrenKeys.push(...childKeys);
        }
        if (_lodash.default.uniq(childrenKeys).length !== childrenKeys.length) {
          hasDuplicateKey = true;
        }
        // console.log(`at node, componentNane: ${node.componentName}, hasDuplicateKey ${hasDuplicateKey}, childrenKeys`, childrenKeys);

        // if the node has key, return it. otherwise bubble up the keys from children
        return node[key] ? [node[key]] : childrenKeys;
      }
      formDFS(updatedForm);
      return !hasDuplicateKey;
    }
    return true;
  },
  'api_id_alphanumeric': function (_ref2) {
    let {
      value,
      key,
      instances,
      stateParams: shouldBeAlphanumeric,
      selfModule
    } = _ref2;
    if (shouldBeAlphanumeric) {
      return /^[a-zA-Z0-9_]*$/.test(value);
    } else {
      return true;
    }
  },
  'profileRepeater_templateType': function (_ref3) {
    let {
      value,
      key,
      instances,
      stateParams: shouldCheckTemplateType,
      selfModule
    } = _ref3;
    const templateTypeComponent = selfModule.findAncestorBy(el => el.componentName === 'rubyComponentFieldForm').findDescendentBy(el => el.props.key === 'templateType');
    const {
      templateType
    } = templateTypeComponent.formValue() || {};
    if (shouldCheckTemplateType && value) {
      return _lodash.default.includes(TEMPLATE_CONSTANTS.TOP_LEVEL_TEMPLATE_TYPES, templateType);
    } else {
      return true;
    }
  },
  'contentEndpoint_requiresTemplateSelections': function (_ref4) {
    let {
      value
    } = _ref4;
    if (/^\/ruby\/api\/v2\/content\/options\?ruby_client_fk=\d+&content_subsite_fk=\d+$/.test(value)) {
      return false;
    } else {
      return true;
    }
  }
};
const customValidationErrorsByKey = {
  'unique': function (params) {
    return `${params.label} must be unique.`;
  },
  'api_id_alphanumeric': function (params) {
    return `${params.label} must contain only these characters: A-Z a-z 0-9 _ -`;
  },
  'profileRepeater_templateType': function (_ref5) {
    let {
      selfModule
    } = _ref5;
    const templateTypeComponent = selfModule.findAncestorBy(el => el.componentName === 'rubyComponentFieldForm').findDescendentBy(el => el.props.key === 'templateType');
    const {
      templateType
    } = templateTypeComponent.formValue() || {};
    const templateTypeName = TEMPLATE_CONSTANTS.TEMPLATE_TYPE_OPTIONS.find(option => option.value === templateType).text;
    return `A ${templateTypeName} may not use Profile Modules.`;
  },
  'contentEndpoint_requiresTemplateSelections': function () {
    return 'At least one template selection is required.';
  }
};
function _customValidateField_forConstraintKey(fieldKey, fieldValue, constraintKey, constraintsObj, instances, selfModule, currInstance) {
  const stateParams = constraintsObj.params || constraintsObj;
  const constraintParams = {
    value: fieldValue,
    key: fieldKey,
    instances,
    stateParams,
    selfModule,
    currInstance
  };
  const properties_byKey = selfModule.propertiesWithoutPermissions_byKey();
  var isOK = customValidationsByKey[constraintKey].call(null, constraintParams);
  if (!isOK) {
    const errorMessage = customValidationErrorsByKey[constraintKey]({
      label: properties_byKey[fieldKey].label,
      selfModule
    });
    return {
      message: errorMessage
    };
  }
}
function _validateField_withConstraints(fieldKey, fieldValue) {
  let constraints = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  let instances = arguments.length > 3 ? arguments[3] : undefined;
  let selfModule = arguments.length > 4 ? arguments[4] : undefined;
  let currInstance = arguments.length > 5 ? arguments[5] : undefined;
  var ruleKeys = Object.keys(constraints);
  const properties_byKey = selfModule.propertiesWithoutPermissions_byKey();
  const errorObjects = ruleKeys.reduce(function (collector, ruleKey) {
    if (constraints[ruleKey] === null) {
      return collector;
    }
    // Check for validator function
    else if (customValidationsByKey.hasOwnProperty(ruleKey)) {
      const customErrorObj = _customValidateField_forConstraintKey(fieldKey, fieldValue, ruleKey, constraints[ruleKey], instances, selfModule, currInstance);
      if (customErrorObj) {
        collector.push(customErrorObj);
      }
      return collector;
    } else if (!Validator[ruleKey]) {
      throw "Invalid validator function: " + ruleKey;
    }
    const stateParams = constraints[ruleKey].params || constraints[ruleKey];
    const constraintParams = [fieldValue].concat(stateParams ? stateParams : []);
    var isOK = Validator[ruleKey].apply(null, constraintParams);
    if (!isOK) {
      const errorMessage = Validator.getErrorMessage({
        label: properties_byKey[fieldKey].label,
        constraints
      }, ruleKey);
      collector.push({
        message: errorMessage
      });
    }
    return collector;
  }, []);
  return errorObjects;
}
module.exports = function () {
  const id = this.getID();
  const TYPES = {
    SET_FIELD_CARD_PROPERTY: `@ruby-app/${id}/SET_FIELD_CARD_PROPERTY`,
    SET_FIELD_PROPERTY: `@ruby-app/${id}/SET_FIELD_PROPERTY`,
    ADD_CARD: `@ruby-app/${id}/ADD_CARD`,
    DUPLICATE_CARD: `@ruby-app/${id}/DUPLICATE_CARD`,
    DELETE_CARD: `@ruby-app/${id}/DELETE_CARD`,
    SET_EDIT_CARD_CODE: `@ruby-app/${id}/SET_EDIT_CARD_CODE`,
    MOVE_CARD: `@ruby-app/${id}/MOVE_CARD`,
    UPDATE_CARD_ORDER: `@ruby-app/${id}/UPDATE_CARD_ORDER`,
    SET_FIELD_ERROR_TREE: `@ruby-app/${id}/SET_FIELD_ERROR_TREE`
  };
  return {
    TYPES,
    generators: {
      set_property_forFieldCardKey_toValue: function (property, key, value) {
        return {
          type: TYPES.SET_FIELD_CARD_PROPERTY,
          payload: {
            key,
            property,
            value
          }
        };
      },
      set_property_forFieldKey_toValue_withErrors: function (property, key, value, errors) {
        return {
          type: TYPES.SET_FIELD_PROPERTY,
          payload: _objectSpread({
            key,
            property,
            errors,
            error: errors ? errors[0] : undefined
          }, value != undefined ? {
            value
          } : {})
        };
      },
      set_property_forFieldKey_toValue_withConstraints: function (property, key, value, constraints, propSpecKey) {
        const selfModule = this;
        const actions = selfModule.getAction().generators;
        const {
          selfSelector
        } = selfModule.getDependencies();
        const propertiesWithoutPermissions_byType = this.propertiesWithoutPermissions_byType();
        return (dispatch, getState) => {
          const fieldState = _lodash.default.get(selfSelector(getState()), ['fields', this.props.key, 'value']);
          const instances = _lodash.default.get(fieldState, 'instances');
          const currInstance = instances[key];
          const currComponentName = _lodash.default.get(instances, [key, 'instanceProps', 'componentName', 'value']);
          const properties = propertiesWithoutPermissions_byType[currComponentName] || [];
          const propertiesSpecsReferencingCurrent = properties.filter(spec => spec.defaultToProp === property);
          const errors = constraints ? _validateField_withConstraints(propSpecKey || property, value, constraints, instances, selfModule, currInstance) : undefined;
          dispatch(actions.set_property_forFieldKey_toValue_withErrors(property, key, value, errors));
          propertiesSpecsReferencingCurrent.map(_ref6 => {
            let {
              propKey,
              defaultToProp,
              constraints
            } = _ref6;
            const stateValue = _lodash.default.get(instances, [key, 'instanceProps', propKey, 'value']);
            if (stateValue) {
              //# only process if stateValue is undefined
              return;
            }
            const defaultedValue = value ? rubyWords.keyify(value) : undefined;
            const resolvedValue = _lodash.default.isNil(stateValue) ? defaultedValue : stateValue;
            const errors = constraints ? _validateField_withConstraints(propKey, defaultedValue, constraints, instances, selfModule, currInstance) : undefined;
            dispatch(actions.set_property_forFieldKey_toValue_withErrors(propKey, key, undefined, errors));
          });
        };
      }
      //# if targetID == undefined, insert it at the end
      ,
      addCard_ofType_afterTargetID: function (type, targetID) {
        return {
          type: TYPES.ADD_CARD,
          payload: {
            type,
            targetID,
            scrollToBottomOnMount: targetID == null
          }
        };
      },
      duplicateCard_withTargetID: function (targetID) {
        return {
          type: TYPES.DUPLICATE_CARD,
          payload: {
            targetID
          }
        };
      },
      deleteCard_withTargetID: function (targetID) {
        return {
          type: TYPES.DELETE_CARD,
          payload: {
            targetID
          }
        };
      },
      startCodeEditingForInstance: function (instance) {
        const {
          selfActions,
          codeDialogActions
        } = this.getDependencies();
        const instanceID = _lodash.default.get(instance, 'id');
        const instanceType = _lodash.default.get(instance, ['instanceProps', 'instanceType']);
        const fieldType = _lodash.default.get(instance, ['instanceProps', 'componentName', 'value']);
        const instanceState = {
          instances: {
            [instanceID]: instance
          },
          childIds: [instanceID]
        };
        const formJS_forInstance = this.formValueFromLocalState({
          fields: {
            [this.props.key]: {
              value: instanceState
            }
          }
        }).children[0];
        const formJS_forInstance_asString = JSON.stringify(formJS_forInstance, null, JSON_INDENTATION_SPACES);
        const properties_byKey = this.propertiesWithoutPermissions_byKey();

        //# TODO: get the appropriate key/props
        //# collect all the keys and form the structure that we need to mimic the store structure
        //# then run it through the transformer to get the formJS structure
        const fieldInfo_byComponentName = this.fieldInfo_byComponentName();
        const coreProperties_byInstanceType = this.coreProperties_byInstanceType();
        const corePropertyKeys = Object.keys(coreProperties_byInstanceType[instanceType]);
        const additionalPropertyKeys = _lodash.default.get(fieldInfo_byComponentName[fieldType], 'propertyKeys', []);
        const autoPopulateData_asInstanceProps = Object.assign.apply(Object, corePropertyKeys.concat(additionalPropertyKeys).map(propertyKey => {
          const propKey = properties_byKey[propertyKey].propKey;
          return {
            [propKey]: {
              value: null
            }
          };
        }));
        const autoPopulateData_asFormJS = fieldTypesThatDontAutopopulate[fieldType] ? null : (0, _serverTransforms.convertFieldEditorState_toFormJS)({
          instances: {
            [instanceID]: _extends({}, instance, {
              instanceProps: autoPopulateData_asInstanceProps
            })
          },
          childIds: [instanceID]
        })[0];
        return dispatch => {
          dispatch(codeDialogActions.openDialogWithOptions({
            value: formJS_forInstance_asString,
            autoPopulateData: autoPopulateData_asFormJS,
            onSubmit: codeDialogValue => {
              dispatch(selfActions.setEditCardCode_forInstanceID_withValue(instanceID, JSON.parse(codeDialogValue)));
              dispatch(codeDialogActions.closeAndResetDialog());
            }
          }));
        };
      },
      startCodeEditingForEntireForm: function () {
        const formKey = this.props.key;
        const {
          selfSelector,
          selfActions,
          codeDialogActions
        } = this.getDependencies();
        return (dispatch, getState) => {
          const selfState = selfSelector(getState());
          const formJS = this.formValueFromLocalState(selfState).children;
          //# leveraging the overridden function that does special things
          //# for the field-editor state

          const formJS_asString = JSON.stringify(formJS, null, JSON_INDENTATION_SPACES);
          dispatch(codeDialogActions.openDialogWithOptions({
            value: formJS_asString,
            onSubmit: codeDialogValue => {
              dispatch(selfActions.setFieldValueByKey(JSON.parse(codeDialogValue), formKey));
              dispatch(codeDialogActions.closeAndResetDialog());
            }
          }));
        };
      },
      setEditCardCode_forInstanceID_withValue: function (instanceID, value) {
        return {
          type: TYPES.SET_EDIT_CARD_CODE,
          payload: {
            instanceID,
            value
          }
        };
      },
      moveCard_withTargetID_toAfterID: function (targetID, afterID) {
        return {
          type: TYPES.MOVE_CARD,
          payload: {
            targetID,
            afterID
          }
        };
      },
      updateCardOrdering_withOrder: function (order) {
        return {
          type: TYPES.UPDATE_CARD_ORDER,
          payload: {
            order
          }
        };
      }
      //# overriding mixin
      ,
      setFieldErrorMessageByKey: function (errorArray, key) {
        const TYPES = this.getAction().TYPES;
        return {
          type: TYPES.SET_FIELD_ERROR_TREE,
          payload: {
            errors: errorArray,
            key //# NOTE: the assumption is that there is always one key
          }
        };
      }
    }
  };
};