"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _lodash = _interopRequireDefault(require("lodash"));
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _InternationalAddress = _interopRequireDefault(require("./InternationalAddress"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function generator() {
  const selfModule = this;
  const {
    selfSelector,
    latEl,
    lngEl,
    feSettingsSelector,
    headerComponent
  } = selfModule.getDependencies();
  const fieldKey = this.props.key;
  const showErrorNotification = selfModule.showErrorNotification.bind(selfModule);
  let prevErrorObject;
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const formValue = selfModule.internalFormValue(selfState);
    const feSettings = feSettingsSelector(state);
    const mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);
    const newErrorObject = selfModule.formError();

    // Might need to clear the state error
    if (!_lodash.default.isEqual(newErrorObject, prevErrorObject)) {
      prevErrorObject = newErrorObject;
      selfModule.refreshParentErrors();
    }
    const GOOGLE_API_KEY = _lodash.default.get(feSettings, 'RUBYAPPS_SETTINGS.google_api_key', _lodash.default.get(feSettings, 'RUBY_GOOGLE_MAPS_API_KEY'));

    //# find settings url
    const settingsEditMenuSpec = GOOGLE_API_KEY ? null : headerComponent.getPermittedMenuSpecForId('cms-settings');
    const mappedProps = _extends({}, ownProps, mixinFieldProps, {
      id: selfModule.getID(),
      showErrorNotification,
      value: fieldKey ? formValue[fieldKey] : formValue,
      GOOGLE_API_KEY,
      settingsEditMenuSpec
    });
    return mappedProps;
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch),
      delegateActions: {
        longitudeField: (0, _redux.bindActionCreators)(lngEl.getAction().generators, dispatch),
        latitudeField: (0, _redux.bindActionCreators)(latEl.getAction().generators, dispatch)
      }
    };
  }
  function mergeProps(stateProps, dispatchProps, ownProps) {
    return _extends({}, ownProps, stateProps, dispatchProps, {
      //# setup the delegate callbacks here whcih calls on dispatchProps.actions...
      delegateRouteChange: (route, menuItemConfig) => {
        if (menuItemConfig.url) {
          if (menuItemConfig.newWindow) {
            window.open(route);
          } else {
            window.location = route;
          }
        } else {
          dispatchProps.actions.navigateToPathWithOptions({
            path: route
          });
        }
      }
    });
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps, mergeProps)(_InternationalAddress.default);
}