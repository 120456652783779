"use strict";

var _redux = require("redux");
var _reactRedux = require("react-redux");
var _ConfirmDialog = _interopRequireDefault(require("./ConfirmDialog"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const _ = require('lodash');
module.exports = function ConfirmDialogGenerator() {
  const selfModule = this;
  const dependencies = selfModule.getDependencies();
  const selfSelector = dependencies.selfSelector;
  const selfActions = selfModule.getAction().generators;
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const mappedFields = {};
    return _extends({}, _.pick(selfState, ['open', 'loading', 'title', 'content', 'endpoint', 'cancelLabel', 'submitLabel', 'submitLabel_secondary']), mappedFields);
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfActions, dispatch)
    };
  }
  function mergeProps(stateProps, dispatchProps, ownProps) {
    //# need to handle delegation here because it needs the bound action functions
    let modifiedStateProps = stateProps;
    return _extends({}, ownProps, modifiedStateProps, dispatchProps, {
      //# setup the delegate callbacks here which calls on dispatchProps.actions...
      delegateSubmit: function (evt) {
        return dispatchProps.actions.submit();
      },
      delegateSubmit_secondary: function (evt) {
        return dispatchProps.actions.submit_secondary();
      },
      delegateClose: function (evt) {
        return dispatchProps.actions.closeAndResetDialog();
      }
    });
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps, mergeProps)(_ConfirmDialog.default);
};