"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const defaultState = {
  activitiesEndpoint: null,
  activitiesListEndpoint: null,
  activitiesById: {},
  activitiesIdArray: [],
  pollIntervalId: null,
  activitiesByIdToPoll: {},
  showAll: false,
  edits: null,
  newComment: ''
};
module.exports = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  const {
    TYPES
  } = this.getAction();
  const {
    type,
    payload
  } = action;
  if (!state.hasOwnProperty('activitiesIdArray')) {
    state = _extends({}, defaultState, state);
  }
  switch (type) {
    case TYPES.SET_ACTIVITIES_ENDPOINT:
      return _extends({}, state, {
        activitiesEndpoint: payload.endpoint
      });
    case TYPES.SET_ACTIVITIES_LIST_ENDPOINT:
      return _extends({}, state, {
        activitiesListEndpoint: payload.endpoint
      });
    case TYPES.SET_ACTIVITY_LIST:
      const activitiesIdArray_0 = [];
      const activitiesById_0 = payload.activities.reduce((collector, value, id) => {
        collector[value.id] = value;
        activitiesIdArray_0[id] = value.id;
        return collector;
      }, {});
      return _extends({}, state, {
        //activities: payload.activities
        activitiesIdArray: activitiesIdArray_0,
        activitiesById: activitiesById_0
      });
    case TYPES.SET_ACTIVITIES_BY_ID_TO_POLL:
      return _extends({}, state, {
        activitiesByIdToPoll: payload.activitiesByIdToPoll
      });
    case TYPES.CLEAR_ACTIVITY_ID_FROM_POLL:
      return _extends({}, state, {
        activitiesByIdToPoll: _lodash.default.omit(state.activitiesByIdToPoll, [payload.activityId])
      });
    case TYPES.SET_POLL_INTERVAL_ID:
      return _extends({}, state, {
        pollIntervalId: payload.pollIntervalId
      });
    case TYPES.SET_ACTIVITY_OBJECT:
      const activitiesById_1 = _extends({}, state.activitiesById, {
        [payload.activity.id]: payload.activity
      });
      return _extends({}, state, {
        activitiesById: activitiesById_1
      });
    case TYPES.UPDATE_ACTIVITY_OBJECT:
      const activityId = payload.activity.id;
      const currentData = state.activitiesById[activityId];
      const updatedData = payload.activity;
      const mergedData = _lodash.default.merge({}, currentData, updatedData);
      const updatedActivities = _extends({}, state.activitiesById, {
        [payload.activity.id]: mergedData
      });
      return _extends({}, state, {
        activitiesById: updatedActivities
      });
    case TYPES.TOGGLE_SHOW_ALL:
      return _extends({}, state, {
        showAll: !state.showAll
      });
    case TYPES.EDIT_NEW_COMMENT:
      return _extends({}, state, {
        newComment: payload.comment
      });
    case TYPES.EDIT_ACTIVITY_COMMENT:
      return _extends({}, state, {
        edits: {
          activityId: payload.activityId,
          comment: payload.comment
        }
      });
    case TYPES.CLEAR_EDITS:
      return _extends({}, state, {
        edits: null
      });
    case TYPES.RESET_SHOW_ALL:
      return _extends({}, state, {
        showAll: false
      });
    case TYPES.RESET_STORE:
      return _extends({}, defaultState);
    default:
      return state;
  }
};