"use strict";

var _reduxBatchedActions = require("redux-batched-actions");
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const Promise = require('bluebird');
function typesWithID(id) {
  return {
    SET_VISIBLE: `@@ruby-app/${id}/SET_VISIBLE`,
    SET_VISIBLE_STEADYSTATE: `@@ruby-app/${id}/SET_VISIBLE_STEADYSTATE`,
    UNSET_VISIBLE: `@@ruby-app/${id}/UNSET_VISIBLE`
  };
}
const generators = {
  _set_visible: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SET_VISIBLE
    };
  },
  _set_visible_steadystate: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SET_VISIBLE_STEADYSTATE
    };
  },
  set_visible: function () {
    const {
      TYPES
    } = this.getAction();
    return (dispatch, getState) => {
      const selfState = this.getState(getState());
      const shouldUpdateChildren = !selfState.isVisibleLatched;
      const parentFormComponent = this.getParentFormComponent();
      if (!shouldUpdateChildren) {
        dispatch(this.getAction().generators._set_visible());
        return;
      }

      //# When being called by field-tabs setSelectedIndex
      //# it might have been triggeed by the #hash which references the tab 
      //# this happens before we retrieve data so there's contention between the two
      //# we check whether there's a pending promise before we set visibility 
      //# just in case 
      const promisedOnceResolved = parentFormComponent.getStatefulCacheForKey('promisedOnceResolved');
      return (promisedOnceResolved || Promise.resolve()).then(_.identity, _.identity).then(() => {
        dispatch(this.getAction().generators._set_visible());
      }).delay(100).then(() => {
        const parentFormValue = parentFormComponent.formValue();
        const selfFormValue = this.formValue();
        const selfErrorFormValue = this.getState().error;

        //# update children
        let updateChildren__actionCollector = [];
        const updateChildren__collectAction = value => {
          updateChildren__actionCollector.push(value);
        };
        const existingChildren = this.getChildren();
        if (existingChildren && existingChildren.length == 0) {
          this.updateChildren(true, updateChildren__collectAction);
        }
        const formValueToLocalState__promises = [];
        if (selfFormValue) {
          let actionCollector = [];
          const collectAction = value => {
            actionCollector.push(value);
          };

          //# Because of pristine form data, we need  to somehow update it for the following set of data

          const promiseTree = this._formValueToLocalState(selfFormValue, collectAction, false, parentFormValue);
          promiseTree.then(() => {
            dispatch((0, _reduxBatchedActions.batchActions)(actionCollector));
            dispatch((0, _reduxBatchedActions.batchActions)(updateChildren__actionCollector)); //# request rerender of children

            //# get updated formValue and then update the pristineFormData

            const formValue_newSelf = this.formValue();
            //# NOTE: the selfFormValue above is effectively the pristineFormData partial
            //# and formValue_newSelf is the rendere variant of pristineFormData partial
            //# so we need to update the parentFormComponent's pristineFormData to account for the browser-side defaults
            const fieldKey = this.props.key;
            const formValue_newChildren = fieldKey ? formValue_newSelf[fieldKey] : formValue_newSelf;
            const fieldKeypathArr = this.getFieldKeypathArr();
            const parentFormComponent = this.getParentFormComponent();
            const pristineFormData_existing = parentFormComponent.getState().pristineFormData;
            const pristineFormData_updated = _.cloneDeep(pristineFormData_existing);
            const targetObjectToMerge = fieldKeypathArr.length ? _.get(pristineFormData_updated, fieldKeypathArr, {}) : pristineFormData_updated;
            //# NOTE: targetObjectToMerge might be null (eg. for namespace cause the namespace value would be null in the pristine form if it wasn't previously saved)

            _extends(targetObjectToMerge, formValue_newChildren);
            if (fieldKeypathArr.length) {
              //# set updated object at keypath
              //# otherwise, we assume the pristineFormData was updated
              _.set(pristineFormData_updated, fieldKeypathArr, targetObjectToMerge);
            }
            dispatch(parentFormComponent.getAction().generators.setPristineFormData(pristineFormData_updated));
          });
          formValueToLocalState__promises.push(promiseTree);
        }
        if (selfErrorFormValue) {
          let actionCollector = [];
          const collectAction = value => {
            actionCollector.push(value);
          };

          //# Because of pristine form data, we need  to somehow update it for the following set of data
          const promiseTree = this._formValueToLocalState(selfErrorFormValue, collectAction, true, {});
          promiseTree.then(() => {
            dispatch((0, _reduxBatchedActions.batchActions)(actionCollector));

            //# update parent errors
            this.refreshParentErrors();
          });
          formValueToLocalState__promises.push(promiseTree);
        }
        return Promise.all(formValueToLocalState__promises);
      }).then(() => {
        dispatch(this.getAction().generators._set_visible_steadystate());
      });
    };
  },
  unset_visible: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.UNSET_VISIBLE
    };
  }
};
module.exports = function () {
  return {
    TYPES: typesWithID(this.getID()),
    generators
  };
};