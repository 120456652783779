"use strict";

var _serverTransforms = require("./serverTransforms");
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const uuid = require('node-uuid');
const _ = require('lodash');
const NAME_KEY = 'label';
const TYPE_KEY = 'componentName';
const defaultFormState = {
  instances: {
    /*
    '1': {
        id: '1',
        instanceProps: {
            instanceType: 'Tab',
            _locked: {
                value: false
            },
            label: {
                value: null,
            },
        }
    },
    '2': {
        id: '2',
        instanceProps: {
            instanceType: 'Fieldset',
            _locked: {
                value: false
            },
            label: {
                value: null,
            },
        },
    },
    '3': {
        id: '3',
        instanceProps: {
            instanceType: 'Field',
            _locked: {
                value: true
            },
            label: {
                value: 'Case Cite',
                errorText: 'Label is a required field.',
            },
            componentName: {
                value: 'Text',
            },
            constraint_required: {
                value: true,
            },
            key: {
                value: 'case_cite',
            },
        },
    },
    '4': {
        id: '4',
        instanceProps: {
            instanceType: 'Field',
            label: {
                value: 'Reported Case?',
            },
            componentName: {
                value: 'Checkbox',
            },
            constraint_required: {
                value: false,
            },
            key: {
                value: 'reported',
            }
        }
    },
    */
  },
  childIds: [/*'1','2', '3','4'*/]
};
function newSpecForInstanceType(instanceType) {
  switch (instanceType) {
    case 'Tab':
      return {
        instanceType: 'Tab',
        label: {
          value: null
        }
      };
    case 'Fieldset':
      return {
        instanceType: 'Fieldset',
        label: {
          value: null
        }
      };
    case 'Field':
      return {
        instanceType: 'Field',
        label: {
          value: null
        },
        componentName: {
          value: 'Text'
        },
        constraint_required: {
          value: null
        },
        key: {
          value: null
        }
      };
    case 'SubTemplate':
      return {
        instanceType: 'SubTemplate',
        label: {
          value: ''
        }
      };
  }
}
function newID() {
  return uuid.v1();
}
function flattenTree(tree) {
  function recurse(nodes, path) {
    return _.map(nodes, function (node, index) {
      if (node) {
        return [_.map(_.omit(node, ['children']), (nodeValue, nodeKey) => {
          var newPath = path.concat([index, nodeKey]);
          return {
            patharr: newPath,
            key: nodeKey,
            value: nodeValue
          };
        }), recurse(node.children, path.concat([index, 'children']))];
      } else {
        return [];
      }
    });
  }
  return _.flattenDeep(recurse(tree, []));
}
function setStateWithFieldKey(state, fieldKey, value) {
  let isUserModified = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  const originalFields = state.fields || {};
  const updatedValue = {
    value
  };
  const updatedField = _extends({}, originalFields[fieldKey] || {}, updatedValue);
  const updatedFields = _extends({}, originalFields, {
    [fieldKey]: updatedField
  });
  if (isUserModified) {
    updatedFields[fieldKey].userModifiedTimestamp = new Date();
  }
  return _extends({}, state, {
    fields: updatedFields
  });
}
function setFieldCardProperty_reducer(selfModule, state, action) {
  const formKey = selfModule.props.key;
  const {
    type,
    payload
  } = action;
  const fieldState = _.get(state, ['fields', formKey, 'value']);
  const newState = _.merge({}, fieldState, {
    instances: {
      [payload.key]: {
        [payload.property]: payload.value
      }
    }
  });
  return setStateWithFieldKey(state, formKey, newState);
}
function setFieldProperty_reducer(selfModule, state, action) {
  const formKey = selfModule.props.key;
  const {
    type,
    payload
  } = action;
  const {
    error,
    errors,
    key
  } = payload;
  const fieldState = _.get(state, ['fields', formKey, 'value']);
  const current_property__value = _.get(fieldState.instances[payload.key].instanceProps, [payload.property, 'value']);
  const newState = _extends({}, fieldState, {
    instances: _extends({}, fieldState.instances, {
      [payload.key]: _extends({}, fieldState.instances[payload.key], {
        instanceProps: _extends({}, fieldState.instances[payload.key].instanceProps, {
          [payload.property]: {
            value: payload.hasOwnProperty('value') ? payload.value : current_property__value,
            errorText: error ? error.message : null
          }
        })
      })
    })
  });
  return setStateWithFieldKey(state, formKey, newState);
}

/*
 *  Given a FormJS like tree of errors, we need to merge that into the full FormJS tree
 * */
function setFieldErrors_reducer(selfModule, state, action) {
  const formKey = selfModule.props.key;
  const {
    type,
    payload
  } = action;
  const errorTree = payload.errors;
  const formJS_forInstance = (0, _serverTransforms.convertFieldEditorState_toFormJS)(_.get(state, ['fields', formKey, 'value']));

  //# walk the errorTree, generating a keypath and setting the keypath on formJS_forInstance
  const flattenedErrorTree = flattenTree(errorTree);
  flattenedErrorTree.forEach(errorObject => {
    const {
      patharr,
      key,
      value
    } = errorObject;

    //# WARNING:
    //# This is hacky. We shouldn't be nesting the values when formJS_forInstance
    //# should represent the actual formJS
    const formJSValue = _.get(formJS_forInstance, patharr);
    _.set(formJS_forInstance, patharr, {
      _doLift: true,
      value: formJSValue,
      errorText: value
    });
    //# We rely on the conversion functions we already have
    //# and then we patch up the values so that we effectively insert 'errorText'
    //# adjacent to the 'value' for the instanceProps.${property}
  });

  const augmentedFieldEditorState = (0, _serverTransforms.convertFormJS_toFieldEditorState)(formJS_forInstance);
  //# patch the augmentedFieldEditorState up by moving the values out
  _.each(augmentedFieldEditorState.instances, instance => {
    _.each(instance.instanceProps, instanceProp => {
      if (instanceProp.value && instanceProp.value._doLift) {
        instanceProp = _extends({}, instanceProp, _.omit(instanceProp.value, ['_doLift']));
      }
    });
  });
  return setStateWithFieldKey(state, formKey, augmentedFieldEditorState);
}
function addCard_reducer(selfModule, state, action) {
  const formKey = selfModule.props.key;
  const {
    type,
    payload
  } = action;
  const instanceType = payload.type;
  const targetID = payload.targetID;
  const scrollToBottomOnMount = payload.scrollToBottomOnMount;
  const fieldState = _.get(state, ['fields', formKey, 'value']);
  const childIds = _.get(fieldState, 'childIds');
  const newChildID = newID();
  const newSpec = {
    id: newChildID,
    instanceProps: newSpecForInstanceType(instanceType),
    scrollToBottomOnMount
  };
  const indexForNewChild = targetID ? childIds.indexOf(targetID) : childIds.length;
  const newChildIds = [].concat(childIds.slice(0, indexForNewChild + 1), newChildID, childIds.slice(indexForNewChild + 1));
  const newState = _extends({}, fieldState, {
    childIds: newChildIds,
    instances: _extends({}, fieldState.instances, {
      [newChildID]: newSpec
    })
  });
  return setStateWithFieldKey(state, formKey, newState);
}
function duplicateCard_reducer(selfModule, state, action) {
  const formKey = selfModule.props.key;
  const {
    type,
    payload
  } = action;
  const targetID = payload.targetID;
  const fieldState = _.get(state, ['fields', formKey, 'value']);
  const childIds = _.get(fieldState, 'childIds');
  const referenceInstance = _.get(fieldState, ['instances', targetID]);
  const newChildID = newID();
  const name = _.get(referenceInstance, ['instanceProps', NAME_KEY, 'value']);
  const key = _.get(referenceInstance, ['instanceProps', 'key', 'value']);
  const newSpecState = _.merge({}, referenceInstance, {
    id: newChildID,
    instanceProps: {
      [NAME_KEY]: {
        value: name + ' Copy'
      },
      key: {
        value: key + '_copy'
      }
    }
  });
  const indexForNewChild = childIds.indexOf(targetID);
  const newChildIds = [].concat(childIds.slice(0, indexForNewChild + 1), newChildID, childIds.slice(indexForNewChild + 1));
  const newState = _extends({}, fieldState, {
    childIds: newChildIds,
    instances: _extends({}, fieldState.instances, {
      [newChildID]: newSpecState
    })
  });
  return setStateWithFieldKey(state, formKey, newState);
}
function deleteCard_reducer(selfModule, state, action) {
  const formKey = selfModule.props.key;
  const {
    type,
    payload
  } = action;
  const targetID = payload.targetID;
  const fieldState = _.get(state, ['fields', formKey, 'value']);
  const childIds = _.get(fieldState, 'childIds');
  const referenceInstance = _.get(fieldState, ['instances', targetID]);
  const deleteIndex = childIds.indexOf(targetID);
  const updatedChildIds = [].concat(childIds.slice(0, deleteIndex), childIds.slice(deleteIndex + 1));
  const newState = _extends({}, fieldState, {
    childIds: updatedChildIds,
    instances: _.omit(fieldState.instances, targetID)
  });
  return setStateWithFieldKey(state, formKey, newState);
}
function setEditCardCode_reducer(selfModule, state, action) {
  const formKey = selfModule.props.key;
  const {
    type,
    payload
  } = action;
  const {
    instanceID,
    value
  } = payload;
  const fieldState = _.get(state, ['fields', formKey, 'value']);
  if (value) {
    const fieldEditorState = (0, _serverTransforms.convertFormJS_toFieldEditorState)([value]);
    const newState_withUpdatedInstance = _extends({}, fieldState, {
      instances: _extends({}, fieldState.instances, {
        [instanceID]: _extends({}, _.get(fieldState, ['instances', instanceID]), {
          instanceProps: _.get(fieldEditorState, ['instances', fieldEditorState.childIds[0], 'instanceProps'])
        })
      })
    });
    return setStateWithFieldKey(state, formKey, newState_withUpdatedInstance);
  }
}
function updateCardOrder_reducer(selfModule, state, action) {
  const formKey = selfModule.props.key;
  const {
    type,
    payload
  } = action;
  const newOrder = payload.order;
  const fieldState = _.get(state, ['fields', formKey, 'value']);
  const newState = _extends({}, fieldState, {
    childIds: newOrder
  });
  return setStateWithFieldKey(state, formKey, newState);
}
function moveCard_reducer(selfModule, state, action) {
  const formKey = selfModule.props.key;
  const {
    type,
    payload
  } = action;
  const {
    targetID,
    afterID
  } = payload;
  const fieldState = _.get(state, ['fields', formKey, 'value']);
  const childIds = _.get(fieldState, 'childIds');
  const currentIndex = childIds.indexOf(targetID);
  const childIdsWithoutMovedID = [].concat(childIds.slice(0, currentIndex), childIds.slice(currentIndex + 1));
  const newIndex = childIdsWithoutMovedID.indexOf(afterID) + 1;
  const newChildIds = [].concat(childIdsWithoutMovedID.slice(0, newIndex), targetID, childIdsWithoutMovedID.slice(newIndex));
  const newState = _extends({}, fieldState, {
    childIds: newChildIds
  });
  return setStateWithFieldKey(state, formKey, newState);
}
module.exports = function (state, action) {
  const selfModule = this;
  const formKey = selfModule.props.key;
  if (state == undefined) {
    state = {
      fields: {
        [formKey]: {
          value: defaultFormState
        }
      }
    };
  } else if (!state.fields[formKey].value) {
    return setStateWithFieldKey(state, formKey, defaultFormState);
  }
  const {
    TYPES,
    generators
  } = this.getAction();
  const {
    type,
    payload
  } = action;
  switch (type) {
    case TYPES.SET_FIELD_CARD_PROPERTY:
      return setFieldCardProperty_reducer(selfModule, state, action);
    case TYPES.SET_FIELD_PROPERTY:
      return setFieldProperty_reducer(selfModule, state, action);
    case TYPES.SET_FIELD_ERROR_TREE:
      return setFieldErrors_reducer(selfModule, state, action);
    case TYPES.ADD_CARD:
      return addCard_reducer(selfModule, state, action);
    case TYPES.DUPLICATE_CARD:
      return duplicateCard_reducer(selfModule, state, action);
    case TYPES.DELETE_CARD:
      return deleteCard_reducer(selfModule, state, action);
    case TYPES.MOVE_CARD:
      return moveCard_reducer(selfModule, state, action);
    case TYPES.UPDATE_CARD_ORDER:
      return updateCardOrder_reducer(selfModule, state, action);
    case TYPES.SET_EDIT_CARD_CODE:
      return setEditCardCode_reducer(selfModule, state, action);
    case TYPES.SET_FIELD_VALUE_BY_KEY:
      const fieldEditorState = (0, _serverTransforms.convertFormJS_toFieldEditorState)(action.payload.value);
      return setStateWithFieldKey(state, formKey, fieldEditorState, false);
    default:
      return state;
  }
};