"use strict";

var _reduxBatchedActions = require("redux-batched-actions");
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
/*
   20220826: This is an intermediary solution for what should uiltimately be replaced with:

   [RT#81884 - Collaborative Editing Requested, Page-level locks are too monolithic]( https://gitlab.intranet.rubensteintech.com/project/ruby-app/-/issues/1177)

*/

const _ = require('lodash');
const jsondiffpatch = require('jsondiffpatch');
const queryString = require('query-string');
const request = require('../../../ruby-superagent');
const rubyNotificationsComponent = require('../../../ruby-component-notifications');
const sitemapUtils = require('../common/utils');
const rubyLogger = require('@rubyapps/ruby-logger');
const packageName = 'ruby-component-field-sitemap';
const logger = rubyLogger.getLogger(packageName);
const POLLING_INTERVAL = 20 /*seconds*/ * 1000 /*milliseconds*/;

//# Should be used by ATF when testing or visiting the sitemap page
//# NOTE: if you use this to disable polling, be aware of the consequences of this use
const DEBUG_FLAG__DISABLE_POLLING = 'debug__sitemap__disablePolling';
//# if you want to completely disable polling
const DEBUG_FLAG__SUPPRESS_POLLING_DIALOG = 'debug__sitemap__suppressPollingDialog';
//# you can also just supress the dialog
//# but be aware that the sitemap will automatically update/refresh
//# which might be an issue if you're testing the sitemap

//# Should be used by anyone when debugging the Sitemap Updated popup showing when it shouldn't
const DEBUG_FLAG__LOG_SITEMAP_DIFF = 'debug__sitemap__logSitemapDiff';
const LOCATION__QUERY_PARAMS = location && location.search ? _objectSpread({}, queryString.parse(location.search)) : {};
const IS_DEBUG_FLAG__DISABLE_POLLING = LOCATION__QUERY_PARAMS.hasOwnProperty(DEBUG_FLAG__DISABLE_POLLING);
const IS_DEBUG_FLAG__SUPPRESS_POLLING_DIALOG = LOCATION__QUERY_PARAMS.hasOwnProperty(DEBUG_FLAG__SUPPRESS_POLLING_DIALOG);
const IS_DEBUG_FLAG__SHOW_SITEMAP_DIFF = LOCATION__QUERY_PARAMS.hasOwnProperty(DEBUG_FLAG__LOG_SITEMAP_DIFF);
module.exports = {
  iterPolling__checkIfDataChanged() {
    const pollingTimeoutId = setTimeout(() => {
      this.polling__checkIfDataChanged();
    }, POLLING_INTERVAL);
    this.setStatefulCacheForKey('pollingTimeoutId', pollingTimeoutId);
  },
  polling__checkIfDataChanged() {
    const {
      parentForm,
      currentUser
    } = this.getDependencies();
    const selfState = this.getState();
    const parentRouteComponent = this.findAncestorBy(n => n.getRouteElement);
    const {
      routeParams: {
        routeActive
      } = {
        routeActive: false
      }
    } = parentRouteComponent.getState();
    const pendingAddPage = this.getStatefulCacheForKey('pendingAddPage');
    if (!routeActive || pendingAddPage) {
      return;
    }
    const retrievedFormDataID = parentRouteComponent._retrievedFormDataID;
    if (!retrievedFormDataID) {
      return;
    }

    //# check if there's a pending hydration
    const isLoading = _.get(selfState, ['griddleState', 'loading'], false);
    //# NOTE: we cannot chain to griddleState.recentRequest because the cached request value is before request.end() gets called
    //# so if you chained to this, it would cause anotehr request to be made

    const pendingSave = parentForm.getStatefulCacheForKey('submitToRoute_withID_andFormValue');
    if (pendingSave) {
      logger.info(`Pending save exists, going to skip polling until next interval`);
      return pendingSave.then(() => {
        //# timeout then continue polling
        this.iterPolling__checkIfDataChanged();
      });
    } else if (isLoading) {
      logger.info(`Pending request exists, going to skip polling until next interval`);
      return this.iterPolling__checkIfDataChanged();
    }
    const dataEndpoint = parentRouteComponent.getInstanceDataEndpoint_forId(retrievedFormDataID, {
      query: 'polling=1'
    });
    const requestObject = request.get(dataEndpoint);
    return requestObject.then(response => {
      const {
        routeParams: {
          routeActive
          //# NOTE: route might have become inactive since making the request 
          //# eg. if you created a new page, since it's autonavigates to that page
        } = {
          routeActive: false
        }
      } = parentRouteComponent.getState();
      const pendingAddPage = this.getStatefulCacheForKey('pendingAddPage');
      if (!routeActive || pendingAddPage) {
        return;
      }
      const pendingSave = parentForm.getStatefulCacheForKey('submitToRoute_withID_andFormValue');
      if (pendingSave) {
        //# a save was dispatched while pollling, we should skip and wait

        logger.info(`Pending save exists, going to skip polling until next interval`);
        return pendingSave.then(() => {
          //# timeout then continue polling
          this.iterPolling__checkIfDataChanged();
        });
      }
      const serverFormData = response.body;
      const {
        pristineData
      } = parentRouteComponent.getState();
      const fieldKeypathArr = this.getFieldKeypathArr();
      const sitemap_formValue = _.get(this.formValue(), fieldKeypathArr);
      const server__sitemap_formValue = _.get(sitemapUtils.cleanSitemapFormValue_forFieldKeypathArr(serverFormData, fieldKeypathArr), fieldKeypathArr);
      if (!_.isEqual(sitemap_formValue, server__sitemap_formValue)) {
        if (IS_DEBUG_FLAG__SHOW_SITEMAP_DIFF) {
          console.log(`pollChanges: Sitemap Changes:`, jsondiffpatch.diff(sitemap_formValue, server__sitemap_formValue));
        }
        //# ask for refresh
        //# then continue polling
        const parentRouteActions = parentRouteComponent.getAction().generators;
        const modifiedPristineData = _objectSpread({}, pristineData);
        _.set(modifiedPristineData, fieldKeypathArr, server__sitemap_formValue);
        this.getStore().dispatch((0, _reduxBatchedActions.batchActions)([, parentRouteActions.setPristineData(modifiedPristineData), parentRouteActions.setRequestResponse(response), this.getAction().generators.setFieldValueByKey(server__sitemap_formValue, this.key(), true) //# need to update sitemap
        ]));

        //# restart polling 
        this.iterPolling__checkIfDataChanged();

        //# automatically reload the data and continue polling
        //# we just keep the notification modal around
        //# only show if the tab is active and has focus
        if (!document.hidden && document.hasFocus() && !IS_DEBUG_FLAG__SUPPRESS_POLLING_DIALOG) {
          const currentUserState = currentUser.getState();
          const currentUserName = `${currentUserState.first_name} ${currentUserState.last_name}`;
          const didCurrentUserModify = serverFormData.last_modified_by_user_name == currentUserName;
          this.showNotificationModal({
            type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.ERROR,
            title: 'Sitemap Updated',
            message: (didCurrentUserModify ? 'You modified the sitemap in a different tab.' : `${serverFormData.last_modified_by_user_name} modified the sitemap.`) + ' This session\'s sitemap has been updated to reflect these changes.'
          });
        }
      } else {
        //# continue polling
        this.iterPolling__checkIfDataChanged();
      }
    }).catch(err => {
      //# ignore error for now
      console.error(`Error polling for page data at endpoint: [${dataEndpoint}]`, err);
    });
  },
  onReduxInit: function (dispatch) {
    const store = this.getStore();
    if (IS_DEBUG_FLAG__DISABLE_POLLING) {
      return () => {};
    }
    const pollingTimeoutId = setTimeout(() => {
      this.polling__checkIfDataChanged();
    }, POLLING_INTERVAL);
    this.setStatefulCacheForKey('pollingTimeoutId', pollingTimeoutId);
    const parentRouteComponent = this.findAncestorBy(n => n.getRouteElement);
    const storeUnsub = store.subscribe(() => {
      const {
        routeParams: {
          routeActive
        } = {
          routeActive: true
        }
      } = parentRouteComponent.getState();
      if (!routeActive) {
        storeUnsub();
        const pollingTimeoutId = this.getStatefulCacheForKey('pollingTimeoutId');
        if (pollingTimeoutId) {
          clearTimeout(pollingTimeoutId);
          this.clearStatefulCacheForKey('pollingTimeoutId');
        }
      }
    });
    return () => {
      storeUnsub();
      const pollingTimeoutId = this.getStatefulCacheForKey('pollingTimeoutId');
      if (pollingTimeoutId) {
        clearTimeout(pollingTimeoutId);
        this.clearStatefulCacheForKey('pollingTimeoutId');
      }
    };
  }
};