"use strict";

const RubyComponent = require('@rubyapps/ruby-component');
const PropTypes = RubyComponent.PropTypes;
const middleware = require('./middleware');
const dialogMixin = {
  mixinName: 'rubyComponentMixinDialog',
  middleware,
  action: require('./action'),
  reducer: require('./reducer')
};
module.exports = dialogMixin;