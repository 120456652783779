"use strict";

//== The Middleware ========================================================//

const middlewareInitializer = function (store) {
  const selfModule = this;
  const selfAction = selfModule.getAction();
  return next => action => {
    let nextRetVal = next(action); //# still allow @@router to continue propagation

    const actionTypeComponents = action.type.split('/');
    switch (actionTypeComponents[actionTypeComponents.length - 1]) {
      //# if we saved the user edit page or a role page, we want to immediately pull in new user info
      //# if case the permissions changed
      case 'FORM_SAVE_SUCCESSFUL':
        const selfState = selfModule.getState();
        const {
          modelKey,
          pageId
        } = action.payload;
        if (modelKey == 'user' && pageId == selfState.id || modelKey == 'RubyRole') {
          //# pull new data from /me
          store.dispatch(selfAction.generators.retrieveLoggedInUser());
        }
        break;
    }
    return nextRetVal;
  };
};
module.exports = middlewareInitializer;