"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const _ = require('lodash');
module.exports = function (state, hydratedToggleTree) {
  const togglesById = togglesById_fromToggleTree(hydratedToggleTree);
  return togglesById;
};
function togglesById_fromToggleTree() {
  let toggleTree = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  const togglesById = _.reduce(toggleTree, (acc, treeNode) => {
    const {
      id,
      children = []
    } = treeNode;
    const value = _extends({
      [id]: _extends({}, _.omit(treeNode, ['children']), {
        toggled: false
      })
    }, !_.isEmpty(children) ? togglesById_fromToggleTree(children) : {});
    return _extends(acc, value);
  }, {});
  return togglesById;
}
function updateToggleTree(toggledId, toggleCtx, toggleTree) {
  const updatedToggleTree = _.reduce(toggleTree, (acc, toggleData) => {
    const {
      isToggled
    } = toggleCtx;
    const {
      id,
      fieldKey,
      children = []
    } = toggleData;
    const updatedData = id === toggledId ? _extends({}, toggleData, {
      toggled: isToggled
    }, !fieldKey && !_.isEmpty(children) && {
      children: updateChildToggleStates(children, isToggled)
    }) : _.isEmpty(children) ? toggleData : _extends({}, toggleData, {
      children: updateToggleTree(toggledId, toggleCtx, children)
    });
    return acc.concat(updatedData);
  }, []);
  return updatedToggleTree;
}
function updateChildToggleStates(toggleTree, toggleState) {
  return _.reduce(toggleTree, (acc, treeNode) => {
    const {
      children = []
    } = treeNode;
    const updatedChildState = _extends({}, treeNode, !_.isEmpty(children) && {
      children: updateChildToggleStates(children, toggleState)
    }, {
      toggled: toggleState
    });
    return acc.concat(updatedChildState);
  }, []);
}