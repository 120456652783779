// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "h1 {\n    font-size: 22px;\n}\n.ForgotPassword__forgotPasswordGridWrapper___GbDf7 {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: absolute;\n    height: 100%;\n    width: 100%;\n}\n.ForgotPassword__forgotPasswordBlock___OjjWc {\n    text-align: center;\n}\n.ForgotPassword__forgotPasswordCard___nrrsQ {\n    text-align: left;\n}\n.ForgotPassword__forgotPasswordHeader___tSRFM {\n    text-align: center;\n    margin-top: 20px;\n}\n.ForgotPassword__logo___mSW_S {\n    width: 320px;\n    height: 70px;\n    display: inline-block;\n}\n", "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-forgot-password-page/src/client/reactComponents/ForgotPassword.cssModule"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;IACZ,WAAW;AACf;AACA;IACI,kBAAkB;AACtB;AACA;IACI,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;AACA;IACI,YAAY;IACZ,YAAY;IACZ,qBAAqB;AACzB","sourcesContent":["h1 {\n    font-size: 22px;\n}\n.forgotPasswordGridWrapper {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: absolute;\n    height: 100%;\n    width: 100%;\n}\n.forgotPasswordBlock {\n    text-align: center;\n}\n.forgotPasswordCard {\n    text-align: left;\n}\n.forgotPasswordHeader {\n    text-align: center;\n    margin-top: 20px;\n}\n.logo {\n    width: 320px;\n    height: 70px;\n    display: inline-block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"forgotPasswordGridWrapper": "ForgotPassword__forgotPasswordGridWrapper___GbDf7",
	"forgotPasswordBlock": "ForgotPassword__forgotPasswordBlock___OjjWc",
	"forgotPasswordCard": "ForgotPassword__forgotPasswordCard___nrrsQ",
	"forgotPasswordHeader": "ForgotPassword__forgotPasswordHeader___tSRFM",
	"logo": "ForgotPassword__logo___mSW_S"
};
export default ___CSS_LOADER_EXPORT___;
