// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n", "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerGrey": "rgb(232, 232, 232)",
	"black_100": "#000",
	"black_25": "rgba(0, 0, 0, 0.25)",
	"black_40": "rgba(0, 0, 0, 0.4)",
	"black_50": "rgba(0, 0, 0, 0.5)",
	"materialPrimaryLight": "#CFD8DC",
	"borderGrey": "#cecece",
	"errorColor": "rgb(244, 67, 54)",
	"primary1Color": "rgb(96, 125, 139)",
	"accent1Color": "#455A64"
};
export default ___CSS_LOADER_EXPORT___;
