"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _reactRedux = require("react-redux");
var _OpenGraph = _interopRequireDefault(require("./OpenGraph"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function generator() {
  const selfModule = this;
  const {
    selfSelector
  } = selfModule.getDependencies();
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const formValue = selfModule.formValueFromLocalState(selfState);
    const mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);
    const mappedProps = _extends({}, {
      id: selfModule.getID(),
      value: formValue
    }, ownProps, mixinFieldProps, selfState.props);
    return mappedProps;
  }
  return (0, _reactRedux.connect)(mapStateToProps)(_OpenGraph.default);
}