"use strict";

const onElementResize = require('element-resize-event');
const ELEMENT_RESIZE_ATTR_KEY = 'element__onresize';
module.exports = {
  //# == Utilities =============================================//
  _unbindBoundElementResize: function () {
    const boundComponentEl = this._boundComponentEl;
    if (boundComponentEl && boundComponentEl.__resizeListeners__ && boundComponentEl.__resizeTrigger__.contentDocument) {
      //console.log('[_bindElementResize()], unbinding onElementResize', boundComponentEl.attributes);
      boundComponentEl.removeAttribute(ELEMENT_RESIZE_ATTR_KEY);
      this._unbindElementResize(boundComponentEl);
      this._boundComponentEl = null;
    }
  },
  _unbindElementResize: function (componentEl) {
    //console.log('[_unbindElementResize()] componentEl:', componentEl.attributes);
    if (componentEl.__resizeListeners__ && componentEl.__resizeTrigger__.contentDocument) {
      onElementResize.unbind(componentEl);
    }
  },
  _bindElementResize: function (componentEl, callback) {
    if (!componentEl) return false;
    this._componentEl = componentEl;
    const boundComponentEl = this._boundComponentEl;
    const componentElChanged = boundComponentEl && boundComponentEl != componentEl;
    if (componentElChanged) {
      //console.log('[_bindElementResize()] componentEl changed');
      this._unbindElementResize(boundComponentEl);
    }

    //console.log('[_bindElementResize()], componentEl', componentEl.attributes)
    //# NOTE: there's an issue where if we logged the componentEl itself, it crashes IFF the devtools is open
    //# this seems to be an issue in conjunction to using the onElementResize module either in here or the Repeater since this exists inside a Repeater
    if (componentEl.getAttribute(ELEMENT_RESIZE_ATTR_KEY)) {
      //console.log('[_bindElementResize()] already bound to resize. componentEl:', componentEl);
      return false;
    }

    //# Doing something specific to element-resize-event module
    const sizeChangeCallback = () => {
      callback && callback();
    };
    onElementResize(componentEl, sizeChangeCallback);
    componentEl.setAttribute(ELEMENT_RESIZE_ATTR_KEY, true);
    //console.log('[_bindElementResize()], bound componentEl', componentEl)
    this._boundComponentEl = componentEl;
    return true;
  }
};