"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getDocgenPermissionItemForSelfModule;
const docgenComponentId = 'rubyComponentPluginContentDocGen';
function getDocgenPermissionItemForSelfModule(selfModule) {
  const docgenComponent = selfModule.getRoot().findDescendentByID(docgenComponentId);
  return docgenComponent ? {
    label: 'Document Download',
    id: 'contentDocGen',
    value: {
      keyword: 'content_doc_gen',
      ruby_client: 3,
      action: '*'
    }
  } : null;
}