"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
var _bioUpdatesMenuItems = _interopRequireDefault(require("./bioUpdatesMenuItems"));
var _globalDataMenuItems = _interopRequireDefault(require("./globalDataMenuItems"));
var _lookupMenuItem = _interopRequireDefault(require("./lookupMenuItem"));
var _structuredDataMenuItems = _interopRequireDefault(require("./structuredDataMenuItems"));
var _websitesMenuItems = _interopRequireDefault(require("./websitesMenuItems"));
var _contentReportsMenuItems = _interopRequireDefault(require("./contentReportsMenuItems"));
var _sweeperReportsMenuItems = _interopRequireDefault(require("./sweeperReportsMenuItems"));
var _documentDownloadsMenuItems = _interopRequireDefault(require("./documentDownloadsMenuItems"));
var _settingsMenuItems = _interopRequireDefault(require("./settingsMenuItems"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function getMenuForSelfModule(selfModule) {
  const bioUpdatesMenuItems = (0, _bioUpdatesMenuItems.default)(selfModule) || [];
  const globalDataMenuItems = (0, _globalDataMenuItems.default)(selfModule) || [];
  const lookupMenuItem = (0, _lookupMenuItem.default)(selfModule) || [];
  const structuredDataMenuItems = (0, _structuredDataMenuItems.default)(selfModule) || [];
  const websitesMenuItemsBySection = (0, _websitesMenuItems.default)(selfModule) || {};
  const contentReportsMenuItems = (0, _contentReportsMenuItems.default)(selfModule) || [];
  const sweeperReportsMenuItems = (0, _sweeperReportsMenuItems.default)(selfModule) || [];
  const documentDownloadsMenuItems = (0, _documentDownloadsMenuItems.default)(selfModule) || [];
  const settingsMenuItems = (0, _settingsMenuItems.default)(selfModule) || [];

  //# get page_redirect template id
  const pageRedirectTemplate = selfModule.getFormForTemplateKey('page_redirect');
  const modulesWithMenuItems = selfModule.getRoot().findDescendentsBy(node => node.hasOwnProperty('getMenuItemsBySection'));
  //# merge all menu items by section
  const additionalMenuItemsBySection = _lodash.default.reduce(modulesWithMenuItems, (collector, module) => {
    const menuItemsBySection = module.getMenuItemsBySection();
    _lodash.default.forEach(menuItemsBySection, (menuItemOrItems, section) => {
      collector[section] = collector[section].concat(menuItemOrItems);
    });
    return collector;
  }, {
    web_content: [],
    structured_data: [],
    experience_manager: [],
    proposals: [],
    admin: [],
    developer_tools: [],
    debugging_tools: []
  });
  const menuConfig = {
    //# root url, used for the header, not the menu

    route: '/' //# used to be '/app/dashboard', but let indexRouter handle it
    ,

    children: [{
      name: 'Content',
      id: 'content',
      children: [].concat(websitesMenuItemsBySection.web_content || []
      /*
      //# DEPRECATING 20210215 - we've removed the old javascript modules that powers this page, so this page no longer works starting in v12
      //# NOTE: the permission toggle is still available because we need to allow access to the old media
      , {
          name: 'Media Browser'
          , icon: 'material-ui/svgIcons/ImagePhotoLibrary'
          , permissions: [ { keyword: 'media_gallery', ruby_client: 3, action: '*' } ]
          , url: '/gallery/index.html'
      }*/, {
        name: 'Quick Find',
        icon: 'material-ui/svgIcons/ActionSearch',
        permissions: [{
          keyword: 'can_publish_content',
          ruby_client: 3,
          subsite: 1,
          action: "edit"
        }],
        route: '/app/content/en/quick-find'
      }, globalDataMenuItems, {
        name: 'Redirects',
        icon: 'material-ui/svgIcons/ActionSwapHoriz',
        permissions: [{
          action: 'get',
          model: 'content',
          template: _lodash.default.get(pageRedirectTemplate, 'id', 'page_redirect'),
          ruby_client: _lodash.default.get(pageRedirectTemplate, 'ruby_client_fk', 3),
          subsite: _lodash.default.first(_lodash.default.get(pageRedirectTemplate, 'ruby_subsite_fk', [1]))
        }],
        route: '/app/lookups/en/page_redirect/list'
      }, additionalMenuItemsBySection.web_content)
    }, {
      name: 'Structured Data',
      id: 'structured_data',
      children: structuredDataMenuItems.concat(additionalMenuItemsBySection.structured_data)
    }, {
      name: 'RubyLaw Experience',
      id: 'experience_manager',
      children: additionalMenuItemsBySection.experience_manager
    }, {
      name: 'RubyLaw Proposals',
      id: 'proposals',
      children: additionalMenuItemsBySection.proposals
    }, {
      name: 'Admin',
      id: 'admin',
      children: [{
        name: 'Users',
        icon: 'material-ui/svgIcons/SocialPersonOutline',
        permissions: [{
          model: 'user',
          action: 'get'
        }],
        route: '/app/admin/user/list'
      }, {
        name: 'Roles',
        icon: 'material-ui/svgIcons/ActionVerifiedUser',
        permissions: [{
          model: 'RubyRole',
          action: 'get'
        }],
        route: '/app/admin/role/list'
      }, {
        name: 'Usage Logs',
        icon: 'material-ui/svgIcons/ActionReceipt',
        route: '/app/admin/logs',
        permissions: [{
          model: 'changelog',
          ruby_client: 3,
          action: '*'
        }]
      }].concat(websitesMenuItemsBySection.admin || [], lookupMenuItem, {
        name: 'Sitemap XML',
        icon: 'material-ui/svgIcons/ActionCode',
        permissions: [{
          keyword: 'admin_sitemap_section',
          ruby_client: 3,
          action: '*'
        }],
        route: '/app/admin/sitemap-xml'
      }, {
        name: 'Share',
        icon: 'material-ui/svgIcons/SocialShare',
        permissions: [{
          keyword: 'admin_share_section',
          ruby_client: 3,
          action: '*'
        }],
        route: '/app/admin/share'
      }, bioUpdatesMenuItems, {
        name: 'Templates',
        permissions: [{
          model: 'Template',
          ruby_client: 3,
          action: 'get'
        }],
        icon: 'material-ui/svgIcons/AvLibraryBooks',
        route: '/app/admin/template/list'
      }, contentReportsMenuItems, sweeperReportsMenuItems, documentDownloadsMenuItems, settingsMenuItems, additionalMenuItemsBySection.admin)
    }, {
      name: 'Developer Tools',
      id: 'developer_tools',
      children: [{
        name: 'Optimization',
        icon: 'font-awesome/bolt',
        route: '/app/admin/optimization',
        permissions: [{
          keyword: 'dev_tools__optimization'
        }]
      }, {
        name: 'API Documentation',
        icon: 'material-ui/svgIcons/FileFolderOpen',
        url: '/app/api-docs/',
        newWindow: true,
        permissions: [{
          keyword: 'dev_tools'
        }, {
          model: 'changelog',
          ruby_client: 3,
          action: '*'
        }]
      }, {
        name: 'API Explorer',
        icon: 'material-ui/svgIcons/FileCloud',
        route: '/app/admin/api-explorer',
        permissions: [{
          keyword: 'dev_tools'
        }, {
          model: 'changelog',
          ruby_client: 3,
          action: '*'
        }]
      }, {
        name: 'API Logs',
        icon: 'material-ui/svgIcons/FileFileDownload',
        route: '/app/admin/api-logs',
        permissions: [{
          keyword: 'dev_tools'
        }, {
          model: 'changelog',
          ruby_client: 3,
          action: '*'
        }]
      }].concat(additionalMenuItemsBySection.developer_tools)
    }, {
      name: 'Debugging Tools',
      id: 'debugging_tools',
      children: [{
        name: 'Internal API Explorer',
        icon: 'material-ui/svgIcons/ActionExtension',
        route: '/app/admin/internal-api-explorer',
        permissions: [{
          keyword: 'debugging_tools'
        }, {
          model: 'changelog',
          ruby_client: 3,
          action: '*'
        }]
      }].concat(additionalMenuItemsBySection.debugging_tools)
    }]
  };
  return menuConfig;
}
var _default = getMenuForSelfModule;
/*[
    , {
        name: 'Attorneys'
        , icon: 'material-ui/svgIcons/SocialGroup'
        , permissions: [ { model: 'content', template: '1700', ruby_client: 3, subsite: 1, action: 'get' } ]
        , route: '/app/content/en/attorney/list'
    }
    , {
        name: 'Attorney - Alternate Bios'
        , icon: 'material-ui/svgIcons/ActionAssignmentInd'
        , permissions: [ { model: 'content', template: '1600', ruby_client: 3, subsite: 1, action: 'get' } ]
        , route: '/app/content/en/alternate_bio/list'
    }
    , {
        name: 'Blog Posts'
        , icon: 'material-ui/svgIcons/ContentFontDownload'
        , permissions: [ { model: 'content', template: '3500', ruby_client: 3, subsite: 1, action: 'get' } ]
        , route: '/app/content/en/blog_post/list'
    }
    , {
        name: 'Blogs'
        , icon: 'material-ui/svgIcons/ActionViewStream'
        , permissions: [ { model: 'content', template: '2100', ruby_client: 3, subsite: 1, action: 'get' } ]
        , route: '/app/content/en/blog/list'
    }
    , {
        name: 'Events'
        , icon: 'material-ui/svgIcons/MapsLocalActivity'
        , permissions: [ { model: 'content', template: '3900', ruby_client: 3, subsite: 1, action: 'get' } ]
        , route: '/app/content/en/event/list'
    }
    , {
        name: 'Industries'
        , icon: 'material-ui/svgIcons/HardwareMemory'
        , permissions: [ { model: 'content', template: '4100', ruby_client: 3, subsite: 1, action: 'get' } ]
        , route: '/app/content/en/industry/list'
    }
    , {
        name: 'Job Listings'
        , icon: 'material-ui/svgIcons/PlacesBusinessCenter'
        , permissions: [ { model: 'content', template: '3800', ruby_client: 3, subsite: 1, action: 'get' } ]
        , route: '/app/content/en/job_description/list'
    }
    , {
        name: 'News'
        , icon: 'material-ui/svgIcons/SocialPublic'
        , permissions: [ { model: 'content', template: '1800', ruby_client: 3, subsite: 1, action: 'get' } ]
        , route: '/app/content/en/news/list'
    }
    , {
        name: 'Offices'
        , icon: 'material-ui/svgIcons/SocialLocationCity'
        , permissions: [ { model: 'content', template: '2000', ruby_client: 3, subsite: 1, action: 'get' } ]
        , route: '/app/content/en/office/list'
    }
    , {
        name: 'Practices'
        , icon: 'material-ui/svgIcons/AvLibraryBooks'
        , permissions: [ { model: 'content', template: '1500', ruby_client: 3, subsite: 1, action: 'get' } ]
        , route: '/app/content/en/practice_area/list'
    }
    , {
        name: 'Publications'
        , icon: 'material-ui/svgIcons/MapsLayers'
        , permissions: [ { model: 'content', template: '3700', ruby_client: 3, subsite: 1, action: 'get' } ]
        , route: '/app/content/en/publications/list'
    }
    , {
        name: 'Matters'
        , icon: 'material-ui/svgIcons/SocialWhatshot'
        , permissions: [ {
            model: 'content'
            , template: mattersTemplateId
            , ruby_client: 3
            , subsite: 1
            , action: 'get'
        } ]
        , route: '/app/content/en/matter/list'
    }
]*/
exports.default = _default;