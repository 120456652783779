// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ScheduledStatus__wrapper___FHLjR {\n    display: flex;\n    flex-wrap: wrap;\n}\n", "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-scheduled-status/src/client/reactComponents/ScheduledStatus.cssModule"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;AACnB","sourcesContent":[".wrapper {\n    display: flex;\n    flex-wrap: wrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ScheduledStatus__wrapper___FHLjR"
};
export default ___CSS_LOADER_EXPORT___;
