"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getStructuredDataMenuItemsForSelfModule;
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const RubyWords = require('@rubyapps/ruby-words');
const inflection = RubyWords.inflection;

//# TODO: see if we can pass the icon component instead of the string
const structuredDataTemplateType = 'structured_data_template';
const experienceManagerComponentId = 'rubyComponentPluginExperienceManager';
const pluginProposalComponentId = 'rubyComponentPluginProposal';
function getStructuredDataMenuItemsForSelfModule(selfModule) {
  const experienceManagerComponent = selfModule.getRoot().findDescendentByID(experienceManagerComponentId);
  const pluginProposalComponent = selfModule.getRoot().findDescendentByID(pluginProposalComponentId);
  const templateKeysToOmit = ['global_data', 'rubyapps_settings'].concat(experienceManagerComponent ? ['matter', 'matter_client'] : [], pluginProposalComponent ? ['proposal', 'proposal_content'] : []);
  const forms = selfModule.getForms();
  let structuredDataForms = _lodash.default.map(_lodash.default.omit(forms, templateKeysToOmit), form => form).filter(form => form.templateType == structuredDataTemplateType);
  structuredDataForms.sort((a, b) => {
    const nameA = a.name.toUpperCase();
    const nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    } else if (nameA > nameB) {
      return 1;
    } else {
      return 0;
    }
  });
  const structuredDataMenuItems = structuredDataForms.map(form => ({
    name: inflection.pluralize(form.name),
    icon: form.icon || 'material-ui/svgIcons/ActionViewList',
    permissions: [{
      model: 'content',
      template: `${form.id}`,
      ruby_client: form.ruby_client_fk,
      subsite: 1 //# TODO
      ,
      action: 'get'
    }],
    route: `/app/content/en/${form.key}/list`
  }));
  return structuredDataMenuItems;
}