"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _lodash = _interopRequireDefault(require("lodash"));
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _Password = _interopRequireDefault(require("./Password"));
var _zxcvbn = _interopRequireDefault(require("zxcvbn"));
var _analyzer = require("../../common/analyzer");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function passwordStrengthIndicationAndSuggestions(passwordValue) {
  let analysis = (0, _zxcvbn.default)(passwordValue || '');
  let {
    score,
    feedback
  } = analysis;
  let {
    suggestions
  } = feedback;
  let passwordMeterResults = (0, _analyzer.analyze)(passwordValue);
  return _objectSpread({
    suggestions
  }, passwordMeterResults);
}
function generator() {
  const selfModule = this;
  const {
    selfSelector,
    currentUserSelector,
    pairedComponentAction
  } = selfModule.getDependencies();
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);
    const fieldObject = selfModule.props.key ? _lodash.default.pick(selfState.fields[selfModule.props.key], ['value', 'error']) : {};
    const passwordAnalysis = fieldObject.value && ownProps.showStrength ? passwordStrengthIndicationAndSuggestions(fieldObject.value) : {};
    const error = fieldObject.error && fieldObject.error.message ? fieldObject.error : passwordAnalysis.errors ? {
      message: {
        msg: [].concat(passwordAnalysis.score != -1 ? `Password Strength: ${passwordAnalysis.status}` : [], passwordAnalysis.errors),
        level: passwordAnalysis.score != -1 ? 'info' : 'error'
      }
    } : {};
    const mappedProps = _extends({
      showPassword: selfState.showPassword,
      pairedField: selfModule.props.pairedField
    }, ownProps, mixinFieldProps, _extends(fieldObject, {
      richErrorMessages: _lodash.default.compact([error.message]).concat(ownProps.showStrength && passwordAnalysis.suggestions ? {
        msg: passwordAnalysis.suggestions,
        level: 'info'
      } : [])
    }));
    return mappedProps;
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch),
      delegateRefreshParentErrors: selfModule.refreshParentErrors.bind(selfModule),
      pairedComponentAction: pairedComponentAction && (0, _redux.bindActionCreators)(pairedComponentAction.generators, dispatch)
    };
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_Password.default);
}