"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _MenuItem = _interopRequireDefault(require("./MenuItem"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function generator() {
  const selfModule = this;
  const {
    selfSelector,
    actionForMenuItem,
    iconClass
  } = selfModule.getDependencies();
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const props = selfModule.getProps(true);
    const mappedProps = _extends({
      iconClass
    }, ownProps, props, selfState);
    return mappedProps;
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch),
      delegateActions: (0, _redux.bindActionCreators)({
        actionForMenuItem
      }, dispatch)
    };
  }
  const ConnectedMenuItem = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_MenuItem.default);
  _extends(ConnectedMenuItem.propTypes, _MenuItem.default.propTypes);
  return ConnectedMenuItem;
}