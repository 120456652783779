"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var rubyLogger = require('@rubyapps/ruby-logger');
var logger = rubyLogger.getLogger('ruby-redux-manager:logger-middleware');
const loggerMiddleware = store => next => action => {
  logger.debug('dispatching action:', action.type, action.payload);
  console.info('dispatching action:', action.type, action.payload, typeof action == 'function' ? action : undefined);
  let result = next(action);
  logger.debug({
    'next state': store.getState()
  });
  return result;
};
var _default = loggerMiddleware;
exports.default = _default;