"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const styles = {
  TextField: {
    style: {
      display: 'block'
    }
  },
  LastTextField: {
    style: {
      display: 'block',
      marginBottom: 10
    }
  },
  RaisedButton: {
    style: {
      width: '100%',
      marginTop: 10,
      marginBottom: 10
    }
  },
  BackButton: {
    style: {
      position: 'absolute',
      top: 15,
      display: 'block',
      minWidth: 98
    }
  },
  InfoMessage: {
    style: {
      position: 'relative',
      top: '5px'
    }
  },
  Card: {
    style: {
      marginTop: 20,
      marginBottom: 20
    }
  },
  CardText: {
    style: {
      padding: '0px 16px 0px 16px',
      overflow: 'hidden'
    }
  }
};
var _default = styles;
exports.default = _default;