"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _lodash = _interopRequireDefault(require("lodash"));
var _FieldEditor = _interopRequireDefault(require("./FieldEditor"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const rubyAccessManager = require('@rubyapps/ruby-access-manager');
const fieldHelperMixin = require('../fieldHelperMixin/index');
const fieldHelperMixin__KEYS = Object.keys(fieldHelperMixin);
function generator() {
  const selfModule = this;
  const {
    selfSelector,
    codeDialogActions,
    frontendSettingsSelector,
    rubyClientFKSelector,
    currentUserSelector,
    formsSelector,
    getActiveRubyClientInfo
  } = selfModule.getDependencies();
  const boundSelfModule = _lodash.default.mapValues(_lodash.default.pick(selfModule, fieldHelperMixin__KEYS), fn => fn.bind(selfModule));
  let previousTemplateProps;
  let previousAllForms;
  function getTemplatePropsFromState(state) {
    const allForms = formsSelector(state);
    const isAllFormsSame = allForms == previousAllForms;
    if (isAllFormsSame) {
      return previousTemplateProps;
    }
    previousAllForms = allForms;
    const subtemplates = _lodash.default.pickBy(allForms, form => form.templateType == 'subtemplate');
    const sitemapTemplates = _lodash.default.pickBy(allForms, {
      templateType: 'sitemap_template'
    });
    const lookupTemplates = _lodash.default.pickBy(allForms, {
      templateType: 'lookup_template'
    });
    const sdTemplates = _lodash.default.chain(allForms).pickBy({
      templateType: 'structured_data_template'
    }).omitBy({
      key: 'global_data'
    }).omitBy({
      key: 'rubyapps_settings'
    }).value();
    const contentTemplateOptions = _lodash.default.chain({}).assign(sitemapTemplates, lookupTemplates, sdTemplates).values().map(form => {
      const {
        key,
        name
      } = form;
      return {
        value: key,
        primaryText: name
      };
    }).sortBy(['primaryText']).value();
    const newTemplateProps = {
      subtemplates,
      contentTemplateOptions
    };
    previousTemplateProps = newTemplateProps;
    return newTemplateProps;
  }
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const rubyClientFK = rubyClientFKSelector(state);
    const userObject = currentUserSelector(state);
    const frontendSettings = frontendSettingsSelector(state);
    const allAccessPerm = {
      keyword: 'admin_templates_section.locked_items',
      ruby_client: rubyClientFK,
      action: 'edit'
    };
    return _extends({
      fieldState: _lodash.default.get(selfState, ['fields', selfModule.props.key, 'value']),
      overrideLocked: rubyAccessManager.doesUserHavePermission(userObject, allAccessPerm),
      all_permissions: _lodash.default.get(frontendSettings, ['all_permissions']),
      rubyClientInfo: getActiveRubyClientInfo()
    }, getTemplatePropsFromState(state));
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch),
      delegateActions: {
        codeDialog: (0, _redux.bindActionCreators)(codeDialogActions, dispatch)
      }
      //# TODO: map selfModule fieldProperties methods
      ,
      selfModule: boundSelfModule
    };
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_FieldEditor.default);
}