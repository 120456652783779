"use strict";

var React = require('react');
var rubyComponent = require('@rubyapps/ruby-component');
var CONSTANTS = require('../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;
var reducer = require('./reducer');
var RCRubyClientFK = rubyComponent.createClass({
  componentName: componentName,
  reducer: reducer,
  CONSTANTS: CONSTANTS
});
module.exports = RCRubyClientFK;