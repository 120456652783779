"use strict";

//import {batchActions} from 'redux-batched-actions';

module.exports = function () {
  const id = this.getID();
  const TYPES = {
    RESET_STATE: `@ruby-app/${id}/RESET_STATE`
  };
  return {
    TYPES,
    generators: {
      resetState: function (state) {
        return {
          type: TYPES.RESET_STATE,
          payload: {
            state //# optional, defaults to using this.getInitialState()
          }
        };
      }
    }
  };
};