"use strict";

var _reactRouterRedux = require("react-router-redux");
const CONSTANTS = require('../../common/constants');
const path = require('path');
const TYPES = CONSTANTS.ROUTE_ACTION_TYPES;
const _ = require('lodash');
const queryString = require('query-string');
function formattedUrlForPayload(payload) {
  const {
    path = window.location.pathname,
    query,
    hash
  } = payload;
  const formattedQuery = query ? queryString.stringify(query) : undefined;
  const formattedHash = hash ? queryString.stringify(_.omit(hash, ['__original'])) : undefined;
  return `${path}${formattedQuery ? `?${formattedQuery}` : ''}${formattedHash ? `#${formattedHash}` : ''}`;
}

//# 2018103
//# NOTE: we should not use these actions in non route components.
//# we have this here for backwards compatibility with older components
module.exports = function () {
  const localTypes = {};
  return {
    TYPES: localTypes,
    generators: {
      /**
       *  @param {String} payload.path - path to navigate to
       *  @param {Object} payload.hash - hash object where key-value pairs mirror query params. So the formatted hash will look like: #param=value&param=value
       *  @param {boolean} options.ignoreChanges - skip checking for changes. Use this only when you know you've saved the page
       */
      navigateToPathWithOptions: function (payload, callback) {
        let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
        const selfModule = this;
        const {
          generators: actions
        } = selfModule.getAction();
        const confirmDialog = selfModule.getRubyComponentAtPath('.rubyComponentConfirmDialog');
        const confirmDialogActions = confirmDialog.getAction().generators;
        const activeRouteComponent = selfModule.getActiveRouteComponent();
        const canEdit = activeRouteComponent && activeRouteComponent.hasUnsavedChanges && activeRouteComponent.canEdit();
        const hasUnsavedChanges = activeRouteComponent && activeRouteComponent.hasUnsavedChanges && activeRouteComponent.hasUnsavedChanges();
        const wrappedCallback = function () {
          let count = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
          if (selfModule.getActiveRouteComponent() == undefined) {
            //# NOTE: not the best way to determine whether the route has changed 
            //# but the route is changed on LOCATION_CHANGE action
            //# which we have no control over with regards to providing additional parameters in the payload
            if (count < 3) {
              //# Arbitrary, try 5 times
              setTimeout(() => {
                wrappedCallback(count++);
              }, 200);
            }
          } else {
            callback && callback();
          }
        };
        return (dispatch, getState) => {
          const {
            ignoreChanges = false
          } = options;
          //# get active route component
          //# routeComponent && routeComponent.hasUnsavedChanges() 
          //# then show dialog
          //# Only perform this check if we're leaving the page
          //# it could be the case that we're navigating to the same path but changing hashes

          const isNavigatingToSamePath = !payload.path || payload.path == window.location.pathname;
          if (!ignoreChanges && !isNavigatingToSamePath && canEdit && hasUnsavedChanges) {
            dispatch(confirmDialogActions.openDialogWithOptions({
              title: 'You have unsaved changes',
              content: 'Are you sure you want to leave the page?',
              submitLabel: 'Save & continue',
              submitSuccessCallback: () => {
                activeRouteComponent.saveChanges_usingOptions({
                  successCallback: responseBody => {
                    dispatch((0, _reactRouterRedux.push)(formattedUrlForPayload(payload)));
                    callback && wrappedCallback();
                  }
                });
              },
              submitLabel_secondary: 'Continue',
              submitSuccessCallback_secondary: () => {
                dispatch((0, _reactRouterRedux.push)(formattedUrlForPayload(payload)));
                callback && wrappedCallback();
              }
            }));
          } else {
            dispatch((0, _reactRouterRedux.push)(formattedUrlForPayload(payload)));
            callback && wrappedCallback();
          }
        };
      },
      replacePathWithOptions: function (payload) {
        return (0, _reactRouterRedux.replace)(formattedUrlForPayload(payload));
      }

      //# DEPRECATING 20201027 - should always use the 'react-router-redux' actions. Let our middleware handle converting the url to routeParams appropriately
      ,
      setHashForActiveRouteComponent: function (hash) {
        const selfModule = this;
        return dispatch => {
          setImmediate(() => {
            const activeRouteComponent = selfModule.getActiveRouteComponent();
            dispatch(activeRouteComponent.getAction().generators.setHash(hash));
          });
        };
      }
    }
  };
};