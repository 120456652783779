"use strict";

var _redux = require("redux");
var _reactRedux = require("react-redux");
var _Notification = _interopRequireDefault(require("./Notification"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const rubyLogger = require('@rubyapps/ruby-logger');
const logger = rubyLogger.getLogger('ruby-component-notifications:reactComponents/Notification');
module.exports = function NotificationGenerator() {
  const selfModule = this;
  const dependencies = selfModule.getDependencies();
  const selfSelector = dependencies.selfSelector;
  const selfActions = selfModule.getAction().generators;
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    return _extends({}, selfState, {
      formatted_mailto_link: selfModule.createSupportLink()
    });
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfActions, dispatch)
    };
  }
  function mergeProps(stateProps, dispatchProps, ownProps) {
    //# need to handle delegation here because it needs the bound action functions
    const delegateClose = function (evt, modal) {
      dispatchProps.actions.closeAndResetNotification(modal);
    };
    return _extends({}, ownProps, stateProps, dispatchProps, {
      //# setup the delegate callbacks here which calls on dispatchProps.actions...
      delegateClose
    });
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps, mergeProps)(_Notification.default);
};