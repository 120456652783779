"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const defaultState = {
  isOpen: false,
  error: null
  //, error: null /# error: {message: <string>}just like the nested fields
};

module.exports = function (state, action) {
  const {
    TYPES
  } = this.getDependencies().selfAction;
  const {
    type,
    payload
  } = action;
  switch (type) {
    case TYPES.OPEN:
      return _extends({}, state, {
        isOpen: true
      });
    case TYPES.CLOSE:
      return _extends({}, state, {
        isOpen: false
      });
    case TYPES.RESET_STORE:
      return this.getInitialState();
    case TYPES.UPDATE_DISPLAY_VALUE_SPECS:
      return _extends({}, state, payload || {});
    default:
      return state;
  }
};