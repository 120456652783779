"use strict";

module.exports = {
  fieldSpec: {
    displayText: 'Dropdown',
    propertyKeys: ['key', 'endpoint_builder_for_dropdown', 'options', 'default_value_text', 'dropdown_data_type', 'help_text', 'permissions', 'private_field', 'namespace', 'pluginOptionsByModuleName_hidden', 'count_hidden']
  },
  propertySpecs: {
    count_hidden: {
      label: 'Count',
      type: 'number',
      propKey: 'count',
      hidden: true
      //# description: "The number of options to retrieve"
    }
  }
};