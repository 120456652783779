"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _lodash = _interopRequireDefault(require("lodash"));
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _TFA = _interopRequireDefault(require("./TFA"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function generator() {
  const selfModule = this;
  const {
    selfSelector,
    currentUserSelector,
    frontendSettingsSelector,
    usernameSelector
  } = selfModule.getDependencies();
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const frontendSettings = frontendSettingsSelector(state);
    const usernameState = usernameSelector(state);
    const mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);
    const mappedProps = _extends({
      frontendSettings,
      tfaLabel: _lodash.default.get(usernameState, ['fields', 'username', 'value'], null)
    }, ownProps, mixinFieldProps, ['enabled', 'registered', 'secret', 'token'].reduce((fields, key) => {
      fields[key] = _lodash.default.pick(selfState.fields[key], ['value', 'error']);
      return fields;
    }, {}));
    return mappedProps;
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
    };
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_TFA.default);
}