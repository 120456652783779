"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
/* jshint -W138 */

const actionTypes = require('./action').TYPES;
module.exports = function (state, action) {
  switch (action.type) {
    case actionTypes.OVERRIDE_FORM_PROPS:
      console.warn('[DEPRECATED] 20180927 - should only be used to override client-side templates like user and RubyRole, but we should not be doing that in general');
      const overrides = action.payload.config;
      const newState = JSON.parse(JSON.stringify(state));
      const fieldsByTemplateKey = getFieldsByTemplateKey_fromTemplates(newState);
      Object.keys(overrides || {}).forEach(templateKey => {
        const fieldsToOverrideByKey = action.payload.config[templateKey];
        Object.keys(fieldsToOverrideByKey).forEach(fieldKey => {
          const fieldToOverride = _.find(fieldsByTemplateKey[templateKey], field => {
            return field.key === fieldKey;
          });
          _extends(fieldToOverride, overrides[templateKey][fieldKey]);
        });
      });
      return newState;
    case actionTypes.SET_TEMPLATE:
      return _extends({}, state, {
        [action.payload.key]: action.payload.data
      });
    case actionTypes.SET_TEMPLATES:
      return _extends({}, state, action.payload.templates);
    default:
      return state;
  }
};
function getFieldsByTemplateKey_fromTemplates(templates) {
  let fieldsByTemplateKey = {};
  Object.keys(templates).forEach(templateKey => {
    const template = templates[templateKey];
    const flattenedForm = flatten_object_withKey(template.form, 'children');
    fieldsByTemplateKey[templateKey] = flattenedForm;
  });
  return fieldsByTemplateKey;
}
function flatten_object_withKey(object, key) {
  if (Array.isArray(object[key])) {
    return (object[key] || []).reduce((result, curr) => {
      return result.concat(flatten_object_withKey(curr, key));
    }, []);
  } else {
    return object;
  }
}