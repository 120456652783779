"use strict";

var _moment = _interopRequireDefault(require("moment"));
var _react = _interopRequireDefault(require("react"));
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _queryString = _interopRequireDefault(require("query-string"));
var _lodash = _interopRequireDefault(require("lodash"));
var _ContentCompareDialog = _interopRequireDefault(require("./ContentCompareDialog"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
var rubyLogger = require('@rubyapps/ruby-logger');
var logger = rubyLogger.getLogger('ruby-component-content-compare:reactComponents/ContentCompareDialog');
var rubyWords = require('@rubyapps/ruby-words');
var rubyNotificationsComponent = require('@rubyapps/ruby-component-notifications');
var ReactDOM = require('react-dom');
const routeParser = require('route-parser');
const RubyComponentEditPage__CONSTANTS = require('@rubyapps/ruby-component-material-edit-page/src/common/constants');
const rubyAccessManager = require('@rubyapps/ruby-access-manager');
var PropTypes = _react.default.PropTypes;
var action = require('../action');
var CONSTANTS = require('../../common/constants');
var componentName = CONSTANTS.COMPONENT_NAME;
function flattenObject() {
  let o = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _extends({}, ...function _flatten(o, keypath) {
    return [].concat(...Object.keys(o).map(k => {
      const currentKeypath = `${keypath}.${k}`;
      const currentValue = o[k];
      return _lodash.default.isObject(currentValue) ? _flatten(currentValue, currentKeypath) : {
        [currentKeypath]: currentValue
      };
    }));
  }(o, ''));
}
function shareMailtoGenerator(selfModule, state, url) {
  const dependencies = selfModule.getDependencies();
  const {
    selfSelector,
    frontendSettingsSelector,
    currentUserSelector,
    formsSelector
  } = dependencies;
  const currentUserState = currentUserSelector(state);
  const forms = formsSelector(state);
  const {
    username,
    first_name,
    last_name
  } = currentUserState || {};
  const {
    CLIENT_NAME: clientName,
    RUBY_BRANDING: rubyBranding,
    "WWW.INIT.SSL_ONLY": sslIsEnabled
  } = frontendSettingsSelector(state) || {};
  const selfState = selfSelector(state);
  const {
    pageName,
    templateKey
  } = selfState;
  const resultURL = _lodash.default.get(selfState, 'result.url', '');
  const hostname = window.location.hostname;
  const httpProtocol = sslIsEnabled ? 'https' : 'http';
  const template = forms[templateKey];
  const isMultilingual = _lodash.default.get(template, 'isMultilingual', false);
  const getPreviewQuery_fromFullURL = _lodash.default.flowRight(pickedArgs => _queryString.default.stringify(pickedArgs), allArgs => _lodash.default.pick(allArgs, [].concat(isMultilingual ? 'lang' : [])), rawQuery => _queryString.default.parse(rawQuery), fullUrl => fullUrl.substring(fullUrl.indexOf('?')));
  const resultQuery = resultURL && getPreviewQuery_fromFullURL(resultURL);
  const shareURL = hostname && resultURL && `${httpProtocol}://${hostname}${resultURL.substring(0, resultURL.indexOf('?'))}${resultQuery ? '?' + resultQuery : ''}`;
  if (!shareURL) {
    return;
  }
  const emailSubjectText = `${clientName} Website: ${first_name} ${last_name} has shared a webpage update with you`;
  const emailBodyText = `${first_name} ${last_name} has made an update for you to preview in ${rubyBranding}. Please note that this update may not yet be published on the live website:

${pageName ? `${pageName}\n` : ''}
${shareURL}

This is a secure link that will expire in ${_moment.default.duration(selfModule.props.previewDuration, 'seconds').humanize()}.

Thank you.`;
  return 'mailto:?subject=' + encodeURIComponent(emailSubjectText) + '&body=' + encodeURIComponent(emailBodyText);
}
function delegateShareGenerator(selfModule, state, url) {
  const mailtoString = shareMailtoGenerator(selfModule, state, url);
  return mailtoString && function () {
    //# TODO: need to somehow let the beforeunload callback know that the requested location is sound
    window.__requestedLocation__ = mailtoString;
    window.location = mailtoString;
  };
}
module.exports = function contentCompareDialogGenerator() {
  var selfModule = this;
  var selfProps = this.props;

  //# Dependencies
  const dependencies = selfModule.getDependencies();
  const {
    editPageComponent,
    editPageSelector,
    selfSelector,
    selfActions,
    frontendSettingsSelector,
    formsSelector,
    currentUserSelector
  } = dependencies;

  //# Constants
  var editPageRouteTemplate = editPageComponent.getRoutePath();

  //# Routes
  var editPageRouteParser = new routeParser(editPageRouteTemplate);
  function mapStateToProps(state) {
    const selfState = selfSelector(state);
    const {
      languages = []
    } = frontendSettingsSelector(state);
    const currentUserState = currentUserSelector(state);
    const forms = formsSelector(state);
    const canApproveContent = selfModule.canUserPublish();
    const {
      rubyClientUrl,
      subsiteUrl,
      templateKey,
      id: pageID,
      isLoading
    } = selfState;
    const formData = _lodash.default.get(selfState, ['options', 'formData'], _lodash.default.get(selfState, ['options', 'compareURLData', 'updatedData'], {}));
    const template = forms[templateKey];
    const rootModule = selfModule.getRoot();
    const editURLSpec = _lodash.default.get(selfState, 'options.editURLSpec') || (pageID && templateKey && subsiteUrl ? {
      path: rootModule.getBestUrlForComponentName_fromModule_withParams(RubyComponentEditPage__CONSTANTS.COMPONENT_NAME, selfModule, {
        rubyClient: rubyClientUrl,
        subsite: subsiteUrl,
        template: templateKey,
        id: pageID,
        action: 'edit'
      })
    } : null);
    const delegateShare = delegateShareGenerator(selfModule, state);

    //# handle disable language options
    const {
      contentForLanguages = {}
    } = formData;
    const languages__withAppropriateOptionsDisabled = languages.map(languageOption => {
      if (languageOption.isDefault) {
        return languageOption;
      }
      const formDataForLanguage = _lodash.default.get(contentForLanguages, [languageOption.value], {});
      const flattenedFormData = flattenObject(formDataForLanguage);
      const descendentsHaveValues = _lodash.default.reduce(flattenedFormData, (collector, value, key) => {
        if (collector) {
          return collector;
        }
        if (!(_lodash.default.isNil(value) || value == "")) {
          return true;
        }
        return collector;
      }, false);
      if (!descendentsHaveValues) {
        return _extends({
          disabled: true
        }, languageOption);
      } else {
        return languageOption;
      }
    });
    var mappedProps = _extends({
      templateKey: templateKey,
      id: pageID,
      open: selfState.isOpen,
      previewPage: selfState.previewPage,
      result: selfState.result,
      basis: selfState.basis,
      diff: selfState.diff,
      tableDiff: selfState.tableDiff,
      editURLSpec,
      showContentApprovalActions: selfState.showContentApprovalActions //# TODO: DEPRECATED remove once we can remove legacy content-approval
      ,
      additionalHeaderElements: selfState.additionalHeaderElements,
      showEditAction: selfState.showEditAction,
      canApproveContent,
      title: selfState.title,
      isLoading,
      languages: languages__withAppropriateOptionsDisabled,
      isMultilingual: _lodash.default.get(template, 'isMultilingual', false),
      selectedLanguage: selfState.selectedLanguage,
      delegateShare
    });
    return mappedProps;
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfActions, dispatch),
      delegateReject: function (evt) {
        dispatch(delegateReject);
      },
      delegateApprove: function (evt) {
        dispatch(delegateApprove);
      }
    };
  }
  function mergeProps(stateProps, dispatchProps, ownProps) {
    //# need to handle delegation here because it needs the bound action functions
    var currentURL = location.pathname;
    return _extends({}, ownProps, stateProps, dispatchProps, {
      delegateEdit: function (evt) {
        //logger.debug(evt, 'Edit button clicked 2');
        if (currentURL == stateProps.editURLSpec.path) {
          dispatchProps.actions.closeAndReset();
        } else {
          dispatchProps.actions.navigateToPathWithOptions(stateProps.editURLSpec);
          dispatchProps.actions.closeAndReset();
        }
      },
      delegateClose: function (evt) {
        dispatchProps.actions.closeAndReset();
      },
      delegateLanguageChange: dispatchProps.actions.setSelectedLanguage
    });
  }

  //# TODO: REMOVE once we can get rid of the legacy content approval plugin (or migrate the approve/reject dialog logic for the review dialog)
  //# DEPRECATED 20201014
  function delegateReject(dispatch, getState) {
    const state = getState();
    const selfState = selfSelector(state);
    const {
      id,
      delegateActions,
      editorName,
      options,
      templateKey
    } = selfState;
    const afterUpdateCallback = _lodash.default.get(options, 'afterUpdateCallback');
    dispatch(selfActions.closeAndReset());
    //# then display reject modal
    dispatch(delegateActions.openRejectModal_usingOptions({
      id: id,
      templateKey,
      defaultFields: {
        to: {
          value: editorName,
          visible: true,
          disabled: true
        }
      },
      successCallback: (err, response) => {
        let contentDisplayString = rubyWords.quote(_lodash.default.get(response, 'body.draft_name' //# note great but need to check for draft or approval
        , _lodash.default.get(response, 'body.approval_name')));
        afterUpdateCallback && afterUpdateCallback(err, response);
        selfModule.pushNotification({
          message: contentDisplayString ? `Changes to ${contentDisplayString} were rejected.` : 'The approval request was rejected.',
          type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.INFO
        });
      },
      errorCallback: err => dispatch(genericContentApprovalErrorHandler)
    }));
  }

  //# TODO: REMOVE
  //# DEPRECATED 20201014
  function delegateApprove(dispatch, getState) {
    const state = getState();
    const selfState = selfSelector(state);
    const {
      id,
      delegateActions,
      editorName,
      options,
      templateKey
    } = selfState;
    const afterUpdateCallback = _lodash.default.get(options, 'afterUpdateCallback');
    dispatch(selfActions.closeAndReset());
    //# then display approve modal
    dispatch(delegateActions.openApproveModal_usingOptions({
      id: id,
      templateKey: templateKey,
      defaultFields: {
        to: {
          value: editorName,
          visible: true,
          disabled: true
        }
      },
      successCallback: (err, response) => {
        let contentDisplayString = rubyWords.quote(_lodash.default.get(response, 'body.draft_name' //# note great but need to check for draft or approval
        , _lodash.default.get(response, 'body.approval_name')));
        selfModule.pushNotification({
          message: contentDisplayString ? `Changes to ${contentDisplayString} were approved.` : 'The approval request was approved.',
          type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.SUCCESS
        });
        afterUpdateCallback && afterUpdateCallback(err, response);
      },
      errorCallback: () => dispatch(genericContentApprovalErrorHandler)
    }));
  }
  function genericContentApprovalErrorHandler(error) {
    return dispatch => {
      let title = 'Content Approval Request Update Error',
        message = 'An unexpected error was encountered while updating this request. ' + 'Please try again later or contact Support.';
      if (error.status === 401) {
        title = 'Authorization Error';
        message = 'You are not authorized to update approval requests. ' + 'Try logging back in or contact Support.';
      }
      dispatch(selfModule.showNotificationModal({
        title,
        message
      }));
    };
  }

  //return selfModule.connect(mapStateToProps, mapDispatchToProps, mergeProps)(ContentCompareDialog);
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps, mergeProps)(_ContentCompareDialog.default);
};