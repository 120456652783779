"use strict";

var _react = _interopRequireDefault(require("react"));
var _index = _interopRequireDefault(require("../local_modules/ruby-model-mixin-saml/src/client/index.js"));
var CONSTANTS = _interopRequireWildcard(require("../common/constants"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const RubyComponent = require('@rubyapps/ruby-component');
const RCPluginSso = RubyComponent.createClass({
  componentName: CONSTANTS.COMPONENT_NAME,
  CONSTANTS,
  getReactClass: function () {
    return _react.default.createClass({
      render: function () {
        return this.props.children;
      }
    });
  },
  children: function () {
    return [RubyComponent.createElement(_index.default)];
  }
});
module.exports = RCPluginSso;