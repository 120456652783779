"use strict";

const _ = require('lodash');
module.exports = {
  doesComponentHaveMixin: (Component, needleMixin) => {
    const mixinName = _.isString(needleMixin) ? needleMixin : needleMixin.mixinName;
    const mixins = Component._flattenedMixins || Component.mixins;
    return mixins.reduce((collector, mixin) => {
      if (collector) {
        return collector;
      }
      return mixin.mixinName == mixinName;
    }, false);
  }

  //# get the "super" version of the method, eg. if you called superForMethod(node, "formValueToLocalState"), 
  //# it'll get the right-most method in the liste of flattened mixins
  ,
  superMixinContaining: function (Component, propertyKey, fromValue) {
    const mixins = Component._flattenedMixins || Component.mixins;
    const filteredMixins = mixins.filter(mixin => mixin.hasOwnProperty(propertyKey) && mixin[propertyKey] != fromValue);
    return _.last(filteredMixins);
  }
};