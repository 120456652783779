"use strict";

function typesFromID(id) {
  const TYPES = {
    REPLACE_REDUX_STATE: `@@ruby-app/${id}/REPLACE_REDUX_STATE`
  };
  return TYPES;
}
const generators = {
  replaceReduxState: function (reduxState) {
    const TYPES = this.getAction().TYPES;
    return {
      type: TYPES.REPLACE_REDUX_STATE,
      payload: {
        reduxState
      }
    };
  }
};
module.exports = function () {
  const TYPES = typesFromID(this.getID());
  return {
    TYPES,
    generators
  };
};