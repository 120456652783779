"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
const Promise = require('bluebird');
const rubyLogger = require('@rubyapps/ruby-logger');
const logger = rubyLogger.getLogger('ruby-component-forms:client:index');
const React = require('react');
const RubyComponent = require('@rubyapps/ruby-component');
const PropTypes = RubyComponent.PropTypes;
const memoize = require('memoizee');
const {
  defaultMemoizeeOptions
} = require('@rubyapps/ruby-memoize');
const jsonselect = require('JSONSelect');
const headerFormSelector = jsonselect.compile('object:has(:root > .componentName:val("Header"))');
const tabFormSelector = jsonselect.compile('object:has(:root > .componentName:val("Tab"))');
require('deepdash')(_lodash.default);
const rubyComponent = require('@rubyapps/ruby-component');
const RubyComponentPluginTemplateEditor__CONSTANTS = require('@rubyapps/ruby-component-plugin-template-editor/src/common/constants');
const RubyComponentConfirmDialog__CONSTANTS = require('@rubyapps/ruby-component-confirm-dialog/src/common/constants');
const RubyComponentFieldRepeater__CONSTANTS = require('@rubyapps/ruby-component-field-repeater/src/common/constants');
const currentUserMixin = require('@rubyapps/ruby-component-mixin-current-user');
const formsContentMixin = require('@rubyapps/ruby-component-mixin-forms-content');
const eventEmitterMixin = require('@rubyapps/ruby-component-mixin-event-emitter');
const fieldSpecMixin = require('./fieldSpecMixin');
const CONSTANTS = require('../common/constants');
const componentName = CONSTANTS.COMPONENT_NAME;
const action = require('./action');
const reducer = require('./reducer');
const middleware = require('./middleware');
const {
  ADMIN_TEMPLATE,
  TOP_LEVEL_TEMPLATE_TYPES
} = require('@rubyapps/ruby-component-plugin-template-editor/src/common/constants');
const templates = {};
const defaultState = _objectSpread({}, templates);
function parseFieldKey(key) {
  let parts = key.match(/^(.+?)(\d+)$/);
  let fieldPrefix = key;
  let fieldNumber = 1;
  if (!/^top_column_\d+/.test(key) && parts) {
    fieldPrefix = parts[1];
    fieldNumber = parseInt(parts[2], 10);
  }
  return {
    fieldPrefix,
    fieldNumber
  };
}
;
function provideRangeOfFields(name, numberOfFields) {
  return `${name} (up to ${numberOfFields})`;
}
;
function reduceTree(tree, reducer, getChildren, collection) {
  return _lodash.default.reduce(tree, (coll, value, keyOrIdx) => {
    const newColl = reducer(coll, value, keyOrIdx);
    return reduceTree(getChildren(newColl, value, keyOrIdx), reducer, getChildren, newColl);
  }, collection);
}
const RCForms = rubyComponent.createClass({
  componentName: componentName,
  mixins: [currentUserMixin, formsContentMixin, eventEmitterMixin, fieldSpecMixin],
  action: action,
  reducer: reducer,
  middleware: middleware,
  propTypes: {
    confirmDialogID: PropTypes.string
  },
  getDefaultProps: () => ({
    pluginTemplateEditorId: RubyComponentPluginTemplateEditor__CONSTANTS.COMPONENT_NAME,
    confirmDialogID: RubyComponentConfirmDialog__CONSTANTS.COMPONENT_NAME
  }),
  getInitialState: () => defaultState,
  dependencies: function () {
    const root = this.getRoot();
    const selfSelector = this.getDefaultSelector();
    const selfAction = this.getAction();
    const pluginTemplateEditorComponent = root.findDescendentByID(this.props.pluginTemplateEditorId);
    return {
      selfSelector,
      selfAction,
      pluginTemplateEditorComponent
    };
  },
  overrideProps_withConfig: function (config, dispatch) {
    dispatch(this.getAction().generators.overrideProps_withConfig(config));
  },
  onReduxInit: function (dispatch) {
    dispatch(this.getAction().generators.pollTemplates());
  },
  CONSTANTS: CONSTANTS

  //== UTILITIES =========================================================//
  ,
  setTemplate_atKey: function (template, key) {
    return this.getStore().dispatch(this.getAction().generators.setTemplate_atKey(...arguments));
  },
  getTemplateForKey: function (key, templatesState) {
    templatesState = templatesState || this.getState();
    const selectedTemplate = _lodash.default.get(templatesState, [key]);
    return selectedTemplate;
  },
  isValidTopLevelTemplate: function (template) {
    return _lodash.default.includes(TOP_LEVEL_TEMPLATE_TYPES, template.templateType);
  },
  augmentedTemplate: function (template, callingModule) {
    return template;
  }

  //# NOTE: cannot just memoize this, we need to memoize each call to 'augmentedTemplate()' 
  //# ... each template augmentor cares about a different set of states
  //# we are however, allowing temporary memoization
  //# NOTE: templateState would change if the template has been updated
  ,
  getAugmentedTemplateForKey: memoize(function (key, templateState, callingModule) {
    //return this.getTemplateForKey(key, templateState);
    const templateToAugment = this.getTemplateForKey(key, templateState);
    if (!templateToAugment) {
      return templateToAugment;
    }
    const mixins = this.getMixins();
    const augmentedTemplateFns = [this.augmentedTemplate].concat(mixins.filter(mixin => mixin.hasOwnProperty('augmentedTemplate')).map(mixin => mixin.augmentedTemplate));
    const clonedTemplateToAugment = _lodash.default.cloneDeep(templateToAugment);
    const augmentedTemplate = _lodash.default.reduce(augmentedTemplateFns, (result, augmentor) => {
      return augmentor.call(this, result, callingModule);
    }, clonedTemplateToAugment);

    //# add template key to the form component so we have access to that info in form props
    augmentedTemplate.form.template_keyword = key;
    logger.debug('[getAugmentedTemplateForKey()]', key, augmentedTemplate);
    return augmentedTemplate;
  }, _extends({}, defaultMemoizeeOptions, {
    profileName: 'getAugmentedTemplateForKey',
    maxAge: 1000 /*ms*/ * 60 /*sec*/ * 2 /*minutes*/
  })),

  promisedAugmentedTemplateForKey: function (key, templateState, callingModule) {
    if (templateState) {
      return Promise.resolve(this.getAugmentedTemplateForKey(key, templateState, callingModule));
    }
    return new Promise(resolve => {
      this.once(`state:${key}`, () => {
        resolve(this.getAugmentedTemplateForKey(key, templateState, callingModule));
      }, {
        preempt: true
      });
    });
  },
  getActiveTemplateKey: function () {
    const selfModule = this;
    const activeRouteComponent = selfModule.getActiveRouteComponent();
    const currentTemplateKey = _lodash.default.result(activeRouteComponent, 'getTemplateKey');
    return currentTemplateKey;
  },
  activeTemplatesAreDifferent_fromPrevState: function (previousTemplates) {
    const selfModule = this;
    const newTemplates = selfModule.getState();
    const activeTemplateKey = selfModule.getActiveTemplateKey();
    const activeRouteComponent = selfModule.getActiveRouteComponent();
    if (!activeRouteComponent) {
      return false;
    }
    const profileFields = activeRouteComponent.findDescendentsBy(el => el.componentName === RubyComponentFieldRepeater__CONSTANTS.COMPONENT_NAME && _lodash.default.result(el, 'hasRemoteOptions'));
    const activeProfileModuleTypes = _lodash.default.flowRight(_lodash.default.uniq, values => values.map(value => value.type), _lodash.default.flattenDeep, fields => fields.map(field => [].concat(_lodash.default.flatten(_lodash.default.values(field.formValue())) //# selected profiles
    , _lodash.default.result(field, 'getState.options') //# retrieved profiles
    )))(profileFields);
    const activeTemplateKeys = [].concat(activeTemplateKey || [], activeProfileModuleTypes);
    return activeTemplateKeys.length > 0 && _lodash.default.some(activeTemplateKeys, templateKey => !_lodash.default.isEqual(selfModule.preparedTemplateForComparison(newTemplates[templateKey]), selfModule.preparedTemplateForComparison(previousTemplates[templateKey])));
  },
  openTemplateChangedDialog_forTemplate: function (template) {
    const selfModule = this;
    const rootComponent = selfModule.getRoot();
    const confirmDialogComponent = rootComponent.findDescendentByID(selfModule.props.confirmDialogID);
    const confirmDialogActions = confirmDialogComponent.getAction().generators;
    const dispatch = selfModule.getStore().dispatch;
    template && dispatch(confirmDialogActions.openDialogWithOptions({
      title: `New Version Of The Current Template Detected`,
      content: `The ${template.name} template has been modified.` + ' To ensure the integrity of your data, please refresh the current page.' + ' Attempting to save your current work may fail if fields have been removed or added to this template.',
      submitLabel: 'Refresh',
      submitSuccessCallback: response => {
        window.location.reload();
      }
    }));
  },
  preparedTemplateForComparison: function (template) {
    return _lodash.default.omit(template, ['created_timestamp', 'hiddenValues', '__expandedWithTemplatesMap']);
  },
  availableFieldsInTemplateForEditing: function (template) {
    const headerAndFirstTab = headerFormSelector.match(template).concat(_lodash.default.first(tabFormSelector.match(template)) || []);
    let fieldsByPrefix = reduceTree(
    //[ template.form ], // the tree
    headerAndFirstTab,
    // the reducer function
    (map, field, fieldIdx) => {
      // Only include fields that have a key and label
      // (and they are not an info field, hidden, or namespace)
      if (field.key && field.label && field.componentName != 'Hidden' && field.componentName != 'Info' && field.componentName != 'NamespaceSelector') {
        let {
          fieldPrefix,
          fieldNumber
        } = parseFieldKey(field.key);
        const mappedFields = map[fieldPrefix] ? map[fieldPrefix] : [];
        return _lodash.default.assign({}, map, {
          [fieldPrefix]: mappedFields.concat({
            fieldIdx,
            field,
            fieldNumber
          })
        });
      } else {
        return map;
      }
    },
    // the get children function
    (map, field, fieldIdx) => {
      // Only return children, grandchildren, etc
      // of the first tab
      const isArray = _lodash.default.isArray(field.children);
      const hasChildren = isArray && _lodash.default.size(field.children) > 0;
      return hasChildren ? field.children : [];
    }, {} // the initial value
    );

    let availableFields = Object.keys(fieldsByPrefix).reduce((list, prefix) => {
      let fields = fieldsByPrefix[prefix];
      if (fields.length === 1) {
        list.push(fields[0].field.label);
      } else if (fields.length > 1) {
        let numberOfFields = fields.length;
        if (fields[0].fieldNumber == 1 && fields[numberOfFields - 1].fieldNumber == numberOfFields) {
          list.push(provideRangeOfFields(fields[0].field.label, numberOfFields));
        } else {
          fields.forEach(field => {
            list.push(field.field.label);
          });
        }
      }
      return list;
    }, []);
    return availableFields;
  },
  templatesForActiveRouteComponent: function () {
    const templates = this.getState();
    return this.filteredTemplatesForActiveRouteComponent(_lodash.default.map(templates, template => template));
  }

  //# Template filtering
  ,
  filteredTemplatesForActiveRouteComponent: function (templates) {
    const activeRouteComponent = rubyApp.getActiveRouteComponent();
    if (activeRouteComponent == undefined) {
      return [];
    } else if (activeRouteComponent.getActiveRubyClientInfo == undefined) {
      console.warn(activeRouteComponent.getID(), 'does not have the accessMixin, so you cannot call on getActiveRubyClientInfo()');
      return [];
    }
    const {
      routeParams
    } = activeRouteComponent.getState();
    const rubyClientInfo = activeRouteComponent.getActiveRubyClientInfo();
    const {
      subsite: currentSubsite
    } = rubyClientInfo;
    const {
      template: currentTemplate
    } = routeParams;
    const filteredTemplates = _lodash.default.reduce(templates, (collector, template) => {
      const {
        key: templateKey,
        ruby_subsite_fk
      } = template;
      const isSameAsCurrentTemplate = templateKey === currentTemplate;
      const subsiteMatches = ruby_subsite_fk === currentSubsite || Array.isArray(ruby_subsite_fk) && _lodash.default.includes(ruby_subsite_fk, currentSubsite) || ruby_subsite_fk == null;
      if (subsiteMatches && !isSameAsCurrentTemplate) {
        collector.push(template);
      }
      return collector;
    }, []);
    return filteredTemplates;
  }
  //# canMergeContent_withTemplateKey is being defined here
  //*  but we might want to include it using registerMixin from the proposal plugin
  ,
  canMergeContent_withTemplateKey: function (templateKey) {
    const templates = this.getState();
    return _lodash.default.get(templates, [templateKey, 'allowContentMerge'], false);
  },
  isResolved: function () {
    return !!this._retrievedTemplates;
  },
  pickFields_inTemplate_byPicker_withOptions: function (template, picker, suppliedOptions) {
    const defaultOptions = {
      eachDeepOptions: {
        checkCircular: true
      },
      baseFieldPicker: node => node && node.hasOwnProperty('componentName')
    };
    const options = _lodash.default.defaults(suppliedOptions, defaultOptions);
    let pickedFields = [];
    _lodash.default.eachDeep(template, function (value) {
      if (value && rubyComponent.isComponent(value)) {
        //# don't try to iterate over children of a ruby-component; you'll get a stack overflow
        return false;
      }
      if (options.baseFieldPicker(...arguments) && picker(...arguments)) {
        pickedFields.push(value);
      } else {
        pickedFields;
      }
    }, [], options.eachDeepOptions);
    return pickedFields;
  }
});
module.exports = RCForms;