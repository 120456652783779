// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles__closeButton___GhC_P {\n    background: none;\n    border: none;\n    cursor: pointer;\n    outline: none;\n    position: absolute;\n    top: -40px;\n    right: 0px;\n    vertical-align: bottom;\n    z-index: 1;\n    height: 40px;\n    margin-right: -10px;\n    padding: 10px;\n    width: 40px;\n    fill: white;\n}\n", "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-plugin-media-manager/src/local_modules/ruby-component-field-media-preview/src/client/reactComponents/styles.cssModule"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,aAAa;IACb,kBAAkB;IAClB,UAAU;IACV,UAAU;IACV,sBAAsB;IACtB,UAAU;IACV,YAAY;IACZ,mBAAmB;IACnB,aAAa;IACb,WAAW;IACX,WAAW;AACf","sourcesContent":[".closeButton {\n    background: none;\n    border: none;\n    cursor: pointer;\n    outline: none;\n    position: absolute;\n    top: -40px;\n    right: 0px;\n    vertical-align: bottom;\n    z-index: 1;\n    height: 40px;\n    margin-right: -10px;\n    padding: 10px;\n    width: 40px;\n    fill: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"closeButton": "styles__closeButton___GhC_P"
};
export default ___CSS_LOADER_EXPORT___;
