"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.flatten = flatten;
exports.getPrunedSitemap_fromSitemap = getPrunedSitemap_fromSitemap;
exports.pathCalculator = pathCalculator;
exports.unflatten = unflatten;
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
//var rubyLogger = require('@rubyapps/ruby-logger');
//var logger = rubyLogger.getLogger('ruby-sitemap:sitemapData');
const DEFAULT_ID_KEY = 'id';
const DEFAULT_PARENT_KEY = 'parent_id';
const DEFAULT_CHILDREN_KEY = 'children';
const MAX_PATH_SIZE = 10; // cap hierarchy size off @ 10 - prevent cycles from breaking stuff

/* Implementation of unflatten
 * is based on http://stackoverflow.com/a/22072374/3326617
 *
 * modifications:
 * - options: parentKey
 * - sort by display_order
 */
function unflatten(list, parent) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const idKey = options.idKey || DEFAULT_ID_KEY;
  const parentKey = options.parentKey || DEFAULT_PARENT_KEY;
  const childrenKey = options.childrenKey || DEFAULT_CHILDREN_KEY;

  // not children of parent but descendants
  const indirectDescendants = _lodash.default.filter(list, page => !parent(page));

  //return _unflatten(_.cloneDeep(list), parent, options);
  const children = _lodash.default.reduce(list, (children, page) => {
    const isChildPage = parent(page);
    if (!isChildPage) {
      return children;
    }

    // children of `page`
    const grandChildren = unflatten(indirectDescendants, potentialGrandChild => potentialGrandChild[parentKey] == page[idKey], options);
    return children.concat([grandChildren.length ? _lodash.default.assign({
      children: grandChildren
    }, page) : page]);
  }, []);
  return children.sort(pageRankComparison);
  function pageRankComparison(a, b) {
    return getPageRank(a) - getPageRank(b);
  }
  function getPageRank(page) {
    const rankKey = options.rankKey || 'rank';
    return page[rankKey] || Infinity;
  }
}
function flatten(hierarchy) {
  let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const idKey = options.idKey || DEFAULT_ID_KEY;
  const parentKey = options.parentKey || DEFAULT_PARENT_KEY;
  const childrenKey = options.childrenKey || DEFAULT_CHILDREN_KEY;
  if (!hierarchy || !hierarchy.length) {
    return [];
  }
  return hierarchy.reduce((flatArray, hElem) => {
    let flattenedChildren = flatten(hElem.children); // depth first
    let flatSegment = [_lodash.default.pickBy(hElem, (val, key) => {
      return key !== childrenKey;
    })];
    if (flattenedChildren.length) {
      flatSegment = flatSegment.concat(flattenedChildren);
    }
    return flatArray.concat(flatSegment);
  }, []);
}
function pathCalculator(array, options) {
  if (!Array.isArray(array)) {
    return undefined;
  }
  options = options || {};
  let idKey = options.idKey || DEFAULT_ID_KEY;
  let parentKey = options.parentKey || DEFAULT_PARENT_KEY;
  let idMap = array.reduce((mapObj, item) => {
    mapObj[item[idKey]] = item;
    return mapObj;
  }, {});
  let pathMap = array.reduce((mapObj, item) => {
    let path = [];
    let parent = item[parentKey];
    while (!isNaN(parent) && parent !== null && path.length <= MAX_PATH_SIZE) {
      // while parent id is a valid #
      let parentRec = idMap[parent];
      path.push(parent);
      if (parentRec) {
        parent = parentRec[parentKey];
      } else {
        parent = undefined;
      }
    }
    mapObj[item[idKey]] = path; // TODO: don't need to reverse? or create path string?
    return mapObj;
  }, {});
  return function calculator(id) {
    return pathMap[id];
  };
}
function getPrunedSitemap_fromSitemap(sitemap) {
  const rootIds = _lodash.default.get(sitemap, 'result') || [];
  const sitemapItems = _lodash.default.get(sitemap, 'entities.sitemapItem') || {};
  const sitemapWithPrunedChildren = _lodash.default.mapValues(sitemapItems, item => {
    return _extends({}, item, {
      children: (item.children || []).filter(childId => sitemapItems[childId])
    });
  });
  return {
    result: rootIds,
    entities: {
      sitemapItem: sitemapWithPrunedChildren
    }
  };
}