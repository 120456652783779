"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _lodash = _interopRequireDefault(require("lodash"));
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _Permissions = _interopRequireDefault(require("./Permissions"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const rubyAccessManager = require('@rubyapps/ruby-access-manager');
function generator() {
  const selfModule = this;
  const {
    selfSelector
  } = selfModule.getDependencies();
  const getOptionsEndpoint = selfModule.curriedGetOptionsEndpoint();
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);
    const permissionItemsByKey = selfState.permissionItemsByKey || {};
    const formValue = _lodash.default.get(selfState, ['fields', selfModule.props.key, 'value']) || [];
    const {
      toggledByPermId,
      idsByPermId
    } = selfModule.getFieldValueFromLocalState(selfState);
    const toggledByPermIdIncludingAncestors = selfModule.toggledByPermIdIncludingAncestors(selfState);
    const mappedProps = _extends({}, ownProps, mixinFieldProps, {
      id: selfModule.getID(),
      fieldKey: selfModule.props.key //key is not exposed to the react component
      ,
      permissionItemsByKey,
      rootPermissionKey: 'root',
      getOptionsEndpoint,
      idsByPermId,
      toggledByPermId: toggledByPermIdIncludingAncestors
    });
    return mappedProps;
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
    };
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_Permissions.default);
}