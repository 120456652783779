"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
//import TimePickerStyle from '@rubyapps/ruby-styles/src/client/styleObjects/timepicker';
var _default = {
  //    TimePicker: TimePickerStyle
};
exports.default = _default;