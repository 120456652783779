"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const _ = require('lodash');
const {
  ancestorIdsForId_usingTogglesById
} = require('./utils');
module.exports = function (state, toggleCtx) {
  const {
    togglesById,
    toggleTree
  } = state;
  const toggleIds = idsToUpdateFromToggleCtx_usingToggleTree_andTogglesById(toggleCtx, toggleTree, togglesById);
  const mutableTogglesById = _.cloneDeep(togglesById); //# Don't modify original togglesById - instead modify a copy
  const updatedTogglesById = setTogglesById_forToggleIds_usingToggleCtx(mutableTogglesById, toggleIds, toggleCtx);
  return updatedTogglesById;
};
function idsToUpdateFromToggleCtx_usingToggleTree_andTogglesById(toggleCtx, toggleTree, togglesById) {
  const {
    toggledId,
    isToggled
  } = toggleCtx;
  const collectedIds = _.reduce(toggleTree, (acc, treeNode) => {
    const {
      id,
      children = [],
      fieldKey
    } = treeNode;
    const collectedIds = id === toggledId ? acc.concat(id, ancestorToggleIdsInheritingToggleCtx_usingTogglesById(toggleCtx, togglesById), descendantToggleIdsFromToggleTree(children)) : idsToUpdateFromToggleCtx_usingToggleTree_andTogglesById(toggleCtx, children, togglesById);
    return acc.concat(collectedIds);
  }, []);
  return collectedIds;
}
function ancestorToggleIdsInheritingToggleCtx_usingTogglesById(toggleCtx, togglesById) {
  const {
    toggledId,
    isToggled
  } = toggleCtx;
  return isToggled ? ancestorIdsForId_usingTogglesById(toggledId, togglesById)
  //# If a node is toggled OFF then we must check if any siblings are toggled ON
  //# If no siblings are toggled ON then we can collect the parent id and recurse up the tree
  : ancestorIdsForId_untilNoSiblingsMatchToggleCondition_usingTogglesById(toggledId, isToggled, togglesById);
}
function ancestorIdsForId_untilNoSiblingsMatchToggleCondition_usingTogglesById(toggleId, toggleCondition, togglesById) {
  let collectedAncestorIds = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  const parentId = togglesById[toggleId].parentId;
  if (!parentId) {
    return collectedAncestorIds;
  }
  const siblingIds = Object.keys(togglesById).filter(id => togglesById[id].parentId === parentId && id !== toggleId);
  const siblingsNotMatchingToggleCondition = siblingIds.filter(siblingId => togglesById[siblingId].toggled === !toggleCondition);
  return _.isEmpty(siblingsNotMatchingToggleCondition) ? ancestorIdsForId_untilNoSiblingsMatchToggleCondition_usingTogglesById(parentId, toggleCondition, togglesById, collectedAncestorIds.concat(parentId)) : collectedAncestorIds;
}
function descendantToggleIdsFromToggleTree(toggleTree) {
  const toggleIds = _.reduce(toggleTree, (acc, treeNode) => {
    const {
      id,
      children = []
    } = treeNode;
    return _.isEmpty(children) ? acc.concat(id) : acc.concat(id, descendantToggleIdsFromToggleTree(children));
  }, []);
  return toggleIds;
}
function setTogglesById_forToggleIds_usingToggleCtx(togglesById, toggleIds, toggleCtx) {
  const {
    isToggled
  } = toggleCtx;
  const mutatedTogglesById = _.reduce(toggleIds, (acc, id) => {
    const toggleData = acc[id];
    const updatedToggleData = _extends({}, toggleData, {
      toggled: isToggled
    });
    return _extends(acc, {
      [id]: updatedToggleData
    });
  }, togglesById);
  return mutatedTogglesById;
}