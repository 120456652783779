"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  handle: {
    margin: '10px 10px',
    cursor: 'move'
  },
  locked: {
    margin: '10px 10px',
    cursor: 'not-allowed'
  },
  title: {
    cursor: 'pointer'
  }
};
exports.default = _default;