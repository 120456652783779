"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Griddle = void 0;
Object.defineProperty(exports, "GriddleBatchMovePlugin", {
  enumerable: true,
  get: function () {
    return _griddleBatchMovePlugin.default;
  }
});
exports.GriddleUtilityBarPlugin = exports.GriddleSubgridPlugin = exports.GriddleSelectionPlugin = exports.GriddleRender = exports.GriddleRemotePlugin = exports.GriddlePreferencesPlugin = exports.GriddleDndPlugin = exports.GriddleDefaultsPlugin = void 0;
Object.defineProperty(exports, "customComponents", {
  enumerable: true,
  get: function () {
    return _customComponents.customComponents;
  }
});
Object.defineProperty(exports, "customComponentsByType", {
  enumerable: true,
  get: function () {
    return _customComponents.customComponentsByType;
  }
});
var GriddleReact = _interopRequireWildcard(require("./local_modules/griddle-react"));
var GriddleRender = _interopRequireWildcard(require("./local_modules/griddle-render"));
exports.GriddleRender = GriddleRender;
var GriddleSubgridPlugin = _interopRequireWildcard(require("./local_modules/griddle-subgrid-plugin"));
var GriddleDndPlugin = _interopRequireWildcard(require("./local_modules/griddle-dnd-plugin"));
exports.GriddleDndPlugin = GriddleDndPlugin;
var GriddleRemotePlugin = _interopRequireWildcard(require("./local_modules/griddle-remote-plugin"));
exports.GriddleRemotePlugin = GriddleRemotePlugin;
var GriddleDefaultsPlugin = _interopRequireWildcard(require("./local_modules/griddle-defaults-plugin"));
exports.GriddleDefaultsPlugin = GriddleDefaultsPlugin;
var GriddleSelectionPlugin = _interopRequireWildcard(require("./local_modules/griddle-selection-plugin"));
var GriddleUtilityBarPlugin = _interopRequireWildcard(require("./local_modules/griddle-utility-bar-plugin"));
var GriddlePreferencesPlugin = _interopRequireWildcard(require("./local_modules/griddle-preferences-plugin"));
var _griddleBatchMovePlugin = _interopRequireDefault(require("./local_modules/griddle-batch-move-plugin"));
var _customComponents = require("./customComponents");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
/*
import * as GriddleReact from 'griddle-react';
import * as GriddleRender from 'griddle-render';
import * as GriddleSubgridPlugin from 'griddle-subgrid-plugin';
import * as GriddleDndPlugin from 'griddle-dnd-plugin';
import * as GriddleRemotePlugin from 'griddle-remote-plugin';
import * as GriddleDefaultsPlugin from 'griddle-defaults-plugin';
import * as GriddleSelectionPlugin from 'griddle-selection-plugin';
import * as GriddleUtilityBarPlugin from './utility-bar';
*/

const Griddle = GriddleReact.default;
exports.Griddle = Griddle;
const GriddleSubgridPluginDefault = GriddleSubgridPlugin.default;
exports.GriddleSubgridPlugin = GriddleSubgridPluginDefault;
const GriddleDndPluginDefault = GriddleDndPlugin.default;
const GriddleSelectionPluginDefault = GriddleSelectionPlugin.default;
exports.GriddleSelectionPlugin = GriddleSelectionPluginDefault;
const GriddleUtilityBarPluginDefault = GriddleUtilityBarPlugin.default;
exports.GriddleUtilityBarPlugin = GriddleUtilityBarPluginDefault;
const GriddlePreferencesPluginDefault = GriddlePreferencesPlugin.default;

/*
    , RowDefinition = require('griddle-render').DefaultModules.RowDefinition
    , ColumnDefinition = require('griddle-render').DefaultModules.ColumnDefinition
*/
exports.GriddlePreferencesPlugin = GriddlePreferencesPluginDefault;