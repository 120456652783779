"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
var _constants = _interopRequireDefault(require("./constants"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
module.exports = {
  /*
   * some error message defaults
   * based on error object from superagent
   * options.modal
   * options.type
   *
   * options.passthroughError - (bool) we want to start showing error from the server instead of the hardcoded translation, 
   *      for now, this is opt-in, but eventually the default will be true
   */
  getErrorNotificationOptions: function (error) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {
      passthroughError: false
    };
    const type = _constants.default.NOTIFICATION_TYPES.ERROR;
    const errorResponseBody = _lodash.default.get(error, 'response.body.error', error);
    if (options.passthroughError) {
      return _objectSpread(_objectSpread({
        type,
        modal: true
      }, options), {}, {
        title: errorResponseBody.title,
        message: errorResponseBody.message
      });
    }
    if (error && error.status === 401) {
      return {
        type,
        modal: true,
        title: 'Authorization Error',
        message: 'A permissions error was encountered. ' + 'Please try reloading the page, logging back in, or contacting Support.'
      };
    } else if (error && error.status === 403) {
      return {
        type,
        modal: true,
        title: 'Forbidden',
        message: 'You do not have permission to take this action. ' + 'Please try reloading the page, logging back in, or contacting Support.'
      };
    } else if (error && error.status === 422) {
      return {
        type,
        modal: false,
        title: 'Data Error',
        message: 'Please review your form for any errors.'
        //# TODO: consider using error message from error object (not sure if it's ok to blindly show it)
      };
    } else if (errorResponseBody) {
      return {
        type,
        modal: true,
        title: errorResponseBody.title || 'RubyLaw Error',
        message: 'An unexpected error was encountered. ' + 'Please try reloading the page, logging back in, or contacting Support.'
      };
    }
  }
};