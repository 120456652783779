"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _lodash = _interopRequireDefault(require("lodash"));
var _ActivityLister = _interopRequireDefault(require("./ActivityLister"));
var _proposalPluginUtils = require("../../../../ruby-component-plugin-proposal/src/common/proposalPluginUtils");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const CAN_DOWNLOAD_PROPOSAL_PERM = {
  keyword: 'can_generate_proposal_doc',
  subsite: true,
  ruby_client: true
};
function generator() {
  const selfModule = this;
  const {
    selfSelector,
    forms,
    currentUserSelector,
    editPage,
    editPageSelector,
    checkShouldDisableSave,
    frontendSettingsComponent
  } = selfModule.getDependencies();
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const rubyUser = currentUserSelector(state);
    const editPageState = editPageSelector(state);
    const mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);

    //# create activities array from selfState.activitiesById and activitiesIdArray
    const activities = selfState.activitiesIdArray.map(id => selfState.activitiesById[id]);
    const canUserDownload = selfModule.doesUserHavePermission(CAN_DOWNLOAD_PROPOSAL_PERM);
    const templateKey = selfModule.getProps(true).templateKey;
    const proposalSubsiteId = (0, _proposalPluginUtils.getProposalSubsiteId_withSettings)(frontendSettingsComponent.getState());
    const isProposalSubsite = proposalSubsiteId == selfModule.getActiveSubsiteId();
    const shouldHidePreviewLinks = isProposalSubsite;
    return _extends({}, ownProps, mixinFieldProps, selfState, {
      rubyUser,
      selfModule,
      activities,
      canUserPublish: selfModule.canUserPublish(),
      checkShouldDisableSave,
      canUserDownload,
      shouldHidePreviewLinks: ownProps.hidePreviewLinks || isProposalSubsite,
      shouldHideRevertLinks: ownProps.hideRevertLinks,
      contentApprovalFlag: _lodash.default.get(editPageState, 'contentApprovalData') // TODO: is this a good idea?
      ,
      getWordDocGenerationStatus_fromActivity: selfModule.getWordDocGenerationStatus_fromActivity.bind(selfModule),
      STATUS_TYPES: _lodash.default.get(selfModule, 'CONSTANTS.STATUS_TYPES'),
      templateKey,
      forms
    });
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
    };
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_ActivityLister.default);
}