"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rubyComponentPatcherForDate = rubyComponentPatcherForDate;
exports.rubyComponentPatcherForTime = rubyComponentPatcherForTime;
var _lodash = _interopRequireDefault(require("lodash"));
var _reduxBatchedActions = require("redux-batched-actions");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function rubyComponentPatcherForTime(childComponent) {
  const selfModule = this;
  const timeComponent = childComponent;
  const timeKey = childComponent.props.key;
  //# override the actions of the child component
  const childGetAction = childComponent.getAction;
  childComponent.getAction = function () {
    const childAction = childGetAction.apply(childComponent);
    const siblings = selfModule.getChildren();
    const dateComponent = siblings[0];
    const dateKey = dateComponent.props.key;
    const hydratedAction = {
      TYPES: childAction.TYPES,
      generators: _extends({}, childAction.generators, {
        setFieldValueByKey: function (value, key, ignoreValidation, bubblePayload) {
          const {
            generators: dateActions
          } = dateComponent.getAction();
          const {
            generators: timeActions
          } = timeComponent.getAction();
          const childActionObject = childAction.generators.setFieldValueByKey(value, key, true); //# handle validation here

          if (ignoreValidation && !bubblePayload) {
            return childActionObject;
          }
          const dateValue = dateComponent.formValue()[dateKey];
          const dateError = dateComponent.formError()[dateKey];
          const prevDateErrorMessage = dateError;
          const prevTimeErrorMessage = timeComponent.formError()[timeKey];
          const collectedActions = [childActionObject];
          const valueHasData = somethingHasData(value);
          const dateConstraints = {
            //required: _.get(dateComponent.props, 'verify.required') || valueHasData ? true : false
            required: _lodash.default.get(dateComponent.props, 'verify.required')
          };
          const timeConstraints = {
            required: _lodash.default.get(timeComponent.props, 'verify.required')
          };
          const dateErrors = dateComponent.validateValue_forField_againstConstraints(dateValue, dateKey, dateConstraints);
          const timeErrors = timeComponent.validateValue_forField_againstConstraints(value, timeKey, timeConstraints);
          const newDateErrorMessage = _lodash.default.get(dateErrors, '0.message');
          const newTimeErrorMessage = _lodash.default.get(timeErrors, '0.message');
          collectedActions.push(dateActions.setFieldErrorMessageByKey(newDateErrorMessage, dateKey));
          collectedActions.push(timeActions.setFieldErrorMessageByKey(newTimeErrorMessage, timeKey));
          const batchedActions = (0, _reduxBatchedActions.batchActions)(collectedActions);
          if (bubblePayload) {
            return batchedActions;
          }
          return dispatch => {
            dispatch(batchedActions);
            if (newDateErrorMessage != prevDateErrorMessage || newTimeErrorMessage != prevTimeErrorMessage) {
              selfModule.refreshParentErrors();
              selfModule.refreshParentErrors(); // call twice as temporary patch while RT#53866 isn't fixed, TODO: remove after fixing
            }
          };
        }
      })
    };
    //# polyfill the parents prop (NOTE: not great)
    hydratedAction.generators.setFieldValueByKey._parents = childAction.generators.setFieldValueByKey._parents;
    return hydratedAction;
  };
  return childComponent;
}
function rubyComponentPatcherForDate(childComponent) {
  const selfModule = this;
  const dateComponent = childComponent;
  const dateKey = dateComponent.props.key;
  //# override the actions of the child component
  const childGetAction = childComponent.getAction;
  childComponent.getAction = function () {
    const childAction = childGetAction.apply(childComponent);
    const siblings = selfModule.getChildren();
    const timeComponent = siblings[1];
    const timeKey = timeComponent.props.key;
    const hydratedAction = {
      TYPES: childAction.TYPES,
      generators: _extends({}, childAction.generators, {
        setFieldValueByKey: function (value, key, ignoreValidation, bubblePayload) {
          const {
            generators: dateActions
          } = dateComponent.getAction();
          const {
            generators: timeActions
          } = timeComponent.getAction();
          const childActionObject = childAction.generators.setFieldValueByKey(value, key, true); //# handle validation here

          if (ignoreValidation && !bubblePayload) {
            return childActionObject;
          }
          const timeValue = timeComponent.formValue()[timeKey];
          const timeValueHasData = somethingHasData(timeValue);
          const dateError = dateComponent.formError()[dateKey];
          const prevDateErrorMessage = dateError;
          const prevTimeErrorMessage = timeComponent.formError()[timeKey];
          const collectedActions = [childActionObject];
          const dateConstraints = {
            //required: _.get(dateComponent.props, 'verify.required') || timeValueHasData ? true : false
            required: _lodash.default.get(dateComponent.props, 'verify.required')
          };
          const timeConstraints = {
            required: _lodash.default.get(timeComponent.props, 'verify.required')
          };
          const dateErrors = dateComponent.validateValue_forField_againstConstraints(value, dateKey, dateConstraints);
          const timeErrors = timeComponent.validateValue_forField_againstConstraints(timeValue, timeKey, timeConstraints);
          const newDateErrorMessage = _lodash.default.get(dateErrors, '0.message');
          const newTimeErrorMessage = _lodash.default.get(timeErrors, '0.message');
          collectedActions.push(dateActions.setFieldErrorMessageByKey(newDateErrorMessage, dateKey));
          collectedActions.push(timeActions.setFieldErrorMessageByKey(newTimeErrorMessage, timeKey));
          const batchedActions = (0, _reduxBatchedActions.batchActions)(collectedActions);
          if (bubblePayload) {
            return batchedActions;
          }
          return dispatch => {
            dispatch(batchedActions);
            if (newDateErrorMessage != prevDateErrorMessage || newTimeErrorMessage != prevTimeErrorMessage) {
              selfModule.refreshParentErrors();
              selfModule.refreshParentErrors(); // call twice as temporary patch while RT#53866 isn't fixed, TODO: remove after fixing
            }
          };
        }
      })
    };

    //# polyfill the parents prop (NOTE: not great)
    hydratedAction.generators.setFieldValueByKey._parents = childAction.generators.setFieldValueByKey._parents;
    return hydratedAction;
  };
  return childComponent;
}
function somethingHasData(something) {
  let somethingHasData = something ? true : false;
  if (_lodash.default.isObject(something)) {
    somethingHasData = _lodash.default.filter(something, something => something).length ? true : false;
  }
  return somethingHasData;
}