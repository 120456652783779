// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EmptyList__noResultsContent___xXbun {\n    padding: 20px;\n    width: 100%;\n    box-sizing: border-box;\n    text-align: center;\n    transition: opacity 450ms ease-in-out;\n    background: #fff;\n}\n\n.EmptyList__imageWrapper___Imuo_ {\n    width: 200px;\n    padding: 16px 67px 0 67px;\n    opacity: 0.7;\n    display: inline-block;\n    position: relative;\n}\n.EmptyList__logo___Krsg8 {\n    max-width: 200px;\n    margin-bottom: 20px;\n}\n.EmptyList__header___HVWKD {\n    font-size: 20px;\n}\n.EmptyList__details___n0JfE {\n    \n}\n", "",{"version":3,"sources":["webpack://./src/local_modules/ruby-react-components/src/client/view/EmptyList.cssModule"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,qCAAqC;IACrC,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,qBAAqB;IACrB,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,eAAe;AACnB;AACA;;AAEA","sourcesContent":[".noResultsContent {\n    padding: 20px;\n    width: 100%;\n    box-sizing: border-box;\n    text-align: center;\n    transition: opacity 450ms ease-in-out;\n    background: #fff;\n}\n\n.imageWrapper {\n    width: 200px;\n    padding: 16px 67px 0 67px;\n    opacity: 0.7;\n    display: inline-block;\n    position: relative;\n}\n.logo {\n    max-width: 200px;\n    margin-bottom: 20px;\n}\n.header {\n    font-size: 20px;\n}\n.details {\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noResultsContent": "EmptyList__noResultsContent___xXbun",
	"imageWrapper": "EmptyList__imageWrapper___Imuo_",
	"logo": "EmptyList__logo___Krsg8",
	"header": "EmptyList__header___HVWKD",
	"details": "EmptyList__details___n0JfE"
};
export default ___CSS_LOADER_EXPORT___;
