"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const lockIconWidth = 16;
var _default = {
  LockIcon: {
    style: {
      width: 17,
      height: 16,
      paddingLeft: 6,
      position: 'relative',
      top: 1
    }
  }
};
exports.default = _default;