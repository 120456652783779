"use strict";

module.exports = {
  modelName: 'user',
  columns: [{
    key: 'username',
    displayName: 'Username',
    minWidth: 70,
    resizable: true
  }, {
    key: 'first_name',
    displayName: 'First Name',
    resizable: true
  }, {
    key: 'last_name',
    displayName: 'Last Name',
    resizable: true
  }, {
    key: 'ruby_role_fk',
    references: {
      model: 'RubyRole'
    }
    //, shouldExpand: true //# NOTE: need to continue using references because the model doesn't support the expand query
    ,
    type: 'valueInObject',
    displayName: 'Role',
    resizable: true
  }],
  defaultSortColumn: 'username',
  defaultSortDirection: 'asc'
};