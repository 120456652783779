"use strict";

//import {batchActions} from 'redux-batched-actions';
const fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
module.exports = function () {
  const id = this.getID();
  const TYPES = {
    OPEN: `@ruby-app/${id}/OPEN`,
    OPENING: `@ruby-app/${id}/OPENING`,
    CLOSE: `@ruby-app/${id}/CLOSE`
  };
  return {
    TYPES,
    generators: {
      _open: function () {
        return {
          type: TYPES.OPEN,
          payload: {
            dialog_opening: false,
            dialog_open: true
          }
        };
      },
      _opening: function () {
        return {
          type: TYPES.OPENING,
          payload: {
            dialog_opening: true
          }
        };
      },
      openDialog: function () {
        const {
          generators: actions
        } = this.getAction();
        return dispatch => {
          dispatch(actions._opening());
          this.replaceChildren();
          dispatch(actions._open());
        };
      },
      _close: function () {
        return {
          type: TYPES.CLOSE,
          payload: {
            dialog_open: false,
            dialog_opening: false
          }
        };
      },
      closeDialog: function () {
        const {
          generators: actions
        } = this.getAction();

        //# close info pane
        return dispatch => {
          dispatch(actions._close());
          this.replaceChildren();
        };
      }
    }
  };
};