// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ArrayList__DragHandleIcon____lJDv {\n    cursor: move;\n    position: relative;\n}\n\n.ArrayList__Placeholder____z5iW {\n    /*\n    background: #e8e8e8;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    */\n    border-radius: 16px;\n}\n\n.ArrayList__ReactGridLayout___qdP9T .react-grid-item {\n    width: 100% !important;\n}\n\n.ArrayList__chip___NtjRz .buttons > *{\n    margin-left: 0px!important;\n}\n", "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-array-map/src/client/reactComponents/ArrayList.cssModule"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI;;;;;;;KAOC;IACD,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".DragHandleIcon {\n    cursor: move;\n    position: relative;\n}\n\n.Placeholder {\n    /*\n    background: #e8e8e8;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    */\n    border-radius: 16px;\n}\n\n.ReactGridLayout :global(.react-grid-item) {\n    width: 100% !important;\n}\n\n.chip :global(.buttons > *){\n    margin-left: 0px!important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DragHandleIcon": "ArrayList__DragHandleIcon____lJDv",
	"Placeholder": "ArrayList__Placeholder____z5iW",
	"ReactGridLayout": "ArrayList__ReactGridLayout___qdP9T",
	"chip": "ArrayList__chip___NtjRz"
};
export default ___CSS_LOADER_EXPORT___;
