"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _lodash = _interopRequireDefault(require("lodash"));
var _reactRedux = require("react-redux");
var _DashboardAnalytics = _interopRequireDefault(require("./DashboardAnalytics"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function generator() {
  const selfModule = this;
  const {
    selfSelector,
    frontendSettingsComponent
  } = selfModule.getDependencies();
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const frontendSettings = frontendSettingsComponent.getState();
    const protocol = frontendSettings['WWW.INIT.SSL_ONLY'] ? 'https://' : 'http://';
    const props = selfModule.getProps(true);
    const domainURL = new URL(`${protocol}${props.domain}`);
    const host = `${domainURL.protocol}//${domainURL.host}`;
    return _extends({}, ownProps, _lodash.default.pick(selfState, Object.keys(_DashboardAnalytics.default.propTypes)), _lodash.default.pick(selfModule.props, Object.keys(_DashboardAnalytics.default.propTypes)), {
      id: selfModule.getID(),
      host,
      children: ownProps.getMergedChildrenReactElements ? ownProps.getMergedChildrenReactElements() : ownProps.children
    });
  }
  return (0, _reactRedux.connect)(mapStateToProps)(_DashboardAnalytics.default);
}