"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  Card: {
    containerStyle: {
      paddingBottom: 15
    }
  },
  CardText: {
    style: {
      paddingTop: 0,
      paddingBottom: 0,
      position: 'relative'
    }
  }
};
exports.default = _default;