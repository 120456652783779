"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
/* jshint -W138 */
//con1st rubyMiddlewareFields = require('@rubyapps/ruby-middleware-fields');

//# NOTE: should not need to have a default state
const _ = require('lodash');
/**
 *
 *  defaultState = {
 *      errorContext: the context of the error object that the comment dialog should respond to
 *      endpoint: the endpoint to submit the form to
 *      propagateErrorActionType: the action type to dispatch if the error context doesn't match
 *  }
 *
 **/
var defaultState = {
  error: null,
  errorPayload: null,
  endpoint: '/users/login',
  forgotPasswordState: null // "form", "success"
  ,
  fields: {
    username: {
      value: null,
      label: 'Username',
      error: null,
      constraints: {
        required: true
      }
    },
    password: {
      value: null,
      label: 'Password',
      error: null,
      constraints: {
        required: true
      }
    },
    tfa_secret: {
      value: null,
      label: 'Two Factor Auth Secret' //# used only during setup
      ,
      error: null
    },
    tfa_token: {
      value: null,
      label: 'Two Factor Auth Token',
      error: null,
      constraints: {
        required: true
      }
    },
    fp_username: {
      value: null,
      label: 'Username',
      error: null,
      constraints: {
        required: true
      }
    }
  }
};
module.exports = function (state, action) {
  const actionTypes = this.getAction().TYPES;
  if (!state.hasOwnProperty('fields') || !state.fields.hasOwnProperty('username')) {
    state = _extends({}, state, _.cloneDeep(defaultState));
  }
  switch (action.type) {
    case actionTypes.SET_ERROR:
      return _extends({}, state, {
        error: action.payload.error
      });
    case actionTypes.SET_ERROR_PAYLOAD:
      return _extends({}, state, {
        errorPayload: action.payload.errorPayload
      });
    case actionTypes.SWITCH_TO_FORGOT_PASSWORD:
      return _extends({}, state, {
        forgotPasswordState: 'form'
      });
    case actionTypes.FORGOT_PASSWORD_SUCCESS:
      return _extends({}, state, {
        forgotPasswordState: 'success'
      });
    case actionTypes.RESET:
      return _extends({}, state, _.cloneDeep(defaultState));
    default:
      return state;
  }
};