"use strict";

const request = require('@rubyapps/ruby-superagent');
const urljoin = require('url-join');
const inflection = require('inflection');
const rubyWords = require('@rubyapps/ruby-words');
const rubyNotificationsComponent = require('@rubyapps/ruby-component-notifications');
const typesFromID = id => ({
  SET_MOST_RECENTLY_MODIFIED_ITEM: `@@ruby-app/${id}/SET_MOST_RECENTLY_MODIFIED_ITEM`,
  SET_GRIDDLE_STATE: `@@ruby-app/${id}/SET_GRIDDLE_STATE`,
  SET_INTERNAL_FILTER_TAGS: `@@ruby-app/${id}/SET_INTERNAL_FILTER_TAGS`,
  SET_CUSTOM_FILTERS: `@@ruby-app/${id}/SET_CUSTOM_FILTERS`
});
const FETCH_MOST_RECENTLY_MODIFIED_ENDPOINT = '/content/fetch-most-recently-modified';
const generators = {
  setItemStatus_withActive: function (item, isActive, thenableArguments) {
    const {
      templateKey,
      id
    } = item;
    const selfModule = this;
    const {
      feSettingsSelector
    } = selfModule.getDependencies();
    const pushNotification = selfModule.pushNotification.bind(selfModule);
    const showNotificationModal = selfModule.showNotificationModal.bind(selfModule);
    return (dispatch, getState) => {
      const applicationState = getState();
      const apiUrlPrefix = _.get(feSettingsSelector(applicationState), 'restApiRoot');
      const endpoint = urljoin(apiUrlPrefix, inflection.pluralize(templateKey), '' + id, isActive ? 'publish' : 'unpublish');
      const requestPromise = request.post(endpoint).then(response => {
        const displayStatusMap = {
          active: 'published',
          inactive: 'unpublished'
        };
        let status = _.get(response, 'body.status');
        let contentDisplayString = rubyWords.quote(_.get(response, 'body.name')) || 'This content';
        let displayedStatus = displayStatusMap[status];
        pushNotification({
          message: `${contentDisplayString} is now ${displayedStatus}.`,
          type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.SUCCESS
        });
        return response;
      }, err => {
        let notificationMethod = pushNotification,
          title,
          message,
          type = rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.ERROR;
        if (err.status === 409) {
          message = 'The entry could not be updated due to a pending content approval request. ' + 'Please try again once the request is resolved.';
        } else if (err.status === 403) {
          message = _.get(err, 'response.body.error.message') || 'You do not have the permission to update content. ' + 'Please edit the page and submit a content approval request.';
        } else if (err.status === 422) {
          message = 'The entry could not be updated due to invalid content. ' + 'Please edit the page to fix.';
        } else {
          title = 'Content Update Error';
          message = _.get(err, 'response.body.error.message') || 'An error was encountered while updating content. ' + 'Please try again later.';
          notificationMethod = showNotificationModal;
        }
        notificationMethod({
          type,
          title,
          message
        });
        throw err;
      });
      if (thenableArguments) {
        requestPromise.then.apply(requestPromise, thenableArguments);
      }
      return requestPromise;
    };
  },
  retrieveAndSetMostRecentlyModifiedItem: function () {
    const selfModule = this;
    const {
      feSettingsSelector,
      rubyClientFKSelector
    } = selfModule.getDependencies();
    const {
      fetchMostRecentlyModifiedEndpoint = FETCH_MOST_RECENTLY_MODIFIED_ENDPOINT,
      templateKey
    } = selfModule.props;
    return (dispatch, getState) => {
      const applicationState = getState();
      const apiUrlPrefix = _.get(feSettingsSelector(applicationState), 'restApiRoot');
      const rubyClientFK = rubyClientFKSelector(applicationState);
      const endpoint = urljoin(apiUrlPrefix, fetchMostRecentlyModifiedEndpoint, '?ruby_client_fk=' + rubyClientFK, '&templateType=sitemap_template', templateKey ? '&template_keyword=' + type : '');
      const requestPromise = request.get(endpoint).then(response => {
        const item = response.body.data[0];
        dispatch(generators.setMostRecentlyModifiedItem(item));
      }, err => {});
    };
  },
  setMostRecentlyModifiedItem: function (item) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SET_MOST_RECENTLY_MODIFIED_ITEM,
      payload: {
        item
      }
    };
  },
  setGriddleState: function (griddleState) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SET_GRIDDLE_STATE,
      payload: {
        griddleState
      }
    };
  }
};
module.exports = function () {
  const TYPES = typesFromID(this.getID());
  return {
    TYPES,
    generators
  };
};