"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.aurl = aurl;
exports.getLargeThumbnailUrl_forAsset = getLargeThumbnailUrl_forAsset;
var _lodash = _interopRequireDefault(require("lodash"));
var _path = _interopRequireDefault(require("path"));
var _sprintfJs = require("sprintf-js");
var _constants = _interopRequireDefault(require("./constants"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const {
  ASSET_CONSTANTS
} = _constants.default;
function aurl(assetId, type, options) {
  if (!assetId) {
    return null;
  }
  assetId = (0, _sprintfJs.sprintf)('%03d', assetId);
  const version = _lodash.default.get(options, 'version');
  const versionDir = version ? `/v${version}` : '';
  const filename = _lodash.default.get(options, 'filename');
  const extension = _lodash.default.get(options, 'type') || _lodash.default.last((filename || "").split(/\./g)) || 'jpg';
  const assetName = _lodash.default.get(options, 'asset_name') || filename || '';
  const assetExtension = _path.default.extname(assetName);
  const preppedAssetNameWithExtension = `${_path.default.basename(assetName, assetExtension)}.${extension}`;
  const displayAssetName = preppedAssetNameWithExtension;
  const firstDigit = assetId.charAt(0);
  const secondDigit = assetId.charAt(1);
  var url;
  switch (type) {
    case ASSET_CONSTANTS.LARGETHUMB:
      url = `/utils/thumbs/${firstDigit}/${secondDigit}/${assetId}_largethumb.${extension}`;
      break;
    case ASSET_CONSTANTS.IMAGE_PATH:
      url = `/images/content/${firstDigit}/${secondDigit}/${assetId}.${extension}`;
      break;
    default:
      // TODO: this is messy, maybe this should be updated to accept a callback
      // pull asset info (this might change currently!), use config for www server
      url = (0, _sprintfJs.sprintf)('/images/content/%d/%d%s/%s/%s', firstDigit, secondDigit, versionDir, assetId, displayAssetName);
      break;
  }
  ;
  return url;
}
function getLargeThumbnailUrl_forAsset(assetObj) {
  return !assetObj ? null : aurl(assetObj.asset_id, ASSET_CONSTANTS.LARGETHUMB, {
    filename: assetObj.filename,
    type: assetObj.type
  });
}