"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.expandRow = expandRow;
var types = _interopRequireWildcard(require("./constants"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
/*
 * @param {string} direction - (default: 'toggle') ['toggle', 'collapse', 'expand'] directions other than 'toggle' is typically used only for 'includeDescendants'
 * */
function expandRow(griddleKey) {
  let direction = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'toggle';
  let includeDescendants = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  return {
    type: types.GRIDDLE_ROW_TOGGLED,
    griddleKey: griddleKey,
    direction,
    includeDescendants
  };
}