"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _textfield = _interopRequireDefault(require("@rubyapps/ruby-styles/src/client/styleObjects/textfield"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
var _default = {
  TextField: _extends({}, _textfield.default, {
    style: _extends({}, _textfield.default.style, {
      width: '100%'
    })
  }),
  Toggle: {
    style: {
      width: 'auto'
    }
  }
};
exports.default = _default;