"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
module.exports = function (state, action) {
  const {
    TYPES
  } = this.getAction();
  const {
    type,
    payload
  } = action;
  if (!state) {
    state = this.getInitialState();
  }
  switch (type) {
    case TYPES.SET_VISIBLE:
      return _extends({}, state, {
        isVisible: true //# use a more generic property so we can leverage mixin to handle children
        ,
        isVisibleLatched: true
      });
    case TYPES.SET_VISIBLE_STEADYSTATE:
      return _extends({}, state, {
        isVisibleSteadyState: true
      });
    case TYPES.UNSET_VISIBLE:
      return _extends({}, state, {
        isVisible: false
      });
    case TYPES.RESET_STORE:
      const activeState = this.getState();
      const initialState = this.getInitialState(activeState);
      const resetState = _extends({}, state, initialState);
      return resetState;
    default:
      return state;
  }
};