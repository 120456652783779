"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const PropTypes = _react.default.PropTypes;
var _default = {
  activity_type: PropTypes.string,
  approval_id: PropTypes.number,
  draft_id: PropTypes.number //# not great but here to account for draft plugin
  ,
  canUserDownload: PropTypes.bool,
  comment: PropTypes.string,
  create_modified_timestamp: PropTypes.string,
  editMode: PropTypes.bool,
  entity_id: PropTypes.number,
  entity_name: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  last_modified_timestamp: PropTypes.string,
  recipient_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rteConfig: PropTypes.object,
  rteMode: PropTypes.string,
  status: PropTypes.string,
  user_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  version_data_id: PropTypes.number,
  version_num: PropTypes.number,
  wordDocUrl: PropTypes.string,
  isWordDocGenerating: PropTypes.bool,
  templateKey: PropTypes.string.isRequired,
  forms: PropTypes.object.isRequired
};
exports.default = _default;