"use strict";

module.exports = {
  COMPONENT_NAME: 'rubyComponentFieldLinkUrl',
  DEFAULT_TEXT_PROPS: {
    label: "Link Text",
    key: "text",
    data_type: "string"
  },
  DEFAULT_LINK_PROPS: {
    label: "Link Url",
    key: "link",
    data_type: "string"
  }
};