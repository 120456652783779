"use strict";

module.exports = {
  COMPONENT_NAME: 'rubyComponentNotifications',
  DEFAULT_LIGHTWEIGHT_NOTIFICATION_DURATION: 10000 /* 10 seconds */,
  NOTIFICATION_TYPES: {
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error'
  }
};