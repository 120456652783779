"use strict";

/* jshint -W138 */
var actionTypes = require('./action').TYPES;

//# NOTE: should not need to have a default state

var defaultState = null;
module.exports = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actionTypes.SET_LOGGED_IN_USER:
      return action.payload.userInfo;
    case actionTypes.CLEAR_LOGGED_IN_USER:
      return null;
    default:
      return state;
  }
};