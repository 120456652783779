"use strict";

var asset_constants = require('./utils/asset-constants.js');
var _ = require('lodash');

/*
 * configs = {
 *      common: [core, client, env]
 *      server: [core, client, env]
 * }
 *
 * */
var createMergedConfig = function (configs) {
  var mergedConfig = {};
  for (var namespaceKey in configs) {
    var configArray = [{}].concat(configs[namespaceKey]);
    var mergedConfigForNamespace = _.extend.apply(_, configArray);
    if (namespaceKey === "common") {
      mergedConfigForNamespace.Constants = asset_constants;
    }
    mergedConfig[namespaceKey] = mergedConfigForNamespace;
  }
  return mergedConfig;
};
module.exports = createMergedConfig;