"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
/* jshint -W138 */
var actionTypes = require('./action').TYPES;

//# NOTE: should not need to have a default state

module.exports = function (state, action) {
  switch (action.type) {
    case actionTypes.SET_FRONTEND_SETTINGS:
      return _extends({}, state, action.payload['frontend-settings']);
    case actionTypes.SET_FRONTEND_SETTING:
      return _extends({}, state, {
        [action.payload.key]: action.payload.value
      });
    default:
      return state;
  }
};