"use strict";

var _reduxBatchedActions = require("redux-batched-actions");
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const Promise = require('bluebird');
const baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
module.exports = {
  cloneSelf: function (parentNode) {
    let overrides = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    return baseFieldMixin.cloneSelf.call(this, parentNode, _extends({
      children: []
    }, overrides)); //# don't clone children since it's dynamic
    //# this might be an issue if we need to clone this node
    //# for purposes other than displayValue
    //# IF we cannot call on helperChildrenFromOptions() to
    //# generate new helper children
  },

  helperChildrenFromOptions: function (options) {
    const selfModule = this;
    const selfID = this.getID();
    if (this._cachedHelperChildrenByUUID == undefined) {
      this._cachedHelperChildrenByUUID = {};
    }
    //# UUID is the index of the options for now since the order never changes
    const cachedChildrenByUUID = this._cachedHelperChildrenByUUID;
    const hashedOptions = this.hashedItems(options);
    let cachedCount = 0;
    let helperReduxStore;
    const getStore = () => helperReduxStore;
    /*
    const getState = function(appState) {
        const selfSelector = this.getSelectorRelativeToComponent(selfModule.getParent())
        const store = this.getStore();
        
        return selfSelector(appState || store.getState());
    }
    */
    const childrenComponents = hashedOptions.reduce((collector, hashedOption, index) => {
      const {
        _hash: childID,
        _value: option
      } = hashedOption;
      const cachedChild = cachedChildrenByUUID[childID];
      if (0 && cachedChild) {
        cachedCount++;
        return collector.concat(cachedChild);
      }
      const idPrefix = `${selfID}[${childID}]`;
      const builtChildComponents = this.buildChildComponentsForOption(option, idPrefix);
      cachedChildrenByUUID[childID] = builtChildComponents;

      //# deeply patch getStore for each of the childrenComponents
      builtChildComponents.forEach(childComponent => {
        //# patch the parent and getStore for accessibility
        childComponent._isHelper = true;
        childComponent._parent = this;
        childComponent.getStore = getStore;
        //childComponent.getState = getState;
        childComponent.findDescendentsBy(node => {
          node.getStore = getStore;
          return false;
        });
      });
      return collector.concat(builtChildComponents);
    }, []).filter(child => child != undefined);

    //# TODO: we can probably somehow cache the different combinations of options
    //# to prevent overly hitting the server whenever we need to re-query the options
    //# for displayValue
    helperReduxStore = this.helperReduxStore({
      children: childrenComponents
    });
    return childrenComponents;
  },
  _displayValueFromLocalState: function (selfState, entireState) {
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    //# get displayValues for all of it's children
    //# so not the thing that's being edited, but the one that's been commited
    const selfKey = this.props.key;
    const componentOptions = _lodash.default.get(selfState, 'props.options') || this.props.options || [];
    const formValueForFiltering = this.formValueForFilteringFromLocalState(selfState, entireState);
    const filteredComponentOptions = this.filteredOptionsByTheirQueries(componentOptions, formValueForFiltering);
    const helperChildren = this.helperChildrenFromOptions(filteredComponentOptions);
    const helperReduxStore = helperChildren.length ? helperChildren[0].getStore() : undefined;
    const fieldValue = this.getFieldValueFromLocalState(selfState) || {};
    const collectorSpec = this.newActionCollectorSpec();
    return Promise.all(helperChildren.map(helperChild => {
      return helperChild._formValueToLocalState(fieldValue, collectorSpec.collectAction);
    })).then(res => {
      if (helperChildren.length == 0) {
        return {};
      }
      helperReduxStore.dispatch((0, _reduxBatchedActions.batchActions)(collectorSpec.collectedActions));
      //console.log(JSON.stringify(helperReduxStore.getLocalState(), null, '  '));
      //# then create a new selfState for children
      const newSelfState = helperReduxStore.getLocalState()[this.getID()];
      const newEntireState = entireState;
      _lodash.default.set(entireState, this.getKeypathArr(), newSelfState);
      const arrOfReducedChildrenPromises = _lodash.default.reduce(helperChildren, (collector, child, index) => {
        const childID = child.getID();
        const childDisplayValue = child._displayValueFromLocalState ? child._displayValueFromLocalState(newSelfState[childID], newEntireState, options) : undefined;
        if (childDisplayValue != undefined) {
          collector.push(childDisplayValue);
        }
        return collector;
      }, []);
      const reducedChildrenPromise = Promise.all(arrOfReducedChildrenPromises).then(arrOfReducedChildrenState => _extends({}, ...arrOfReducedChildrenState));
      const promisedDisplayObject = selfKey ? Promise.props({
        [selfKey]: reducedChildrenPromise
      }) : reducedChildrenPromise;
      return promisedDisplayObject;
    });
  }
};