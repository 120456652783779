"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.base_fieldInfo_byComponentName = void 0;
exports.propertySpecsByKey_withRubyPermissions = propertySpecsByKey_withRubyPermissions;
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
const INVALID_API_KEYS = ['where', 'offset', 'skip', 'order', 'fields', 'limit', 'include', 'esnative', '_compoundLogic', 'and', 'or', 'gt', 'gte', 'lt', 'lte', 'between', 'eq', 'neq', 'inq', 'nin', 'regexp', 'like', 'nlike', 'simpleqs', 'nsimpleqs', 'script_fields', 'explain', 'termFreq', 'highlight', 'nested', '_nestedResultsByKey', 'expand'];
function propertySpecsByKey_withRubyPermissions(ruby_permissions) {
  return _objectSpread(_objectSpread(_objectSpread({
    constraint_data_type_number_hidden: {
      label: 'Data Type',
      type: 'number',
      propKey: 'constraint_data_type',
      hidden: true,
      defaultValue: 'number'
    },
    data_type_number_hidden: {
      label: 'Data Type',
      type: 'object',
      propKey: 'data_type',
      hidden: true,
      defaultValue: ["number"],
      constraints: {
        required: true
      }
    },
    dropdown_data_type: {
      label: 'Data Type',
      type: 'dropdown',
      propKey: 'data_type',
      defaultValue: 'string',
      options: [{
        value: 'number',
        primaryText: 'number'
      }, {
        value: 'string',
        primaryText: 'string'
      }],
      constraints: {
        required: true
      }
    },
    token_tagger_data_type: {
      label: 'Data Type',
      type: 'dropdown',
      propKey: 'data_type',
      defaultValue: ['string'],
      options: [{
        value: 'number',
        primaryText: 'number'
      }, {
        value: ['number'],
        primaryText: 'array of numbers'
      }, {
        value: 'string',
        primaryText: 'string'
      }, {
        value: ['string'],
        primaryText: 'array of strings'
      }],
      constraints: {
        required: true
      },
      transform: (propVal, propDef, inst) => {
        const isMultiValue = _lodash.default.get(inst, 'instanceProps.limit.value') != 1;
        const value = propVal || propDef.defaultValue;
        return isMultiValue ? _lodash.default.castArray(value) : value;
      }
    },
    token_tagger_advanced_search: {
      type: 'toggle',
      label: 'Enable advanced search',
      propKey: 'advancedSearch',
      defaultValue: false
    },
    token_tagger_hydrate_suggested_values: {
      type: 'toggle',
      label: 'Show Suggested Values',
      propKey: 'hydrateSuggestions',
      defaultValue: false
    },
    repeater_show_token_tagger: {
      label: 'Show Auto-Complete',
      type: 'toggle',
      propKey: 'showTokenTagger',
      defaultValue: false,
      constraints: {
        required: true
      }
    },
    repeater_show_menu: {
      label: 'Show Add Button',
      type: 'toggle',
      propKey: 'showMenu',
      defaultValue: true,
      constraints: {
        required: true
      }
    },
    repeater_sortable: {
      label: 'Sortable',
      type: 'toggle',
      propKey: 'sortable',
      defaultValue: true,
      constraints: {
        required: true
      }
    },
    data_type_bool_hidden: {
      label: 'Data Type',
      propKey: 'data_type',
      hidden: true,
      defaultValue: "boolean",
      constraints: {
        required: true
      }
    },
    options: {
      label: 'Options (separated by newlines)',
      type: 'textarea',
      propKey: 'options',
      constraints: {
        required: false
      }
    },
    mode: {
      label: 'Mode',
      type: 'text',
      propKey: 'mode',
      constraints: {
        required: false
      }
    },
    body_class: {
      label: 'Editor Body Class',
      type: 'text',
      propKey: 'body_class',
      constraints: {
        required: false
      }
    },
    value: {
      label: 'Value',
      type: 'text',
      propKey: 'value',
      constraints: {
        required: false
      }
    },
    info: {
      label: 'Info',
      type: 'richtext',
      propKey: 'value',
      constraints: {
        required: false
      }
    },
    limit: {
      label: 'Limit',
      type: 'number',
      propKey: 'limit',
      transform: propVal => {
        return isNaN(parseInt(propVal)) ? undefined : parseInt(propVal);
      },
      constraints: {
        required: false
      }
    },
    label: {
      label: 'Field Label',
      type: 'text',
      propKey: 'label',
      constraints: {
        required: true
      }
    },
    fieldset_label: {
      label: 'Fieldset Label',
      type: 'text',
      propKey: 'label',
      constraints: {
        required: true
      }
    },
    tab_label: {
      label: 'Tab Label',
      type: 'text',
      propKey: 'label',
      constraints: {
        required: true
      }
    },
    componentName: {
      label: 'Type',
      type: 'text',
      propKey: 'componentName',
      constraints: {
        required: true
      }
    },
    key: {
      label: 'API ID',
      type: 'text',
      propKey: 'key',
      defaultToProp: 'label',
      constraints: {
        api_id_alphanumeric: true,
        required: true,
        unique: true,
        pattern: `^(?!(${INVALID_API_KEYS.join('|')})$).*`
        //# TODO: NOTE: not the greatest, we will need to find a way to dynamically pull in all valid loopback filter query keys
        //# like the custom ones
      }
    },

    key_optional: {
      label: 'API ID',
      type: 'text',
      propKey: 'key',
      constraints: {
        api_id_alphanumeric: true,
        unique: true
      }
    },
    constraint_media_type: {
      label: 'Media Type',
      type: 'multiselect',
      defaultValue: ['image'],
      propKey: 'constraint_media_type',
      constraints: {
        required: true
      },
      options: [{
        value: 'image',
        primaryText: 'Image'
      }, {
        value: 'video',
        primaryText: 'Video'
      }, {
        value: 'audio',
        primaryText: 'Audio'
      }, {
        value: 'flash',
        primaryText: 'Flash'
      }, {
        value: 'slideshow',
        primaryText: 'Slideshow'
      }, {
        value: 'text',
        primaryText: 'Text'
      }, {
        value: 'pdf',
        primaryText: 'PDF'
      }, {
        value: 'document',
        primaryText: 'Document'
      }, {
        value: 'generic',
        primaryText: 'Generic'
      }]
    },
    default_value_bool: {
      label: 'Default Value',
      type: 'toggle',
      propKey: 'default',
      defaultValue: false,
      constraints: {
        required: true
      },
      transform: propVal => {
        return !!propVal;
      }
    },
    default_value_text: {
      label: 'Default Value',
      type: 'text',
      propKey: 'default'
    },
    default_value_textarea: {
      label: 'Default Value',
      type: 'textarea',
      propKey: 'default'
    },
    constraint_required: {
      label: 'Field Required?',
      type: 'toggle',
      propKey: 'constraint_required',
      defaultValue: false,
      constraints: {
        required: true
      },
      transform: propVal => {
        return propVal ? propVal : undefined;
      }
    },
    field_meta_locked: {
      label: 'Locked from being edited?',
      type: 'toggle',
      propKey: '_locked',
      defaultValue: false,
      constraints: {
        required: true
      },
      transform: propVal => {
        return propVal ? propVal : undefined;
      }
    },
    constraint_min_char: {
      label: 'Min Char',
      propKey: 'constraint_min',
      type: 'number',
      transform: propVal => {
        return isNaN(parseInt(propVal)) ? undefined : parseInt(propVal);
      }
    },
    constraint_max_char: {
      label: 'Max Char',
      propKey: 'constraint_max',
      type: 'number',
      transform: propVal => {
        return isNaN(parseInt(propVal)) ? undefined : parseInt(propVal);
      }
    },
    constraint_media_min_width: {
      label: 'Min Width (px)',
      propKey: 'constraint_media_min_width',
      type: 'number',
      transform: propVal => {
        return isNaN(parseInt(propVal)) ? undefined : parseInt(propVal);
      }
    },
    constraint_media_max_width: {
      label: 'Max Width (px)',
      propKey: 'constraint_media_max_width',
      type: 'number',
      transform: propVal => {
        return isNaN(parseInt(propVal)) ? undefined : parseInt(propVal);
      }
    },
    constraint_media_min_height: {
      label: 'Min Height (px)',
      propKey: 'constraint_media_min_height',
      type: 'number',
      transform: propVal => {
        return isNaN(parseInt(propVal)) ? undefined : parseInt(propVal);
      }
    },
    constraint_media_max_height: {
      label: 'Max Height (px)',
      propKey: 'constraint_media_max_height',
      type: 'number',
      transform: propVal => {
        return isNaN(parseInt(propVal)) ? undefined : parseInt(propVal);
      }
    },
    constraint_media_exact_width: {
      label: 'Exact Width (px)',
      propKey: 'constraint_media_exact_width',
      type: 'number',
      transform: propVal => {
        return isNaN(parseInt(propVal)) ? undefined : parseInt(propVal);
      }
    },
    constraint_media_exact_height: {
      label: 'Exact Height (px)',
      propKey: 'constraint_media_exact_height',
      type: 'number',
      transform: propVal => {
        return isNaN(parseInt(propVal)) ? undefined : parseInt(propVal);
      }
    },
    constraint_pattern: {
      label: 'Pattern',
      propKey: 'constraint_pattern',
      type: 'text',
      transform: propVal => {
        return typeof propVal === 'string' ? propVal : undefined;
      }
    },
    constraint_pattern_options: {
      label: 'Predefined Patterns',
      propKey: 'constraint_pattern',
      type: 'dropdown',
      options: [{
        value: null,
        primaryText: 'None'
      }, {
        value: '^$|.+@.+',
        primaryText: 'Email'
      }, {
        value: '^$|^\\d\\d\\d\\d-[0-1]\\d-[0-3]\\d',
        primaryText: 'Date (YYYY-MM-DD)'
      }],
      transform: propVal => {
        return typeof propVal === 'string' ? propVal : undefined;
      }
    },
    help_text: {
      label: 'Description',
      propKey: 'description',
      type: 'text'
    },
    getdata: {
      label: 'Get Data Method',
      propKey: 'getdata',
      type: 'text'
    },
    endpoint_builder: {
      label: 'Source',
      propKey: 'url',
      //defaultValue: '/ruby/api/v2/content/options?ruby_client_fk=3&content_subsite_fk=1', //# also default to Custom URL
      type: 'endpoint_builder'
    },
    endpoint_builder_for_dropdown: {
      label: 'Source',
      propKey: 'url',
      type: 'endpoint_builder',
      constraints: {
        contentEndpoint_requiresTemplateSelections: true
      }
    },
    url: {
      label: 'URL',
      propKey: 'url',
      type: 'text'
    },
    constraint_min_value: {
      label: 'Min Value',
      propKey: 'constraint_min_value',
      type: 'number',
      transform: propVal => {
        return isNaN(parseFloat(propVal)) ? undefined : parseFloat(propVal);
      }
    },
    constraint_max_value: {
      label: 'Max Value',
      propKey: 'constraint_max_value',
      type: 'number',
      transform: propVal => {
        return isNaN(parseFloat(propVal)) ? undefined : parseFloat(propVal);
      }
    },
    constraint_exclusive_min_value: {
      label: 'Exclusive Min Value',
      propKey: 'constraint_exclusive_min_value',
      type: 'number',
      transform: propVal => {
        return isNaN(parseFloat(propVal)) ? undefined : parseFloat(propVal);
      }
    },
    constraint_exclusive_max_value: {
      label: 'Exclusive Max Value',
      propKey: 'constraint_exclusive_max_value',
      type: 'number',
      transform: propVal => {
        return isNaN(parseFloat(propVal)) ? undefined : parseFloat(propVal);
      }
    },
    constraint_percent: {
      label: 'Allow Percent Sign?',
      propKey: 'constraint_percent',
      defaultValue: false,
      type: 'toggle'
    },
    constraint_decimal: {
      label: 'Allow Decimals?',
      propKey: 'constraint_decimal',
      defaultValue: false,
      type: 'toggle'
    },
    constraint_max_scale: {
      label: 'Max Number of Decimal Points',
      propKey: 'constraint_max_scale',
      type: 'number',
      transform: propVal => {
        return isNaN(parseInt(propVal)) ? undefined : parseInt(propVal);
      }
    },
    permissions: {
      label: 'Required Permissions',
      type: 'multiselect',
      propKey: 'ruby_permissions',
      constraints: {
        required: false
      },
      options: ruby_permissions
    },
    namespace: {
      label: 'Namespace Regex',
      type: 'text',
      propKey: 'namespace',
      contraints: {
        required: false
      }
    },
    children_hidden: {
      label: 'Children',
      type: 'textarea',
      propKey: 'children',
      hidden: true
    },
    presetColors_hidden: {
      label: 'presetColors',
      type: 'textarea',
      propKey: 'presetColors',
      hidden: true
    },
    pluginOptionsByModuleName_hidden: {
      label: 'pluginOptionsByModuleName',
      type: 'textarea',
      propKey: 'pluginOptionsByModuleName',
      hidden: true
    },
    //# NOTE: might not need this since 'baseFieldMixinOverride.js' 
    //# grabs the props from the component propTypes
    object_options_hidden: {
      label: 'Options',
      type: 'textarea',
      propKey: 'options',
      defaultValue: [],
      hidden: true,
      constraints: {
        required: true
      },
      options: ruby_permissions
    },
    repeater_module_selector: {
      label: 'Repeater Module Options',
      type: 'multiselect',
      propKey: 'repeater_modules',
      url: '/ruby/api/v2/templates/options' + '?ruby_client_fk=3' + '&where=' + JSON.stringify({
        templateType: "repeater_module_template"
      })
    },
    data_type_hidden: {
      label: 'Data Type',
      type: 'string',
      propKey: 'data_type',
      hidden: true,
      constraints: {
        required: true
      }
    },
    format_hidden: {
      label: 'format',
      type: 'string',
      propKey: 'format',
      hidden: true
    },
    unsafeTags_hidden: {
      label: 'unsafeTags',
      type: 'boolean',
      propKey: 'unsafeTags',
      hidden: true
    },
    repeater_token_tagger_options: {
      label: 'Auto-Complete URL',
      type: 'dropdown',
      propKey: 'url',
      options: [{
        value: null,
        primaryText: 'None - show Repeater Module Options'
      }, {
        value: '/ruby/api/v2/templates/profileOptions?ruby_client_fk=3&templateType=profile_template',
        primaryText: 'Profile Modules URL'
      }],
      constraints: {
        profileRepeater_templateType: true
      }
    },
    hintText: {
      label: 'Hint Text',
      type: 'text',
      propKey: 'hintText',
      contraints: {
        required: false
      }
    },
    subtemplate: {
      label: 'Subtemplate',
      propKey: 'subtemplate'
    },
    validate_unique_value: {
      type: 'toggle',
      label: 'Validate Unique Value',
      propKey: 'validateUniqueValue',
      defaultValue: false
    },
    private_field: {
      type: 'toggle',
      label: 'Is private?',
      propKey: 'private',
      defaultValue: false
    }
  }, require('./fieldProperties/Dropdown').propertySpec), require('./fieldProperties/TokenTagger').propertySpec), require('./fieldProperties/Toggles').propertySpecs);
}
const base_fieldInfo_byComponentName = {
  Object: {
    displayText: 'Object',
    propertyKeys: ['key', 'help_text', 'permissions', 'private_field', 'namespace', 'pluginOptionsByModuleName_hidden']
  },
  ColorPicker: {
    displayText: 'Color Picker',
    propertyKeys: ['key', 'help_text', 'permissions', 'private_field', 'namespace', 'pluginOptionsByModuleName_hidden', 'presetColors_hidden']
  },
  ComponentizedDatePicker: {
    displayText: 'Componentized Date Picker',
    propertyKeys: ['key', 'help_text', 'permissions', 'private_field', 'namespace', 'pluginOptionsByModuleName_hidden']
  },
  DatePicker: {
    displayText: 'Date Picker',
    propertyKeys: ['key', 'help_text', 'permissions', 'private_field', 'namespace', 'pluginOptionsByModuleName_hidden']
  },
  DatetimePicker: {
    displayText: 'Date/Time Picker',
    propertyKeys: ['key', 'help_text', 'permissions', 'private_field', 'namespace', 'pluginOptionsByModuleName_hidden']
  },
  Dropdown: require('./fieldProperties/Dropdown').fieldSpec,
  FieldsPicker: {
    displayText: 'Fields Picker',
    propertyKeys: ['key', 'help_text', 'permissions', 'private_field', 'namespace', 'pluginOptionsByModuleName_hidden']
  },
  GooglePreview: {
    displayText: 'Google Preview',
    propertyKeys: ['help_text', 'permissions', 'private_field', 'namespace', 'pluginOptionsByModuleName_hidden']
  },
  ImagePicker: {
    displayText: 'Image Picker',
    propertyKeys: ['key', 'constraint_media_type', 'constraint_media_min_width', 'constraint_media_max_width', 'constraint_media_min_height', 'constraint_media_max_height', 'constraint_media_exact_width', 'constraint_media_exact_height', 'help_text', 'permissions', 'private_field', 'namespace', 'pluginOptionsByModuleName_hidden']
  },
  Info: {
    displayText: 'Info',
    propertyKeys: ['info', 'namespace', 'pluginOptionsByModuleName_hidden']
  },
  InternationalAddress: {
    displayText: 'International Address',
    propertyKeys: ['key', 'help_text', 'permissions', 'private_field', 'namespace', 'pluginOptionsByModuleName_hidden']
  },
  JsonEditor: {
    displayText: 'JSON Editor',
    propertyKeys: ['key', 'help_text', 'permissions', 'private_field', 'namespace', 'data_type_hidden']
  },
  LinkUrl: {
    displayText: 'Link URL',
    propertyKeys: ['key', 'help_text', 'permissions', 'private_field', 'namespace', 'pluginOptionsByModuleName_hidden']
  },
  Number: {
    displayText: 'Number',
    propertyKeys: ['key', 'help_text', 'permissions', 'private_field', 'constraint_min_value', 'constraint_max_value', 'constraint_data_type_number_hidden', 'constraint_exclusive_min_value', 'constraint_exclusive_max_value', 'namespace', 'pluginOptionsByModuleName_hidden', 'validate_unique_value']
  },
  OpenGraph: {
    displayText: 'Open Graph',
    propertyKeys: ['key', 'help_text', 'permissions', 'private_field', 'namespace', 'pluginOptionsByModuleName_hidden']
  },
  ProfileIdentity: {
    displayText: 'Bio Profile Identity',
    propertyKeys: ['key', 'help_text', 'permissions', 'private_field', 'namespace', 'pluginOptionsByModuleName_hidden']
  },
  RadioButtonGroup: {
    displayText: 'Radio',
    propertyKeys: ['key', 'options', 'default_value_text', 'help_text', 'permissions', 'private_field', 'namespace', 'pluginOptionsByModuleName_hidden']
  },
  RichTextEditor: {
    displayText: 'Rich Text Editor',
    propertyKeys: ['key', 'default_value_text', 'constraint_min_char', 'constraint_max_char', 'mode', 'body_class', 'help_text', 'permissions', 'private_field', 'namespace', 'pluginOptionsByModuleName_hidden']
  },
  Repeater: {
    displayText: 'Repeater',
    propertyKeys: ['key', 'limit', 'object_options_hidden', 'children_hidden', 'repeater_module_selector', 'repeater_show_token_tagger', 'repeater_token_tagger_options', 'repeater_show_menu', 'repeater_sortable', 'help_text', 'permissions', 'private_field', 'namespace', 'pluginOptionsByModuleName_hidden']
  },
  Sitemap: {
    displayText: 'Sitemap'
  },
  Text: {
    displayText: 'Text Field',
    propertyKeys: ['key', 'hintText', 'constraint_min_char', 'constraint_max_char', 'constraint_pattern', 'constraint_pattern_options', 'help_text', 'permissions', 'private_field', 'namespace', 'pluginOptionsByModuleName_hidden', 'validate_unique_value', 'unsafeTags_hidden', 'format_hidden']
  },
  Textarea: {
    displayText: 'Text Area',
    propertyKeys: ['key', 'default_value_text', 'constraint_min_char', 'constraint_max_char', 'constraint_pattern', 'help_text', 'permissions', 'private_field', 'namespace', 'pluginOptionsByModuleName_hidden', 'unsafeTags_hidden']
  },
  TimePicker: {
    displayText: 'Time Picker',
    propertyKeys: ['key', 'help_text', 'permissions', 'private_field', 'namespace', 'pluginOptionsByModuleName_hidden']
  },
  TokenTagger: require('./fieldProperties/TokenTagger').fieldSpec,
  Toggle: {
    displayText: 'Toggle',
    propertyKeys: ['key', 'default_value_bool', 'data_type_bool_hidden', 'help_text', 'permissions', 'private_field', 'namespace', 'pluginOptionsByModuleName_hidden']
  },
  Toggles: require('./fieldProperties/Toggles').fieldSpec,
  Url: {
    displayText: 'URL Field',
    propertyKeys: ['key', 'hintText', 'default_value_text', 'constraint_min_char', 'constraint_max_char', 'constraint_pattern', 'help_text', 'permissions', 'private_field', 'namespace', 'pluginOptionsByModuleName_hidden']
  }
};

/* //# DEPRECATED? 20190726 - not used
const propertyKeys_byType = _.reduce(
    base_fieldInfo_byComponentName,
    (propertiesKeys_byType, typeArray, componentName) => {
        propertiesKeys_byType[componentName] = base_fieldInfo_byComponentName[componentName].propertyKeys;
        return propertiesKeys_byType;
    },
    {}
);
*/
exports.base_fieldInfo_byComponentName = base_fieldInfo_byComponentName;