"use strict";

var _reduxBatchedActions = require("redux-batched-actions");
module.exports = function () {
  const id = this.getID();
  const TYPES = {
    APPEND_ITEM: `@ruby-app/${id}/APPEND_ITEM`,
    SELECT_ITEM: `@ruby-app/${id}/SELECT_ITEM`,
    CLEAR_SELECTED_ITEM: `@ruby-app/${id}/CLEAR_SELECTED_ITEM`,
    REPLACE_ITEM: `@ruby-app/${id}/REPLACE_ITEM`,
    UPDATE_DISPLAY_VALUE_BY_HASH: `@ruby-app/${id}/UPDATE_DISPLAY_VALUE_BY_HASH`
  };
  return {
    TYPES,
    generators: {
      appendItem: function (item) {
        const {
          generators
        } = this.getAction();
        const selfFieldValue = this.getFieldValue() || [];
        return generators.setFieldValue(selfFieldValue.concat(item));
      },
      selectItem: function (item) {
        const {
          TYPES
        } = this.getAction();
        return {
          type: TYPES.SELECT_ITEM,
          payload: {
            selectedValue: item
          }
        };
      },
      clearSelectedItem: function () {
        const {
          TYPES
        } = this.getAction();
        return {
          type: TYPES.CLEAR_SELECTED_ITEM
        };
      },
      replaceChildItem: function (existingItem, newItem) {
        const {
          TYPES
        } = this.getAction();
        return {
          type: TYPES.REPLACE_ITEM,
          payload: {
            existingItem,
            newItem
          }
        };
      },
      updateDisplayValueByHash: function (displayValueByHash) {
        const {
          TYPES
        } = this.getAction();
        return {
          type: TYPES.UPDATE_DISPLAY_VALUE_BY_HASH,
          payload: {
            displayValueByHash
          }
        };
      },
      validateChildrenFields: function () {
        const selfModule = this;
        const children = this.getChildren();
        let collector = [];
        const collectAction = value => {
          collector.push(value);
        };
        children.forEach(child => {
          child.iterativelyTraverseChildren_withCallback(rcElement => {
            const actions = rcElement.getAction().generators;
            if (actions.hasOwnProperty('validateAllFields')) {
              collectAction(actions.validateAllFields());
            }
          });
        });
        return (0, _reduxBatchedActions.batchActions)(collector);
        return dispatch => {
          formModule.iterativelyTraverseChildren_withCallback(rcElement => {
            const actions = rcElement.getAction().generators;
            actions.validateAllFields && dispatch(actions.validateAllFields());
          });
        };
      }
    }
  };
};