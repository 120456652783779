"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.customComponentsByType = exports.customComponents = void 0;
var _DateFormatterGenerator = _interopRequireDefault(require("./DateFormatterGenerator"));
var _DatetimeFormatterGenerator = _interopRequireDefault(require("./DatetimeFormatterGenerator"));
var _HtmlFormatterGenerator = _interopRequireDefault(require("./HtmlFormatterGenerator"));
var _IntegerFormatterGenerator = _interopRequireDefault(require("./IntegerFormatterGenerator"));
var _ImageFormatterGenerator = _interopRequireDefault(require("./ImageFormatterGenerator"));
var _KeyToHumanPrettifierGenerator = _interopRequireDefault(require("./KeyToHumanPrettifierGenerator"));
var _LogLevelFormatterGenerator = _interopRequireDefault(require("./LogLevelFormatterGenerator"));
var _NumberFormatterGenerator = _interopRequireDefault(require("./NumberFormatterGenerator"));
var _PublishGenerator = _interopRequireDefault(require("./PublishGenerator"));
var _PublishAndDateFormatterGenerator = _interopRequireDefault(require("./PublishAndDateFormatterGenerator"));
var _RelevanceFormatterGenerator = _interopRequireDefault(require("./RelevanceFormatterGenerator"));
var _RubyActionsGenerator = _interopRequireDefault(require("./RubyActionsGenerator"));
var _SweeperErrorValueGenerator = _interopRequireDefault(require("./SweeperErrorValueGenerator"));
var _TemplateKeyToTemplateNameGenerator = _interopRequireDefault(require("./TemplateKeyToTemplateNameGenerator"));
var _TimeFormatterGenerator = _interopRequireDefault(require("./TimeFormatterGenerator"));
var _ValueInObjectGenerator = _interopRequireDefault(require("./ValueInObjectGenerator"));
var _ObjectGenerator = _interopRequireDefault(require("./ObjectGenerator"));
var _BooleanFormatterGenerator = _interopRequireDefault(require("./BooleanFormatterGenerator"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//# customComponents

const customComponents = {
  DateFormatterGenerator: _DateFormatterGenerator.default,
  KeyToHumanPrettifierGenerator: _KeyToHumanPrettifierGenerator.default,
  PublishGenerator: _PublishGenerator.default,
  PublishAndDateFormatterGenerator: _PublishAndDateFormatterGenerator.default,
  RubyActionsGenerator: _RubyActionsGenerator.default
};
exports.customComponents = customComponents;
const customComponentsByType = {
  date: _DateFormatterGenerator.default,
  datetime: _DatetimeFormatterGenerator.default,
  integer: _IntegerFormatterGenerator.default,
  image: _ImageFormatterGenerator.default,
  html: _HtmlFormatterGenerator.default,
  humanize: _KeyToHumanPrettifierGenerator.default,
  logLevel: _LogLevelFormatterGenerator.default,
  number: _NumberFormatterGenerator.default,
  publish: _PublishGenerator.default,
  publishAndDate: _PublishAndDateFormatterGenerator.default,
  relevance: _RelevanceFormatterGenerator.default,
  rubyActions: _RubyActionsGenerator.default,
  sweeperErrorValue: _SweeperErrorValueGenerator.default,
  templateName: _TemplateKeyToTemplateNameGenerator.default,
  time: _TimeFormatterGenerator.default,
  valueInObject: _ValueInObjectGenerator.default //# TODO: consider replacing this with just ObjectGenerator
  ,
  object: _ObjectGenerator.default,
  booleanString: _BooleanFormatterGenerator.default
};
exports.customComponentsByType = customComponentsByType;