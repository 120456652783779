"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.decoratedFunctionChain = decoratedFunctionChain;
exports.decoratedFunctionReduce_booleanAnd = decoratedFunctionReduce_booleanAnd;
function decoratedFunctionChain(theFunction) {
  //# TODO: change the functions instead
}
function decoratedFunctionReduce_booleanAnd(theFunction, functionName) {
  function decoratedFunction() {
    const mixins = this.getMixins();
    const allFunctions = mixins.reduce((collector, mixin) => {
      if (mixin.hasOwnProperty(functionName) && mixin[functionName] != decoratedFunction) {
        collector.push(mixin[functionName]);
      }
      return collector;
    }, [theFunction]);
    return allFunctions.reduce((collector, theFunction) => {
      if (!collector) {
        return collector;
      }
      return collector && theFunction.apply(this, arguments);
    }, true);
  }
  decoratedFunction.original = theFunction;
  return decoratedFunction;
}