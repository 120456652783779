"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const fieldSelflessMixin = {
  mixinName: 'rubyComponentMixinFieldSelfless',
  _formValueFromLocalState: function (selfState, isError, predicateFormatter, entireState) {
    let options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
    if (selfState == undefined) {
      return undefined;
    }
    const selfID = this.getID();
    const selfKey = this.props.key;
    const children = this.getChildren();
    const arrOfReducedChildrenState = _.reduce(children, (collector, child, index) => {
      const childID = child.getID(); //# TODO: change caused ID isn't unique enough
      const childFormValue = child.formValueFromLocalState ? child._formValueFromLocalState(selfState[childID], isError, predicateFormatter, entireState, options) : undefined;
      if (childFormValue != undefined) {
        collector.push(childFormValue);
      }
      return collector;
    }, []);
    const hiddenValues = this.props.hiddenValues || {};
    const formHiddenValues = isError ? {} : hiddenValues;
    const reducedChildrenState = _extends({}, formHiddenValues, ...arrOfReducedChildrenState);
    return reducedChildrenState;
  }
};
module.exports = fieldSelflessMixin;