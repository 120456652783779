"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const _ = require('lodash');
const path = require('path');
const rubyLogger = require('@rubyapps/ruby-logger');
const packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
const logger = rubyLogger.getLogger(packageName);
module.exports = {
  getProps: function (shouldHydrateProps) {
    const mergedProps = _extends({}, this.props, this.getState().props);
    let finalProps = mergedProps;
    if (shouldHydrateProps) {
      logger.warn('shouldHydrateProps is not supported in this mixin');
    }
    return finalProps;
  },
  action: function () {
    const TYPES = (id => ({
      SET_PROPS: `@@ruby-app/${id}/SET_PROPS`
    }))(this.getID());
    return {
      TYPES,
      generators: {
        setProps: function (props) {
          const TYPES = this.getAction().TYPES;
          return {
            type: TYPES.SET_PROPS,
            payload: {
              props
            }
          };
        }
      }
    };
  },
  reducer: function () {
    let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
      props: {}
    };
    let action = arguments.length > 1 ? arguments[1] : undefined;
    const {
      TYPES
    } = this.getAction();
    const {
      type,
      payload
    } = action;
    switch (type) {
      case TYPES.SET_PROPS:
        return _extends({}, state, {
          props: _extends({}, state.props, payload.props)
        });
      default:
        return state;
    }
  },
  getFromListerContext: function () {
    //# TODO: support overriding context?
    //# we should use the same context used for the lister pages 

    if (!this.getState().routeParams.routeActive) {
      console.warn(`getFromListerContext() was called for ${this.getID()} when it is not active`);
    }
    return this.props.fromListerContext || window.location.pathname;
  },
  listerQueryFromGriddleState: function (griddleState) {
    let {
      listerConfig: explicitListerConfig,
      additionalQueryParams = {}
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const selfModule = this;
    const {
      rubyClientFKComponent,
      listerConfigsComponent
    } = this.getDependencies();
    const {
      client: rubyClientId,
      subsite: subsiteId
    } = selfModule.getActiveRubyClientInfo();
    const {
      staticFilterTags,
      internalFilterTags,
      internalFieldsQuery = {
        fields: [],
        expand: []
      },
      listerConfig: props__listerConfig,
      templateKey = selfModule.getState().routeParams.template
      //# NOTE: not great, but for now, we have two ways to set templateKey, either it's statically defined in props, or it's part of the route
    } = selfModule.getProps ? selfModule.getProps() : selfModule.props;
    const defaultListerConfig = listerConfigsComponent.listerConfigForKey(templateKey, selfModule);
    const listerConfig = explicitListerConfig || defaultListerConfig || props__listerConfig;
    const {
      pageProperties: {
        currentPage: page,
        pageSize,
        sortColumns: sortColumnsFromTableState,
        sortAscending,
        sortDescending
      },
      filter
    } = griddleState;
    const filterAsObject = _.isString(filter) ? {
      filterTags: [],
      queryString: filter
    } : _extends({}, filter);

    //# include staticFilterTags and internalFilterTags in an adjacent where filter
    const filterAsObjectArray = [filterAsObject, internalFilterTags && internalFilterTags.length ? {
      filterTags: internalFilterTags
    } : undefined, staticFilterTags && staticFilterTags.length ? {
      filterTags: staticFilterTags
    } : undefined].filter(n => n);
    const sortDirectionFromTableState = sortAscending ? 'asc' : 'desc';
    const sortDict = selfModule.utils.getSnameAndSdirAsDictFromSortColumns_andSortDir_usingGriddleStore(sortColumnsFromTableState, sortDirectionFromTableState, griddleState);
    const columns = selfModule.utils.getColumnsFromGriddleState_defaultingToColumns(griddleState, listerConfig.columns);
    const fieldsQueryObject = selfModule.utils.formatRequestPropsForColumns(columns);
    const allWhereFilters = filterAsObjectArray.map(filterObject => selfModule.utils.savedSearchToWhereFilter(filterObject, templateKey)).filter(n => n && _.values(n).filter(o => o !== undefined).length)
    //# NOTE: {key: undefined} gets translated as {} when calling on JSON.stringify
    //# We could also be explicit and perform a _.mapValue() and translate undefined to neq: [] or neq: null
    //# NOTE that we need to allow null's through though
    ;

    //# == Handling Custom Filters ===========================================//
    //# NOTE: for now we'll do this
    //# but eventually we want to fold this into the 
    //# filter.where query param
    const utilityBarState = griddleState.utilityBarProperties || {};
    const customFilters = _.get(listerConfig, 'filters', []);
    const customFiltersState = _.get(utilityBarState, 'customFilters');
    const defaultQuery = _.get(selfModule, 'props.listerConfig.defaultQuery') || {};
    const filterQuery = _.reduce(customFilters, (result, filterSpec, idx) => {
      const filterName = filterSpec.name || filterSpec.key;
      const filterKey = filterSpec.key || filterSpec.name;
      const filterValue = _.get(customFiltersState, [filterKey] //[filterName]
      , filterSpec.defaultValue);
      return filterValue ? _extends({}, result, filterSpec.isPlainArg ? {
        [filterKey]: filterValue
      } : {
        [`filterType${idx}`]: filterKey,
        [`filterVal${idx}`]: filterValue
      }) : result;
    }, {});
    const whereFilter = allWhereFilters.length > 1 ? {
      and: allWhereFilters
    } : allWhereFilters[0];

    //# NOTE: if we need to, we'll add a query modifier middleware to allow listers to modify the resultant queries
    const baseQuery = _objectSpread({
      p: page - 1,
      psize: pageSize,
      search: filterAsObject.queryString,
      fields: _.uniq(fieldsQueryObject.fields.concat(internalFieldsQuery.fields))
      //# NOTE: fields can be keypaths, the server only uses the root key when deferring to loopback to retrieve specific fields
      ,
      expand: _.uniq((fieldsQueryObject.expand || []).concat(internalFieldsQuery.expand || []))
      //# TODO: expand should contain the dataPath because the server needs the keypath to the profile selector,
      //# and the hydfration needs the last key in the path
      ,
      sname: sortDict.sname,
      sdir: sortDict.sdir,
      ruby_client_fk: rubyClientId,
      content_subsite_fk: subsiteId,
      'filter.where': whereFilter ? JSON.stringify(whereFilter) : undefined
    }, additionalQueryParams);
    return _extends({}, baseQuery, defaultQuery, filterQuery);
  }
};