"use strict";

const _ = require('lodash');
const RubyComponent = require('@rubyapps/ruby-component');
const PropTypes = RubyComponent.PropTypes;

//# NOTE: uses form data from a form field component to set the customFilter values. This is primarily used by the MediaPicker to set the Space filter value
const formdataDependencyListerConfigMixin = {
  mixinName: 'rubyComponentMixinListFormDataDependency__listerConfigs',
  mixinIndex: 100 //# force this mixin to be last
  ,
  augmentedListerConfig: function (listerConfig, key, callingListerModule) {
    if (callingListerModule.props.formElementForFilters) {
      const formElement = callingListerModule.getFormElement();
      listerConfig.filters.forEach(filterSpec => {
        const filterKey = filterSpec.name || filterSpec.key;
        const formField = formElement.findDescendentBy(node => node.props.key == filterKey);
        if (formField) {
          const formFieldValue = formField.formValue()[formField.props.key];
          const normalizedFormFieldValue = _.isArray(formFieldValue) ? _.first(formFieldValue) : formFieldValue;
          //# TODO: if we want to support complex values like arrays we'll need
          //# to update this. for now, we assume it's not an array
          // RT#85842: Disable the filter if there's only 1 option, since the content shown must be for that option.
          // If the form field value is an array with multiple elements, like the globaldata_ancestor_fk field having
          // multiple spaces, then don't disable the filter and allow users to select options.
          // This change was made to allow users to select media from any space for a mediapicker on an SD edit page
          // when the page is related to multiple spaces.
          // Ideally, for a page related to multiple spaces, only those spaces would be options in the filter.
          // See https://gitlab.intranet.rubensteintech.com/project/ruby-app/-/issues/1406 for more info.
          filterSpec.disabled = !_.isArray(formFieldValue) || _.isArray(formFieldValue) && _.size(formFieldValue) < 2;
          filterSpec.defaultToFirstOption = false;
          filterSpec.defaultValue = normalizedFormFieldValue;
          filterSpec.value = normalizedFormFieldValue;
        }
      });
    }
    return listerConfig;
  }
};
module.exports = formdataDependencyListerConfigMixin;