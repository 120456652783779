"use strict";

const RubyComponent = require('@rubyapps/ruby-component');
const PropTypes = RubyComponent.PropTypes;
const SAVED_SEARCH_LISTER_CONSTANTS = require('../../../ruby-component-saved-search-lister/src/common/constants');
const savedSearchMixin = {
  mixinName: 'rubyComponentMixinListerSavedSearch',
  propTypes: {
    savedSearchListerID: PropTypes.string.isRequired,
    savedSearchContext: PropTypes.string
  },
  getDefaultProps: function (props, componentClass) {
    return {
      savedSearchListerID: SAVED_SEARCH_LISTER_CONSTANTS.COMPONENT_NAME,
      savedSearchContext: null //props.id || componentClass.componentName
    };
  },

  getSavedSearchContext: function () {
    //# TODO: support overriding context?
    //# we should use the same context used for the lister pages 

    if (!this.getState().routeParams.routeActive) {
      console.warn(`getSavedSearchContext() was called for ${this.getID()} when it is not active`);
    }
    return this.props.savedSearchContext || window.location.pathname;
  },
  getSavedSearchListerDependencies: function () {
    const rootModule = this.getRoot();
    const savedSearchLister = rootModule.findDescendentByID(this.props.savedSearchListerID);
    const savedSearchListerUrl = rootModule.getUrlForComponent_fromModule_withParams(savedSearchLister, this);
    return {
      savedSearchLister,
      savedSearchListerUrl //# DEPRECATED 
      ,
      savedSearchListerRoute: savedSearchListerUrl
    };
  }
};
module.exports = savedSearchMixin;