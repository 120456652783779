"use strict";

function typesWithID(id) {
  return {
    SET_IS_LOADING: `@@ruby-app/${id}/SET_IS_LOADING`
  };
}
const generators = {
  setIsLoading: function (isLoading) {
    const {
      TYPES,
      generators
    } = this.getAction();
    return {
      type: TYPES.SET_IS_LOADING,
      payload: {
        isLoading
      }
    };
  }
};
module.exports = function () {
  return {
    TYPES: typesWithID(this.getID()),
    generators
  };
};