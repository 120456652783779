"use strict";

var _constants = _interopRequireDefault(require("./constants"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const {
  PROPOSAL_APP_ROUTE
} = _constants.default;
const rubyAccessManager = require('@rubyapps/ruby-access-manager');
function getProposalSubsiteId_withSettings(settings) {
  const proposalSubsiteId = rubyAccessManager.getSubsiteId_fromSettings_forRoute(settings, PROPOSAL_APP_ROUTE);
  return proposalSubsiteId;
}
module.exports = {
  getProposalSubsiteId_withSettings
};