"use strict";

const path = require('path');
const rubyLogger = require('@rubyapps/ruby-logger');
const packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
const logger = rubyLogger.getLogger(packageName);
const EventEmitter = require('events');
const _ = require('lodash');
const {
  NAMESPACES
} = require('../common/constants');
const STATE_EVENT_SPECS_KEY = 'stateEventSpecs';
module.exports = {
  mixinName: 'rubyComponentMixinEventEmitter',
  doIfShouldEmitForEventName: function (eventName) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const eventEmitter = this.eventEmitter();
    const selfState = this.getState();
    const selfID = this.getID();
    const {
      onAny
    } = options;

    //console.debug(`[${selfID}] checking for eventName: [${eventName}]`, selfState);

    const selectedStateValue = this.getStateForEventName(eventName);
    const cachedStateValue = this._getCachedStateForEventName(eventName);
    if (selectedStateValue !== undefined) {
      if (onAny || !_.isEqual(selectedStateValue, cachedStateValue)) {
        logger.debug(`[${selfID}] emitting eventName: [${eventName}] with selected state: `, selectedStateValue);
        this._updateCachedStateForEventName(eventName, selectedStateValue);
        eventEmitter.emit(eventName, selectedStateValue, selfState, cachedStateValue //# prevWatchedState
        );
      }
    }
  },

  isEventNameValidNamespace: function (eventName) {
    const eventPair = eventName.split(':');
    if (eventPair[0] == NAMESPACES.STATE) {
      return true;
    }
    return false;
  },
  getStateForEventName: function (eventName, selfState) {
    const eventPair = eventName.split(':');
    if (eventPair[0] != NAMESPACES.STATE) {
      throw new Error(`Event: [${eventName}] is not compatible with ${this.mixinName}`);
    }
    selfState = selfState || this.getState();
    const keypath = eventPair[1];
    const selectedStateValue = _.get(selfState, keypath);
    return selectedStateValue;
  },
  onReduxInit: function () {
    return this.getStore().subscribe(() => {
      const eventEmitter = this.eventEmitter();

      //# iterate through the events and select the keypaths based on the registered events
      //# emit if the value is not undefined
      const eventNames = Object.keys(this.getStatefulCacheForKey(STATE_EVENT_SPECS_KEY) || {});

      //Object.keys(eventEmitter._events).forEach(eventName => {
      eventNames.forEach(eventName => {
        const options = this._getCachedOptionsForEventName(eventName);
        this.doIfShouldEmitForEventName(eventName, options);
      });
    });
  },
  onUnmount: function () {
    const eventEmitter = this.eventEmitter();
    eventEmitter.removeAllListeners();
  },
  eventEmitter: function () {
    if (this._eventEmitter) {
      return this._eventEmitter;
    }

    //# set up the EventEmitter instance
    const eventEmitter = new EventEmitter();
    this._eventEmitter = eventEmitter;

    //# list for event registrations so we can keep track
    /*
    eventEmitter.on('newListener', (event, listener) => {
        
        debugger;
    });
    */
    //# NOTE: for now, we can just do Object.keys(eventEmitter._events)
    //# but caution that _events is an internal property

    return eventEmitter;
  }
  /**
   * Register an event handle
   * @param {string} eventName - formatted to be a local keypath to this module's state (eg. 'state:forms')
   * @param {Function} eventHandler - (selectedState, moduleState) => {}
   * @param {Object} options
   * @param {boolean} options.preempt - call on emit if the state we're expecting is available
   * @param {boolean} options.onAny - emit regardless of whether state has changed. By default, it only emits on state changes
   **/,
  on: function (eventName, eventHandler) {
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    const eventEmitter = this.eventEmitter();

    //# TODO: check if the eventName is a valid 'state:keypath' format
    //# if it's not, we just do a regular eventEmitter bind

    eventEmitter.on.apply(eventEmitter, arguments);
    if (this.isEventNameValidNamespace(eventName)) {
      this._setInitialCachedStateForEventName(eventName, options);
      if (options.preempt) {
        //# if we're preempting, then we need to allow any through
        this.doIfShouldEmitForEventName(eventName, {
          onAny: true
        });
      }
    }
  },
  off: function () {
    const eventEmitter = this.eventEmitter();
    return eventEmitter.removeListener.apply(eventEmitter, arguments);
  },
  once: function (eventName, eventHandler) {
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    const eventEmitter = this.eventEmitter();
    eventEmitter.once.apply(eventEmitter, arguments);
    if (this.isEventNameValidNamespace(eventName)) {
      this._setInitialCachedStateForEventName(eventName, options);
      if (options.preempt) {
        this.doIfShouldEmitForEventName(eventName, {
          onAny: true
        });
      }
    }
  },
  emit: function () {
    const eventEmitter = this.eventEmitter();
    eventEmitter.emit.apply(eventEmitter, arguments);
  }

  //# == CACHE HANDLING =====================================//
  ,
  _getCachedStateForEventName: function (eventName) {
    return this.getStatefulCacheForKey([STATE_EVENT_SPECS_KEY, eventName, 'state']);
  },
  _getCachedOptionsForEventName: function (eventName) {
    return this.getStatefulCacheForKey([STATE_EVENT_SPECS_KEY, eventName, 'options']);
  },
  _updateCachedStateForEventName: function (eventName, state) {
    this.setStatefulCacheForKey([STATE_EVENT_SPECS_KEY, eventName, 'state'], state);
  },
  _setInitialCachedStateForEventName: function (eventName, options) {
    this.setStatefulCacheForKey([STATE_EVENT_SPECS_KEY, eventName], {
      state: this.getStateForEventName(eventName),
      options
    });
  },
  _clearCachedStateForEventName: function (eventName) {
    this.clearStatefulCacheForKey([STATE_EVENT_SPECS_KEY, eventName]);
  }
};