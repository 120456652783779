"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _reactRedux = require("react-redux");
var _ConnectorMappersGenerator = _interopRequireDefault(require("@rubyapps/ruby-component-mixin-field-base/src/client/reactComponents/ConnectorMappersGenerator"));
var _Number = _interopRequireDefault(require("./Number"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function generator() {
  const selfModule = this;
  const {
    mapStateToProps: defaultMapStateToProps,
    mapDispatchToProps: defaultMapDispatchToProps
  } = _ConnectorMappersGenerator.default.call(this);
  return (0, _reactRedux.connect)(defaultMapStateToProps, defaultMapDispatchToProps)(_Number.default);
}