"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addSortColumn = addSortColumn;
exports.clearStaticData = clearStaticData;
exports.filterData = filterData;
exports.filterDataByColumn = filterDataByColumn;
exports.initializeGrid = initializeGrid;
exports.loadData = loadData;
exports.loadNext = loadNext;
exports.loadPage = loadPage;
exports.loadPrevious = loadPrevious;
exports.prependStaticData = prependStaticData;
exports.refreshCurrentPage = refreshCurrentPage;
exports.removeGrid = removeGrid;
exports.requestToLoadData = requestToLoadData;
exports.setColumnProperties = setColumnProperties;
exports.setColumnRenderedSizeProperties = setColumnRenderedSizeProperties;
exports.setColumnResizeProperties = setColumnResizeProperties;
exports.setPageSize = setPageSize;
exports.setStaticData = setStaticData;
exports.sort = sort;
exports.toggleColumn = toggleColumn;
var _lodash = _interopRequireDefault(require("lodash"));
var types = _interopRequireWildcard(require("../constants/action-types"));
var _reduxBatchedActions = require("redux-batched-actions");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function initializeGrid(properties) {
  return {
    type: types.GRIDDLE_INITIALIZED,
    properties
  };
}
function removeGrid() {
  return {
    type: types.GRIDDLE_REMOVED
  };
}
function loadData(data) {
  return {
    type: types.GRIDDLE_LOADED_DATA,
    data
  };
}
function requestToLoadData(data, properties) {
  //# types.GRIDDLE_LOAD_DATA
  return dispatch => {
    if (data) {
      dispatch(loadData(data));
    }
  };
}
function filterData(filter) {
  return {
    type: types.GRIDDLE_FILTERED,
    filter: filter
  };
}
function filterDataByColumn(filter, column) {
  return {
    type: types.GRIDDLE_FILTERED_BY_COLUMN,
    filter,
    column
  };
}
function setPageSize(pageSize) {
  return {
    type: types.GRIDDLE_SET_PAGE_SIZE,
    pageSize
  };
}
function sort(column) {
  return {
    type: types.GRIDDLE_SORT,
    sortColumns: [column]
  };
}
function addSortColumn(column) {
  return {
    type: types.GRIDDLE_ADD_SORT_COLUMN,
    sortColumn: column
  };
}
function loadNext() {
  return (0, _reduxBatchedActions.batchActions)([_loadNext.apply(this, arguments), clearStaticData()]);
}
function loadPrevious() {
  return (0, _reduxBatchedActions.batchActions)([_loadPrevious.apply(this, arguments), clearStaticData()]);
}
function loadPage(number) {
  return (0, _reduxBatchedActions.batchActions)([_loadPage.apply(this, arguments), clearStaticData()]);
}
function _loadNext() {
  return {
    type: types.GRIDDLE_NEXT_PAGE
  };
}
function _loadPrevious() {
  return {
    type: types.GRIDDLE_PREVIOUS_PAGE
  };
}
function _loadPage(number) {
  return {
    type: types.GRIDDLE_GET_PAGE,
    pageNumber: number
  };
}
function refreshCurrentPage() {
  return {
    type: types.GRIDDLE_REFRESH_CURRENT_PAGE
  };
}
function toggleColumn(columnId) {
  return {
    type: types.GRIDDLE_TOGGLE_COLUMN,
    columnId
  };
}
function setColumnProperties(columnId, properties) {
  return {
    type: types.GRIDDLE_SET_COLUMN_PROPERTIES,
    columnId,
    properties
  };
}
function setColumnResizeProperties(propertiesByID) {
  return {
    type: types.GRIDDLE_SET_COLUMN_RESIZE_PROPERTIES,
    propertiesByID
  };
}
function setColumnRenderedSizeProperties(propertiesByID) {
  return {
    type: types.GRIDDLE_SET_COLUMN_RENDERED_SIZE_PROPERTIES,
    propertiesByID
  };
}
function clearStaticData() {
  return setStaticData([]);
}
function prependStaticData(staticData) {
  return {
    type: types.GRIDDLE_PREPEND_STATIC_DATA,
    staticData: _lodash.default.castArray(staticData)
  };
}
function setStaticData(staticData) {
  return {
    type: types.GRIDDLE_SET_STATIC_DATA,
    staticData
  };
}