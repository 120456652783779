"use strict";

const url = require('url');
const RubyComponentFeSettings__CONSTANTS = require('@rubyapps/ruby-component-frontend-settings/src/common/constants');
const supportMixin = {
  mixinName: 'rubyComponentMixinSupport',
  createSupportLink: function () {
    const selfModule = this;
    const store = selfModule.getStore();
    if (store == undefined) return null;
    const applicationState = store.getState();
    const userObject = selfModule.getCurrentUserState(); // depends on access-control-mixin
    if (!userObject) return null;
    const {
      first_name: userFirstName,
      last_name: userLastName
    } = userObject;
    let feSettingsSelector = this._feSettingsSelector;
    if (!feSettingsSelector) {
      const rootModule = selfModule.getRoot();
      const feSettingsID = selfModule.props.feSettingsID || RubyComponentFeSettings__CONSTANTS.COMPONENT_NAME;
      const feSettingsComponent = rootModule.findDescendentByID(feSettingsID);
      feSettingsSelector = feSettingsComponent.getDefaultSelector();
      this._feSettingsSelector = feSettingsSelector;
    }
    const feConstants = feSettingsSelector(applicationState);
    const {
      RUBY_BRANDING: rubyBranding,
      CLIENT_NAME: clientName,
      version,
      DEPLOY_VERSION
    } = feConstants;
    if (!clientName || !rubyBranding) return null;
    const subject = `${clientName} - ${rubyBranding} support request for ${userFirstName} ${userLastName}`;
    const details = `


========================================================================================================================
User: ${userFirstName} ${userLastName}
RubyApps Version: ${version}
Project Version: ${DEPLOY_VERSION}
Browser Info:
    userAgent:  ${window.navigator.userAgent}
    languages:  ${JSON.stringify(window.navigator.languages)}
========================================================================================================================
`;
    const urlObject = {
      protocol: 'mailto',
      slashes: false,
      auth: 'support',
      host: 'rubensteintech.com',
      query: {
        subject,
        body: details
      }
    };
    return url.format(urlObject);
  } // createSupportLink
};

module.exports = supportMixin;