"use strict";

const formValue__repeater__office = require('./testData/formValue__repeater__office');
const sweeperError__repeater__office = require('./testData/sweeperError__repeater__office');
const tests = {
  data: {
    formValue__repeater__office,
    sweeperError__repeater__office
  },
  hydrateFormValue: function (data) {
    const contentForm = window.rubyApp.findDescendentsBy(el => el.props.key == 'content')[0];
    store.dispatch(contentForm.getAction().generators.seedWithFormData(data, true));
  },
  hydrateSweeperError: function (errorMessagesTree) {
    const editPage = window.rubyApp.findDescendentsBy(el => el.props.key == 'content')[0].getParent();
    editPage.seedFormWithSweeperErrors(errorMessagesTree);
  }
};
module.exports = tests;