"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _immutable = _interopRequireDefault(require("immutable"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default = _immutable.default.fromJS({
  pageProperties: {
    pageSize: 10,
    currentPage: 1,
    sortColumns: [],
    sortAscending: true
  },
  filter: '',
  columnFilters: []
});
exports.default = _default;