"use strict";

module.exports = {
  COMPONENT_NAME: 'rubyComponentFieldTFA',
  GET_DEFAULT_PROPS: function (props) {
    const {
      mode,
      locked,
      disabled
    } = props;
    const enabledKey = qualifyTFAComponentKey(props.keyPrefix, 'enabled');
    const registeredKey = qualifyTFAComponentKey(props.keyPrefix, 'registered');
    const secretKey = qualifyTFAComponentKey(props.keyPrefix, 'secret');
    const tokenKey = qualifyTFAComponentKey(props.keyPrefix, 'token');
    return {
      //# NOTE: this is currently not used to actually render the children components
      //# nor is it used to generate the json-schema
      //# this is specifically used by browser-side content-property-helper 
      //# in order to 
      childrenPropsByKey: {
        [enabledKey]: {
          label: "Enable MFA",
          key: enabledKey,
          componentName: 'Toggle' //# NOTE: for now, this doesn't do anything
        },

        [registeredKey]: {
          label: "Registered MFA",
          key: registeredKey,
          componentName: 'Hidden'
        },
        [secretKey]: {
          label: "MFA Secret",
          key: secretKey,
          componentName: 'Hidden'
        },
        [tokenKey]: {
          label: "MFA Token",
          key: tokenKey,
          componentName: 'Text'
        }
      }
    };
  }
};
function qualifyTFAComponentKey(qualifier, key) {
  return `${qualifier}_${key}`;
}