"use strict";

var _aurl = require("../common/aurl");
var _utils = require("../common/utils");
var _constants = _interopRequireDefault(require("../common/constants"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const RubyComponent = require('@rubyapps/ruby-component');
const PropTypes = RubyComponent.PropTypes;
const RCResourceLocator = RubyComponent.createClass({
  CONSTANTS: _constants.default,
  propTypes: {
    preferPlainUris: PropTypes.bool
  },
  componentName: _constants.default.COMPONENT_NAME,
  getDefaultProps: function () {
    return {
      preferPlainUris: false
    };
  },
  aurl: _aurl.aurl,
  getLargeThumbnailUrl_forAsset: _aurl.getLargeThumbnailUrl_forAsset,
  makePlainUri: _utils.makePlainUri
});
module.exports = RCResourceLocator;