"use strict";

const _ = require('lodash');
const rubyLodash = require('@rubyapps/ruby-lodash');
function getUniqueTopLevelKeysFromDynamicFormOptions(options) {
  // first flatten the dynamic form options into a single array of options for easier iteration
  const optionValues_asFlatArray = _.flatten(options.map(option => _.get(option, 'value') || []));

  // then walk each option value to get the top level keys and accumulate them
  const topLevelKeys_fromOptionValues = optionValues_asFlatArray.reduce((keyCollector, optionValue) => {
    return keyCollector.concat(getTopLevelKeysForOptionValue(optionValue));
  }, []).filter(key => !_.isNil(key));
  return _.uniq(topLevelKeys_fromOptionValues);
}

// walk an option value depth-first to get the top level keys
// once a key is found at a node, then you have obtained a top level key, so no need to walk its children
function getTopLevelKeysForOptionValue(optionValue) {
  // first check the current node for a key
  if (optionValue.key) {
    return [optionValue.key];
  }

  // then check the children nodes for keys
  return rubyLodash.depthFirstReduce(optionValue, (keyCollectorForOptionValue, node) => {
    return node.key ? keyCollectorForOptionValue.concat(node.key) : keyCollectorForOptionValue;
  }, [], node => node.key ? [] : node.children);
}
module.exports = {
  getUniqueTopLevelKeysFromDynamicFormOptions,
  getTopLevelKeysForOptionValue
};