"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  Toggle: {
    style: {
      width: 'auto',
      minWidth: 130,
      marginRight: 4
    }
  }
};
exports.default = _default;