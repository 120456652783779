"use strict";

const jsonSelect = require('JSONSelect');
const UserSamlMixin = {
  mixinName: 'rubyComponentMixinUserSaml',
  augmentedTemplate: function (template, callingModule) {
    const {
      key
    } = template;
    if (key !== 'user') {
      return template;
    }
    const fieldset = {
      componentName: 'Fieldset',
      key: 'saml',
      label: 'Single Sign-On (SSO) Settings',
      children: [{
        componentName: 'Toggle',
        key: 'disable_basic_login',
        label: 'Prohibit local authentication via RubyApps'
      }]
    };
    const hasFieldsetSelector = `:has(:root > .componentName:val("Fieldset")):has(:root > .key:val("${fieldset.key}"))`;
    if (jsonSelect.match(hasFieldsetSelector, template).length > 0) {
      return template;
    }
    jsonSelect.forEach(':has(:root > .componentName:val("Tab")):has(:root > .label:val("User Details"))', template, tab => {
      tab.children.push(fieldset);
    });
    return template;
  }
};
module.exports = UserSamlMixin;