"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const rowHeight = 30;
const borderGray = '#cacaca';
const leftBackgroundGray = '#f7f7f7';
const leftColumnWidth = 200;
const TableHeaderColumnCommon = {
  style: {
    color: 'black',
    height: rowHeight,
    backgroundColor: leftBackgroundGray,
    padding: 0
  }
};
const TableRowColumnCommon = {
  style: {
    height: rowHeight,
    padding: 0,
    verticalAlign: 'top'
  }
};
const CellContentCommon = {
  style: {
    minHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingLeft: 8
  }
};
var _default = {
  Table: {
    bodyStyle: {},
    wrapperStyle: {
      maxHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${borderGray}`
    }
  },
  TableRow: {
    style: {
      height: rowHeight,
      borderBottom: `1px solid ${borderGray}`
    }
  },
  additionalField: {
    style: {
      borderTop: `1px solid ${borderGray}`,
      width: '100%',
      minHeight: rowHeight
    }
  },
  TableRowLast: {
    style: {
      height: rowHeight
    }
  },
  TableHeaderColumnLeft: _lodash.default.merge({}, TableHeaderColumnCommon, {
    style: {
      width: leftColumnWidth,
      borderRight: `1px solid ${borderGray}`
    }
  }),
  TableHeaderColumnRight: _lodash.default.merge({}, TableHeaderColumnCommon, {}),
  TableRowColumnLeft_fromRequired(required) {
    return _lodash.default.merge({}, TableRowColumnCommon, {
      style: {
        backgroundColor: leftBackgroundGray,
        width: leftColumnWidth,
        fontWeight: required ? 'bold' : null,
        borderRight: `1px solid ${borderGray}`
      }
    });
  },
  TableRowColumnRight: _lodash.default.merge({}, TableRowColumnCommon, {}),
  HeaderContentWrapper: _lodash.default.merge({}, CellContentCommon, {
    style: {
      textAlign: 'center'
    }
  }),
  CellContentWrapper: _lodash.default.merge({}, CellContentCommon, {}),
  RichTextCellContentWrapper: {
    style: {
      minHeight: '100%',
      paddingLeft: 0
    }
  },
  SelectField: {
    style: {
      height: rowHeight,
      width: '100%'
    },
    labelStyle: {
      lineHeight: '35px'
    },
    iconStyle: {
      top: '3px'
    },
    underlineStyle: {
      display: 'none'
    }
  }
};
exports.default = _default;