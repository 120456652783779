"use strict";

const _ = require('lodash');
function ancestorIdsForId_usingTogglesById(toggledId, togglesById) {
  const collectedAncestorIds = [];
  let currentId = toggledId;
  while (togglesById[currentId].parentId) {
    const parentId = togglesById[currentId].parentId;
    collectedAncestorIds.push(parentId);
    currentId = parentId;
  }
  return collectedAncestorIds;
}
module.exports = {
  ancestorIdsForId_usingTogglesById
};