"use strict";

var SECOND = 1e3;
var MINUTE = 6e4;
var HOUR = 36e5;
var DAY = 864e5;
var WEEK = 6048e5;
var MONTH = 2628e6;
var HALF_YEAR = 1577e7;
var shortFormat = 'M/D/Y';
var longFormatWithDayAndTime = 'dddd [at] h:mma zz';
var longFormatWithDateAndTime = 'MMMM D [at] h:mma zz';
var longFormatWithDate = 'MMMM D';
var longFormatWithYear = 'MMM D[,] Y';
var FORMATS = {
  seconds: {
    short: 's',
    long: 'second'
  },
  minutes: {
    short: 'm',
    long: 'minute'
  },
  hours: {
    short: 'h',
    long: 'hour'
  },
  days: {
    short: 'd',
    long: 'day'
  }
};
module.exports = initialize(require('moment-timezone'));
function initialize(moment) {
  // these values are also defined in Ruby/Utils/Utils.pm
  var abbrs = {
    "-0500 EST": "ET",
    "-0400 EDT": "ET",
    "-0600 CST": "CT",
    "-0500 CDT": "CT",
    "-0700 MST": "MT",
    "-0600 MDT": "MT",
    "-0800 PST": "PT",
    "-0700 PDT": "PT"
  };
  moment.fn.zoneName = function () {
    var zoneAbbr = this.zoneAbbr();
    var offsetAndAbbrev = this.format('ZZ z');
    if (!zoneAbbr) {
      var zone = moment.tz.guess();
      this.tz(zone);
      zoneAbbr = this.zoneAbbr();
      offsetAndAbbrev = this.format('ZZ z');
    }
    return abbrs[offsetAndAbbrev] || zoneAbbr;
  };
  moment.fn.longButFriendly = function () {
    return friendlyFormat.call(this, 'long');
  };
  moment.fn.shortAndFriendly = moment.fn.friendly = function () {
    return friendlyFormat.call(this, 'short');
  };
  moment.duration.fn.friendlyDuration = function () {
    if (this > moment.duration(1, 'hours')) {
      return this.hours() + 'h ' + this.minutes() + 'm';
    } else if (this > moment.duration(1, 'minutes')) {
      return this.minutes() + 'm ' + this.seconds() + 's';
    } else {
      return this.seconds() + 's';
    }
  };
  return moment;
  function friendlyFormat(format) {
    var diff, formattedStr, num, unit, unitStr;
    diff = Math.abs(this.diff(moment()));
    unit = null;
    num = null;
    if (format === 'short') {
      if (diff <= SECOND) {
        unit = 'seconds';
        num = 1;
      } else if (diff < MINUTE) {
        unit = 'seconds';
      } else if (diff < HOUR) {
        unit = 'minutes';
      } else if (diff < DAY) {
        unit = 'hours';
      } else if (diff < WEEK) {
        unit = 'days';
      } else {
        return this.format(shortFormat);
      }
    } else if (format === 'long') {
      if (diff < MINUTE) {
        return 'Today, just now';
      } else if (diff < 10 * MINUTE) {
        return 'Today, just a few minutes ago';
      } else if (diff < HOUR) {
        unit = 'minutes';
      } else if (diff < DAY) {
        unit = 'hours';
      } else if (diff < WEEK) {
        return this.format(longFormatWithDayAndTime);
      } else if (diff < MONTH) {
        return this.format(longFormatWithDateAndTime);
      } else if (diff < HALF_YEAR) {
        return this.format(longFormatWithDate);
      } else {
        return this.format(longFormatWithYear);
      }
    }
    if (!(num && unit)) {
      num = moment.duration(diff)[unit]();
    }
    unitStr = unit = FORMATS[unit][format];
    if (format === 'long' && num > 1) {
      unitStr += 's';
    }
    formattedStr = format === 'long' ? [num, unitStr, 'ago'].join(' ') : [num + unitStr, 'ago'].join(' ');
    return formattedStr;
  }
  ;
}
;