"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
/* jshint -W138 */

const defaultState = {
  open: false,
  loading: false,
  mode: 'all',
  activities: []
};
module.exports = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  const {
    type,
    payload
  } = action;
  const {
    TYPES
  } = this.getAction();
  switch (type) {
    case TYPES.SET_DISPLAY:
      return _extends({}, state, {
        open: payload.open
      });
    case TYPES.SET_LOADING:
      return _extends({}, state, {
        loading: payload.loading
      });
    case TYPES.SET_MODE:
      return _extends({}, state, {
        mode: payload.mode
      });
    case TYPES.SET_ACTIVITIES:
      return _extends({}, state, {
        activities: payload.activities
      });
    default:
      return state;
  }
};