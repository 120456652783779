"use strict";

const _ = require('lodash');

//# TODO: move to ruby-lodash
//# childrenIterator = (children) => { returns matching children; }
const DEFAULT_CHILDREN_SELECTOR = (node, nodepathArr) => node.children;
//# return all children by default
//# meaning that we'll always walk the entire tree
//# override this if we can hint which nodes to skip
//# reducer = (collector, value, nodepathArr) => {}
//# NOTE: keypathArr here doesn't account for the key used to select children
//# since the knowledge of the key is encapsulated in the childrenSelector
//# NOTE: nodepathArr: is array of nodes encountered
function depthFirstReduce(node, reducer, initialValue) {
  let childrenSelector = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : DEFAULT_CHILDREN_SELECTOR;
  let nodepathArr = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : [];
  const childrenNodes = childrenSelector(node, nodepathArr) || [];
  return childrenNodes.reduce((collector, childNode, index) => {
    const childNodepathArr = nodepathArr.concat(childNode);
    //# first call on reducer
    const updatedCollector = reducer(collector, childNode, childNodepathArr);

    //# then recurse
    return depthFirstReduce(childNode, reducer, updatedCollector, childrenSelector, childNodepathArr);
  }, initialValue);
}
function childrenSelectorMatchingKeypath_byKey_forChildrenKey(keypath) {
  let propertyKey = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'key';
  let childrenKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'children';
  const targetKeypathArr = _.isArray(keypath) ? keypath : keypath.split('.');
  return (node, nodepathArr) => {
    const keypathArr = nodepathArr.map(n => n.key).filter(_.identity);
    const nextKey = targetKeypathArr[keypathArr.length];
    const selectedChildren = (node[childrenKey] || []).reduce((collector, childNode, index) => {
      if (childNode.hasOwnProperty(propertyKey)) {
        if (childNode.key == nextKey) {
          collector.push(childNode);
        }
      } else {
        collector.push(childNode);
      }
      return collector;
    }, []);
    return selectedChildren;
  };
}
module.exports = {
  depthFirstReduce,
  childrenSelectors: {
    childrenSelectorMatchingKeypath_byKey_forChildrenKey
  }
};