// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[4].use[2]!../../../../ruby-styles/src/client/cssModules/colors.cssModule";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".empty-list__noResults___ObVej {\n    background: #fff;\n    border-left: 1px solid " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["borderGrey"] + ";\n    border-right: 1px solid " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["borderGrey"] + ";\n    border-bottom: 1px solid " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["borderGrey"] + ";\n}\n", "",{"version":3,"sources":["webpack://./src/local_modules/griddle/local_modules/griddle-render/src/empty-list.cssModule"],"names":[],"mappings":"AAEA;IACI,gBAAgB;IAChB,mEAAiC;IACjC,oEAAkC;IAClC,qEAAmC;AACvC","sourcesContent":["@value borderGrey from '@rubyapps/ruby-styles/src/client/cssModules/colors.cssModule';\n\n.noResults {\n    background: #fff;\n    border-left: 1px solid borderGrey;\n    border-right: 1px solid borderGrey;\n    border-bottom: 1px solid borderGrey;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"borderGrey": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["borderGrey"] + "",
	"noResults": "empty-list__noResults___ObVej"
};
export default ___CSS_LOADER_EXPORT___;
