"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function ancestorKeysForKey_inPermissionItemsByKey(key, permissionItemsByKey) {
  let ancestorKeys = [];
  let currentParentKey = permissionItemsByKey[key].parentKey;
  while (currentParentKey && currentParentKey != 'root') {
    //# root isn't toggle
    ancestorKeys.push(currentParentKey);
    currentParentKey = permissionItemsByKey[currentParentKey].parentKey;
  }
  return ancestorKeys;
}
function toggledStateForParentPermItem_usingToggledByPermId(parentPermItem, toggledByPermId) {
  const siblingKeys = parentPermItem.children;
  const parentToggledState = siblingKeys.reduce((collector, siblingKey) => {
    return collector || toggledByPermId[siblingKey];
  }, false);
  return parentToggledState;
}
module.exports = function () {
  const id = this.getID();
  const TYPES = {
    SET_PERMISSION_ITEMS: `@@ruby-app/${id}/SET_PERMISSION_ITEMS`,
    SET_ROOT_PERMISSION_ITEM_KEY: `@@ruby-app/${id}/SET_ROOT_PERMISSION_ITEM_KEY`,
    CUSTOM_RESET_STATE: `@@ruby-app/${id}/CUSTOM_RESET_STATE`
  };
  const generators = {
    setPermissionItems: function (items) {
      return {
        type: TYPES.SET_PERMISSION_ITEMS,
        payload: {
          items
        }
      };
    },
    setRootPermissionItemKey: function (key) {
      return {
        type: TYPES.SET_ROOT_PERMISSION_ITEM_KEY,
        payload: {
          key
        }
      };
    },
    resetStore: function () {
      return {
        type: TYPES.CUSTOM_RESET_STATE
      };
    },
    toggleValues: function (values, toggled) {
      const actions = this.getAction().generators;
      const selfState = this.getState();
      const {
        permissionItemsByKey
      } = selfState;
      const fieldValue = this.getFieldValue();
      const {
        toggledByPermId
      } = fieldValue || {};
      let toggled_toggledByPermId = values.reduce((collector, permId) => {
        collector[permId] = toggled;
        return collector;
      }, _extends({}, toggledByPermId));

      //# determine if the ancestors need to be toggled as well by looking at the value's siblings
      //# assume that the first value in values is the root key being toggled
      const primaryKey = _lodash.default.first(values);
      const primaryPermItem = permissionItemsByKey[primaryKey];
      if (primaryPermItem.parentKey) {
        const ancestorKeys = ancestorKeysForKey_inPermissionItemsByKey(primaryKey, permissionItemsByKey);
        ancestorKeys.forEach(ancestorKey => {
          //# NOTE: this is not functional because the state of the ancestors 
          //# progressively depends on the previous ancestor calculations
          //# NOTE: order of ancestorKeys matter as we want to start from the lowest and walk up the tree
          //# TODO: for each parent, need to mutate toggled_toggledByPermId
          const ancestorPermItem = permissionItemsByKey[ancestorKey];
          const ancestorToggledState = toggledStateForParentPermItem_usingToggledByPermId(ancestorPermItem, toggled_toggledByPermId);
          toggled_toggledByPermId[ancestorKey] = ancestorToggledState;
        });
      }
      const newFieldValue = _extends({}, fieldValue, {
        toggledByPermId: toggled_toggledByPermId
      });
      return actions.setFieldValueByKey(newFieldValue, this.props.key);
    },
    setIdsForPermKey: function (ids, permKey) {
      const actions = this.getAction().generators;
      const fieldValue = this.getFieldValue();
      const {
        idsByPermId
      } = fieldValue;
      const newFieldValue = _extends({}, fieldValue, {
        idsByPermId: _extends({}, idsByPermId, {
          [permKey]: ids
        })
      });
      return actions.setFieldValueByKey(newFieldValue, this.props.key);
    }
  };
  return {
    TYPES,
    generators
  };
};