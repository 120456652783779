"use strict";

function typesWithID(id) {
  return {
    OPEN: `@@ruby-app/${id}/OPEN_DIALOG`,
    CLOSE: `@@ruby-app/${id}/CLOSE_DIALOG`
  };
}
const generators = {
  open: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.OPEN
    };
  },
  close: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.CLOSE
    };
  }
};
module.exports = function () {
  return {
    TYPES: typesWithID(this.getID()),
    generators
  };
};