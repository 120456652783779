"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function typesWithID(id) {
  return {
    //# Overridden action types
    SET_FIELD_ERROR_OBJECT_BY_KEY: `@ruby-app/${id}/SET_FIELD_ERROR_OBJECT_BY_KEY`,
    MERGE_FIELD_ERROR_OBJECT_BY_KEY: `@ruby-app/${id}/MERGE_FIELD_ERROR_OBJECT_BY_KEY`,
    SET_FIELD_ERROR_MESSAGE_BY_KEY: `@ruby-app/${id}/SET_FIELD_ERROR_MESSAGE_BY_KEY`
    //, CLEAR_FIELD_ERRORS_WITH_KEYS_BY_KEY: `@ruby-app/${id}/CLEAR_FIELD_ERRORS_WITH_KEYS_BY_KEY`
    //# NOTE: leveraging the MERGE_FIELD_ERROR_OBJECT_BY_KEY action
    ,
    RESET_STORE: `@ruby-app/${id}/RESET_STORE`,
    SET_DESCENDENTS_HAVE_VALUES: `@@ruby-app/${id}/SET_DESCENDENTS_HAVE_VALUES`
  };
}
const generators = {
  //# Overridden field actions
  setFieldErrorMessageByKey: function (message, key) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SET_FIELD_ERROR_MESSAGE_BY_KEY,
      payload: {
        error: {
          message: _lodash.default.isString(message) ? {
            msg: message,
            level: 'error'
          } : message
        },
        key
      }
    };
  },
  setFieldErrorObjectByKey: function (error, key) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SET_FIELD_ERROR_OBJECT_BY_KEY,
      payload: {
        error,
        key
      }
    };
  },
  mergeFieldErrorObjectByKey: function (error, key) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.MERGE_FIELD_ERROR_OBJECT_BY_KEY,
      payload: {
        error,
        key
      }
    };
  }
  //# DEPRECATED: 20180521 - we shouldn't need to explicitly clearErrors anymore
  ,
  clearErrors: function () {
    const selfKey = this.props.key;
    const {
      generators: actions
    } = this.getAction();
    return actions.setFieldErrorMessageByKey(null, selfKey);
  },
  clearErrorsWithKeys_inState: function () {
    let errorKeys = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    let inState = arguments.length > 1 ? arguments[1] : undefined;
    const {
      TYPES,
      generators
    } = this.getAction();
    const selfState = inState;
    const error = selfState.error ? selfState.error : {};
    const selfKey = this.props.key;
    const preppedError = _lodash.default.reduce(errorKeys, (collector, errorKey, errorKeyIndex) => {
      if (error.hasOwnProperty(errorKey)) {
        collector[errorKey] = null;
      }
      return collector;
    }, {});
    return generators.mergeFieldErrorObjectByKey(preppedError, selfKey);
  },
  resetStore: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.RESET_STORE
    };
  },
  setDescendentsHaveValues: function (descendentsHaveValues) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SET_DESCENDENTS_HAVE_VALUES,
      payload: {
        descendentsHaveValues
      }
    };
  }
};
module.exports = function () {
  return {
    TYPES: typesWithID(this.getID()),
    generators
  };
};