"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const path = require('path');
const rubyLogger = require('@rubyapps/ruby-logger');
const packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
const logger = rubyLogger.getLogger(packageName);
const RubyComponent = require('@rubyapps/ruby-component');
const PropTypes = RubyComponent.PropTypes;
const eventEmitterMixin = require('@rubyapps/ruby-component-mixin-event-emitter');
const formdataListPageMixin = {
  mixinName: 'rubyComponentMixinListFormDataDependency',
  mixins: [eventEmitterMixin],
  propTypes: {
    formElementForFilters: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.element])
    //# if boolean, then search for the nearest ancestor form
    //# if string, then that's the ID
    //# if element, then we're given the element

    //# if this exists ... we should disable the filter if the field exists
  },

  getFormElement: function () {
    const {
      formElementForFilters
    } = this.props;
    let formElement;
    if (_lodash.default.isString(formElementForFilters)) {
      formElement = this.getRoot().findDescendentByID(formElementForFilters);
    } else if (formElementForFilters === true) {
      formElement = this.findAncestorBy(node => node.componentName == 'rubyComponentFieldForm');
    } else {
      formElement = formElementForFilters;
    }
    return formElement;
  }

  //# TODO: don't rely on this for now. There's an issue where any state changes will cause thist o think it should refresh when it shouldn't (we need a better check for comparing the cachedFormValues 
  //# Since we're relying on the listerConfigMixin, the initial cachedFormValue state isn't available so the next time the state 
  //# updates, it'll trigger this to refresh the lister. We can update the cachedFormValue but currently
  //# the need to set the defaultValue for the filters is only used by the MediaPicker, which the listerConfig mixin 
  //# is suitable for that
  /*
  , onReduxInit: function() {
      const {
          formElementForFilters
      } = this.props;
       if (!formElementForFilters) {return;}
       const formElement = this.getFormElement();
      if (!formElement) {
          logger.warn('formElementForFilters was provided but it\'s undefined');
          return;
      }
       const listerConfig = this.getListerConfig();
      const filterKeys = (listerConfig.filters || []).map(filter => filter.name || filter.key)
       if (filterKeys.length == 0) {return;}
       const formValue = formElement.formValue();
      const formValueForFilters = _.pick(formValue, filterKeys);
       const formFields = filterKeys.map(filterKey => formElement.findDescendentBy(node => node.props.key == filterKey));
       let unsub;
      if (formFields.length) {
          //# only need to set up watching if we have form values
          this.once('state:griddleState', (selectedState) => {
              //# Note: we could update all fields to include the eventEmitter mixin
              //# and be able to do formFields.map(n => n.on ..) but I don't know if we want to make the fields that heavy
               const utilityBarEvents = this.getGriddleProps().events;
              const checkFormValuesAndUpdateGriddleIfShould = () => {
                   //# NOTE: this is not great because we're assuming the filter options
                  //# don't expect array values
                  const selectedFormValues = _.mapValues(
                      Object.assign(...formFields.map(field => field.formValue()))
                      , value => (_.isArray(value)? _.first(value) :value)
                  );
                  const cachedFormValues = this.getStatefulCacheForKey('selectedFormValues');
                  this.setStatefulCacheForKey('selectedFormValues', selectedFormValues);
                   if (!_.isEqual(selectedFormValues, cachedFormValues)) {
                      console.log(`Form is different for component: [${this.getID()}]`, selectedFormValues, cachedFormValues);
                       const {
                          griddleState
                      } = this.getState();
                      const utilityBarState = griddleState.utilityBarProperties || {};
                      const newUtilityBarProperties = Object.assign({}
                          , utilityBarState || {}
                          , {
                              customFilters: Object.assign({}
                                  , _.get(utilityBarState, 'customFilters')
                                  , selectedFormValues
                              )
                          }
                      );
                       utilityBarEvents.setUtilityBarProperties(newUtilityBarProperties);
                      utilityBarEvents.getPage(1, true); //# jump back to the first page
                  }
              };
               //checkFormValuesAndUpdateGriddleIfShould();
              //# the listerConfigsMixin will handle setting defaults
              unsub = this.getStore().subscribe(() => {
                  checkFormValuesAndUpdateGriddleIfShould();
              });
          })
      }
       return () => {
          unsub && unsub();
          this.clearStatefulCache();
      }
  }
  */
};

const RC_listerConfigs = require('@rubyapps/ruby-component-lister-configs');
RC_listerConfigs.registerMixin(require('./listerConfigsMixin'));
module.exports = formdataListPageMixin;