"use strict";

var _reactRedux = require("react-redux");
var _LoadingIndicator = _interopRequireDefault(require("./LoadingIndicator"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
module.exports = function LoadingIndicatorGenerator() {
  const selfModule = this;
  const {
    selfSelector
  } = selfModule.getDependencies();
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    return selfState;
  }
  return (0, _reactRedux.connect)(mapStateToProps)(_LoadingIndicator.default);
};