"use strict";

const _ = require('lodash');
module.exports = {
  COMPONENT_NAME: 'rubyComponentFieldDatetimePicker',
  GET_DEFAULT_PROPS: function () {
    let props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const isRequired = _.get(props, 'verify.required');
    const mode = _.get(props, 'mode');

    //# TODO: styles here actually represents reactProps.childrenPropsByClass, consider refactoring
    //# currently it might be a bit difficult to do because existing projects might be using styles already
    const styles = {
      DatePickerWrapper: {
        style: {
          //display: 'flex'
          //, width: 150
          //, position: 'relative'
          width: '100%',
          position: 'relative',
          marginRight: 15
        }
      }
      //, DatePicker: {}
      //, TimePickerWrapper: {}
      //, TimePicker: {}
    };

    return {
      styles,
      data_type: 'object',
      childrenPropsByKey: {
        date: {
          label: props.label || "Date",
          key: "date",
          data_type: "string",
          ruby_permissions: props.ruby_permissions,
          "verify": isRequired ? {
            "required": true
          } : {},
          styles: props.styles ? props.styles : styles,
          mode
        },
        time: {
          label: "Time",
          key: "time",
          isIsoFormat: false //# force using the "HH:mm" format
          ,
          data_type: "string",
          ruby_permissions: props.ruby_permissions,
          "verify": isRequired ? {
            "required": true
          } : {},
          styles: props.styles ? props.styles : styles,
          mode
        }
      }
    };
  }
};