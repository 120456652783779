"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _urlJoin = _interopRequireDefault(require("url-join"));
var _querystring = _interopRequireDefault(require("querystring"));
var _LoginWithSaml = _interopRequireDefault(require("./LoginWithSaml"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
module.exports = function LoginWithSamlConnector() {
  const selfModule = this;
  const selfProps = this.props;
  const {
    feSettingsComponent
  } = this.getDependencies();
  function mapStateToProps(state, ownProps) {
    const selfState = selfModule.getState(state);
    const feSettingsSelector = feSettingsComponent.getDefaultSelector();
    const feSettingsState = feSettingsSelector(state);
    const apiUrlPrefix = _lodash.default.get(feSettingsState, 'restApiRoot');
    const ssoEnabled = _lodash.default.get(feSettingsState, 'RUBYAPPS_SETTINGS.saml.sso_enabled');
    let window__location__search = window.location.search;
    // Remove the beginning ? if present (it usually is), since querystring.parse doesn't handle it.
    if (window__location__search.indexOf('?') === 0) {
      window__location__search = window__location__search.slice(1);
    }
    const searchQuery = _querystring.default.parse(window__location__search);
    const next_page = _lodash.default.get(searchQuery, 'next_page');
    let query = {};
    if (next_page) {
      query.next_page = next_page;
    }
    let stringifiedQuery = '';
    if (query && !_lodash.default.isEmpty(query)) {
      stringifiedQuery = `?${_querystring.default.stringify(query)}`;
    }
    const initiateLoginEndpoint = (0, _urlJoin.default)(apiUrlPrefix, 'users/login/saml', stringifiedQuery);
    return _extends({
      selfProps,
      selfState,
      href: initiateLoginEndpoint,
      shouldRender: ssoEnabled
    });
  }
  function mapDispatchToProps(dispatch, ownProps) {
    const selfActions = selfModule.getAction();
    return {
      actions: (0, _redux.bindActionCreators)(selfActions, dispatch)
    };
  }
  function mergeProps(stateProps, dispatchProps, ownProps) {
    return _extends({}, ownProps, stateProps, dispatchProps);
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps, mergeProps)(_LoginWithSaml.default);
};