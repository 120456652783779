// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Repeater__DragHandleIcon___HUBsI {\n    margin: -10px 0 -10px -10px;\n    margin-right: 0!important;\n    padding: 5px;\n    cursor: move;\n    position: relative;\n    top: 2px;\n}\n\n\n.Repeater__Placeholder____RXhX {\n    background: #e8e8e8;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n", "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-repeater/src/client/reactComponents/Repeater.cssModule"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,QAAQ;AACZ;;;AAGA;IACI,mBAAmB;IACnB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".DragHandleIcon {\n    margin: -10px 0 -10px -10px;\n    margin-right: 0!important;\n    padding: 5px;\n    cursor: move;\n    position: relative;\n    top: 2px;\n}\n\n\n.Placeholder {\n    background: #e8e8e8;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DragHandleIcon": "Repeater__DragHandleIcon___HUBsI",
	"Placeholder": "Repeater__Placeholder____RXhX"
};
export default ___CSS_LOADER_EXPORT___;
