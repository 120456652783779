"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const Promise = require('bluebird');
const path = require('path');
const rubyLogger = require('@rubyapps/ruby-logger');
const packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
const logger = rubyLogger.getLogger(packageName);
const React = require('react');
const PropTypes = React.PropTypes;
const _ = require('lodash');
const routeReducer = require('./reducer');
const routeMiddleware = require('./middleware');
const routeActionGenerator = require('./action');
const jsonselect = require('JSONSelect');
//#displayName: "Param"

const paramSelector = jsonselect.compile('object:has(:root > .displayName:val("Param"))');
const routeMixin = {
  propTypes: {
    defaultRouteParams: PropTypes.shape({
      template: PropTypes.string
    }),
    validateRouteParams: PropTypes.func,
    rerouteBasedOnBestUrl: PropTypes.bool,
    waitForDependenciesToResolve: PropTypes.bool
  },
  getDefaultProps: () => ({
    defaultRouteParams: {},
    validateRouteParams: params => {
      //# `this` is valid
      return true;
    }
  }),
  getInitialState: function () {
    return {
      routeParams: {
        routeActive: false
      }
    };
  },
  action: routeActionGenerator,
  reducer: routeReducer,
  middleware: routeMiddleware

  //# routeComponents can override and return false
  //# which I think the material edit page needs to do
  //# because it needs to check if the template is correct
  ,
  getUrlUsingParams: function (params) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const {
      // used to validate URL parameters, by default validation always passes
      // see https://lodash.com/docs/4.17.15#iteratee for possible options for validator
      matchedParamsValidator = _.stubTrue
    } = options;
    const paramsWithDefault = _extends({}, this.props.defaultRouteParams, params);
    const validRouteParams = this.props.validateRouteParams.call(this, paramsWithDefault);
    if (!validRouteParams) {
      return;
    }
    const routePathParser = this.getRoutePathParser();
    const url = routePathParser.reverse(paramsWithDefault);
    if (url) {
      const matchedParams = routePathParser.match(url);
      const validMatchedParams = _.iteratee(matchedParamsValidator)(matchedParams);
      return validMatchedParams ? url : undefined;
    }
    return url;
  },
  getRouteParamKeys() {
    const routePathParser = this.getRoutePathParser();
    const {
      ast
    } = routePathParser;
    const paramKeys = paramSelector.match(ast).map(paramSpec => paramSpec.props.name);
    return paramKeys;
  },
  areDependenciesResolved: function () {
    const dependencies = this.getDependencies();
    const resolved = _.reduce(dependencies, (collector, dependency, key) => {
      if (!(_.isObject(dependency) && dependency._rubyComponent && dependency.hasOwnProperty('isResolved'))) {
        return collector;
      }
      const dependency__isResolved = dependency.isResolved();
      logger.trace('Checking resolution for:', key, dependency__isResolved);
      return collector && dependency__isResolved;
    }, true);
    return resolved;
  }

  //# TODO: refactor this to support nested isResolved() calls
  //# Eg. index-redirect relies on header, which relies on website
  //# and it's website that needs ot resolve asynchronously
  //# currently we're also injecting the website dependency into the index-redirect component
  ,
  promiseForDependenciesResolution: function () {
    const store = this.getStore();
    return new Promise((resolve, reject) => {
      if (this.props.waitForDependenciesToResolve) {
        //# TODO: we might want to default this to true for all routeComponents
        const areDependenciesResolved = this.areDependenciesResolved();
        if (areDependenciesResolved) {
          resolve(true);
        } else {
          logger.trace(`Dependencies are not resolved for ${this.getID()}. Need to wait.`);
          const unsub = store.subscribe(() => {
            const areDependenciesResolved = this.areDependenciesResolved();
            if (areDependenciesResolved) {
              logger.trace(`Dependencies are resolved for ${this.getID()}.`);
              unsub();
              resolve(true);
            }
          });
        }
      } else {
        resolve(true);
      }
    });
  }
};
module.exports = routeMixin;