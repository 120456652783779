"use strict";

function typesFromID(id) {
  const TYPES = {
    SET_LOADING: `@@ruby-app/${id}/LOADING`
  };
  return TYPES;
}
var generators = {
  setLoading: function (isLoading) {
    const {
      TYPES
    } = this.getAction();
    const selfModule = this;
    return {
      type: TYPES.SET_LOADING,
      payload: isLoading
    };
  }
};
module.exports = function () {
  const TYPES = typesFromID(this.getID());
  return {
    TYPES: TYPES,
    generators: generators
  };
};