"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Components", {
  enumerable: true,
  get: function () {
    return _components.default;
  }
});
exports.default = createRubyComponentsFromFormJS;
Object.defineProperty(exports, "registerComponentForKey", {
  enumerable: true,
  get: function () {
    return _components.registerComponentForKey;
  }
});
var _lodash = _interopRequireDefault(require("lodash"));
var _components = _interopRequireWildcard(require("./components"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const RubyComponent = require('@rubyapps/ruby-component');
/*
   {
        "componentName": "Form"
        , "modelType": "content"
        , "children": [
            {
                "componentName": "Text"
                , "label": "Name"
                , "key": "name"
            }
        ]
    }
*/

const CONSTANTS = require('./constants');

//# traverses
const OMIT_PROPS = ['component', 'children'];
//# idPrefix: key[${i}].key[${j}]
function createRubyComponentNodeWithSpec(spec, idPrefix, depth, index, ctx) {
  const componentKey = spec.componentName;
  let theComponent = _components.default[componentKey];
  if (!theComponent) {
    const originalSpec = spec;
    spec = _extends({}, spec, {
      value: `Component not found for spec: <br/> <pre>${JSON.stringify(originalSpec, null, 4)}</pre>`
    });
    theComponent = _components.default.Info;
  }
  const theComponentName = spec.key || theComponent.componentName;
  const compoundID = spec.id ? spec.id : idPrefix ? `${idPrefix}.${theComponentName}` : theComponentName;
  let additionalPropsBasedOnComponent = _additionalPropsForAllFields(spec, depth, index);
  if (spec.componentName == 'Fieldset') {
    additionalPropsBasedOnComponent = _extends({}, additionalPropsBasedOnComponent, _additionalPropsForFieldset(spec, depth, index));
  }
  const props = _extends(additionalPropsBasedOnComponent, _lodash.default.omit(spec, OMIT_PROPS), {
    id: compoundID
  });
  const children = spec.children ? spec.children : [];
  const formattedChildren = _lodash.default.reduce(children, (collector, child, index) => {
    if (child == undefined) {
      return collector;
    }
    if (child.componentName == 'Tab' && spec.componentName != 'Tabs') {
      if (collector.currentTabs == undefined) {
        collector.currentTabs = {
          componentName: 'Tabs',
          children: []
        };
        collector.children.push(collector.currentTabs);
      }
      collector.currentTabs.children.push(child);
    } else {
      collector.children.push(child);
    }
    return collector;
  }, {
    currentTabs: undefined,
    children: []
  }).children;

  //# TODO: instead of calling on children like this, we should expect component to have 
  //# .children() method
  //# that way, it's the responsibility of each component to determine whether it should generate teh children
  //# 
  const childrenDepth = depth + 1;
  const childrenComponents = [];

  //# TODO: remove
  /*
  const childrenComponents = _.map(formattedChildren, (childSpec, index) =>
      createRubyComponentNodeWithSpec(childSpec, `${compoundID}[${index}]`, childrenDepth, index)
  ).filter((rubyComponentChild) => rubyComponentChild != undefined);
  */

  return RubyComponent.createElement(theComponent, _objectSpread(_objectSpread(_objectSpread({}, props), _lodash.default.pick(ctx, ['_parent'])), {}, {
    __originalSpec: spec,
    __formattedChildren: formattedChildren
    //# NOTE: `children` is a reserved key, eg. header uses it
    //# we blindly spread the rubyComponent props to the react component, so children cannot be used
    //# while we can handle this key specifically, I think it's better to use a different key
    //# for the purposes of handling the RubyComponent children interally by each individual component
    //# we should use `__formattedChildren` instead
  }), ...childrenComponents);
}
function _additionalPropsForAllFields(spec, depth, index) {
  return {
    __depth: depth,
    __index: index
  };
}
function _additionalPropsForFieldset(spec, depth, index) {
  return {
    expandedByDefault: depth <= 4 && index < CONSTANTS.EXPAND_FIRST_NTH_CHILDREN
  };
}
function createRubyComponentsFromFormJS(formJS, idPrefix) {
  let depth = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  let index = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  let ctx = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
  //# const {_parent} = ctx;
  //#     normally set by `newMergedExplicitAndImplicitChildren` 
  //#     but some `children()` expects to retrieve state, which relies on this._parent

  //# walk the tree
  return createRubyComponentNodeWithSpec(formJS, idPrefix, depth, index, ctx);
}