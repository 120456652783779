"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getContentDocgenMenuItemsForSelfModule;
//# TODO: see if we can pass the icon component instead of the string
const pluginContentDocgenComponentId = 'rubyComponentPluginContentDocGen';
function getContentDocgenMenuItemsForSelfModule(selfModule) {
  const pluginContentDocgenComponent = selfModule.getRoot().findDescendentByID(pluginContentDocgenComponentId);
  return pluginContentDocgenComponent ? {
    name: 'Documents',
    icon: 'material-ui/svgIcons/FileCloudDownload',
    permissions: [{
      keyword: 'content_doc_gen',
      ruby_client: 3,
      action: '*'
    }],
    route: '/app/admin/doc-generation/downloads'
  } : null;
}