"use strict";

function typesWithID(id) {
  return {
    EXPAND: `@@ruby-app/${id}/EXPAND_FIELDSET`,
    COLLAPSE: `@@ruby-app/${id}/COLLAPSE_FIELDSET`,
    EXPAND_ALL: `@@ruby-app/${id}/EXPAND_ALL_FIELDSETS`,
    COLLAPSE_ALL: `@@ruby-app/${id}/COLLAPSE_ALL_FIELDSETS`
  };
}
function getAllFieldsetsReachableByElement(element) {
  const tabElement = element.findAncestorBy(rcElement => rcElement.componentName == 'rubyComponentFieldTab');
  const allFieldsetElements = tabElement.findDescendentsBy(rcElement => rcElement.componentName == 'rubyComponentFieldFieldset');
  return allFieldsetElements;
}
function getSiblingFieldsetsReachableByElement(element) {
  const parentElement = element.getParent();
  const siblingFieldsetElements = parentElement.findChildrenBy(rcElement => rcElement.componentName == 'rubyComponentFieldFieldset');
  return siblingFieldsetElements;
}
const generators = {
  _expand: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.EXPAND
    };
  },
  _collapse: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.COLLAPSE
    };
  },
  expand: function () {
    const actions = this.getAction().generators;
    return dispatch => {
      dispatch(actions._expand());
      dispatch(actions.set_visible());
    };
  },
  collapse: function () {
    const actions = this.getAction().generators;
    return dispatch => {
      dispatch(this.getAction().generators._collapse());
      dispatch(this.getAction().generators.unset_visible());
    };
  },
  expandAll: function () {
    const allFieldsetElements = getSiblingFieldsetsReachableByElement(this);
    return dispatch => {
      allFieldsetElements.forEach(fieldsetElement => {
        dispatch(fieldsetElement.getAction().generators.expand());
      });
    };
  },
  collapseAll: function () {
    const allFieldsetElements = getSiblingFieldsetsReachableByElement(this);
    return dispatch => {
      allFieldsetElements.forEach(fieldsetElement => {
        dispatch(fieldsetElement.getAction().generators.collapse());
      });
    };
  }

  //# caching formValue if children not intitialized
};

module.exports = function () {
  return {
    TYPES: typesWithID(this.getID()),
    generators
  };
};