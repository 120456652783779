"use strict";

const _ = require('lodash');
const mime = require('mime-types');
updateMime_addDefaultExtension_forMimeType(mime, 'jpg', 'image/jpeg');
updateMime_addDefaultExtension_forMimeType(mime, 'mp3', 'audio/mpeg');
updateMime_addDefaultExtension_forMimeType(mime, 'mov', 'video/quicktime');
updateMime_setMimeType_toValue(mime, "video/vnd.youtube.yt", {
  "extensions": ['youtube'],
  "sources": ["https://www.iana.org/assignments/media-types/video/vnd.youtube.yt"]
});
updateMime_setMimeType_toValue(mime, "video/vnd.vimeo.vmo", {
  "extensions": ['vimeo']
});
updateMime_setMimeType_toValue(mime, "video/x-vimeo", {
  "extensions": ['vimeo']
});
updateMime_setMimeType_toValue(mime, "video/vimeo", {
  "extensions": ['vimeo']
});
function updateMime_addDefaultExtension_forMimeType(mime, ext, mimeType) {
  _.update(mime, ['extensions', mimeType], currValue => [ext, ..._.without(currValue, ext)]);
  _.set(mime, ['types', ext], mimeType);
}
function updateMime_setMimeType_toValue(mime, mimeType, mimeValue) {
  _.set(mime, ['extensions', mimeType], mimeValue.extensions);
  _.set(mime, ['types', mimeValue.extensions[0]], mimeType);
}
module.exports = mime;