"use strict";

const request = require('@rubyapps/ruby-superagent');
const _ = require('lodash');
const moment = require('moment');
const urljoin = require('url-join');
const {
  ERROR_CODES
} = require('../common/constants');
function typesFromID(id) {
  const TYPES = {
    SET_ERROR: `@@ruby-app/${id}/SET_ERROR`,
    SET_ERROR_PAYLOAD: `@@ruby-app/${id}/SET_ERROR_PAYLOAD`,
    RESET: `@@ruby-app/${id}/RESET`,
    SWITCH_TO_FORGOT_PASSWORD: `@@ruby-app/${id}/SWITCH_TO_FORGOT_PASSWORD`,
    FORGOT_PASSWORD_SUCCESS: `@@ruby-app/${id}/FORGOT_PASSWORD_SUCCESS`
  };
  return TYPES;
}
var generators = {
  //# errorPayload = {error: { message: '' }}
  setError: function (errorPayload) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SET_ERROR,
      payload: errorPayload
    };
  },
  setErrorPayload: function (errorPayload) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SET_ERROR_PAYLOAD,
      payload: {
        errorPayload
      }
    };
  },
  switchToForgotPassword: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SWITCH_TO_FORGOT_PASSWORD
    };
  },
  forgotPasswordSuccess: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.FORGOT_PASSWORD_SUCCESS
    };
  },
  reset: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.RESET
    };
  },
  clearErrorsAndSetFieldValueByKey: function (value, key) {
    const generators = this.getAction().generators;
    return dispatch => {
      dispatch(generators.setError({
        error: null
      }));
      dispatch(generators.setFieldValueByKey(value, key));
    };
  },
  validateBeforeSubmitting: function (payload) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const selfModule = this;
    const selfSelectorObj = selfModule.getSelector();
    const generators = selfModule.getAction().generators;
    return (dispatch, getState) => {
      dispatch(generators.validateFields(['username', 'password'].concat(options.additionalFieldKeys || [])));
      //# since validateAllFields is synchronous, we have acces to the updated state at this point
      const applicationState = getState();
      const hasErrors = Object.keys(selfSelectorObj.getFieldsThatErroredByKey(applicationState)).length > 0;
      if (hasErrors) {
        //# TODO: show another error ?
        options.errorCallback && options.errorCallback(new Error('Invalid input'));
      } else {
        dispatch(generators.submit(payload, options));
      }
    };
  },
  submit: function () {
    let nextPage = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '/';
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const selfModule = this;
    const {
      currentUserActions
    } = this.getDependencies();
    const selfSelectorObj = selfModule.getSelector();
    const generators = selfModule.getAction().generators;
    return (dispatch, getState) => {
      const applicationState = getState();
      const selfState = selfSelectorObj.default(applicationState);
      const fields = selfState.fields;
      const data = _.reduce(fields, (collector, fieldObj, fieldKey) => {
        collector[fieldKey] = fieldObj.value;
        return collector;
      }, {});
      const previousErrorPayload = selfState.errorPayload;

      //# dispatch(generators.setLoading());
      const thenableArguments = [function onResult(response) {
        //# dispatch(generators.clearLoading());

        //# if not local, we reject (don't even use the local route)
        if (nextPage.charAt(0) == '/') {
          //# assume relative if slash
          //# navigate to page
          window.location.href = nextPage; //# we need to force a page reload to get the rest of the bundle
        } else {
          window.location.href = '/'; //# redirect to the root page and ignore other urls
        }

        //dispatch(generators.navigateToPathWithOptions({path:nextPage}));
        setTimeout(() => {
          dispatch(generators.reset());
        }, 1000); //# since we're doing a hard navigation to the nextPage, we don't need to reset the form
        //# but we'll leave this in here anyways, but on a delay so that we don't need the form content change
      }, function onError(err) {
        //# dispatch(generators.clearLoading());

        const errorResponseBody = _.get(err, 'response.body');
        dispatch(generators.setErrorPayload(errorResponseBody.error));
        const errorStatusCode = err.status;
        if (errorStatusCode == 449) {
          dispatch(generators.setError({
            error: null
          }));
        } else if (errorStatusCode == 429) {
          const nextValidRequestDate = errorResponseBody.error.nextValidRequestDate;
          let timeToWaitString;
          if (nextValidRequestDate) {
            timeToWaitString = `${moment(nextValidRequestDate).diff(moment(), 'minutes') + 1} minutes`; //# round up
          } else {
            timeToWaitString = '5 minutes';
          }
          dispatch(generators.setError({
            error: {
              message: `Too many tries. Please try again in ${timeToWaitString}.`
            }
          }));
        } else {
          const errorMessage = errorResponseBody.error.message;
          dispatch(generators.setError({
            error: {
              message: errorMessage
            }
          }));
        }
        options.errorCallback && options.errorCallback(err);
      }];
      dispatch(currentUserActions.logIn(data, thenableArguments));
    };
  }

  //# NOT necessary since we're not validating the username input for this
  ,
  validateBeforeSubmittingForgotPassword: function () {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const selfModule = this;
    const selfSelectorObj = selfModule.getSelector();
    const generators = selfModule.getAction().generators;
    return (dispatch, getState) => {
      dispatch(generators.validateFields(['fp_username']));
      const applicationState = getState();
      const hasErrors = Object.keys(selfSelectorObj.getFieldsThatErroredByKey(applicationState)).length > 0;
      if (hasErrors) {
        //# TODO: show another error ?

        options.errorCallback && options.errorCallback(new Error('Invalid input'));
      } else {
        dispatch(generators.submitForgotPassword(options));
      }
    };
  },
  submitForgotPassword: function () {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const selfModule = this;
    const selfSelectorObj = selfModule.getSelector();
    const generators = selfModule.getAction().generators;
    return (dispatch, getState) => {
      const applicationState = getState();
      const selfState = selfSelectorObj.default(applicationState);
      const fields = selfState.fields;
      const data = _.reduce(fields, (collector, fieldObj, fieldKey) => {
        collector[fieldKey] = fieldObj.value;
        return collector;
      }, {});
      return request.post('/ruby/api/v2/users/forgotPassword').send(data).then(() => {
        dispatch(generators.forgotPasswordSuccess());
        options.successCallback && options.successCallback();
      }, error => {
        options.errorCallback && options.errorCallback(error);
      });
    };
  }
};
module.exports = function () {
  const TYPES = typesFromID(this.getID());
  return {
    TYPES: TYPES,
    generators: generators
  };
};