"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
/* jshint -W138 */
var actionTypes = require('./action').TYPES;

//# NOTE: should not need to have a default state

/**
 *
 *  defaultState = {
 *      errorContext: the context of the error object that the confirm dialog should respond to
 *      endpoint: the endpoint to submit the form to
 *      propagateErrorActionType: the action type to dispatch if the error context doesn't match
 *  }
 *
 **/
var defaultState = {
  open: false,
  loading: false,
  title: null,
  content: '' //# the body of the dialog
  ,
  endpoint: null,
  submitSuccessCallback: null,
  submitFailCallback: null,
  submitLabel: null,
  cancelLabel: null

  //# optional
  ,
  submitLabel_secondary: null,
  submitSuccessCallback_secondary: null
};
module.exports = function (state, action) {
  const stateFromRC = this.props.reduxState || {};
  if (state === undefined) {
    state = _extends(defaultState, stateFromRC);
  }
  switch (action.type) {
    case actionTypes.OPEN_DIALOG:
      return _extends({}, state, {
        open: true
      });
    case actionTypes.CLOSE_DIALOG:
      return _extends({}, state, {
        open: false
      });
    case actionTypes.SET_LOADING:
      return _extends({}, state, {
        loading: true
      });
    case actionTypes.CLEAR_LOADING:
      return _extends({}, state, {
        loading: false
      });
    case actionTypes.SET_OPTIONS:
      return _extends({}, state, action.payload);
    case actionTypes.RESET_DIALOG:
      return _extends({}, state, defaultState, stateFromRC);
    default:
      return state;
  }
};