"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _baseField = _interopRequireDefault(require("./baseField"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default = {
  style: _baseField.default.style
  /*
  , underlineStyle: {
      //borderColor: Colors.black_25
  }
  , iconStyle: {
      //fill: Colors.black_25
  }
  */
};
exports.default = _default;