"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
var _set = _interopRequireDefault(require("lodash/fp/set"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function getUsedIds_fromSitemapValue() {
  let sitemapValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const usedIds = _lodash.default.reduce(sitemapValue.result, (accumulator, id) => {
    return [].concat(accumulator, id, function getDescendentIds(id) {
      const childrenIds = _lodash.default.get(sitemapValue, ['entities', 'sitemapItem', id, 'children']) || [];
      const descendentIds = [].concat(childrenIds, ...childrenIds.map(id => getDescendentIds(id)));
      return descendentIds;
    }(id));
  }, []);
  return usedIds;
}
function getDanglingIds_fromSitemapValue() {
  let sitemapValue = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  //# extract all ids from entities
  //# although the children should be accounted for as top-level entries in `entities` 
  //# that might not be the case (if the structure was badly defined) so we should include children

  const allEntityIds = Object.keys(_lodash.default.reduce(_lodash.default.get(sitemapValue, 'entities.sitemapItem', {}), (collector, entitySpec) => {
    const {
      children = []
    } = entitySpec;
    collector[entitySpec.id] = true;
    children.forEach(childId => {
      collector[childId] = true;
    });
    return collector;
  }, {} //# store as a map to handle repeats 
  )).map(Number);
  const usedIds = getUsedIds_fromSitemapValue(sitemapValue);
  const danglingIds = _lodash.default.difference(allEntityIds, usedIds);
  return danglingIds;
}
function getMissingPagesInSitemap_fromSitemap(sitemapNew, sitemapOld) {
  const prevSitemapIds = getUsedIds_fromSitemapValue(sitemapOld);
  const newSitemapIds = getUsedIds_fromSitemapValue(sitemapNew);
  const idsOfPagesRemovedFromSitemap = _lodash.default.difference(prevSitemapIds, newSitemapIds);
  return idsOfPagesRemovedFromSitemap;
}
function findDupesInSitemapValue(sitemapValue) {
  const usedIds = getUsedIds_fromSitemapValue(sitemapValue);
  const usedIdsMap = usedIds.reduce((collector, id) => {
    collector[id] = collector[id] + 1 || 1;
    return collector;
  }, []);
  const dupesMap = _lodash.default.pickBy(usedIdsMap, (val, key) => val > 1);
  return Object.keys(dupesMap);
}
function cleanSitemapFormValue_forFieldKeypathArr(formValue, fieldKeypathArr) {
  return _lodash.default.flow([function removeUndefinedIds(formValue) {
    return (0, _set.default)(fieldKeypathArr, {
      entities: {
        sitemapItem: _lodash.default.pickBy(_lodash.default.get(formValue, [...fieldKeypathArr, 'entities', 'sitemapItem'], {}), (value, key) => key != 'undefined')
      },
      result: _lodash.default.get(formValue, [...fieldKeypathArr, 'result'], []).filter(value => !_lodash.default.isNil(value))
    }, formValue);
  }, function removeUnusedIds(formValue) {
    const sitemapValue = _lodash.default.get(formValue, fieldKeypathArr);
    const usedIds = getUsedIds_fromSitemapValue(sitemapValue);
    const prunedSitemapValue = _extends({}, sitemapValue, {
      entities: _extends({}, formValue.entities, {
        sitemapItem: _lodash.default.pick(_lodash.default.get(sitemapValue, 'entities.sitemapItem'), usedIds)
      })
    });
    return (0, _set.default)(fieldKeypathArr, prunedSitemapValue, formValue);
  }])(formValue);
}
module.exports = {
  getUsedIds_fromSitemapValue,
  getDanglingIds_fromSitemapValue,
  getMissingPagesInSitemap_fromSitemap,
  findDupesInSitemapValue,
  cleanSitemapFormValue_forFieldKeypathArr
};