"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const rubyWords = require('@rubyapps/ruby-words');
module.exports = function (forms, templateKey, getContentListUrl_forTemplate) {
  let excludeFromFilter = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  let optKeys = Object.keys(forms);
  let defaultOption = null,
    options = [];
  options = optKeys.filter(option => !_lodash.default.find(excludeFromFilter, exclude => option == exclude)).map(tk => {
    let form = forms[tk];
    let displayName = _lodash.default.get(form, 'name');
    let option = {
      text: displayName || rubyWords.capitalizeWords(rubyWords.inflection.transform(tk, ['humanize', 'pluralize'])),
      value: getContentListUrl_forTemplate(tk)
    };
    if (tk === templateKey) {
      defaultOption = option;
    }
    return option;
  }).filter(opt => {
    return opt.text && opt.value;
  });
  const sortedOptions = _lodash.default.sortBy(options, ['text']);
  return {
    options: sortedOptions,
    defaultOption
  };
};