"use strict";

module.exports = {
  //# make modules accessible for all Field components
  //# used by toHTML and searchableFields
  modules: {
    numeral: require('numeral'),
    moment: require('@rubyapps/friendly-ruby-moment'),
    _: require('lodash')
  }
};