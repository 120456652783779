"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addSortColumn = addSortColumn;
exports.addSortColumnRemoteHandler = addSortColumnRemoteHandler;
exports.dataWasLoaded = dataWasLoaded;
exports.filterData = filterData;
exports.filterDataRemoteHandler = filterDataRemoteHandler;
exports.loadData = loadData;
exports.loadNext = loadNext;
exports.loadNextRemoteHandler = loadNextRemoteHandler;
exports.loadPage = loadPage;
exports.loadPageRemoteHandler = loadPageRemoteHandler;
exports.loadPrevious = loadPrevious;
exports.loadPreviousRemoteHandler = loadPreviousRemoteHandler;
exports.makeRequest = makeRequest;
exports.refreshCurrentPage = refreshCurrentPage;
exports.refreshCurrentPageRemoteHandler = refreshCurrentPageRemoteHandler;
exports.remoteError = remoteError;
exports.requestToLoadData = requestToLoadData;
exports.setLoading = setLoading;
exports.setPageSize = setPageSize;
exports.setPageSizeRemoteHandler = setPageSizeRemoteHandler;
exports.sort = sort;
exports.sortRemoteHandler = sortRemoteHandler;
exports.startLoading = startLoading;
var types = _interopRequireWildcard(require("./constants"));
var _storeUtil = require("./util/store-util");
var _griddleCore = require("../../griddle-core");
var _immutable = _interopRequireDefault(require("immutable"));
var _reduxBatchedActions = require("redux-batched-actions");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
/**
 *  @param {array[Object]} additionalBatchActions - additional action payloads to concat to batch action
 *  @param {array[Object]} additionalPostBatchActions - additional actions to dispatch after batch
 */
function remoteAction(actionType, response) {
  let additionalBatchActions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  let additionalPostBatchActions = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  /*
  return batchActions([
      {
          type: actionType,
          currentPage: response.page,
          maxPage: response.maxPage,
          totalCount: response.totalCount,
          data: Immutable.fromJS(response.data)
      }
      , dataWasLoaded()
  ]);
  */

  return dispatch => {
    const batchedActionsPayload = (0, _reduxBatchedActions.batchActions)([{
      type: actionType,
      currentPage: response.page,
      maxPage: response.maxPage,
      totalCount: response.totalCount,
      data: _immutable.default.fromJS(response.data)
    }, ...additionalBatchActions]);
    dispatch(batchedActionsPayload);
    dispatch(dataWasLoaded());
    additionalPostBatchActions.forEach(dispatch);
  };
}
function remoteError(err, response) {
  return {
    type: types.GRIDDLE_REMOTE_ERROR
  };
}
function makeRequest(remoteProvider, tableState, successActionGenerator) {
  return (dispatch, getState) => {
    if (!remoteProvider || !remoteProvider.makeRequest) {
      console.error('No provider has been configured. Please ensure that a provider has been passed in to the griddle-remote-plugin.');
    }

    // Make the request.
    dispatch(remoteProvider.makeRequest(tableState, {
      startLoadingActionGenerator: startLoading,
      successActionGenerator,
      remoteErrorActionGenerator: remoteError,
      griddleState: getState().toJSON()
    }));
  };
}
function setLoading(loading) {
  return {
    type: loading ? types.GRIDDLE_START_LOADING : types.GRIDDLE_STOP_LOADING
  };
}
function startLoading(req) {
  return (0, _reduxBatchedActions.batchActions)([_startLoading(req), _griddleCore.GriddleActions.clearStaticData() //# clear the static data on remote request
  ]);
}

function _startLoading(req) {
  return {
    type: types.GRIDDLE_START_LOADING,
    req
  };
}
function dataWasLoaded() {
  return {
    type: types.GRIDDLE_LOADED_DATA
  };
}
function loadData(data) {
  return {
    type: types.GRIDDLE_LOADED_DATA,
    data
  };
}
function requestToLoadData(store, data, properties, ignoreCache) {
  let currentPage = store.getState().getIn(['pageProperties', 'currentPage']) || 1;

  //# types.GRIDDLE_LOAD_DATA
  return dispatch => {
    // Load the first page of results.
    dispatch(loadPage(store, currentPage, ignoreCache));
  };
}

/*
export function initializeGrid(store, properties) {
  let currentPage = store.getState().getIn(['pageProperties', 'currentPage']) || 1;
  return dispatch => {
    // Initialize the grid.
    dispatch(GriddleActions.initializeGrid(properties));

    // Load the first page of results.
    dispatch(loadPage(store, currentPage));
  }
}
*/

function filterData(store, filter) {
  const tableState = _objectSpread(_objectSpread({}, (0, _storeUtil.getTableState)(store)), {}, {
    page: 1,
    filter
  });
  const remoteProvider = (0, _storeUtil.getRemoteProvider)(store);
  return dispatch => {
    // Execute the filter.
    dispatch(_griddleCore.GriddleActions.filterData(filter));
    dispatch(makeRequest(remoteProvider, tableState, response => {
      return filterDataRemoteHandler(response, filter);
    }));
  };
}
function filterDataRemoteHandler(response, filter) {
  /*
  return batchActions([
  // Append the data.
  remoteAction(types.GRIDDLE_REMOTE_REPLACE_DATA, response)
  // Execute the filter.
  , GriddleActions.filterData(filter)
  // Indicate that we're done loading.
  , { type: types.GRIDDLE_STOP_LOADING }
  ]);
  */

  return dispatch => {
    // Append the data.
    dispatch(remoteAction(types.GRIDDLE_REMOTE_REPLACE_DATA, response));

    // Execute the filter.
    dispatch(_griddleCore.GriddleActions.filterData(filter));
    // Indicate that we're done loading.
    dispatch({
      type: types.GRIDDLE_STOP_LOADING
    });
  };
}
function setPageSize(store, pageSize) {
  const tableState = _objectSpread(_objectSpread({}, (0, _storeUtil.getTableState)(store)), {}, {
    page: 1,
    pageSize
  });
  const remoteProvider = (0, _storeUtil.getRemoteProvider)(store);
  return makeRequest(remoteProvider, tableState, response => {
    return setPageSizeRemoteHandler(response, pageSize);
  });
}
function setPageSizeRemoteHandler(response, pageSize) {
  // Append the data.
  const batchedRemoteAction = remoteAction(types.GRIDDLE_REMOTE_REPLACE_DATA, response
  // Set the page size.
  , [_griddleCore.GriddleActions.setPageSize(pageSize)]
  // Indicate that we're done loading.
  , [{
    type: types.GRIDDLE_STOP_LOADING
  }]);
  return batchedRemoteAction;
}
function sort(store, column) {
  const tableState = _objectSpread(_objectSpread({}, (0, _storeUtil.getTableState)(store)), {}, {
    page: 1,
    // Reset the page
    sortColumn: [column],
    sortDirection: (0, _storeUtil.getUpdatedSortDirection)(store, column)
  });
  const remoteProvider = (0, _storeUtil.getRemoteProvider)(store);
  return makeRequest(remoteProvider, tableState, response => {
    return sortRemoteHandler(response, column);
  });
}
function sortRemoteHandler(response, column) {
  return dispatch => {
    // Append the data.
    dispatch(remoteAction(types.GRIDDLE_REMOTE_REPLACE_DATA, response));

    // Finish the sort.
    dispatch(_griddleCore.GriddleActions.sort(column));

    // Indicate that we're done loading.
    dispatch({
      type: types.GRIDDLE_STOP_LOADING
    });
  };
}
function addSortColumn(store, column) {
  let tableState = (0, _storeUtil.getTableState)(store);
  tableState.column = tableState.column.concat(column);
  const remoteProvider = (0, _storeUtil.getRemoteProvider)(store);
  return makeRequest(remoteProvider, tableState, response => {
    return addSortColumnRemoteHandler(response, column);
  });
}
function addSortColumnRemoteHandler(response, column) {
  return dispatch => {
    // Append the data.
    dispatch(remoteAction(types.GRIDDLE_REMOTE_REPLACE_DATA, response));

    // Finish the sort.
    dispatch(_griddleCore.GriddleActions.addSortColumn(column));

    // Indicate that we're done loading.
    dispatch({
      type: types.GRIDDLE_STOP_LOADING
    });
  };
}
function loadNext(store) {
  let tableState = (0, _storeUtil.getTableState)(store);
  tableState.page++;
  const remoteProvider = (0, _storeUtil.getRemoteProvider)(store);
  const storeState = store.getState();
  const staticData_immutable = storeState.get('staticData');
  const staticData = staticData_immutable ? staticData_immutable.toJS() : staticData_immutable;
  const currentPage = storeState.getIn(['pageProperties', 'currentPage']);
  const maxPage = storeState.getIn(['pageProperties', 'maxPage']);
  const shouldClearPagesLoaded = staticData && staticData.length;
  if (shouldClearPagesLoaded) {
    //# force using loadPage, which inherently clears the data and loadedPages cache
    const nextPageNumber = currentPage < maxPage ? currentPage + 1 : currentPage;
    return loadPage(store, nextPageNumber, true);
  }

  // If this is a page that has already been loaded, simply load the page.
  const loadedPages = (0, _storeUtil.getPagesLoaded)(store);
  if (loadedPages.includes(tableState.page)) {
    return _griddleCore.GriddleActions.loadNext();
  }
  return makeRequest(remoteProvider, tableState, response => {
    return loadNextRemoteHandler(response);
  });
}
function loadNextRemoteHandler(response) {
  return dispatch => {
    // Append the data.
    dispatch(remoteAction(types.GRIDDLE_REMOTE_APPEND_DATA, response));

    // Load the next page, now that we have the data.
    dispatch(_griddleCore.GriddleActions.loadNext());

    // Indicate that we're done loading.
    dispatch({
      type: types.GRIDDLE_STOP_LOADING
    });
  };
}
function loadPrevious(store) {
  let tableState = (0, _storeUtil.getTableState)(store);
  tableState.page--;
  const remoteProvider = (0, _storeUtil.getRemoteProvider)(store);
  const storeState = store.getState();
  const staticData_immutable = storeState.get('staticData');
  const staticData = staticData_immutable ? staticData_immutable.toJS() : staticData_immutable;
  const currentPage = storeState.getIn(['pageProperties', 'currentPage']);
  const shouldClearPagesLoaded = staticData && staticData.length;
  if (shouldClearPagesLoaded) {
    //# force using loadPage, which inherently clears the data and loadedPages cache
    const previousPageNumber = currentPage > 0 ? currentPage - 1 : 0;
    return loadPage(store, previousPageNumber, true);
  }

  // If this is a page that has already been loaded, simply load the page.
  const loadedPages = (0, _storeUtil.getPagesLoaded)(store);
  if (loadedPages.includes(tableState.page)) {
    return _griddleCore.GriddleActions.loadPrevious();
  }
  return makeRequest(remoteProvider, tableState, response => {
    return loadPreviousRemoteHandler(response);
  });
}
function loadPreviousRemoteHandler(response) {
  return dispatch => {
    // Append the data.
    dispatch(remoteAction(types.GRIDDLE_REMOTE_PREPEND_DATA, response));

    // Load the previous page, now that we have the data.
    dispatch(_griddleCore.GriddleActions.loadPrevious());

    // Indicate that we're done loading.
    dispatch({
      type: types.GRIDDLE_STOP_LOADING
    });
  };
}
function loadPage(store, number, ignoreCache) {
  const tableState = _objectSpread(_objectSpread({}, (0, _storeUtil.getTableState)(store)), {}, {
    page: number
  });
  const remoteProvider = (0, _storeUtil.getRemoteProvider)(store);

  // If this is a page that has already been loaded, simply load the page.
  if (!ignoreCache) {
    const loadedPages = (0, _storeUtil.getPagesLoaded)(store);
    if (loadedPages.includes(tableState.page)) {
      return _griddleCore.GriddleActions.loadPage(number);
    }
  }
  // Make a remote request.
  return makeRequest(remoteProvider, tableState, response => {
    return loadPageRemoteHandler(response, number);
  });
}
function loadPageRemoteHandler(response, number) {
  return dispatch => {
    // Replace the data.
    dispatch(remoteAction(types.GRIDDLE_REMOTE_REPLACE_DATA, response));

    // Load the specified page, now that we have the data.
    dispatch(_griddleCore.GriddleActions.loadPage(number));

    // Indicate that we're done loading.
    dispatch({
      type: types.GRIDDLE_STOP_LOADING
    });
  };
}
function refreshCurrentPage(store, callback) {
  const tableState = (0, _storeUtil.getTableState)(store);
  const remoteProvider = (0, _storeUtil.getRemoteProvider)(store);

  // Bypass loaded pages during refresh

  // Make a remote request.
  return makeRequest(remoteProvider, tableState, response => {
    const retVal = refreshCurrentPageRemoteHandler(response);
    callback && callback(response);
    return retVal;
  });
}
function refreshCurrentPageRemoteHandler(response) {
  return dispatch => {
    // Replace the data.
    dispatch(remoteAction(types.GRIDDLE_REMOTE_REPLACE_DATA, response));

    // Load the specified page, now that we have the data.
    dispatch(_griddleCore.GriddleActions.refreshCurrentPage());

    // Indicate that we're done loading.
    dispatch({
      type: types.GRIDDLE_STOP_LOADING
    });
  };
}