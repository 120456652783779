"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = defaultMappers_forReactClass;
var _redux = require("redux");
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function defaultMappers_forReactClass() {
  let reactClass = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const selfModule = this;
  const reactClass__propTypeKeys = Object.keys(reactClass.propTypes || {});

  //# figure out the toHTML ahead of time
  const hydratedToHTML = this.hydratedToHTMLFromProps(this.props);
  function mapStateToProps(state, ownProps) {
    const selfState = selfModule.getState(state);
    const mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);
    const formattedError = selfModule.formattedErrorFromState(state);
    const {
      fieldKey
    } = mixinFieldProps;
    const mappedProps = _extends({}, ownProps, _.pick(selfState, reactClass__propTypeKeys), mixinFieldProps, fieldKey ? _.pick(selfState.fields[fieldKey], ['value']) : {}, formattedError
    //# formattedError should include richErrorMessages
    //# no need to call on selfModule.richErrorMessages()
    , hydratedToHTML ? {
      toHTML: hydratedToHTML
    } : {});
    return mappedProps;
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
    };
  }
  return {
    mapStateToProps,
    mapDispatchToProps
  };
}