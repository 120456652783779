"use strict";

var _reduxBatchedActions = require("redux-batched-actions");
//== The Middleware ========================================================//

const middlewareInitializer = function (store) {
  const selfModule = this;
  const selfAction = selfModule.getAction();
  const selfActionGenerators = selfAction.generators;
  const {
    currentUserSelector
  } = selfModule.getDependencies();
  return next => action => {
    const nextRetVal = next(action); //# still allow @@router to continue propagation
    const subscribeToStore = selfModule.getStore().subscribe;
    switch (action.type) {
      case selfAction.TYPES.SET_ROUTE_PARAMS:
        // NOTE: SET_ROUTE_PARAMS will only execute this middleware
        //       if this component is currently active
        _fetchLastModified_onActivate({
          action,
          currentUserSelector,
          store,
          selfActionGenerators,
          subscribeToStore
        });
        break;
      case _reduxBatchedActions.BATCH:
        //# check if the batch action has SET_ROUTE_PARAMS
        const foundSetRouteParamsAction = action.payload.filter(action => {
          return action.type == selfAction.TYPES.SET_ROUTE_PARAMS;
        })[0];
        if (foundSetRouteParamsAction) {
          _fetchLastModified_onActivate({
            action: foundSetRouteParamsAction,
            currentUserSelector,
            store,
            selfActionGenerators,
            subscribeToStore
          });
        }
        break;
    }
    return nextRetVal;
  };
};
module.exports = middlewareInitializer;
function _fetchLastModified_onActivate(params) {
  const {
    action,
    currentUserSelector,
    store,
    selfActionGenerators,
    subscribeToStore
  } = params;

  // If we have a valid template keyword
  const templateKey = action.payload.template;
  if (!templateKey) {
    return;
  }
  store.dispatch(selfActionGenerators.retrieveAndSetMostRecentlyModifiedItem_forType(templateKey));
  return;
}