"use strict";

var _reactRouterRedux = require("react-router-redux");
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const CONSTANTS = require('../../common/constants');
const path = require('path');
const TYPES = CONSTANTS.ROUTE_ACTION_TYPES;
const routeHelperActionGenerator = require('../routeHelperMixin/action');
module.exports = function () {
  const selfModule = this;
  const selfID = selfModule.getID();
  const localTypes = {
    SET_ROUTE_PARAMS: path.join(TYPES.ACTION_TYPE_PREFIX, selfID, TYPES.SET_ROUTE_PARAMS_SUFFIX),
    SET_HASH: path.join(TYPES.ACTION_TYPE_PREFIX, selfID, TYPES.SET_HASH_SUFFIX),
    LEAVING_ROUTE: path.join(TYPES.ACTION_TYPE_PREFIX, selfID, TYPES.LEAVING_ROUTE_SUFFIX)
  };
  const routeHelperActions = routeHelperActionGenerator.apply(this, arguments);
  return {
    TYPES: _extends(localTypes, routeHelperActions.TYPES),
    generators: _extends({
      setRouteParams: function (payload) {
        return {
          type: localTypes.SET_ROUTE_PARAMS,
          payload: payload
        };
      },
      leavingRoute: function (payload) {
        return {
          type: localTypes.LEAVING_ROUTE,
          payload: payload
        };
      }

      //# DEPRECATING 20201027 - should always use the 'react-router-redux' actions. Let our middleware handle converting the url to routeParams appropriately
      /**
       *  @param {Object} payload.hash - hash object where key-value pairs mirror query params. So the formatted hash will look like: #param=value&param=value
       */,
      setHash: function (hash) {
        //# NOTE: hash should always be an object now, but we're not preventing strings
        return {
          type: localTypes.SET_HASH,
          payload: {
            hash
          }
        };
      },
      push: _reactRouterRedux.push,
      replace: _reactRouterRedux.replace
    }, routeHelperActions.generators)
  };
};