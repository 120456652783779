"use strict";

//== The Middleware ========================================================//

const middlewareInitializer = function (store) {
  const selfModule = this;
  return next => action => {
    const nextRetVal = next(action); //# still allow @@router to continue propagation
    //# but also need to immediately call on next;

    //if (action.type.indexOf('LEAVING_ROUTE') >= 0) {
    if (action.type === '@@router/LOCATION_CHANGE') {
      const {
        open
      } = selfModule.getState();
      const {
        generators: actions
      } = selfModule.getAction();
      if (open) {
        actions.closeAndReset && store.dispatch(actions.closeAndReset());
      }
    }
    return nextRetVal;
  };
};
module.exports = middlewareInitializer;