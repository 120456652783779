"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const RubyComponent = require('@rubyapps/ruby-component');
const PropTypes = RubyComponent.PropTypes;
const baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
const forcedLeafNodeMixin = {
  mixinName: 'rubyComponentMixinFieldForcedLeafNode',
  _formValueToLocalState: function (formValue, dispatchOrCollect, isError, entireFormValue) {
    let options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
    return baseFieldMixin._formValueToLocalState.call(this, formValue, dispatchOrCollect, isError, entireFormValue, _extends({
      ignoreChildren: true
    }, options));
  },
  _formValueFromLocalState: function (selfState, isError, predicateFormatter, entireState) {
    let options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
    return baseFieldMixin._formValueFromLocalState.call(this, selfState, isError, predicateFormatter, entireState, _extends({
      ignoreChildren: true
    }, options));
  },
  _objectValueToLocalState: function (objectValue, dispatchOrCollect, isError) {
    let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    return baseFieldMixin._objectValueToLocalState.call(this, objectValue, dispatchOrCollect, isError, _extends({
      ignoreChildren: true
    }, options));
  },
  _objectValueFromLocalState: function (selfState, isError, limitToTabWithLabel) {
    let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    return baseFieldMixin._objectValueFromLocalState.call(this, selfState, isError, limitToTabWithLabel, _extends({
      ignoreChildren: true
    }, options));
  }
};
module.exports = forcedLeafNodeMixin;