"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const _ = require('lodash');
const RubyComponent = require('@rubyapps/ruby-component');
const PropTypes = RubyComponent.PropTypes;
const memoize = require('memoizee');
const REQUEST_TYPE_SUGGESTED_OPTIONS_RETRIEVAL = 'requestType_suggestedOptionsRetrieval';
const getMissingOptions_fromNeeded_andActual = memoize(_getMissingOptions_fromNeeded_andActual, {
  length: 2
});
function _getMissingOptions_fromNeeded_andActual(needed, actual) {
  return _.differenceWith(needed, actual, _.isEqual);
}
module.exports = {
  propTypes: {
    suggestedValues: PropTypes.arrayOf(PropTypes.oneOfType(PropTypes.number, PropTypes.string))
  },
  action: function () {
    const id = this.getID();
    const TYPES = {
      SET_SUGGESTED_OPTIONS: `@ruby-app/${id}/SET_SUGGESTED_OPTIONS`
    };
    return {
      TYPES,
      generators: {
        setSuggestedOptions: function (suggestedOptions) {
          return {
            type: TYPES.SET_SUGGESTED_OPTIONS,
            payload: suggestedOptions
          };
        }
      }
    };
  },
  reducer: function (state, action) {
    const {
      TYPES
    } = this.getAction();
    const {
      type,
      payload
    } = action;
    switch (type) {
      case TYPES.SET_SUGGESTED_OPTIONS:
        return _extends({}, state, {
          suggestedOptions: payload
        });
      default:
        return state;
    }
  },
  getSuggestedOptionsIfShould: function () {
    const selfModule = this;
    const suggestedValues = selfModule.getProps(true).suggestedValues;
    if (_.isEmpty(suggestedValues)) {
      return;
    }
    const url = selfModule.url();
    const suggestedOptionsRequestParams = {
      url,
      suggestedValues
    };
    if (!_.isEqual(suggestedOptionsRequestParams, selfModule._prev_suggestedOptionsRequestParams)) {
      selfModule._prev_suggestedOptionsRequestParams = suggestedOptionsRequestParams;
      const suggestedValues_normalized = _.flow(_.castArray, arrValue => _.flatMap(arrValue, item => this.coercedValue(item)))(suggestedValues);
      selfModule.getStore().dispatch(this.getAction().generators.setSuggestedOptions([]));
      const actions = selfModule.getAction().generators;
      return selfModule.getStore().dispatch(actions.retrieveOptionsFromUrl_withQuery(this.url(), {
        id: suggestedValues_normalized
      }, null, true //# allowAbort
      , false //# clearCache
      , REQUEST_TYPE_SUGGESTED_OPTIONS_RETRIEVAL)).then(data => {
        selfModule.getStore().dispatch(this.getAction().generators.setSuggestedOptions(data));
      });
    }
  },
  addSuggestedOptions_toCache: function () {
    const selfState = this.getState();
    const options = _.get(selfState, 'props.options') || this.props.options || [];
    const suggestedOptions = _.get(selfState, 'suggestedOptions');
    const missingOptions = getMissingOptions_fromNeeded_andActual(suggestedOptions, options);
    if (missingOptions.length) {
      this.getStore().dispatch(this.getAction().generators.updateCachedOptions_withAdditional(suggestedOptions));
    }
  },
  onReduxInit: function () {
    const selfModule = this;
    const store = selfModule.getStore();
    const _debounced_getSuggestedOptionsIfShould = _.throttle(selfModule.getSuggestedOptionsIfShould.bind(selfModule), 1000);
    return store.subscribe(() => {
      _debounced_getSuggestedOptionsIfShould();
      selfModule.addSuggestedOptions_toCache();
    });
  }
};