"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
var _memoizee = _interopRequireDefault(require("memoizee"));
var _mixins = require("../../../ruby-component/src/client/decorators/mixins");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const RubyComponent = require('@rubyapps/ruby-component');
const rubyAccessManager = require('@rubyapps/ruby-access-manager');
const RubyComponentFeSettings = require('@rubyapps/ruby-component-frontend-settings');
const RubyComponentForms__CONSTANTS = require('@rubyapps/ruby-component-forms/src/common/constants');
const currentUserMixin = require('@rubyapps/ruby-component-mixin-current-user');
const ACCESS_CONSTANTS = rubyAccessManager.CONSTANTS;
const PERMISSION_STRINGS = ACCESS_CONSTANTS.PERMISSION_STRINGS;
const PERMISSION_ACTIONS = ACCESS_CONSTANTS.PERMISSION_ACTIONS;
const accessControlMixin = RubyComponent.createMixin({
  mixins: [currentUserMixin],
  mixinName: 'rubyComponentMixinAccessControl',
  getDefaultProps: () => ({
    frontendSettingsID: RubyComponentFeSettings.CONSTANTS.COMPONENT_NAME
  }),
  dependencies: function () {
    const rootComponent = this.getRoot();
    const feSettingsComponent = rootComponent.findDescendentByID(this.props.frontendSettingsID);
    return {
      feSettingsComponent
    };
  },
  canUserPublish: function () {
    const rubyComponent = this;

    // Given a ruby client id, subsite and user object
    const rubyClientId = rubyComponent.getActiveRubyClientId();
    const subsiteId = rubyComponent.getActiveSubsiteId();
    const userObject = rubyComponent.getCurrentUserState();
    const publishPerm = _createPublishPermission(PERMISSION_STRINGS.PUBLISH, rubyClientId, subsiteId, PERMISSION_ACTIONS.EDIT);

    // Check to see if the user has publish permission
    // for the client/subsite
    return rubyAccessManager.doesUserHavePermission(userObject, publishPerm);
  },
  doesUserHavePermission: (0, _mixins.decoratedFunctionReduce_booleanAnd)(function (permInQuestion, options) {
    const rubyComponent = this;
    const userObject = rubyComponent.getCurrentUserState();
    const perm = _extends({}, permInQuestion);
    const forms = this.getForms();
    if (perm.template && forms[perm.template]) {
      //# if template prop is key instead of the id
      //# look up id from key
      const formId = _lodash.default.get(forms, [perm.template, 'id']);
      perm.template = formId;
    }
    if (perm.ruby_client === true) {
      perm.ruby_client = rubyComponent.getActiveRubyClientId();
    }
    if (perm.subsite === true) {
      perm.subsite = rubyComponent.getActiveSubsiteId();
    }
    return rubyAccessManager.doesUserHavePermission(userObject, perm);
  }, 'doesUserHavePermission'),
  getForms: function (applicationState) {
    const rubyComponent = this;

    // Get the full application state
    const store = rubyComponent.getStore();
    if (store == undefined) {
      return undefined;
    }
    applicationState = applicationState || store.getState();
    let formsSelector = this._formsSelector;
    if (!formsSelector) {
      const rootModule = rubyComponent.getRoot();
      const formsID = rubyComponent.props.formsID || RubyComponentForms__CONSTANTS.COMPONENT_NAME;
      const formsComponent = rootModule.findDescendentByID(formsID);
      formsSelector = formsComponent.getDefaultSelector();
      this._formsSelector = formsSelector;
    }

    // Return the forms state
    return formsSelector(applicationState);
  },
  getTemplateForKey: function (key) {
    return this.getForms()[key];
  },
  getFormForTemplateKey: function (key) {
    //# this returns the template, so use the better-named `getTemplateForKey` instead
    return this.getTemplateForKey(key);
  },
  getFrontendSettings: function () {
    const rubyComponent = this;

    // Get the full application state
    const store = rubyComponent.getStore();
    if (store == undefined) {
      return undefined;
    }
    const applicationState = store.getState();
    let feSettingsSelector = this._feSettingsSelector;
    if (!feSettingsSelector) {
      const {
        feSettingsComponent
      } = this.getDependencies();
      feSettingsSelector = feSettingsComponent.getDefaultSelector();
      this._feSettingsSelector = feSettingsSelector;
    }

    // Return the frontendSettings state
    return feSettingsSelector(applicationState);
  },
  getRubyClientInfo_forRoute: function (route) {
    const rubyComponent = this;
    const feSettings = this.getFrontendSettings();
    return rubyAccessManager.getRubyClientInfo_fromSettings_forRoute(feSettings, route);
  },
  getActiveRubyClientInfo: function () {
    const rubyComponent = this;
    const feSettings = this.getFrontendSettings();
    const route = window.location.pathname;
    return rubyAccessManager.getRubyClientInfo_fromSettings_forRoute(feSettings, route);
  },
  getActiveRubyClientId: function () {
    const rubyComponent = this;
    const feSettings = this.getFrontendSettings();
    const route = window.location.pathname;

    // Return a client id
    return rubyAccessManager.getRubyClientId_fromSettings_forRoute(feSettings, route);
  },
  getActiveSubsiteId: function () {
    const rubyComponent = this;
    const feSettings = this.getFrontendSettings();
    const route = window.location.pathname;
    const defaultSubsiteId = 1; // TODO: we need to remove this hard coded ID once we complete the subsite task for propgen

    // Return a subsite id
    return rubyAccessManager.getSubsiteId_fromSettings_forRoute(feSettings, route) || defaultSubsiteId;
  }

  //# url identifiers
  ,
  getActiveRubyClientUrlIdentifier: function () {
    const routeComponent = this.getActiveRouteComponent();
    if (!routeComponent) return;

    // Given this rubyComponent's state from the store
    const selfState = routeComponent.getState();

    // Return the part of the URL that represents the ruby client
    // For this to work, the rubyComponent should be a child of
    // the route-cms rubyComponent, which has the path:
    //   { path: "cms(/client/:rubyClient)" }
    return _lodash.default.get(selfState, ['routeParams', 'rubyClient']);
  },
  getActiveSubsiteUrlIdentifier: function () {
    const routeComponent = this.getActiveRouteComponent();
    let selfState;
    if (!routeComponent) {
      selfState = {
        routeParams: {}
      };
    } else {
      // Given this rubyComponent's state from the store
      selfState = routeComponent.getState();
    }
    const applicationState = this.getStore().getState();
    const routeParams = _extends({}, _lodash.default.get(applicationState, 'routeParams', {}), _lodash.default.get(selfState, 'routeParams', {}));
    // Return the part of the URL that represents the subsite
    // For this to work, the :subsite route should be part of this
    // rubyComponent's path prop:  { path: "/content/:subsite/quick-find" }
    return _lodash.default.get(routeParams, 'subsite');
  },
  getSubsiteUrlIdentifier_forRubyClientId_andSubsiteId: function (rubyClientId, subsiteId) {
    const feSettings = this.getFrontendSettings();
    return rubyAccessManager.getSubsiteUrlIdentifier_fromSettings_forRubyClientId_andSubsiteId(feSettings, rubyClientId, subsiteId);
  },
  getDefaultSubsiteUrlIdentifier: function () {
    const feSettings = this.getFrontendSettings();

    // Return default subsiteUrl
    return rubyAccessManager.getDefaultSubsiteUrlIdentifier_fromSettings(feSettings);
  }
});
module.exports = accessControlMixin;

// Memoize here, so that doesUserHavePermission memoize works
const _createPublishPermission = (0, _memoizee.default)(_raw_createPublishPermission, {
  length: 4
});
function _raw_createPublishPermission(keyword, ruby_client, subsite, action) {
  return {
    keyword,
    ruby_client,
    subsite,
    action
  };
}