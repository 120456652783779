// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[4].use[2]!../../../../ruby-styles/src/client/cssModules/colors.cssModule";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImagePickerWidget__rtgPhoto___OkIEm {\n    width:260px;\n    height:180px;\n    position:relative;\n    overflow:hidden;\n}\n.ImagePickerWidget__rtgPhoto_noMedia____FSht {\n    /* webpackIgnore: true */\n    background: url(/images/image_picker.gif) no-repeat;\n}\n\n\n\n.ImagePickerWidget__rtgPhotoImg___cbwz3 {\n    margin:0 auto;\n}\n.ImagePickerWidget__rtgPhotoImg_noMedia___w1TWY {\n    padding:0;\n    display:none;\n}\n\n\n.ImagePickerWidget__rtgIco___WW785 {\n    position:absolute;\n    top:-3px;\n    right:-5px;\n}\n\n.ImagePickerWidget__rtgIco_noMedia___TILvN {\n    display: none;\n}\n\n.ImagePickerWidget__rtgImageInfo___fTEF8 {\n    color: " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["black_50"] + ";\n    font-size: 0.857143em;\n}\n", "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-image-picker/src/client/reactComponents/ImagePickerWidget.cssModule"],"names":[],"mappings":"AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,eAAe;AACnB;AACA;IAEI,wBAAwB;IACxB,mDAAmD;AACvD;;;;AAIA;IACI,aAAa;AACjB;AACA;IAEI,SAAS;IACT,YAAY;AAChB;;;AAGA;IACI,iBAAiB;IACjB,QAAQ;IACR,UAAU;AACd;;AAEA;IAEI,aAAa;AACjB;;AAEA;IACI,mDAAe;IACf,qBAAqB;AACzB","sourcesContent":["@value black_50 from \"@rubyapps/ruby-styles/src/client/cssModules/colors.cssModule\";\n\n.rtgPhoto {\n    width:260px;\n    height:180px;\n    position:relative;\n    overflow:hidden;\n}\n.rtgPhoto_noMedia {\n    composes: rtgPhoto;\n    /* webpackIgnore: true */\n    background: url(/images/image_picker.gif) no-repeat;\n}\n\n\n\n.rtgPhotoImg {\n    margin:0 auto;\n}\n.rtgPhotoImg_noMedia {\n    composes: rtgPhotoImg;\n    padding:0;\n    display:none;\n}\n\n\n.rtgIco {\n    position:absolute;\n    top:-3px;\n    right:-5px;\n}\n\n.rtgIco_noMedia {\n    composes: rtgIco;\n    display: none;\n}\n\n.rtgImageInfo {\n    color: black_50;\n    font-size: 0.857143em;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black_50": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["black_50"] + "",
	"rtgPhoto": "ImagePickerWidget__rtgPhoto___OkIEm",
	"rtgPhoto_noMedia": "ImagePickerWidget__rtgPhoto_noMedia____FSht ImagePickerWidget__rtgPhoto___OkIEm",
	"rtgPhotoImg": "ImagePickerWidget__rtgPhotoImg___cbwz3",
	"rtgPhotoImg_noMedia": "ImagePickerWidget__rtgPhotoImg_noMedia___w1TWY ImagePickerWidget__rtgPhotoImg___cbwz3",
	"rtgIco": "ImagePickerWidget__rtgIco___WW785",
	"rtgIco_noMedia": "ImagePickerWidget__rtgIco_noMedia___TILvN ImagePickerWidget__rtgIco___WW785",
	"rtgImageInfo": "ImagePickerWidget__rtgImageInfo___fTEF8"
};
export default ___CSS_LOADER_EXPORT___;
