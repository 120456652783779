"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const request = require('@rubyapps/ruby-superagent');
const qs = require('qs');
function generateImagePath(id, width, height, query_params) {
  var path = ['', 'utils', 'modules', id, width, height, 'module-image'].join('/');
  var ext = 'jpg';
  var query_string = qs.stringify(query_params);
  return `${path}.${ext}?${query_string}`;
}
function typesWithID(id) {
  return {
    OPEN_DIALOG: `@@ruby-app/${id}/OPEN_DIALOG`,
    CLOSE_DIALOG: `@@ruby-app/${id}/CLOSE_DIALOG`,
    RESET_DIALOG: `@@ruby-app/${id}/RESET_DIALOG`,
    SNAPSHOT_STATE: `@@ruby-app/${id}/SNAPSHOT_STATE`,
    REVERT_STATE: `@@ruby-app/${id}/REVERT_STATE`,
    SET_PREVIEW: `@@ruby-app/${id}/SET_PREVIEW`,
    CLEAR_PREVIEW: `@@ruby-app/${id}/CLEAR_PREVIEW`
  };
}
const generators = {
  //# NOTE: <Repeater> expects this action to be available
  //# if we want the children to react ONLY to being newly added
  onNewlyAdded: function () {
    const {
      generators
    } = this.getAction();
    return generators.openDialogAndSnapshotState();
  }
  //# options.parent_id
  ,
  openDialog: function (options) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.OPEN_DIALOG
    };
  },
  closeDialog: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.CLOSE_DIALOG
    };
  },
  snapshotState: function () {
    const {
      TYPES
    } = this.getAction();
    const formState = this.formValueFromLocalState(this.getState());
    return {
      type: TYPES.SNAPSHOT_STATE,
      payload: {
        state: formState
      }
    };
  },
  revertState: function () {
    const {
      TYPES
    } = this.getAction();
    const {
      stateSnapshot
    } = this.getState();
    this.formValueToLocalState(stateSnapshot);
    return {
      type: TYPES.REVERT_STATE
    };
  },
  openDialogAndSnapshotState: function () {
    const {
      generators
    } = this.getAction();
    return dispatch => {
      dispatch(generators.openDialog());
      dispatch(generators.snapshotState());
    };
  },
  closeAndResetDialog: function () {
    const {
      generators
    } = this.getAction();
    return dispatch => {
      dispatch(generators.closeDialog());
      dispatch(generators.resetDialog());
    };
  },
  closeAndRevertStateAndResetDialog: function () {
    const {
      generators
    } = this.getAction();
    return dispatch => {
      dispatch(generators.closeDialog());
      dispatch(generators.revertState());
      dispatch(generators.resetDialog());
    };
  },
  resetDialog: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.RESET_DIALOG
    };
  },
  setPreview: function (previewUrl) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SET_PREVIEW,
      payload: {
        previewUrl
      }
    };
  },
  clearPreview: function (previewUrl) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.CLEAR_PREVIEW
    };
  },
  retrievePreview_withFormValue: function (formValue) {
    const {
      selfAction: {
        generators
      },
      parentFormKey,
      parentKey
    } = this.getDependencies();
    const selfKey = this.props.key;
    const fieldKey = this.props.parentKey;
    const templateKey = parentFormKey;
    const parentComponent = this.getParent();
    const selfIndex = parentComponent && parentComponent.getIndexForChildId ? parentComponent.getIndexForChildId(selfKey) : 0;
    const data = _extends({
      module_type: this.props.type
    }, formValue[selfKey]);
    const previewImagePath = generateImagePath(selfKey, this.props.width, this.props.height, {
      field_key: fieldKey,
      template_keyword: templateKey,
      idx: selfIndex,
      data: JSON.stringify(data)
    });
    return generators.setPreview(previewImagePath);
  }
};
module.exports = function () {
  return {
    TYPES: typesWithID(this.getID()),
    generators
  };
};