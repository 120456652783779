"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
var _reduxBatchedActions = require("redux-batched-actions");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const thunkMiddleware = require('redux-thunk');
const {
  createStore,
  combineReducers,
  applyMiddleware
} = require('redux');
const CACHE_CLEAR_TIMEOUT = 500;
const reduxHelperMixin = {
  mixinName: 'rubyComponentMixinReduxHelper'
  //# NOTE: cachedHelperReduxStore probably shouldn't be used
  //# we want to inject the helperReduxStore instance into the cloned nodes instead
  ,
  cachedHelperReduxStore: function (options) {
    if (this._helperReduxStore) {
      return this._helperReduxStore;
    }
    return this._helperReduxStore = this.helperReduxStore(options);
  },
  helperReduxStore: function (options) {
    const selfComponentReducers = this.getReducerByKey(options);
    const middlewares = [thunkMiddleware];
    const createStoreWithMiddleware = applyMiddleware.apply(null, middlewares)(createStore);
    const reduxStore = createStoreWithMiddleware((0, _reduxBatchedActions.enableBatching)(combineReducers(selfComponentReducers)));
    const selfID = this.getID();
    const getEntireState = () => {
      let entireState;
      //# caching entireState to speed up helper methods
      //# we don't really care too much about the entire state, we just need one instace
      //# to provide the expected redux state to the rest of our helper children
      //# since we need the redux state to mimic the real data shape
      if (this._cachedEntireState) {
        entireState = this._cachedEntireState;
      } else {
        //console.time('entireStateClone:'+selfID);
        this._cachedEntireState = _lodash.default.cloneDeep(this.getStore().getState());
        //console.timeEnd('entireStateClone:'+selfID);
        entireState = this._cachedEntireState;
      }
      clearTimeout(this._cachedEntireState_clearTimeoutId);
      this._cachedEntireState_clearTimeoutId = setTimeout(() => {
        this._cachedEntireState;
      }, CACHE_CLEAR_TIMEOUT);
      return entireState;
    };
    const patchedReduxStore = _extends({}, reduxStore, {
      getState: () => {
        const entireState = getEntireState();
        _lodash.default.set(entireState, this.getKeypathArr(), reduxStore.getState()[selfID]);
        return entireState;
      },
      getLocalState: reduxStore.getState
    });
    return patchedReduxStore;
  },
  cachedHelperSelf() {
    if (this._helperSelf) {
      return this._helperSelf;
    }
    return this._helperSelf = this.helperSelf();
  },
  helperSelf() {
    let helperReduxStore;
    const getStore = () => helperReduxStore;

    //# need to deeply copy children
    const helperSelf = this.cloneSelf(undefined, {
      getStore
    }); //depthFirstCloneNode(this, undefined, {getStore});

    //# create reduxStore with helper children
    helperReduxStore = this.helperReduxStore({
      children: helperSelf.getChildren()
    });
    return helperSelf;
  },
  newActionCollectorSpec() {
    const collectedActions = [];
    const collectAction = value => {
      collectedActions.push(value);
    };
    return {
      collectAction: collectAction,
      collectedActions
    };
  }
};
module.exports = reduxHelperMixin;