"use strict";

const _ = require('lodash');
const RubyComponentUtils = require('@rubyapps/ruby-component/src/common/utils');
function mergedChildrenPropsByKeyFromForm_andDefaultProps(formJsItem, defaultProps) {
  const {
    childrenPropsByKey: default_childrenPropsByKey
  } = defaultProps;
  const {
    childrenPropsByKey
  } = formJsItem;
  return RubyComponentUtils.twoDepthMergedPropsFromArr_forKey([defaultProps, formJsItem], 'childrenPropsByKey');
}
module.exports = {
  mergedChildrenPropsByKeyFromForm_andDefaultProps
};