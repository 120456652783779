"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.buildColumnProperties = buildColumnProperties;
exports.columnPropertiesFromArray = columnPropertiesFromArray;
exports.getSimpleNormalizedData = exports.default = void 0;
var _reselect = require("reselect");
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
//# custom normalizer function because:
//# 1 - normalizr is not very performant and it does more than we need ( it handles multiple data types )
//# 2 - we already have perfomance issues with rendering large lists
const getSimpleNormalizedData = (0, _reselect.createSelector)([state => (_lodash.default.get(state, 'data') || []).concat(_lodash.default.get(state, 'staticData') || []), state => _lodash.default.get(state, 'idColumn') || 'id', state => _lodash.default.get(state, 'renderProperties.rowProperties.childColumnName') || 'children'], (allRowData, idKey, childrenKey) => {
  const flattenedData = allRowData.reduce(function flattenReducingFunc(result, curr) {
    const children = curr[childrenKey] || [];
    return result.concat(_extends({}, curr, {
      children: children.map(child => child[idKey])
    }), children.reduce(flattenReducingFunc, []));
  }, []);
  const normalizedData = {
    dataById: _lodash.default.keyBy(flattenedData, idKey),
    rootIds: allRowData.map(rowData => _lodash.default.get(rowData, idKey))
  };
  return normalizedData;
});

//TODO: Move most of this functionality to the component or something like that :|
exports.getSimpleNormalizedData = getSimpleNormalizedData;
function columnPropertiesFromArray(columns) {
  //TODO: Make this more efficient -- this is just kind of make it work at this point
  let properties = {};
  columns.forEach(column => properties[column] = {
    id: column
  });
  return properties;
}
function buildColumnProperties(_ref) {
  let {
    rowProperties,
    allColumns,
    defaultColumns
  } = _ref;
  let columnProperties = defaultColumns ? columnPropertiesFromArray(defaultColumns) : {};
  if (rowProperties && rowProperties.props && !!rowProperties.props.children && Array.isArray(rowProperties.props.children)) {
    columnProperties = rowProperties.props.children.reduce((previous, current) => {
      previous[current.props.id] = current.props;
      return previous;
    }, columnProperties);
  } else if (rowProperties && rowProperties.props && rowProperties.props.children) {
    //if just an object
    columnProperties[rowProperties.props.children.props.id] = rowProperties.props.children.props;
  }

  //TODO: Don't check this this way :|
  if (Object.keys(columnProperties).length === 0 && allColumns) {
    columnProperties = columnPropertiesFromArray(allColumns);
  }
  return columnProperties;
}
const PropertyHelper = {
  propertiesToJS(_ref2) {
    let {
      rowProperties,
      allColumns,
      defaultColumns,
      ignoredColumns = []
    } = _ref2;
    const getHiddenColumns = columnProperties => {
      const visibleKeys = Object.keys(columnProperties);
      const hiddenColumns = allColumns.filter(column => visibleKeys.indexOf(column) < 0);
      let hiddenColumnProperties = {};
      hiddenColumns.forEach(column => hiddenColumnProperties[column] = {
        id: column
      });
      return hiddenColumnProperties;
    };
    const ignoredColumnsWithChildren = ignoredColumns.indexOfChildren > -1 ? ignoredColumns : [...ignoredColumns, 'children'];
    //if we don't have children return an empty metatdata object
    if (!rowProperties) {
      const columnProperties = columnPropertiesFromArray(defaultColumns || allColumns);
      const hiddenColumnProperties = getHiddenColumns(columnProperties);
      return {
        rowProperties: null,
        columnProperties,
        ignoredColumns: ignoredColumnsWithChildren,
        hiddenColumnProperties: hiddenColumnProperties
      };
    }
    const columnProperties = buildColumnProperties({
      rowProperties,
      allColumns,
      defaultColumns
    });
    var rowProps = _extends({}, rowProperties.props);
    delete rowProps.children;
    if (!rowProps.hasOwnProperty('childColumnName')) {
      rowProps.childColumnName = 'children';
    }
    const hiddenColumnProperties = getHiddenColumns(columnProperties);

    //make sure that children is in the ignored column list

    return {
      rowProperties: rowProps,
      columnProperties,
      hiddenColumnProperties,
      ignoredColumns: ignoredColumnsWithChildren,
      metadataColumn: '__metadata'
    };
  }
};
var _default = PropertyHelper;
exports.default = _default;