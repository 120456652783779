"use strict";

require("jquery");
require("jquery-ui-dist/jquery-ui.js");
require('@rubyapps/jquery/utils');

//# NOTE: exposing for backwards compatibility
//# but do not overwrrite jquery is it already exists
if (!window.hasOwnProperty('jQuery') || typeof window.jQuery != 'function') {
  //# NOTE:  we check the type because we polyfill jQuery
  window.$ = $;
  window.jQuery = $;
}
var rubyReduxManager = require('@rubyapps/ruby-redux-manager');
var rubyReactManager = require('@rubyapps/ruby-react-manager');
require('@rubyapps/ruby-polyfills');
const CONSTANTS = require('../common/constants');
window.RUBYAPP_VERSION = CONSTANTS.RUBYAPP_VERSION;
require('../local_modules/ruby-styles/src/client/index');
window.Promise = require('bluebird'); //# always use bluebird for Promise

const IS_DEV_MODE = "production" !== 'production';
IS_DEV_MODE && require('../common/utils/profilers');

//# support rubyapp_debug flag

const queryString = require('query-string');
const queryObject = queryString.parse(location.search);
if (queryObject['rubyapp_debug']) {
  window.RUBYAPP_DEBUG = true;
}

const path = require('path');
require('@rubyapps/ruby-superagent'); //# NOTE: superagent is a singleton, so call on this will patch all superagents with the superagent-cache plugin

if (typeof console == "undefined") {
  window.console = {
    log: function () {}
  };
}
if (console && typeof console.warn == 'undefined') {
  console.warn = function () {};
}
if (console && typeof console.error == 'undefined') {
  console.error = function () {};
}
if (console && typeof console.info == 'undefined') {
  console.info = function () {};
}
window.ReactTestUtils = require('react-addons-test-utils'); //# always enable test utils
if (IS_DEV_MODE) {
  // Allow developers access to React Performance tools
  var Perf = require('react-addons-perf');
  window.React = {
    Perf: Perf
  };

  // Let developers start/stop profiling
  window.startProfile = () => Perf.start();
  window.stopProfile = () => {
    Perf.stop();
    Perf.printInclusive();
    Perf.printExclusive();
    Perf.printWasted();
    Perf.printDOM();
  };
  window.RubyComponentTests = require('@rubyapps/ruby-debug-utils');
  function print_nav_timing_data() {
    // Use getEntriesByType() to just get the "navigation" events
    var perfEntries = performance.getEntriesByType("navigation");
    for (var i = 0; i < perfEntries.length; i++) {
      console.log("= Navigation entry[" + i + "]");
      var p = perfEntries[i];
      // dom Properties
      console.log("DOM content loaded = " + (p.domContentLoadedEventEnd - p.domContentLoadedEventStart));
      console.log("DOM complete = " + p.domComplete);
      console.log("DOM interactive = " + p.interactive);

      // document load and unload time
      console.log("document load = " + (p.loadEventEnd - p.loadEventStart));
      console.log("document unload = " + (p.unloadEventEnd - p.unloadEventStart));

      // other properties
      console.log("type = " + p.type);
      console.log("redirectCount = " + p.redirectCount);
    }
  }
  window.print_nav_timing_data = print_nav_timing_data;
}
window.Modernizr = require("modernizr"); //# exposing modernizr globally for backwards compatibility

// polyfill console.time for unsupported browsers
console.time = console.time || function () {};
console.timeEnd = console.timeEnd || function () {};
var appManager = {
  _rootComponent: undefined,
  _rubyReduxManager: rubyReduxManager,
  _rubyReactManager: rubyReactManager
  /*
   *  config: {
   *      rootComponent: <ruby-component instance>
   *  }
   *
   * */,
  init: function (config) {
    const self = this;
    if (config.overrides && config.overrides.coreModules) {
      let coreModules = config.overrides.coreModules;
      if (coreModules.rubyReduxManager) {
        this._rubyReduxManager = coreModules.rubyReduxManager;
      }
      if (coreModules.rubyReactManager) {
        this._rubyReactManager = coreModules.rubyReactManager;
      }
    }
    this._rootComponent = config.rootComponent;
    let rootComponent = this._rootComponent;
    rootComponent.triggerTree_onMount();
    let rubyReduxManager = this._rubyReduxManager;
    let rubyReactManager = this._rubyReactManager;

    //# setup unsavedChanges check for onbeforeunload
    window.addEventListener("beforeunload", function (e) {
      const activeRouteComponent = rootComponent.getActiveRouteComponent();
      const hasUnsavedChanges = activeRouteComponent && activeRouteComponent.hasUnsavedChanges && activeRouteComponent.hasUnsavedChanges();
      const requestedLocationIsMailto = window.__requestedLocation__ && window.__requestedLocation__.indexOf('mailto') >= 0;
      if (hasUnsavedChanges && !requestedLocationIsMailto) {
        var confirmationMessage = "\o/";
        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Webkit, Safari, Chrome etc.
      }
      //# clear requestedLocation
      window.__requestedLocation__ = undefined;
      rootComponent.iterativelyTraverseChildren_withCallback(element => {
        element.onBeforeUnload && element.onBeforeUnload();
      });
    });

    /* TODO: Commenting because unused currently
    let boundGetFormTemplate_forKey = function(templateKey) {
        return config.forms[templateKey];
    };
    */
    if (typeof window !== "undefined") {
      window.rubyAppManager = this;
      window.rubyApp = this._rootComponent;
    }
    rubyReduxManager.init({
      rootComponent: rootComponent,
      isDevMode: IS_DEV_MODE
    }).then(function (rubyReduxManager) {
      if (typeof window !== "undefined") {
        window.store = rubyReduxManager.getStore();
      }
      rootComponent.triggerTree_onReduxInit(rubyReduxManager.getStore().dispatch);
      config.overrideFormFieldPropsConfig && self._overrideFormFieldPropsInForm(config.overrideFormFieldPropsConfig);
    });
    rubyReactManager.init({
      store: rubyReduxManager.getStore(),
      rootComponent: rootComponent
    }).then(function (rubyReactManager) {
      rubyReactManager.render();
      rootComponent.triggerTree_onReactInit();
    });
    return new Promise(function (resolve, reject) {
      resolve(this);
    });
  }

  //# DEPRECATING: 20171110 - We shouldn't rely on passing in permissionItemsByKey unless we really need to override the set of permission toggles
  ,
  _overrideFormFieldPropsInForm(config) {
    const dispatch = rubyReduxManager.getStore().dispatch;
    const rubyComponentForms = appManager._rootComponent.findDescendentsBy(node => node.componentName == 'rubyComponentForms');
    rubyComponentForms.forEach(node => {
      node.overrideProps_withConfig(config, dispatch);
    });
  }
};
module.exports = appManager;