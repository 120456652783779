"use strict";

const RubyComponent = require('@rubyapps/ruby-component');
const PropTypes = RubyComponent.PropTypes;
const anchorNavigationMixin = {
  mixinName: 'rubyComponentMixinAnchorNavigation',
  navigateToSelf: function () {
    //# NOTE: each component must define this themselves.
    //# This module serves as an interface
    //# 20201026 - DEPRECATING. We only used this to navigate to tabs on hash changes, but now it's the Tabs parent that should be responsible for this
  }
};
module.exports = anchorNavigationMixin;