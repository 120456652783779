"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _Tab = _interopRequireDefault(require("./Tab"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
//import {Tab} from 'material-ui/Tabs';

function generator() {
  const selfModule = this;
  const {
    selfSelector
  } = selfModule.getDependencies();
  const tabProps = Object.keys(_Tab.default.propTypes).concat(['index', 'selected']);
  //# NOTE: material-ui is missing 'index' and 'selected' in props

  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);
    const formattedError = selfModule.formattedErrorFromState(state);
    const children = ownProps.getMergedChildrenReactElements ? ownProps.getMergedChildrenReactElements() : ownProps.children;
    return _extends({}, selfState, ownProps, mixinFieldProps, formattedError, {
      children: children
      //# NOTE: these children are not used here
      //# the actual children used is the ones we return
      //# in the call to getReactElement()
      //# which is extracted by material-ui/Tabs before
      //# this component is rendered
    });
  }

  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
    };
  }
  function mergeProps(stateProps, dispatchProps, ownProps) {
    return _extends({}, dispatchProps, stateProps);
  }

  //# TODO: if we need the tabs to be connected, we will need to figure out how to fix it
  const connectedTab = (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps, mergeProps)(_Tab.default);
  connectedTab.propagatedAttributes = _Tab.default.propagatedAttributes;
  connectedTab.muiName = _Tab.default.muiName;
  return connectedTab;

  //# WARNING: the way the material-ui Tabs component works
  //# it gets the "ownProps" for the children before it calls on this connetor
  //# this means that any alterd props does not propagate to the parent component
  //# and it's overriding the children directly expecting ownProps.children to be
  //# available
  //# which won't be if we're using the mixin-field-dynamic
}