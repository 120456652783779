// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles__refreshIndicator___fqyVg {\n    display: inline-block;\n    position: relative;\n}\n.styles__refreshDivWrapper___P5y3e {\n    position: relative;\n    width: 100%;\n}\n.styles__refreshDivContent___Q2APZ {\n    width: 100%;\n    text-align: center;\n    margin-bottom: -15px;\n}\n.styles__refreshDivContent__tab___gX6V9 {\n    margin-top: 20px;\n}\n", "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-mixin-field-dynamic/src/client/styles.cssModule"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,kBAAkB;AACtB;AACA;IACI,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,oBAAoB;AACxB;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".refreshIndicator {\n    display: inline-block;\n    position: relative;\n}\n.refreshDivWrapper {\n    position: relative;\n    width: 100%;\n}\n.refreshDivContent {\n    width: 100%;\n    text-align: center;\n    margin-bottom: -15px;\n}\n.refreshDivContent__tab {\n    margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"refreshIndicator": "styles__refreshIndicator___fqyVg",
	"refreshDivWrapper": "styles__refreshDivWrapper___P5y3e",
	"refreshDivContent": "styles__refreshDivContent___Q2APZ",
	"refreshDivContent__tab": "styles__refreshDivContent__tab___gX6V9"
};
export default ___CSS_LOADER_EXPORT___;
