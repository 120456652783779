"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "TabTemplate", {
  enumerable: true,
  get: function () {
    return _TabTemplate.default;
  }
});
Object.defineProperty(exports, "Tabs", {
  enumerable: true,
  get: function () {
    return _Tabs.default;
  }
});
var _Tabs = _interopRequireDefault(require("./Tabs"));
var _TabTemplate = _interopRequireDefault(require("./TabTemplate"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }