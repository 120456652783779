"use strict";

const beautify = require('js-beautify');
function beautifyHtml(html) {
  return beautify.html(html, {
    preserve_newlines: false
  });
}
module.exports = {
  sanitizeHTML: html => html.replace(/<\/script/g, '<\\/script').replace(/<!--/g, '<\\!--'),
  beautifyHtml
};