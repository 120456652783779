"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const _ = require('lodash');
const RubyComponent = require('@rubyapps/ruby-component');
const {
  PropTypes
} = RubyComponent;
const routeParser = require('@rubyapps/route-parser');
const jsonselect = require('JSONSelect');
const paramSelector = jsonselect.compile('object:has(:root > .displayName:val("Param"))');

//# This is a helper mixin for utility methods to help parse the url prop
//# This mixin doesn't handle override the _formValueToLocalState() methods
const fieldUrlMixin = {
  mixinName: 'rubyComponentMixinFieldUrl',
  propTypes: {
    url: PropTypes.string
  },
  getInitialState: () => ({
    props: {
      options: null,
      filteredOptions: null
    },
    requestedOptionsUrlByType: null,
    refreshRequestTimestamp: null,
    requestedTimestamp: null,
    requestedQuery: null
  })

  //== UTILITIES =============================//
  ,
  urlRouteParser: function (url) {
    url = url || this.props.url;
    let urlRouteParser = this._urlRouteParser;
    if (url != (urlRouteParser || {}).spec) {
      urlRouteParser = this._urlRouteParser = new routeParser(url);
    }
    return urlRouteParser;
  },
  didUrlDependentFieldsUpdate: function (urlDependentFields) {
    urlDependentFields = urlDependentFields || this.urlDependentFields();
    const cachedUrlDependentFieldValues = this.getStatefulCacheForKey('_cachedUrlDependentFieldValues');
    const formValue = this.getParentFormComponent().formValue(); //# {cacheTemporarily: true} DEPRECATED 20190502 - reference the base field mixin for more info

    const editPageComponent = this.findAncestorBy(element => element.componentName === 'rubyComponentEditPage');
    const formValueWithId = _extends({}, formValue, {
      id: formValue.id ? formValue.id : _.result(editPageComponent, 'getTypedPageId')
    });
    const newUrlDependentFieldValues = _.pick(formValueWithId, urlDependentFields);
    this.setStatefulCacheForKey('_cachedUrlDependentFieldValues', newUrlDependentFieldValues);
    //this.props.key == '__renderType_expanded' && console.log(cachedUrlDependentFieldValues, newUrlDependentFieldValues)

    return !_.isEqual(cachedUrlDependentFieldValues, newUrlDependentFieldValues);
  },
  urlDependentFields: function (url) {
    if (!url) {
      const props = this.props;
      const state = this.getState();
      url = props.url || _.get(state, 'props.url');
    }
    const cachedUrlDependentFields = _.get(this._urlDependentFieldsByUrl, [url]);
    if (cachedUrlDependentFields) {
      return cachedUrlDependentFields;
    }
    if (!url) {
      return false;
    }
    const routeParser = this.urlRouteParser(url);
    if (!this._urlDependentFieldsByUrl) {
      this._urlDependentFieldsByUrl = {};
    }
    const routeParser__params = routeParser ? paramSelector.match(routeParser.ast) : [];
    const paramKeys = routeParser__params.map(param => _.get(param, 'props.name'));
    this._urlDependentFieldsByUrl[url] = paramKeys;
    return this._urlDependentFieldsByUrl[url];
  }

  /**
   * @param {Object=} props - override the props
   * @param {Object=} rootFormValue - override the form's formValue
   * @param {Object=} additionalParams - any additional params to include on the call to routeParser.reverse()
   */,
  hydratedUrl: function () {
    let {
      props,
      state,
      formValue,
      params = {}
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    //# NOTE: TODO: if you need to support JSONPointers, then
    //# this method needs to be updated to also parse the url prop as a template string.
    //# The expectation is that you need to specify the url like '/var/url/${JSONPointer('1/path/to/value')}'

    props = props || this.props;
    if (!state.props) {
      console.warn('state.props should never be null for this component:', this.props, ". It is very likely that the id got munged (and replaced with a number). Please retrace steps to try and reproduce this issue");
    }
    const props__url = props.url || _.get(state, 'props.url');
    if (props__url == undefined) {
      return undefined;
    }
    const urlDepdendentFields = this.urlDependentFields(props__url);
    const isUrlDependent = urlDepdendentFields.length;
    if (!isUrlDependent) {
      return props__url;
    }
    formValue = formValue || this.getParentFormComponent().formValue(); //# {cacheTemporarily: true} DEPRECATED 20190502 - reference the base field mixin for more info

    const urlRouteParser = this.urlRouteParser(props__url);
    const routeParent = this.findAncestorBy(module => module.getRouteElement);
    const pageId = _.get(formValue, 'id') || _.result(routeParent, 'getTypedPageId');
    const routeParser__reverseParams = _extends({}, pageId && {
      id: pageId
    }, formValue, params);

    //# TODO: deep convert arrays, but for now, just convert the top-level arrays
    Object.keys(routeParser__reverseParams).map(key => {
      if (_.isArray(routeParser__reverseParams[key])) {
        routeParser__reverseParams[key] = JSON.stringify(routeParser__reverseParams[key]);
      }
    });

    //# "url" : "/ruby/api/v2/content/office/options?ruby_client_fk=3&content_subsite_fk=1&where=%7B%22rank%22%3A:dependent_rank_tokentagger%7D"
    //# NOTE: '%3A' is not valid, needs to be ':', but it cannot be ':' when using Route Parser
    const hydratedUrl = urlRouteParser.reverse(routeParser__reverseParams);
    if (!hydratedUrl) {
      return false;
    }
    return decodeURIComponent(
    //# NOTE: calling this only for '%3A' => ':' if we have an encodedUriComponent for JSON
    //# this is not ideal but will have to do for now
    hydratedUrl);
  },
  url: function () {
    let {
      props,
      state,
      formValue,
      params
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    props = props || this.props;
    state = state || this.getState();
    const hydratedUrl = this.hydratedUrl({
      props,
      state,
      formValue,
      params
    });
    return hydratedUrl;
  }
};
module.exports = fieldUrlMixin;