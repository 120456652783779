"use strict";

const _ = require('lodash');
const {
  ancestorIdsForId_usingTogglesById
} = require('./utils');
module.exports = function (state, fieldValue) {
  const {
    togglesById,
    toggleTree
  } = state;
  const mutableTogglesById = _.cloneDeep(togglesById); //# Don't modify original togglesById - instead modify a copy
  const updatedTogglesById = setTogglesById_usingFieldValue(mutableTogglesById, fieldValue);
  return updatedTogglesById;
};
function setTogglesById_usingFieldValue(togglesById) {
  let fieldValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (fieldValue === null) {
    return togglesById;
  }
  const {
    fields: keyPaths = [],
    expand = []
  } = fieldValue;
  const allIdsToHydrate = [].concat(idsToHydrateFromKeyPaths_usingTogglesById(keyPaths, togglesById), idsToHydrateFromExpandSpecs_usingTogglesById(expand, togglesById));
  _.forEach(allIdsToHydrate, idToHydrate => {
    togglesById[idToHydrate].toggled = true;
  });
  return togglesById;
}
function idsToHydrateFromKeyPaths_usingTogglesById(keyPaths, togglesById) {
  let keyPathPrefixArray = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  const togglesByKeyPath = _.keyBy(togglesById, 'keyPath');
  const idsToHydrate = _.reduce(keyPaths, (acc, keyPath) => {
    const expandedKeyPath = keyPathPrefixArray.concat(keyPath).join('.');
    //# NOTE: Sometimes toggleData may not exist at `expandedKeypath` since it's possible
    //# that the field at `expandedKeypath` no longer exists (field/template removed via
    //# TemplateBuilder/migration script)
    const toggleData = togglesByKeyPath[expandedKeyPath] || {};
    const {
      id
    } = toggleData;
    return id ? acc.concat(id, ancestorIdsForId_usingTogglesById(id, togglesById)) : acc;
  }, []);
  return idsToHydrate;
}
function idsToHydrateFromExpandSpecs_usingTogglesById(expandSpecs, togglesById) {
  let keyPathPrefixArray = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  const togglesByKeyPath = _.keyBy(togglesById, 'keyPath');
  const idsToHydrate = _.reduce(expandSpecs, (acc, expandSpec) => {
    const keyPath = Object.keys(expandSpec)[0];
    const {
      expand = [],
      fields = []
    } = expandSpec[keyPath];
    const updatedKeyPathPrefixArray = keyPathPrefixArray.concat(keyPath);
    const expandedKeyPath = updatedKeyPathPrefixArray.join('.');
    const toggleData = togglesByKeyPath[expandedKeyPath];
    const {
      id
    } = toggleData;
    return acc.concat(id, ancestorIdsForId_usingTogglesById(id, togglesById), idsToHydrateFromExpandSpecs_usingTogglesById(expand, togglesById, updatedKeyPathPrefixArray), idsToHydrateFromKeyPaths_usingTogglesById(fields, togglesById, updatedKeyPathPrefixArray));
  }, []);
  return idsToHydrate;
}