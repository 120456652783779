"use strict";

const typesFromID = id => ({
  SHOW_LOADING_INDICATOR: `@@ruby-app/${id}/SHOW_LOADING_INDICATOR`,
  HIDE_LOADING_INDICATOR: `@@ruby-app/${id}/HIDE_LOADING_INDICATOR`
});
const generators = {
  show: function (options) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SHOW_LOADING_INDICATOR
    };
  },
  hide: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.HIDE_LOADING_INDICATOR
    };
  }
};
module.exports = function () {
  const TYPES = typesFromID(this.getID());
  return {
    TYPES: TYPES,
    generators: generators
  };
};