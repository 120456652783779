"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _reactRedux = require("react-redux");
var _ConnectorMappersGenerator = _interopRequireDefault(require("@rubyapps/ruby-component-mixin-field-base/src/client/reactComponents/ConnectorMappersGenerator"));
var _RubyComponentFieldDynamicForm = _interopRequireDefault(require("./RubyComponentFieldDynamicForm"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); } //# NOTE: use this if you're writing a field component
function generator() {
  const selfModule = this;
  const selfSelector = this.getDefaultSelector();
  //# NOTE: use this if you're writing a field component
  const {
    mapStateToProps: defaultMapStateToProps,
    mapDispatchToProps: defaultMapDispatchToProps
  } = _ConnectorMappersGenerator.default.call(this);
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const pickedSelfState = _.pick(selfState, ['rerenderTimestamp']);
    return _extends({}, defaultMapStateToProps.apply(null, arguments), pickedSelfState, {
      children: ownProps.getMergedChildrenReactElements ? ownProps.getMergedChildrenReactElements() : ownProps.children
    });
  }

  /*
  function mapDispatchToProps (dispatch, ownProps) {
      return Object.assign({
          delegateActions: {
              mediaGallery: bindActionCreators(mediaGalleryComponent.getAction().generators, dispatch)
          }
      }, defaultMapDispatchToProps.apply(null, arguments));
  }
   function mapStateToProps(state, ownProps) {
      return Object.assign({}, ownProps, selfModule.getState(state));
  }
   function mapDispatchToProps (dispatch, ownProps) {
      return {
          actions: bindActionCreators(selfModule.getAction().generators)
      };
  }
  */

  return (0, _reactRedux.connect)(mapStateToProps, defaultMapDispatchToProps)(_RubyComponentFieldDynamicForm.default);
}