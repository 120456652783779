"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AFTER_REDUCE = AFTER_REDUCE;
exports.GRIDDLE_LOADED_DATA_AFTER = GRIDDLE_LOADED_DATA_AFTER;
exports.GRIDDLE_ROW_TOGGLED = GRIDDLE_ROW_TOGGLED;
exports.getVisibleChildData = getVisibleChildData;
exports.setRowProperties = setRowProperties;
var _lodash = _interopRequireDefault(require("lodash.assign"));
var _griddleCore = require("../../griddle-core");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const IS_DEV_MODE = "production" !== 'production';
const {
  data
} = _griddleCore.GriddleHelpers;
const {
  getVisibleDataColumns
} = data;

/*
OVERALL TODO:
  fix column order
*/
function hasChildren(record) {
  let childrenPropertyName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'children';
  return record.get(childrenPropertyName) && record.get(childrenPropertyName).size > 0;
}
function transform(data, state) {
  let childrenPropertyName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'children';
  let currentData = data;
  /* //# we're being passed the data that we need, so don't use filter
  if(state.get('filter') && state.get('filter') !== '') {
    currentData = filterChildren(data, state.get('filter'), childrenPropertyName);
  }
  */

  return currentData;
}
function sortChildren(data, state, helpers) {
  let childrenPropertyName = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'children';
  const sortColumns = state.getIn(['sortProperties', 'sortColumns']);
  const sortAscending = state.getIn(['sortProperties', 'sortAscending']);
  const idColumn = state.get('idColumn');
  const getSortedRows = function (data) {
    let sort = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    const mappedData = data.map((row, index) => {
      let griddleKey = row.getIn(['__metadata', 'griddleKey']);
      let rowIdValue = getRowIdValue_fromGriddleKey(state.get('data'), griddleKey, idColumn, childrenPropertyName) + '';
      return hasChildren(row) && state.get('expandedRows').has(rowIdValue) === true ? row.set('children', getSortedRows(row.get('children'), true)) : row;
    });
    return sort ? helpers.getSortedData(mappedData, sortColumns, sortAscending) : mappedData;
  };
  if (!sortColumns || !helpers) {
    return data;
  }
  return getSortedRows(data);
}

/*
function nestedParentIdReducer(collector = [], object) {
    if (object && object.children) {
        collector.push(object.id);
        _.reduce(object.children, nestedParentIdReducer, collector);
    }
    return collector;
}
*/

function nestedParentIdReducer_generator(matcher) {
  return function nestedParentIdReducer() {
    let collector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    let object = arguments.length > 1 ? arguments[1] : undefined;
    if (matcher(object)) {
      collector.push(object.id);
      _.reduce(object.children, nestedParentIdReducer, collector);
    }
    return collector;
  };
}
function AFTER_REDUCE(state, action, helpers) {
  //# ~200ms
  //IS_DEV_MODE && console.time('==== AFTER_REDUCE');
  const columns = helpers.getDataColumns(state, data);
  const properties = getProperties(columns);
  const currentData = transform(state.get('visibleData'), state, properties.childrenPropertyName);
  columns.push(properties.childrenPropertyName);

  //# NOTE: we dont' need to sortChildren or getSortedColumsn
  //# we don't rely on objects with ordered keys cause we get the jsonObject instead of using immutable directly
  //# we dont need to sortChildren because we get it already sorted from the server
  //IS_DEV_MODE && console.time('==== newState');
  let newState = state.set('visibleData', getVisibleChildData(currentData, columns)); //# maybe ~70 - 86ms
  //.set('visibleData', sortChildren(getVisibleChildData(data, columns), state, helpers, properties.childrenPropertyName), columns); //# maybe ~90ms
  //.set('visibleData', sortChildren(helpers.getSortedColumns(getVisibleChildData(data, columns), columns), state, helpers, properties.childrenPropertyName), columns); //# ~ 94ms
  //IS_DEV_MODE && console.timeEnd('==== newState');

  const filterState = state.get('filter');
  if (action.type == 'GRIDDLE_FILTERED' && filterState && filterState !== '') {
    //# expand columns that we need to expand
    const idsThatHaveChildren = _.reduce(state.get('data').toJS(), nestedParentIdReducer_generator(object => object && object.children), []);
    const expandedRows = newState.get('expandedRows');
    const newExpandedRows = idsThatHaveChildren.reduce((collector, id) => collector.set(id, true), expandedRows);
    newState = newState.set('expandedRows', newExpandedRows);
    //# TODO: if we want to clear the expandedRows, then we want to watch for filterState == ''
    //# and then clear the expandedRows state
  } else if (action.type == 'GRIDDLE_LOADED_DATA') {
    const idsThatHaveChildren = _.reduce(state.get('data').toJS(), nestedParentIdReducer_generator(object => object && object.__autoexpandForCMS), []);
    const expandedRows = newState.get('expandedRows');
    const newExpandedRows = idsThatHaveChildren.reduce((collector, id) => collector.set(id, true), expandedRows);
    newState = newState.set('expandedRows', newExpandedRows);
  }

  //IS_DEV_MODE && console.timeEnd('==== AFTER_REDUCE');
  //# with just getVisibleChildData: 68 - 151ms
  //# with everything: 69.6 - 153ms (although I think it averages higher)
  return newState;
}
function GRIDDLE_ROW_TOGGLED(state, action, helpers) {
  const {
    griddleKey,
    direction,
    includeDescendants
  } = action;
  const columns = helpers.getDataColumns(state, state.get('data'));
  const {
    childrenPropertyName
  } = getProperties(columns);
  const idColumn = state.get('idColumn');
  let rowValues = griddleKey ? [getRowValue_fromGriddleKey(state.get('data'), griddleKey, idColumn, childrenPropertyName)] : getTopLevelRowValuesWithChildren(state.get('data'));

  // the reason we can't use the griddleKey is because
  // it will likely change as we use filter + sort actions

  let hydratedDirection = direction;
  if (direction == 'toggle') {
    //# if direction is toggle, there should be only one rowValue
    //# even if there are multiple, the direction should be the same for all rows
    //# so use the first rowValue to determine direction
    const rowValue = rowValues[0];
    const rowIdValue = rowValue.get(idColumn) + '';
    hydratedDirection = state.get('expandedRows').has(rowIdValue.toString()) || state.get('expandedRows').has(Number.parseInt(rowIdValue)) ? 'collapse' : 'expand';
  }
  let nodesToToggle = [...rowValues];
  const filteredGetChildren = helpers.filteredGetChildren(child => {
    //# if child has childrne
    return child.get('hasChildren');
  });
  if (includeDescendants) {
    //# walk rowValue and include all children
    rowValues.forEach(rowValue => {
      nodesToToggle = nodesToToggle.concat(helpers.flattenNestedDataBy(filteredGetChildren(rowValue), filteredGetChildren));
    });
  }
  let newState = state;
  if (hydratedDirection == 'collapse') {
    newState = nodesToToggle.reduce((stateAcc, node) => {
      const rowIdValue = node.get(idColumn) + '';
      return stateAcc.set('expandedRows', stateAcc.get('expandedRows').remove(rowIdValue.toString()).remove(Number.parseInt(rowIdValue)));
    }, newState);
  } else {
    newState = nodesToToggle.reduce((stateAcc, node) => {
      const rowIdValue = node.get(idColumn) + '';
      return stateAcc.set('expandedRows', stateAcc.get('expandedRows').set(rowIdValue, true));
    }, newState);
  }
  return newState;
}
function getTopLevelRowValuesWithChildren(data) {
  let childrenPropertyName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'children';
  const rowValues = [];
  data.forEach(row => {
    const children = row.get(childrenPropertyName);
    if (children && children.size > 0) {
      rowValues.push(row);
    }
  });
  return rowValues;
}
function getRowValue_fromGriddleKey(data, griddleKey) {
  let idColumn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'id';
  let childrenPropertyName = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'children';
  let immutableListToSearch = [data];
  let currentNode;
  let foundNode;
  currentNode = immutableListToSearch.pop();
  while (foundNode === undefined) {
    currentNode.forEach(row => {
      if (foundNode === undefined) {
        if (row.get('griddleKey') === griddleKey) {
          foundNode = row;
          return;
        }
        let children = row.get(childrenPropertyName);
        if (children && children.size > 0) {
          immutableListToSearch.push(children);
        }
      }
    });
    currentNode = immutableListToSearch.pop();
  }
  return foundNode;
}
function getRowIdValue_fromGriddleKey(data, griddleKey) {
  let idColumn = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'id';
  let childrenPropertyName = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'children';
  let immutableListToSearch = [data];
  const foundNode = getRowValue_fromGriddleKey.apply(null, arguments);
  return foundNode ? foundNode.get(idColumn) : foundNode;
}

//TODO: This is almost the same as the filterChildrenData method but not applying the filter method :/
function filterChildren(rows, filter) {
  let childrenPropertyName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'children';
  return rows.map(row => {
    let children = row.get(childrenPropertyName);
    if (children && children.size > 0) {
      children = filterChildrenData(row.get(childrenPropertyName), filter, childrenPropertyName);
    }
    return row.set(childrenPropertyName, children);
  });
}
function filterChildrenData(rows, filter) {
  let childrenPropertyName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'children';
  const values = rows.filter(row => {
    let children = row.get(childrenPropertyName);
    if (children && children.size > 0) {
      children = filterChildrenData(row.get(childrenPropertyName), filter, childrenPropertyName);
    }
    const hasMatch = children && children.length > 0 || Object.keys(row.toJSON()).some(key => {
      return row.get(key) && row.get(key).toString().toLowerCase().indexOf(filter.toLowerCase()) > -1;
    });
    return hasMatch;
  });
  return values;
}
function GRIDDLE_LOADED_DATA_AFTER(state, action, helpers) {
  const data = state.get('data');
  const columns = helpers.getDataColumns(state, data);
  const newData = setRowProperties(state, data, getProperties(columns));
  return state.set('data', newData);
}
function getProperties(columns) {
  return (0, _lodash.default)({
    childrenPropertyName: 'children',
    columns: []
  }, columns);
}
function hasMetadata(data) {
  const metadata = data.get(0).get('__metadata');
  return metadata && metadata.size > 0;
}

//# TODO: for this to work, we need to memoize `getVisibleDataColumns`
//# which is a challenge itself because we're using rubyLodash.get
//# and it's also mergeDeep, so the data it returns is completely new
/*
const memoize = require('memoizee')
const memProfile = require('memoizee/profile');
const { defaultMemoizeeOptions } = require('@rubyapps/ruby-memoize');
const memoize__normalizers__getFixed = require("memoizee/normalizers/get-fixed");
const getVisibleChildData__normalizers__getFixed = memoize__normalizers__getFixed(3);
const getVisibleChildData = memoize(
    ...
, {
    ...defaultMemoizeeOptions
    , profileName: 'getVisibleChildData'
    , normalizer: {
        get: (args) => {
            const [data, columns, childrenPropertyName] = args;

            return getVisibleChildData__normalizers__getFixed.get([data, JSON.stringify(columns), childrenPropertyName]);
        }
        , set: (args) => {
            const [data, columns, childrenPropertyName] = args;

            return getVisibleChildData__normalizers__getFixed.set([data, JSON.stringify(columns), childrenPropertyName]);
        }
        , delete: getVisibleChildData__normalizers__getFixed.delete
        , clear: getVisibleChildData__normalizers__getFixed.clear
    }
});
*/
function getVisibleChildData(data, columns) {
  let childrenPropertyName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'children';
  if (data.size === 0) {
    return data;
  }
  //get the data and make sure metadata is applied
  const dataWithMetadata = hasMetadata(data) ? data : getVisibleDataColumns(data, columns);

  //go through each visible child row and set it to use the correct column settings

  return dataWithMetadata.map((row, index) => {
    let children = row.get(childrenPropertyName);
    if (children && children.size > 0) {
      children = getVisibleChildData(children, columns, childrenPropertyName);
    }
    return row.set('children', children);
  });
}
function setRowProperties(state, data, properties) {
  let depth = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  let parentId = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
  let key = 0;
  const getKey = () => key += 1;
  return data.map((row, index) => {
    let children = row.get(properties.childrenPropertyName);
    let currentKey = parentId !== null ? `${parentId}.${getKey()}` : `${row.get('griddleKey')}`;
    if (children && children.size > 0) {
      children = setRowProperties(state, children, {
        childrenPropertyName: properties.childrenPropertyName,
        columns: properties.columns
      }, depth + 1, currentKey);
    }
    return row.sortBy((val, key) => properties.columns.indexOf(key)).set('children', children).set('depth', depth).set('expandedFlag', state.get('expandedRows').get(row.get('id'))).set('griddleKey', currentKey).set('parentId', parentId).set('hasChildren', children && children.size > 0);
  });
}