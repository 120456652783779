"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
var _action = _interopRequireDefault(require("./action"));
var _reducer = _interopRequireDefault(require("./reducer"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const Promise = require('bluebird');
const RubyComponent = require('@rubyapps/ruby-component');
const {
  PropTypes
} = RubyComponent;
const routeParser = require('@rubyapps/route-parser');
const baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
const fieldOptionsMixin = require('@rubyapps/ruby-component-mixin-field-options');
const fieldUrlMixin = require('@rubyapps/ruby-component-mixin-field-url');
const RubyClientFK__CONSTANTS = require('@rubyapps/ruby-component-rubyclientfk/src/common/constants');
const {
  REQUEST_TYPE_FORM_HYDRATION,
  REQUEST_TYPE_OPTIONS_RETRIEVAL
} = require('../common/constants');
const fieldRemoteOptionsMixin = {
  mixinName: 'rubyComponentMixinFieldRemoteOptions',
  propTypes: {
    options: PropTypes.array,
    preloadOptions: PropTypes.bool,
    preloadOptionsQuery: PropTypes.object
  },
  mixins: [fieldOptionsMixin, fieldUrlMixin],
  action: _action.default,
  reducer: _reducer.default,
  getInitialState: () => ({
    props: {
      options: null,
      filteredOptions: null
    },
    requestedOptionsUrlByType: null,
    refreshRequestTimestamp: null,
    requestedTimestamp: null,
    requestedQuery: null,
    searchValue: ''
  }),
  dependencies: function () {
    const RubyClientFKSelector = this.getRoot().findDescendentByID(RubyClientFK__CONSTANTS.COMPONENT_NAME).getDefaultSelector();
    return {
      RubyClientFKSelector
    };
  }

  //== UTILITIES =============================//
  ,

  url: function () {
    let {
      props,
      state,
      formValue,
      params
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    props = props || this.props;
    state = state || this.getState();
    const hydratedUrl = this.hydratedUrl({
      props,
      state,
      formValue,
      params
    });

    //# This was added to fix a bug where the Proposal Field in the Add To Proposal Dialog
    //# didn't default to a just created proposal. ref commit 8f400372
    //# I tested this and it appears to no longer be necessary.
    //# Removing it because it returns a potentially stale url that may no longer be valid.
    //# In particular, it prevents the renderType field in the Media Info Pane from properly
    //# clearing cached options when the Info Pane is closed. That, in turn, prevented the
    //# field from updating if the Info Pane was reopened for the same record.
    //      || _.get(state, ['requestedOptionsUrlByType', REQUEST_TYPE_OPTIONS_RETRIEVAL]);
    return hydratedUrl;
  },
  cacheRequestObject: function (requestObject, requestType) {
    this._requestObjectByType = _extends({}, this._requestObjectByType, {
      [requestType]: requestObject
    });
    ;
  }

  //# NOTE: do not perform value coercion. Used by methods like `formValueFromFieldValue_forKey` which is used by coercedValue
  ,
  _getOptionsMatchingOptionValue_raw: function (optionValue) {
    if (_lodash.default.isNil(optionValue) || optionValue.length == 0) {
      return [];
    }
    const optionValue_normalized = _lodash.default.flow(_lodash.default.castArray, arrValue => _lodash.default.flatMap(arrValue, item => item + "") //# forcing it to a string for the purposes of finding it
    )(optionValue);
    const options = _lodash.default.get(this.getState(), 'props.options') || this.props.options || [];
    const foundOptions = options.filter(option => option && optionValue_normalized.indexOf((option.value || option.id) + "") >= 0);
    return foundOptions;
  }
  //# TODO: override to to the server to get updated data
  //# Assumes options are loaded
  ,
  getOptionsMatchingOptionValue: function (optionValue) {
    if (_lodash.default.isNil(optionValue) || optionValue.length == 0) {
      return [];
    }
    const optionValue_normalized = _lodash.default.flow(_lodash.default.castArray, arrValue => _lodash.default.flatMap(arrValue, item => this.coercedValue(item)))(optionValue);
    const options = _lodash.default.get(this.getState(), 'props.options') || this.props.options || [];
    const foundOptions = options.filter(option => option && optionValue_normalized.indexOf(this.coercedValue(option.value || option.id, true)) >= 0);
    return foundOptions;
  },
  getOptionsMatchingOptionValueAsync: function (optionValue) {
    if (_lodash.default.isNil(optionValue) || optionValue.length == 0) {
      return Promise.resolve([]);
    }
    const optionValue_normalized = _lodash.default.flow(_lodash.default.castArray, arrValue => _lodash.default.flatMap(arrValue, item => this.coercedValue(item)))(optionValue);
    const options = _lodash.default.get(this.getState(), 'props.options') || this.props.options || [];
    const foundOptions = options.filter(option => option && optionValue_normalized.indexOf(this.coercedValue(option.value || option.id, true)) >= 0);
    if (foundOptions.length) {
      return Promise.resolve(foundOptions);
    } else {
      return this.retrieveOptionsFromUrl_withQueryAsync(this.url(), {
        id: optionValue_normalized
      }, undefined, REQUEST_TYPE_FORM_HYDRATION).then(data => {
        //# NOTE: This is not necessary but the User options endpoint currently doesn't filter by id
        const foundOptions = data.filter(option => option && optionValue_normalized.indexOf(this.coercedValue(option.value || option.id, true)) >= 0);

        //# might as well cache the options here
        this.getStore().dispatch(this.getAction().generators.updateCachedOptions_withAdditional(data));
        return foundOptions;
      });
    }
  },
  normalizedOptions: function (options) {
    return options.map(option => {
      return _extends({}, option, {
        value: option.value || option.id,
        text: this.getDisplayTextFromOption(option)
      });
    });
  },
  getDisambiguatedOptionsFromHardcodedOptions: function (options) {
    const optionsWithUniqueValues = _lodash.default.uniqBy(options, 'value') || [];
    const disambiguatedOptions = optionsWithUniqueValues.map((thisOption, index) => {
      const otherOptions = options.slice(0, index).concat(options.slice(index + 1));
      const originalDisplayText = this.getDisplayTextFromOption(thisOption);
      const optionsWithMatchingDisplayText = otherOptions.filter(otherOption => originalDisplayText === this.getDisplayTextFromOption(otherOption));
      const finalDisplayText = optionsWithMatchingDisplayText.length > 0 ? `${originalDisplayText} (${thisOption.value})` : originalDisplayText;
      return _extends({}, thisOption, {
        text: finalDisplayText
      });
    });
    return disambiguatedOptions;
  },
  retrieveOptionsFromUrl_withQueryAsync: function (url, query) {
    let allowAbort = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
    let requestType = arguments.length > 3 ? arguments[3] : undefined;
    const store = this.getStore();
    const actions = this.getAction().generators;
    return actions.retrieveOptionsFromUrl_withQuery(url, query, undefined, allowAbort, requestType)(store.dispatch, store.getState);
  },
  onReduxInit: function (dispatch) {
    //if (this.props.options) { return; }
    if (!this.props.url) {
      return;
    } //# TODO: figure out why we changed the check to options: 53685d0e99d8ef53d3c65c2e767f6a4831736dcb
    const store = this.getStore();
    const actions = this.getAction().generators;

    /*
        //# NOTE: don't need this UNLESS we want to preload options
        //# before the reactComponent is mounted
        if (this.props.preloadOptions) {
            dispatch(actions.cacheOptionsFromUrl_withQuery(this.url()));
        }
    */

    //# NOTE: This is used for caching all options
    //# this won't work with the tokentagger since we expect to have filtered data
    return store.subscribe(() => {
      if (_lodash.default.isNil(this.getState())) {
        return;
      }
      const {
        requestedOptionsUrlByType,
        refreshRequestTimestamp,
        requestedTimestamp,
        requestedQuery,
        isMounted
      } = this.getState();
      const props = this.getProps();
      const {
        defaultToFirstOption
      } = props;
      const urlDependentFields = this.urlDependentFields();
      const isUrlDependent = urlDependentFields.length;
      if (isUrlDependent) {
        //# check if dependent fields updated
        const didUrlDependentFieldsUpdate = this.didUrlDependentFieldsUpdate(urlDependentFields);
        if (!didUrlDependentFieldsUpdate) {
          //console.log('== dependent fields is same for: ',  this.props.key);
          return;
        }
      }
      const props__url = this.url({
        props
      });

      //# Instead of preventing updating/retrieving the options if the requestedOptionsUrl is null 
      //# (we previously did this because we relied on preloadOptions() being called)
      //# we instead check if the component is mounted before we allow for reretrieval
      //# so for the case of the Add to Proposal Dialog, it doens't have a url to preload
      //# so it waits for it to be mounted before it seeds its options
      const requestedOptionsUrl = _lodash.default.get(requestedOptionsUrlByType, [REQUEST_TYPE_OPTIONS_RETRIEVAL]);
      const isMounted_orPreloaded_orDefaultToFirst = isMounted || requestedOptionsUrl || defaultToFirstOption;
      if (isMounted_orPreloaded_orDefaultToFirst && requestedOptionsUrl != props__url) {
        //console.log(`[${this.key()}] requestedOptionsUrl: ${requestedOptionsUrl} vs props__url: ${props__url}`)
        dispatch(actions.retrieveOptionsWithQuery(
        //# `requestedQuery` is not guaranteed to be defined yet.
        //# For TokenTaggers, this may cause a request without a `count` param, which may timeout.
        //# When it's not defined, using `preloadOptionsQuery` since this subscription is essentially
        //# pre-loading options for the new `props__url`.
        //# Otherwise, use `requestedQuery` so that `search_input` is used if it has been set.
        requestedQuery || this.props.preloadOptionsQuery, {
          url: props__url,
          clearCache: true,
          thenableArguments: [data => {
            if (isUrlDependent) {
              //# re-seed the form value to see if current value is still valid
              const currentFormValue = this.formValue();
              if (_lodash.default.isNil(currentFormValue[this.key()])) {
                return;
              }
              //# no current value so we don't need to check for validity

              this.formValueToLocalState(currentFormValue);
            }
          }]
        }));
      } else if (refreshRequestTimestamp > requestedTimestamp) {
        //console.log(`[${this.key()}] refreshRequestTimestamp: ${refreshRequestTimestamp} vs ${requestedTimestamp}`)
        //# re-request the data
        dispatch(actions.retrieveOptionsFromUrl_withQuery(requestedQuery, {
          url: requestedOptionsUrl,
          clearCache: true
        }));
      }
    });
  },
  getPrunedValue_fromRawValue_withOptions: function getPrunedValue_fromRawValue_withOptions(value, options) {
    const validOptionValues = (options || []).map(option => this.coercedValue(option.value || option.id, true));
    if (_lodash.default.isArray(value)) {
      const filteredValue = value.filter(optionValue => validOptionValues.includes(optionValue));
      return _lodash.default.isEmpty(filteredValue) ? null : filteredValue;
    } else {
      return validOptionValues.includes(value) ? value : null;
    }
  }

  //== OVERRIDES =============================//
  ,
  _formValueFromLocalState: function (selfState, isError, predicateFormatter, entireState) {
    let options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
    const selfKey = this.props.key;
    const formValue = baseFieldMixin._formValueFromLocalState.apply(this, arguments);
    if (formValue.hasOwnProperty(selfKey)) {
      if (formValue[selfKey] == undefined && this.props.defaultToFirstOption) {
        formValue[selfKey] = _lodash.default.get(selfState, 'props.options.0.value');
      }
    }
    return formValue;
  },
  _formValueToLocalState: function (formValue, dispatchOrCollect, isError, entireFormValue) {
    let options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
    const dispatch = this.getStore().dispatch;
    const actions = this.getAction().generators;
    const selfID = this.getID();
    const selfKey = this.props.key;
    if (!formValue.hasOwnProperty(selfKey)) {
      //# only short-circuit if property is missing
      //# otherwise, continue populating (even if null)
      return Promise.resolve(null);
    }
    if (isError) {
      return baseFieldMixin._formValueToLocalState.apply(this, arguments);
    }

    //# support omit field picker
    const shouldOmitField = _lodash.default.isFunction(options.omitFieldPicker) && options.omitFieldPicker(this, ...arguments);
    if (shouldOmitField) {
      return undefined;
    } //# NOTE: this will get checked later because we call on baseFieldMixin, but we want to preemptively call on it
    //# so we don't superfluously call on the options request

    //# This is used to filter out entries with the wrong data-type, e.g. omit strings if the field's data-type is
    //# `number`. This change was applied to avoid a `number_format_exception` from ES when attempting to find
    //# records by a string id, when the template uses number values for ids.
    //# Ideally, we should be able to search by both strings and numbers and the connector should handle that
    //# gracefully. Once that is done, this can be replaced with:
    // const formValueForKey = formValue[selfKey]
    const formValueForKey = this.formValueFromFieldValue_forKey(this.fieldValueFromFormValue_forKey(formValue[selfKey], selfKey), selfKey);
    const props__options = (_lodash.default.get(this.getState(), 'props.options') || []).concat(this.props.options || []);
    const formValueForKey_prunedByPropsOptions = this.getPrunedValue_fromRawValue_withOptions(formValueForKey, props__options);
    const props__url = this.url({
      formValue: entireFormValue
    });
    const propsOptionsAreComplete = _lodash.default.isEqual(formValueForKey_prunedByPropsOptions, formValueForKey);
    if (props__url && !propsOptionsAreComplete) {
      return new Promise((resolve, reject) => {
        dispatch(actions.retrieveOptionsFromUrl_withQuery(props__url, {
          id: formValueForKey
        }, [data => {
          const allOptions = data.concat(props__options);
          const formValueForKey_prunedByAllOptions = this.getPrunedValue_fromRawValue_withOptions(formValueForKey, allOptions);
          if (!_lodash.default.isEqual(formValueForKey_prunedByAllOptions, formValueForKey)) {
            //# some options aren't found, we're filtering them out
            console.warn(`Some options are no longer available, we are filtering them out on hydration` + `. Field key: '${this.props.key}'. Found Options:`, allOptions);
          }

          //# NOTE: previously, we're mutating the object here
          //# This wasn't the greatest
          formValue = _extends({}, formValue, {
            [selfKey]: formValueForKey_prunedByAllOptions
          });

          //# might as well cache the options here
          dispatch(actions.updateCachedOptions_withAdditional(data));
          resolve(baseFieldMixin._formValueToLocalState.call(this, formValue, dispatchOrCollect, false, entireFormValue, options));
        }, err => {
          reject(err);
        }], undefined, false //# clearCache
        , REQUEST_TYPE_FORM_HYDRATION));
      }).catch(err => {
        console.error(`Error retrieving options for form key ${selfKey} with value ${formValueForKey}: `, err);
      });
    } else {
      if (!propsOptionsAreComplete) {
        //# some options aren't found, we're filtering them out
        console.warn(`Some options are no longer available, we are filtering them out on hydration. Field key:` + ` '${this.props.key}'. Found Options:`, props__options);
      }
      formValue = _extends({}, formValue, {
        [selfKey]: formValueForKey_prunedByPropsOptions
      });
      return baseFieldMixin._formValueToLocalState.call(this, formValue, dispatchOrCollect, false, entireFormValue, options);
    }
  },
  resetStore: function (dispatchOrCollect, shouldResetStore) {
    //# immediately call on resetStore instead of dispatchOrCollect
    const store = this.getStore();
    const actions = this.getAction().generators;
    const children = this.getChildren();

    //# NOTE: temporary measure to really immediately dispatch for thunks
    //# Since we may call on resetStore with a collector for dispatching thunks
    const shouldResetStoreResult = shouldResetStore ? shouldResetStore(this) : true;
    //# default is true;

    if (shouldResetStoreResult) {
      actions.resetStore && store.dispatch(actions.resetStore());
      children.forEach(child => {
        child.resetStore && child.resetStore.apply(child, arguments);
      });
    }
  },
  clearForm: function (dispatchOrCollect) {
    if (!dispatchOrCollect) {
      dispatchOrCollect = this.getStore().dispatch;
    }
    const children = this.getChildren();
    const {
      generators: actions
    } = this.getAction();

    //# getInitialState() and replace the field
    const initialState = this.getInitialState ? this.getInitialState() : {};
    if (initialState.hasOwnProperty('fields') || initialState.hasOwnProperty('error')) {
      //# set initialState differently if this.props.defaultToFirstOption;
      const newLocalState = _lodash.default.pick(initialState, ['fields', 'error']);
      if (this.props.defaultToFirstOption) {
        //# NOTE: cannot defaultToFirstOption on form clear because of the async nature of retrieving props
        /*
        const options = _.get(this.getState(), ['props', 'options'], []) || [];
        if (options.length) {
            const firstOptionValue = this.coercedValue(options[0].value);
            _.set(newLocalState, ['fields', this.props.key, 'value'], firstOptionValue);
        }*/
        //# try setting it to be undefined so that we can dynamically select the first option
        //# since undefined is treated differently than null
        _lodash.default.set(newLocalState, ['fields', this.props.key, 'value'], undefined);
      }
      dispatchOrCollect(actions.replaceLocalState(newLocalState));
    }
    children.forEach(child => {
      if (child.clearForm) {
        child.clearForm(dispatchOrCollect);
      }
    });
  }

  //== OVERRIDES ======================//
  ,
  sampleValueFromFieldSpecs: function () {
    //# first check if getState().props.options has any
    //# hit options endpoint and rando select options
    const selfState = this.getStore() ? this.getState() : {};
    const options = _lodash.default.get(selfState, 'props.options') || this.props.options;
    const faker = require('@rubyapps/ruby-faker');
    if (options && options.length) {
      return faker.helpers.randomize(options).value;
    }
    const selfUrl = this.url();
    if (!selfUrl) {
      console.error(`[${this.getID()}] this.url() was called but no url prop`);
    }
    const actions = this.getAction().generators;
    const dispatch = this.getStore().dispatch;
    return new Promise((resolve, reject) => {
      //# TODO: replace with retrieveOptionsWithQuery() in v11
      dispatch(actions.retrieveOptionsFromUrl_withQuery(selfUrl + '&count=10' //# arbitrary limit for count
      , undefined, [data => {
        //dispatch(actions.updateCachedOptions_withAdditional(data));
        //# NOTE: cannot update cache because this field might be in a repeater (which isn't created yet), this it has no real state

        resolve(faker.helpers.randomize(data).value);
      }]));
    });
  }
};
module.exports = fieldRemoteOptionsMixin;