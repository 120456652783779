"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const RubyComponentTemplateEditPage__CONSTANTS = require('@rubyapps/ruby-component-plugin-template-editor/src/local_modules/ruby-component-template-edit-page/src/common/constants');
const RubyComponentFieldForm__CONSTANTS = require('@rubyapps/ruby-component-field-form/src/common/constants');

//== The Middleware ========================================================//

const middlewareInitializer = function (store) {
  const selfModule = this;
  const selfSelector = this.getDefaultSelector();
  const selfAction = selfModule.getAction();
  const {
    pluginTemplateEditorComponent
  } = selfModule.getDependencies();
  const formComponents = pluginTemplateEditorComponent.findDescendentsBy(node => node.componentName == RubyComponentFieldForm__CONSTANTS.COMPONENT_NAME);
  const saveSuccess_actionTypes = formComponents.reduce((collector, formComponent) => {
    const SAVE_SUCCESSFUL_TYPE = formComponent.getAction().TYPES.SAVE_SUCCESSFUL;
    if (SAVE_SUCCESSFUL_TYPE) {
      collector.push(SAVE_SUCCESSFUL_TYPE);
    }
    return collector;
  }, []);
  return next => action => {
    let nextRetVal = next(action); //# still allow @@router to continue propagation
    const actionType = action.type;
    if (_lodash.default.includes(saveSuccess_actionTypes, actionType)) {
      store.dispatch(selfAction.generators.retrieveTemplates());
    }
    return nextRetVal;
  };
};
module.exports = middlewareInitializer;