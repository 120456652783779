"use strict";

var _reduxBatchedActions = require("redux-batched-actions");
function typesWithID(id) {
  return {
    SET_IS_LOADING: `@@ruby-app/${id}/SET_IS_LOADING`,
    SET_REQUEST_OBJECT: `@@ruby-app/${id}/SET_REQUEST_OBJECT`,
    SET_REQUEST_RESPONSE: `@@ruby-app/${id}/SET_REQUEST_RESPONSE`
  };
}
const generators = {
  setIsLoading: function (isLoading) {
    const {
      TYPES,
      generators
    } = this.getAction();
    return {
      type: TYPES.SET_IS_LOADING,
      payload: {
        isLoading
      }
    };
  },
  setRequestObject: function (requestObject) {
    const {
      TYPES,
      generators
    } = this.getAction();
    return (0, _reduxBatchedActions.batchActions)([generators._setRequestObject(requestObject), generators.setIsLoading(true)]);
  },
  clearRequestObject: function () {
    const {
      TYPES,
      generators
    } = this.getAction();
    return (0, _reduxBatchedActions.batchActions)([generators._clearRequestObject(), generators.setIsLoading(false)]);
  },
  _setRequestObject: function (requestObject) {
    const {
      TYPES,
      generators
    } = this.getAction();
    return {
      type: TYPES.SET_REQUEST_OBJECT,
      payload: {
        requestObject
      }
    };
  },
  _clearRequestObject: function () {
    const {
      TYPES,
      generators
    } = this.getAction();
    return {
      type: TYPES.SET_REQUEST_OBJECT,
      payload: {
        requestObject: null
      }
    };
  },
  setRequestResponse: function (requestResponse) {
    const TYPES = this.getAction().TYPES;
    //response.constructor.name == "Error" if it's an error
    return {
      type: TYPES.SET_REQUEST_RESPONSE,
      payload: {
        requestResponse
      }
    };
  }
};
module.exports = function () {
  return {
    TYPES: typesWithID(this.getID()),
    generators
  };
};