"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
const defaultChildrenPropsByKeyFromProps = props => {
  const {
    mode,
    disabled,
    locked
  } = props;
  return {
    open_graph_title: {
      label: 'Open Graph Title',
      componentName: "Text",
      key: 'open_graph_title',
      data_type: 'string',
      defaultValue: '',
      ruby_permissions: props.ruby_permissions,
      mode,
      disabled,
      locked
    },
    open_graph_description: {
      label: 'Open Graph Description',
      componentName: "Textarea",
      key: 'open_graph_description',
      data_type: 'string',
      defaultValue: '',
      ruby_permissions: props.ruby_permissions,
      mode,
      disabled,
      locked
    },
    open_graph_image: {
      label: 'Open Graph Image',
      componentName: "ImagePicker",
      key: 'open_graph_image',
      data_type: 'number',
      defaultValue: '',
      ruby_permissions: props.ruby_permission,
      constraints: {
        type: 'jpg,png,gif',
        max_width: '1200',
        max_height: '1200',
        rec_max_file_size: '1000KB'
      },
      mode,
      disabled,
      locked
    }
  };
};
const childrenFormFromProps = _ref => {
  let {
    childrenPropsByKey = {}
  } = _ref;
  const {
    open_graph_title,
    open_graph_description,
    open_graph_image
  } = childrenPropsByKey;
  return [_objectSpread({
    componentName: "Text"
  }, open_graph_title), _objectSpread({
    componentName: "Textarea"
  }, open_graph_description), _objectSpread({
    componentName: "ImagePicker"
  }, open_graph_image)];
};
module.exports = {
  defaultChildrenPropsByKeyFromProps,
  childrenFormFromProps
};