"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const EXCLUDED_COMPONENT_NAMES = ['NamespaceSelector'];
function selectedColumnsPropsFromRubyComponentProps_andGriddleProps(rubyComponentProps, griddleProps) {
  const {
    searchableFields = [],
    listerConfig
  } = rubyComponentProps;
  const filteredSearchableFields = searchableFields.filter(searchableField => EXCLUDED_COMPONENT_NAMES.indexOf(searchableField.componentName) < 0);
  const columnSpecsArrFromGriddleColumnProps = _lodash.default.map(_lodash.default.omit(_lodash.default.get(griddleProps, 'state.renderProperties.columnProperties', {}), ['id']), columnSpec => _extends({}, columnSpec, {
    key: columnSpec.id
  }));
  const griddleSelectedColumnSpecsArr = _lodash.default.get(griddleProps, 'state.utilityBarProperties.selectedColumnSpecsArr', columnSpecsArrFromGriddleColumnProps);
  let selectedColumnsProps = {
    selectedColumnSpecsArr: griddleSelectedColumnSpecsArr,
    searchableFields: filteredSearchableFields
  };
  if (listerConfig) {
    selectedColumnsProps.listerConfig = listerConfig;
  }
  return selectedColumnsProps;
}
var _default = selectedColumnsPropsFromRubyComponentProps_andGriddleProps;
exports.default = _default;