"use strict";

const request = require('@rubyapps/ruby-superagent');
const typesForId = id => ({
  UPDATE__LAST_UPDATED_TIMESTAMP: `@@ruby-app/${id}/UPDATE__LAST_UPDATED_TIMESTAMP`
});
var generators = {
  update_lastUpdatedTimestamp: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.UPDATE__LAST_UPDATED_TIMESTAMP,
      payload: {
        lastUpdatedTimestamp: new Date()
      }
    };
  }
};
module.exports = function () {
  return {
    TYPES: typesForId(this.getID()),
    generators: generators
  };
};