"use strict";

const defaultState = {};
module.exports = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  const {
    TYPES
  } = this.getAction();
  const {
    type,
    payload
  } = action;
  switch (type) {
    default:
      return state;
  }
};