"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = _default;
var _immutable = _interopRequireDefault(require("immutable"));
var _lodash = _interopRequireDefault(require("lodash"));
var _store = _interopRequireDefault(require("store"));
var constants = _interopRequireWildcard(require("./preferences-plugin-constants"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function _default(preferencesStorageKeyIdentifier, preferencesToSave) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  // figure out the key where the preferences are saved

  const version = RUBYAPP_VERSION || '0.0';
  let preferencesStorageKey = [constants.LOCAL_STORAGE_KEY];
  if (preferencesStorageKeyIdentifier) {
    preferencesStorageKey.push(preferencesStorageKeyIdentifier);
  }
  preferencesStorageKey = preferencesStorageKey.join('::') || '';

  // fetch the preferences
  let savedGriddleState = _lodash.default.get(_store.default.get(preferencesStorageKey), [version], {});

  //# incorporate any initialState defined by options
  preferencesToSave.forEach(preferenceKey => {
    const overrideState = _lodash.default.get(options, ['initialState', preferenceKey]);
    if (overrideState) {
      if (_lodash.default.isPlainObject) {
        savedGriddleState[preferenceKey] = _extends({}, savedGriddleState[preferenceKey], overrideState);
      } else {
        savedGriddleState[preferenceKey] = overrideState;
      }
    }
  });
  return _immutable.default.fromJS(_objectSpread(_objectSpread({}, savedGriddleState), {}, {
    preferencesStorageKey,
    preferencesToSave,
    filterHintText: options.filterHintText || 'Search'
  }));
}
;