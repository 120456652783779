"use strict";

const RubyComponentForms = require('@rubyapps/ruby-component-forms');

//== The Middleware ========================================================//

const middlewareInitializer = function (store) {
  const selfModule = this;
  const selfSelector = this.getDefaultSelector();
  const {
    TYPES,
    generators: actions
  } = selfModule.getAction();
  return next => action => {
    let nextRetVal = next(action); //# still allow @@router to continue propagation
    let mySetRouteAction;
    switch (action.type) {
      case 'BATCHING_REDUCER.BATCH':
        const batchPayload = action.payload;
        mySetRouteAction = _.find(batchPayload, {
          'type': TYPES.SET_ROUTE_PARAMS
        });
        if (mySetRouteAction) {
          //# trigger login attempt
          store.dispatch(actions.logIn());
        }
        break;
      case TYPES.SET_ROUTE_PARAMS:
        //# trigger login attempt
        store.dispatch(actions.logIn());
        break;
    }
    return nextRetVal;
  };
};
module.exports = middlewareInitializer;