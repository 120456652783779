"use strict";

/* jshint -W138 */

const defaultState = {
  pristineFields: null,
  pristineErrors: null //# might not need
  ,
  error: null
};
module.exports = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  const {
    TYPES
  } = this.getDependencies().selfAction;
  const {
    type,
    payload
  } = action;
  switch (type) {
    case TYPES.SEED_CHILDREN:
      return state;
    default:
      return state;
  }
};