"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const FONT_SIZE = 14;
const FLOATING_LABEL_STYLE = {
  top: 42
};
const BaseDatetimePicker = {
  DatePicker: {
    floatingLabelStyle: FLOATING_LABEL_STYLE,
    style: {
      width: 'auto'
    },
    textFieldStyle: {},
    errorStyle: {
      position: 'absolute',
      marginBottom: -22,
      width: 300
    }
  },
  TimePicker: {
    floatingLabelStyle: FLOATING_LABEL_STYLE,
    style: {
      width: 'auto'
    },
    textFieldStyle: {},
    errorStyle: {
      position: 'absolute',
      width: 160,
      marginBottom: -22
    }
  },
  DatePickerWrapper: {
    style: {
      width: 150,
      position: 'relative',
      marginRight: 20
    }
  },
  TimePickerWrapper: {
    style: {
      position: 'relative'
    }
  }
};
const StartDatetimePicker = _lodash.default.merge({}, BaseDatetimePicker);
const EndDatetimePicker = _lodash.default.merge({}, BaseDatetimePicker);
var _default = {
  Dropdown: {
    SelectField: {
      //# old
      floatingLabelStyle: FLOATING_LABEL_STYLE,
      style: {
        fontSize: FONT_SIZE,
        width: 106,
        marginTop: -14
      },
      labelStyle: {
        top: 9
      }
    },
    Selectize: {
      floatingLabelStyle: {
        top: 12,
        fontSize: FONT_SIZE
      },
      style: {
        fontSize: FONT_SIZE,
        width: 125,
        marginTop: 8
      }
    }
  },
  StartDatetimePicker,
  EndDatetimePicker
};
exports.default = _default;