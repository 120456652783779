"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _reactRedux = require("react-redux");
var _mustache = _interopRequireDefault(require("mustache"));
var _lodash = _interopRequireDefault(require("lodash"));
var _Text = _interopRequireDefault(require("./Text"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const mustacheHelpers = {
  appendPeriodIfMissing: function () {
    return function (text, render) {
      const newText = text.endsWith('.') ? text : text + '.';
      return render(newText);
    };
  }
};
function generator() {
  const selfModule = this;
  const {
    selfSelector,
    frontendSettingsComponent,
    parentForm
  } = selfModule.getDependencies();
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const {
      interpolateValue
    } = ownProps;
    const rawValue = _lodash.default.get(ownProps, 'value');
    const config = frontendSettingsComponent.getState();
    const view = !interpolateValue ? {} : _extends({
      config,
      pageData: parentForm.formValue()
    }, mustacheHelpers);
    let outputValue = rawValue;
    if (rawValue && interpolateValue) {
      switch (interpolateValue) {
        case 'lodash':
          outputValue = _lodash.default.template(rawValue)(view);
          break;
        case true:
        case 'mustache':
        default:
          outputValue = _mustache.default.render(rawValue, view);
          break;
      }
    }
    const mappedProps = _extends({}, ownProps, {
      id: selfModule.getID(),
      value: outputValue
    });
    return mappedProps;
  }
  return (0, _reactRedux.connect)(mapStateToProps)(_Text.default);
}