"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
/* jshint -W138 */

/*
const defaultState = {
    onSelect: null
    , open: false
};
*/

module.exports = function (state, action) {
  const TYPES = this.getAction().TYPES;
  if (state == undefined) {
    state = this.getInitialState();
  }
  const {
    type,
    payload
  } = action;
  switch (type) {
    case TYPES.OPEN_DIALOG:
      return _extends({}, state, {
        open: true
      });
    case TYPES.CLOSE_DIALOG:
      return _extends({}, state, {
        open: false
      });
    case TYPES.SET_OPTIONS:
      {
        return _extends({}, state, action.payload);
      }
    case TYPES.RESET_DIALOG:
      return _extends({}, state, this.getInitialState());
    default:
      return state;
  }
};