"use strict";

var _bluebird = _interopRequireDefault(require("bluebird"));
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
module.exports = {
  //# returns a tree of promises
  //# TODO: need to call on Promise.props
  _displayValueFromLocalState: function (selfState, entireState) {
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    //console.log(`[__formValueFromLocalState()] id: ${this.getID()}, options:`, options);
    if (selfState == undefined) {
      return _bluebird.default.resolve(undefined);
    }
    const selfID = this.getID();
    const selfKey = this.props.key;
    const children = options.ignoreChildren ? [] : this.getChildren();
    const arrOfReducedChildrenPromises = _lodash.default.reduce(children, (collector, child, index) => {
      const childID = child.getID();
      const childDisplayValue = child._displayValueFromLocalState ? child._displayValueFromLocalState(selfState[childID], entireState, options) : undefined;
      if (childDisplayValue != undefined) {
        collector.push(childDisplayValue);
      }
      return collector;
    }, []);
    const reducedChildrenPromise = _bluebird.default.all(arrOfReducedChildrenPromises).then(arrOfReducedChildrenState => _extends({}, ...arrOfReducedChildrenState));
    const selfHasFieldsState = selfState.hasOwnProperty('fields');
    const reducedChildrenAvail = arrOfReducedChildrenPromises.length > 0;
    const displayObject = reducedChildrenAvail && selfKey ? {
      [selfKey]: reducedChildrenPromise
    } : reducedChildrenAvail ? reducedChildrenPromise : selfHasFieldsState ? this._getValuesFromFieldsObject(selfState.fields, false, undefined, options) : {}; //# return an empty object which gets merged in

    return _bluebird.default.props(displayObject);
  },
  displayValueFromLocalState: function (selfState, entireState, options) {
    if (!entireState) {
      entireState = this.getStore().getState();
    }
    entireState = _lodash.default.cloneDeep(entireState); //# prevent accidental mutations

    return this._displayValueFromLocalState(selfState, entireState, options);
  },
  displayValue: function (options) {
    return this.displayValueFromLocalState(this.getState(), options);
  },
  cloneSelf: function (parentNode) {
    let overrides = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const children = overrides.children || this.getChildren();
    const clonedNode = Object.create(this);
    clonedNode._isHelper = true;
    clonedNode.clearCache();
    if (parentNode) {
      clonedNode._parent = parentNode;
    }
    ;
    clonedNode.getStore = overrides.getStore;

    //console.log('cloning:', clonedNode.getID(), 'clonedNode._isHelper: ', clonedNode._isHelper);
    const clonedChildren = children.map(child => child.cloneSelf(clonedNode, overrides));
    clonedNode._children = clonedChildren;
    return clonedNode;
  }
};