"use strict";

const _ = require('lodash');

/*
    example: 
    {
        childrenPropsByKey: {
            keyA: {}
            , keyB: {}
            , keyC: {}
        }
    }
    keyPath = "childrenPropsByKey"
    we expect childrenPropsByKey.* to be merged
*/

function twoDepthMergedPropsFromArr_forKey(arrOfProps, key) {
  const selectedPropsArr = arrOfProps.reduce((collector, prop) => {
    const selectedProp = _.get(prop, key);
    if (selectedProp) {
      collector.push(selectedProp);
    }
    return collector;
  }, [{}]);
  if (selectedPropsArr.length == 1) {
    return undefined;
  }
  const mergedProps = Object.assign.apply(Object, selectedPropsArr);
  const allChildrenKeys = Object.keys(mergedProps);
  const twoDepthMergedProps = {};
  allChildrenKeys.forEach(childKey => {
    const mergedChild = _shallowMergedPropsFromArr_forKey(selectedPropsArr, childKey);
    if (mergedChild) {
      twoDepthMergedProps[childKey] = mergedChild;
    }
  });
  return twoDepthMergedProps;
}
function _shallowMergedPropsFromArr_forKey(arrOfProps, key) {
  const selectedPropsArr = arrOfProps.reduce((collector, prop) => {
    const selectedProp = _.get(prop, key);
    if (selectedProp) {
      collector.push(selectedProp);
    }
    return collector;
  }, [{}]);
  if (selectedPropsArr.length == 1) {
    return undefined;
  }
  const mergedProps = Object.assign.apply(Object, selectedPropsArr);
  return mergedProps;
}
module.exports = {
  twoDepthMergedPropsFromArr_forKey
};