"use strict";

var _constants = require("../common/constants");
const queryString = require('query-string');
const query = location.search ? queryString.parse(location.search) : {};
const {
  superagent: superagentQuery
} = query;
//# for now only support superagent: false/0 to disable cache
//# in the future, we want to support `?superagent={...options}`
if (superagentQuery) {
  const eff_query = {};
  if (superagentQuery == 'false' || superagentQuery == '0') {
    eff_query.disable_cache = true;
  }
  if (eff_query.disable_cache) {
    const superagent = require('superagent');
    console.log(`Disabling superagent cache plugin`);
    module.exports = superagent;
  } else {
    module.exports = require('../common/index.js')({
      retryOptions: _constants.DEFAULT_RETRY_OPTIONS
    });
  }
} else {
  module.exports = require('../common/index.js')({
    retryOptions: _constants.DEFAULT_RETRY_OPTIONS
  });
}