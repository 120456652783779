"use strict";

const RubyComponent = require('@rubyapps/ruby-component');
const currentUserRubyComponent__CONSTANTS = require('@rubyapps/ruby-component-current-user/src/common/constants');
const currentUserMixin = RubyComponent.createMixin({
  mixinName: 'rubyComponentMixinCurrentUser',
  getDefaultProps: () => ({
    currentUserID: currentUserRubyComponent__CONSTANTS.COMPONENT_NAME
  }),
  dependencies: function () {
    const currentUser = this.getRoot().findDescendentByID(this.props.currentUserID);
    return {
      currentUser,
      currentUserSelector: currentUser.getSelfStateSelector()
    };
  },
  getCurrentUserState: function () {
    // Get the full application state
    const store = this.getStore();
    if (store == undefined) {
      return undefined;
    }
    const applicationState = store.getState();

    // Get the function that can pull out the current user state
    // from the application state
    const {
      currentUserSelector
    } = this.getDependencies();

    // Return the current user state
    return currentUserSelector(applicationState);
  },
  getCurrentUser: function () {
    return this.getDependencies().currentUser;
  },
  augmentedTemplate: require('./augmentedTemplate')
});
module.exports = currentUserMixin;