"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rubyBlue_light = exports.rubyBlue = exports.headerGrey = exports.errorColor = exports.categoryColors = exports.borderGrey = exports.black_50 = exports.black_40 = exports.black_25 = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
var Colors = _interopRequireWildcard(require("material-ui/styles/colors"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const headerGrey = 'rgb(232, 232, 232)';
exports.headerGrey = headerGrey;
const black_25 = 'rgba(0,0,0,0.25)';
exports.black_25 = black_25;
const black_40 = 'rgba(0,0,0,0.40)';
exports.black_40 = black_40;
const black_50 = 'rgba(0,0,0,0.50)';
exports.black_50 = black_50;
const borderGrey = 'rgba(0,0,0,0.2)';
exports.borderGrey = borderGrey;
const rubyBlue = '#607D8B'; //'#005b95'; //# Primary Color
exports.rubyBlue = rubyBlue;
const rubyBlue_light = '#CFD8DC'; //ColorManipulator.lighten(rubyBlue, emphasisCoefficient); //# light Primary Color
exports.rubyBlue_light = rubyBlue_light;
const categoryColors = _lodash.default.reverse(['#f7fbff', '#deebf7', '#c6dbef', '#9ecae1', '#6baed6', '#4292c6', '#2171b5', '#08519c', '#08306b']);
exports.categoryColors = categoryColors;
const errorColor = Colors.red500;
exports.errorColor = errorColor;