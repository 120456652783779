// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[4].use[2]!../../../../ruby-styles/src/client/cssModules/field-base.cssModule";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImagePicker__wrapper___tD0bp {\n    margin-top: 26px;\n}\n.ImagePicker__label___oOkqA {\n}\n\n", "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-image-picker/src/client/reactComponents/ImagePicker.cssModule"],"names":[],"mappings":"AAAA;IAEI,gBAAgB;AACpB;AACA;AAEA","sourcesContent":[".wrapper {\n    composes: wrapper from '@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule';\n    margin-top: 26px;\n}\n.label {\n    composes: label from '@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule';\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ImagePicker__wrapper___tD0bp " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["wrapper"] + "",
	"label": "ImagePicker__label___oOkqA " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["label"] + ""
};
export default ___CSS_LOADER_EXPORT___;
