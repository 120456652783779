"use strict";

const CONSTANTS = require('./constants');
const {
  PasswordMeter
} = require('./password-meter');

//# a different kind of module to check for password strength
//https://github.com/dropbox/zxcvbn

function DEPRECATED_analyze(passwordValue) {
  let valueToAnalyze = passwordValue || '';
  let hasLowercase = /[a-z]/;
  let hasUppercase = /[A-Z]/;
  let hasNumber = /\d/;
  let hasNonAlphanumeric = /[^a-zA-Z0-9]/;
  let shortFlag = valueToAnalyze.length < CONSTANTS.MIN_PASSWORD_LENGTH,
    longFlag = valueToAnalyze.length > CONSTANTS.MAX_PASSWORD_LENGTH,
    lowercaseFlag = hasLowercase.test(valueToAnalyze),
    uppercaseFlag = hasUppercase.test(valueToAnalyze),
    numericFlag = hasNumber.test(valueToAnalyze),
    nonAlphnumericFlag = hasNonAlphanumeric.test(valueToAnalyze);
  let score = [lowercaseFlag, uppercaseFlag, numericFlag, nonAlphnumericFlag].reduce((sum, flag) => flag ? sum + 1 : sum, 0);
  let weakFlag = score < CONSTANTS.MIN_CUSTOM_SCORE;
  return {
    shortFlag,
    longFlag,
    weakFlag,
    score,
    lowercaseFlag,
    uppercaseFlag,
    numericFlag,
    nonAlphnumericFlag
  };
}

/*
• [x] Minimum length of 8 characters
• [x] Allow case-sensitive characters, number, or special characters
• [x] Disallow any part of the username
• [x] Disallow any form of the word "password"
• [x] Disallow any character more than 3 time in succession
*/
//# returns one of
/*
[{
    "score" : 118,
    "status" : "medium",
    "percent" : 59
}, {
    "score" : -1,
    "status" : "needs requirement(s)",
    "errors" : ["The minimum password length is 5.", "The password must start with 1.", "The password must end with $.", "You must use at least 1 lowercase letter(s).", "You must use at least 1 number(s).", "You must use at least 1 symbol(s).", "The Password must include all the items specified.", "The Password must include at least one item specified [$]."],
    "percent" : 0
}, {
    "score" : -1,
    "status" : "needs requirement(s)",
    "errors" : ["The Password must include all the items specified."],
    "percent" : 0
}
] 
*/

function analyze(passwordValue) {
  let overrideOptions = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  //# we want to exclude username so we at least expect overrideOptions.exclude
  const {
    exclude = []
  } = overrideOptions;
  const analysisResults = new PasswordMeter({
    minLength: CONSTANTS.MIN_PASSWORD_LENGTH,
    maxLength: CONSTANTS.MAX_PASSWORD_LENGTH
    //uppercaseLettersMinLength: 1,
    //lowercaseLettersMinLength: 2,
    //numbersMinLength: 1,
    //symbolsMinLength: 1,
    ,
    characterTypeMinLength: 3 //# out of uppercaseLetters, numbers, symbols
    ,
    allowedConsecutiveRepeatedLetters: 3
    //include: ['a', '$'],
    //exclude: ['1baA$', '0xaZ$'],
    //startsWith: '1',
    //endsWith: '$',
    //includeOne: ['$']
    //blackList
    //uniqueLettersMinLength
    ,
    exclude: {
      value: ['password'].concat(exclude),
      message: "Password cannot contain variants of 'password' or your username"
    }
  }, {
    "40": "very weak",
    // 001 <= x <  040
    "80": "weak",
    // 040 <= x <  080
    "120": "medium",
    // 080 <= x <  120
    "180": "strong",
    // 120 <= x <  180`
    "200": "very strong",
    // 180 <= x <  200
    "_": "excellent" //        x >= 200
  }).getResults([passwordValue], true);
  return analysisResults[0];
}
module.exports = {
  analyze,
  DEPRECATED_analyze
};