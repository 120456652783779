"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const styles = {
  textarea: {
    cols: 80,
    minRows: 6,
    maxRows: 24
  }
};
var _default = styles;
exports.default = _default;