// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[4].use[2]!../../../../ruby-styles/src/client/cssModules/field-base.cssModule";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles__wrapper___ZMKyp {\n\n    margin-top: 0px;\n}\n\n.styles__label___isB4_ {\n}\n", "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-expanded-data/src/client/reactComponents/styles.cssModule"],"names":[],"mappings":"AAAA;;IAGI,eAAe;AACnB;;AAEA;AAEA","sourcesContent":[".wrapper {\n    composes: wrapper from '@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule';\n\n    margin-top: 0px;\n}\n\n.label {\n    composes: label from '@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "styles__wrapper___ZMKyp " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["wrapper"] + "",
	"label": "styles__label___isB4_ " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["label"] + ""
};
export default ___CSS_LOADER_EXPORT___;
