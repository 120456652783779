"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _lodash = _interopRequireDefault(require("lodash"));
var _Dropdown = _interopRequireDefault(require("./Dropdown"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const UNKNOWN = 10;
const NUMBER = 11;
const STRING = 12;
function optionValuesDataType(options) {
  const firstNonNullOptionValue = _lodash.default.get(options.filter(option => option.value !== null), '0.value');
  if (!_lodash.default.isNil(firstNonNullOptionValue)) {
    if (_lodash.default.isString(firstNonNullOptionValue)) {
      return STRING;
    } else if (_lodash.default.isNumber(firstNonNullOptionValue)) {
      return NUMBER;
    }
  } else {
    return UNKNOWN;
  }
}
function generator() {
  const selfModule = this;
  const {
    selfSelector,
    currentUserSelector
  } = selfModule.getDependencies();
  const defaultNamespace = selfModule.getDefaultNamespace();
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);
    const options = _lodash.default.get(selfState, 'props.options') || ownProps.options || [];
    const availableOptionsAsValues = options.map(option => option.value);
    const {
      fieldKey
    } = mixinFieldProps;
    let {
      error,
      value
    } = fieldKey ? _lodash.default.pick(selfState.fields[fieldKey], ['value', 'error']) : {};
    const activeNamespace = value;
    const errorsByNamespace_fromLocalState = _lodash.default.reduce(selfState.savedLocalStateByNamespace, (collector, savedLocalState, namespace) => {
      if (namespace == activeNamespace) {
        return collector;
      }
      const error = _lodash.default.get(savedLocalState, ['fields', fieldKey, 'error']);
      if (error && error.message) {
        collector[namespace] = error;
      }
      return collector;
    }, error && error.message ? {
      [activeNamespace]: error
    } : {});
    //# 20170518 - NOTE: deferring updating the check here for errors outside of error.message because we shouldn't have other error.<key> values 

    const errorsByNamespace_fromDeferred = _lodash.default.reduce(_lodash.default.get(selfState, ['deferredFormErrorByNamespace']), (collector, deferredFormError, namespace) => {
      collector[namespace] = {
        message: {
          msg: "Please review. Some fields are invalid.",
          level: "error"
        }
      };
      return collector;
    }, {});
    const clearErrorsByNamespace_fromDeferred = _lodash.default.get(selfState, ['deferredClearFormErrorByNamespace'], {});
    const errorsByNamespace = _extends({}, errorsByNamespace_fromDeferred, _lodash.default.omit(errorsByNamespace_fromLocalState, Object.keys(clearErrorsByNamespace_fromDeferred)));
    //# 20170629 - NOTE: if we have a standing order to clear errors that's deferred, we should exclude the localStateErrors from that namespace

    if (!error || !error.message) {
      error = errorsByNamespace[Object.keys(errorsByNamespace)[0]];
    }
    const valueNormalizedAsArray = Array.isArray(value) ? value : [value];
    const isArray = _lodash.default.isArray(_lodash.default.get(ownProps, 'data_type'));

    //# coercing the input values
    const optionValueDataType = optionValuesDataType(options);
    let coercedValues;
    if (optionValueDataType == STRING) {
      coercedValues = valueNormalizedAsArray.map(value => {
        return value == null ? value : value + '';
      });
    } else if (optionValueDataType == NUMBER) {
      coercedValues = valueNormalizedAsArray.map(value => {
        return value == null ? value : parseInt(value);
      });
    } else {
      coercedValues = valueNormalizedAsArray;
    }

    //# some values cannot be found in the set of options, we we filter them out;
    const filteredValues = coercedValues.filter(value => availableOptionsAsValues.indexOf(value) != -1);
    return _extends({}, ownProps, {
      isArray
    }, mixinFieldProps, _extends({}, selfState.props), {
      error,
      errorsByNamespace,
      value: filteredValues,
      defaultNamespace,
      options: mixinFieldProps.required ? options : [{
        value: null,
        text: "\u00a0"
      }].concat(options)
    });
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
    };
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_Dropdown.default);
}