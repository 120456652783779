"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getTimeZones = getTimeZones;
var _lodash = _interopRequireDefault(require("lodash"));
var _momentTimezone = _interopRequireDefault(require("moment-timezone"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const timeZoneList = _momentTimezone.default.tz.names();
const deprecatedOptions = ['US/Alaska', 'US/Aleutian', 'US/Arizona', 'US/Central', 'US/East-Indiana', 'US/Eastern', 'US/Hawaii', 'US/Indiana-Starke', 'US/Michigan', 'US/Mountain', 'US/Pacific', 'US/Samoa', 'US/Pacific-New', 'CET', 'CST6CDT', 'EST', 'EST5EDT', 'MST', 'MST7MDT', 'PST8PDT'];
const timeZoneAliases = {
  'Alaska': 'America/Anchorage',
  'Aleutian': 'America/Adak',
  'Arizona': 'America/Phoenix',
  'Central Time Zone (CT)': 'America/Chicago',
  'East Indiana': 'America/Indiana/Indianapolis',
  'Eastern Time Zone (ET)': 'America/New_York',
  'Hawaii': 'Pacific/Honolulu',
  'Indiana Starke': 'America/Indiana/Knox',
  'Michigan': 'America/Detroit',
  'Mountain Time Zone (MT)': 'America/Denver',
  'Pacific Time Zone (PT)': 'America/Los_Angeles'
};
function getTimeZones() {
  const allPrettyNames = timeZoneList.map(tzString => getPrettyNameFromTZ(tzString));
  const currentDate = (0, _momentTimezone.default)();
  const customTimeZoneList = timeZoneList.map(timeZone => {
    const standardTimeOffset = _momentTimezone.default.tz(currentDate, timeZone).format("Z");
    const prettyName = getPrettyNameFromTZ(timeZone);
    const label = allPrettyNames.filter(name => name === prettyName).length > 1 ? getUnambiguousPrettyNameFromTZ(timeZone) : prettyName;
    return {
      offset: _momentTimezone.default.tz.zone(timeZone).utcOffset(currentDate),
      primaryText: `(GMT${standardTimeOffset}) ${label}`,
      value: timeZone
    };
  });
  const augmentedTimeZones = customTimeZoneList.concat(_lodash.default.map(timeZoneAliases, (timeZone, alias) => {
    const offset = _momentTimezone.default.tz(currentDate, timeZone).format("Z");
    return {
      offset: _momentTimezone.default.tz.zone(timeZone).utcOffset(currentDate),
      primaryText: `(GMT${offset}) ${alias}`,
      value: timeZone
    };
  }));
  const filteredOptions = augmentedTimeZones.filter(option => {
    return !_lodash.default.includes(deprecatedOptions, option.value);
  });
  return filteredOptions;
}
function getPrettyNameFromTZ(tzString) {
  const splitTimeZone = tzString.split('/');
  return splitTimeZone.slice(1).join(' - ').replace(/_/g, ' ');
}
function getUnambiguousPrettyNameFromTZ(tzString) {
  const prettyName = getPrettyNameFromTZ(tzString);
  const groupName = tzString.split('/')[0];
  return `${prettyName} (${groupName})`;
}