"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getStyles = void 0;
var _transitions = _interopRequireDefault(require("material-ui/styles/transitions"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const getStyles = (props, context, state) => {
  const {
    baseTheme,
    textField: {
      floatingLabelColor,
      focusColor,
      textColor,
      disabledTextColor,
      backgroundColor,
      hintColor,
      errorColor
    }
  } = context.muiTheme;
  const styles = {
    root: {
      fontSize: 16,
      lineHeight: '24px',
      width: props.fullWidth ? '100%' : 256,
      height: (props.rows - 1) * 24 + (props.floatingLabelText ? 72 : 48),
      display: 'inline-block',
      position: 'relative',
      fontFamily: baseTheme.fontFamily,
      transition: _transitions.default.easeOut('200ms', 'height')
    },
    error: {
      position: 'relative',
      bottom: -6,
      fontSize: 12,
      lineHeight: '12px',
      color: errorColor,
      transition: _transitions.default.easeOut()
    }
  };
  _extends(styles.error, props.errorStyle);
  return styles;
};
exports.getStyles = getStyles;