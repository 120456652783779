"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const update = require('react-addons-update');
const defaultState = {
  props: {},
  savedLocalStateByNamespace: {},
  savedFormValueByNamespace: {},
  deferredFormValueByNamespace: {},
  deferredFormErrorByNamespace: {},
  deferredClearFormErrorByNamespace: {}
};
module.exports = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  const {
    TYPES
  } = this.getAction();
  const {
    type,
    payload
  } = action;
  if (!state.hasOwnProperty('savedLocalStateByNamespace')) {
    state = _extends({}, defaultState, state);
  }
  switch (type) {
    case TYPES.SET_OPTIONS:
      if (!payload.options || payload.options.length == 0) {
        return state;
      }
      const updatedState = _extends({}, state, {
        props: _extends({}, state.props, {
          options: payload.options
        })
      });
      return updatedState;
    case TYPES.CUSTOM_RESET_STATE:
      return _extends({}, state, this.getInitialState(), defaultState);
    case TYPES.SET_LOCAL_STATE_CACHE_FOR_NAMESPACE:
      return _extends({}, state, {
        savedLocalStateByNamespace: _extends({}, state.savedLocalStateByNamespace, {
          [payload.namespace]: payload.localState
        })
      });
    case TYPES.SAVE_LOCAL_STATE:
      return _extends({}, state, {
        savedLocalStateByNamespace: _extends({}, state.savedLocalStateByNamespace, {
          [payload.namespace]: payload.localState
        })
      });
    case TYPES.SAVE_FORM_VALUE:
      return _extends({}, state, {
        savedFormValueByNamespace: _extends({}, state.savedFormValueByNamespace, {
          [payload.namespace]: payload.formValue
        })
      });
    case TYPES.SET_LOCAL_STATE:
      return _extends({}, state, payload.localState);
    case TYPES.REPLACE_SAVED_LOCAL_STATE_BY_NAMESPACE:
      return _extends({}, state, {
        savedLocalStateByNamespace: payload.savedLocalStateByNamespace
      });
    case TYPES.REPLACE_DEFERRED_FORM_VALUE_BY_NAMESPACE:
      return _extends({}, state, {
        deferredFormValueByNamespace: payload.deferredFormValueByNamespace
      });
    case TYPES.REPLACE_DEFERRED_FORM_ERROR_BY_NAMESPACE:
      return _extends({}, state, {
        deferredFormErrorByNamespace: payload.deferredFormErrorByNamespace
      });
    case TYPES.REPLACE_DEFERRED_CLEAR_FORM_ERROR_BY_NAMESPACE:
      return _extends({}, state, {
        deferredClearFormErrorByNamespace: payload.deferredClearFormErrorByNamespace
      });
    case TYPES.CLEAR_DEFERRED_FORM_VALUE_FOR_NAMESPACE:
      return _extends({}, state, {
        deferredFormValueByNamespace: _lodash.default.omit(state.deferredFormValueByNamespace, [payload.namespace])
      });
    case TYPES.CLEAR_DEFERRED_FORM_ERROR_FOR_NAMESPACE:
      return _extends({}, state, {
        deferredFormErrorByNamespace: _lodash.default.omit(state.deferredFormErrorByNamespace, [payload.namespace])
      });
    case TYPES.CLEAR_DEFERRED_CLEAR_FORM_ERROR_FOR_NAMESPACE:
      return _extends({}, state, {
        deferredClearFormErrorByNamespace: _lodash.default.omit(state.deferredClearFormErrorByNamespace, [payload.namespace])
      });
    default:
      return state;
  }
};