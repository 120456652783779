"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var Colors = _interopRequireWildcard(require("@rubyapps/ruby-styles/src/client/styleObjects/colors"));
var Spacing = _interopRequireWildcard(require("@rubyapps/ruby-styles/src/client/styleObjects/spacing"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const AutoComplete__MenuStyles = {
  menuProps: {
    autoWidth: true,
    maxHeight: '500px' //# NOTE: 'auto' doesn't work. If we want to get it working, we'll need to update the <Menu> component
  },

  menuStyle: {
    overflowX: 'hidden'
  },
  listStyle: {
    width: '100%',
    minWidth: '300px'
  }
};
var _default = {
  AutoComplete__fullWidth: _extends({
    fullWidth: true
  }, AutoComplete__MenuStyles),
  AutoComplete__rightMargin: _extends({
    fullWidth: false,
    style: {
      marginRight: Spacing.listerSpacing,
      flexShrink: 0
    }
  }, AutoComplete__MenuStyles),
  Chip: {
    style: {
      marginRight: 10,
      marginBottom: 14
    },
    labelStyle: {
      color: '#ffffff'
    }
  },
  Dropdown__rightMargin: {
    autoWidth: true,
    style: {
      marginRight: Spacing.listerSpacing,
      width: 'auto',
      flexShrink: 0
    }
  },
  EqualOperator: {
    labelStyle: {
      color: Colors.rubyBlue,
      fontSize: '25px'
    }
  },
  FilterIcon: {
    style: {
      width: 22
    }
  },
  LogicalOperator: {
    labelStyle: {
      fontSize: '12px'
    }
  },
  Generic__rightMargin: {
    style: {
      marginRight: Spacing.listerSpacing
    }
  },
  OperatorChip: {
    style: {
      backgroundColor: 'none',
      marginRight: 7,
      marginBottom: 14
    }
  },
  RaisedButton: {
    style: {
      flexShrink: 0,
      marginLeft: 0,
      marginRight: 0
    }
  },
  RaisedButton__leftMargin: {
    style: {
      flexShrink: 0,
      marginLeft: Spacing.listerSpacing,
      marginRight: 0
    }
  },
  Selectize__rightMargin: {
    style: {
      marginTop: 0,
      marginRight: Spacing.listerSpacing
    }
  },
  ToolbarGroup__nested: {
    style: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      marginLeft: -1 * Spacing.listerSpacing,
      marginRight: -1 * Spacing.listerSpacing
      //, backgroundColor: 'rgb(212, 212, 212)'
      ,
      boxShadow: '0 0px 10px 1px rgb(199, 199, 199) inset',
      overflow: 'hidden'
    }
  },
  ToolbarGroup__filterTags: {
    style: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start'
    }
  },
  ToolbarGroupDiv: {
    style: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: Spacing.listerSpacing,
      width: '100%',
      padding: `12px ${Spacing.listerSpacing}px`
    }
  },
  ToolbarGroupDiv__selectColumns: {
    style: {
      paddingLeft: Spacing.listerSpacing,
      width: 'calc(100% - 74px)',
      padding: `12px 0 0px ${Spacing.listerSpacing}px`
    }
  },
  ToolbarGroupDiv__selectedColumnTags: {
    style: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-start'
    }
  },
  TextField: {
    style: {
      height: 48,
      width: '100%',
      minWidth: 256,
      marginRight: Spacing.listerSpacing
    },
    underlineStyle: {
      borderColor: Colors.black_25
    },
    inputStyle: {
      marginTop: 0,
      marginBottom: 0
    },
    floatingLabelStyle: {
      top: 13
    },
    floatingLabelFocusStyle: {
      transform: 'scale(1) translate(-22px, 0px)'
    }
  },
  UtilityBar: {
    style: {}
  }
};
exports.default = _default;