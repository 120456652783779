"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getLookupMenuItemForSelfModule;
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const RubyWords = require('@rubyapps/ruby-words');
const inflection = RubyWords.inflection;
const lookupTemplateType = 'lookup_template';
const templateKeysToOmit = ['page_redirect'];
function getLookupMenuItemForSelfModule(selfModule) {
  const forms = selfModule.getForms();
  const lookupForms = _lodash.default.flowRight(templates => _lodash.default.sortBy(templates, template => template.name.toUpperCase()), templates => _lodash.default.filter(templates, template => {
    const templateSubsiteFk = _lodash.default.get(template, 'ruby_subsite_fk');
    const templateSubsites = templateSubsiteFk ? _lodash.default.castArray(templateSubsiteFk) : [];
    const baseNeedlePerm = {
      action: 'get',
      model: 'content',
      ruby_client: template.ruby_client_fk,
      subsite: true
      //# default to true if ruby_subsite_fk is null because that's implicitly a wildcard
      //# which means the active subsite will be valid
      ,
      template: template.id
    };
    return templateSubsites.length ? _lodash.default.reduce(templateSubsites, (collector, templateSubsite) => {
      if (collector) {
        return collector;
      }
      return selfModule.doesUserHavePermission(_extends({}, baseNeedlePerm, {
        subsite: templateSubsite
      }));
    }, false) : selfModule.doesUserHavePermission(baseNeedlePerm);
  }), templates => _lodash.default.filter(templates, template => !_lodash.default.includes(templateKeysToOmit, template.key)), templates => _lodash.default.filter(templates, {
    templateType: lookupTemplateType
  }), keyedForms => _lodash.default.values(keyedForms))(forms);
  const lookupForm = lookupForms[0];
  const lookupMenuItem = lookupForm && {
    name: 'Lookups',
    icon: 'material-ui/svgIcons/ActionLabel',
    route: `/app/lookups/en/${lookupForm.key}/list`
  };
  return lookupMenuItem;
}