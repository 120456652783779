"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _Tabs = _interopRequireDefault(require("./Tabs"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function generator() {
  const selfModule = this;
  const {
    selfSelector
  } = selfModule.getDependencies();
  function mapStateToProps(state) {
    const selfState = selfSelector(state);
    return selfState;
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
    };
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_Tabs.default);
}