"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _lodash = _interopRequireDefault(require("lodash"));
var _DropdownWrapper = _interopRequireDefault(require("./DropdownWrapper"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const DropdownWrapper__propTypeKeys = Object.keys(_DropdownWrapper.default.propTypes);
const UNKNOWN = 10;
const NUMBER = 11;
const STRING = 12;
function optionValuesDataType(options) {
  const firstNonNullOptionValue = _lodash.default.get(options.filter(option => option.value !== null), '0.value');
  if (!_lodash.default.isNil(firstNonNullOptionValue)) {
    if (_lodash.default.isString(firstNonNullOptionValue)) {
      return STRING;
    } else if (_lodash.default.isNumber(firstNonNullOptionValue)) {
      return NUMBER;
    }
  } else {
    return UNKNOWN;
  }
}
function generator() {
  const selfModule = this;
  const {
    selfSelector
  } = selfModule.getDependencies();

  //# figure out the toHTML ahead of time
  const hydratedToHTML = this.hydratedToHTMLFromProps(this.props);
  //# currently not used

  //# optionToHTML because toHTML is used by mode:info
  const hydratedOptionToHTML = this.hydratedToHTMLFromProps({
    toHTML: this.props.optionToHTML
  });
  const defaultHydratedOptionToHTML = this.hydratedToHTMLFromProps({
    toHTML: "${data.status === 'inactive' ? `<span class='unpublishedChipContainer'><span>` + data.text + `</span><span class='unpublishedChip'>unpublished</span></span>` : data.text}"
  });
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);
    const options = _lodash.default.get(selfState, 'props.options') || ownProps.options || [];
    const availableOptionsAsValues = options.map(option => option.value);
    let {
      value
    } = selfModule.props.key ? _lodash.default.pick(selfState.fields[selfModule.props.key], ['value']) : {};
    if (value == undefined && selfModule.props.defaultToFirstOption) {
      //# NOTE: hacky selecting defaultToFirstOption since we cannot preemptively set the value instate
      value = availableOptionsAsValues[0];
    }
    const valueNormalizedAsArray = Array.isArray(value) ? value : [value];
    const isArray = _lodash.default.isArray(_lodash.default.get(ownProps, 'data_type'));
    const formattedError = selfModule.formattedErrorFromState(state);

    //# coercing the input values
    const optionValueDataType = optionValuesDataType(options);
    let coercedValues;
    if (optionValueDataType == STRING) {
      coercedValues = valueNormalizedAsArray.map(value => {
        return value == null ? value : value + '';
      });
    } else if (optionValueDataType == NUMBER) {
      coercedValues = valueNormalizedAsArray.map(value => {
        return value == null ? value : parseInt(value);
      });
    } else {
      coercedValues = valueNormalizedAsArray;
    }

    //# some values cannot be found in the set of options, we we filter them out;
    const filteredValues = coercedValues.filter(value => availableOptionsAsValues.indexOf(value) != -1);
    const mergedProps = _extends({}, ownProps, {
      isArray
    }, mixinFieldProps, _lodash.default.pick(selfState, DropdownWrapper__propTypeKeys), _extends({}, selfState.props), {
      value: filteredValues
    }, formattedError, hydratedToHTML ? {
      toHTML: hydratedToHTML
    } : {}, hydratedOptionToHTML ? {
      optionToHTML: hydratedOptionToHTML
    } : {
      optionToHTML: defaultHydratedOptionToHTML
    });
    const isMultiSelect = mergedProps.limit == null || Number.isInteger(mergedProps.limit) && mergedProps.limit > 1;
    if (mergedProps.required || mergedProps.mode === 'info' || mergedProps.mode === 'control' || isMultiSelect) {
      mergedProps.options = options;
    } else {
      mergedProps.options = [{
        value: null,
        text: "\u00a0"
      }].concat(options);
    }
    return mergedProps;
  }
  function mapDispatchToProps(dispatch) {
    return {
      actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
    };
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_DropdownWrapper.default);
}