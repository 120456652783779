"use strict";

var _reduxBatchedActions = require("redux-batched-actions");
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const _ = require('lodash');
const {
  FE_SETTINGS_KEY,
  STICKY_FE_SETTINGS_KEY
} = require('../common/constants');
const generators = {
  autosaveSearch_fromGriddleState: function (griddleState) {
    const selfModule = this;
    const {
      feSettingsComponent
    } = selfModule.getDependencies();
    const currSavedSearch = feSettingsComponent.getState()[FE_SETTINGS_KEY] || {};
    const stateToSave = _.pick(griddleState, selfModule.props.autosaveSearch_griddleStateKeys);
    return (0, _reduxBatchedActions.batchActions)([feSettingsComponent.getAction().generators.setFrontendSetting_forKey_toValue(FE_SETTINGS_KEY, _extends({}, currSavedSearch, {
      [selfModule.getAutosaveSearch_context()]: stateToSave
    })), selfModule.getAction().generators.setStickySearch_fromGriddleState(griddleState)]);
  },
  clearOtherAutosavedSearches: function () {
    const selfModule = this;
    const {
      feSettingsComponent
    } = selfModule.getDependencies();
    const currSavedSearch = feSettingsComponent.getState()[FE_SETTINGS_KEY] || {};
    return feSettingsComponent.getAction().generators.setFrontendSetting_forKey_toValue(FE_SETTINGS_KEY, _.pick(currSavedSearch, [selfModule.getAutosaveSearch_context()]));
  },
  setStickySearch_fromGriddleState: function (griddleState) {
    const selfModule = this;
    const {
      feSettingsComponent
    } = selfModule.getDependencies();
    const currStickySearch = selfModule.getStickySearchState();
    const stateToSave = _.pick(griddleState, selfModule.getStickySearchKeys());
    const newFeSettings__stickySettings = _.merge({}, currStickySearch, stateToSave);
    return feSettingsComponent.getAction().generators.setFrontendSetting_forKey_toValue(STICKY_FE_SETTINGS_KEY, newFeSettings__stickySettings);
  }
};
module.exports = function () {
  return {
    generators
  };
};