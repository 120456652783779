"use strict";

const _ = require('lodash');
const queryString = require('query-string');
const CONSTANTS = require('../../common/constants');
const {
  COMPONENT_NAME
} = CONSTANTS;
const mceCommand = `mce${COMPONENT_NAME}`;
const TINYMCE_DATA_PREFIX = 'data-mce-p-';
const CLASS_NAME = 'rubycontent-page-link';
const CLASS_PREFIX = 'rubycontent-page-';
const REFERENCE_MODEL_KEY = 'content';

//# module will self register

// Load plugin specific language pack
tinymce.PluginManager.requireLangPack(COMPONENT_NAME);

//# TOOD: figure out how to load the languages

const $ = require('jquery');
tinymce.create(`tinymce.plugins.${COMPONENT_NAME}`, {
  _editor: null,
  _rubyApp: null //{childrenByKey: {}}
  ,
  _selfModule: null,
  _selectedNode: null,
  _selectedNodeSpec: null // the mailto: data parsed, to be used as formData for dialog
  ,
  _augment_text: null //# TODO: what is augment text used for?

  /* ************************************************************
     ** BEGIN: FRAMEWORK METHODS
     ********************************************************** */

  /**
    * Initializes the plugin, this will be executed after the plugin has
    * been created.  This call is done before the editor instance has
    * finished it's initialization so use the onInit event of the editor
    * instance to intercept that event.
    * @param {tinymce.Editor} ed Editor instance that the plugin is
    *                                   initialized in.
    * @param {string} url Absolute URL to where the plugin is located.
    */,
  init: function (ed, url) {
    var self = this;
    this._editor = ed;
    this._rubyApp = ed.settings.rubyApp;
    this._selfModule = this._rubyApp.childrenByKey[COMPONENT_NAME];

    // Register the command so that it can be invoked by using
    // tinyMCE.activeEditor.execCommand('mceRubyAppMediaManager');
    ed.addCommand(mceCommand, function () {
      self._handlePickerButtonClick();
    });

    // Register the image gallery button
    ed.ui.registry.addButton(COMPONENT_NAME, {
      //title: 'Insert Link/Choose Page',
      tooltip: 'Insert Link/Choose Page',
      icon: 'link',
      //# use icon if we're using a tinymce theme class
      onAction: () => {
        return ed.execCommand(mceCommand);
      },
      onSetup: api => {
        const controlManager = api;
        const editorNodeChange = e => {
          return self._handleNodeChange(ed, controlManager, e.element);
        };
        ed.on('NodeChange', editorNodeChange);
        return api => {
          ed.off('NodeChange', editorNodeChange);
        };
      }
    });
    ed.ui.registry.addButton('rubyunlink', {
      tooltip: "Remove link",
      icon: "unlink",
      onAction: () => {
        return ed.execCommand('unlink');
      },
      onSetup: api => {
        const controlManager = api;
        const editorNodeChange = function (e) {
          let href = '';
          let node = e.element;
          const isHighlighted = ed.selection.getContent().length == 0;
          // tinyMCE unlink replaces anchor tag with span tag to keep class information
          // if the current tag is a span tag find the parent anchor tag if possible
          let parentAnchor = ed.dom.getParent(node, 'A');
          if (node.nodeName != 'A' && node.className.indexOf('rubycontent-page-link') > -1 && parentAnchor) {
            node = parentAnchor;
          }

          // nodeType should equal Node.ELEMENT_NODE 
          // Node global variable is not supported by all browsers.
          if (node.nodeType == 1) {
            href = node.getAttribute('href');
          }
          const hasLink = href || node.className.indexOf('rubycontent-page-link') > -1;
          var isMailTo = /^mailto:/.test(href);
          controlManager.setDisabled(isHighlighted && node.nodeName != 'A');

          //# don't set active because this is only used by toggleButton
          //controlManager.setActive( node.nodeName == 'A' && !isMailTo );
        };
        // Add a node change handler, selects the button in the UI when
        // a link is selected
        ed.on('NodeChange', editorNodeChange);
        return api => {
          ed.off('NodeChange', editorNodeChange);
        };
      }
    });

    //ed.settings.rubyComponent.actions to get the rubyComponent actions

    //# set options on the rubyComponent
    this._selfModule.getStore().dispatch(this._selfModule.getAction().generators.setOptions({
      onSelect: this._onSelect.bind(this)
    }));
  } // init

  /**
    * Creates control instances based in the incomming name. This method is 
    * normally not needed since the addButton method of the tinymce.Editor
    * class is a more easy way of adding buttons but you sometimes need to
    * create more complex controls like listboxes, split buttons etc then
    * this method can be used to create those.
    *
    * @param {String} n Name of the control to create.
    * @param {tinymce.ControlManager} cm Control manager to use inorder to
    *                                    create new control.
    * @return {tinymce.ui.Control} New control instance or null if no
    *                              control was created.
    */,
  createControl: function (n, cm) {
    return null;
  }

  /**
    * Returns information about the plugin as a name/value array.
    * The current keys are longname, author, authorurl, infourl and version.
    *
    * @return {Object} Name/value array containing info about the plugin.
    */,
  getInfo: function () {
    return {
      longname: 'RubyApp Content Page Picker plugin',
      author: 'Howard Yee (Rubenstein Technology Group)',
      authorurl: 'http://www.rubensteintech.com',
      version: "1.0"
    };
  } // getInfo

  /* ************************************************************
     ** BEGIN: INITIALIZATION METHODS
     ********************************************************** */

  /* ************************************************************
     ** BEGIN: EVENT HANDLER METHODS
     ********************************************************** */,

  _handleNodeChange: function (editor, controlManager, node) {
    // Reset some global data
    this._augment_text = '';
    this._selectedNode = node;
    this._selectedNodeSpec = null;

    // tinyMCE unlink replaces anchor tag with span tag to keep class information
    // if the current tag is a span tag find the parent anchor tag if possible
    const parentAnchor = editor.dom.getParent(node, 'A');
    if (node.nodeName != 'A' && node.className.indexOf('rubycontent-page-link') > -1 && parentAnchor) {
      node = parentAnchor;
    }
    const $node = $(node);

    //<a class="rubycontent-page-link rubycontent-page-3232" title="Florida Supreme Court Rejects the &quot;Risk Utility&quot; Test For Strict Liability Design Defect Cases" href="http://www-dev.rubylaw.dev/en/perspectives/florida-supreme-court-rejects-the-risk-utility-test-for-strict.html" data-rubyapp-reference="content::3232">asdsad</a>

    const href = $node.attr('href');
    const isHighlighted = editor.selection.getContent().length;
    if (isHighlighted) {
      controlManager.setDisabled(false);
    } else {
      controlManager.setDisabled(true);
    }
    if (!_.isNil(href) && isHighlighted) {
      const referenceString = $node.attr('data-rubyapp-reference') || $node.attr(TINYMCE_DATA_PREFIX + 'data-rubyapp-reference') || "";
      const referenceArray = referenceString.split("::");
      const nodeSpec = {
        model: referenceArray[0],
        title: $node.attr('title'),
        href,
        new_window: $node.attr('target') == "_blank",
        referenced_content_id: referenceArray[1],
        referenced_content: referenceArray[1] ? {
          name: $node.attr('title'),
          url: href
        } : {}
      };
      this._selectedNodeSpec = nodeSpec;

      //controlManager.setActive( true );

      return;
    }

    //controlManager.setActive( false );
  } // _handleNodeChange
  ,

  _handlePickerButtonClick: function () {
    var self = this;
    self._storeSelection();
    self._openDialog();
    return true;
  } // _handleRubyAppMediaManagerButtonClick
  ,

  _onSelect: function (data) {
    const editor = this._editor;
    const selectedNode = editor.selection.getNode();
    const {
      _selfModule: selfModule
    } = this;
    const {
      referenced_content = {}
    } = data;
    const href = referenced_content.url || data.href || '';
    const title = referenced_content.name || data.title;
    const referenced_content_id = data.referenced_content_id;

    //# TODO: why are we selecting the entire node? 
    //# NOTE: the old image picker did this. Note that this check only runs for links that do not have inside markup.
    // So, it will run for <a>test</a> and NOT for <a><span>Test</span></a>.
    // Select the entire link
    let isAlreadyAlinkWithNoInsideMarkup;
    if (editor.selection.getNode().nodeName == 'A') {
      editor.selection.select(editor.selection.getNode());
      isAlreadyAlinkWithNoInsideMarkup = true;
    }
    this._restoreSelection();
    let $embeddable;
    const selectedMarkup = editor.selection.getContent() || '';

    // If text has been selected, link to the Media.
    // Otherwise, embed the Media directly in the text.
    if (selectedMarkup) {
      // NOTE: the <div> tags are a hack. Without them, the text
      // of HTML strings such as "hello <em>world</em>" return an
      // empty string instead of the expected "hello world".
      // The <div> guarantees that there is always a valid wrapping
      // element to retrieve text from.
    } else {
      $embeddable = $(embeddableMarkup);
    }

    // isAlreadyAlinkWithNoInsideMarkup is falsy even when an image is already wrapped by a link.
    // Removing the parent prevents: https://gitlab.intranet.rubensteintech.com/project/ruby-app/-/merge_requests/1840#note_194989
    if (_.get(selectedNode, 'nodeName') === 'IMG') {
      const parentNode = _.get(selectedNode, 'parentElement');
      _.get(parentNode, 'nodeName') === 'A' ? $(parentNode).remove() : null;
    }

    // This check is necessary because calling `.html()` in a link with no inside markup(<a>test</a>),
    // for some reason keeps the old link around as an empty link.
    // Therefore, calling `.text()` prevents it.
    const embeddableText = isAlreadyAlinkWithNoInsideMarkup ? $(`<div>${selectedMarkup}</div>`).text() : $(`<div>${selectedMarkup}</div>`).html();
    $embeddable = $(`<a href="${href}">${embeddableText}</a>`);
    title && $embeddable.attr('title', title);
    data.new_window && $embeddable.attr('target', '_blank');
    if (referenced_content_id) {
      $embeddable.addClass(CLASS_NAME);
      $embeddable.addClass(`${CLASS_PREFIX}${referenced_content_id}`);
      $embeddable.attr('data-rubyapp-plugin', COMPONENT_NAME);
      $embeddable.attr('data-rubyapp-reference', `${REFERENCE_MODEL_KEY}::${referenced_content_id}`);
    }
    editor.execCommand('mceInsertContent', false, $('<div>').append($embeddable).html(), {
      skip_undo: 1
    });
    editor.undoManager.add();
    editor.execCommand('mceRepaint');
    this._closeDialog();
  }

  /* ************************************************************
     ** BEGIN: HELPER METHODS
     ********************************************************** */,

  _closeDialog: function () {
    const {
      _selfModule: selfModule
    } = this;
    const {
      generators: actions
    } = selfModule.getAction();
    selfModule.getStore().dispatch(actions.closeDialog());
    /*
    selfModule.getStore().dispatch(
        this._selectedNodeSpec?
            actions.openAndUpdate_withOptions(this._selectedNodeSpec)
            :actions.openWithOptions()
    );
    */

    return;
  },
  _openDialog: function () {
    const {
      _selfModule: selfModule
    } = this;
    const {
      generators: actions
    } = selfModule.getAction();
    selfModule.getStore().dispatch(actions.openDialog({
      defaultFormData: this._selectedNodeSpec,
      onSubmit: (formData, successCb, errorCb) => {
        this._onSelect(formData);
      }
    }));
    /*
    selfModule.getStore().dispatch(
        this._selectedNodeSpec?
            actions.openAndUpdate_withOptions(this._selectedNodeSpec)
            :actions.openWithOptions()
    );
    */

    return;
  } // _openDialog

  /* ************************************************************
     ** BEGIN: tinyMCE HELPER METHODS 
     **   (copied from tinyMCE's Popup.js)
     ********************************************************** */,

  _getLang: function (n, dv) {
    return tinymce.activeEditor.getLang(n, dv);
  },
  _getTinyMCEParam: function (n, dv) {
    return tinymce.activeEditor.getParam(n, dv);
  },
  _storeSelection: function () {
    tinymce.activeEditor.windowManager.bookmark = tinymce.activeEditor.selection.getBookmark(1);
  },
  _restoreSelection: function () {
    if (tinymce.isIE) tinymce.activeEditor.selection.moveToBookmark(tinymce.activeEditor.windowManager.bookmark);
  }
}); // tinymce.create

// Register plugin
tinymce.PluginManager.add(COMPONENT_NAME, tinymce.plugins[COMPONENT_NAME]);
module.exports = {
  pluginName: COMPONENT_NAME
};