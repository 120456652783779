"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initialState;
var _immutable = _interopRequireWildcard(require("immutable"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function initialState() {
  let idCol = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'id';
  let initialSelectedRecords = arguments.length > 1 ? arguments[1] : undefined;
  let initialSelectionOrder = arguments.length > 2 ? arguments[2] : undefined;
  return _immutable.default.fromJS({
    selectedRecords: initialSelectedRecords ? (0, _immutable.fromJS)(initialSelectedRecords) : _immutable.default.Map(),
    selectionOrder: initialSelectionOrder ? (0, _immutable.fromJS)(initialSelectionOrder) : _immutable.default.List(),
    idColumn: idCol
  });
}