"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GRIDDLE_ROW_UNSELECT_VISIBLE_ROWS = exports.GRIDDLE_ROW_SELECT_VISIBLE_ROWS = exports.GRIDDLE_ROW_SELECTION_TOGGLED = exports.GRIDDLE_CLEAR_ALL_ROW_SELECTIONS = void 0;
const GRIDDLE_ROW_SELECTION_TOGGLED = 'GRIDDLE_ROW_SELECTION_TOGGLED';
exports.GRIDDLE_ROW_SELECTION_TOGGLED = GRIDDLE_ROW_SELECTION_TOGGLED;
const GRIDDLE_ROW_SELECT_VISIBLE_ROWS = 'GRIDDLE_ROW_SELECT_VISIBLE_ROWS';
exports.GRIDDLE_ROW_SELECT_VISIBLE_ROWS = GRIDDLE_ROW_SELECT_VISIBLE_ROWS;
const GRIDDLE_ROW_UNSELECT_VISIBLE_ROWS = 'GRIDDLE_ROW_UNSELECT_VISIBLE_ROWS';
exports.GRIDDLE_ROW_UNSELECT_VISIBLE_ROWS = GRIDDLE_ROW_UNSELECT_VISIBLE_ROWS;
const GRIDDLE_CLEAR_ALL_ROW_SELECTIONS = 'GRIDDLE_CLEAR_ALL_ROW_SELECTIONS';
exports.GRIDDLE_CLEAR_ALL_ROW_SELECTIONS = GRIDDLE_CLEAR_ALL_ROW_SELECTIONS;