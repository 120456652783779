"use strict";

module.exports = {
  modelName: 'api_log_entry',
  columns: [{
    key: 'timestamp',
    displayName: 'Timestamp',
    type: 'date',
    componentParams: {
      format: 'MMM DD HH:mm:ss'
    },
    resizable: false,
    width: 130
  }, {
    key: 'description.user',
    displayName: 'Username',
    resizable: false,
    sortable: false,
    width: 130
  }, {
    key: 'request_id',
    displayName: 'Transaction ID',
    resizable: false,
    sortable: false,
    width: 120
  }, {
    key: 'level',
    displayName: 'Log Level',
    resizable: false,
    sortable: false,
    type: 'logLevel',
    width: 90
  }, {
    key: 'request',
    displayName: 'Request',
    resizable: true,
    sortable: false
  }, {
    key: 'message',
    displayName: 'Message',
    resizable: true,
    sortable: false
  }],
  defaultSortColumn: 'timestamp',
  defaultSortDirection: 'DESC',
  fields: ['description']
};