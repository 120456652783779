"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _ComponentizedDatePicker = _interopRequireDefault(require("./ComponentizedDatePicker"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function generator() {
  const selfModule = this;
  const {
    selfSelector
  } = selfModule.getDependencies();

  //# figure out the toHTML ahead of time
  const hydratedToHTML = this.hydratedToHTMLFromProps(this.props);
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);
    const formattedError = selfModule.formattedErrorFromState(state);
    const mappedProps = _extends({}, ownProps, mixinFieldProps, {
      id: selfModule.getID(),
      fieldKey: selfModule.props.key //key is not exposed to the react component
    }, selfModule.props.key ? _.pick(selfState.fields[selfModule.props.key], ['value']) : {}, formattedError, hydratedToHTML ? {
      toHTML: hydratedToHTML
    } : {});
    return mappedProps;
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
    };
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_ComponentizedDatePicker.default);
}