"use strict";

const baseClass = {
  mixins: []
  //# static method
  ,
  registerMixin: function (mixin) {
    const indexOfMixin = this.mixins.indexOf(mixin);
    if (indexOfMixin < 0) {
      this.mixins.push(mixin);
    }
  }
};
module.exports = baseClass;