"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const path = require('path');
const rubyLogger = require('@rubyapps/ruby-logger');
const packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
const logger = rubyLogger.getLogger(packageName);
const _ = require('lodash');
const rubyLodash = require('@rubyapps/ruby-lodash');
const baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
module.exports = {
  //# Mimic the naming scheme for content property helper
  //# NOTE: this might not support retrieving children components of complex fields for now
  //# this won't handle overloaded fields for now (like DynamicForm)
  //# TODO: support keypaths and wildcards
  //# TODO: support referenced templates (like profile widget)
  //# TODO: support DynamicForm
  //#     NOTE: to support the custom children, we just need to customize the childrenSelector
  //#     to return custom nodes, which may be exterior to the root tree node
  fieldSpecsForTemplate_atKeypath: function (template, keypath, callingModule) {
    const Components = require('../../../ruby-component-builder/src/common/components').default;
    template = _.isString(template) ? this.getAugmentedTemplateForKey(template, undefined, callingModule || this) : template;
    const keypathArr = keypath.split('.');
    const lastKeypathKey = _.last(keypathArr);
    const childrenSelector = rubyLodash.childrenSelectors.childrenSelectorMatchingKeypath_byKey_forChildrenKey(keypathArr);
    const selectedFieldSpecs = rubyLodash.depthFirstReduce(template.form, (collector, node, nodepathArr) => {
      const lastNodeKey = (_.findLast(nodepathArr, n => n.key) || {}).key;
      if (lastNodeKey == lastKeypathKey) {
        collector.push(node);
      }
      return collector;
    }, [], childrenSelector);
    const hydratedFieldSpecs = selectedFieldSpecs.map(fieldSpec => {
      const componentForChild = Components[fieldSpec.componentName];
      if (!componentForChild) {
        logger.warn(`No component found for [${fieldSpec.componentName}] with formJS valued:`, fieldSpec);
        return fieldSpec;
      }
      const getFieldSpecFromProps = componentForChild.getFieldSpecFromProps || baseFieldMixin.getFieldSpecFromProps;
      const defaultProps = componentForChild.getDefaultProps ? componentForChild.getDefaultProps(fieldSpec) : {};
      return _extends({}, defaultProps, fieldSpec);
    });
    return hydratedFieldSpecs;
  }
};