"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getContentReportsMenuItemsForSelfModule;
//# TODO: see if we can pass the icon component instead of the string
const pluginContentReportsComponentId = 'rubyComponentPluginContentReports';
function getContentReportsMenuItemsForSelfModule(selfModule) {
  const pluginContentReportsComponent = selfModule.getRoot().findDescendentByID(pluginContentReportsComponentId);
  return pluginContentReportsComponent ? {
    name: 'Content Reports',
    icon: 'material-ui/svgIcons/ActionDescription',
    permissions: [{
      keyword: 'report_structured_data_section',
      ruby_client: 3,
      action: '*'
    }]
    //, url: '/reports/content/structured_data.html'
    ,
    route: '/app/admin/reports/content/structured-data'
  } : null;
}