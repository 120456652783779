"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.hydrateDependenciesForRubyComponent = hydrateDependenciesForRubyComponent;
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/*
    dependencies: {
        [key]: idOfComponent
    }
*/

function hydrateDependenciesForRubyComponent(selfModule, dependencies) {
  const rootModule = selfModule.getRoot();
  return _lodash.default.reduce(dependencies, (collector, componentId, key) => {
    collector[key] = rootModule.findDescendentByID(componentId);
    return collector;
  }, {});
}