"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
var defaultState = {
  childrenOrderById: [],
  childrenIdToTypeMap: {},
  userAddedChildrenIdMap: {}
  //# used to track any user-explicitly added modules during the editing session
  ,
  rerenderTimestamp: null,
  error: null //# error: {message: <string>}just like the nested fields
  ,
  options: [],
  optionsByType: {}
};
function _addOptionsToState_reducer(selfmodule, state, action) {
  const {
    optionsByType
  } = state;
  const optionsToAdd = action.payload.options;
  const newOptionsByType = optionsToAdd.reduce((collector, option) => {
    collector[option.type] = option;
    return collector;
  }, _extends({}, optionsByType));
  return _extends({}, state, {
    options: _lodash.default.map(newOptionsByType, option => option),
    optionsByType: newOptionsByType
  });
}
function _appendItemOfType_reducer(selfmodule, state, action) {
  const {
    type,
    id: newChildId
  } = action.payload;
  //const options = this.normalizedOptions();

  //# Generate a uuid, add it to the array of items (in the fields
  const childrenOrderById = state.childrenOrderById.slice();
  childrenOrderById.push(newChildId);

  //# Generate a map of uuids to type map
  const childrenIdToTypeMap = _extends({}, state.childrenIdToTypeMap, {
    [newChildId]: type
  });
  const userAddedChildrenIdMap = _extends({}, state.userAddedChildrenIdMap, {
    [newChildId]: new Date()
  });
  return _extends({}, state, {
    childrenOrderById,
    childrenIdToTypeMap,
    userAddedChildrenIdMap
  });
  //# children child editing should just work
}

function _deleteItemWithId_reducer(selfmodule, state, action) {
  const childId = action.payload.id;

  //# removing the childId from the type map
  const childrenIdToTypeMap = _lodash.default.omit(state.childrenIdToTypeMap, [childId]);
  const childrenOrderById = state.childrenOrderById.filter(id => id != childId);
  return _extends({}, _lodash.default.omit(state, childId), {
    childrenOrderById,
    childrenIdToTypeMap
  });
  //# children child editing should just work
}

function moveItem_reducer(selfModule, state, action) {
  const {
    id: childId,
    betweenIDs
  } = action.payload;
  const childrenOrderById = state.childrenOrderById.filter(id => id != childId);
  let updatedChildrenOrderById;
  if (betweenIDs[0]) {
    //# if we have a left value
    const insertIndex = childrenOrderById.indexOf(betweenIDs[0]);
    updatedChildrenOrderById = childrenOrderById.slice();
    updatedChildrenOrderById.splice(insertIndex + 1, 0, childId);
  } else {
    //# insert to the left of the right value
    const insertIndex = childrenOrderById.indexOf(betweenIDs[1]);
    updatedChildrenOrderById = childrenOrderById.slice();
    updatedChildrenOrderById.splice(insertIndex, 0, childId);
  }
  return _extends({}, state, {
    childrenOrderById: updatedChildrenOrderById
  });
}
function updateItemOrder_reducer(selfModule, state, action) {
  const {
    itemOrder
  } = action.payload;
  return _extends({}, state, {
    childrenOrderById: itemOrder
  });
}
function _replaceState_reducer(selfModule, state, action) {
  return _extends({}, state, action.payload);
}

/* 20170517 - DEPRECATED - howard
function _setFieldError_reducer(selfModule, state, action) {
    return Object.assign({}, state
        , {
            error: action.payload.error
        }
    );
}
*/

module.exports = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  const {
    selfAction: {
      TYPES
    }
  } = this.getDependencies();
  if (!state.hasOwnProperty('childrenOrderById')) {
    const normalizedOptions = this.normalizedOptions_withOptions(this.props.options);
    state = _extends({}, state, defaultState, {
      options: normalizedOptions,
      optionsByType: this.optionsByType_fromOptions(normalizedOptions)
    });
  }
  switch (action.type) {
    case TYPES.APPEND_ITEM_OF_TYPE:
      return _appendItemOfType_reducer(this, state, action);
    case TYPES.DELETE_ITEM_WITH_ID:
      return _deleteItemWithId_reducer(this, state, action);
    case TYPES.MOVE_ITEM:
      return moveItem_reducer(this, state, action);
    case TYPES.UPDATE_ITEM_ORDER:
      return updateItemOrder_reducer(this, state, action);
    case TYPES.REPLACE_STATE:
      return _replaceState_reducer(this, state, action);
    case TYPES.ADD_OPTIONS:
      return _addOptionsToState_reducer(this, state, action);

    /* 20170517 - DEPRECATED - howard
    case TYPES.SET_FIELD_ERROR_MESSAGE_BY_KEY:
        return _setFieldError_reducer(this, state, action);
    */
    case TYPES.RESET_STORE:
      return this.getInitialState();
    default:
      return state;
  }
};