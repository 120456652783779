"use strict";

module.exports = {
  fieldSpec: {
    displayText: 'Multiselect Toggles',
    propertyKeys: ['key', 'endpoint_builder', 'options', 'limit', 'token_tagger_data_type', 'help_text', 'permissions', 'private_field', 'namespace', 'pluginOptionsByModuleName_hidden', 'style_hidden', 'count_hidden']
  },
  propertySpecs: {
    style_hidden: {
      label: 'Style',
      type: 'textarea',
      propKey: 'style',
      hidden: true
      //# an object
    },

    count_hidden: {
      label: 'Count',
      type: 'number',
      propKey: 'count',
      hidden: true
      //# description: "The number of options to retrieve"
    }
  }
};