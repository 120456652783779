"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GRIDDLE_STOP_LOADING = exports.GRIDDLE_START_LOADING = exports.GRIDDLE_REMOTE_REPLACE_DATA = exports.GRIDDLE_REMOTE_PREPEND_DATA = exports.GRIDDLE_REMOTE_ERROR = exports.GRIDDLE_REMOTE_APPEND_DATA = exports.GRIDDLE_LOADED_DATA = void 0;
const GRIDDLE_START_LOADING = 'GRIDDLE_START_LOADING';
exports.GRIDDLE_START_LOADING = GRIDDLE_START_LOADING;
const GRIDDLE_STOP_LOADING = 'GRIDDLE_STOP_LOADING';
exports.GRIDDLE_STOP_LOADING = GRIDDLE_STOP_LOADING;
const GRIDDLE_REMOTE_REPLACE_DATA = 'GRIDDLE_REMOTE_REPLACE_DATA';
exports.GRIDDLE_REMOTE_REPLACE_DATA = GRIDDLE_REMOTE_REPLACE_DATA;
const GRIDDLE_REMOTE_APPEND_DATA = 'GRIDDLE_REMOTE_APPEND_DATA';
exports.GRIDDLE_REMOTE_APPEND_DATA = GRIDDLE_REMOTE_APPEND_DATA;
const GRIDDLE_REMOTE_PREPEND_DATA = 'GRIDDLE_REMOTE_PREPEND_DATA';
exports.GRIDDLE_REMOTE_PREPEND_DATA = GRIDDLE_REMOTE_PREPEND_DATA;
const GRIDDLE_REMOTE_ERROR = 'GRIDDLE_REMOTE_ERROR';
exports.GRIDDLE_REMOTE_ERROR = GRIDDLE_REMOTE_ERROR;
const GRIDDLE_LOADED_DATA = 'GRIDDLE_LOADED_DATA';
exports.GRIDDLE_LOADED_DATA = GRIDDLE_LOADED_DATA;