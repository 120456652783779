"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const uuid = require('node-uuid');
const _ = require('lodash');

//# use a weakmap to map item to uuid

const guidHelperMixin = {
  mixinName: 'rubyComponentMixinFieldGuidHelper'

  /**
   * Taking the item and providing a hash for the iem
   * NOTE: in order to prevent attempting to 
   * @param {NumberLike} x - The magic number.
   **/,
  hashedItem: function (item) {
    const isItemPrimitive = _.isString(item) || _.isNil(item) || _.isBoolean(item) || _.isNumber(item);
    let targetMap;
    if (isItemPrimitive) {
      if (!this._stringMap) {
        this._stringMap = new Map();
      }
      targetMap = this._stringMap;
    } else {
      if (!this._weakMap) {
        this._weakMap = new WeakMap();
      }
      targetMap = this._weakMap;
    }
    let itemUUID = targetMap.get(item);
    if (!itemUUID) {
      itemUUID = uuid.v1();
      targetMap.set(item, itemUUID);
    }
    return {
      _hash: itemUUID,
      _value: item
    };
  },
  unhashedItem: function (item) {
    return item ? item._value : null;
  },
  hashedItems: function (items) {
    return (items || []).map((item, index) => _extends({
      _index: index
    }, this.hashedItem(item)));
  },
  unhashedItems: function (items) {
    return (items || []).map(this.unhashedItem);
  },
  itemFromItemsMatchingHash: function (items, hash) {
    const hashedItemsByHash = _.keyBy(this.hashedItems(items), '_hash');
    return this.unhashedItem(hashedItemsByHash[hash]);
  }
};
module.exports = guidHelperMixin;