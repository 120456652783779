"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _flattenObjectReducer() {
  let collector = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let objectA = arguments.length > 1 ? arguments[1] : undefined;
  let pathArr = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  if (_lodash.default.isObject(objectA) && Object.keys(objectA).length == 0) {
    collector[pathArr.join('.')] = null;
    //# when the leaf object is null, we still need to have it appear
    //# in the flattened root object;
    return collector;
  }
  return _lodash.default.reduce(objectA, (collector, valueA, keyA) => {
    const keyAPathArr = pathArr.concat(keyA);
    if (_lodash.default.isObject(valueA) || _lodash.default.isArray(valueA)) {
      return _flattenObjectReducer(collector, valueA, keyAPathArr);
    }
    collector[keyAPathArr.join('.')] = valueA;
    return collector;
  }, collector);
}
function _coerceDataTypes(objectA) {
  return _lodash.default.reduce(objectA, (collector, valueA, keyA) => {
    collector[keyA] = valueA == undefined ? null : valueA;
    return collector;
  }, {});
}
const diffHelperMixin = {
  mixinName: 'rubyComponentMixinFieldDiffHelper',
  flattenedObject: objectToFlatten => {
    return _coerceDataTypes(_flattenObjectReducer({}, objectToFlatten));
  }
};
module.exports = diffHelperMixin;