"use strict";

module.exports = {
  COMPONENT_NAME: 'rubyComponentListerConfigs',
  DEFAULT_CONFIG: {
    columns: [{
      key: 'name',
      displayName: 'Name'
    }],
    filters: [],
    defaultSortColumn: 'name',
    defaultSortDirection: 'asc'
  }
};