"use strict";

var _redux = require("redux");
var _reactRedux = require("react-redux");
var _lodash = _interopRequireDefault(require("lodash"));
var _routeParser = _interopRequireDefault(require("route-parser"));
var _AlterTemplateDialog = _interopRequireDefault(require("./AlterTemplateDialog"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const rubyWords = require('@rubyapps/ruby-words');
const RubyComponentForms = require('@rubyapps/ruby-component-forms');
module.exports = function alterTemplateDialogGenerator() {
  const selfModule = this;
  const rootModule = this.getRoot();
  const dependencies = selfModule.getDependencies();
  const selfSelector = dependencies.selfSelector;
  const frontendSettingsSelector = dependencies.frontendSettingsSelector;
  const selfActions = selfModule.getAction().generators;
  const editPageComponent = dependencies.editPageComponent;
  const formsSelector = dependencies.formsSelector;

  //# NOTE: previewsByFormKey isn't used, consider DEPRECATING
  const previewsByFormKey = this.props.previewsByFormKey;
  const previewsPrefix = this.props.previewsPrefix;
  const previewsExtension = this.props.previewsExtension;
  function formsArrayReducer(collector, formObject, formKey) {
    const availableFields = RubyComponentForms.availableFieldsInTemplateForEditing(formObject);
    collector.push({
      value: formKey,
      primaryText: _lodash.default.get(formObject, 'name') || rubyWords.capitalizeWords(rubyWords.inflection.transform(formKey, ['humanize'])),
      availableFields,
      previewImage: formObject.previewImage ? formObject.previewImage.url : previewsByFormKey && previewsByFormKey[formKey] ? previewsByFormKey : previewsPrefix + formKey + previewsExtension
    });
    return collector;
  }

  //# Figuring out the editPage route
  const editPageRouteTemplate = editPageComponent ? editPageComponent.getRoutePath() : ':template/:id';
  const editPageRouteParser = new _routeParser.default(editPageRouteTemplate);
  function mapStateToProps(state, ownProps) {
    selfModule;
    const selfState = selfSelector(state);
    const allForms = formsSelector(state);
    const activeRouteComponent = rootModule.getActiveRouteComponent();
    const activeSubsiteId = _lodash.default.isFunction(_lodash.default.get(activeRouteComponent, 'getActiveSubsiteId')) ? activeRouteComponent.getActiveSubsiteId() : {};
    const alterableForms = _lodash.default.reduce(allForms, (result, curr, key) => {
      const isAlterable = getIsAlterable_fromForm_forSupportedTemplateTypes(curr, selfState.supportedTemplateTypes);
      const subsiteMatches = getSubsiteMatches_fromForm_forActiveSubsite(curr, activeSubsiteId);
      if (isAlterable && subsiteMatches) {
        result[key] = curr;
        return result;
      } else {
        return result;
      }
    }, {});
    const formsArray = _lodash.default.reduce(alterableForms, formsArrayReducer, []);
    const frontendSettingsState = frontendSettingsSelector(state);
    const mappedProps = {
      open: selfState.open,
      confirmOpen: selfState.confirmOpen
      //, hasErrors: Object.keys(selector.getFieldsThatErroredByKey(state)).length > 0
      ,
      fields: {
        templateKey: {
          id: 'templateKey',
          value: _lodash.default.get(selfState, 'fields.templateKey.value') || (formsArray.length ? formsArray[0].value : null),
          errorText: _lodash.default.get(selfState, ['fields', 'templateKey', 'error', 'message'], null),
          formsArray: formsArray.sort((a, b) => {
            return a.primaryText.localeCompare(b.primaryText);
          })
        }
      }
    };
    return mappedProps;
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfActions, dispatch)
    };
  }
  function mergeProps(stateProps, dispatchProps, ownProps) {
    //# need to handle delegation here because it needs the bound action functions
    let modifiedStateProps = stateProps;
    modifiedStateProps.fields.templateKey.onChange = value => {
      dispatchProps.actions.setFieldValueByKey(value, 'templateKey');
    };
    return _extends({}, ownProps, modifiedStateProps, dispatchProps, {
      //# setup the delegate callbacks here whcih calls on dispatchProps.actions...
      delegateAlterTemplate: function (evt) {
        let id = _lodash.default.get(stateProps, 'fields.id.value');
        let templateKey = _lodash.default.get(stateProps, 'fields.templateKey.value');
        dispatchProps.actions.closeAndAlterTemplate_thenResetAlterTemplateDialog({
          id,
          templateKey
        });
        return;
      },
      delegateClose: function (evt) {
        dispatchProps.actions.closeAlterTemplateDialog();
        dispatchProps.actions.resetAlterTemplateDialog();
      },
      delegateOpenConfirmation: function (evt) {
        dispatchProps.actions.openConfirmation();
      }
    });
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps, mergeProps)(_AlterTemplateDialog.default);
};
function getSubsiteMatches_fromForm_forActiveSubsite(form, activeSubsiteId) {
  const formSubsites = form.ruby_subsite_fk ? _lodash.default.castArray(form.ruby_subsite_fk) : [activeSubsiteId];
  return _lodash.default.includes(formSubsites, activeSubsiteId);
}
function getIsAlterable_fromForm_forSupportedTemplateTypes(form, supportedTemplateTypes) {
  const isPageContent = supportedTemplateTypes.indexOf(form.templateType) > -1;
  return _lodash.default.every([!_lodash.default.get(form, 'unique_template'), isPageContent]);
}