"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const Promise = require('bluebird');
const request = require('@rubyapps/ruby-superagent');
function typesWithID(id) {
  return {
    RETRIEVE_ANALYTICS_DATA: `@@ruby-app/${id}/RETRIEVE_ANALYTICS_DATA`,
    SET_ANALYTICS_DATA: `@@ruby-app/${id}/SET_ANALYTICS_DATA`,
    SET_DOMAIN: `@@ruby-app/${id}/SET_DOMAIN`
  };
}
const generators = {
  retrieveAnalyticsData: function (_ref) {
    let {
      endpointsByKey
    } = _ref;
    const {
      TYPES,
      generators
    } = this.getAction();
    return (dispatch, getState) => {
      Promise.props(_lodash.default.mapValues(endpointsByKey, endpoint => request.get(endpoint).then(res => res.body))).then(data => {
        dispatch(generators.setAnalyticsData({
          data
        }));
      });
    };
  },
  setAnalyticsData: function (_ref2) {
    let {
      data
    } = _ref2;
    const {
      TYPES,
      generators
    } = this.getAction();
    return {
      type: TYPES.SET_ANALYTICS_DATA,
      payload: {
        data
      }
    };
  },
  openLink: function (urlValue) {
    const protocolRegex = new RegExp('^(https?://)');
    const isAbsolute = protocolRegex.exec(urlValue) !== null;
    const isRootPath = urlValue.startsWith('/');
    const url = isAbsolute || isRootPath ? urlValue : `//${urlValue}`;
    const newWindow = window.open();
    newWindow.opener = null;
    newWindow.location = url;
  }
};
module.exports = function () {
  return {
    TYPES: typesWithID(this.getID()),
    generators
  };
};