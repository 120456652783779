"use strict";

//# Register the RubyComponent against the TinyMCE Plugin
const React = require('react');
const RubyComponent = require('@rubyapps/ruby-component');
const PropTypes = RubyComponent.PropTypes;
const emailLinkDialogTemplate = require('../common/templates/emailLinkDialog').default;
const CONSTANTS = require('../common/constants');
const RCRTEEmailLink = RubyComponent.createClass({
  componentName: CONSTANTS.COMPONENT_NAME,
  getInitialState: function () {
    return {
      //onSubmit: null //# when "add link" is clicked
      //, open: false
    };
  },
  getDefaultProps: () => ({
    formDialogID: require('@rubyapps/ruby-component-form-dialog/src/common/constants').COMPONENT_NAME,
    formsID: require('@rubyapps/ruby-component-forms/src/common/constants').COMPONENT_NAME
  }),
  dependencies: function () {
    const selfModule = this;
    const rootModule = selfModule.getRoot();
    const formsComponent = rootModule.findDescendentByID(this.props.formsID);
    const formDialogComponent = rootModule.findDescendentByID(this.props.formDialogID);
    return {
      formsComponent,
      formDialogComponent
    };
  },
  action: require('./action'),
  reducer: require('./reducer'),
  onReduxInit: function (dispatch) {
    const {
      formsComponent
    } = this.getDependencies();

    //# register the form
    formsComponent.setTemplate_atKey(emailLinkDialogTemplate, emailLinkDialogTemplate.key);
  }

  //# == UTILITIES =================================//
  ,

  getAugmentedDialogTemplate: function () {
    const {
      formsComponent
    } = this.getDependencies();
    const augmentedDialogTemplate = formsComponent.getAugmentedTemplateForKey(emailLinkDialogTemplate.key, undefined, this);
    return augmentedDialogTemplate;
  }
});
const {
  pluginName
} = require('./tinymce/editor_plugin');
tinymce.PluginManager.lookup[pluginName].RubyComponent = RCRTEEmailLink;
module.exports = RCRTEEmailLink;