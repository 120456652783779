"use strict";

var _urlJoin = _interopRequireDefault(require("url-join"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
const request = require('@rubyapps/ruby-superagent');
const RubyComponentEditPage__CONSTANTS = require('@rubyapps/ruby-component-material-edit-page/src/common/constants');
const ACTIVITIES_ENDPOINT = '/activities/recent-updates';
function typesWithID(id) {
  return {
    SET_DISPLAY: `@@ruby-app/${id}/SET_DISPLAY`,
    SET_LOADING: `@@ruby-app/${id}/SET_LOADING`,
    SET_ACTIVITIES: `@@ruby-app/${id}/SET_ACTIVITIES`,
    SET_MODE: `@@ruby-app/${id}/SET_MODE`
  };
}
const generators = {
  openDisplay: function () {
    const {
      generators
    } = this.getAction();
    return (dispatch, getState) => {
      dispatch(generators.setDisplay(true));
      dispatch(generators.fetchActivities());
    };
  },
  closeDisplay: function () {
    const {
      generators
    } = this.getAction();
    return (dispatch, getState) => {
      dispatch(generators.setDisplay(false));
    };
  },
  setDisplay: function (open) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SET_DISPLAY,
      payload: {
        open
      }
    };
  },
  showLoading: function () {
    const {
      generators
    } = this.getAction();
    return (dispatch, getState) => {
      dispatch(generators.setLoading(true));
    };
  },
  clearLoading: function () {
    const {
      generators
    } = this.getAction();
    return (dispatch, getState) => {
      dispatch(generators.setLoading(false));
    };
  },
  setLoading: function (loading) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SET_LOADING,
      payload: {
        loading
      }
    };
  },
  setActivities: function (activities) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SET_ACTIVITIES,
      payload: {
        activities
      }
    };
  },
  fetchActivities: function () {
    const {
      generators
    } = this.getAction();
    const {
      currentUserSelector,
      feSettingsSelector,
      selfSelector
    } = this.getDependencies();
    return (dispatch, getState) => {
      const applicationState = getState();
      const selfState = selfSelector(applicationState);
      const feSettings = feSettingsSelector(applicationState);
      const currentUser = currentUserSelector(applicationState);
      const {
        restApiRoot
      } = feSettings;
      let mode = selfState.mode;
      let activitiesEndpointURL = mode === 'user' ? (0, _urlJoin.default)(restApiRoot, ACTIVITIES_ENDPOINT, `?user_id=${currentUser.id}`) : (0, _urlJoin.default)(restApiRoot, ACTIVITIES_ENDPOINT);
      dispatch(generators.setLoading(true));
      request.get(activitiesEndpointURL).then(res => {
        dispatch(generators.setActivities(res.body));
        dispatch(generators.setLoading(false));
      }, err => {
        dispatch(generators.setLoading(false));
      });
    };
  }
  /**
   *  @param options.pathOptions -  additional path options 
   */,
  editEntity_ofModel: function (entityInfo, modelName) {
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    const rootComponent = this.getRoot();
    const {
      id,
      template_keyword,
      content_subsite_fk,
      ruby_client_fk
    } = entityInfo;
    const {
      generators
    } = this.getAction();
    const {
      pathOptions = {}
    } = options;
    return (dispatch, getState) => {
      const applicationState = getState();
      const editLink = rootComponent.getBestUrl_fromModule_withParams(this, {
        action: 'edit',
        template: template_keyword,
        id,
        subsite: this.getSubsiteUrlIdentifier_forRubyClientId_andSubsiteId(ruby_client_fk, content_subsite_fk),
        content_subsite_fk,
        modelName
        // TODO: ruby client url
      }, {
        //# only consider routes that have an `id` param
        //# this is more general than checking that node.componentName === 'rubyComponentEditPage'
        matchedParamsValidator: 'id'
      });
      dispatch(generators.setDisplay(false));
      dispatch(generators.navigateToPathWithOptions(_objectSpread({
        path: editLink
      }, pathOptions)));
    };
  },
  setMode: function (mode) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SET_MODE,
      payload: {
        mode
      }
    };
  }
};
module.exports = function () {
  const TYPES = typesWithID(this.getID());
  return {
    TYPES,
    generators: generators
  };
};