"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  Dialog: {
    style: {}
  },
  CircularProgress: {
    style: {}
  },
  ModuleButton: {
    style: {
      display: 'block',
      width: '100%',
      height: 'auto',
      borderRadius: 0
    }
  },
  EditButton: {
    style: {}
  }
};
exports.default = _default;