"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _lodash = _interopRequireDefault(require("lodash"));
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _Repeater = _interopRequireDefault(require("./Repeater"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const rubyWords = require('@rubyapps/ruby-words');
function generator() {
  const selfModule = this;
  const {
    selfSelector
  } = selfModule.getDependencies();
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const pickedSelfState = _lodash.default.pick(selfState, ['childrenOrderById', 'childrenIdToTypeMap', 'rerenderTimestamp', 'options']);
    const {
      options,
      optionsByType
    } = selfState;
    const moduleProps = _extends({}, selfModule.props, {
      options,
      optionsByType
    });
    const optionsContainModule = options.filter(option => option.componentName == "Module").length > 0;
    const mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);
    const formattedError = selfModule.formattedErrorFromState(state);
    const mappedProps = _extends({}, pickedSelfState, ownProps, moduleProps, mixinFieldProps, {
      id: selfModule.getID(),
      moduleMode: optionsContainModule,
      dragHandleClass: 'dragHandle__' + rubyWords.keyify(selfModule.getID()),
      children: ownProps.getMergedChildrenReactElements ? ownProps.getMergedChildrenReactElements() : ownProps.children
    }, formattedError);
    return mappedProps;
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
    };
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_Repeater.default);
}