"use strict";

const RubyComponentTemplateEditPage__CONSTANTS = require('@rubyapps/ruby-component-plugin-template-editor/src/local_modules/ruby-component-template-edit-page/src/common/constants');
const RubyComponentFieldForm__CONSTANTS = require('@rubyapps/ruby-component-field-form/src/common/constants');

//== The Middleware ========================================================//

const middlewareInitializer = function (store) {
  const selfModule = this;
  const selfSelector = this.getDefaultSelector();
  const selfAction = selfModule.getAction();
  const {
    formsComponent
  } = selfModule.getDependencies();
  const {
    TYPES: formsComponent__TYPES
  } = formsComponent.getAction();
  return next => action => {
    let nextRetVal = next(action); //# still allow @@router to continue propagation
    const actionType = action.type;
    switch (actionType) {
      case formsComponent__TYPES.SET_FORM:
      case formsComponent__TYPES.SET_FORMS:
        store.dispatch(selfAction.generators.update_lastUpdatedTimestamp());
        break;
    }
    return nextRetVal;
  };
};
module.exports = middlewareInitializer;