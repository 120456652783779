"use strict";

function typesWithID(id) {
  return {
    SEED_CHILDREN: `@@ruby-app/${id}/SEED_CHILDREN`,
    RETRIEVE_FORM: `@@ruby-app/${id}/RETRIEVE_FORM`
  };
}
function getQueryForSave_usingFieldsState(fieldsState) {
  return {
    templateName: fieldsSate.templateName
  };
}
const generators = {
  seedChildrenWithFormData: function (formData) {
    const selfModule = this;
    const {
      selfAction
    } = selfModule.getDependencies();
    return {
      type: selfAction.TYPES.SEED_CHILDREN
    };
  }

  //# TODO:
  //# NOTE: this does not fire if tab is active through the url hash
  ,
  selectSelf: function () {
    const {
      generators: actions
    } = this.getAction();
    const parentTabsComponent = this.getParent();
    const {
      generators: parentActions
    } = parentTabsComponent.getAction();
    const siblings = parentTabsComponent.getChildren();
    const selfIndex = _.indexOf(siblings, this);
    return (dispatch, getState) => {
      dispatch(parentActions.setSelectedIndex(selfIndex));
    };
  },
  onSelected: function () {
    const {
      generators: actions
    } = this.getAction();
    return actions.set_visible();
  }
};
module.exports = function () {
  return {
    TYPES: typesWithID(this.getID()),
    generators
  };
};