"use strict";

const request = require('@rubyapps/ruby-superagent');
const _ = require('lodash');
const rubyMiddlewareFields = require('@rubyapps/ruby-middleware-fields');
const TYPES = {
  OPEN_DIALOG: '@@ruby-app/alert-dialog/OPEN_DIALOG',
  CLOSE_DIALOG: '@@ruby-app/alert-dialog/CLOSE_DIALOG',
  SET_LOADING: '@@ruby-app/alert-dialog/SET_LOADING',
  CLEAR_LOADING: '@@ruby-app/alert-dialog/CLEAR_LOADING',
  SET_CONTENT: '@@ruby-app/alert-dialog/SET_CONTENT',
  SET_OPTIONS: '@@ruby-app/alert-dialog/SET_OPTIONS',
  RESET_DIALOG: '@@ruby-app/alert-dialog/RESET_DIALOG'
};
var generators = {
  //# options.parent_id
  openDialog: function (options) {
    return {
      type: TYPES.OPEN_DIALOG
    };
  }
  /**
   *
   *  options: {
   *      title: the title of the dialog
   *      content: the content / message of the dialog
   *      endpoint: the endpoint to submit the form to
   *      submitSuccessCallback: fn()
   *      submitFailCallback: fn()
   *  }
   *
   **/,
  openDialogWithOptions: function (options) {
    const generators = this.getAction().generators;
    return (dispatch, getState) => {
      dispatch(generators.setOptions(options));
      dispatch(generators.openDialog());
    };
  },
  closeDialog: function () {
    return {
      type: TYPES.CLOSE_DIALOG
    };
  },
  closeAndResetDialog: function () {
    const selfModule = this;
    return dispatch => {
      const {
        closeCallback
      } = this.getState();
      if (closeCallback) {
        closeCallback();
      }
      dispatch(generators.closeDialog());
      dispatch(generators.resetDialog());
    };
  },
  setOptions: function (options) {
    const selfModule = this;
    const generators = selfModule.getAction().generators;
    return {
      type: TYPES.SET_OPTIONS,
      payload: options
    };
  },
  setLoading: function () {
    return {
      type: TYPES.SET_LOADING
    };
  },
  clearLoading: function () {
    return {
      type: TYPES.CLEAR_LOADING
    };
  },
  setContent: function (content) {
    return {
      type: TYPES.SET_CONTENT,
      payload: content
    };
  },
  resetDialog: function () {
    return {
      type: TYPES.RESET_DIALOG
    };
  },
  submit: function () {
    const selfModule = this;
    const selfSelectorObj = selfModule.getSelector();
    const generators = selfModule.getAction().generators;
    return (dispatch, getState) => {
      const applicationState = getState();
      const selfState = selfSelectorObj.default(applicationState);
      const {
        errorContext,
        endpoint
      } = selfState;
      const fields = selfState.fields;
      const data = _.reduce(fields, (collector, fieldObj, fieldKey) => {
        collector[fieldKey] = fieldObj.value;
        return collector;
      }, {});

      // TODO: show loader, don't allow actions for closing or submitting to continue
      dispatch(generators.setLoading());
      return request.post(endpoint).send(data).then(function onResult(response) {
        dispatch(generators.clearLoading());
        dispatch(generators.closeDialog());
        selfState.submitSuccessCallback && selfState.submitSuccessCallback(response);
      }, function onError(err) {
        dispatch(generators.clearLoading());
        selfState.submitFailCallback && selfState.submitFailCallback(err);
      });
    };
  }
};
module.exports = {
  TYPES: TYPES,
  generators: generators
};