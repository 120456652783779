"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getTemplateEditorPermissionItemForSelfModule;
const pluginTemplateEditorComponentId = 'rubyComponentPluginTemplateEditor';
function getTemplateEditorPermissionItemForSelfModule(selfModule) {
  const templateEditorComponent = selfModule.getRoot().findDescendentByID(pluginTemplateEditorComponentId);
  return templateEditorComponent ? [{
    label: 'Templates',
    id: 'templates',
    populateWithValueFor: 'template'
  }, {
    label: 'Templates - All Access',
    id: 'templatesAllAccess',
    value: {
      keyword: 'admin_templates_section.locked_items',
      ruby_client: 3,
      action: '*'
    }
  }] : null;
}