"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const Promise = require('bluebird');
const faker = require('@rubyapps/ruby-faker');

//# IDEA: expose something like 
//# Window.sampleDataByFieldKey = {
//      name: [...] //# possible names to use
//# }
module.exports = {
  //# override this for complex components like Repeater 
  //# or TokenTagger
  //# or special field types like url or email
  sampleValueFromFieldSpecs: function () {
    const selfKey = this.props.key;
    const data_type = this.props.data_type || 'string';
    if (this.props.default) {
      return this.props.default;
    }

    //# need to handle some special keys

    let isProcessed = false;
    let value;
    switch (selfKey) {
      case 'parent_id':
        isProcessed = true;
        break;
    }
    if (!value && !isProcessed) {
      switch (data_type) {
        case 'number':
          value = faker.random.number();
          break;
        case 'boolean':
          value = faker.random.boolean();
          break;
        case 'string':
          value = faker.random.uuid();
          break;
      }
    }
    return value;
  }
  /**
   * like formValueFromLocalState, but it recursively generates sample data based on field specs
   */,
  _promisedFormValueFromFieldSpecs: function () {
    const selfID = this.getID();
    const selfKey = this.props.key;
    const children = this.getChildren();
    const promisedArrOfReducedChildrenState = Promise.reduce(children, (collector, child, index) => {
      const childID = child.getID();
      return (child._promisedFormValueFromFieldSpecs ? child._promisedFormValueFromFieldSpecs() : Promise.resolve(undefined)).then(childFormValue => {
        if (childFormValue != undefined) {
          collector.push(childFormValue);
        }
        return collector;
      });
    }, []);
    return promisedArrOfReducedChildrenState.then(arrOfReducedChildrenState => {
      const reducedChildrenState = _extends({}, ...arrOfReducedChildrenState);
      const reducedChildrenAvail = Object.keys(reducedChildrenState).length > 0;
      return reducedChildrenAvail && selfKey ? Promise.props({
        [selfKey]: reducedChildrenState
      }) : selfKey ? Promise.props({
        [selfKey]: this.sampleValueFromFieldSpecs()
      }) : reducedChildrenAvail ? reducedChildrenState : undefined;
    });
  },
  promisedFormValueFromFieldSpecs: function () {
    return this._promisedFormValueFromFieldSpecs();
  }
};