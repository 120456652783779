"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const request = require('@rubyapps/ruby-superagent');
const rubyLogger = require('@rubyapps/ruby-logger');
const logger = rubyLogger.getLogger('ruby-component-forms:client/action');
const TYPES = {
  OVERRIDE_FORM_PROPS: '@@ruby-app/forms/OVERRIDE_FORM_PROPS',
  SET_TEMPLATE: '@@ruby-app/forms/SET_TEMPLATE',
  SET_TEMPLATES: '@@ruby-app/forms/SET_TEMPLATES'
};

//# fetch-form gets the expanded forms
//# we should hit a different endpoint to get the expanded forms
//# templates/fetch-forms //# returns the entire list
const FETCH_TEMPLATES_URL = '/ruby/api/v2/templates/fetch-forms/';
const FETCH_TEMPLATE_URL = '/ruby/api/v2/templates/fetch-form/';
const TEMPLATE_POLLING_INTERVAL = 1000 * 60 * 1; //# ms * sec * min; //# every 1 minute;

var generators = {
  overrideProps_withConfig(config) {
    return {
      type: TYPES.OVERRIDE_FORM_PROPS,
      payload: {
        config
      }
    };
  },
  pollTemplates: function () {
    const selfModule = this;
    const selfActions = selfModule.getAction().generators;
    return function (dispatch, getState) {
      const previousTemplates = selfModule.getState();
      dispatch(selfActions.retrieveTemplates(true)).then(() => {
        const activeTemplateKey = selfModule.getActiveTemplateKey();
        const previousTemplate = previousTemplates[activeTemplateKey];
        if (selfModule.activeTemplatesAreDifferent_fromPrevState(previousTemplates)) {
          selfModule.openTemplateChangedDialog_forTemplate(previousTemplate);
        }
        setTimeout(() => {
          dispatch(selfActions.pollTemplates());
        }, TEMPLATE_POLLING_INTERVAL);
      });
    };
  },
  retrieveTemplates: function (isPolling) {
    const self = this;
    const selfActions = self.getAction().generators;
    return function (dispatch, getState) {
      return request.get(FETCH_TEMPLATES_URL + (isPolling ? '?polling' : '')).query({
        where: JSON.stringify({
          status: 'active'
        })
      }).then(response => {
        const templatesArr = _lodash.default.get(response, ['body']);
        const templatesByKey = _lodash.default.keyBy(templatesArr, 'key');

        //# hacky
        self._retrievedTemplates = true; //# must set this before update the state

        dispatch(generators.setTemplates(templatesByKey));
      }).catch(err => {
        if (_lodash.default.get(err, 'response.status') != 401) {
          //# only show the error for non-auth related errors
          logger.warn("Encountered error while retrieveing templates", err.stack);
          self.showErrorNotification({
            error: err
          });
        }
      });
    };
  },
  retrieveTemplate_forTemplateKey: function (templateKey) {
    const self = this;
    const selfActions = self.getAction().generators;
    return function (dispatch, getState) {
      return request.get(FETCH_TEMPLATE_URL + templateKey).then(function success(response) {
        // with RT#81215, the status field is added to the template model so now
        // it can be enabled or disabled. Thus, when we use this action, 
        // we need to account for situations that the templates are changed 
        // to inactive or the templates are still inactive but with other changes. 
        // either way, template shouldn't be set under these situations. 
        console.warn("TODOs (retrieveTemplate_forTemplateKey): update logic for dispatching setTemplate_atKey");

        // TODO: update logic 
        dispatch(generators.setTemplate_atKey(response.body, templateKey));
      }, function error(err) {
        //# don't show error, just redirect if necessary
        if (err.response.status != 401) {
          //# only show the error for non-auth related errors
          self.showErrorNotification({
            error: err
          });
        }
      });
    };
  },
  setTemplates: function (templates) {
    return {
      type: TYPES.SET_TEMPLATES,
      payload: {
        templates
      }
    };
  },
  setTemplate_atKey: function (data, key) {
    return {
      type: TYPES.SET_TEMPLATE,
      payload: {
        data,
        key
      }
    };
  }
};
module.exports = {
  TYPES: TYPES,
  generators: generators
};