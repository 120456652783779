"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GRIDDLE_COLLAPSE_ROW = GRIDDLE_COLLAPSE_ROW;
exports.GRIDDLE_EXPAND_ROW = GRIDDLE_EXPAND_ROW;
exports.GRIDDLE_FILTERED_AFTER = GRIDDLE_FILTERED_AFTER;
exports.GRIDDLE_LOADED_DATA_AFTER = GRIDDLE_LOADED_DATA_AFTER;
exports.GRIDDLE_MOVE_ROW = GRIDDLE_MOVE_ROW;
exports.GRIDDLE_MOVE_ROW_WITH_ID = GRIDDLE_MOVE_ROW_WITH_ID;
exports.GRIDDLE_MOVE_ROW_WITH_ID_TO_CHILD_OF_ROW_WITH_ID = GRIDDLE_MOVE_ROW_WITH_ID_TO_CHILD_OF_ROW_WITH_ID;
exports.GRIDDLE_MOVE_ROW_WITH_ID_TO_RELATIVE_POSITION_BY_ID = GRIDDLE_MOVE_ROW_WITH_ID_TO_RELATIVE_POSITION_BY_ID;
exports.setRowProperties = setRowProperties;
var _lodash = _interopRequireDefault(require("lodash"));
var _immutable = _interopRequireDefault(require("immutable"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function GRIDDLE_COLLAPSE_ROW(state, action, helpers) {
  const indexPath = action.indexPath;
  const rowPath = getPathInData_fromIdxPath(indexPath);
  let data = state.get('data');
  let hoverRow = data.getIn([...rowPath]);
  if (hoverRow.get('children') && hoverRow.get('children').size > 0 && state.getIn(['expandedRows', hoverRow.get('id')])) {
    return state.setIn(['expandedRows', hoverRow.get('id')], false);
  } else {
    return state;
  }
}
function GRIDDLE_EXPAND_ROW(state, action, helpers) {
  const indexPath = action.indexPath;
  const rowPath = getPathInData_fromIdxPath(indexPath);
  let data = state.get('data');
  let hoverRow = data.getIn([...rowPath]);
  if (hoverRow.get('children') && hoverRow.get('children').size > 0 && !state.getIn(['expandedRows', hoverRow.get('id')])) {
    return state.setIn(['expandedRows', hoverRow.get('id')], true);
  } else {
    return state;
  }
}
function GRIDDLE_MOVE_ROW_WITH_ID_TO_RELATIVE_POSITION_BY_ID(state, action) {
  const {
    moveId,
    referenceId

    //# "before", "after", "inside"
    ,
    relativePosition
  } = action;
  const data = state.get('data');
  const movedRowIdxPath = getIndexPath_fromNestedData_withFindFunc(data.toJS(), el => el.id === moveId);
  const movedRow = data.getIn(getPathInData_fromIdxPath(movedRowIdxPath));
  const movedRowContainerPath = getGridRowContainerPath_fromIdxPath(movedRowIdxPath);
  const referenceRowJS = getRow_withId_fromNestedData(referenceId, data.toJS());
  if (_lodash.default.get(referenceRowJS, 'disable_adding_children') && relativePosition === 'inside') {
    return state;
  }
  const dataWithoutMovedRow = data.updateIn(movedRowContainerPath, children => children.splice(_lodash.default.last(movedRowIdxPath), 1));
  const referenceComponentIdxPath = getIndexPath_fromNestedData_withFindFunc(dataWithoutMovedRow.toJS(), el => el.id === referenceId);
  const targetContainerIdxPath = relativePosition === 'inside' ? referenceComponentIdxPath : referenceComponentIdxPath.slice(0, -1);
  const insertIdx = {
    inside: referenceRowJS.children.length,
    before: _lodash.default.last(referenceComponentIdxPath),
    after: _lodash.default.last(referenceComponentIdxPath) + 1
  }[relativePosition];
  const targetRowContainerPath = targetContainerIdxPath.length ? getPathInData_fromIdxPath(targetContainerIdxPath).concat('children') : [];
  const newData = dataWithoutMovedRow.updateIn(targetRowContainerPath, children => {
    const childrenNormalizedAsArray = children == undefined ? _immutable.default.List.of() : children;
    return childrenNormalizedAsArray.splice(insertIdx, 0, movedRow);
  });

  // make sure the parent we just added to is expanded
  const expandedRows = state.get('expandedRows');
  const newExpandedRows = relativePosition === 'inside' && referenceId ? expandedRows.set(referenceId, true) : expandedRows;
  const newDataWithRowProps = setRowProperties(state, newData);
  return state.set('data', newDataWithRowProps).set('expandedRows', newExpandedRows);
}
function GRIDDLE_MOVE_ROW_WITH_ID_TO_CHILD_OF_ROW_WITH_ID(state, action, helpers) {
  const moveId = action.moveId;
  const targetId = action.targetId;
  const data = state.get('data');
  const movedRowJS = getRow_withId_fromNestedData(moveId, data.toJS());
  const movedRowIdxPath = movedRowJS.indexPath;
  const movedRowContainerPath = getGridRowContainerPath_fromIdxPath(movedRowIdxPath);
  const movedRow = data.getIn(getPathInData_fromIdxPath(movedRowIdxPath));
  const targetRowJS = getRow_withId_fromNestedData(targetId, data.toJS());
  if (movedRow == null || _immutable.default.Iterable.isIterable(movedRow) && !movedRow.get('id') || _lodash.default.get(targetRowJS, 'disable_adding_children')) {
    return state;
  }
  const dataWithoutMovedRow = data.updateIn(movedRowContainerPath, children => children.splice(movedRowIdxPath.slice(-1)[0], 1));
  const targetComponentIdxPath = getIndexPath_fromNestedData_withFindFunc(dataWithoutMovedRow.toJS(), el => el.id === targetId);
  const targetRowContainerPath = getGridRowContainerPath_fromIdxPath(targetComponentIdxPath.concat(0));
  const newData = dataWithoutMovedRow.updateIn(targetRowContainerPath, children => {
    const childrenNormalizedAsArray = children == undefined ? _immutable.default.List.of() : children;
    return childrenNormalizedAsArray.splice(0, 0, movedRow);
  });

  // make sure the parent we just added to is expanded
  const expandedRows = state.get('expandedRows');
  const newExpandedRows = targetId ? expandedRows.set(targetId, true) : expandedRows;
  const newDataWithRowProps = setRowProperties(state, newData);
  return state.set('data', newDataWithRowProps).set('expandedRows', newExpandedRows);
}
function getIndexPath_fromNestedData_withFindFunc(nestedData, findFunc) {
  let indexPath;
  nestedSearch_children_currIdxPath(nestedData, []);
  function nestedSearch_children_currIdxPath(children, currIdxPath) {
    children.forEach((el, idx) => {
      if (findFunc(el)) {
        indexPath = currIdxPath.concat(idx);
      } else if (Array.isArray(el.children)) {
        nestedSearch_children_currIdxPath(el.children, currIdxPath.concat(idx));
      }
    });
  }
  return indexPath;
}
function getRow_withId_fromNestedData(id, nestedData) {
  const flattenedArray = flatten_nestedData(nestedData);
  const row = flattenedArray.find(child => child.id === id);
  return row;
}
function GRIDDLE_MOVE_ROW_WITH_ID(state, action, helpers) {
  const id = action.id;
  const data = state.get('data').toJS();
  const flattenedArray = flatten_nestedData(data);
  const movedChild = flattenedArray.find(child => child.id === id);
  const movedChildIdxPath = movedChild.indexPath;
  const newAction = {
    type: "GRIDDLE_MOVE_ROW",
    fromIdxPath: movedChildIdxPath,
    toIdxPath: action.toIdxPath
  };
  return GRIDDLE_MOVE_ROW(state, newAction, helpers);
}
function GRIDDLE_MOVE_ROW(state, action, helpers) {
  const fromIdxPath = action.fromIdxPath;
  const toIdxPath = action.toIdxPath;
  const movedRow = getGridRow_fromData_withIdxPath(state.getIn(['data']), fromIdxPath);
  const movedRowContainerPath = getGridRowContainerPath_fromIdxPath(fromIdxPath);
  const targetRowContainerPath = getGridRowContainerPath_fromIdxPath(toIdxPath);
  const targetRowParentPath = targetRowContainerPath.slice(0, -1) || [];
  let data = state.get('data');
  const targetRowParent = targetRowParentPath.length > 0 ? data.getIn(targetRowParentPath) : undefined;
  if (movedRow == null || _immutable.default.Iterable.isIterable(movedRow) && !movedRow.get('id') || _immutable.default.Iterable.isIterable(targetRowParent) && (!targetRowParent.get('id') || targetRowParent.get('disable_adding_children'))) {
    return state;
  }
  const dataWithoutMovedRow = data.updateIn(movedRowContainerPath, children => children.splice(fromIdxPath.slice(-1)[0], 1));
  const newTargetComponentIdxPath = !targetRowParent ? [] : getIndexPath_fromNestedData_withFindFunc(dataWithoutMovedRow.toJS(), el => el.id === targetRowParent.get('id'));
  const newTargetRowContainerPath = getGridRowContainerPath_fromIdxPath(newTargetComponentIdxPath.concat(toIdxPath.slice(-1)));
  const newData = dataWithoutMovedRow.updateIn(newTargetRowContainerPath, children => {
    children = children == undefined ? _immutable.default.List.of() : children;
    return children.splice(toIdxPath.slice(-1)[0], 0, movedRow);
  });

  // make sure the parent we just added to is expanded
  const expandedRows = state.get('expandedRows');
  const newExpandedRows = targetRowParent ? expandedRows.set(targetRowParent.get('id'), true) : expandedRows;
  const newDataWithRowProps = setRowProperties(state, newData);
  return state.set('data', newDataWithRowProps).set('expandedRows', newExpandedRows);
}
function GRIDDLE_FILTERED_AFTER(state, action, helpers) {
  const newFilter = action.filter;
  const utilityBarState = state.get('utilityBarProperties');
  return newFilter ? setRowProperties_inState(state).setIn(['utilityBarProperties'], _extends({}, utilityBarState, {
    sortable_enabled: false
  })) : setRowProperties_inState(state);
}
function GRIDDLE_LOADED_DATA_AFTER(state, action, helpers) {
  return setRowProperties_inState(state);
}
function setRowProperties_inState(state) {
  const data = state.get('data');
  const newData = setRowProperties(state, data);
  return state.set('data', setRowProperties(state, data));
}
function setRowProperties(state, data) {
  let parentIndexPath = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  let depth = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 0;
  let parent_id = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
  let key = 0;
  const getKey = () => key++;
  const utilityBarState = _immutable.default.Iterable.isIterable(state.get('utilityBarProperties')) ? state.get('utilityBarProperties').toJS() : state.get('utilityBarProperties');
  const isDraggable = !state.get('filter') && _lodash.default.get(utilityBarState, 'sortable_enabled') === true && _lodash.default.get(utilityBarState, 'template') == null;
  return data.map((row, index) => {
    let id = row.get('id');
    let children = row.get('children');
    let currentIndexPath = parentIndexPath !== null ? [...parentIndexPath, getKey()] : [getKey()];
    if (children && children.size > 0) {
      children = setRowProperties(state, children, currentIndexPath, depth + 1, id);
    }
    return row.set('parent_id', parent_id).set('isDraggable', isDraggable).set('children', children).set('depth', depth).set('expandedFlag', state.get('expandedRows').get(row.get('id'))).set('indexPath', currentIndexPath).set('hasChildren', children && children.size > 0);
  });
}
function getGridRowContainerPath_fromIdxPath(idxPath) {
  if (idxPath.length === 1) {
    return [];
  } else {
    return getPathInData_fromIdxPath(idxPath).slice(0, -1);
  }
}
function getGridRow_fromData_withIdxPath(data, idxPath) {
  const pathToRowInData = getPathInData_fromIdxPath(idxPath);
  return data.getIn(pathToRowInData);
}

// takes in somthing like this: [0,3,2]
// and returns [0,'children',3,'chidren',2]
// used to get the data from a nested structure
function getPathInData_fromIdxPath(idxPath) {
  return idxPath.reduce((arr, val, idx) => {
    if (idx === 0) {
      return [idxPath[0]];
    } else {
      return arr.concat('children', val);
    }
  }, []);
}
function flatten_nestedData(nestedData) {
  let flattenedArray = [];
  pluckAllChildrenIntoFlattenedArray_fromNestedArr(nestedData);
  function pluckAllChildrenIntoFlattenedArray_fromNestedArr(arr) {
    arr.forEach(child => {
      flattenedArray.push(child);
      if (Array.isArray(child.children)) {
        pluckAllChildrenIntoFlattenedArray_fromNestedArr(child.children);
      }
    });
  }
  return flattenedArray;
}