"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const React = require('react');
const RubyComponent = require('@rubyapps/ruby-component');
const PropTypes = RubyComponent.PropTypes;
const CONSTANTS = require('../common/constants');
const componentName = CONSTANTS.COMPONENT_NAME;
const baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
const fieldValidationMixin = require('@rubyapps/ruby-component-mixin-field-validations');
const fieldUrlMixin = require('@rubyapps/ruby-component-mixin-field-url');
const RCHidden = RubyComponent.createClass({
  mixins: [baseFieldMixin, fieldValidationMixin, fieldUrlMixin],
  propTypes: {
    reactProps: PropTypes.object
    //, label: PropTypes.string
    //, key: PropTypes.string
    //, constraints: PropTypes.object
    //, options: [
    //      //# like dropdown
    //      {
    //      
    //      }
    //  ]
    ,
    options: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
    }))
  },
  getDefaultProps: function () {
    return {
      listerConfig: {
        excludeFromColumnSelection: true,
        excludeFromFilterSelection: true
      }
    };
  },
  componentName: componentName,
  CONSTANTS: CONSTANTS,
  _formValueFromLocalState: function (selfState, isError) {
    let predicateFormatter = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : value => value;
    let entireState = arguments.length > 3 ? arguments[3] : undefined;
    let options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
    const baseFormValue = baseFieldMixin._formValueFromLocalState.apply(this, arguments);
    const key = this.props.key;
    const hydratedProps = this.getProps(true);
    return _extends({}, baseFormValue, hydratedProps.hasOwnProperty('value') ? {
      [key]: hydratedProps.value
    } : {});
  }
  /* 
  //# DEPRECATED 20180913 - was used by overriding formValueFromFieldValue_forKey()
  //# but we should defer responsibility to the baseFieldMixin
  , _getNumberFromValue: function (value) {
      // If its truly not a number
      // then pass string to server and let server complain
      // as opposed to passing null or 0 and letting the server save that value
      return value === ""                ? null
           : _.isNaN(parseInt(value,10)) ? value
           :                               parseInt(value, 10)
           ;
  }
  */
});

module.exports = RCHidden;