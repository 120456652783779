"use strict";

//# DO NOT ADD TO THIS WITHOUT SPEAKING TO CORE TEAM
module.exports = {
  LOGIN_ROUTE: '/login.html',
  RUBYAPP_VERSION: '0.2',
  COMPOUND_FIELD_MAPPINGS: {
    full_username: ['first_name', 'last_name']
  },
  EXECUTION_MODE: {
    DEFAULT: 'server',
    SERVER: 'server',
    WORKER: 'worker',
    SCRIPT: 'script',
    TEST: 'test'
  }

  // Cache public files for 1 year
  ,
  PUBLIC_TTL: '1y'

  // Cache private file for 1 day
  // (we could cache for 1 year, but private URLs are user specific
  //  and we don't want to many hanging around in CF/Varnish)
  ,
  PRIVATE_TTL: '1d'
};