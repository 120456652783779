"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _normalizr = require("normalizr");
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function getFlattenedNestedData_fromNestedData_withChildrenKey(nestedData) {
  let key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'children';
  const wrappedNestedData = Array.isArray(nestedData) ? {
    id: 'root',
    [key]: nestedData
  } : nestedData;

  //let nextId = 0;
  function generateId(entity, id) {
    const newId = _.get(entity, 'id');
    //|| (nextId++).toString();
    //# previously there's an obscure scenario where if the griddleData received an empty row 
    //# where the id was missing (which previously happened during the denormalize stage of the hydrate-sitemap endpoint)
    //# and you modified the sitemap,
    //# then nextId would have been used, which is invalid
    //# thus causing the system to think you 
    return newId;
  }
  const rowSchema = new _normalizr.Schema('rows', {
    idAttribute: generateId
  });
  rowSchema.define({
    children: (0, _normalizr.arrayOf)(rowSchema)
  });
  const normalizedData = (0, _normalizr.normalize)(wrappedNestedData, rowSchema);
  const rowsByKey = _.get(normalizedData, 'entities.rows');
  return _.pickBy(rowsByKey, (val, key) => key !== 'root');
}
function getNormalizedDataWithoutDuplicates_fromNormalizedData() {
  let data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const rootIds = data.entities.rows[data.result].children;
  const rowById = _.get(data, 'entities.rows');
  const newRootIds = _.uniq(rootIds);
  const rowIds = Object.keys(rowById);
  const newRows = rowIds.map((id, index) => {
    const row = rowById[id];
    const encounteredRows = rowIds.slice(0, index).map(id => rowById[id]);
    const encounteredChildrenIds = [].concat(...encounteredRows.map(row => row.children || []));
    const currentChildren = row.children || [];
    const childrenWithoutRootItems = currentChildren.filter(childId => newRootIds.findIndex(rootId => rootId == childId) == -1);
    const childrenWithoutEncounteredIds = childrenWithoutRootItems.filter(childId => {
      return encounteredChildrenIds.findIndex(rootId => rootId == childId) === -1;
    });
    return _extends({}, row, {
      children: childrenWithoutEncounteredIds //: childrenWithoutRootItems
    });
  });

  const newRowById = newRows.reduce((result, curr) => {
    result[curr.id] = curr;
    return result;
  }, {});
  return {
    result: newRootIds,
    entities: {
      rows: newRowById
    }
  };
}
var _default = {
  getNormalizedDataWithoutDuplicates_fromNormalizedData,
  getFlattenedNestedData_fromNestedData_withChildrenKey
};
exports.default = _default;