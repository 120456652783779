"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
var _index = _interopRequireDefault(require("./reactComponents/UtilityBar/index"));
var _indexConnector = _interopRequireDefault(require("./reactComponents/UtilityBar/indexConnector"));
var _indexConnector2 = _interopRequireDefault(require("@rubyapps/ruby-component-mixin-list-page/src/client/reactComponents/AdvancedSearchUtilityBar/indexConnector.js"));
var _index2 = _interopRequireDefault(require("@rubyapps/ruby-component-mixin-list-page/src/client/reactComponents/AdvancedSearchUtilityBar/index.js"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const RubyComponentAlterTemplateDialog = require('@rubyapps/ruby-component-alter-template-dialog');
const RubyComponentConfirmDialog = require('@rubyapps/ruby-component-confirm-dialog');
const ListPage = require('@rubyapps/ruby-component-list-page');
const rubyNotificationsComponent = require('@rubyapps/ruby-component-notifications');
const GriddleModule = require('@rubyapps/griddle');
const {
  GriddleUtilityBarPlugin,
  GriddleRemotePlugin,
  GriddleDefaultsPlugin
} = GriddleModule;
const RemotePlugin = GriddleRemotePlugin.RemotePlugin;
const superagentProvider = GriddleRemotePlugin.superagentProvider;
const ListerConnectorHelper = require('@rubyapps/ruby-component-mixin-list-page/src/client/reactComponents/ListerConnectorHelper');
function getGriddlePlugins_duringMergeProps(stateProps, dispatchProps, ownProps) {
  const utilityBarPlugin = getUtilityBarPlugin_duringMergeProps.apply(this, arguments);
  const remotePlugin = getRemotePlugin_duringMergeProps.apply(this, arguments);
  const defaultsPlugin = getDefaultsPlugin_duringMergeProps.apply(this, arguments);
  return [].concat(utilityBarPlugin, remotePlugin, defaultsPlugin).filter(_lodash.default.identity);
}
module.exports = getGriddlePlugins_duringMergeProps;
function _getLister_fromTemplateKey(selfModule, templateKey) {
  const {
    listerConfigsComponent
  } = selfModule.getDependencies();
  return listerConfigsComponent.listerConfigForKey(templateKey, selfModule);
}
function getDefaultsPlugin_duringMergeProps(stateProps, dispatchProps, ownProps) {
  const selfModule = this;
  const {
    hierarchical,
    columns
  } = stateProps;
  const listerConfig = _getLister_fromTemplateKey(selfModule, stateProps.template_key);
  const defaultSortColumn = selfModule.utils.determineDefaultSortColumn_withColumns_andListerConfig(columns, listerConfig);
  const defaultSortDirection = selfModule.utils.determineDefaultSortDirection_withColumns_andListerConfig(columns, listerConfig);
  return GriddleDefaultsPlugin.DefaultsPlugin(selfModule.getInitialGriddleState_withDefault({
    pageProperties: {
      pageSize: ListPage.CONSTANTS.DEFAULT_PAGE_SIZE,
      sortColumns: !hierarchical ? [defaultSortColumn] : [],
      sortAscending: defaultSortDirection == 'asc'
    }
  }));
}
function getUtilityBarPlugin_duringMergeProps(stateProps, dispatchProps, ownProps) {
  const selfModule = this;
  const showTemplateFilter = selfModule.props.showTemplateFilter;
  const customFilterName = 'filter1';
  const {
    listerNav_defaultOption,
    listerNav_options,
    hasAddEntryPermission,
    hierarchical,
    template_key,
    customFilters,
    allForms
  } = stateProps;
  const listerConfig = _getLister_fromTemplateKey(selfModule, template_key);
  const getUtilityBarActions_usingUtilityBarProps = selfModule.getComposedUtilityBarActions_usingUtilityBarProps;
  const mergedPropsFromArgument = _extends({
    listerConfig,
    searchableFields: selfModule.searchableFieldsForKey(template_key)
  }, ownProps, stateProps, dispatchProps);
  //# temporary cache of the props for access by Griddle
  selfModule._cachedPropsForConnector = mergedPropsFromArgument;
  if (selfModule.props.searchMode == 'advanced') {
    const {
      savedSearchListerRoute
    } = selfModule.getSavedSearchListerDependencies();
    _extends(selfModule._cachedPropsForConnector, {
      savedSearchListerRoute
    });
    return GriddleUtilityBarPlugin((0, _indexConnector2.default)(selfModule, () => selfModule._cachedPropsForConnector)(_index2.default));
  }
  return GriddleUtilityBarPlugin((0, _indexConnector.default)(selfModule, () => selfModule._cachedPropsForConnector)(_index.default));
}
function getRemotePlugin_duringMergeProps(stateProps, dispatchProps, ownProps) {
  const selfModule = this;
  const {
    listApiEndpoint,
    hierarchical,
    filters,
    template_key,
    customFilters
  } = stateProps;
  const additionalQueryParams = _lodash.default.get(selfModule, 'props.additionalQueryParams') || {};
  const listerConfig = _getLister_fromTemplateKey(selfModule, template_key);
  const remoteConfig = {
    url: listApiEndpoint,
    formatRequest: ListerConnectorHelper.curriedSelfModule_forFormatRequest(selfModule, {
      listerConfig,
      additionalQueryParams: _extends(_objectSpread({}, hierarchical ? {
        template_keyword: template_key
      } : {}), additionalQueryParams)
    }),
    formatSuccess: ListerConnectorHelper.formatSuccess
  };
  return RemotePlugin(superagentProvider, remoteConfig);
}