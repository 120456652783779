"use strict";

module.exports = {
  componentWillReceiveProps: function (nextProps) {
    if (nextProps.open == false && this.props.open == true) {
      this._unbindWindowResize();
    } else if (nextProps.open == true && this.props.open == false) {
      this._bindWindowResize();
    }
  },
  componentDidUpdate: function (prevProps) {
    const nextProps = this.props;
    if (nextProps.open == true && !prevProps.open) {
      this._updateLastRenderedTimestamp();
    }
  },
  componentDidMount: function () {
    this._isMounted = true;
    this._bindWindowResize();
    this._updateLastRenderedTimestamp();
  },
  componentWillUnmount: function () {
    this._isMounted = false;

    //# stop listening to window resize
    window.removeEventListener('resize', this.onWindowResize);
  },
  _updateLastRenderedTimestamp: function () {
    if (this._isMounted) {
      this.setState({
        lastRenderedTimestamp: new Date()
      });
    }
  },
  _bindWindowResize: function () {
    window.addEventListener('resize', this._updateLastRenderedTimestamp);
  },
  _unbindWindowResize: function () {
    window.removeEventListener('resize', this._updateLastRenderedTimestamp);
  }
};