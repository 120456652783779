"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const tokenizer = require('sbd');
module.exports = {
  getTextSummary: function (value) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const sbdOptions = _extends({
      sanitize: true
    }, options.sbdOptions);
    const sentences = tokenizer.sentences(value, sbdOptions);
    if (options.sentenceCount) {
      return sentences.slice(0, options.sentenceCount).join(' ');
    }
    return sentences[0] || '';
  }
};