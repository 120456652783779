"use strict";

var _reduxBatchedActions = require("redux-batched-actions");
const Promise = require('bluebird');
const request = require('@rubyapps/ruby-superagent');
const fieldForm_CONSTANTS = require('@rubyapps/ruby-component-field-form/src/common/constants');
function typesWithID(id) {
  return {
    SET_PRISTINE_DATA: `@@ruby-app/${id}/SET_PRISTINE_DATA`,
    SET_URL: `@@ruby-app/${id}/SET_URL`,
    RESET_STORE: `@@ruby-app/${id}/RESET_STORE`
  };
}
const generators = {
  retrieveDataAtEndpoint: function (endpoint, thenableArguments) {
    const selfModule = this;
    const {
      TYPES,
      generators
    } = this.getAction();
    return function (dispatch) {
      const promisedRequest = endpoint && request.get(endpoint).then(function success(response) {
        //
        const data = response.body;
        //# set the response data

        dispatch((0, _reduxBatchedActions.batchActions)([generators.clearRequestObject(), generators.setPristineData(data), generators.setUrl(endpoint)]));

        //return Promise.resolve(data).then(...thenableArguments);

        return data;
      });
      dispatch(generators.setRequestObject(promisedRequest));
      if (thenableArguments) {
        return promisedRequest.then(...thenableArguments);
      }
      return promisedRequest;
    };
  },
  setPristineData: function (data) {
    const TYPES = this.getAction().TYPES;
    return {
      type: TYPES.SET_PRISTINE_DATA,
      payload: {
        data
      }
    };
  },
  setUrl: function (data) {
    const TYPES = this.getAction().TYPES;
    return {
      type: TYPES.SET_URL,
      payload: {
        data
      }
    };
  }

  //# NOTE: maybe? not really an action that creates state but does it count if
  //# it's actually the children generating state changes?
  ,
  formValueToChildrenLocalState: function (formValue) {
    return dispatch => {
      //# return a batch action
      let collector = [];
      const collectAction = value => {
        collector.push(value);
      };
      let promisedOnceResolved = {
        resolve: null,
        reject: null
      };
      const promiseTree = this.formValueToChildrenLocalState(formValue, collectAction, {
        promisedOnceResolved: new Promise((resolve, reject) => {
          promisedOnceResolved.resolve = resolve;
          promisedOnceResolved.reject = reject;
        })
      });
      promiseTree.then(() => {
        dispatch((0, _reduxBatchedActions.batchActions)(collector));

        //console.log('promisedOnceResolved.resolve for: ', this.getID())
        promisedOnceResolved.resolve && promisedOnceResolved.resolve();
      });
    };
  },
  resetChildrenLocalState: function () {
    return dispatch => {
      //# return a batch action
      let collector = [];
      const collectAction = value => {
        collector.push(value);
      };
      const promiseTree = this.resetChildrenLocalState(collectAction);
      promiseTree.then(() => {
        dispatch((0, _reduxBatchedActions.batchActions)(collector));
      });
    };
  },
  resetStore: function () {
    const TYPES = this.getAction().TYPES;
    return {
      type: TYPES.RESET_STORE
    };
  }
};
module.exports = function () {
  return {
    TYPES: typesWithID(this.getID()),
    generators
  };
};