"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filteredGetChildren = filteredGetChildren;
exports.flattenNestedDataBy = flattenNestedDataBy;
exports.getChildren = getChildren;
exports.getSortedColumns = getSortedColumns;
function getSortedColumns(data, columns, childrenPropertyName) {
  //# ~44ms
  return data.map((row, index) => {
    let children = row.get(childrenPropertyName || 'children');
    if (children && children.size > 0) {
      children = getSortedColumns(row.get('children'), columns);
    }
    return row.sortBy((val, key) => columns.indexOf(key)).set('children', children);
  });
}
function getChildren(row) {
  const rowId = row.get('id');
  const hasChildren = row.getIn(['__metadata', 'hasChildren'], row.get('hasChildren'));
  return hasChildren ? row.get('children') : null;
}
function filteredGetChildren(filter) {
  return row => {
    const rowId = row.get('id');
    const hasChildren = row.getIn(['__metadata', 'hasChildren'], row.get('hasChildren'));
    return hasChildren ? row.get('children').filter(filter) : null;
  };
}
function flattenNestedDataBy(data, expandFunc) {
  if (!data) return [];
  return data.reduce((acc, row) => {
    const children = flattenNestedDataBy(expandFunc(row), expandFunc);
    return acc.concat([row], children);
  }, []);
}