"use strict";

const RubyComponent = require('@rubyapps/ruby-component');
const PropTypes = RubyComponent.PropTypes;
module.exports = {
  propTypes: {
    keepToolbarOpen: PropTypes.bool
  },
  getDefaultProps: function () {
    return {
      keepToolbarOpen: false
    };
  }
};