"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPagesLoaded = getPagesLoaded;
exports.getRemoteProvider = getRemoteProvider;
exports.getTableState = getTableState;
exports.getUpdatedSortDirection = getUpdatedSortDirection;
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function getTableState(store) {
  const state = store.getState().toJS();
  return {
    page: _lodash.default.get(state, ['pageProperties', 'currentPage']),
    pageSize: _lodash.default.get(state, ['pageProperties', 'pageSize']),
    filter: _lodash.default.get(state, 'filter'),
    sortColumn: _lodash.default.get(state, ['pageProperties', 'sortColumns']),
    sortDirection: _lodash.default.get(state, ['pageProperties', 'sortAscending']) ? 'asc' : 'desc'
  };
}
function getUpdatedSortDirection(store, sortColumn) {
  const state = store.getState();
  const sortColumns = state.getIn(['pageProperties', 'sortColumns']);

  // Default to ascending
  if (!sortColumns || !sortColumns.includes(sortColumn)) return 'asc';

  // Flip the sort direction
  return state.getIn(['pageProperties', 'sortAscending']) ? 'desc' : 'asc';
}
function getPagesLoaded(store) {
  const state = store.getState();
  return state.get('pagesLoaded');
}
function getRemoteProvider(store) {
  const state = store.getState();
  return state.get('remoteProvider').toJS();
}