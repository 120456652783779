// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TokenTaggerList__DragHandleIcon___z4uBE {\n    cursor: move;\n    position: relative;\n}\n\n.TokenTaggerList__Placeholder___veHxq {\n    /*\n    background: #e8e8e8;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    */\n    border-radius: 16px;\n}\n\n", "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-repeater/src/client/reactComponents/TokenTagger/TokenTaggerList.cssModule"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI;;;;;;;KAOC;IACD,mBAAmB;AACvB","sourcesContent":[".DragHandleIcon {\n    cursor: move;\n    position: relative;\n}\n\n.Placeholder {\n    /*\n    background: #e8e8e8;\n    width: 100%;\n    height: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    */\n    border-radius: 16px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"DragHandleIcon": "TokenTaggerList__DragHandleIcon___z4uBE",
	"Placeholder": "TokenTaggerList__Placeholder___veHxq"
};
export default ___CSS_LOADER_EXPORT___;
