"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
var _reduxBatchedActions = require("redux-batched-actions");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const {
  combineReducers
} = require('redux');
const request = require('@rubyapps/ruby-superagent');
const urljoin = require('url-join');
function typesWithID(id) {
  return {
    CUSTOM_RESET_STATE: `@@ruby-app/${id}/CUSTOM_RESET_STATE`,
    SET_LOCAL_STATE_CACHE_FOR_NAMESPACE: `@@ruby-app/${id}/SET_LOCAL_STATE_CACHE_FOR_NAMESPACE`,
    SAVE_FORM_VALUE: `@@ruby-app/${id}/SAVE_FORM_VALUE`,
    SAVE_LOCAL_STATE: `@@ruby-app/${id}/SAVE_LOCAL_STATE`,
    SET_LOCAL_STATE: `@@ruby-app/${id}/SET_LOCAL_STATE` //# DEPRECATED 20170106, use mixin "replaceLocalState" instead
    ,
    REPLACE_SAVED_LOCAL_STATE_BY_NAMESPACE: `@@ruby-app/${id}/REPLACE_SAVED_LOCAL_STATE_BY_NAMESPACE`,
    REPLACE_DEFERRED_FORM_VALUE_BY_NAMESPACE: `@@ruby-app/${id}/REPLACE_DEFERRED_FORM_VALUE_BY_NAMESPACE`,
    REPLACE_DEFERRED_FORM_ERROR_BY_NAMESPACE: `@@ruby-app/${id}/REPLACE_DEFERRED_FORM_ERROR_BY_NAMESPACE`,
    REPLACE_DEFERRED_CLEAR_FORM_ERROR_BY_NAMESPACE: `@@ruby-app/${id}/REPLACE_DEFERRED_CLEAR_FORM_ERROR_BY_NAMESPACE`,
    CLEAR_DEFERRED_FORM_VALUE_FOR_NAMESPACE: `@@ruby-app/${id}/CLEAR_DEFERRED_FORM_VALUE_FOR_NAMESPACE`,
    CLEAR_DEFERRED_FORM_ERROR_FOR_NAMESPACE: `@@ruby-app/${id}/CLEAR_DEFERRED_FORM_ERROR_FOR_NAMESPACE`,
    CLEAR_DEFERRED_CLEAR_FORM_ERROR_FOR_NAMESPACE: `@@ruby-app/${id}/CLEAR_DEFERRED_CLEAR_FORM_ERROR_FOR_NAMESPACE`
  };
}
const KEYS_TO_OMIT_FROM_LOCAL_STATE_CACHE = ['savedLocalStateByNamespace', 'savedLocalReducerByNamespace', 'savedFormValueByNamespace', 'deferredFormValueByNamespace', 'deferredFormErrorByNamespace', 'deferredClearFormErrorByNamespace'];
const generators = {
  //# NOTE: we're batching resetStore, and THUNKS don't work
  //# so you MUST override the rubyComponent.resetStore method and call on store.dispatch immediately 
  //# Hopefully, in the future, we can update how resetStore works and/or the batch plugin to 
  //# allow for batching thunks
  resetStore: function () {
    const {
      TYPES,
      generators
    } = this.getAction();
    const selfSelector = this.getDefaultSelector();
    return (dispatch, getState) => {
      const applicationState = getState();
      const selfState = selfSelector(applicationState);
      const options = _lodash.default.get(selfState, 'props.options', []);
      dispatch({
        type: TYPES.CUSTOM_RESET_STATE
      });
    };
  },
  setLocalStateCacheForNamespace: function (localState, namespace) {
    const {
      TYPES,
      generators
    } = this.getAction();
    return {
      type: TYPES.SET_LOCAL_STATE_CACHE_FOR_NAMESPACE,
      payload: {
        namespace,
        localState
      }
    };
  }
  //# for namespace reconstitution
  ,
  saveLocalStateForActiveNamespace: function () {
    const {
      TYPES,
      generators
    } = this.getAction();
    const selfSelector = this.getDefaultSelector();
    const selfID = this.getID();
    const selfKey = this.props.key;
    const children = this.getChildren();
    const state = this.getState();
    const selfObjectValue = this.objectValueFromLocalState(state);
    const childrenIDs = children.map(child => child.getID());
    //const localState = _.pick(selfObjectValue[selfID], childrenIDs);
    const localState = _lodash.default.omit(state, KEYS_TO_OMIT_FROM_LOCAL_STATE_CACHE);
    const activeNamespace = _lodash.default.get(state, ['fields', this.props.key, 'value']);
    return {
      type: TYPES.SAVE_LOCAL_STATE,
      payload: {
        namespace: activeNamespace,
        localState
      }
    };
  },
  saveFormValueForActiveNamespace: function () {
    const {
      TYPES,
      generators
    } = this.getAction();
    const state = this.getState();
    const activeNamespace = _lodash.default.get(state, ['fields', this.props.key, 'value']);
    const formValueForActiveNamespace = this.getFormValueForActiveNamespace_withLocalState(state);
    return {
      type: TYPES.SAVE_FORM_VALUE,
      payload: {
        namespace: activeNamespace,
        formValue: formValueForActiveNamespace
      }
    };
  },
  replaceSavedLocalStateByNamespace: function (savedLocalStateByNamespace) {
    const {
      TYPES,
      generators
    } = this.getAction();
    return {
      type: TYPES.REPLACE_SAVED_LOCAL_STATE_BY_NAMESPACE,
      payload: {
        savedLocalStateByNamespace
      }
    };
  },
  replaceDeferredFormValueByNamespace: function (deferredFormValueByNamespace) {
    const {
      TYPES,
      generators
    } = this.getAction();
    return {
      type: TYPES.REPLACE_DEFERRED_FORM_VALUE_BY_NAMESPACE,
      payload: {
        deferredFormValueByNamespace
      }
    };
  },
  replaceDeferredFormErrorByNamespace: function (deferredFormErrorByNamespace) {
    const {
      TYPES,
      generators
    } = this.getAction();
    return {
      type: TYPES.REPLACE_DEFERRED_FORM_ERROR_BY_NAMESPACE,
      payload: {
        deferredFormErrorByNamespace
      }
    };
  },
  replaceDeferredClearFormErrorByNamespace: function (deferredClearFormErrorByNamespace) {
    const {
      TYPES,
      generators
    } = this.getAction();
    return {
      type: TYPES.REPLACE_DEFERRED_CLEAR_FORM_ERROR_BY_NAMESPACE,
      payload: {
        deferredClearFormErrorByNamespace
      }
    };
  },
  setLocalStateForActiveNamespace: function () {
    const {
      TYPES,
      generators: actions
    } = this.getAction();
    const selfSelector = this.getDefaultSelector();
    const selfID = this.getID();
    const state = this.getState();
    const children = this.getChildren();
    const activeNamespace = _lodash.default.get(state, ['fields', this.props.key, 'value']);
    const localState = _lodash.default.get(state, ['savedLocalStateByNamespace', activeNamespace]);
    const deferredFormValue = _lodash.default.get(state, ['deferredFormValueByNamespace', activeNamespace]);
    const deferredFormError = _lodash.default.get(state, ['deferredFormErrorByNamespace', activeNamespace]);
    const deferredClearFormError = _lodash.default.get(state, ['deferredClearFormErrorByNamespace', activeNamespace]);

    //# NOTE: if received deferredFormError, we want to dispatch formValueToLocalState() for the errors AFTER recovering the localState
    //# NOTE: if we received deferreredFormValue, then we want to ignore the localState
    //# NOTE: the assumption could be that any namespaced value that has a localState cached should be preferred because the formValue being loaded should only happen on route load before localStates have been cached for other namespaces (thus the deferred formValues are preferred)

    const arrayOfActions_forReset = [];
    const collector_forReset = action => {
      arrayOfActions_forReset.push(action);
    };
    const arrayOfActions_forHydration = [];
    const collector_forHydration = action => {
      arrayOfActions_forHydration.push(action);
    };
    if (localState) {
      const preppedLocalState = _lodash.default.omit(localState, KEYS_TO_OMIT_FROM_LOCAL_STATE_CACHE);
      /* //# 20171130 - deprecated call. It's being handled below
      this.replaceLocalState(preppedLocalState, collector_forReset, (el) => {
          if (el.props.excludeFromNamespaces) { return false; }
          return true;
      });
      */
    } else {
      //# reset children values
      children.forEach(child => {
        child.resetStore && child.resetStore(collector_forReset, el => {
          if (el.props.excludeFromNamespaces) {
            return false;
          }
          return true;
        });
      });
    }
    let promiseTree = Promise.resolve(null);
    return dispatch => {
      //# dispatch the swap first
      dispatch((0, _reduxBatchedActions.batchActions)(arrayOfActions_forReset));
      setImmediate(() => {
        if (deferredFormValue) {
          //deferredFormValue.subservice_extended_content = [];
          promiseTree = this.deferredFormValueToLocalState(deferredFormValue, collector_forHydration); //# TODO: the problematic thing is here
          //# remove the deferredFormValue for the sepcific namespace
          collector_forHydration(actions.clearDeferredFormValueForNamespace(activeNamespace));
        } else if (localState) {
          const preppedLocalState = _lodash.default.omit(localState, KEYS_TO_OMIT_FROM_LOCAL_STATE_CACHE);
          promiseTree = this.replaceLocalStateAsync(preppedLocalState, el => {
            if (el.props.excludeFromNamespaces) {
              return false;
            }
            return true;
          });
        }

        //# Need to wait for promise to resolve
        promiseTree.then(() => {
          arrayOfActions_forHydration.length && dispatch((0, _reduxBatchedActions.batchActions)(arrayOfActions_forHydration));
          setImmediate(() => {
            const delayedArrayOfActions_forClear = [];
            const delayedCollector_forClear = action => {
              delayedArrayOfActions_forClear.push(action);
            };
            const delayedArrayOfActions_forError = [];
            const delayedCollector_forError = action => {
              delayedArrayOfActions_forError.push(action);
            };
            if (deferredClearFormError) {
              this.deferredClearErrorsWithKeys(delayedCollector_forClear, deferredClearFormError);
              delayedCollector_forClear(actions.clearDeferredClearFormErrorForNamespace(activeNamespace));
              dispatch((0, _reduxBatchedActions.batchActions)(delayedArrayOfActions_forClear));
            }
            if (deferredFormError) {
              this.deferredFormValueToLocalState(deferredFormError, delayedCollector_forError, true);
              //# remove the deferredFormError for the sepcific namespace
              delayedCollector_forError(actions.clearDeferredFormErrorForNamespace(activeNamespace));
              dispatch((0, _reduxBatchedActions.batchActions)(delayedArrayOfActions_forError));
            }

            //# NOTE: a handleful of other components calls on refreshParentErrors()
            //# when switching namespaces, and because it happens asynchronously
            //# before we finish hydrating the errors, it clears out the saved
            //# error for the namespace selector
            //# we need to refresh the parent errors
            this.refreshParentErrors();
          });
        });
      });
    };
  },
  clearDeferredFormValueForNamespace: function (namespace) {
    const {
      TYPES,
      generators
    } = this.getAction();
    return {
      type: TYPES.CLEAR_DEFERRED_FORM_VALUE_FOR_NAMESPACE,
      payload: {
        namespace
      }
    };
  },
  clearDeferredFormErrorForNamespace: function (namespace) {
    const {
      TYPES,
      generators
    } = this.getAction();
    return {
      type: TYPES.CLEAR_DEFERRED_FORM_ERROR_FOR_NAMESPACE,
      payload: {
        namespace
      }
    };
  },
  clearDeferredClearFormErrorForNamespace: function (namespace) {
    const {
      TYPES,
      generators
    } = this.getAction();
    return {
      type: TYPES.CLEAR_DEFERRED_CLEAR_FORM_ERROR_FOR_NAMESPACE,
      payload: {
        namespace
      }
    };
  },
  requestNamespaceChangeToNamespace: function (namespace) {
    const {
      TYPES,
      generators: actions
    } = this.getAction();
    const selfKey = this.key();
    return (dispatch, getState) => {
      const selfState = this.getState();
      const defaultNamespace = this.getDefaultNamespace();
      const activeNamespace = _lodash.default.get(selfState, ['fields', selfKey, 'value'], defaultNamespace);
      if (namespace != activeNamespace) {
        // Clearing cache for memoized_propertyExistsForKeypath to fix: https://gitlab.intranet.rubensteintech.com/project/jenner.com/-/issues/477
        const parentForm = this.getParentFormComponent && this.getParentFormComponent();
        const memoized_propertyExistsForKeypath = _lodash.default.get(parentForm, 'getStatefulCacheForKey') && parentForm.getStatefulCacheForKey('memoized_propertyExistsForKeypath');
        memoized_propertyExistsForKeypath && memoized_propertyExistsForKeypath.clear();
        dispatch(actions.saveFormValueForActiveNamespace());
        dispatch(actions.saveLocalStateForActiveNamespace());
        dispatch(actions.setFieldValueByKey(namespace, selfKey));
        dispatch(actions.setLocalStateForActiveNamespace());
      }
    };
  }
};
module.exports = function () {
  return {
    TYPES: typesWithID(this.getID()),
    generators
  };
};