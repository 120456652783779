// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".table-heading-cell__debugRed___VJqrC {\n    border: 1px solid red;\n    min-height: 10px;\n}\n.table-heading-cell__debugBlue___Oh_F_ {\n    border: 1px solid blue!important;\n}\n\n.table-heading-cell__sortableCell___lzDB0 {\n    cursor: pointer;\n}\n\n.table-heading-cell__sortableCell___lzDB0:hover {\n    background: #DDD;\n}\n", "",{"version":3,"sources":["webpack://./src/local_modules/griddle/local_modules/griddle-render/src/table-heading-cell.cssModule"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,gBAAgB;AACpB;AACA;IACI,gCAAgC;AACpC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".debugRed {\n    border: 1px solid red;\n    min-height: 10px;\n}\n.debugBlue {\n    border: 1px solid blue!important;\n}\n\n.sortableCell {\n    cursor: pointer;\n}\n\n.sortableCell:hover {\n    background: #DDD;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"debugRed": "table-heading-cell__debugRed___VJqrC",
	"debugBlue": "table-heading-cell__debugBlue___Oh_F_",
	"sortableCell": "table-heading-cell__sortableCell___lzDB0"
};
export default ___CSS_LOADER_EXPORT___;
