"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
module.exports = function (state, action) {
  const {
    TYPES
  } = this.getAction();
  const {
    type,
    payload
  } = action;
  switch (type) {
    case TYPES.SET_IS_LOADING:
      return _extends({}, state, {
        isLoading: payload.isLoading
      });
    default:
      return state;
  }
};