"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _Header = _interopRequireDefault(require("./Header"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
module.exports = function HeaderGenerator() {
  const selfModule = this;
  const rootComponent = selfModule.getRoot();
  const {
    selfSelector,
    selfActions,
    frontendSettingsSelector,
    currentUserSelector,
    currentUserActions,
    recentUpdatesActions
  } = selfModule.getDependencies();
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const frontendSettings = frontendSettingsSelector(state);
    const currentUserInfo = currentUserSelector(state);
    const mappedFields = {
      id: selfModule.getID(),
      userInfo: currentUserInfo ? currentUserInfo : {},
      delegateLogOut: () => {
        //# deprecated
        window.location = '/logout.html';
      },
      menuConfig: selfModule.filteredMenuConfig(),
      activeRoute: window.location.pathname,
      frontendSettings
    };
    return _extends({}, selfState, mappedFields);
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfActions, dispatch),
      delegateActions: {
        currentUser: (0, _redux.bindActionCreators)(currentUserActions, dispatch),
        recentUpdates: (0, _redux.bindActionCreators)(recentUpdatesActions, dispatch)
      }
    };
  }
  function mergeProps(stateProps, dispatchProps, ownProps) {
    const {
      userEditPageID
    } = selfModule.props;
    const currentUserInfo = stateProps.userInfo;
    const currentUserId = _lodash.default.get(currentUserInfo, 'id');
    const currentUserEditPageLocation = currentUserId ? rootComponent.getUrlForComponent_fromModule_withParams(userEditPageID, selfModule, {
      id: currentUserId,
      action: 'edit'
    }) : '#';
    return _extends({}, ownProps, stateProps, dispatchProps, {
      //# setup the delegate callbacks here whcih calls on dispatchProps.actions...
      delegateRouteChange: function (route) {
        let menuItemConfig = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
        if (menuItemConfig.url) {
          if (menuItemConfig.newWindow) {
            window.open(route);
          } else {
            window.location = route;
          }
        } else {
          dispatchProps.actions.navigateToPathWithOptions({
            path: route
          });
        }
      },
      delegateUpdateAccount: () => {
        dispatchProps.actions.navigateToPathWithOptions({
          path: currentUserEditPageLocation
        });
      },
      delegateToggleMenuPin: () => {
        dispatchProps.actions.toggleMenuPin();
      }
    });
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps, mergeProps)(_Header.default);
};