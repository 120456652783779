"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const PARENT_CONSTANTS = require('../../../../common/constants');
const TEMPLATE_FORM_TEMPLATE_TYPE = 'rubyComponentTemplateEditPage__templateForm';
const TEMPLATE_FORM_TEMPLATE_KEY = TEMPLATE_FORM_TEMPLATE_TYPE;
module.exports = _extends({}, PARENT_CONSTANTS, {
  COMPONENT_NAME: 'rubyComponentTemplateEditPage',
  TEMPLATE_FORM_TEMPLATE_TYPE,
  TEMPLATE_FORM_TEMPLATE_KEY
});