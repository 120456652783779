"use strict";

var constants = _interopRequireWildcard(require("./constants"));
var _initialState = _interopRequireDefault(require("./initial-state"));
var reducers = _interopRequireWildcard(require("./reducer"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
/**
 * Function description
 * @param {object} options.initialState - inistial state object used to override the saved preferences.
 **/
module.exports = function (preferencesStorageKeyIdentifier, preferencesToSave) {
  let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  return {
    name: 'GriddlePreferences',
    constants,
    states: (0, _initialState.default)(preferencesStorageKeyIdentifier, preferencesToSave, options),
    components: {},
    reducers
  };
};