// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[4].use[2]!../../../../ruby-styles/src/client/cssModules/field-base.cssModule";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Text__cardText___EizeV ul {\n    padding-inline-start: 40px;\n}\n\n.Text__cardText___EizeV ol {\n    padding-inline-start: 40px;\n}\n\n.Text__cardText___EizeV p {\n    margin-top: 1em;\n    margin-bottom: 1em;\n}\n\n.Text__wrapper___ypV7L {\n}\n", "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-info/src/client/reactComponents/Text.cssModule"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,eAAe;IACf,kBAAkB;AACtB;;AAEA;AAEA","sourcesContent":[".cardText ul {\n    padding-inline-start: 40px;\n}\n\n.cardText ol {\n    padding-inline-start: 40px;\n}\n\n.cardText p {\n    margin-top: 1em;\n    margin-bottom: 1em;\n}\n\n.wrapper {\n    composes: wrapper from '@rubyapps/ruby-styles/src/client/cssModules/field-base.cssModule';\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardText": "Text__cardText___EizeV",
	"wrapper": "Text__wrapper___ypV7L " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["wrapper"] + ""
};
export default ___CSS_LOADER_EXPORT___;
