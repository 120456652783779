"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
/* jshint -W138 */

const {
  SITEMAP_TEMPLATE,
  STRUCTURED_DATA_TEMPLATE
} = require('@rubyapps/ruby-component-plugin-template-editor/src/common/constants.js');
var defaultState = {
  open: false,
  confirmOpen: false,
  supportedTemplateTypes: [SITEMAP_TEMPLATE, STRUCTURED_DATA_TEMPLATE],
  fields: {
    templateKey: {
      value: null,
      error: null
    }
  },
  endpoint: null,
  submitSuccessCallback: null,
  submitFailCallback: null
};

/*
    //# this middleware expects an action of the following type:
    {
        type: '.../SET_FIELD_VALUE_BY_KEY'
        , payload: {
            key: ''
            , value: ''
            , error: { //# isNil if no error
                message:
            }
        }
    }
*/

module.exports = function (state, action) {
  const actionTypes = this.getAction().TYPES;
  if (!state.hasOwnProperty('open')) {
    state = _extends({}, state, defaultState);
  }
  switch (action.type) {
    case actionTypes.OPEN_ALTER_TEMPLATE_DIALOG:
      return _extends({}, state, {
        open: true
      });
    case actionTypes.OPEN_ALTER_TEMPLATE_DIALOG_CONFIRMATION:
      return _extends({}, state, {
        confirmOpen: true
      });
    case actionTypes.CLOSE_ALTER_TEMPLATE_DIALOG_CONFIRMATION:
      return _extends({}, state, {
        confirmOpen: false
      });
    case actionTypes.SET_OPTIONS:
      return _extends({}, state, action.payload);
    case actionTypes.CLOSE_ALTER_TEMPLATE_DIALOG:
      return _extends({}, state, {
        open: false
      });
    case actionTypes.ALTER_TEMPLATE:
      return _extends({}, state, {
        open: false
      });
    case actionTypes.RESET_ALTER_TEMPLATE_DIALOG:
      return _extends({}, state, defaultState);
    default:
      return state;
  }
};