"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GriddleHelpers = exports.GriddleActions = void 0;
Object.defineProperty(exports, "GriddleReducer", {
  enumerable: true,
  get: function () {
    return _griddleReducer.default;
  }
});
exports.States = exports.Reducers = void 0;
var reducers = _interopRequireWildcard(require("./reducers"));
exports.Reducers = reducers;
var states = _interopRequireWildcard(require("./initialStates/index"));
exports.States = states;
var _griddleReducer = _interopRequireDefault(require("./reducers/griddle-reducer"));
var GriddleActions = _interopRequireWildcard(require("./actions/local-actions"));
exports.GriddleActions = GriddleActions;
var GriddleHelpers = _interopRequireWildcard(require("./helpers"));
exports.GriddleHelpers = GriddleHelpers;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }