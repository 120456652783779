"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addKeyToRows = addKeyToRows;
exports.getAllPossibleColumns = getAllPossibleColumns;
exports.getColumnProperties = getColumnProperties;
exports.getColumnTitles = getColumnTitles;
exports.getDataColumns = getDataColumns;
exports.getPageCount = getPageCount;
exports.getPageProperties = getPageProperties;
exports.getState = getState;
exports.getVisibleData = getVisibleData;
exports.getVisibleDataColumns = getVisibleDataColumns;
exports.updateVisibleData = updateVisibleData;
var _lodash = _interopRequireDefault(require("lodash"));
var _maxSafeInteger = _interopRequireDefault(require("max-safe-integer"));
var _immutable = _interopRequireDefault(require("immutable"));
var _lodash2 = _interopRequireDefault(require("lodash.assign"));
var _rubyLodash = _interopRequireDefault(require("@rubyapps/ruby-lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function getVisibleData(state) {
  const data = state.get('data');
  const columns = getDataColumns(state, data);
  return getVisibleDataColumns(data, columns);
}
function updateVisibleData(state) {
  return state.set('visibleData', getVisibleData(state));
}

//why? Assuming this is carry-over from old flux?
function getState(state) {
  return state;
}
function getPageProperties(state) {
  return state.get('pageProperties');
}
function addKeyToRows(data) {
  let prefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  let key = 0;
  const getKey = () => key++;
  return data.map(row => row.set('griddleKey', prefix + getKey()));
}
function getPageCount(total, pageSize) {
  const calc = total / pageSize;
  return calc > Math.floor(calc) ? Math.floor(calc) + 1 : Math.floor(calc);
}
function getColumnTitles(state) {
  if (state.get('renderProperties') && state.get('renderProperties').get('columnProperties').size !== 0) {
    return state.get('renderProperties').get('columnProperties').filter(column => !!column.get('displayName')).map(column => {
      let col = {};
      col[column.get('id')] = column.get('displayName');
      return col;
    }).toJSON();
  }
  return {};
}
function getColumnProperties(state) {
  if (state.get('renderProperties') && state.get('renderProperties').get('columnProperties') && state.get('renderProperties').get('columnProperties').size !== 0) {
    return state.get('renderProperties').get('columnProperties').toJSON();
  }
  return {};
}

//TODO: Determine if this should stay here
function getAllPossibleColumns(state) {
  if (state.get('data').size === 0) {
    return new _immutable.default.fromJS([]);
  }
  return state.get('data').get(0).keySeq();
}

//From Immutable docs - https://github.com/facebook/immutable-js/wiki/Predicates
function keyInArray(keys) {
  var keySet = _immutable.default.Set(keys);
  return function (v, k) {
    return keySet.has(k);
  };
}
function keyNotInArray(keys) {
  var keySet = _immutable.default.Set(keys);
  return function (v, k) {
    return !keySet.has(k);
  };
}
function getVisibleDataColumns(data, columns) {
  if (data.size < 1) {
    return data;
  }
  const dataColumns = data.get(0).keySeq().toArray();
  const metadataColumns = dataColumns.filter(item => columns.indexOf(item) < 0);

  //Create an object of all column keys with a value of null
  //This would be the default state of the data
  //and data received in 'result' will be merged in with the base state

  const magicColumns = columns.reduce((original, item) => {
    original[item] = null;
    return original;
  }, {});

  //combine the metadata and the "magic" columns
  const extra = data.map((d, i) => new _immutable.default.Map((0, _lodash2.default)(magicColumns, {
    __metadata: d.filter(keyNotInArray(columns)).set('index', i)
  })));

  //# non-immutable way of generating the result data as reference
  //# TODO: might want to update to using Immutable
  const dataArr = data.toJS();
  const flatMappedData = dataArr.map(dataItem => {
    return _lodash.default.reduce(columns, (collector, columnKeyPath, index) => {
      const pluckedValue = _rubyLodash.default.get(dataItem, columnKeyPath);
      const finalPluckedValue = _lodash.default.isArray(pluckedValue) ? pluckedValue.filter(value => !_lodash.default.isNil(value)) : pluckedValue;
      collector[columnKeyPath] = finalPluckedValue;
      return collector;
    }, {});
  });
  const result = _immutable.default.fromJS(flatMappedData);

  //# NOTE: we're doing extra < result so that result overrides any null data in extra
  return extra.mergeDeep(result) //# allow result to override extra
  .map(item => item.sortBy((val, key) => columns.indexOf(key) > -1 ? columns.indexOf(key) : _maxSafeInteger.default));
}
function getDataColumns(state, data) {
  const renderProperties = state.get('renderProperties');
  if (renderProperties && renderProperties.get('columnProperties') && renderProperties.get('columnProperties').size !== 0) {
    const keys = state.getIn(['renderProperties', 'columnProperties']).sortBy(col => col.get('order') || _maxSafeInteger.default).keySeq().toJSON();
    return keys;
  }
  return getAllPossibleColumns(state).toJSON();
}