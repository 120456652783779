"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
module.exports = {
  disambiguatedOptions: function (options) {
    options = _.uniqBy(options, 'value') || [];
    const disambiguatedOptions = options.map((thisOption, index) => {
      const otherOptions = options.slice(0, index).concat(options.slice(index + 1));
      const originalDisplayText = this.displayTextFromOption(thisOption);
      const optionsWithMatchingDisplayText = otherOptions.filter(otherOption => originalDisplayText === this.displayTextFromOption(otherOption));
      const finalDisplayText = optionsWithMatchingDisplayText.length > 0 ? `${originalDisplayText} (${thisOption.value})` : originalDisplayText;
      return _extends({}, thisOption, {
        text: finalDisplayText
      });
    });
    return disambiguatedOptions;
  },
  displayTextFromOption: function (opt) {
    return opt.text || opt.primaryText || opt.label || opt.value;
  },
  getSelectedOptions_fromValues: function (options, values) {
    const disambiguatedOptions = this.disambiguatedOptions(options);
    const selectedOptions = values.reduce((collector, value) => {
      const foundOption = disambiguatedOptions.find(option => option.value === value);
      if (foundOption) {
        collector.push(foundOption);
      }
      return collector;
    }, []);
    return selectedOptions;
  }
};