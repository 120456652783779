"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
var _constants = _interopRequireDefault(require("../common/constants"));
var _permissions = require("../common/permissions");
var _clientInfo = require("../common/client-info");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function _reduceMenuArr_withUserObject(menuArr, userObject) {
  const filteredMenuArr = menuArr.reduce((collector, menuItem, menuIndex) => {
    const permissions = menuItem.permissions ? menuItem.permissions : [];
    const isPermitted = permissions ? (0, _permissions.doesUserMatchPermissionObject)(userObject, permissions) : true;
    if (isPermitted) {
      let reducedMenuItem = _lodash.default.omit(menuItem, ['children', 'permissions']);
      if (menuItem.children) {
        const reducedChildren = _reduceMenuArr_withUserObject(menuItem.children, userObject);
        if (reducedChildren.length) {
          reducedMenuItem.children = reducedChildren;
        }
      }
      if (reducedMenuItem.url || reducedMenuItem.route || Array.isArray(reducedMenuItem.children) && reducedMenuItem.children.length > 0) {
        collector.push(reducedMenuItem);
      }
    }
    return collector;
  }, []);
  return filteredMenuArr;
}
function _rubyClientInfoTreeTraversal_matchingFn(collector, currentNode, matchingFn) {
  if (currentNode == undefined) {
    return collector;
  }
  for (let key in currentNode) {
    let nodeForKey = currentNode[key];
    let mergedCollector = _extends({}, collector, _lodash.default.omit(nodeForKey, ['children']));
    if (matchingFn(mergedCollector)) {
      return mergedCollector;
    } else if (nodeForKey.hasOwnProperty('children')) {
      collector = _lodash.default.reduce(nodeForKey.children, (collector, childNode, childKey) => {
        return _rubyClientInfoTreeTraversal_matchingFn(collector, childNode, matchingFn);
      }, mergedCollector);
    }
  }
  return collector;
}
const accessManager = {
  CONSTANTS: _constants.default,
  does_permissionList_includePermission: function (permissionList, permission) {
    return (0, _permissions.boolean_fromPermissionObject_andUser)(permission, {
      rubyRole: {
        ruby_permissions: permissionList
      }
    });
  },
  idsInRubyPermissionsFromUser_forPerm: _permissions.idsInRubyPermissionsFromUser_forPerm,
  doesUserHavePermission: function (userObject, permission) {
    return (0, _permissions.boolean_fromPermissionObject_andUser)(permission, userObject);
  },
  filteredMenuConfig_withUserObject: function (menuConfig, userObject) {
    if (!userObject) {
      return {};
    }
    const filteredMenuConfig = _reduceMenuArr_withUserObject([menuConfig], userObject);
    return filteredMenuConfig.length ? filteredMenuConfig[0] : {};
  },
  getRubyClientInfo_fromSettings_forRoute: _clientInfo.getRubyClientInfo_fromSettings_forRoute,
  getRubyClientId_fromSettings_forRoute: (settings, route) => (0, _clientInfo.getRubyClientInfo_fromSettings_forRoute)(settings, route).client,
  getSubsiteId_fromSettings_forRoute: (settings, route) => (0, _clientInfo.getRubyClientInfo_fromSettings_forRoute)(settings, route).subsite,
  getSubsiteUrlIdentifier_fromSettings_forRubyClientId_andSubsiteId: function (feSettings, rubyClientId, subsiteId) {
    const {
      rubyClients_routeTree = {}
    } = feSettings;
    const seedInfo = _extends({}, _lodash.default.omit(rubyClients_routeTree, ['children']));
    const rubyClientInfo = _rubyClientInfoTreeTraversal_matchingFn(seedInfo, rubyClients_routeTree.children, infoObject => {
      if (infoObject.client == rubyClientId && infoObject.subsite == subsiteId) {
        return true;
      }
      return false;
    });
    return rubyClientInfo.subsiteUrlIdentifier;
  },
  getDefaultSubsiteUrlIdentifier_fromSettings: function (feSettings) {
    const {
      rubyClients_routeTree = {}
    } = feSettings;
    return rubyClients_routeTree.subsiteUrlIdentifier;
  }
};
module.exports = accessManager;