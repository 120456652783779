"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
exports.registerComponentForKey = registerComponentForKey;
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const rubyLogger = require('@rubyapps/ruby-logger');
const logger = rubyLogger.getLogger('ruby-component-builder:common:components');
const ActivityList = require('@rubyapps/ruby-component-activities');
const ApiSecret = require('@rubyapps/ruby-component-field-api-secret');
const ArrayMap = require('@rubyapps/ruby-component-field-array-map');
const AutoPopulatedText = require('@rubyapps/ruby-component-field-auto-populated-text');
const Button = require('@rubyapps/ruby-component-field-button');
const CodeEditor = require('@rubyapps/ruby-component-field-code-editor');
const JsonEditor = require('@rubyapps/ruby-component-field-json-editor');
const ContextMenu = require('@rubyapps/ruby-component-field-context-menu');
const ColorPicker = require('@rubyapps/ruby-component-field-color-picker');
const ComponentizedDatePicker = require('@rubyapps/ruby-component-field-componentized-date-picker');
const DashboardAnalytics = require('@rubyapps/ruby-component-plugin-websites/src/local_modules/ruby-component-field-dashboard-analytics');
const DatePicker = require('@rubyapps/ruby-component-field-date-picker');
const DatetimePicker = require('@rubyapps/ruby-component-field-datetime-picker');
const Dialog = require('@rubyapps/ruby-component-field-dialog');
const Dropdown = require('@rubyapps/ruby-component-field-dropdown');
const DynamicForm = require('@rubyapps/ruby-component-field-dynamic-form');
const FieldObject = require('@rubyapps/ruby-component-field-object');
const FieldEditor = require('@rubyapps/ruby-component-field-editor');
const Fieldset = require('@rubyapps/ruby-component-field-fieldset');
const FieldsPicker = require('@rubyapps/ruby-component-field-fields-picker');
const Form = require('@rubyapps/ruby-component-field-form');
const GooglePreview = require('@rubyapps/ruby-component-field-google-preview');
const Header = require('@rubyapps/ruby-component-field-header');
const Hidden = require('@rubyapps/ruby-component-field-hidden');
const ImagePicker = require('@rubyapps/ruby-component-field-image-picker');
const InternationalAddress = require('@rubyapps/ruby-component-field-international-address');
const Info = require('@rubyapps/ruby-component-field-info');
const LinkUrl = require('@rubyapps/ruby-component-field-link-url');
const ExpandedData = require('@rubyapps/ruby-component-field-expanded-data');
const MenuItem = require('@rubyapps/ruby-component-field-menu-item');
const ManipulatorNamespace = require('@rubyapps/ruby-component-field-manipulator-namespace');
const Module = require('@rubyapps/ruby-component-field-module');
const NamespaceSelector = require('@rubyapps/ruby-component-field-namespace-selector');
const FieldNumber = require('@rubyapps/ruby-component-field-number');
const Password = require('@rubyapps/ruby-component-field-password');
const Permissions = require('@rubyapps/ruby-component-field-permissions');
const ProfileIdentity = require('@rubyapps/ruby-component-field-profile-identity');
//const QueryBuilder = require('@rubyapps/ruby-component-field-query-builder');
const RadioButtonGroup = require('@rubyapps/ruby-component-field-radio-button-group');
const Repeater = require('@rubyapps/ruby-component-field-repeater');
const RichTextEditor = require('@rubyapps/ruby-component-field-rich-text-editor');
const ScheduledStatus = require('@rubyapps/ruby-component-field-scheduled-status');
const Sitemap = require('@rubyapps/ruby-component-field-sitemap');
const Tab = require('@rubyapps/ruby-component-field-tab');
const Tabs = require('@rubyapps/ruby-component-field-tabs');
const Text = require('@rubyapps/ruby-component-field-text');
const Textarea = require('@rubyapps/ruby-component-field-textarea');
const TFA = require('@rubyapps/ruby-component-field-TFA');
const TimePicker = require('@rubyapps/ruby-component-field-time-picker');
const Toggle = require('@rubyapps/ruby-component-field-toggle');
const Toggles = require('@rubyapps/ruby-component-field-toggles');
const TokenTagger = require('@rubyapps/ruby-component-field-token-tagger');
const Url = require('@rubyapps/ruby-component-field-url');
const OpenGraph = require('@rubyapps/ruby-component-field-open-graph');
const Popover = require('@rubyapps/ruby-component-field-popover');
const ViewLink = require('@rubyapps/ruby-component-field-view-link');
const Components = {
  ActivityList,
  ApiSecret,
  ArrayMap,
  AutoPopulatedText,
  Button,
  CodeEditor,
  JsonEditor,
  ContextMenu,
  ColorPicker,
  ComponentizedDatePicker,
  DashboardAnalytics,
  DatePicker,
  DatetimePicker,
  Dialog,
  Dropdown,
  DynamicForm,
  ExpandedData,
  FieldEditor,
  Fieldset,
  FieldsPicker,
  Form,
  GooglePreview,
  Header,
  Hidden,
  ImagePicker,
  InternationalAddress,
  Info,
  LinkUrl,
  ManipulatorNamespace,
  MenuItem,
  Module,
  NamespaceSelector,
  Number: FieldNumber,
  Object: FieldObject,
  Password,
  Permissions,
  ProfileIdentity
  //, QueryBuilder
  ,
  RadioButtonGroup,
  Repeater,
  RichTextEditor,
  ScheduledStatus,
  Sitemap,
  Tab,
  Tabs,
  Text,
  Textarea,
  TFA,
  TimePicker,
  Toggle,
  Toggles,
  TokenTagger,
  Url,
  OpenGraph,
  Popover,
  ViewLink
};
const baseComponents = _extends({}, Components);
var _default = Components;
exports.default = _default;
function registerComponentForKey(Component, ComponentKey) {
  if (baseComponents.hasOwnProperty(ComponentKey)) {
    logger.info(`Overriding ${ComponentKey} with custom Component`);
  }
  Components[ComponentKey] = Component;
}