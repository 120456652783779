"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getBioUpdatesPermissionItemForSelfModule;
function getBioUpdatesPermissionItemForSelfModule(selfModule) {
  const {
    attorney: attorneyForm
  } = selfModule.getForms();
  const attorneyTemplateId = attorneyForm ? attorneyForm.id : null;
  return attorneyForm ? {
    label: 'Bio Updates',
    id: 'bioUpdates',
    value: [
    // permission to send out emails
    {
      keyword: 'can_notify_for_updates',
      ruby_client: 3,
      action: '*'
    }
    // permission to retrieve attorneys
    , {
      model: "content",
      template: attorneyTemplateId,
      ruby_client: 3,
      subsite: 1,
      action: "get"
    }]
  } : null;
}