"use strict";

const _excluded = ["email"];
function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }
function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
const _ = require('lodash');
const queryString = require('query-string');
const CONSTANTS = require('../../common/constants');
const {
  COMPONENT_NAME
} = CONSTANTS;
const mceCommand = `mce${COMPONENT_NAME}`;

//# module will self register

// Load plugin specific language pack
tinymce.PluginManager.requireLangPack(COMPONENT_NAME);

//# TOOD: figure out how to load the languages

const $ = require('jquery');
const REFERENCE_MODEL_KEY = "emaillink";
tinymce.create(`tinymce.plugins.${COMPONENT_NAME}`, {
  _editor: null,
  _rubyApp: null //{childrenByKey: {}}
  ,
  _selfModule: null,
  _selectedNode: null,
  _selectedNodeSpec: null // the mailto: data parsed, to be used as formData for dialog
  ,
  _augment_text: null //# TODO: what is augment text used for?

  /* ************************************************************
     ** BEGIN: FRAMEWORK METHODS
     ********************************************************** */

  /**
    * Initializes the plugin, this will be executed after the plugin has
    * been created.  This call is done before the editor instance has
    * finished it's initialization so use the onInit event of the editor
    * instance to intercept that event.
    * @param {tinymce.Editor} ed Editor instance that the plugin is
    *                                   initialized in.
    * @param {string} url Absolute URL to where the plugin is located.
    */,
  init: function (ed, url) {
    var self = this;

    //https://webpack.js.org/concepts/loaders/#inline
    //# !! - ignore configured loaders
    //const envelopeSVG = require('!!svg-inline-loader?classPrefix!font-awesome-svg-png/black/svg/envelope-o.svg')
    let envelopeSVG = require('font-awesome-svg-png/black/svg/envelope-o.svg?raw');
    //# fix width and height
    envelopeSVG = envelopeSVG.replace('width="1792"', 'width="20"');
    envelopeSVG = envelopeSVG.replace('height="1792"', 'height="20"');
    ed.ui.registry.addIcon('email', envelopeSVG);
    this._editor = ed;
    this._rubyApp = ed.settings.rubyApp;
    this._selfModule = this._rubyApp.childrenByKey[COMPONENT_NAME];

    // Register the command so that it can be invoked by using
    // tinyMCE.activeEditor.execCommand('mceRubyAppMediaManager');
    ed.addCommand(mceCommand, function () {
      self._handlePickerButtonClick();
    });

    // Register the image gallery button
    ed.ui.registry.addButton(COMPONENT_NAME, {
      //title: 'Insert Email Link',
      tooltip: 'Insert Email Link',
      icon: 'email',
      onAction: () => {
        return ed.execCommand(mceCommand);
      },
      onSetup: api => {
        const controlManager = api;
        const editorNodeChange = e => {
          return self._handleNodeChange(ed, controlManager, e.element);
        };
        ed.on('NodeChange', editorNodeChange);
        return api => {
          ed.off('NodeChange', editorNodeChange);
        };
      }
    });

    //ed.settings.rubyComponent.actions to get the rubyComponent actions

    //# set options on the rubyComponent
    this._selfModule.getStore().dispatch(this._selfModule.getAction().generators.setOptions({
      onSelect: this._onSelect.bind(this)
    }));
  } // init

  /**
    * Creates control instances based in the incomming name. This method is
    * normally not needed since the addButton method of the tinymce.Editor
    * class is a more easy way of adding buttons but you sometimes need to
    * create more complex controls like listboxes, split buttons etc then
    * this method can be used to create those.
    *
    * @param {String} n Name of the control to create.
    * @param {tinymce.ControlManager} cm Control manager to use inorder to
    *                                    create new control.
    * @return {tinymce.ui.Control} New control instance or null if no
    *                              control was created.
    */,
  createControl: function (n, cm) {
    return null;
  }

  /**
    * Returns information about the plugin as a name/value array.
    * The current keys are longname, author, authorurl, infourl and version.
    *
    * @return {Object} Name/value array containing info about the plugin.
    */,
  getInfo: function () {
    return {
      longname: 'RubyApp Email Link Manager plugin',
      author: 'Howard Yee (Rubenstein Technology Group)',
      authorurl: 'http://www.rubensteintech.com',
      version: "1.0"
    };
  } // getInfo

  /* ************************************************************
     ** BEGIN: INITIALIZATION METHODS
     ********************************************************** */

  /* ************************************************************
     ** BEGIN: EVENT HANDLER METHODS
     ********************************************************** */,

  _handleNodeChange: function (editor, controlManager, node) {
    // Reset some global data
    this._augment_text = '';
    this._selectedNode = node;
    this._selectedNodeSpec = null;
    const $node = $(node);

    //<a href="mailto:howard@email.com?subject=SUBJECT&amp;body=BODY%20TEST"></a>

    const href = $node.attr('href');
    const isMailTo = /^mailto:/.test(href);
    const isHighlighted = editor.selection.getContent().length;
    if (isHighlighted) {
      controlManager.setDisabled(false);
    } else {
      controlManager.setDisabled(true);
    }
    if (isMailTo && isHighlighted) {
      const match = /^mailto:(.+?@[^?]+)(\?.*)?$/.exec(href);
      const mailtoSpec = _objectSpread({
        email: match[1]
      }, _.mapValues(match[2] ? queryString.parse(match[2]) : {}, decodeURIComponent));
      this._selectedNodeSpec = mailtoSpec;

      //# don't set active because this is only used by toggleButton
      //controlManager.setActive( true );

      return;
    }
    //controlManager.setActive( false );
  } // _handleNodeChange
  ,

  _handlePickerButtonClick: function () {
    var self = this;
    self._storeSelection();
    self._openDialog();
    return true;
  } // _handleRubyAppMediaManagerButtonClick
  ,

  _onSelect: function (data) {
    const editor = this._editor;
    const selectedNode = editor.selection.getNode();
    const {
      _selfModule: selfModule
    } = this;
    const {
        email
      } = data,
      mailtoParams = _objectWithoutProperties(data, _excluded);

    //# TODO: why are we selecting the entire node?
    //# NOTE: the old image picker did this
    // Select the entire link
    if (editor.selection.getNode().nodeName == 'A') {
      editor.selection.select(editor.selection.getNode());
    }
    this._restoreSelection();
    let $embeddable;
    const selectedMarkup = editor.selection.getContent() || '';

    // If text has been selected, link to the Media.
    // Otherwise, embed the Media directly in the text.
    if (selectedMarkup) {
      // NOTE: the <div> tags are a hack. Without them, the text
      // of HTML strings such as "hello <em>world</em>" return an
      // empty string instead of the expected "hello world".
      // The <div> guarantees that there is always a valid wrapping
      // element to retrieve text from.
    } else {
      $embeddable = $(embeddableMarkup);
    }
    const embeddableText = $(`<div>${selectedMarkup}</div>`).text();
    const mailto_queryString = queryString.stringify(_.omitBy(mailtoParams, _.isNil));
    const href = `mailto:${email}` + (mailto_queryString ? `?${mailto_queryString}` : '');
    $embeddable = $(`<a href="${href}">${embeddableText}</a>`);
    editor.execCommand('mceInsertContent', false, $('<div>').append($embeddable).html(), {
      skip_undo: 1
    });
    editor.undoManager.add();
    editor.execCommand('mceRepaint');
    this._closeDialog();
  }

  /* ************************************************************
     ** BEGIN: HELPER METHODS
     ********************************************************** */,

  _closeDialog: function () {
    const {
      _selfModule: selfModule
    } = this;
    const {
      generators: actions
    } = selfModule.getAction();
    selfModule.getStore().dispatch(actions.closeDialog());
    /*
    selfModule.getStore().dispatch(
        this._selectedNodeSpec?
            actions.openAndUpdate_withOptions(this._selectedNodeSpec)
            :actions.openWithOptions()
    );
    */

    return;
  },
  _openDialog: function () {
    const {
      _selfModule: selfModule
    } = this;
    const {
      generators: actions
    } = selfModule.getAction();
    selfModule.getStore().dispatch(actions.openDialog({
      defaultFormData: this._selectedNodeSpec,
      onSubmit: (formData, successCb, errorCb) => {
        this._onSelect(formData);
      }
    }));
    /*
    selfModule.getStore().dispatch(
        this._selectedNodeSpec?
            actions.openAndUpdate_withOptions(this._selectedNodeSpec)
            :actions.openWithOptions()
    );
    */

    return;
  } // _openDialog

  /* ************************************************************
     ** BEGIN: tinyMCE HELPER METHODS
     **   (copied from tinyMCE's Popup.js)
     ********************************************************** */,

  _getLang: function (n, dv) {
    return tinymce.activeEditor.getLang(n, dv);
  },
  _getTinyMCEParam: function (n, dv) {
    return tinymce.activeEditor.getParam(n, dv);
  },
  _storeSelection: function () {
    tinymce.activeEditor.windowManager.bookmark = tinymce.activeEditor.selection.getBookmark(1);
  },
  _restoreSelection: function () {
    if (tinymce.isIE) tinymce.activeEditor.selection.moveToBookmark(tinymce.activeEditor.windowManager.bookmark);
  }
}); // tinymce.create

// Register plugin
tinymce.PluginManager.add(COMPONENT_NAME, tinymce.plugins[COMPONENT_NAME]);
module.exports = {
  pluginName: COMPONENT_NAME
};