'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = _interopRequireDefault(require("react"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
class RowDefinition extends _react.default.Component {
  render() {
    return null;
  }
}
RowDefinition.propTypes = {
  //Children can be either a single column definition or an array
  //of column definition objects
  //TODO: get this prop type working again
  /*children: React.PropTypes.oneOfType([
    React.PropTypes.instanceOf(ColumnDefinition),
    React.PropTypes.arrayOf(React.PropTypes.instanceOf(ColumnDefinition))
  ]),*/
  //The column value that should be used as the key for the row
  //if this is not set it will make one up (not efficient)
  keyColumn: _react.default.PropTypes.string,
  //The column that will be known used to track child data
  //By default this will be "children"
  childColumnName: _react.default.PropTypes.string,
  //This property allows an to set a css class on a row based on
  //the data within. This should return a css-class name
  cssFunction: _react.default.PropTypes.func
};
var _default = RowDefinition;
exports.default = _default;