"use strict";

const request = require('@rubyapps/ruby-superagent');
const path = require('path');
const urljoin = require('url-join');
function typesWithID(id) {
  return {
    SET_FIELD: `@@ruby-app/${id}/SET_FIELD` //# DEPRECATED: 20170316
    ,
    SET_DOMAIN: `@@ruby-app/${id}/SET_DOMAIN`
    /*
    , SET_CACHED_URL_IDENTIFIER: `@@ruby-app/${id}/SET_CACHED_URL_IDENTIFIER`
    , SET_CACHED_BROWSER_PAGE_TITLE: `@@ruby-app/${id}/SET_CACHED_BROWSER_PAGE_TITLE`
    */,
    SET_GENERATED_URL: `@@ruby-app/${id}/SET_GENERATED_URL`
  };
}
const generators = {
  setField_withValue: function (value) {
    const actions = this.getAction().generators;
    return dispatch => {
      dispatch(actions.setFieldValueByKey(value, this.props.key));
    };
    return {
      type: TYPES.SET_FIELD,
      payload: {
        value
      }
    };
  },
  retrieveURLForPage_withID_andRubyClientFK: function (pageId, rubyClientFK) {
    const {
      TYPES,
      generators: actions
    } = this.getAction();
    const RESOURCE_LOCATOR_GET_URL_ENDPOINT = '/ruby/api/resource-locator/generate_url.json';
    return (dispatch, getState) => {
      return request.get(RESOURCE_LOCATOR_GET_URL_ENDPOINT).query({
        ruby_client_fk: rubyClientFK,
        id: pageId,
        fqdn: 1
      }).then(function success(response) {
        if (response.body.data) {
          const url = new URL(response.body.data);
          dispatch(actions.setDomain(url.hostname));
          dispatch(actions.setURL(url.pathname));
        } else {
          dispatch(actions.setURL(null));
        }
      }, function error(err) {
        dispatch(actions.setURL(null));
        console.warn({
          error: err
        });
      }).catch(err => {
        dispatch(actions.setURL(null));
        console.warn({
          error: err
        });
      });
    };
  },
  setDomain: function (domain) {
    const {
      TYPES,
      generators
    } = this.getAction();
    return {
      type: TYPES.SET_DOMAIN,
      payload: {
        value: domain
      }
    };
  },
  setURL: function (url) {
    const {
      TYPES,
      generators
    } = this.getAction();
    return {
      type: TYPES.SET_PAGE_URL,
      payload: {
        value: url
      }
    };
  },
  setGeneratedUrl: function (generatedUrl) {
    const {
      TYPES,
      generators
    } = this.getAction();
    return {
      type: TYPES.SET_GENERATED_URL,
      payload: {
        value: generatedUrl
      }
    };
  }
};
module.exports = function () {
  return {
    TYPES: typesWithID(this.getID()),
    generators
  };
};