"use strict";

const typesFromID = id => ({
  PIN_MENU: `@@ruby-app/${id}/PIN_MENU`,
  UNPIN_MENU: `@@ruby-app/${id}/UNPIN_MENU`,
  REQUEST_MENU_OPEN_CHANGE: `@@ruby-app/${id}/REQUEST_MENU_OPEN_CHANGE`
});
const generators = {
  pinMenu: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.PIN_MENU
    };
  },
  unpinMenu: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.UNPIN_MENU
    };
  },
  toggleMenuPin: function () {
    const {
      generators: actions
    } = this.getAction();
    const selfState = this.getState();
    if (selfState.menuIsPinned) {
      return actions.unpinMenu();
    } else {
      return actions.pinMenu();
    }
  },
  requestMenuOpenChange: function (requestedOpenStatus) {
    const {
      TYPES,
      generators: actions
    } = this.getAction();
    return {
      type: TYPES.REQUEST_MENU_OPEN_CHANGE,
      payload: requestedOpenStatus
    };
  }
};
module.exports = function () {
  const TYPES = typesFromID(this.getID());
  return {
    TYPES,
    generators
  };
};