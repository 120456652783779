"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.collapseRow_withIdxPath = collapseRow_withIdxPath;
exports.expandRow_withIdxPath = expandRow_withIdxPath;
exports.makeRerankRequest = makeRerankRequest;
exports.moveRow_fromIdxPath_toIdxPath = moveRow_fromIdxPath_toIdxPath;
exports.moveRow_withIdToFirstChildOfRow_withId = moveRow_withIdToFirstChildOfRow_withId;
exports.moveRow_withId_toIdxPath = moveRow_withId_toIdxPath;
exports.onBeginDrag = onBeginDrag;
var _lodash = _interopRequireDefault(require("lodash"));
var types = _interopRequireWildcard(require("./constants"));
var _storeUtil = require("./util/store-util");
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function makeRerankRequest(store, movedRow, events, monitoredItem) {
  const movedRowId = movedRow.rowData.id;
  const sitemapData = store.getState().get('data').toJS();
  const {
    originalIndexPath
  } = monitoredItem;

  //# NOTE: movedRow.rowData is stateful and the indexPath isn't guaranteed to have been updated by the time makeRerankRequest gets called
  const newIndexPath = monitoredItem.indexPath || movedRow.rowData.__metadata.indexPath;
  const movedRowNewIdx = newIndexPath.slice(-1)[0];
  const parentPath = newIndexPath.slice(0, -1);
  const pathToSiblingsInSitemapData = parentPath.reduce((res, curr) => res.concat([curr, 'children']), []);
  const siblings = _lodash.default.get(sitemapData, pathToSiblingsInSitemapData, sitemapData);
  const keyPath = _lodash.default.flatMap(parentPath.map((parentPathComponent, idx) => {
    return idx === 0 ? parentPathComponent : ['children', parentPathComponent];
  }));
  const parentObject = _lodash.default.get(sitemapData, keyPath);

  //# NOTE: movedRow.rowData is stateful and the parent_id isn't guaranteed to have been updated by the time makeRerankRequest gets called
  //# instead, we should look at newIndexPath and then select the row based on newIndexPath.slice(0, -1);
  const parentId = parentObject ? parentObject.id : null;
  const precedingRowId = _lodash.default.get(siblings, [movedRowNewIdx - 1, 'id']);
  const followingRowId = _lodash.default.get(siblings, [movedRowNewIdx + 1, 'id']);
  return dispatch => {
    (0, _storeUtil.getDndProvider)(store).onDrop(store, movedRowId, precedingRowId, followingRowId, parentId, events);
  };
}
function onBeginDrag(store) {
  return dispatch => {
    (0, _storeUtil.getDndProvider)(store).onBeginDrag();
  };
}
function expandRow_withIdxPath(idxPath) {
  return {
    type: types.GRIDDLE_EXPAND_ROW,
    indexPath: idxPath
  };
}
function collapseRow_withIdxPath(idxPath) {
  return {
    type: types.GRIDDLE_COLLAPSE_ROW,
    indexPath: idxPath
  };
}
function moveRow_withIdToFirstChildOfRow_withId(moveId, id) {
  return {
    type: types.GRIDDLE_MOVE_ROW_WITH_ID_TO_CHILD_OF_ROW_WITH_ID,
    moveId,
    targetId: id
  };
}
function moveRow_withId_toIdxPath(id, toIdxPath) {
  return {
    type: types.GRIDDLE_MOVE_ROW_WITH_ID,
    id: id,
    toIdxPath: toIdxPath
  };
}
function moveRow_fromIdxPath_toIdxPath(fromIdxPath, toIdxPath) {
  return {
    type: types.GRIDDLE_MOVE_ROW,
    fromIdxPath: fromIdxPath,
    toIdxPath: toIdxPath
  };
}