"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const styles = {
  RaisedButton: {
    marginRight: '10px'
  }
};
var _default = styles;
exports.default = _default;