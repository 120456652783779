"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = sitemapConnectorGenerator;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _RecentUpdates = _interopRequireDefault(require("./RecentUpdates"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); } //const rubyLogger = require('@rubyapps/ruby-logger');
//const logger = rubyLogger.getLogger('ruby-component-sitemap-page:reactComponents/SitemapConnector');
function sitemapConnectorGenerator() {
  const selfModule = this;
  const selfModuleID = selfModule.getID();
  const {
    selfSelector,
    selfActions,
    feSettingsSelector,
    forms,
    currentUserSelector
  } = selfModule.getDependencies();
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const feSettings = feSettingsSelector(state);
    const rubyUser = currentUserSelector(state);
    return _extends({
      rubyUser,
      forms,
      selfModule
    }, ownProps, selfState);
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfActions, dispatch)
    };
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_RecentUpdates.default);
}