"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const defaultState = {
  subsite: 'en',
  template: 'homepage'
};
module.exports = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case '@@ruby-app/UPDATE_GLOBAL_ROUTE_PARAMS':
      return _extends({}, state, action.payload);
    default:
      return state;
  }
};