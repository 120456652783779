"use strict";

var _matchConstants = _interopRequireDefault(require("../matchConstants"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const _ = require('lodash');
/*
   {
       "id": 1,
       "status": "active",
       "context": "matter",
       "name": "Saved Search A",
       "query": "",
       "filterTags": [{
           "fieldSpec": {
               "key": "name",
               "label": "Name",
               "dataPath": "name",
               "refKey": "module_a" //# the same refKey as returned by RubySchema.pickProperties(),
               "foundIn__templateKey": "matter",
               "nestingOf": undefined,
               "parentDataPath": undefined
               "parentTemplateKey": undefined
           },
           "matchSpec": {
               "text": "contains",
               "value": "contains"
           },
           "searchSpec": {
               "text": "rubytest",
               "value": "rubytest"
           }
       }],
       "created_timestamp": "2017-07-12T00:00:00.000Z",
       "last_modified_timestamp": "2017-08-03T02:20:14.688Z"
   }
*/
/*
   {
       [f]: {
           simpleqs: searchText
       } : {
       [f]: {
           like: searchText
           , options: {
               operator: 'and'
               , fuzziness: 'AUTO'
           }
       }
   };
*/ //https://loopback.io/doc/en/lb2/Where-filter.html#inq
function _filterSpecForDataPath_matching_value(dataPath, matching, value, refKey) {
  let filter;
  switch (matching) {
    case _matchConstants.default.IS: //# NOTE: keeping this here for backwards compatiblity
    //# but this has been DEPRECATED in favor of 'CONTAINS'
    case _matchConstants.default.CONTAINS:
    case _matchConstants.default.IS_EQUAL_TO:
      //# Number
      filter = {
        [dataPath]: value
      };
      break;
    case _matchConstants.default.CONTAINS_ALL_OF:
      const andFilterArray = _.chain(value).castArray().map(val => ({
        [dataPath]: val
      })).value();
      filter = {
        and: andFilterArray
      };
      break;
    case _matchConstants.default.IS_ONE_OF:
      const valueAsArray = value ? _.castArray(value) : [];
      if (valueAsArray.length == 1) {
        filter = {
          [dataPath]: valueAsArray[0]
        };
      } else {
        filter = {
          [dataPath]: {
            inq: valueAsArray
          }
        };
      }
      break;
    case _matchConstants.default.IS_NOT:
    case _matchConstants.default.DOES_NOT_CONTAIN:
    case _matchConstants.default.IS_NOT_EQUAL_TO:
      //# Number
      filter = {
        [dataPath]: {
          neq: value
        }
      };
      break;
    case _matchConstants.default.IS_EMPTY:
      filter = {
        [dataPath]: null
      };
      break;
    case _matchConstants.default.IS_NOT_EMPTY:
      filter = {
        [dataPath]: {
          neq: null
        }
      };
      break;

    //# Array
    case _matchConstants.default.IS_EMPTY__ARRAY:
      filter = {
        or: [{
          [dataPath]: null
        }, {
          [dataPath]: []
        }]
      };
      break;
    case _matchConstants.default.IS_NOT_EMPTY__ARRAY:
      filter = {
        and: [{
          [dataPath]: {
            neq: null
          }
        }, {
          [dataPath]: {
            neq: []
          }
        }]
      };
      break;

    //# Boolean
    case _matchConstants.default.IS_TRUE:
      filter = {
        [dataPath]: true
      };
      break;
    case _matchConstants.default.IS_FALSE:
      filter = {
        or: [{
          [dataPath]: false
        }, {
          [dataPath]: null
        }]
      };
      break;

    //# Date
    case _matchConstants.default.IS_EQUAL_TO__DATE:
      if (_.isObject(value)) {
        if (value.hasOwnProperty('datetime')) {
          //# doesn't need to match both timeZone and datetime
          //# because the datetime is normalized with respect to UTC

          filter = {
            [`${dataPath}.datetime`]: value.datetime
          };
        } else if (value.hasOwnProperty('date')) {
          filter = {
            [`${dataPath}.date`]: value.date
          };
        } else if (value.hasOwnProperty('time')) {
          //# need to match both timeZone and time
          //# this cannot be normalized because DST is different
          //# based on time of year

          filter = {
            and: [{
              [`${dataPath}.time`]: value.time
            }, {
              [`${dataPath}.timeZone`]: value.timeZone
            }]
          };
        }
      } else {
        filter = {
          [dataPath]: value
        };
      }
      break;
    case _matchConstants.default.IS_NOT_EQUAL_TO__DATE:
      if (_.isObject(value)) {
        if (value.hasOwnProperty('datetime')) {
          filter = {
            [`${dataPath}.datetime`]: {
              neq: value.datetime
            }
          };
        } else if (value.hasOwnProperty('date')) {
          filter = {
            [`${dataPath}.date`]: {
              neq: value.date
            }
          };
        } else if (value.hasOwnProperty('time')) {
          filter = {
            and: [{
              [`${dataPath}.time`]: {
                neq: value.time
              }
            }, {
              [`${dataPath}.timeZone`]: {
                neq: value.timeZone
              }
            }]
          };
        }
      } else {
        filter = {
          [dataPath]: {
            neq: value
          }
        };
      }
      break;
    case _matchConstants.default.IS_AFTER__DATE:
      if (_.isObject(value)) {
        if (value.hasOwnProperty('datetime')) {
          filter = {
            [`${dataPath}.datetime`]: {
              gt: value.datetime
            }
          };
        } else if (value.hasOwnProperty('date')) {
          filter = {
            [`${dataPath}.date`]: {
              gt: value.date
            }
          };
        } else if (value.hasOwnProperty('time')) {
          filter = {
            and: [{
              [`${dataPath}.time`]: {
                gt: value.time
              }
            }, {
              [`${dataPath}.timeZone`]: value.timeZone
            }]
          };
        }
      } else {
        filter = {
          [dataPath]: {
            gt: value
          }
        };
      }
      break;
    case _matchConstants.default.IS_BEFORE__DATE:
      if (_.isObject(value)) {
        if (value.hasOwnProperty('datetime')) {
          filter = {
            [`${dataPath}.datetime`]: {
              lt: value.datetime
            }
          };
        } else if (value.hasOwnProperty('date')) {
          filter = {
            [`${dataPath}.date`]: {
              lt: value.date
            }
          };
        } else if (value.hasOwnProperty('time')) {
          filter = {
            and: [{
              [`${dataPath}.time`]: {
                lt: value.time
              }
            }, {
              [`${dataPath}.timeZone`]: value.timeZone
            }]
          };
        }
      } else {
        filter = {
          [dataPath]: {
            lt: value
          }
        };
      }
      break;

    //# Number
    case _matchConstants.default.IS_GREATER_THAN:
      //# Number
      filter = {
        [dataPath]: {
          gt: value
        }
      };
      break;
    case _matchConstants.default.IS_LESS_THAN:
      //# Number
      filter = {
        [dataPath]: {
          lt: value
        }
      };
      break;
    case _matchConstants.default.IS_GREATER_THAN_OR_EQUAL_TO:
      //# Number
      filter = {
        [dataPath]: {
          gte: value
        }
      };
      break;
    case _matchConstants.default.IS_LESS_THAN_OR_EQUAL_TO:
      //# Number
      filter = {
        [dataPath]: {
          lte: value
        }
      };
      break;
    case _matchConstants.default.IS_EMPTY__NUMBER:
    case _matchConstants.default.IS_EMPTY__STRING:
      //# String
      filter = {
        or: [{
          [dataPath]: null
        }, {
          [dataPath]: ''
        }]
      };
      break;
    case _matchConstants.default.IS_NOT_EMPTY__NUMBER:
    case _matchConstants.default.IS_NOT_EMPTY__STRING:
      //# String
      filter = {
        and: [{
          [dataPath]: {
            neq: null
          }
        }, {
          [dataPath]: {
            neq: ''
          }
        }]
      };
      break;

    //# String
    case _matchConstants.default.CONTAINS__STRING:
      filter = {
        [dataPath]: {
          //like: value
          simpleqs: value
          /* //# allow connector-cb-es to handle defaulting the operator
          , options: {
              operator: 'and'
              //, fieldSuffix: '.search'
          }
          */
        }
      };

      break;
    case _matchConstants.default.DOES_NOT_CONTAIN__STRING:
      filter = {
        [dataPath]: {
          //nlike: value
          nsimpleqs: value
        }
      };
      break;
  }
  return filter;
}

/*
 *  filterTags_byMatch = {
 *      "is_greater_than": [
 *          {... filter tag ...}
 *          , {... filter tag ...}
 *      ]
 *      , "is_less_than": [
 *          {... filter tag ...}
 *          , {... filter tag ...}
 *      ]
 *  }
 */
function _reduceFilterTagsByMatch_forDataPath(filterTags_byMatch, dataPath) {
  return _.reduce(filterTags_byMatch, (collector, filterTagCollection, match) => {
    const dataPathForLB = dataPath; //# return raw dataPath; do not strip out wildcard

    const filters = filterTagCollection.map(filterTag => {
      const matchSpec__value = filterTag.matchSpec.value;
      const searchSpec__value = filterTag.searchSpec.value;
      const fieldSpec__refKey = filterTag.fieldSpec.refKey;
      return _filterSpecForDataPath_matching_value(dataPathForLB, matchSpec__value, searchSpec__value, fieldSpec__refKey);
    });
    if (filters.length > 1) {
      //# TODO Currently `and` operator would only be used when
      //# `match === CONTAINS_ALL_OF`. In the future, this could
      //# change, so we'll need a better way to determine what
      //# value `filtersWrapperOperator` should be
      const filtersWrapperOperator = match === _matchConstants.default.CONTAINS_ALL_OF ? 'and' : 'or';
      collector.push({
        [filtersWrapperOperator]: filters
      });
    } else {
      collector.push(filters[0]);
    }
    return collector;
  }, []);
}

/*
 *  filterTags_byDataPath_byMatch = {
 *      "dataPath": {
 *          "is": [
 *              {... filter tag ...}
 *              , {... filter tag ...}
 *          ]
 *      }
 *  }
 */

function filterTagsToWhereFilter(filterTagsTree) {
  const nestedFilterTagNodes = [];
  const filterTags_byDataPath_byMatch = filterTagsTree.reduce((collector, filterTagTreeNode) => {
    const {
      children = []
    } = filterTagTreeNode;
    if (!_.isEmpty(children)) {
      nestedFilterTagNodes.push(filterTagTreeNode);
      return collector;
    }
    const filterTag__dataPath = dataPathExcludingParentPathFromParentTemplateKey_fromFilterTag(filterTagTreeNode);
    const filterTag__match = filterTagTreeNode.matchSpec.value;
    const pathArr = [filterTag__dataPath, filterTag__match];
    _.set(collector, pathArr, _.get(collector, pathArr, []).concat(filterTagTreeNode));
    return collector;
  }, {});
  const whereFilterArr = _.reduce(filterTags_byDataPath_byMatch, (collector, filterTags_byMatch, dataPath) => {
    return collector.concat(_reduceFilterTagsByMatch_forDataPath(filterTags_byMatch, dataPath));
  }, []).concat(_.flatMap(nestedFilterTagNodes, nestedFilterTagNode => {
    const {
      children = []
    } = nestedFilterTagNode;
    const childWithNestingOfProperty = children.find(child => _.get(child, 'fieldSpec.nestingOf'));
    const nestingField = _.get(childWithNestingOfProperty, 'fieldSpec.nestingOf');
    return {
      [nestingField]: {
        nested: {
          where: filterTagsToWhereFilter(children)
        }
      }
    };
  }));
  const finalWhereFilter = whereFilterArr.length > 1 ? {
    and: whereFilterArr
  } : whereFilterArr[0];
  return finalWhereFilter;
}
function dataPathExcludingParentPathFromParentTemplateKey_fromFilterTag(filterTag) {
  const dataPath = _.get(filterTag, 'fieldSpec.dataPath');
  const dataPathPartials = dataPath.split('.');
  const parentTemplateKey = _.get(filterTag, 'fieldSpec.parentTemplateKey');
  if (!parentTemplateKey) {
    return dataPath;
  }
  const parentDataPath = _.get(filterTag, 'fieldSpec.parentDataPath');
  const parentDataPathPartials = parentDataPath ? parentDataPath.split('.') : [];
  const dataPathArrayWithoutParentPathPartials = dataPathPartials.reduce((acc, partial, idx) => {
    return dataPathPartials[idx] !== parentDataPathPartials[idx] ? acc.concat(partial) : acc;
  }, []);
  return dataPathArrayWithoutParentPathPartials.join('.');
}
function filterTagsTreeFromFilterTagsAndTemplateKeyword(filterTags, templateKeyword) {
  const filterTagsByTemplate = _.groupBy(filterTags, filterTag => _.get(filterTag, 'fieldSpec.foundIn__templateKey'));
  const filterTagsForTemplate = filterTagsByTemplate[templateKeyword] || [];
  const filterTagsForUndefined = filterTagsByTemplate[undefined] || []; //# foundIn__templateKey might be undefined

  //# Only process nestedFilterTags if a templateKeyword is provided
  //# otherwise, we assume tags are not nested
  const nestedFilterTags = templateKeyword ? filterTags.filter(filterTag => {
    const parentTemplateKey = _.get(filterTag, 'fieldSpec.parentTemplateKey');
    return parentTemplateKey === templateKeyword;
  }) : [];
  const nestedTemplateKeywords = _.chain(nestedFilterTags).map(nestedFilterTag => _.get(nestedFilterTag, 'fieldSpec.foundIn__templateKey')).uniq().value();
  const filterTagsTree = [].concat(filterTagsForTemplate, filterTagsForUndefined, nestedTemplateKeywords.reduce((acc, nestedTemplateKeyword) => {
    return acc.concat({
      children: filterTagsTreeFromFilterTagsAndTemplateKeyword(filterTags, nestedTemplateKeyword)
    });
  }, []));
  return filterTagsTree;
}
function filterTagsToWhereFilterUsingTemplateKeyword(filterTags, templateKeyword) {
  //# NOTE: if templateKeyword is undefined, assume it's wildcard
  //# since only listers like quick-find would pass an undefined

  const filterTagsTree = templateKeyword ? filterTagsTreeFromFilterTagsAndTemplateKeyword(filterTags, templateKeyword) : filterTags;
  const whereFilter = filterTagsToWhereFilter(filterTagsTree);
  return whereFilter;
}
module.exports = {
  filterTagsToWhereFilter,
  filterTagsToWhereFilterUsingTemplateKeyword,
  filterTagsTreeFromFilterTagsAndTemplateKeyword
};