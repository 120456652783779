"use strict";

const request = require('@rubyapps/ruby-superagent');
const rubyNotificationsComponent = require('@rubyapps/ruby-component-notifications');
function typesWithID(id) {
  return {};
}
const generators = {
  getSecret: function () {
    const {
      generators
    } = this.getAction();
    return (dispatch, getState) => {
      const endpoint = this.determineSecretEndpoint();
      request.get(endpoint).then(res => {
        const secret = res.body;
        dispatch(generators.setSecret(secret));
      }, err => {
        // TODO: show error modal
      });
    };
  },
  generateSecret: function () {
    const self = this;
    const {
      generators
    } = this.getAction();
    return (dispatch, getState) => {
      const endpoint = this.determineSecretEndpoint();
      request.post(endpoint).then(res => {
        const secret = res.body;
        dispatch(generators.setSecret(secret));
      }, err => {
        let notificationMethod = self.showNotificationModal.bind(self);
        let type = rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.ERROR;
        let title = 'API Secret Generation Error';
        let message = 'An unexpected error was encountered while generating a secret. ' + 'Please try again later or contact Support.';
        if (err.status === 403) {
          message = 'You do not have the permission to generate a secret.';
          notificationMethod = self.pushNotification.bind(self);
        }
        notificationMethod({
          title,
          message,
          type
        });
      });
    };
  },
  removeSecret: function () {
    const self = this;
    const {
      generators
    } = this.getAction();
    return (dispatch, getState) => {
      const endpoint = this.determineSecretEndpoint();
      request.del(endpoint).then(res => {
        dispatch(generators.setSecret(null));
      }, err => {
        let notificationMethod = self.showNotificationModal.bind(self);
        let type = rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.ERROR;
        let title = 'API Secret Invalidation Error';
        let message = 'An unexpected error was encountered while invalidating the secret. ' + 'Please try again later or contact Support.';
        if (true || err.status === 403) {
          message = 'You do not have the permission to invalidate the secret.';
          notificationMethod = self.pushNotification.bind(self);
        }
        notificationMethod({
          title,
          message,
          type
        });
      });
    };
  },
  setSecret: function (value) {
    const {
      generators
    } = this.getAction();
    const {
      key: fieldKey
    } = this.props;
    return (dispatch, getState) => {
      dispatch(generators.setFieldValueByKey(value, fieldKey));
    };
  }
};
module.exports = function () {
  return {
    TYPES: typesWithID(this.getID()),
    generators
  };
};