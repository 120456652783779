"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const {
  base_fieldInfo_byComponentName,
  propertySpecsByKey_withRubyPermissions
} = require('./fieldProperties');

//# field Props stuff that requires RubyApp context goes here
//# eg. moving 'fieldInfo_byComponentName' to here
module.exports = {
  fieldInfo_byComponentName: function () {
    const cached = this.getStatefulCacheForKey('fieldInfo_byComponentName');
    if (cached) {
      return cached;
    }
    const Components = require('@rubyapps/ruby-component-builder/src/common/components').default;
    const fieldInfo_byComponentName = _extends({}, base_fieldInfo_byComponentName, _.reduce(Components, (collector, component, componentKey) => {
      const fieldInfo = _.get(component, ['staticPropsByComponent', 'ruby-component-field-editor', 'fieldInfo']);
      if (fieldInfo) {
        collector[componentKey] = fieldInfo;
      }
      return collector;
    }, {}));
    this.setStatefulCacheForKey('fieldInfo_byComponentName', fieldInfo_byComponentName);
    return fieldInfo_byComponentName;
  },
  fieldTypeDisplayText_byType: function () {
    const cached = this.getStatefulCacheForKey('fieldTypeDisplayText_byType');
    if (cached) {
      return cached;
    }
    const fieldInfo_byComponentName = this.fieldInfo_byComponentName();
    const fieldTypeDisplayText_byType = _.reduce(fieldInfo_byComponentName, (result, typeArray, componentName) => {
      result[componentName] = fieldInfo_byComponentName[componentName].displayText;
      return result;
    }, {});
    this.setStatefulCacheForKey('fieldTypeDisplayText_byType', fieldTypeDisplayText_byType);
    return fieldTypeDisplayText_byType;
  },
  propertiesWithoutPermissions_byType: function () {
    const cached = this.getStatefulCacheForKey('propertiesWithoutPermissions_byType');
    if (cached) {
      return cached;
    }
    const propertiesWithoutPermissions_byType = this.propertiesByType_withRubyPermissions([]);
    this.setStatefulCacheForKey('propertiesWithoutPermissions_byType', propertiesWithoutPermissions_byType);
    return propertiesWithoutPermissions_byType;
  },
  propertiesWithoutPermissions_byKey: function () {
    const cached = this.getStatefulCacheForKey('propertiesWithoutPermissions_byKey');
    if (cached) {
      return cached;
    }
    const propertiesWithoutPermissions_byKey = propertySpecsByKey_withRubyPermissions([]);
    this.setStatefulCacheForKey('propertiesWithoutPermissions_byKey', propertiesWithoutPermissions_byKey);
    return propertiesWithoutPermissions_byKey;
  },
  propertiesByKey_withRubyPermissions: function (ruby_permissions) {
    return propertySpecsByKey_withRubyPermissions(ruby_permissions);
  },
  propertiesByType_withRubyPermissions: function (ruby_permissions) {
    const properties_byKey = propertySpecsByKey_withRubyPermissions(ruby_permissions);
    const fieldInfo_byComponentName = this.fieldInfo_byComponentName();
    return _.reduce(fieldInfo_byComponentName, (properties_byType, typeArray, componentName) => {
      properties_byType[componentName] = (fieldInfo_byComponentName[componentName].propertyKeys || []).map(propertyKey => properties_byKey[propertyKey]);
      return properties_byType;
    }, {});
  },
  coreProperties_byInstanceType: function () {
    const cached = this.getStatefulCacheForKey('coreProperties_byInstanceType');
    if (cached) {
      return cached;
    }
    const propertiesWithoutPermissions_byKey = this.propertiesWithoutPermissions_byKey();
    const coreProperties_byInstanceType = {
      'Tab': {
        label: propertiesWithoutPermissions_byKey.label,
        field_meta_locked: propertiesWithoutPermissions_byKey.field_meta_locked
      },
      'Fieldset': {
        label: propertiesWithoutPermissions_byKey.label,
        help_text: propertiesWithoutPermissions_byKey.help_text,
        field_meta_locked: propertiesWithoutPermissions_byKey.field_meta_locked
      },
      'Field': {
        label: propertiesWithoutPermissions_byKey.label,
        constraint_required: propertiesWithoutPermissions_byKey.constraint_required,
        field_meta_locked: propertiesWithoutPermissions_byKey.field_meta_locked,
        componentName: propertiesWithoutPermissions_byKey.componentName
      },
      'SubTemplate': {
        subtemplate: propertiesWithoutPermissions_byKey.subtemplate,
        field_meta_locked: propertiesWithoutPermissions_byKey.field_meta_locked
      }
    };
    this.setStatefulCacheForKey('coreProperties_byInstanceType', coreProperties_byInstanceType);
    return coreProperties_byInstanceType;
  }
};