"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
const defaultChildrenPropsByKeyFromProps = props => {
  const {
    mode,
    disabled,
    locked
  } = props;
  return {
    person_prefix_fk: {
      label: 'Prefix',
      key: 'person_prefix_fk',
      data_type: 'number'
      //, verify: {required: false}
      ,
      ruby_permissions: props.ruby_permissions

      // TODO: Do not hard code client/subsite
      ,
      url: '/ruby/api/v2/content/person_prefix/options?ruby_client_fk=3&content_subsite_fk=1',
      mode,
      disabled,
      locked,
      componentName: 'Dropdown'
    },
    first_name: {
      label: 'First Name',
      key: 'first_name',
      data_type: 'string',
      ruby_permissions: props.ruby_permissions
      //, verify: {required: true}
      ,
      mode,
      disabled,
      locked,
      componentName: 'Text'
    },
    middle_name: {
      label: 'Middle Name',
      key: 'middle_name',
      data_type: 'string',
      ruby_permissions: props.ruby_permissions
      //, verify: {required: false}
      ,
      mode,
      disabled,
      locked,
      componentName: 'Text'
    },
    last_name: {
      label: 'Last Name',
      key: 'last_name',
      data_type: 'string',
      ruby_permissions: props.ruby_permissions
      //, verify: {required: true}
      ,
      mode,
      disabled,
      locked,
      componentName: 'Text'
    },
    person_suffix_fk: {
      label: 'Suffix',
      key: 'person_suffix_fk',
      data_type: 'number',
      ruby_permissions: props.ruby_permissions

      // TODO: Do not hard code client/subsite
      ,
      url: '/ruby/api/v2/content/person_suffix/options?ruby_client_fk=3&content_subsite_fk=1',
      mode,
      disabled,
      locked,
      componentName: 'Dropdown'
    },
    asset_fk: {
      label: 'Image',
      componentName: "ImagePicker",
      key: 'asset_fk',
      data_type: 'number',
      ruby_permissions: props.ruby_permissions,
      constraints: {
        type: 'image'
      },
      mode,
      disabled,
      locked
    }
  };
};

//# take the opportunity here to set whether children values are required
const childrenFormFromProps = _ref => {
  let {
    childrenPropsByKey = {},
    verify = {}
  } = _ref;
  const {
    person_prefix_fk,
    first_name,
    middle_name,
    last_name,
    person_suffix_fk,
    asset_fk
  } = childrenPropsByKey;
  const {
    required
  } = verify;
  return [_objectSpread({
    componentName: "Dropdown"
  }, person_prefix_fk), _objectSpread({
    componentName: "Text",
    verify: {
      required
    }
  }, first_name), _objectSpread({
    componentName: "Text"
  }, middle_name), _objectSpread({
    componentName: "Text",
    verify: {
      required
    }
  }, last_name), _objectSpread({
    componentName: "Dropdown"
  }, person_suffix_fk), _objectSpread({
    componentName: "ImagePicker"
  }, asset_fk)];
};
module.exports = {
  defaultChildrenPropsByKeyFromProps,
  childrenFormFromProps
};