"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.makePlainUri = makePlainUri;
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function makePlainUri(uri) {
  if (!_lodash.default.isString(uri)) {
    return uri;
  }
  return uri.replace(/\/$/, '').replace(/\/index\.html$/, '').replace(/\.html$/, '');
}