"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
module.exports = function (selfModule) {
  const filteredTemplates = selfModule.possibleTemplateForms();
  const options = _lodash.default.sortBy(filteredTemplates.map(template => ({
    text: template.name,
    value: template.key
  })), ['text']);
  const templateOptions = {
    options,
    key: 'template_keyword',
    header: 'All Templates',
    headerValue: null
  };
  return [templateOptions];
};