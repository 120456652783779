"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
var _normalizr = require("normalizr");
var _bioUpdates = _interopRequireDefault(require("./bioUpdates"));
var _templateEditor = _interopRequireDefault(require("./templateEditor"));
var _docgen = _interopRequireDefault(require("./docgen"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const WEBSITES_PLUGIN__CONSTANTS = require('@rubyapps/ruby-component-plugin-websites/src/common/constants');
function getPermissionSpecForSelfModule(selfModule) {
  const {
    global_data: globalDataForm,
    rubyapps_settings: settingsForm
  } = selfModule.getForms();
  const globalDataTemplateId = globalDataForm ? globalDataForm.id : null;
  const settingsTemplateId = settingsForm ? settingsForm.id : null;
  if (!globalDataTemplateId) {
    console.warn("could not retrieve value for globalDataID");
  }
  if (!settingsTemplateId) {
    console.warn("could not retrieve value for settingsID");
  }
  const bioUpdatesPerm = (0, _bioUpdates.default)(selfModule);
  const templateEditorPerm = (0, _templateEditor.default)(selfModule);
  const docgenPerm = (0, _docgen.default)(selfModule);
  const rootModule = selfModule.getRoot();
  const modulesWithPermissions = rootModule.findDescendentsBy(node => node.hasOwnProperty('getPermissionItem'));

  //# hacky cause we don't want this module to know the existance of other plugins
  //# TODO: ideally, we want to implement something similar to the template augmentor mixins
  //# where each plugin can opt to modify the permission structure
  const websitesPlugin = rootModule.findDescendentByID(WEBSITES_PLUGIN__CONSTANTS.COMPONENT_NAME);
  const additionalPermsArr = [].concat.apply([], modulesWithPermissions.map(module => module.getPermissionItem()));
  return {
    id: 'root',
    children: [{
      label: 'RubyLaw',
      id: 'rubylaw',
      children: [].concat(websitesPlugin ? [] : [{
        label: 'Sitemap',
        id: 'sitemap',
        populateWithValueFor: 'sitemap',
        children: []
      }, {
        label: 'Modify Sitemap',
        id: 'modifySitemap',
        populateWithValueFor: 'modify_sitemap'
      }], {
        label: 'API Access - Old Media',
        id: 'accessMedia',
        value: {
          keyword: 'media_gallery',
          ruby_client: 3,
          action: '*'
        }
      }, globalDataTemplateId ? {
        label: 'Global Data',
        id: 'globalData',
        value: {
          model: 'content',
          template: globalDataTemplateId,
          ruby_client: 3,
          subsite: 1,
          action: '*'
        }
      } : [], settingsTemplateId ? {
        label: 'Settings',
        id: 'rubyappsSettings',
        value: {
          model: 'content',
          template: settingsTemplateId,
          ruby_client: 3,
          subsite: 1,
          action: '*'
        }
      } : []
      /*
      , {
          label: 'Override Content Locks'
          , id: 'overrideContentLocks'
          , value: { keyword: 'content.unlock', ruby_client: 3, action: '*' }
      }
      */, {
        label: 'Publish Content',
        id: 'publishContent',
        value: {
          keyword: 'can_publish_content',
          subsite: 1,
          ruby_client: 3,
          action: '*'
        }
      }, {
        label: 'Restricted Content',
        id: 'restrictedContent',
        value: {
          keyword: 'restricted_content',
          ruby_client: 3,
          action: '*'
        }
      }, {
        label: `Private Fields (API Access - Read)`,
        id: 'privateFields__api__read',
        value: {
          keyword: 'privateFields__api',
          action: 'read'
        }
      })
    }, {
      label: 'Sitemap Pages',
      id: 'sitemap',
      populateWithValueFor: 'sitemap',
      children: []
    }, {
      label: 'Structured Data',
      id: 'structuredData',
      populateWithChildrenFor: 'structured_data'
    }, {
      label: 'Lookups',
      id: 'lookups',
      populateWithChildrenFor: 'lookups'
    }].concat(additionalPermsArr
    //, proposalsPerm
    , {
      label: 'Access Administration',
      id: 'accessAdministration',
      children: [{
        label: 'Users',
        id: 'users',
        value: {
          model: 'user',
          action: '*'
        }
      }, {
        label: 'Roles',
        id: 'roles',
        value: {
          model: 'RubyRole',
          action: '*'
        }
      }, {
        label: 'Usage Logs',
        id: 'usageLogs',
        value: {
          model: "changelog",
          ruby_client: 3,
          action: "*"
        }
      }, {
        label: 'Share',
        id: 'share',
        value: {
          keyword: 'admin_share_section',
          ruby_client: 3,
          action: '*'
        }
      }, {
        label: 'Sitemap XML',
        id: 'sitemapXML',
        value: {
          keyword: 'admin_sitemap_section',
          ruby_client: 3,
          action: '*'
        }
      }].concat(bioUpdatesPerm ? bioUpdatesPerm : [], templateEditorPerm ? templateEditorPerm : [], docgenPerm ? docgenPerm : [])
    }, {
      label: 'Reports',
      id: 'reports',
      children: [{
        label: 'Content Reports',
        id: 'contentReports',
        value: {
          keyword: 'report_structured_data_section',
          ruby_client: 3,
          action: '*'
        }
      }]
    }, {
      label: 'Contact Support',
      id: 'contactSupport',
      value: {
        keyword: 'contact_support'
      }
    }, {
      label: 'Developer Tools',
      id: 'developerTools',
      children: [{
        label: "Optimization",
        id: "Optimization",
        value: {
          keyword: 'dev_tools__optimization'
        }
      }, {
        label: "API Tools",
        id: "API Tools",
        value: {
          keyword: 'dev_tools'
        }
      }]
    }, {
      label: 'Debugging Tools',
      id: 'debuggingTools',
      value: {
        keyword: 'debugging_tools'
      }
    })
  };
}
function getPermissionItemsByKeyForSelfModule(selfModule) {
  const permissionSpec = getPermissionSpecForSelfModule(selfModule);
  let nextId = 0;
  function generateId(entity, id) {
    const newId = _lodash.default.get(entity, 'id') || (nextId++).toString();
    return newId;
  }
  const permissionSchema = new _normalizr.Schema('permissions', {
    idAttribute: generateId
  });
  permissionSchema.define({
    children: (0, _normalizr.arrayOf)(permissionSchema)
  });
  const permissionItemsByKey = _lodash.default.get((0, _normalizr.normalize)(permissionSpec, permissionSchema), 'entities.permissions');
  return permissionItemsByKey;
}
module.exports = getPermissionItemsByKeyForSelfModule;