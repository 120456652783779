"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = initialState;
var _immutable = _interopRequireDefault(require("immutable"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function initialState(remoteProvider) {
  return _immutable.default.fromJS({
    totalItemCount: 0,
    loadsInProgress: 0,
    recentRequest: null,
    pagesLoaded: [],
    remoteProvider: remoteProvider
  });
}
;