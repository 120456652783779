"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.batchMoveSelectedIds_toRelativePosition_andReferenceId = batchMoveSelectedIds_toRelativePosition_andReferenceId;
exports.endBatchMove = endBatchMove;
exports.onBatchMove = onBatchMove;
exports.startBatchMove = startBatchMove;
var types = _interopRequireWildcard(require("./constants"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function startBatchMove() {
  return {
    type: types.GRIDDLE_BATCH_MOVE_START
  };
}
function endBatchMove() {
  return {
    type: types.GRIDDLE_BATCH_MOVE_END
  };
}

//# 20230112 - DEPRECATED should only use batchMoveSelectedIds_toRelativePosition_andReferenceId
/*
export function batchMoveSelectedIds_toInsideId(id) {
    return {
        type: types.GRIDDLE_BATCH_MOVE_SELECTED_INSIDE_ID
        , targetId: id
    }
}

export function batchMoveSelectedIds_toBeforeId(id) {
    return {
        type: types.GRIDDLE_BATCH_MOVE_SELECTED_TO_BEFORE_ID
        , targetId: id
    }
}
*/

function batchMoveSelectedIds_toRelativePosition_andReferenceId(relativePosition, referenceId) {
  return {
    type: types.GRIDDLE_BATCH_MOVE_SELECTED_ROWS_RELATIVE_TO_ROW_WITH_ID,
    referenceId,
    relativePosition
  };
}
function onBatchMove(store, events, relativePosition, referenceId) {
  const state = store.getState();
  const selectedRecords = state.get('selectedRecords');
  const selectionOrder = state.get('selectionOrder').toJS();
  const movedIds = selectionOrder.map(idStr => selectedRecords.getIn([idStr, 'id']));
  return dispatch => {
    const batchMoveProvider = state.get('batchMoveProvider').toJS();
    batchMoveProvider.onBatchMove(store, events, movedIds, relativePosition, referenceId);
    return dispatch(endBatchMove());
  };
}