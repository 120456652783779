"use strict";

var _reactRouterRedux = require("react-router-redux");
var _constants = _interopRequireDefault(require("../common/constants"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const urljoin = require('url-join');
const request = require('@rubyapps/ruby-superagent');
const rubyWords = require('@rubyapps/ruby-words');
const rubyNotificationsComponent = require('@rubyapps/ruby-component-notifications');
const FETCH_MOST_RECENTLY_MODIFIED_ENDPOINT = '/content/fetch-most-recently-modified';
const generators = {
  /* TODO: copy of sitemap-page component */
  setItemStatus_withActive: function (item, isActive, thenableArguments) {
    const {
      templateKey,
      id
    } = item;
    const selfModule = this;
    const {
      feSettingsSelector
    } = selfModule.getDependencies();
    const pushNotification = selfModule.pushNotification.bind(selfModule);
    const showNotificationModal = selfModule.showNotificationModal.bind(selfModule);
    return (dispatch, getState) => {
      const applicationState = getState();
      const apiUrlPrefix = _.get(feSettingsSelector(applicationState), 'restApiRoot');
      const endpoint = urljoin(apiUrlPrefix, _constants.default.API_KEY, '' + id, isActive ? 'publish' : 'unpublish');
      const requestPromise = request.post(endpoint).then(response => {
        const displayStatusMap = {
          active: 'published',
          inactive: 'unpublished'
        };
        let status = _.get(response, 'body.status');
        let contentDisplayString = rubyWords.quote(_.get(response, 'body.name')) || 'This content';
        let displayedStatus = displayStatusMap[status];
        pushNotification({
          message: `${contentDisplayString} is now ${displayedStatus}.`,
          type: rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.SUCCESS
        });
        return response;
      }, err => {
        let notificationMethod = pushNotification,
          title,
          message,
          type = rubyNotificationsComponent.CONSTANTS.NOTIFICATION_TYPES.ERROR;
        if (err.status === 409) {
          message = 'The entry could not be updated due to a pending content approval request. ' + 'Please try again once the request is resolved.';
        } else if (err.status === 403) {
          message = _.get(err, 'response.body.error.message') || 'You do not have the permission to update content. ' + 'Please edit the page and submit a content approval request.';
        } else if (err.status === 422) {
          message = 'The entry could not be updated due to invalid content. ' + 'Please edit the page to fix.';
        } else {
          title = 'Content Update Error';
          message = _.get(err, 'response.body.error.message') || 'An error was encountered while updating content. ' + 'Please try again later.';
          notificationMethod = showNotificationModal;
        }
        notificationMethod({
          type,
          title,
          message
        });
        throw err;
      });
      if (thenableArguments) {
        requestPromise.then.apply(requestPromise, thenableArguments);
      }
      return requestPromise;
    };
  }
  /* TODO: copy of action in new-page-dialog component */,
  createNewPage: function (payload) {
    const dependencies = this.getDependencies();
    const editPageComponent = dependencies.editPageComponent;
    const editPageActions = editPageComponent.getAction().generators;
    return (dispatch, getState) => {
      //# update the editPage's data
      const formData = _extends({}, payload.data, {
        content_subsite_fk: 1
      });
      dispatch(editPageActions.hydrateFormWithData(formData));
      dispatch((0, _reactRouterRedux.push)(payload.path));
    };
  },
  retrieveAndSetMostRecentlyModifiedItem_forType: function (type) {
    if (type == undefined) {
      console.warn('No type was provided for retrieveAndSetMostRecentlyModifiedItem_forType');
      return;
    }
    const selfModule = this;
    const {
      selfActions,
      selfSelector,
      feSettingsSelector
    } = selfModule.getDependencies();
    const thunk = (dispatch, getState) => {
      const applicationState = getState();
      const selfState = selfSelector(applicationState);
      const {
        client: rubyClientId,
        subsite: subsiteId
      } = selfModule.getActiveRubyClientInfo();
      const apiUrlPrefix = _.get(feSettingsSelector(applicationState), 'restApiRoot');
      if (rubyClientId && subsiteId) {
        const endpoint = urljoin(apiUrlPrefix, FETCH_MOST_RECENTLY_MODIFIED_ENDPOINT, '?ruby_client_fk=' + rubyClientId, '&content_subsite_fk=' + subsiteId, '&template_keyword=' + type);
        const requestPromise = request.get(endpoint).then(response => {
          const item = response.body.data[0];
          dispatch(selfActions.setMostRecentlyModifiedItem(item));
        }, err => {});
      } else {
        setTimeout(() => {
          dispatch(thunk);
        }, 250);
      }
    };
    return thunk;
  }
};
module.exports = function () {
  const selfModule = this;
  const selfID = selfModule.getID();
  const TYPES = {
    SET_MOST_RECENTLY_MODIFIED_ITEM: `@@ruby-app/${selfID}/SET_MOST_RECENTLY_MODIFIED_ITEM`
  };
  return {
    TYPES,
    generators: _extends({
      setMostRecentlyModifiedItem: function (item) {
        return {
          type: TYPES.SET_MOST_RECENTLY_MODIFIED_ITEM,
          payload: {
            item
          }
        };
      }
    }, generators)
  };
};