"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "addKeyToRows", {
  enumerable: true,
  get: function () {
    return _dataHelpers.addKeyToRows;
  }
});
exports.dateSort = dateSort;
exports.defaultSort = defaultSort;
exports.filterData = filterData;
exports.filterDataByColumns = filterDataByColumns;
exports.getDataSet = getDataSet;
exports.getDataSetSize = getDataSetSize;
exports.getPage = getPage;
Object.defineProperty(exports, "getPageCount", {
  enumerable: true,
  get: function () {
    return _dataHelpers.getPageCount;
  }
});
exports.getSortByType = getSortByType;
exports.getSortedData = getSortedData;
exports.getVisibleData = getVisibleData;
exports.hasNext = hasNext;
exports.hasPrevious = hasPrevious;
exports.sortDataByColumns = sortDataByColumns;
exports.sortTypes = sortTypes;
exports.updateSortColumns = updateSortColumns;
var _dataHelpers = require("./data-helpers");
var _immutable = _interopRequireDefault(require("immutable"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function getVisibleData(state) {
  //get the max page / current page and the current page of data
  const pageSize = state.getIn(['pageProperties', 'pageSize']);
  const currentPage = state.getIn(['pageProperties', 'currentPage']);
  const data = getDataSet(state).skip(pageSize * (currentPage - 1)).take(pageSize);
  const columns = (0, _dataHelpers.getDataColumns)(state, data);
  return (0, _dataHelpers.getVisibleDataColumns)(data, columns);
}
function hasNext(state) {
  return state.getIn(['pageProperties', 'currentPage']) < state.getIn(['pageProperties', 'maxPage']);
}
function hasPrevious(state) {
  return state.getIn(['pageProperties', 'currentPage']) > 1;
}
function getDataSet(state) {
  let data = null;

  //filtered data
  if (state.get('filter') && state.get('filter') !== '') {
    data = filterData(state.get('data'), state.get('filter'));
  }

  //full data
  data = data || state.get('data');

  //filter data by columns
  if (state.get('columnFilters') && state.get('columnFilters').size > 0) {
    data = filterDataByColumns(data, state.get('columnFilters'));
  }
  return data;
}
function filterData(data, filter) {
  return data.filter(row => {
    return Object.keys(row.toJSON()).some(key => {
      return row.get(key) && row.get(key).toString().toLowerCase().indexOf(filter.toLowerCase()) > -1;
    });
  });
}
function filterDataByColumns(data, filters) {
  //filters is an immutable list
  //go through the list and apply the filter to the data based on the filter
  return filters.reduce((previous, current) => {
    const {
      column,
      filter
    } = current;
    return previous.filter(row => row.get(column) && row.get(column).toString().toLowerCase().indexOf(filter.toLowerCase()) > -1);
  }, data);
}
function dateSort(data, column) {
  let sortAscending = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  return data.sort((original, newRecord) => {
    original = !!original.get(column) && new Date(original.get(column)) || null;
    newRecord = !!newRecord.get(column) && new Date(newRecord.get(column)) || null;
    if (original.getTime() === newRecord.getTime()) {
      return 0;
    } else if (original > newRecord) {
      return sortAscending ? 1 : -1;
    } else {
      return sortAscending ? -1 : 1;
    }
  });
}
function defaultSort(data, column) {
  let sortAscending = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  return data.sort((original, newRecord) => {
    original = !!original.get(column) && original.get(column) || "";
    newRecord = !!newRecord.get(column) && newRecord.get(column) || "";

    //TODO: This is about the most cheezy sorting check ever.
    //Make it be able to sort for dates / monetary / regex / whatever
    if (original === newRecord) {
      return 0;
    } else if (original > newRecord) {
      return sortAscending ? 1 : -1;
    } else {
      return sortAscending ? -1 : 1;
    }
  });
}
function sortTypes(type) {
  return {
    "default": defaultSort,
    "date": dateSort
  };
}
function getSortByType(type, helpers) {
  const sortType = (helpers && helpers.sortTypes || sortTypes)();
  return sortType.hasOwnProperty(type) ? sortType[type] : defaultSort;
}
function getSortedData(data, columns, helpers) {
  let sortAscending = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  let sortType = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : "default";
  return getSortByType(sortType, helpers)(data, columns[0], sortAscending, helpers);
}

//TODO: Consider renaming sortAscending here to sortDescending
function updateSortColumns(state, columns) {
  let sortAscending = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
  if (columns.length === 0) {
    return state;
  }
  const shouldSortAscending = sortAscending !== null ? sortAscending : !(state.getIn(['pageProperties', 'sortAscending']) === true && state.getIn(['pageProperties', 'sortColumns']).first() === columns[0]);
  return state.setIn(['pageProperties', 'sortAscending'], shouldSortAscending).setIn(['pageProperties', 'sortColumns'], _immutable.default.fromJS(columns));
}
function sortDataByColumns(state, helpers) {
  if (!state.get('data')) {
    return state;
  }

  //TODO: Clean this up
  const allColumnProperties = state.getIn(['renderProperties', 'columnProperties']);
  const sortColumns = state.getIn(['pageProperties', 'sortColumns']);
  //TODO: Make sort for more than just the first column
  const columnProperties = sortColumns && (sortColumns.size || sortColumns.hasOwnProperty('length') && sortColumns.length) > 0 ? allColumnProperties.get(sortColumns.first()) : null;
  const sortType = columnProperties && columnProperties.get('sortType') || null;
  let sorted = state.set('data', (helpers && helpers.getSortedData || getSortedData)(state.get('data'), sortColumns, helpers, state.getIn(['pageProperties', 'sortAscending']), sortType));

  //if filter is set we need to filter
  //TODO: filter the data when it's being sorted
  if (!!state.get('filter')) {
    sorted = filter(sorted, sorted.get('filter'));
  }
  return sorted;
}
function getDataSetSize(state) {
  return getDataSet(state).size;
}
function getPage(state, pageNumber) {
  const maxPage = (0, _dataHelpers.getPageCount)(getDataSetSize(state), state.getIn(['pageProperties', 'pageSize']));
  if (pageNumber >= 1 && pageNumber <= maxPage) {
    return state.setIn(['pageProperties', 'currentPage'], pageNumber).setIn(['pageProperties', 'maxPage'], maxPage);
  }
  return state;
}