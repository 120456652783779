"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _lodash = _interopRequireDefault(require("lodash"));
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _Button = _interopRequireDefault(require("./Button"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function generator() {
  const selfModule = this;
  const {
    selfSelector
  } = selfModule.getDependencies();
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state) || {};
    const mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps ? selfModule.fieldProps_fromState_andOwnProps(state, ownProps) : {};

    //# NOTE: checking for the existence of these methods because the FilePicker component also uses this Connector,
    //# but it doesn't have some of the mixins expected
    const formattedError = selfModule.formattedErrorFromState ? selfModule.formattedErrorFromState(state) : {};
    const {
      fieldKey
    } = mixinFieldProps;
    const props = selfModule.getProps(true);
    const {
      actionForButton
    } = selfModule.getDependencies(); //# calling it here because the field-sitemap is doing a hacky thing
    //# to override the actionForButton
    //# which we need to honor for now

    return _extends({
      handler: selfModule.props.handler,
      delegateActions: (0, _redux.bindActionCreators)({
        actionForButton
      }, selfModule.getStore().dispatch)
    }, ownProps, mixinFieldProps, props, _lodash.default.pick(_lodash.default.get(selfState, ['fields', fieldKey]), ['value']), formattedError, selfState);
  }
  function mapDispatchToProps(dispatch /*, ownProps*/) {
    return {
      actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
    };
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_Button.default);
}