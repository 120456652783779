"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const action = require('./action');

//# Note: not great but we can make certain assumptions based on the params
function hydratedParams() {
  let params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  params = params || {};
  let hydratedParams = _extends({}, params);
  if (params.hasOwnProperty('template_keyword')) {
    hydratedParams.template = hydratedParams.template || params.template_keyword;
  }
  if (params.hasOwnProperty('id')) {
    hydratedParams.action = hydratedParams.action || 'edit';
  }
  return hydratedParams;
}
const routeHelperMixin = {
  action //# provide some actions to help with navigation to all components
  ,

  parentRouteComponent: function () {
    const parentRouteComponent = this.findAncestorBy(node => node.hasOwnProperty('getRouteElement')) || this;
    return parentRouteComponent;
  },
  _getBestUrlForComponentName_withparams: function (componentName, params) {
    //# get nearest route component
    const parentRouteComponent = this.parentRouteComponent();
    const rootModule = this.getRoot();
    const bestUrl = rootModule.getBestUrlForComponentName_fromModule_withParams(componentName, parentRouteComponent, hydratedParams(params));
    return bestUrl;
  },
  openBestUrlForComponentName_withParams: function (componentName, params) {
    const bestUrl = this._getBestUrlForComponentName_withparams.apply(this, arguments);
    window.open(`${window.location.protocol}//${window.location.host}${bestUrl}`, '_blank');
  },
  navigateToBestUrlForComponentName_withParams: function (componentName, params) {
    const bestUrl = this._getBestUrlForComponentName_withparams.apply(this, arguments);
    this.getStore().dispatch(this.getAction().generators.navigateToPathWithOptions({
      path: bestUrl
    }));
  }
};
module.exports = routeHelperMixin;