"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const defaultState = {
  visible: false
};
module.exports = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  const TYPES = this.getAction().TYPES;
  switch (action.type) {
    case TYPES.SHOW_LOADING_INDICATOR:
      return _extends({}, state, {
        visible: true
      });
    case TYPES.HIDE_LOADING_INDICATOR:
      return _extends({}, state, {
        visible: false
      });
    default:
      return state;
  }
};