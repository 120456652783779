"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const _ = require('lodash');
const PluginTemplateEditor__CONSTANTS = require('@rubyapps/ruby-component-plugin-template-editor/src/common/constants');
const {
  STRUCTURED_DATA_TEMPLATE,
  LOOKUP_TEMPLATE
} = PluginTemplateEditor__CONSTANTS;
const listerConfigMixin = {
  augmentedListerConfig: function (listerConfig, key, rubyComponent) {
    let augmentedListerConfig = augmentListerConfigWithLastModified.apply(this, arguments);
    return augmentedListerConfig;
  }
};
module.exports = listerConfigMixin;
function augmentListerConfigWithLastModified(listerConfig, key, rubyComponent, ctx) {
  if (!(rubyComponent.componentName == 'rubyComponentFieldSitemap' || rubyComponent.componentName == 'rubyComponentStructuredDataListPage')) {
    return listerConfig;
  }
  const {
    template
  } = ctx;
  const templateType = _.get(template, 'templateType');
  if (!_.includes([STRUCTURED_DATA_TEMPLATE, LOOKUP_TEMPLATE], templateType)) {
    return listerConfig;
  }
  const listerConfig__columns = listerConfig.columns;
  const existingColumnIndex = _.findIndex(listerConfig__columns, ['key', 'last_modified_timestamp']);
  if (existingColumnIndex >= 0) {
    //# already exists, we don't need to do anything
    return listerConfig;
  } else {
    const isLookupTemplate = templateType == LOOKUP_TEMPLATE;
    const lastModifiedColumn = {
      key: 'last_modified_timestamp',
      sortKeys: ['status', 'last_modified_timestamp'],
      sortDirsFromDir_andKeys: (dir, keys) => {
        return [dir, 'desc'];
      },
      type: 'publishAndDate',
      displayName: isLookupTemplate ? 'Last Modified' : 'Published / Last Modified',
      sortable: true,
      width: 180,
      resizable: false,
      alwaysShow: true,
      componentParams: {
        hideToggle: isLookupTemplate
      }
    };
    return _extends({}, listerConfig, {
      columns: [].concat(listerConfig.columns, lastModifiedColumn)
    });
  }
}