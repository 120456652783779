"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
const Promise = require('bluebird');
const rubyLogger = require('@rubyapps/ruby-logger');
const packageName = require('../../package.json').name;
const logger = rubyLogger.getLogger(`${packageName}`);

//# similar to _.get() except it supports wildcard keypaths
//# Returns a flattened array if there are wildcards, otherwise the original value
function get(object, keyPath) {
  if (_lodash.default.isArray(keyPath) && _lodash.default.isEmpty(keyPath)) {
    return object;
  }
  const keyPathArr = _lodash.default.isString(keyPath) ? keyPath.split('.') : keyPath;
  const wildcardIndex = keyPathArr.indexOf('*');
  const leftandKeyPathArr = wildcardIndex > -1 ? keyPathArr.slice(0, wildcardIndex) : keyPathArr;
  const rightandKeyPathArr = wildcardIndex > -1 ? keyPathArr.slice(wildcardIndex + 1) : undefined;
  const leftandValue = _lodash.default.get(object, leftandKeyPathArr);
  let value;
  if (rightandKeyPathArr != undefined && leftandValue) {
    const normalizedLeftandValue = [].concat(leftandValue);

    //# map across the leftandValue and call recursively call get()
    //# if the rightandKeyPathArr doesn't have additional '*', then it'll get the appropriate value
    //# after one call
    value = _lodash.default.flatMap(normalizedLeftandValue, lValue => get(lValue, rightandKeyPathArr));
  } else {
    value = leftandValue;
  }
  return value;
}

//# similar to _.get() except it supports wildcard keypaths
//# Returns an unflattened array if there are wildcards, otherwise the original value
function unflattenedGet(object, keyPath) {
  const keyPathArr = _lodash.default.isString(keyPath) ? keyPath.split('.') : keyPath;
  const wildcardIndex = keyPathArr.indexOf('*');
  const leftandKeyPathArr = wildcardIndex > -1 ? keyPathArr.slice(0, wildcardIndex) : keyPathArr;
  const rightandKeyPathArr = wildcardIndex > -1 ? keyPathArr.slice(wildcardIndex + 1) : undefined;
  const leftandValue = _lodash.default.get(object, leftandKeyPathArr);
  let value;
  if (rightandKeyPathArr != undefined && leftandValue) {
    const normalizedLeftandValue = [].concat(leftandValue);
    value = _lodash.default.map(normalizedLeftandValue, lValue => unflattenedGet(lValue, rightandKeyPathArr));
  } else {
    value = leftandValue;
  }
  return value;
}
function forEachKeypath(object, keyPath, callback) {
  return setBy(object, keyPath, (value, keypath) => {
    return Promise.resolve(callback(value, keypath)).then(() => {
      //# returned undefined to avoid mutating object with setBy
      return undefined;
    });
  });
}
/**
 * @param {Object} object -  gets mutated
 * @param {string} keyPath
 * @param {func} valueToSetGenerator - (value, keypathArray) => {return updatedValueForKeypath}
 **/
function setBy(object, keyPath, valueToSetGenerator) {
  let trackingKeypathArr = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  const keyPathArr = _lodash.default.isString(keyPath) ? keyPath.split('.') : keyPath;
  const wildcardIndex = keyPathArr.indexOf('*');
  const leftandKeyPathArr = wildcardIndex > -1 ? keyPathArr.slice(0, wildcardIndex) : keyPathArr;
  const rightandKeyPathArr = wildcardIndex > -1 ? keyPathArr.slice(wildcardIndex + 1) : undefined;
  const leftandValue = _lodash.default.get(object, leftandKeyPathArr);
  if (rightandKeyPathArr != undefined) {
    const normalizedLeftandValue = [].concat(leftandValue);

    //# iterate across the leftandValue and call recursively call setBy()
    //# if the rightandKeyPathArr doesn't have additional '*', then it'll set the appropriate value
    //# after one call
    return Promise.all(normalizedLeftandValue.map((lValue, index) => setBy(lValue, rightandKeyPathArr, valueToSetGenerator, trackingKeypathArr.concat(leftandKeyPathArr, index))));
  } else {
    const setValue = valueToSet => {
      //# only set the value if what we returned exists or is explicitly null.
      //# if it's undefined, we don't want to set it
      if (valueToSet !== undefined) {
        _lodash.default.set(object, leftandKeyPathArr, valueToSet);
      }
    };
    const valueToSet = valueToSetGenerator(leftandValue, trackingKeypathArr.concat(leftandKeyPathArr));
    if (valueToSet && valueToSet.then) {
      //# it's a promise, wait for it to resolve
      return valueToSet.then(resolvedValue => {
        setValue(resolvedValue);
      });
    } else {
      //# auto wrap the result in a promise
      setValue(valueToSet);
      return Promise.resolve();
    }
  }
}

/**
 * @param {Object} object -  gets mutated
 * @param {string} keyPath
 * @param {func} valueToSetGenerator - (value, keypathArray) => {return updatedValueForKeypath}
 **/
function deleteBy(object, keyPath) {
  let trackingKeypathArr = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  const keyPathArr = _lodash.default.isString(keyPath) ? keyPath.split('.') : keyPath;
  const wildcardIndex = keyPathArr.indexOf('*');
  const leftandKeyPathArr = wildcardIndex > -1 ? keyPathArr.slice(0, wildcardIndex) : keyPathArr;
  const rightandKeyPathArr = wildcardIndex > -1 ? keyPathArr.slice(wildcardIndex + 1) : undefined;
  const leftandValue = _lodash.default.get(object, leftandKeyPathArr);
  if (rightandKeyPathArr != undefined) {
    const normalizedLeftandValue = [].concat(leftandValue);

    //# iterate across the leftandValue and call recursively call setBy()
    //# if the rightandKeyPathArr doesn't have additional '*', then it'll set the appropriate value
    //# after one call
    return Promise.all(normalizedLeftandValue.map((lValue, index) => deleteBy(lValue, rightandKeyPathArr, trackingKeypathArr.concat(leftandKeyPathArr, index))));
  } else {
    const deleteValue = () => {
      _lodash.default.unset(object, leftandKeyPathArr);
    };
    deleteValue();
    return Promise.resolve();
  }
}

//# object does not get mutated
//# new object returned, only if valueToSetGenerator returns a defined value
//# valueToSetGenerator = (value, keypathArray) => {}
function updateBy(object, keyPath, valueToSetGenerator) {
  let trackingKeypathArr = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  const keyPathArr = _lodash.default.isString(keyPath) ? keyPath.split('.') : keyPath;
  const wildcardIndex = keyPathArr.indexOf('*');
  const leftandKeyPathArr = wildcardIndex > -1 ? keyPathArr.slice(0, wildcardIndex) : keyPathArr;
  const rightandKeyPathArr = wildcardIndex > -1 ? keyPathArr.slice(wildcardIndex + 1) : undefined;
  const leftandValue = _lodash.default.get(object, leftandKeyPathArr);
  if (rightandKeyPathArr != undefined) {
    const normalizedLeftandValue = [].concat(leftandValue);

    // Try to update each item, keeping track of how many were updated
    const updates = _lodash.default.reduce(normalizedLeftandValue, (acc, lValue, index) => {
      const updatedItem = updateBy(lValue, rightandKeyPathArr, valueToSetGenerator, trackingKeypathArr.concat(leftandKeyPathArr, index));
      if (updatedItem != undefined) {
        return _lodash.default.assign({}, acc, {
          items: acc.items.concat([updatedItem]),
          modified: acc.modified + 1
        });
      } else {
        return _lodash.default.assign({}, acc, {
          items: acc.items.concat([lValue]),
          modified: acc.modified
        });
      }
    }, {
      items: [],
      modified: 0
    });

    // If there were updates, then return them, otherwise return nothing
    if (updates.modified > 0) {
      return _lodash.default.merge({}, _lodash.default.omit(object, leftandKeyPathArr), _lodash.default.set({}, leftandKeyPathArr, updates.items));
    }
  } else {
    const valueToSet = valueToSetGenerator(leftandValue, trackingKeypathArr.concat(leftandKeyPathArr));
    if (valueToSet !== undefined) {
      return _lodash.default.merge({}, _lodash.default.omit(object, leftandKeyPathArr), _lodash.default.set({}, leftandKeyPathArr, valueToSet));
    }
  }
  return undefined;
}

//# updated to support merging symbols
function merge(destObj) {
  for (var _len = arguments.length, sourceObjs = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    sourceObjs[_key - 1] = arguments[_key];
  }
  const result = _lodash.default.mergeWith(destObj, ...sourceObjs, (a, b) => {
    if (!_lodash.default.isObject(b) || b === null) return b;
    //# if b is null, we wipe out the value

    const a_isDefined = !_lodash.default.isNil(a);
    const a_isValidArray = a_isDefined && Array.isArray(a);
    const b_isValidArray = Array.isArray(b);
    if ((a_isValidArray || !a_isValidArray) && b_isValidArray) {
      //# return an array
      const mergedArray = [...(a || [])];
      b.forEach((b_item, b_index) => {
        mergedArray[b_index] = b_item;
        //# need to iterate because b.length might be less than a.length
        //# so we need to account for both; 
      });

      return mergedArray;
    } else if (_lodash.default.isObject(a) && _lodash.default.isObject(b)) {
      //# _.isObject returns true for arrays
      //# so if a is arr and b is obj, this is fine 
      //# we will get an object with array indices
      return _objectSpread(_objectSpread({}, a), b);
    } else {
      //# is a primative and b should override 
      logger.debug(`[merge] dest value is not an object or array, letting source overwrite it.`);
      return _objectSpread({}, b);
    }
  });
  return result;
}
const tree = require('./tree'); //# NOTE: DEPRECATED 20191203 in favor of deepdash.js
const methods = _objectSpread({
  get,
  merge,
  setBy,
  deleteBy,
  forEachKeypath,
  unflattenedGet,
  updateBy
}, tree);
module.exports = _objectSpread({
  default: methods
}, methods);