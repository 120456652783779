"use strict";

const RubyComponentForms = require('@rubyapps/ruby-component-forms');

//== The Middleware ========================================================//

const middlewareInitializer = function (store) {
  const selfModule = this;
  const selfSelector = this.getDefaultSelector();
  const selfAction = selfModule.getAction();
  return next => action => {
    let nextRetVal = next(action); //# still allow @@router to continue propagation
    const hiddenComponent = selfModule._hiddenComponent;
    const {
      forgotPasswordTokenKey,
      forgotPasswordTokenParam
    } = this.props;
    let forgotPasswordUUID;
    let mySetRouteAction;
    switch (action.type) {
      case 'BATCHING_REDUCER.BATCH':
        const batchPayload = action.payload;
        mySetRouteAction = _.find(batchPayload, {
          'type': selfAction.TYPES.SET_ROUTE_PARAMS
        });
        if (mySetRouteAction) {
          forgotPasswordUUID = mySetRouteAction.payload.query[forgotPasswordTokenParam];
          store.dispatch(hiddenComponent.getAction().generators.setFieldValueByKey(forgotPasswordUUID, forgotPasswordTokenKey));
        }
        break;
      case selfAction.TYPES.SET_ROUTE_PARAMS:
        mySetRouteAction = action;
        forgotPasswordUUID = mySetRouteAction.payload.query[forgotPasswordTokenParam];
        store.dispatch(hiddenComponent.getAction().generators.setFieldValueByKey(forgotPasswordUUID, forgotPasswordTokenKey));
        break;
    }
    return nextRetVal;
  };
};
module.exports = middlewareInitializer;