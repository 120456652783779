"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clearAllRowSelections = clearAllRowSelections;
exports.selectVisibleRows = selectVisibleRows;
exports.toggleRowSelection = toggleRowSelection;
exports.unselectVisibleRows = unselectVisibleRows;
var types = _interopRequireWildcard(require("./constants"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
function toggleRowSelection(griddleKey) {
  return {
    type: types.GRIDDLE_ROW_SELECTION_TOGGLED,
    griddleKey
  };
}
function selectVisibleRows() {
  return {
    type: types.GRIDDLE_ROW_SELECT_VISIBLE_ROWS
  };
}
function unselectVisibleRows() {
  return {
    type: types.GRIDDLE_ROW_UNSELECT_VISIBLE_ROWS
  };
}
function clearAllRowSelections(griddleKey) {
  return {
    type: types.GRIDDLE_CLEAR_ALL_ROW_SELECTIONS
  };
}