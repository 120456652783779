"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var Colors = _interopRequireWildcard(require("material-ui/styles/colors"));
var RubyColors = _interopRequireWildcard(require("../styleObjects/colors"));
var ColorManipulator = _interopRequireWildcard(require("material-ui/utils/colorManipulator"));
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
//https://www.materialpalette.com/blue-grey/blue-grey

//import Spacing from 'material-ui/styles/spacing';

const Spacing = {
  iconSize: 24,
  desktopGutter: 24,
  desktopGutterMore: 32,
  desktopGutterLess: 16,
  desktopGutterMini: 8,
  desktopKeylineIncrement: 64,
  desktopDropDownMenuItemHeight: 32,
  desktopDropDownMenuFontSize: 15,
  desktopDrawerMenuItemHeight: 48,
  desktopSubheaderHeight: 48,
  desktopToolbarHeight: 56
};
const emphasisCoefficient = 0.2;
//'#4FC3F7';
const rubyRed = '#ed0c53';
const rubyAccent = '#455A64'; //# Dark Primary Color

const errorColor = Colors.red500;
var _default = {
  spacing: Spacing,
  fontFamily: 'CartoGothicStd,Helvetica,Verdana,Arial,sans-serif',
  zIndex: {
    snackbar: 15
    /*
    , dialog: 9992
    , drawer: 9993
    */
  },

  palette: {
    primary1Color: RubyColors.rubyBlue,
    //_light,
    primary2Color: RubyColors.rubyBlue_light,
    primary3Color: Colors.grey400,
    primary4Color: RubyColors.headerGrey,
    accent1Color: rubyAccent,
    //rubyRed,
    accent2Color: Colors.grey100,
    accent3Color: Colors.grey400,
    textColor: Colors.darkBlack,
    alternateTextColor: Colors.white,
    canvasColor: Colors.white,
    borderColor: Colors.grey300,
    inactiveColor: (0, ColorManipulator.fade)(Colors.darkBlack, 0.5),
    disabledColor: (0, ColorManipulator.fade)(Colors.darkBlack, 0.26),
    disabledColor_level2: (0, ColorManipulator.fade)(Colors.darkBlack, 0.12),
    disabledColor_level3: (0, ColorManipulator.fade)(Colors.darkBlack, 0.06),
    pickerHeaderColor: RubyColors.rubyBlue_light,
    clockCircleColor: (0, ColorManipulator.fade)(Colors.darkBlack, 0.07),
    shadowColor: Colors.fullBlack,
    floatingLabelColor: (0, ColorManipulator.fade)(Colors.darkBlack, 0.5),
    borderColor: RubyColors.black_25
    //# used by textfield

    // TODO: Taken for now from http://isabelcastillo.com/error-info-messages-css
    ,
    infoColor_background: '#BDE5F8',
    infoColor_foreground: '#00529B',
    succesColor_background: '#DFF2BF',
    successColor_foreground: '#4F8A10',
    warningColor_background: '#FEEFB3',
    warningColor_foreground: '#9F6000',
    errorColor_background: '#FFBABA',
    errorColor_foreground: '#D8000C'
  },
  alternates: {
    inkBar: {
      backgroundColor: '#FFFFFF'
    },
    tabError: {
      backgroundColor: errorColor
    }
  }
  //# TODO: start adding in component by component overrides for the transitions
  //# and updating the components to check for some flag and determining whether we
  //# should pass these objects as inline-style overrides
  ,
  noAnimation: {
    drawer: {
      containerStyle: {
        overflow: 'visible',
        transition: 'transform 0ms'
      }
    }
  }
};
exports.default = _default;