"use strict";

var _reduxBatchedActions = require("redux-batched-actions");
function typesWithID(id) {
  return {
    UPDATE_TOGGLES_BY_ID__WHEN_TOGGLED: `@@ruby-app/${id}/UPDATE_TOGGLES_BY_ID__WHEN_TOGGLED`,
    SET_TOGGLES_BY_ID__WHEN_SERVER_HYDRATES_WITH_FIELD_VALUE: `@@ruby-app/${id}/SET_TOGGLES_BY_ID__WHEN_SERVER_HYDRATES_WITH_FIELD_VALUE`,
    INIT_TOGGLE_TREE_AND_TOGGLES_BY_ID__ON_REDUX_INIT: `@@ruby-app/${id}/INIT_TOGGLE_TREE_AND_TOGGLES_BY_ID__ON_REDUX_INIT`
  };
}
const generators = {
  setTogglesByIdUsingToggleCtx: function (toggleCtx) {
    const {
      TYPES,
      generators
    } = this.getAction();

    //updateUserModifiedTimestamp
    return (0, _reduxBatchedActions.batchActions)([{
      type: TYPES.UPDATE_TOGGLES_BY_ID__WHEN_TOGGLED,
      payload: {
        toggleCtx
      }
    }, generators.updateUserModifiedTimestamp()]);
  },
  setTogglesByIdUsingFieldValue(fieldValue) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SET_TOGGLES_BY_ID__WHEN_SERVER_HYDRATES_WITH_FIELD_VALUE,
      payload: fieldValue
    };
  },
  initTogglesByIdUsingHydratedToggleTree: function (toggleTree) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.INIT_TOGGLE_TREE_AND_TOGGLES_BY_ID__ON_REDUX_INIT,
      payload: {
        toggleTree
      }
    };
  }
};
module.exports = function () {
  return {
    TYPES: typesWithID(this.getID()),
    generators
  };
};