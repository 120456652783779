"use strict";

var _store = _interopRequireDefault(require("store"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const RCPersistenceHelper = {
  set: (key, value) => {
    _store.default.set(key, value);
  },
  get: key => {
    return _store.default.get(key);
  },
  clearAll: () => {
    _store.default.clearAll();
  }
};
module.exports = RCPersistenceHelper;