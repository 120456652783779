"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); } /* jshint -W138 */
//# NOTE: default state is provided by the RubyComponent (via getInitialState())
//# because it has to account for the defaultState of the mixins
module.exports = function (state, action) {
  const {
    TYPES
  } = this.getAction();
  const {
    type,
    payload
  } = action;
  switch (type) {
    case TYPES.UPDATE_DISPLAY_VALUE_BY_HASH:
    case TYPES.SELECT_ITEM:
      return _extends({}, state, payload || {});
    case TYPES.CLEAR_SELECTED_ITEM:
      return _extends({}, state, {
        selectedValue: null
      });
    case TYPES.REPLACE_ITEM:
      return replaceItemReducer.call(this, state, action);
    default:
      return state;
  }
};
function replaceItemReducer(state, action) {
  const currentFieldValue = this.getFieldValue();
  const selfKey = this.key();
  const {
    existingItem,
    newItem
  } = action.payload;
  const existingItemsByHash = _lodash.default.keyBy(this.hydratedItems(currentFieldValue), '_hash');
  const hydratedExistingItem = this.hydratedItem(existingItem);
  const foundExistingItem = existingItemsByHash[hydratedExistingItem._hash];
  if (!foundExistingItem) {
    console.warn('[replaceItemReducer()] Could not find existing item:', hydratedExistingItem);
    return state;
  }
  const newValue = currentFieldValue.slice(0);
  newValue[foundExistingItem._index] = newItem;
  return _extends({}, state, {
    fields: _extends({}, state.fields, {
      [selfKey]: _extends({}, state.fields[selfKey], {
        value: newValue
      })
    })
  });
}