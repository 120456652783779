"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterData = filterData;
exports.getDataSet = getDataSet;
exports.getDataSetSize = getDataSetSize;
exports.getPage = getPage;
exports.getPageData = getPageData;
exports.getSortedData = getSortedData;
exports.getVisibleData = getVisibleData;
exports.sortDataByColumns = sortDataByColumns;
var _griddleCore = require("../../griddle-core");
// Override of implementation from 'local-helpers'
function getPageData(state, pageSize, currentPage) {
  const remoteDataIndex = state.get('pagesLoaded').indexOf(currentPage);
  return helpers.getDataSet(state).skip(pageSize * remoteDataIndex).take(pageSize);
}

// Override of implementation from 'local-helpers'
function getPage(state, pageNumber) {
  return state.setIn(['pageProperties', 'currentPage'], pageNumber);
}

// Override of implementation from 'local-helpers'
function getDataSet(state) {
  return state.get('data');
}

// Override of implementation from 'local-helpers'
function getVisibleData(state) {
  //get the max page / current page and the current page of data
  const pageSize = state.getIn(['pageProperties', 'pageSize']);
  const currentPage = state.getIn(['pageProperties', 'currentPage']);
  const remoteDataIndex = state.get('pagesLoaded').indexOf(currentPage);
  const {
    getDataColumns,
    getVisibleDataColumns,
    getSortedColumns
  } = _griddleCore.GriddleHelpers.data;
  const data = getDataSet(state).skip(pageSize * remoteDataIndex).take(pageSize);
  const columns = getDataColumns(state, data);
  return getVisibleDataColumns(data, columns);
}

// Override of implementation from 'local-helpers'
function getDataSetSize(state) {
  return state.get('totalItemCount');
}

// Override of implementation from 'local-helpers'
function filterData(data, filter) {
  // Simply return the data.
  return data;
}

// Override of implementation from 'local-helpers'
function getSortedData(data, columns) {
  let sortAscending = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  // Simply return the data.
  return data;
}

// Override of implementation from 'local-helpers'
function sortDataByColumns(state) {
  // Simply return the data.
  return state;
}