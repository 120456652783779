"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const IS_DEVELOPMENT_ENV = process.NODE_ENV != 'production';
function formatRequestPropsForColumns(columns) {
  const fields = _lodash.default.map(columns, col => col.key).filter(colKey => colKey);

  //# exclude keys in listerConfig that has the 'references' prop defined
  const fieldsToExpand = _lodash.default.reduce(columns, (collector, col) => {
    if (IS_DEVELOPMENT_ENV && col.hasOwnProperty('references')) {
      console.warn(`The 'references' prop in the listerConfig is deprecated.
If you need to expand data, please use: 
{
type: 'valueInObject'
, expand: true
}
`);
    }
    if (col.references == undefined && col.shouldExpand) {
      collector.push(col.key);
    }
    return collector;
  }, []);
  return {
    fields,
    expand: fieldsToExpand
  };
}
var _default = formatRequestPropsForColumns;
exports.default = _default;