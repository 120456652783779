"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const _ = require('lodash');
const OPERATORS = {
  and: true,
  or: true
  /*
  , gt: true
  , gte: true
  , lt: true
  , lte: true
  , between: true
  , inq: true
  , nin: true
  , near: true
  , neq: true
  , like: true
  , nlike: true
  , ilike: true
  , nilike: true
  , regexp: true
  */
};

function mapKeypath(keypath) {
  return keypath;
}
function mapKeypathsInFields(fieldsArr, keypathMapper) {
  return fieldsArr.map(keypathMapper);
}
function mapKeypathsInWhere(where, keypathMapper) {
  const reduceObject = object => {
    return _.reduce(object, (collector, value, key) => {
      if (OPERATORS[key]) {
        //# traverse
        collector[key] = _.isArray(value) ? value.map(reduceObject) : reduceObject(value);
      } else {
        //# assume its a keypath
        collector[keypathMapper(key)] = value;
      }
      return collector;
    }, {});
  };
  return reduceObject(where);
}
function mapKeypathsInQuery(query) {
  let keypathMapper = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : mapKeypath;
  return _extends({}, query, query.where ? {
    where: mapKeypathsInWhere(query.where, keypathMapper)
  } : {}, query.fields ? {
    fields: mapKeypathsInFields(query.fields, keypathMapper)
  } : {});
}
module.exports = {
  mapKeypathsInQuery
};