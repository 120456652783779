"use strict";

const moment = require('moment');
const dateManager = {
  formatWithShortenedAdjacentDays: function (date) {
    var UTC_Date = moment(date);
    var now = moment();
    var today_start = moment(now).startOf('day');
    var yesterday_start = moment(today_start).subtract(1, 'day');
    var year_start = moment(now).startOf('year');
    var formatted_date;
    if (UTC_Date.isAfter(today_start)) {
      formatted_date = "Today, " + UTC_Date.format("h:mma");
    } else if (UTC_Date.isBetween(yesterday_start, today_start)) {
      formatted_date = "Yesterday, " + UTC_Date.format("h:mma");
    } else if (UTC_Date.isAfter(year_start)) {
      formatted_date = UTC_Date.format("MMM D, h:mma");
    } else {
      formatted_date = UTC_Date.format("MMM D, YYYY, h:mma");
    }
    return formatted_date;
  }
};
module.exports = dateManager;