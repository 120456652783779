"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.columnSpecForSelfAndFieldSpec = columnSpecForSelfAndFieldSpec;
exports.columnSpecsArrForSelf_andFieldSpecs = columnSpecsArrForSelf_andFieldSpecs;
exports.default = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
//const rubyWords = require('@rubyapps/ruby-words');

const BASE_COLUMN_SPEC = {
  key: '',
  displayName: ''
  //, type: ''
  ,
  minWidth: 100
  // , width: 
  ,
  resizable: true
  //, sortable: false
};

const DEFAULT_DATASOURCE_CONFIG = {
  key: 'dataPath',
  text: 'displayLabel'
};
const TABLE_WIDTH_MAX = 1215;
const TABLE_WIDTH_MIN = 600; //# NOTE: subject to change
const DEFAULT_ACTION_COLUMN_WIDTH = 240;
const DEFAULT_MIN_WIDTH = 50;
const COMPONENT_TYPES = {
  DATE: 'date',
  DATETIME: 'datetime',
  TIME: 'time',
  HTML: 'html',
  NUMBER: 'number',
  VALUE_IN_OBJECT: 'valueInObject',
  BOOLEAN: 'booleanString'
};
function columnSpecForSelfAndFieldSpec(self) {
  let fieldSpec = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let dataSourceConfig = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : DEFAULT_DATASOURCE_CONFIG;
  let listerConfig = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  const listerConfigColumnsByKey = _lodash.default.keyBy(_lodash.default.get(listerConfig, 'columns', []), 'key');
  const {
    componentName,
    data_type: dataType,
    url: urlProp,
    toHTML,
    listerConfig: fieldSpec__listerConfig
  } = fieldSpec;
  //# TODO: consider just include all of the fieldSpec so the griddle cells can be flexible instead of 
  //# needing to add additional props?

  const isDataTypeArray = _lodash.default.isArray(dataType);
  const primitiveDataType = isDataTypeArray ? dataType[0] : dataType;
  const fieldSpec__key = fieldSpec[dataSourceConfig.key];
  const allowSort = isDataTypeArray ? false : true;
  const columnSpec = _extends({}, BASE_COLUMN_SPEC, {
    breadcrumb: fieldSpec.breadcrumb,
    displayName: fieldSpec[dataSourceConfig.text],
    key: fieldSpec__key,
    toHTML
  }, {
    sortable: 1 || allowSort //# TODO
  }, listerConfigColumnsByKey[fieldSpec__key] || {}, fieldSpec__listerConfig);
  //# handle special cases first

  if (urlProp) {
    //# TODO: support Dropdown options by using humanize?
    columnSpec.type = COMPONENT_TYPES.VALUE_IN_OBJECT;
    columnSpec.shouldExpand = true;
  } else if (componentName == 'DatePicker') {
    columnSpec.type = COMPONENT_TYPES.DATE;
  } else if (componentName == 'ComponentizedDatePicker') {
    columnSpec.type = COMPONENT_TYPES.DATE;
    columnSpec.sortKey = [columnSpec.key, 'date'].join('.');
  } else if (componentName == 'DatetimePicker') {
    columnSpec.type = COMPONENT_TYPES.DATETIME;
    if (dataType != 'string') {
      columnSpec.sortKey = [columnSpec.key, 'datetime'].join('.');
    }
  } else if (componentName == 'TimePicker') {
    columnSpec.type = COMPONENT_TYPES.TIME;
    columnSpec.sortKey = [columnSpec.key, 'time'].join('.');
  } else if (primitiveDataType == 'number') {
    columnSpec.type = COMPONENT_TYPES.NUMBER;
  } else if (componentName == 'RichTextEditor') {
    columnSpec.type = COMPONENT_TYPES.HTML;
  } else if (primitiveDataType == 'boolean') {
    columnSpec.type = COMPONENT_TYPES.BOOLEAN;
  }

  //# NOTE: do this if we need to expose the spec to the griddle custom cell component
  //columnSpec.componentParams = Object.assign({}, columnSpec);

  return columnSpec;
}
const defaultOptions = {
  listerConfig: {},
  reservedColumnWidth: DEFAULT_ACTION_COLUMN_WIDTH
};
function columnSpecsArrForSelf_andFieldSpecs(self) {
  let fieldSpecs = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  let dataSourceConfig = arguments.length > 2 ? arguments[2] : undefined;
  let options = arguments.length > 3 ? arguments[3] : undefined;
  options = _extends({}, defaultOptions, options);
  const {
    listerConfig
  } = options;
  const columnSpecs = fieldSpecs.map(fieldSpec => columnSpecForSelfAndFieldSpec(self, fieldSpec, dataSourceConfig, listerConfig));
  const averageWidth = (TABLE_WIDTH_MAX - options.reservedColumnWidth) / columnSpecs.length;
  const averageMinWidth = (TABLE_WIDTH_MIN - options.reservedColumnWidth) / columnSpecs.length;
  const minWidth = Math.max(DEFAULT_MIN_WIDTH, averageMinWidth);

  //const columnSpecs_normalized = columnSpecs.map(columnSpec => Object.assign({}, columnSpec, {width: averageWidth, minWidth: minWidth}));
  const columnSpecs_normalized = columnSpecs.map(columnSpec => _extends({}, columnSpec));
  return columnSpecs_normalized;
}
var _default = columnSpecForSelfAndFieldSpec;
exports.default = _default;