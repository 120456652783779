"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getWebsitesMenuItemsForSelfModule_bySection;
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
//# TODO: see if we can pass the icon component instead of the string
const _ = require('lodash');
const RubyWords = require('@rubyapps/ruby-words');
const pluginWebsitesComponentId = 'rubyComponentPluginWebsites';
const WEBSITES_PLUGIN__CONSTANTS = require('@rubyapps/ruby-component-plugin-websites/src/common/constants');
function getWebsitesMenuItemsForSelfModule_bySection(selfModule) {
  const pluginWebsitesComponent = selfModule.getRoot().findDescendentByID(pluginWebsitesComponentId);
  const websiteForm = selfModule.getForms()[WEBSITES_PLUGIN__CONSTANTS.TEMPLATE_KEYWORD];
  const formFirstSubsite = _.first(_.castArray(_.get(websiteForm, 'ruby_subsite_fk')));

  //# return old menu items
  if (!(websiteForm && pluginWebsitesComponent)) {
    return {
      web_content: [{
        name: 'Dashboard',
        icon: 'material-ui/svgIcons/ActionDashboard',
        route: '/app/dashboard'
      }, {
        name: 'Sitemap',
        icon: 'ruby/icons/content/sitemap',
        permissions: [{
          keyword: 'content_sitemap_section',
          ruby_client: 3,
          action: 'edit'
        }],
        route: '/app/content/en/sitemap'
      }]
    };
  }
  const mainEntryId = _.result(pluginWebsitesComponent, 'getState.frontendSpacesState.config.mainEntryId');
  const permissionSpec = {
    model: 'content',
    template: websiteForm.id,
    ruby_client: websiteForm.ruby_client_fk,
    subsite: formFirstSubsite || 1,
    action: 'get'
  };
  return {
    admin: [{
      name: WEBSITES_PLUGIN__CONSTANTS.LABEL_PLURAL,
      icon: WEBSITES_PLUGIN__CONSTANTS.ICON_PATH,
      permissions: [permissionSpec],
      route: `/app/${WEBSITES_PLUGIN__CONSTANTS.ROUTE_KEY}/list`
    }],
    web_content: mainEntryId ? [{
      name: 'Dashboard',
      icon: 'material-ui/svgIcons/ActionDashboard',
      permissions: [_extends({
        id: mainEntryId
      }, permissionSpec)],
      route: `/app/${WEBSITES_PLUGIN__CONSTANTS.ROUTE_KEY}/${mainEntryId}/edit#tab=analytics`
    }, {
      name: 'Sitemap',
      icon: 'ruby/icons/content/sitemap',
      permissions: [_extends({
        id: mainEntryId
      }, permissionSpec)],
      route: `/app/${WEBSITES_PLUGIN__CONSTANTS.ROUTE_KEY}/${mainEntryId}/edit`
    }] : []
  };
}