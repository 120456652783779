"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const defaultState = {
  pristineData: null,
  url: null
};
module.exports = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  const {
    TYPES
  } = this.getAction();
  const {
    type,
    payload
  } = action;
  switch (type) {
    case TYPES.SET_PRISTINE_DATA:
      return _extends({}, state, {
        pristineData: payload.data
      });
    case TYPES.SET_URL:
      return _extends({}, state, {
        url: payload.data
      });
    case TYPES.RESET_STORE:
      this.clearStatefulCache();
      //# NOTE: not sure if we should call it here
      //# or in the rubyComponent.resetStore() method
      //# NOTE that we should clear the cache
      //# AFTER the redux state gets cleared or close to it
      //# meaning we can't clear it during the action generator call

      return _extends({}, state, defaultState);
    default:
      return state;
  }
};