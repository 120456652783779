"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.matchLabels = exports.matchConstants = exports.matchConstantKeyByValue = exports.default = void 0;
const _ = require('lodash');
const matchConstants = {
  IS: 'is' //# NOTE: keeping this here for backwards compatiblity 
  //# but this has been DEPRECATED in favor of 'CONTAINS'
  ,
  IS_ONE_OF: 'is_one_of' //# the inq filter
  ,
  IS_NOT: 'is_not',
  CONTAINS: 'contains',
  CONTAINS_ALL_OF: 'contains_all_of',
  DOES_NOT_CONTAIN: 'does_not_contain',
  IS_EMPTY: 'is_empty',
  IS_NOT_EMPTY: 'is_not_empty'

  //# Array
  ,
  IS_EMPTY__ARRAY: 'is_empty__array',
  IS_NOT_EMPTY__ARRAY: 'is_not_empty__array'

  //# Boolean
  ,
  IS_TRUE: 'is_true',
  IS_FALSE: 'is_false'

  //# Date
  ,
  IS_EQUAL_TO__DATE: 'is_equal_to__date',
  IS_NOT_EQUAL_TO__DATE: 'is_not_equal_to__date',
  IS_AFTER__DATE: 'is_after__date',
  IS_BEFORE__DATE: 'is_before__date'

  //# Number
  ,
  IS_EQUAL_TO: 'is_equal_to',
  IS_NOT_EQUAL_TO: 'is_not_equal_to',
  IS_GREATER_THAN: 'is_greater_than',
  IS_LESS_THAN: 'is_less_than',
  IS_GREATER_THAN_OR_EQUAL_TO: 'is_greater_than_or_equal_to',
  IS_LESS_THAN_OR_EQUAL_TO: 'is_less_than_or_equal_to',
  IS_EMPTY__NUMBER: 'is_empty__number',
  IS_NOT_EMPTY__NUMBER: 'is_not_empty__number'

  //# String
  ,
  CONTAINS__STRING: 'contains__string',
  DOES_NOT_CONTAIN__STRING: 'does_not_contain__string',
  IS_EMPTY__STRING: 'is_empty__string',
  IS_NOT_EMPTY__STRING: 'is_not_empty__string'
};
exports.matchConstants = matchConstants;
const matchConstantKeyByValue = _.reduce(matchConstants, (collector, value, key) => {
  collector[value] = key;
  return collector;
}, {});
exports.matchConstantKeyByValue = matchConstantKeyByValue;
const matchLabels = {
  //IS: 'is'
  IS_ONE_OF: 'is',
  IS_NOT: 'is not',
  DOES_NOT_CONTAIN: 'does not contain',
  CONTAINS: 'contains any of',
  CONTAINS_ALL_OF: 'contains all of',
  CONTAINS__STRING: 'contains',
  IS_EMPTY: 'is empty',
  IS_NOT_EMPTY: 'is not empty',
  IS_EQUAL_TO: 'is equal to',
  IS_NOT_EQUAL_TO: 'is not equal to',
  IS_GREATER_THAN: 'is greater than',
  IS_LESS_THAN: 'is less than',
  IS_GREATER_THAN_OR_EQUAL_TO: 'is greater than or equal to',
  IS_LESS_THAN_OR_EQUAL_TO: 'is less than or equal to',
  IS_AFTER: 'is after',
  IS_BEFORE: 'is before',
  IS_TRUE: 'is true/yes/active',
  IS_FALSE: 'is false/no/inactive'
};
exports.matchLabels = matchLabels;
var _default = matchConstants;
exports.default = _default;