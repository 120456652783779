"use strict";

const PAGE_PARAM = 'p';
const PAGE_SIZE_PARAM = 'psize';
const SORT_PARAM = 'sname';
const SORT_DIR_PARAM = 'sdir';
module.exports = {
  COMPONENT_NAME: 'rubyComponentListPage',
  ROUTE_PATH: 'list/:template',
  BASIC_STATE_KEYS_TO_QUERY_PARAMS: {
    sortColumn: SORT_PARAM,
    sortDirection: SORT_DIR_PARAM,
    currentPage: PAGE_PARAM,
    resultsPerPage: PAGE_SIZE_PARAM,
    searchTerm: 'search'
  },
  CMS_URL_PREFIX: '/cms',
  API_URL_PREFIX: '/ruby/api/v2/',
  DEFAULT_SORTABLE_FLAG: false,
  DEFAULT_PAGE_SIZE_OPTIONS: [10, 25, 50, 100, 250],
  DEFAULT_PAGE_SIZE: 25,
  DEFAULT_SORT_COLUMN: 'name',
  DEFAULT_SORT_DIR: 'asc',
  PAGE_PARAM,
  PAGE_SIZE_PARAM,
  SORT_PARAM,
  SORT_DIR_PARAM
};