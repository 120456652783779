"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = createProps;
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function createProps(propTypes, props, className) {
  const newProps = {};
  Object.keys(props).filter(key => key === 'children' || !propTypes[key]).forEach(key => newProps[key] = props[key]);
  return _extends({}, newProps, {
    className
  });
}