"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const RubyComponent = require('@rubyapps/ruby-component');
const PropTypes = RubyComponent.PropTypes;
const rubyMiddlewareFields = require('@rubyapps/ruby-middleware-fields');
const fieldBaseMixin = {
  mixinName: 'rubyComponentMixinFieldValidations',
  propTypes: {
    key: PropTypes.string //# the key to use for the form
    ,
    label: PropTypes.string,
    constraints: PropTypes.object,
    default: PropTypes.string,
    defaultToFirstOption: PropTypes.bool
  },
  getDefaultProps: function () {
    return {
      verify: {}
    };
  },
  getInitialState: function () {
    const {
      key,
      label,
      verify //# NOTE: our component uses constraints, so lets keep it constraints
      //# even though the formJS is verify
      ,
      default: defaultValue,
      defaultToFirstOption,
      options
    } = this.props;
    if (true || key) {
      //# always support this even if key is undefined
      let value;
      //# if defaultValue is true, and we have options, we default
      if (!_lodash.default.isNil(defaultValue)) {
        value = defaultValue;
      } else if (options && options.length && defaultToFirstOption) {
        value = options[0].value;
      }
      return {
        fields: {
          [key]: _extends({
            value: _lodash.default.isUndefined(value) ? null : this.fieldValueFromFormValue_forKey(value, key, true)
            //:this.coercedValue(value)
            //# NOTE: we're not using coercedValue to coerce dataType, rather
            //# we're using it to enforce whether it's array or not
            ,
            error: null,
            label: label,
            userModifiedTimestamp: null
          }, verify ? {
            constraints: verify
          } : {})
        }
      };
    }
    return {};
    //# return an empty object instead because regardless of whether field is returned,
    //# if the component needs to initialize itself with other states, it'll have to check the existence
    //# of whatever it needs to add
  },

  action: rubyMiddlewareFields.action,
  reducer: rubyMiddlewareFields.reducer,
  getFieldValue(state) {
    const localState = this.getState(state);
    return this.getFieldValueFromLocalState(localState);
  },
  getFieldValueFromLocalState(localState) {
    const fields = _lodash.default.get(localState, 'fields', {});
    const fieldKey = this.props.key;
    const fieldSpec = fields[fieldKey] || {};
    return fieldSpec.value;
  },
  getConstraints_forKey_fromState(key, state) {
    if (state == undefined) {
      state = this.getStore().getState();
    }
    const selfSelector = this.getDefaultSelector();
    const localState = selfSelector(state);
    const isFieldInDefaultNamespace = this.isFieldInDefaultNamespace_fromState ? this.isFieldInDefaultNamespace_fromState(state) : true;
    const fields = localState.fields;
    const fieldKey = key ? key : this.props.key;
    const fieldSpec = fields[fieldKey];
    const fieldConstraints = fieldSpec && fieldSpec.constraints;
    const filteredFieldConstraints = fieldSpec ? isFieldInDefaultNamespace ? fieldConstraints : _lodash.default.omit(fieldConstraints, ['required']) : {};
    return filteredFieldConstraints;
  },
  validateValue_forField: function (value, key) {
    const localState = this.getState();
    const fields = localState.fields;
    const fieldSpec = fields[key];
    const fieldLabel = fieldSpec ? fieldSpec.label : '';
    const errors = rubyMiddlewareFields.validateField_withConstraints(fieldLabel, key, value, fieldSpec ? fieldSpec.constraints : {});
    return errors;
  },
  validateValue_forField_againstConstraints: function (value, key, constraints) {
    const localState = this.getState();
    const fields = localState.fields;
    const fieldSpec = fields[key];
    const fieldLabel = fieldSpec ? fieldSpec.label : '';
    const errors = rubyMiddlewareFields.validateField_withConstraints(fieldLabel, key, value, constraints);
    return errors;
  }
};
module.exports = fieldBaseMixin;