"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
module.exports = function () {
  const id = this.getID();
  const TYPES = {
    OPEN_DIALOG: `@@ruby-app/${id}/OPEN_DIALOG`,
    CLOSE_DIALOG: `@@ruby-app/${id}/CLOSE_DIALOG`,
    SET_OPTIONS: `@ruby-app/${id}/SET_OPTIONS`,
    RESET_DIALOG: `@@ruby-app/${id}/RESET_DIALOG`
  };
  return {
    TYPES,
    generators: {
      openDialog: function (options) {
        const selfModule = this;
        const {
          formDialogComponent
        } = selfModule.getDependencies();
        const dispatch = selfModule.getStore().dispatch;
        const formJs = selfModule.getAugmentedDialogTemplate().form;
        const formDialogActions = formDialogComponent.getAction().generators;
        return formDialogActions.open(_objectSpread({
          title: `Email Link Manager`,
          formJs,
          dialogActions: [{
            label: 'Cancel',
            primary: false,
            onTouchTap: () => {
              dispatch(formDialogActions.closeAndReset());
            }
          }, {
            label: 'Add Link',
            primary: true,
            onTouchTap: evt => {
              dispatch(formDialogActions.validateBeforeSubmitting());
              return;
            }
          }],
          onSubmit: (formData, successCB, errorCB) => {
            const generators = selfModule.getAction().generators;

            //# is overwritten by the tinymce plugin
            debugger;
          }
        }, options));
      },
      closeDialog: function (options) {
        const {
          formDialogComponent
        } = this.getDependencies();
        const formDialogActions = formDialogComponent.getAction().generators;
        return formDialogActions.closeAndReset();
      }
      /**
       *
       *  options: {
       *      title: the title of the dialog
       *      defaultFormData: {
       *          //# key-value pair as if it's form data
       *      }
       *  }
       *
       **/
      //# options.parent_id
      ,
      open: function (options) {
        const {
          TYPES,
          generators
        } = this.getAction();
        const openAction = {
          type: TYPES.OPEN_DIALOG
        };
        if (options) {
          return dispatch => {
            dispatch(generators.setOptions(options));
            dispatch(openAction);
          };
        } else {
          return openAction;
        }
      },
      close: function () {
        const {
          TYPES
        } = this.getAction();
        return {
          type: TYPES.CLOSE_DIALOG
        };
      },
      closeAndReset: function () {
        const generators = this.getAction().generators;
        return dispatch => {
          dispatch(generators.close());
          setTimeout(() => {
            dispatch(generators.reset());
          }, 500);
        };
      },
      setOptions: function (options) {
        const {
          TYPES,
          generators
        } = this.getAction();
        const selfModule = this;
        return {
          type: TYPES.SET_OPTIONS,
          payload: options
        };
      },
      reset: function () {
        const selfModule = this;
        const generators = selfModule.getAction().generators;
        const {
          TYPES
        } = selfModule.getAction();
        return {
          type: TYPES.RESET_DIALOG
        };
      }
    }
  };
};