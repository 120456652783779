"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
var _components = _interopRequireDefault(require("../../../ruby-component-builder/src/common/components"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
const baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
const {
  _nodeWithMergedDefaultProps,
  fieldSpecsForNode
} = require('@rubyapps/ruby-content-property-helper/src/client/fieldsSpec.js');
function fieldSpecsTree_fromFormChildren(formChildren, ctx) {
  const fieldSpecsTree = selectableNodesFromFormChildren(formChildren).reduce((acc, node) => {
    const {
      componentName,
      children = []
    } = node;
    if (componentName === 'NamespaceSelector') {
      return acc.concat(fieldSpecsTree_fromFormChildren(children, ctx));
    }
    const nodeWithMergedDefaultProps = _nodeWithMergedDefaultProps(node);

    //# semi-flattened cause address field returns only the children
    //# but the children nodes have 'dataPath' defined
    const fieldSpecs = fieldSpecsForNode(nodeWithMergedDefaultProps, _objectSpread(_objectSpread({}, ctx), {}, {
      dataPathArray: ctx.dataPathArray || []
    }), {
      omitFieldPicker: node => node.mode == 'info'
    });
    //# NOTE: We are relying on a match between the `key` and `componentName`
    //# properties to find the field spec from `fieldSpecs` that matches the
    //# current `node`. The assumption being made here is that it would never
    //# be the case that a given node would have field specs where multiple
    //# items have the same key.
    const matchingFieldSpecForNode = fieldSpecs.find(_lodash.default.matches({
      key: nodeWithMergedDefaultProps.key,
      componentName: nodeWithMergedDefaultProps.componentName
    })) || {};
    const childrenFieldSpecs = fieldSpecs.filter(fieldSpec => {
      //# if nodeWithMergedDefaultProps.key doesn't have a key, we should just include

      return !nodeWithMergedDefaultProps.key && fieldSpec.key || (fieldSpec.dataPath || '').split('.')[0] == nodeWithMergedDefaultProps.key;
    });
    const nodeWithMergedFieldSpec = _extends({}, nodeWithMergedDefaultProps, childrenFieldSpecs.length ? {
      children: childrenFieldSpecs
    } : {}, matchingFieldSpecForNode ? _extends({}, matchingFieldSpecForNode
    //# NOTE: For components like the `ProfileIdentity` widget that have `childrenPropsByKey`
    //# , we are considering each property of `childrenPropsByKey` as a child of the component
    , matchingFieldSpecForNode.hasOwnProperty('childrenPropsByKey') ? {
      children: [].concat(matchingFieldSpecForNode.children, Object.keys(matchingFieldSpecForNode.childrenPropsByKey).map(childPropKey => fieldSpecs.find(spec => spec.key === childPropKey)).filter(_lodash.default.identity))
    } : {}) : {});
    return acc.concat(_extends({}, nodeWithMergedFieldSpec, !_lodash.default.isEmpty(children) ? {
      children: fieldSpecsTree_fromFormChildren(children, ctx)
    } : {}));
  }, []);
  return fieldSpecsTree;
}
function selectableNodesFromFormChildren() {
  let formChildren = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  const selectableNodes = _lodash.default.reduce(formChildren, (acc, node) => {
    const {
      children = []
    } = node;
    return nodePicker(node) ? acc.concat(_extends({}, node, {
      children: selectableNodesFromFormChildren(children)
    })) : acc;
  }, []);
  return selectableNodes;
}
function nodePicker(node) {
  const {
    children = [],
    key,
    componentName = ""
  } = node;
  const component = _components.default[componentName];
  const defaultProps = component && component.getDefaultProps ? component.getDefaultProps({}) : {};
  const hasInternalChildren = defaultProps.childrenPropsByKey;
  const fieldPickerPropsFromNode = _lodash.default.get(node, 'forComponent.FieldsPicker') || {};
  const {
    exclude = false
  } = fieldPickerPropsFromNode;
  const isValidComponent = componentName !== 'Hidden' && componentName !== 'Info';
  const shouldSelect = exclude || !isValidComponent ? false : !_lodash.default.isEmpty(children) ? children.find(nodePicker) : (key || hasInternalChildren) && _lodash.default.isEmpty(children) && !exclude;
  return shouldSelect;
}
module.exports = fieldSpecsTree_fromFormChildren;