"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const FONT_SIZE = 14;
const FLOATING_LABEL_STYLE = {
  top: 42
};
var _default = {
  Dropdown: {
    SelectField: {
      floatingLabelStyle: FLOATING_LABEL_STYLE,
      style: {
        fontSize: FONT_SIZE,
        width: 106,
        top: -10
      },
      labelStyle: {
        top: 9
      },
      errorStyle: {
        position: 'absolute',
        marginBottom: -22,
        width: '230px',
        right: 0,
        textAlign: 'right'
      }
    }
  }
};
exports.default = _default;