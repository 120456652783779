"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DefaultsPlugin = DefaultsPlugin;
var _immutable = _interopRequireDefault(require("immutable"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// Griddle plugin that lets Griddle user's specify
// the default state of the store.
//
// To use this plugin, just do:
//
//    import DefaultsPlugin from 'griddle-defaults-plugin';
//
//    const initialState = { pageProperties: { sortColumns: ['name'] } };
//    <Griddle plugins={[DefaultsPlugin(initialState)]}>
//
// The DefaultsPlugin should be the last plugin in the list.

function DefaultsPlugin(initialState) {
  return {
    name: 'GriddleDefaults',
    states: _immutable.default.fromJS(initialState || {}),
    components: {}
  };
}
;