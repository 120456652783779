"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "Column", {
  enumerable: true,
  get: function () {
    return _column.default;
  }
});
Object.defineProperty(exports, "Row", {
  enumerable: true,
  get: function () {
    return _row.default;
  }
});
Object.defineProperty(exports, "Table", {
  enumerable: true,
  get: function () {
    return _table.default;
  }
});
Object.defineProperty(exports, "TableBody", {
  enumerable: true,
  get: function () {
    return _tableBody.default;
  }
});
Object.defineProperty(exports, "TableHeading", {
  enumerable: true,
  get: function () {
    return _tableHeading.default;
  }
});
var _column = _interopRequireDefault(require("./column"));
var _row = _interopRequireDefault(require("./row"));
var _tableHeading = _interopRequireDefault(require("./table-heading"));
var _tableBody = _interopRequireDefault(require("./table-body"));
var _table = _interopRequireDefault(require("./table"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }