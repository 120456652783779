"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GRIDDLE_CLEAR_ALL_ROW_SELECTIONS = GRIDDLE_CLEAR_ALL_ROW_SELECTIONS;
exports.GRIDDLE_LOADED_DATA_AFTER = GRIDDLE_LOADED_DATA_AFTER;
exports.GRIDDLE_ROW_SELECTION_TOGGLED = GRIDDLE_ROW_SELECTION_TOGGLED;
exports.GRIDDLE_ROW_SELECT_VISIBLE_ROWS = GRIDDLE_ROW_SELECT_VISIBLE_ROWS;
exports.GRIDDLE_ROW_UNSELECT_VISIBLE_ROWS = GRIDDLE_ROW_UNSELECT_VISIBLE_ROWS;
var _immutable = _interopRequireDefault(require("immutable"));
var _helpers = require("../../griddle-subgrid-plugin/src/helpers");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
// These functions are doing most of the work:

function griddleKeysForVisibleData(state, returnCollapsed) {
  const visibleData = state.get('visibleData');
  const visibleStaticData = state.get('visibleStaticData');
  const flatVisibleData = (0, _helpers.flattenNestedDataBy)(visibleData, _helpers.getChildren);
  const flatVisibleStaticData = (0, _helpers.flattenNestedDataBy)(visibleStaticData, _helpers.getChildren);
  const visibleGriddleKeys = flatVisibleData.concat(flatVisibleStaticData).map(row => row.getIn(['__metadata', 'griddleKey'], row.get('griddleKey')));
  return visibleGriddleKeys;
}
function newStateFromSelection(state, griddleKey, selectMode) {
  const idColumn = state.get('idColumn');

  //# NOTE: we might want to update setting staticData to include in regular data
  const isStaticGriddleKey = griddleKey.indexOf('static-') == 0;
  const flatData = (0, _helpers.flattenNestedDataBy)(isStaticGriddleKey ? state.get('staticData') : state.get('data'), _helpers.getChildren);
  const rowData = flatData.find(row => row.get('griddleKey') === griddleKey);
  const rowIdValue = rowData.get(idColumn) + '';
  const isSelected = state.get('selectedRecords').has(rowIdValue);
  const shouldSelect = selectMode == -1 ? !isSelected : selectMode; // -1 == toggle

  const selectedRecords = state.get('selectedRecords');
  //# need to clean up the selected records from selection order
  //# this is a problem for merge lookups cause after merge, thed selected records gets cleared but the selection order doesn't
  const selectionOrder = state.get('selectionOrder').filter(id => {
    return selectedRecords.has(id);
  });
  if (shouldSelect) {
    state = state.set('selectedRecords', selectedRecords.set(rowIdValue, rowData));
    state = state.set('selectionOrder', selectionOrder.concat(rowIdValue));
  } else {
    state = state.set('selectedRecords', selectedRecords.remove(rowIdValue));
    state = state.set('selectionOrder', selectionOrder.filter(id => id !== rowIdValue));
  }
  return setRowProperties_inState(state);
}

// These functions are helper functions for working with nested data:

// These are the actual reducer functions:

function GRIDDLE_ROW_SELECTION_TOGGLED(state, action, helpers) {
  const {
    griddleKey
  } = action;
  return newStateFromSelection(state, griddleKey, -1);
}
function GRIDDLE_ROW_SELECT_VISIBLE_ROWS(state, action, helpers) {
  const visibleGriddleKeys = griddleKeysForVisibleData(state);
  return visibleGriddleKeys.reduce((collectorState, visibleGriddleKey) => newStateFromSelection(collectorState, visibleGriddleKey, 1), state);
}
function GRIDDLE_ROW_UNSELECT_VISIBLE_ROWS(state, action, helpers) {
  const visibleGriddleKeys = griddleKeysForVisibleData(state, true);
  return visibleGriddleKeys.reduce((collectorState, visibleGriddleKey) => newStateFromSelection(collectorState, visibleGriddleKey, 0), state);
}
function GRIDDLE_CLEAR_ALL_ROW_SELECTIONS(state) {
  return setRowProperties_inState(state.set('selectedRecords', _immutable.default.fromJS({})));
}
function GRIDDLE_LOADED_DATA_AFTER(state) {
  return setRowProperties_inState(state);
}
function setRowProperties_inState(state) {
  const data = state.get('data');
  const idColumn = state.get('idColumn');
  const selectedRecords = state.get('selectedRecords');
  return state.set('data', getHydratedRows_fromRows_andParams(data, {
    idColumn,
    selectedRecords
  }));
}
function getHydratedRows_fromRows_andParams(data) {
  let params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  if (!data) {
    return data;
  }
  const {
    idColumn,
    selectedRecords,
    ancestorIsSelected
  } = params;
  return data.map(row => {
    const rowIdValue = row.get(idColumn);
    const isSelected = selectedRecords.has(rowIdValue + '');
    let children = row.get('children');
    if (children && children.size > 0) {
      children = getHydratedRows_fromRows_andParams(children, _extends({}, params, {
        ancestorIsSelected: isSelected || ancestorIsSelected
      }));
    }
    return row.set('isSelected', isSelected).set('ancestorIsSelected', ancestorIsSelected).set('children', children);
  });
}