"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const _ = require('lodash');
const constants = require('../common/constants');
const errorHelper = require('../common/errors');
const typesFromID = id => ({
  OPEN_NOTIFICATION: `@@ruby-app/${id}/error-feedback/OPEN_NOTIFICATION`,
  CLOSE_NOTIFICATION: `@@ruby-app/${id}/error-feedback/CLOSE_NOTIFICATION`,
  RESET_NOTIFICATION: `@@ruby-app/${id}/error-feedback/RESET_NOTIFICATION`
});
var generators = {
  //# options.parent_id
  showNotification: function (options) {
    const selfModule = this;
    const TYPES = selfModule.getAction().TYPES;
    return {
      type: TYPES.OPEN_NOTIFICATION,
      payload: options
    };
  },
  showErrorNotification: function (options) {
    const selfModule = this;
    const TYPES = selfModule.getAction().TYPES;
    options = options || {};
    let error = options.error;
    let defaultErrorOptions = errorHelper.getErrorNotificationOptions(error, options);
    console.error(error);
    return {
      type: TYPES.OPEN_NOTIFICATION,
      payload: _extends({}, defaultErrorOptions, options)
    };
  },
  closeNotification: function (modal) {
    const selfModule = this;
    const TYPES = selfModule.getAction().TYPES;
    return {
      type: TYPES.CLOSE_NOTIFICATION,
      modal
    };
  },
  closeAndResetNotification: function (modal) {
    const generators = this.getAction().generators;
    return dispatch => {
      dispatch(generators.closeNotification(modal));
      const selfState = this.getState();
      if (modal) {
        selfState.modalConfig.onClose && selfState.modalConfig.onClose();
      } else {
        selfState.snackbarConfig.onClose && selfState.snackbarConfig.onClose();
      }
      setTimeout(() => {
        dispatch(generators.resetNotification(modal));
      }, 500);
    };
  },
  resetNotification: function (modal) {
    const selfModule = this;
    const TYPES = selfModule.getAction().TYPES;
    return {
      type: TYPES.RESET_NOTIFICATION,
      modal
    };
  }
};
module.exports = function () {
  const TYPES = typesFromID(this.getID());
  return {
    TYPES,
    generators
  };
};