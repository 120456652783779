"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = {
  IconButton: {
    style: {
      padding: 0,
      marginRight: 4,
      width: 24,
      height: 24
    },
    iconStyle: {
      color: 'rgb(96 125 139)'
    },
    tooltipPosition: 'top-center'
  }
};
exports.default = _default;