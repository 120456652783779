"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const _ = require('lodash');
const updateTogglesByIdUsingState_andToggleCtx = require('./updateTogglesById');
const setTogglesByIdUsingState_andFieldValue = require('./setTogglesById');
const initTogglesByIdUsingState_andHydratedToggleTree = require('./initTogglesById');
module.exports = function (state, action) {
  const {
    TYPES
  } = this.getAction();
  const {
    type,
    payload
  } = action;
  const {
    togglesById,
    toggleTree
  } = state;
  switch (type) {
    case TYPES.UPDATE_TOGGLES_BY_ID__WHEN_TOGGLED:
      const {
        toggleCtx
      } = payload;
      return _extends({}, state, {
        togglesById: updateTogglesByIdUsingState_andToggleCtx(state, toggleCtx)
      });
    case TYPES.SET_TOGGLES_BY_ID__WHEN_SERVER_HYDRATES_WITH_FIELD_VALUE:
      const fieldValue = payload;
      return _extends({}, state, {
        togglesById: setTogglesByIdUsingState_andFieldValue(state, fieldValue)
      });
    case TYPES.INIT_TOGGLE_TREE_AND_TOGGLES_BY_ID__ON_REDUX_INIT:
      const {
        toggleTree: hydratedToggleTree
      } = payload;
      return _extends({}, state, {
        toggleTree: hydratedToggleTree,
        togglesById: initTogglesByIdUsingState_andHydratedToggleTree(state, hydratedToggleTree)
      });
    default:
      return state;
  }
};