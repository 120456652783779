// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Textarea__textarea____i_f2 {\n    width: 100%;\n    background: #fff;\n    border: 1px solid rgba(0,0,0,0.2);\n    padding: 8px;\n    box-sizing: border-box;\n    resize: vertical;\n}\n\n.Textarea__textarea____i_f2::placeholder {\n    color: rgba(0, 0, 0, 0.26);\n}\n\n.Textarea__toolbar____MUCJ {\n    position: absolute;\n    top: 0;\n    right: 0;\n    padding: 4px;\n}\n\n.Textarea__toolbarButton___DKPSf {\n    transform: scale(0.8);\n}\n.Textarea__toolbarButtonWrapper____4fXc {\n    display: inline-block;\n    margin-right: 10px;\n}\n.Textarea__toolbarButtonWrapper____4fXc:last-child {\n    margin-right: 0;\n}\n\n.Textarea__toolbarButton___DKPSf button .fa {\n    font-size: 22px!important;\n}\n", "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-textarea/src/client/reactComponents/Textarea.cssModule"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,iCAAiC;IACjC,YAAY;IACZ,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,QAAQ;IACR,YAAY;AAChB;;AAEA;IACI,qBAAqB;AACzB;AACA;IACI,qBAAqB;IACrB,kBAAkB;AACtB;AACA;IACI,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".textarea {\n    width: 100%;\n    background: #fff;\n    border: 1px solid rgba(0,0,0,0.2);\n    padding: 8px;\n    box-sizing: border-box;\n    resize: vertical;\n}\n\n.textarea::placeholder {\n    color: rgba(0, 0, 0, 0.26);\n}\n\n.toolbar {\n    position: absolute;\n    top: 0;\n    right: 0;\n    padding: 4px;\n}\n\n.toolbarButton {\n    transform: scale(0.8);\n}\n.toolbarButtonWrapper {\n    display: inline-block;\n    margin-right: 10px;\n}\n.toolbarButtonWrapper:last-child {\n    margin-right: 0;\n}\n\n.toolbarButton button :global(.fa) {\n    font-size: 22px!important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textarea": "Textarea__textarea____i_f2",
	"toolbar": "Textarea__toolbar____MUCJ",
	"toolbarButton": "Textarea__toolbarButton___DKPSf",
	"toolbarButtonWrapper": "Textarea__toolbarButtonWrapper____4fXc"
};
export default ___CSS_LOADER_EXPORT___;
