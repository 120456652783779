"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
/* jshint -W138 */

module.exports = function (state, action) {
  const actionTypes = this.getAction().TYPES;
  const {
    type,
    payload
  } = action;
  switch (type) {
    case actionTypes.SET_MOST_RECENTLY_MODIFIED_ITEM:
      return _extends({}, state, {
        mostRecentlyModifiedItem: payload.item ? payload.item : {}
      });
    default:
      return state;
  }
};