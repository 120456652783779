"use strict";

const request = require('@rubyapps/ruby-superagent');
const rubyMiddlewareFields = require('@rubyapps/ruby-middleware-fields');
function typesFromID(id) {
  const TYPES = {
    OPEN_ALTER_TEMPLATE_DIALOG: `@@ruby-app/${id}/OPEN_ALTER_TEMPLATE_DIALOG`,
    CLOSE_ALTER_TEMPLATE_DIALOG: `@@ruby-app/${id}/CLOSE_ALTER_TEMPLATE_DIALOG`,
    OPEN_ALTER_TEMPLATE_DIALOG_CONFIRMATION: `@@ruby-app/${id}/OPEN_ALTER_TEMPLATE_DIALOG_CONFIRMATION`,
    CLOSE_ALTER_TEMPLATE_DIALOG_CONFIRMATION: `@@ruby-app/${id}/CLOSE_ALTER_TEMPLATE_DIALOG_CONFIRMATION`,
    SET_OPTIONS: `@@ruby-app/${id}/SET_OPTIONS`,
    ALTER_TEMPLATE: `@@ruby-app/${id}/ALTER_TEMPLATE`,
    RESET_ALTER_TEMPLATE_DIALOG: `@@ruby-app/${id}/RESET_ALTER_TEMPLATE_DIALOG`
  };
  return TYPES;
}
var generators = {
  openAlterTemplateDialog: function () {
    let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const {
      TYPES,
      generators
    } = this.getAction();
    let selfModule = this;
    return (dispatch, getState) => {
      //# determine the initialization state of the form

      dispatch(generators.setOptions(options));
      dispatch({
        type: TYPES.OPEN_ALTER_TEMPLATE_DIALOG
      });
    };
  },
  setOptions: function (options) {
    const {
      TYPES
    } = this.getAction();
    const selfModule = this;
    return {
      type: TYPES.SET_OPTIONS,
      payload: options
    };
  },
  closeAlterTemplateDialog: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.CLOSE_ALTER_TEMPLATE_DIALOG
    };
  },
  closeAndReset: function () {
    const {
      generators: actions
    } = this.getAction();
    return dispatch => {
      dispatch(actions.closeAlterTemplateDialog());
      setTimeout(() => {
        dispatch(actions.resetAlterTemplateDialog());
      }, 500);
    };
  },
  openConfirmation: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.OPEN_ALTER_TEMPLATE_DIALOG_CONFIRMATION
    };
  },
  closeConfirmation: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.CLOSE_ALTER_TEMPLATE_DIALOG_CONFIRMATION
    };
  },
  alterTemplate: function (payload) {
    const selfModule = this;
    const selfSelectorObj = selfModule.getSelector();
    return (dispatch, getState) => {
      const applicationState = getState();
      const selfState = selfSelectorObj.default(applicationState);
      const {
        endpoint
      } = selfState;
      request.post(endpoint).query({
        toTemplate: payload.templateKey
      }).then(function onResult(response) {
        selfState.submitSuccessCallback && selfState.submitSuccessCallback(response);
      }, function onError(err) {
        selfState.submitFailCallback && selfState.submitFailCallback(err);
      });
    };
  },
  resetAlterTemplateDialog: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.RESET_ALTER_TEMPLATE_DIALOG
    };
  },
  closeAndAlterTemplate_thenResetAlterTemplateDialog: function (payload) {
    const {
      generators
    } = this.getAction();
    return (dispatch, getState) => {
      dispatch(generators.closeConfirmation());
      dispatch(generators.closeAlterTemplateDialog());
      dispatch(generators.alterTemplate.call(this, payload));
      dispatch(generators.resetAlterTemplateDialog());
    };
  }
};
module.exports = function () {
  const TYPES = typesFromID(this.getID());
  return {
    TYPES: TYPES,
    generators: generators
  };
};