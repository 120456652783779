"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
function constructKeyURI(issuer, label, secret) {
  // TODO: allow configuration of type
  // alternative: hotp (counter based)
  const type = 'totp';
  let encodedLabel = encodeURIComponent(label);
  let encodedIssuer = encodeURIComponent(issuer);
  return `otpauth://${type}/${encodedLabel}?secret=${secret}&issuer=${encodedIssuer}`;
}
var _default = constructKeyURI;
exports.default = _default;