// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ActivityList__activityList___oglKl {\n    margin-left: 70px;\n    width: 66%;\n}\n\n.ActivityList__newComment___ZEJ8K {\n    margin-left: 70px;\n    width: 66%;\n}\n\n.ActivityList__newCommentTitle___FKlOP {\n    padding-top: 15px;\n}\n\n.ActivityList__newCommentOptions___ovp9a {\n    margin-top: 10px;\n    margin-bottom: 10px;\n    text-align: right;\n}\n\n", "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-activities/src/client/reactComponents/ActivityList.cssModule"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,UAAU;AACd;;AAEA;IACI,iBAAiB;IACjB,UAAU;AACd;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":[".activityList {\n    margin-left: 70px;\n    width: 66%;\n}\n\n.newComment {\n    margin-left: 70px;\n    width: 66%;\n}\n\n.newCommentTitle {\n    padding-top: 15px;\n}\n\n.newCommentOptions {\n    margin-top: 10px;\n    margin-bottom: 10px;\n    text-align: right;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"activityList": "ActivityList__activityList___oglKl",
	"newComment": "ActivityList__newComment___ZEJ8K",
	"newCommentTitle": "ActivityList__newCommentTitle___FKlOP",
	"newCommentOptions": "ActivityList__newCommentOptions___ovp9a"
};
export default ___CSS_LOADER_EXPORT___;
