"use strict";

var _memoizee = _interopRequireDefault(require("memoizee"));
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
const RubyComponent = require('@rubyapps/ruby-component');
const PropTypes = RubyComponent.PropTypes;
const rubyAccessManager = require('@rubyapps/ruby-access-manager');
const RubyComponentCurrentUser = require('@rubyapps/ruby-component-current-user');
const ACCESS_CONSTANTS = rubyAccessManager.CONSTANTS;
const PERMISSION_STRINGS = ACCESS_CONSTANTS.PERMISSION_STRINGS;
const PERMISSION_ACTIONS = ACCESS_CONSTANTS.PERMISSION_ACTIONS;
function _doesUserHaveFieldPermission_forComponent(fieldPermissionKeyword, rubyComponent) {
  // Get the parent RubyComponent that defines the route
  // It should have the access-control mixin applied
  // Which provides the getActiveRubyClientId and getCurrentUserState methods
  const routeParent = rubyComponent.findAncestorBy(module => module.getRouteElement);
  if (!routeParent) return false;
  if (!routeParent.getActiveRubyClientId) return false;
  if (!routeParent.getCurrentUserState) return false;

  // Given a ruby client id and user object
  const rubyClientId = routeParent.getActiveRubyClientId();
  const userObject = routeParent.getCurrentUserState();
  const fieldPerm = _lodash.default.isPlainObject(fieldPermissionKeyword) ? fieldPermissionKeyword : _createFieldPermission(fieldPermissionKeyword, rubyClientId, PERMISSION_ACTIONS.EDIT);

  // Check to see if the user has publish permission
  // for the client/subsite
  return rubyAccessManager.doesUserHavePermission(userObject, fieldPerm);
}

// Memoize here, so that doesUserHavePermission memoize works
const _createFieldPermission = (0, _memoizee.default)(_raw_createFieldPermission, {
  length: 3
});
function _raw_createFieldPermission(keyword, ruby_client, action) {
  return {
    keyword,
    ruby_client,
    action
  };
}
const fieldPropsMixin = {
  mixinName: 'rubyComponentMixinFieldProps',
  key: function () {
    return this.props.key;
    const selfKey = [this.namespacePrefixKey(), this.props.key].filter(value => value).join('.');
    return selfKey;
  },
  statesSelector_fromState_andOwnProps: function (state, ownProps) {
    const fieldPerms = _lodash.default.get(ownProps, 'ruby_permissions', []);
    const selfKey = this.key();
    const id = this.getID();
    const parentForm = this.getParentFormComponent();
    const canEditForm = parentForm ? parentForm.canEdit() : true;
    const isActiveForNamespace = this.isFieldActiveForNamespace_fromState ? this.isFieldActiveForNamespace_fromState(state) : true;
    const hidden = _lodash.default.result(ownProps, 'hidden') || false;
    return {
      id,
      fieldKey: selfKey //key is not exposed to the react component
      ,
      isActive: isActiveForNamespace && !hidden,
      locked: ownProps.hasOwnProperty('locked') && ownProps.locked == true ? ownProps.locked : !canEditForm ? true : fieldPerms.reduce((lockedFlag, perm) => {
        return lockedFlag || !this._doesUserHaveFieldPermission_forComponent(perm, this);
      }, false)
    };
  },
  fieldProps_fromState_andOwnProps: function (state, ownProps) {
    let _boundGridWrapperRenderer;
    if (ownProps.renderGridWrapper) {
      _boundGridWrapperRenderer = ownProps.renderGridWrapper;
    } else {
      if (!this._boundGridWrapperRenderer) {
        this._boundGridWrapperRenderer = this.renderGridWrapper.bind(this);
      }
      _boundGridWrapperRenderer = this._boundGridWrapperRenderer;
    }
    const selfSelector = this.getDefaultSelector();
    const selfState = selfSelector(state);
    const isFieldInDefaultNamespace = this.isFieldInDefaultNamespace_fromState();
    const selectedState = fieldPropsMixin.statesSelector_fromState_andOwnProps.apply(this, arguments);
    const {
      fieldKey: selfKey
    } = selectedState;
    return _objectSpread({
      key: selectedState.id //# use id instead of selfKey for the React element key
      ,
      required: isFieldInDefaultNamespace ? _lodash.default.get(selfState, ['fields', selfKey, 'constraints', 'required'], false) : false
      //# TODO: 20161221 NOTE: need a better way to handle excluding required due to namespaces
      ,
      renderGridWrapper: _boundGridWrapperRenderer
    }, selectedState);
  },
  _doesUserHaveFieldPermission_forComponent
};
module.exports = fieldPropsMixin;