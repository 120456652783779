"use strict";

const retryDelay = 2 /* sec */ * 1000 /* ms */;
const maxRetryDelay = 30 * 1000;
const backoffRate = (maxRetryDelay - retryDelay) / 30; //# 30 retries to reach max delay
const readinessProbe = '/_health/ready?polling';
module.exports = {
  MAX_RETRIES: 2,
  RETRY_DELAY: retryDelay,
  MAX_RETRY_DELAY: maxRetryDelay,
  BACKOFF_RATE: backoffRate,
  READINESS_PROBE: readinessProbe,
  DEFAULT_RETRY_OPTIONS: {
    readinessProbe: readinessProbe,
    delay: retryDelay,
    maxDelay: maxRetryDelay,
    backoffRate
  }
};