"use strict";

function typesWithID(id) {
  return {};
}
const generators = {};
module.exports = function () {
  return {
    TYPES: typesWithID(this.getID()),
    generators
  };
};