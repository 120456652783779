"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AFTER_REDUCE = AFTER_REDUCE;
var _lodash = _interopRequireDefault(require("lodash"));
var _store = _interopRequireDefault(require("store"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function AFTER_REDUCE(griddleState) {
  let preferencesStorageKey = griddleState.get('preferencesStorageKey');
  let preferencesToSave = griddleState.get('preferencesToSave') || [];
  let stateToSave = preferencesToSave.reduce((map, key) => {
    map[key] = griddleState.get(key);
    return map;
  }, {});
  if (preferencesStorageKey) {
    debounchedLocalStorageUpdate(preferencesStorageKey, stateToSave);
  }
  return griddleState;
}
function debounchedLocalStorageUpdate(key, value) {
  let debouncedUpdate = _lodash.default.debounce((key, value) => {
    const version = RUBYAPP_VERSION || '0.1';
    _store.default.set(key, {
      [version]: value
    });
  }, 1000);
  debouncedUpdate(key, value);
}