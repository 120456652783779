"use strict";

const WEBSITE_PLUGIN__CONSTANTS = require('../../../ruby-component-plugin-websites/src/common/constants');
const ADMIN_TEMPLATE = 'admin';
const LOOKUP_TEMPLATE = 'lookup_template';
const PROFILE_TEMPLATE = 'profile_template';
const PROPOSAL_PAGE_TEMPLATE = 'proposal_page_template';
const PROPOSAL_TEMPLATE = 'proposal_template';
const SITEMAP_TEMPLATE = 'sitemap_template';
const STRUCTURED_DATA_TEMPLATE = 'structured_data_template';
const SUB_TEMPLATE = 'subtemplate';
const WEBSITE_TEMPLATE = 'space_template';
const REPEATER_MODULE_TEMPLATE = 'repeater_module_template';
const GENERIC_TEMPLATE = 'generic_template';
module.exports = {
  COMPONENT_NAME: 'rubyComponentPluginTemplateEditor',
  API_URL_BASE: '/ruby/api/v2/',
  DEFAULT_TEMPLATE_KEY: 'CoffeeShops',
  ADMIN_TEMPLATE,
  SUB_TEMPLATE,
  LOOKUP_TEMPLATE,
  STRUCTURED_DATA_TEMPLATE,
  SITEMAP_TEMPLATE,
  PROPOSAL_PAGE_TEMPLATE,
  PROPOSAL_TEMPLATE,
  PROFILE_TEMPLATE,
  WEBSITE_TEMPLATE,
  GENERIC_TEMPLATE,
  TOP_LEVEL_TEMPLATE_TYPES: [
  //# templateTypes that aren't used as part of other templates
  LOOKUP_TEMPLATE, SITEMAP_TEMPLATE, STRUCTURED_DATA_TEMPLATE, PROPOSAL_PAGE_TEMPLATE, PROPOSAL_TEMPLATE, WEBSITE_TEMPLATE, GENERIC_TEMPLATE],
  TEMPLATE_TYPE_VALUES: [LOOKUP_TEMPLATE, PROFILE_TEMPLATE, SITEMAP_TEMPLATE, STRUCTURED_DATA_TEMPLATE, PROPOSAL_PAGE_TEMPLATE, SUB_TEMPLATE, PROPOSAL_TEMPLATE, WEBSITE_TEMPLATE, REPEATER_MODULE_TEMPLATE, GENERIC_TEMPLATE],
  TEMPLATE_TYPE_OPTIONS: [{
    value: LOOKUP_TEMPLATE,
    text: 'Lookup Template'
  }, {
    value: WEBSITE_TEMPLATE,
    text: `${WEBSITE_PLUGIN__CONSTANTS.LABEL_PLURAL} Template`
  }, {
    value: PROFILE_TEMPLATE,
    text: 'Matter Profile Template'
  }, {
    value: PROPOSAL_PAGE_TEMPLATE,
    text: 'Proposal Page Template'
  }, {
    value: PROPOSAL_TEMPLATE,
    text: 'Proposal Template'
  }, {
    value: REPEATER_MODULE_TEMPLATE,
    text: 'Repeater Module Template'
  }, {
    value: SITEMAP_TEMPLATE,
    text: 'Sitemap Template'
  }, {
    value: STRUCTURED_DATA_TEMPLATE,
    text: 'Structured Data Template'
  }, {
    value: SUB_TEMPLATE,
    text: 'Subtemplate'
  }, {
    value: GENERIC_TEMPLATE,
    text: 'Generic'
  }]
};