"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const _ = require('lodash');
function curriedSelfModule_forFormatRequest(selfModule) {
  let {
    listerConfig,
    additionalQueryParams = {}
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    client: rubyClientId,
    subsite: subsiteId
  } = selfModule.getActiveRubyClientInfo();
  return (req, tableState, store) => {
    const {
      page,
      pageSize,
      filter,
      sortColumn,
      sortDirection
    } = tableState;
    const mergedStore = _extends({}, store, {
      pageProperties: _objectSpread(_objectSpread({}, store.pageProperties), {}, {
        currentPage: page,
        pageSize,
        sortColumns: sortColumn,
        sortAscending: sortDirection === 'asc'
      }),
      filter
    });
    const formattedQuery = selfModule.listerQueryFromGriddleState(mergedStore, {
      listerConfig,
      additionalQueryParams
    });
    req = req.query(formattedQuery);
    return req;
  };
}
function formatSuccess(resp, tableState) {
  const total = resp.total;
  return {
    page: tableState.page,
    maxPage: parseInt(total / tableState.pageSize, 10) + (total % tableState.pageSize ? 1 : 0),
    totalCount: total,
    data: resp.data
  };
}
module.exports = {
  curriedSelfModule_forFormatRequest,
  formatSuccess
};