"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rubyComponentPatcherForPopover = rubyComponentPatcherForPopover;
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function rubyComponentPatcherForPopover(popoverComponent) {
  const selfModule = this;
  const popoverStatesSelector = popoverComponent.statesSelector;
  popoverComponent.statesSelector = function (state) {
    return _extends({}, popoverStatesSelector.bind(popoverComponent)(state), selfModule.statesSelector.bind(selfModule)(state));
  };
  popoverComponent._formValueFromLocalState = function (selfState, isError, predicateFormatter, entireState) {
    let options = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
    if (selfState == undefined) {
      return undefined;
    }
    const children = this.getChildren();
    const arrOfReducedChildrenState = _lodash.default.reduce(children, (collector, child, index) => {
      const childID = child.getID();
      const childFormValue = child.formValueFromLocalState ? child._formValueFromLocalState(selfState[childID], isError, predicateFormatter, entireState) : undefined;
      if (childFormValue != undefined) {
        collector.push(childFormValue);
      }
      return collector;
    }, []);
    const reducedChildrenState = _extends({}, ...arrOfReducedChildrenState);
    const reducedChildrenAvail = Object.keys(reducedChildrenState).length > 0;
    const formValue = reducedChildrenAvail ? reducedChildrenState : undefined;
    const statusFormValue = selfModule.findDescendentBy(el => el.key() === 'status').formValue();
    const parentRawFormValue = _extends({}, statusFormValue, formValue);
    let modifiedFormValue = _extends({}, formValue, {
      start_timestamp: selfModule.getStartTimeStampIsVisible_fromRawFormValue(parentRawFormValue) ? formValue.start_timestamp : null,
      end_timestamp: selfModule.getEndTimeStampIsVisible_fromRawFormValue(parentRawFormValue) ? formValue.end_timestamp : null
    });
    if (options.excludeNull) {
      if (_lodash.default.isNil(modifiedFormValue.start_timestamp)) {
        delete modifiedFormValue.start_timestamp;
      }
      if (_lodash.default.isNil(modifiedFormValue.end_timestamp)) {
        delete modifiedFormValue.end_timestamp;
      }
    }
    return modifiedFormValue;
  };
  return popoverComponent;
}