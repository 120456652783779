// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[4].use[2]!../../../../ruby-styles/src/client/cssModules/colors.cssModule";
import ___CSS_LOADER_ICSS_IMPORT_1___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[4].use[2]!../../../../ruby-styles/src/client/cssModules/toolbar.cssModule";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_1___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".list-pagination__Toolbar___WP9bG {\n}\n.list-pagination__ToolbarGroup___I_kw2 {\n}\n.list-pagination__pagerRowsPerPage___hxYIp {\n    display: flex;\n    align-items: center;\n\n}\n.list-pagination__pagerLabel___XHrwh {\n    color: " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["black_40"] + ";\n    padding-right: 16px;\n    position: relative;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    overflow: hidden;\n}\n.list-pagination__pagerNav___JtWAb {\n    display: flex;\n    align-items: center;\n}\n\n.list-pagination__ToolbarSeparator___mYCX3 {\n}\n\n.list-pagination__pagerInput___g62AE {\n    width: 30px;\n}\n\n", "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-list-page/src/client/reactComponents/list-pagination.cssModule"],"names":[],"mappings":"AAEA;AAEA;AACA;AAEA;AACA;IACI,aAAa;IACb,mBAAmB;;AAEvB;AACA;IACI,mDAAe;IACf,mBAAmB;IACnB,kBAAkB;IAClB,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;AAEA;;AAEA;IACI,WAAW;AACf","sourcesContent":["@value black_40 from '@rubyapps/ruby-styles/src/client/cssModules/colors.cssModule';\n\n.Toolbar {\n    composes: Toolbar from '@rubyapps/ruby-styles/src/client/cssModules/toolbar.cssModule';\n}\n.ToolbarGroup {\n    composes: ToolbarGroup from '@rubyapps/ruby-styles/src/client/cssModules/toolbar.cssModule';\n}\n.pagerRowsPerPage {\n    display: flex;\n    align-items: center;\n\n}\n.pagerLabel {\n    color: black_40;\n    padding-right: 16px;\n    position: relative;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    overflow: hidden;\n}\n.pagerNav {\n    display: flex;\n    align-items: center;\n}\n\n.ToolbarSeparator {\n    composes: ToolbarSeparator from '@rubyapps/ruby-styles/src/client/cssModules/toolbar.cssModule';\n}\n\n.pagerInput {\n    width: 30px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black_40": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["black_40"] + "",
	"Toolbar": "list-pagination__Toolbar___WP9bG " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["Toolbar"] + "",
	"ToolbarGroup": "list-pagination__ToolbarGroup___I_kw2 " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["ToolbarGroup"] + "",
	"pagerRowsPerPage": "list-pagination__pagerRowsPerPage___hxYIp",
	"pagerLabel": "list-pagination__pagerLabel___XHrwh",
	"pagerNav": "list-pagination__pagerNav___JtWAb",
	"ToolbarSeparator": "list-pagination__ToolbarSeparator___mYCX3 " + ___CSS_LOADER_ICSS_IMPORT_1___.locals["ToolbarSeparator"] + "",
	"pagerInput": "list-pagination__pagerInput___g62AE"
};
export default ___CSS_LOADER_EXPORT___;
