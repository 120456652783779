"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _lodash = _interopRequireDefault(require("lodash"));
var _reactRedux = require("react-redux");
var _ViewLink = _interopRequireDefault(require("./ViewLink"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function generator() {
  const selfModule = this;
  const {
    parentForm
  } = selfModule.getDependencies();
  function mapStateToProps( /*state*/
  ) {
    const status = _lodash.default.result(parentForm, 'getState.pristineFormData.status');
    const link = status && status == 'active' && (_lodash.default.get(selfModule.getProps(true), 'link') || _lodash.default.result(parentForm, 'getState.pristineFormData.url'));
    return {
      label: selfModule.props.label || 'View',
      link,
      disabled: !link || _lodash.default.get(selfModule, 'props.disabled')
    };
  }
  return (0, _reactRedux.connect)(mapStateToProps)(_ViewLink.default);
}