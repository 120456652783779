"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
/* jshint -W138 */

module.exports = function (state, action) {
  const {
    TYPES
  } = this.getAction();
  const {
    type,
    payload
  } = action;
  if (!state) {
    state = this.getInitialState();
  }
  switch (type) {
    case TYPES.SET_SELECTED_INDEX:
      return _extends({}, state, {
        selectedIndex: payload.selectedIndex
      });
    case TYPES.RESET_STORE:
      return _extends({}, state, this.getInitialState());
    default:
      return state;
  }
};