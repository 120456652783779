"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getBioUpdatesMenuItemsForSelfModule;
//# TODO: see if we can pass the icon component instead of the string
function getBioUpdatesMenuItemsForSelfModule(selfModule) {
  const {
    global_data: globalDataForm
  } = selfModule.getForms();
  const {
    GLOBAL_DATA_ID: globalDataInstanceId //# DEPRECATED CONFIG VALUE
  } = selfModule.getFrontendSettings();
  const globalDataTemplateId = globalDataForm ? globalDataForm.id : null;
  return globalDataForm ? {
    name: 'Global Data (old)',
    icon: 'ruby/icons/content/global-data',
    permissions: [{
      model: 'content',
      template: globalDataTemplateId,
      ruby_client: globalDataForm.ruby_client_fk,
      subsite: 1,
      action: '*'
    }],
    route: `/app/content/en/global_data/${globalDataInstanceId}/edit`
  } : null;
}