"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getSubsiteOptions_fromSettings = exports.getRubyClientInfo_fromSettings_forRoute = void 0;
var _lodash = _interopRequireDefault(require("lodash"));
var _memoizee = _interopRequireDefault(require("memoizee"));
var _rubyMemoize = require("../../../ruby-memoize");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
// Cache the permission check when given the same permission/user reference
const getRubyClientInfo_fromSettings_forRoute = (0, _memoizee.default)(raw_getRubyClientInfo_fromSettings_forRoute, _extends({}, _rubyMemoize.defaultMemoizeeOptions, {
  length: 2
}));
exports.getRubyClientInfo_fromSettings_forRoute = getRubyClientInfo_fromSettings_forRoute;
const getSubsiteOptions_fromSettings = (0, _memoizee.default)(raw_getSubsiteOptions_fromSettings, _extends({}, _rubyMemoize.defaultMemoizeeOptions, {
  length: 1
}));

//# Walks this tree:
/*

    rubyClients_routeTree = {
        //# top-level are defaults
        "subsite":1
        , "subsiteUrlIdentifier": "en"
        , "client": 3

        "children": {
            "app": {
                "children": {
                    "content": {
                        "children": {
                            "cn": {
                                "subsite": 3
                                "subsiteUrlIdentifier": "cn"
                            }
                        }
                    }
                    , "proposal": {
                        "subsite": 2
                    }
                }
            }
         }
    }


   
   currentNode = {
        "app": {
            "subsite": 1
            , "client": 3
            , "children": {
                "content": {
                    "subsite": 1
                    "children": {
                        "cn": {
                            "subsite": 2
                        }
                    }
                }
                , "proposal": {
                    "subsite": 2
                }
            }
        }
    }

    currentKeypathArray = ['app', 'proposal'];
*/
exports.getSubsiteOptions_fromSettings = getSubsiteOptions_fromSettings;
function _rubyClientInfoReducer(collector, currentNode, currentKeypathArray) {
  if (currentNode == undefined) {
    return collector;
  }
  const currentKey = currentKeypathArray[0];
  const childKeypathArray = currentKeypathArray.slice(1);
  const nodeForKey = currentNode[currentKey];
  if (nodeForKey) {
    collector = _extends({}, collector, _lodash.default.omit(nodeForKey, ['children']));

    //# recursively call
    if (childKeypathArray.length && nodeForKey.hasOwnProperty('children')) {
      collector = _rubyClientInfoReducer(collector, nodeForKey.children, childKeypathArray);
    }
  }
  return collector;
}

//# returns {subsite, client}
function raw_getRubyClientInfo_fromSettings_forRoute(settings, route) {
  const {
    rubyClients_routeTree = {}
  } = settings;
  const routeArray = route.split('/').filter(value => !!value);
  const rubyClientInfo = _rubyClientInfoReducer(_extends({}, _lodash.default.omit(rubyClients_routeTree, ['children'])), rubyClients_routeTree.children, routeArray);
  return rubyClientInfo;
}
function getSubsiteOptions_fromNode(node) {
  const thisOption = _extends({}, _lodash.default.pick(node, ['subsite', 'subsiteLabel']));
  const childOptions = node.children ? _lodash.default.map(node.children, childNode => {
    return getSubsiteOptions_fromNode(childNode);
  }) : [];
  return _lodash.default.flatMap([].concat(thisOption, childOptions)).filter(el => !_lodash.default.isEmpty(el));
}

//# NOTE: some subsite values repeat (eg. Websites and Content share the same subsite value)
function raw_getSubsiteOptions_fromSettings(settings) {
  const {
    rubyClients_routeTree = {}
  } = settings;
  const rubySubsiteOptions = getSubsiteOptions_fromNode(rubyClients_routeTree);
  const rubySubsiteOptionsBySubsite = rubySubsiteOptions.reduce((collector, subsiteSpec) => {
    const {
      subsite,
      subsiteLabel
    } = subsiteSpec;
    if (!collector[subsite]) {
      collector[subsite] = {
        subsiteLabel: []
      };
    }
    collector[subsite].subsiteLabel = collector[subsite].subsiteLabel.concat(subsiteLabel);
    return collector;
  }, {});
  const mergedRubySubsiteOptions = _lodash.default.map(rubySubsiteOptionsBySubsite, (value, key) => {
    return {
      subsiteLabel: value.subsiteLabel.join(' / '),
      subsite: key
    };
  });
  return mergedRubySubsiteOptions;
}