// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InternationalAddress__columnWrapper___v2hY9 {\n    display: flex;\n}\n.InternationalAddress__leftColumn___WY2ZQ {\n    width: 300px;\n    margin-right: 20px;\n}\n.InternationalAddress__rightColumn___CUTCi {\n    flex: 1;\n}\n\n.InternationalAddress__placeholderWrapper___POY6F {\n    height: 100%;\n    width: 100%;\n    text-align: center;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.InternationalAddress__placeholderContent___uLamP {\n    width: 50%;\n}\n", "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-international-address/src/client/reactComponents/InternationalAddress.cssModule"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,OAAO;AACX;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;AACA;IACI,UAAU;AACd","sourcesContent":[".columnWrapper {\n    display: flex;\n}\n.leftColumn {\n    width: 300px;\n    margin-right: 20px;\n}\n.rightColumn {\n    flex: 1;\n}\n\n.placeholderWrapper {\n    height: 100%;\n    width: 100%;\n    text-align: center;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n.placeholderContent {\n    width: 50%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"columnWrapper": "InternationalAddress__columnWrapper___v2hY9",
	"leftColumn": "InternationalAddress__leftColumn___WY2ZQ",
	"rightColumn": "InternationalAddress__rightColumn___CUTCi",
	"placeholderWrapper": "InternationalAddress__placeholderWrapper___POY6F",
	"placeholderContent": "InternationalAddress__placeholderContent___uLamP"
};
export default ___CSS_LOADER_EXPORT___;
