"use strict";

var _lightRubyTheme = _interopRequireDefault(require("./materialThemes/lightRubyTheme"));
var _lightRubyTheme_bright = _interopRequireDefault(require("./materialThemes/lightRubyTheme_bright"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
require('./styles/index.css');

//require('@fortawesome/fontawesome-free/css/fontawesome.css');
//require('@fortawesome/fontawesome-free/css/regular.css');
require('@fortawesome/fontawesome-free/css/all.css');
require('@fortawesome/fontawesome-free/css/v4-shims.css');
module.exports = {
  materialTheme: {
    default: _lightRubyTheme.default,
    bright: _lightRubyTheme_bright.default
  }
};