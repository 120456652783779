"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const styles = {
  MainCardText: {
    style: {
      position: 'relative',
      minHeight: 45,
      paddingLeft: 32,
      paddingRight: 32
    }
  },
  FloatingPlusMenu: {
    style: {
      position: 'absolute',
      bottom: 10,
      right: 15
    }
  },
  cardWrapper: {
    style: {}
  },
  placeholderWrapper: {
    style: {}
  },
  ReactGridLayout: {
    style: {}
  }
};
var _default = styles;
exports.default = _default;