"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _datepicker = _interopRequireDefault(require("@rubyapps/ruby-styles/src/client/styleObjects/datepicker"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default = {
  DatePicker: _datepicker.default
};
exports.default = _default;