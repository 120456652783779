"use strict";

module.exports = {
  COMPONENT_NAME: 'rubyComponentSitemapPage',
  ROUTE_PATH: 'content/:subsite/sitemap',
  REDUCER_KEYS_TO_QUERY_PARAMS: {
    sortColumn: 'sname',
    sortDirection: 'sdir',
    currentPage: 'p',
    resultsPerPage: 'psize',
    searchTerm: 'search'
  },
  CMS_URL_PREFIX: '/cms',
  API_KEY: 'content',
  FILTER_TYPES: {
    NAV: 'NAV',
    CONTENT_FILTER: 'CONTENT.FILTER'
  },
  HOOKS: {
    SKIP_HIERARCHY_DELETION: 'skipHierarchyDeletion'
  }
};