"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.makeRequest = makeRequest;
var _superagent = _interopRequireDefault(require("superagent"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//# NOTE: the provider is responsible for 
//# noting that we're loading by dispatching startLoadingActionGenerator
function makeRequest(remoteConfig, tableState, _ref) {
  let {
    startLoadingActionGenerator,
    successActionGenerator,
    remoteErrorActionGenerator,
    griddleState
  } = _ref;
  return dispatch => {
    const {
      url,
      requestHeaders,
      formatRequest,
      formatSuccess,
      formatError
    } = remoteConfig;

    // Set up the request.
    let loadRequest = _superagent.default.get(url);

    // If the   request needs to be constructed differently, pass to the override function.
    if (formatRequest) {
      loadRequest = formatRequest(loadRequest, tableState, griddleState);
    } else {
      const {
        page,
        pageSize,
        filter,
        sortColumn,
        sortDirection
      } = tableState;

      // Add parameters
      loadRequest = loadRequest.query({
        page: page,
        pageSize: pageSize,
        filter: filter,
        sortColumn: sortColumn && sortColumn.length > 0 ? sortColumn[0] : '',
        sortDirection: sortDirection
      });

      // Add any headers added to the remote config
      if (requestHeaders) {
        Object.keys(requestHeaders).forEach(key => {
          loadRequest = loadRequest.set(key, requestHeaders[key]);
        });
      }
    }
    if (loadRequest) {
      // Indicate that our AJAX request is starting.
      dispatch(startLoadingActionGenerator(loadRequest));
      loadRequest.end(function (err, res) {
        if (res.ok) {
          let successResponse = res.body;

          // Format if necessary.
          if (formatSuccess) {
            successResponse = formatSuccess(successResponse, tableState, griddleState);
          }

          // Dispatch the success
          dispatch(successActionGenerator(successResponse));
        } else {
          let errorResponse = res.body;

          // Format if necessary
          if (formatError) {
            errorResponse = formatError(err, errorResponse);
          }

          // Dispatch the error
          dispatch(remoteErrorActionGenerator(err, errorResponse));
        }
      });
    } else {
      dispatch(successActionGenerator({
        data: [],
        total: 0
      }));
    }
  };
}