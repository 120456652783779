"use strict";

var _constants = require("./constants");
const superagent = require('superagent');
const superagentCache = require('superagent-cache');
const superagentCache__utils = require('superagent-cache/utils');
const queryString = require('query-string');
const _ = require('lodash');
const Promise = require('bluebird');
const AgentBase = require('./agent-base');
const methods = require('methods');
superagentCache(superagent, null, {
  preventDuplicateCalls: true,
  forceUpdate: true
  //# NOTE: we don't want to cache anything because right now there are a lot of modules that uses
  //# superagent, where there's an expectation that it's not cached
  //# so if we want to actually cache responses, we'll need to 
  //# update the consumers to explicitly call on .forceUpdate(true) for the calls that we don't want cached
  //, expiration: 30 
});

//# patch abort to clear out superagent-cache's pendingCache
const parentAbort = superagent.Request.prototype.abort;
superagent.Request.prototype.abort = function () {
  //const key = superagentCache__utils.keygen(superagent, this, superagent.defaults);

  //console.log('aborting:', key);
  //delete superagent.pendingRequests[key];

  //# unfortunately the key generated doesn't match the pendingRequests key, we'll just have to wipe out everything

  superagent.pendingRequests = {};
  return parentAbort.apply(this, arguments);
};

//# Monkey patch superagent-cache's method of parsing query parameters
//# Its arrayToObj and stringToObj methods roll their own query string parsing
//# that does not handle multiple values for the same parameter key.
//# Instead of parsing this query parameter as an array with multiple values, it parses it as a single value
//# for the duplicated parameter key, whichever value for that key appears last in the string.
//#
//# This causes `getQueryParams` to calculate incorrect query parameters and
//# therefore generate incorrect cache keys that may cause false-positive cache collisions.
superagentCache__utils.getQueryParams = function (req) {
  if (req && req.qs && !superagentCache__utils.isEmpty(req.qs)) {
    return req.qs;
  } else if (req && req.qsRaw) {
    return arrayToObj(req.qsRaw);
  } else if (req && req.req) {
    return stringToObj(req.req.path);
  } else if (req && req._query) {
    return stringToObj(req._query.join('&'));
  }
  return null;
  function arrayToObj(arr) {
    if (!arr || !arr.length) {
      return null;
    }
    const str = arr.join('&');
    return stringToObj(str);
  }
  function stringToObj(str) {
    if (!str) {
      return null;
    }
    return queryString.parse(str);
  }
};

// reference: https://github.com/ladjs/superagent/blob/master/src/node/agent.js
function Agent(options) {
  if (!(this instanceof Agent)) {
    return new Agent(options);
  }
  AgentBase.call(this);
  if (options) {
    if (options.ca) {
      this.ca(options.ca);
    }
    if (options.key) {
      this.key(options.key);
    }
    if (options.pfx) {
      this.pfx(options.pfx);
    }
    if (options.cert) {
      this.cert(options.cert);
    }
    if (options.rejectUnauthorized === false) {
      this.disableTLSCerts();
    }
  }
  for (const name of methods) {
    this[name] = this[name].bind(this);
  }
  ;
}
Agent.prototype = Object.create(AgentBase.prototype);
for (const name of methods) {
  const methodName = name.toUpperCase();
  Agent.prototype[name] = function (url, fn) {
    const request_ = new superagent.Request(methodName, url);
    this._setDefaults(request_);
    if (fn) {
      request_.end(fn);
    }
    return request_;
  };
}
Agent.prototype.del = Agent.prototype.delete;
let isOnline = false;

// check if the window object is available 
if (!(typeof window === 'undefined')) {
  window.addEventListener("load", () => {
    isOnline = navigator.onLine ? true : false;
  });
  window.addEventListener("offline", () => {
    isOnline = false;
  });
  window.addEventListener("online", () => {
    isOnline = true;
  });
} else {
  isOnline = true;
}

// assume readinessProbe is fully qualified 
function probeReadiness() {
  let retryOptions = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let retryCount = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  const {
    readinessProbe = _constants.READINESS_PROBE,
    delay = _constants.RETRY_DELAY,
    maxDelay = _constants.MAX_RETRY_DELAY,
    backoffRate = _constants.BACKOFF_RATE
  } = retryOptions;

  //# progressive backoff delay until max delay
  const backoffDelay = Math.trunc(Math.min(backoffRate * retryCount + delay, maxDelay));
  if (!isOnline) {
    return Promise.delay(backoffDelay).then(() => {
      return probeReadiness(retryOptions, retryCount + 1);
    });
  } else {
    return superagent.options(readinessProbe).then(res => {
      return superagent.get(readinessProbe).then(res => {
        return Promise.resolve();
      }).catch(err => {
        if (err.status === 405 && err.name === 'NotReadyError') {
          return Promise.delay(backoffDelay).then(() => {
            return probeReadiness(retryOptions, retryCount + 1);
          });
        } else {
          return Promise.resolve();
        }
      });
    }).catch(err => {
      if (err.message && err.message.indexOf("the network is offline") >= 0) {
        return Promise.delay(backoffDelay).then(() => {
          return probeReadiness(retryOptions, retryCount + 1);
        });
      } else {
        return Promise.resolve();
      }
    });
  }
}
function superagentFactory() {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const {
    retryOptions
  } = options;
  const newSuperagent = Agent();
  if (_.isNil(retryOptions)) {
    return newSuperagent;
  } else {
    let cachedPromisedProbe;
    newSuperagent.get = new Proxy(newSuperagent.get, {
      apply(target, thisArg, argumentsList) {
        const requestGetObj = Reflect.apply(target, thisArg, argumentsList);
        requestGetObj._retry = new Proxy(requestGetObj._retry, {
          apply(target, thisArg, argumentsList) {
            if (!cachedPromisedProbe) {
              cachedPromisedProbe = probeReadiness(retryOptions);
            }
            return cachedPromisedProbe.then(() => {
              cachedPromisedProbe = null;
              return Reflect.apply(target, thisArg, argumentsList);
            });
          }
        });
        return requestGetObj.retry(_constants.MAX_RETRIES, function (err, res) {
          // by default, superagent retries 429 but we don't want that
          if (res && res.status && res.status === 429) return false;
          // https://github.com/ladjs/superagent/blob/master/src/request-base.js#L191
          // return undefined so it falls back to superagent defaults
          return;
        }, retryOptions);
      }
    });
    return newSuperagent;
  }
}
;
module.exports = superagentFactory;