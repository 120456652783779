"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _layout = require("@rubyapps/ruby-styles/src/client/styleObjects/layout");
const thumbtackStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  right: 0,
  height: '44px',
  width: '44px',
  padding: 0
};
const stylesObject = {
  Drawer: {
    containerStyle: {
      overflow: 'visible'
    }
  },
  ListItem: {
    style: {
      padding: '0 0 9px'
    },
    innerDivStyle: {
      paddingLeft: 56
    }
  },
  Icon: {
    style: {
      display: 'block',
      fill: 'rgb(117, 117, 117)',
      height: 24,
      width: 24,
      transition: 'all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
      position: 'absolute',
      top: 0,
      margin: 12,
      left: 4,
      textAlign: 'center'
    }
  },
  Toolbar: {
    style: {
      height: _layout.headerHeight
    }
  },
  ToolbarGroup: {
    style: {
      justifyContent: 'flex-start',
      width: '100%'
    }
  },
  Paper: {
    style: {
      position: 'relative'
    }
  },
  MenuButton: {
    style: {
      marginRight: -6
    }
  },
  IconButton__thumbtack__inactive: {
    style: thumbtackStyle,
    iconStyle: {
      color: 'rgba(0, 0, 0, 0.3)',
      iconHoverColor: 'rgba(0, 0, 0, 0.87)',
      transform: 'rotate(20deg)'
    }
  },
  IconButton__thumbtack__active: {
    style: thumbtackStyle,
    iconStyle: {
      color: 'rgba(0, 0, 0, 0.87)',
      iconHoverColor: 'rgba(0, 0, 0, 0.3)',
      transform: 'rotate(0deg)'
    }
  }
};
var _default = stylesObject;
exports.default = _default;