"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RemotePlugin = RemotePlugin;
exports.superagentProvider = void 0;
var actions = _interopRequireWildcard(require("./actions"));
var constants = _interopRequireWildcard(require("./constants"));
var helpers = _interopRequireWildcard(require("./helpers"));
var reducers = _interopRequireWildcard(require("./reducer"));
var _initialState = _interopRequireDefault(require("./initial-state"));
var _bindRemoteProvider = _interopRequireDefault(require("./util/bind-remote-provider"));
var _superagentProvider = _interopRequireWildcard(require("./providers/superagent-provider"));
exports.superagentProvider = _superagentProvider;
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _getRequireWildcardCache(nodeInterop) { if (typeof WeakMap !== "function") return null; var cacheBabelInterop = new WeakMap(); var cacheNodeInterop = new WeakMap(); return (_getRequireWildcardCache = function (nodeInterop) { return nodeInterop ? cacheNodeInterop : cacheBabelInterop; })(nodeInterop); }
function _interopRequireWildcard(obj, nodeInterop) { if (!nodeInterop && obj && obj.__esModule) { return obj; } if (obj === null || typeof obj !== "object" && typeof obj !== "function") { return { default: obj }; } var cache = _getRequireWildcardCache(nodeInterop); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj.default = obj; if (cache) { cache.set(obj, newObj); } return newObj; }
//import combineRemoteActions from './util/combine-remote-actions';

// Remote providers

function RemotePlugin(provider, remoteConfig) {
  const remoteProvider = (0, _bindRemoteProvider.default)(provider, remoteConfig);
  return {
    name: 'GriddleRemote',
    actions: actions,
    storeBoundActions: [actions.requestToLoadData, actions.filterData, actions.setPageSize, actions.sort, actions.addSortColumn, actions.loadNext, actions.loadPrevious, actions.loadPage, actions.refreshCurrentPage],
    constants,
    helpers: helpers,
    states: (0, _initialState.default)(remoteProvider),
    reducers,
    components: {}
  };
}
;