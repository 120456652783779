"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _ConnectorMappersGenerator = _interopRequireDefault(require("@rubyapps/ruby-component-mixin-field-base/src/client/reactComponents/ConnectorMappersGenerator"));
var _Toggles = _interopRequireDefault(require("./Toggles"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function generator() {
  const selfModule = this;
  const {
    selfSelector
  } = selfModule.getDependencies();
  const {
    mapStateToProps: defaultMapStateToProps
  } = _ConnectorMappersGenerator.default.call(this, _Toggles.default);
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const hydratedProps = selfModule.getProps(true);
    const mixinFieldProps = selfModule.fieldProps_fromState_andOwnProps(state, ownProps);
    const selfStateProps_withoutNullValues = _.omitBy(hydratedProps, _.isNil);
    const formattedError = selfModule.formattedErrorFromState(state);
    const mappedDefaultProps = defaultMapStateToProps.apply(null, arguments);
    const mappedProps = _extends({}, ownProps, mixinFieldProps, mappedDefaultProps, {
      id: selfModule.getID(),
      fieldKey: selfModule.props.key //key is not exposed to the react component
      ,
      isMounted: selfState.isMounted
    }, selfStateProps_withoutNullValues, hydratedProps.key ? _.pick(selfState.fields[hydratedProps.key], ['value']) : {}, hydratedProps.optionToHTML ? {
      optionToHTML: selfModule.hydratedToHTMLFromProps({
        toHTML: hydratedProps.optionToHTML
      })
    } : {}, formattedError);
    return mappedProps;
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
    };
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_Toggles.default);
}