"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
//const rubyLogger = require('@rubyapps/ruby-logger');
//const logger = rubyLogger.getLogger('ruby-middleware-fields');

const _ = require('lodash');
const Validator = require('@rubyapps/ruby-validator-wrapper');
const CONSTANTS = {
  SET_FIELD_SUFFIX: 'SET_FIELD_VALUE_BY_KEY',
  SET_FIELD_ERROR_MESSAGE_SUFFIX: 'SET_FIELD_ERROR_MESSAGE_BY_KEY',
  SET_FIELD_ERROR_OBJECT_SUFFIX: 'SET_FIELD_ERROR_OBJECT_BY_KEY',
  MERGE_FIELD_ERROR_OBJECT_SUFFIX: 'MERGE_FIELD_ERROR_OBJECT_BY_KEY',
  VALIDATE_ALL_FIELDS_SUFFIX: 'VALIDATE_FIELDS',
  SET_ALL_FIELDS_SUFFIX: 'SET_ALL_FIELDS',
  RESET_STORE_SUFFIX: 'RESET_STORE',
  UPDATE_USER_MODIFIED_TIMESTAMP: 'UPDATE_USER_MODIFIED_TIMESTAMP'
};

/*
    //# this middleware expects an action of the following type:
    {
        type: '.../SET_FIELD_VALUE_BY_KEY'
        , payload: {
            key: ''
            , value: ''
        }
    }
    //# if an error is detected, it will insert an object into the payload:
    {
        payload: {
            error: {
                message:
            }
        }
    }
*/

/*
    constraints = {
        <constraint>: {
            params: [...] //# additional params that should be passed into the validator
            , message: ''
        }
    }
*/

const defaultConstraintParamsByKey = {
  required: [true]
};
const defaultConstraintMessage = {
  required: 'Field is required'
};
function _validateField_withConstraints(fieldLabel, fieldKey, fieldValue) {
  let constraints = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  let errorMessagesByKeyword = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
  var ruleKeys = Object.keys(constraints);
  const errorObjects = ruleKeys.reduce(function (collector, ruleKey) {
    // Check for validator function
    if (!Validator[ruleKey]) {
      //throw( "Invalid validator function: " + ruleKey );
      return collector;
    } else if (_.isNil(constraints[ruleKey])) {
      return collector;
    }
    const stateParams = !_.isNil(constraints[ruleKey]) ? constraints[ruleKey].params || constraints[ruleKey] : undefined;
    const defaultParams = !_.isNil(defaultConstraintParamsByKey[ruleKey]) ? defaultConstraintParamsByKey[ruleKey] : [];
    const constraintParams = [fieldValue].concat(!_.isNil(stateParams) ? stateParams : defaultParams);
    var isOK = Validator[ruleKey].apply(null, constraintParams);
    if (!isOK) {
      //# TODO: rely on validator to get the error message instead
      const errorMessage = _.get(errorMessagesByKeyword, ruleKey) || Validator.getErrorMessage({
        label: fieldLabel,
        verify: constraints
      }, ruleKey);
      collector.push({
        message: errorMessage
      });
    }
    return collector;
  }, []);
  return errorObjects;
}
function _validateFieldsInStateByKeys(state, keys) {
  const formValue = this.formValueFromLocalState(state);
  const fields = state.fields ? state.fields : {};
  return _.reduce(keys, (collector, fieldKey) => {
    const fieldObj = fields[fieldKey];

    //# NOTE: probably doesn't need to call on selfModule.getConstraints_fromState
    //# since this isn't called by the field components
    const errors = _validateField_withConstraints(fieldObj.label, fieldKey, formValue[fieldKey] || fieldObj.value //# prefer the formValue
    , fieldObj.constraints, this.props.errorMessagesByKeyword);
    collector[fieldKey] = _extends({}, fieldObj, {
      errors: errors //# include all errors in case we need it in the future
      ,
      error: errors[0]
    });
    return collector;
  }, {});
}
function _validateAllFieldsInStateByKey(state) {
  const fields = state.fields ? state.fields : {};
  const fieldKeys = Object.keys(fields);
  return _validateFieldsInStateByKeys.call(this, state, fieldKeys);
}

//# 20160908 DEPRECATED. Don't want to use middleware because
//# fields gets loaded in dynamically
const fieldMiddleware = function (store) {
  const selfModule = this;
  const localStateSelector = selfModule.getDefaultSelector();
  const selfAction = selfModule.getAction();
  return next => action => {
    //# check if we need to validate
    if (action.type === selfAction.TYPES.SET_FIELD_VALUE_BY_KEY && action.payload && action.payload.ignoreValidation != true) {
      const localState = localStateSelector(store.getState());
      const fields = localState.fields;
      const fieldKey = action.payload.key;
      const fieldValue = action.payload.value;
      const fieldSpec = fields[fieldKey];
      const fieldLabel = fieldSpec ? fieldSpec.label : '';
      const errors = _validateField_withConstraints(fieldLabel, fieldKey, fieldValue, fieldSpec ? fieldSpec.constraints : {}, selfModule.props.errorMessagesByKeyword);
      const newActionPayload = _extends({}, action.payload, {
        errors: errors,
        error: errors[0]
      });
      const result = next(_extends({}, action, {
        payload: newActionPayload
      }));
      return result;
    } else if (action.type === selfAction.TYPES.VALIDATE_ALL_FIELDS) {
      //# validate and set all fields
      const localState = localStateSelector(store.getState());
      const validatedFieldsObject = _validateAllFieldsInStateByKey.call(this, localState);
      const result = store.dispatch({
        type: selfAction.TYPES.SET_ALL_FIELDS,
        payload: {
          fields: validatedFieldsObject
        }
      });
      return result;
    } else {
      return next(action);
    }
  };
};
function _reduceFieldState_withAction() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let action = arguments.length > 1 ? arguments[1] : undefined;
  const {
    payload
  } = action;
  return _extends({}, state, payload.hasOwnProperty('value') ? {
    value: action.payload.value,
    userModifiedTimestamp: new Date()
  } : {}, payload.hasOwnProperty('error') ? {
    error: _extends({}, state.error, action.payload.error)
  } : {}, payload.hasOwnProperty('userModifiedTimestamp') ? {
    userModifiedTimestamp: action.payload.userModifiedTimestamp
  } : {});
}
function _reduceFieldState_withErrorObjectAction(state, action) {
  const {
    payload
  } = action;
  return _extends({}, state, payload.hasOwnProperty('error') ? {
    error: action.payload.error
  } : {});
}
//# assume state is fields
//# returns
//  {
//      fields: {
//          [key]: {
//              label: 'Field Label'
//              value:
//              error: {
//                  message: <string>
//              }
//              constraints: {
//                  [constraint key]: <param>
//              }
//          }
//      }
//  }
function _reducer(state, action) {
  const selfModule = this;
  const {
    TYPES
  } = selfModule.getAction();
  const payload__key = _.get(action, 'payload.key');
  const payload__error = _.get(action, 'payload.error', {});
  switch (action.type) {
    case TYPES.UPDATE_USER_MODIFIED_TIMESTAMP:
    case TYPES.SET_FIELD_VALUE_BY_KEY:
      const updatedFields = _extends({}, state.fields, {
        [payload__key]: _reduceFieldState_withAction(state.fields[payload__key], action)
      });
      return _extends({}, state, {
        fields: updatedFields
      });
    case TYPES.SET_FIELD_ERROR_OBJECT_BY_KEY:
      if (payload__key) {
        //# NOTE: if we don't have a payload__key, we set the error in the root-level
        //# of the field state
        //# this fallback is really used for field-set like fields that have keys
        //# like repeaters
        const updatedFields_withReplacedError = _extends({}, state.fields, {
          [payload__key]: _reduceFieldState_withAction(state.fields[payload__key], action)
        });
        return _extends({}, state, {
          fields: updatedFields_withReplacedError
        });
      } else {
        return _extends({}, state, {
          error: payload_error
        });
      }
    case TYPES.SET_FIELD_ERROR_MESSAGE_BY_KEY:
    case TYPES.MERGE_FIELD_ERROR_OBJECT_BY_KEY:
      if (payload__key) {
        const updatedFields_withMergedError = _extends({}, state.fields, {
          [payload__key]: _reduceFieldState_withAction(state.fields[payload__key], action)
        });
        return _extends({}, state, {
          fields: updatedFields_withMergedError
        });
      } else {
        const mergedErrorObject = _extends({}, state.error, payload__error);
        return _extends({}, state, {
          error: mergedErrorObject
        });
      }
    case TYPES.SET_ALL_FIELDS:
      const payloadFields = action.payload.fields;
      const allUpdatedFields = _.reduce(payloadFields, (collector, fieldObj, fieldKey) => {
        const payload__fieldObj = fieldObj;
        const state__fieldObj = state.fields[fieldKey] || {};
        collector[fieldKey] = _extends({}, state__fieldObj, payload__fieldObj);
        return collector;
      }, _objectSpread({}, state.fields)); //# make sure we start with a shallow copy

      return _extends({}, state, {
        fields: allUpdatedFields
      });
    case TYPES.RESET_STORE:
      //# NOTE: all reducers gets called so 
      //# we need to make sure we set the initial state correct
      return _objectSpread(_objectSpread({}, state), selfModule.getInitialState(state));
    default:
      return state;
  }
}
function getAction_usingTypePrefix(typePrefix) {
  const TYPES = {
    SET_FIELD_VALUE_BY_KEY: typePrefix + CONSTANTS.SET_FIELD_SUFFIX,
    SET_FIELD_ERROR_MESSAGE_BY_KEY: typePrefix + CONSTANTS.SET_FIELD_ERROR_MESSAGE_SUFFIX,
    SET_FIELD_ERROR_OBJECT_BY_KEY: typePrefix + CONSTANTS.SET_FIELD_ERROR_OBJECT_SUFFIX,
    MERGE_FIELD_ERROR_OBJECT_BY_KEY: typePrefix + CONSTANTS.MERGE_FIELD_ERROR_OBJECT_SUFFIX,
    VALIDATE_ALL_FIELDS: typePrefix + CONSTANTS.VALIDATE_ALL_FIELDS_SUFFIX,
    SET_ALL_FIELDS: typePrefix + CONSTANTS.SET_ALL_FIELDS_SUFFIX,
    RESET_STORE: typePrefix + CONSTANTS.RESET_STORE_SUFFIX,
    UPDATE_USER_MODIFIED_TIMESTAMP: typePrefix + CONSTANTS.UPDATE_USER_MODIFIED_TIMESTAMP
  };
  return {
    TYPES,
    generators: {
      resetStore: function () {
        return {
          type: TYPES.RESET_STORE
        };
      }
      /**
       * should only be used by components that does not user setFieldValueByKey to update state (eg. Repeater)
       */,
      updateUserModifiedTimestamp: function (key) {
        key = key ? key : this.key ? this.key() : this.props.key;
        return {
          type: TYPES.UPDATE_USER_MODIFIED_TIMESTAMP,
          payload: {
            key,
            userModifiedTimestamp: new Date()
          }
        };
      },
      validateFields: function (fieldKeys) {
        const localState = this.getState();
        const validatedFieldsObject = _validateFieldsInStateByKeys.call(this, localState, fieldKeys);
        return {
          type: TYPES.SET_ALL_FIELDS,
          payload: {
            fields: validatedFieldsObject
          }
        };
      },
      validateAllFields: function () {
        const localState = this.getState();
        const validatedFieldsObject = _validateAllFieldsInStateByKey.call(this, localState);
        return {
          type: TYPES.SET_ALL_FIELDS,
          payload: {
            fields: validatedFieldsObject
          }
        };
        return {
          type: TYPES.VALIDATE_ALL_FIELDS
        };
      },
      setAllFieldsWithObj: function (obj) {
        return {
          type: TYPES.SET_ALL_FIELDS,
          payload: {
            fields: obj
          }
        };
      },
      setFieldValue: function (value) {
        const key = this.key ? this.key() : this.props.key;
        const actions = this.getAction().generators;
        return actions.setFieldValueByKey(value, key);
      },
      setFieldValueByKey: function (value, key, ignoreValidation) {
        const selfModule = this;
        const localState = selfModule.getState();
        const prevErrorMessage = _.get(localState, ['fields', key, 'error', 'message']);
        const fields = localState.fields;
        const fieldKey = key;
        const fieldValue = value;
        const fieldSpec = fields[fieldKey];
        const fieldLabel = fieldSpec ? fieldSpec.label : '';
        const fieldConstraints = this.getConstraints_forKey_fromState(fieldKey);
        const action = {
          type: TYPES.SET_FIELD_VALUE_BY_KEY,
          payload: {
            value: fieldValue,
            key,
            ignoreValidation
          }
        };
        if (!ignoreValidation) {
          const errors = _validateField_withConstraints(fieldLabel, fieldKey, fieldValue, fieldConstraints, selfModule.props.errorMessagesByKeyword);
          const newActionPayload = _extends({}, action.payload, {
            errors: errors,
            error: errors[0] ? errors[0] : {
              message: null //# explicitly say message is null
            }
          });

          const newErrorMessage = _.get(errors[0], 'message', errors[0]);
          const newAction = _extends({}, action, {
            payload: newActionPayload
          });
          return (dispatch, getState) => {
            dispatch(newAction);
            if (newErrorMessage != prevErrorMessage) {
              selfModule.refreshParentErrors && selfModule.refreshParentErrors();
            }
          };
        }
        return action;
      },
      setFieldErrorMessageByKey: function (message, key) {
        return {
          type: TYPES.SET_FIELD_ERROR_MESSAGE_BY_KEY,
          payload: {
            error: {
              message: _.isString(message) ? {
                msg: message,
                level: 'error'
              } : message
            },
            key
          }
        };
      },
      setFieldErrorObjectByKey: function (error, key) {
        return {
          type: TYPES.SET_FIELD_ERROR_OBJECT_BY_KEY,
          payload: {
            error,
            key
          }
        };
      },
      mergeFieldErrorObjectByKey: function (error, key) {
        return {
          type: TYPES.MERGE_FIELD_ERROR_OBJECT_BY_KEY,
          payload: {
            error,
            key
          }
        };
      },
      clearErrorsWithKeys_inState: function () {
        let errorKeys = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
        let inState = arguments.length > 1 ? arguments[1] : undefined;
        const {
          TYPES,
          generators
        } = this.getAction();
        const selfKey = this.props.key;
        const selfState = inState;
        const error = _.get(selfState, ['fields', selfKey, 'error']) || {};
        const preppedError = _.reduce(errorKeys, (collector, errorKey, errorKeyIndex) => {
          if (error.hasOwnProperty(errorKey)) {
            collector[errorKey] = null;
          }
          return collector;
        }, {});
        return generators.mergeFieldErrorObjectByKey(preppedError, selfKey);
      }
    }
  };
}
module.exports = {
  CONSTANTS: CONSTANTS
  //, middleware: fieldMiddleware
  ,
  reducer: _reducer,
  action: function () {
    const id = this.getID();
    return getAction_usingTypePrefix(`@@ruby-app/${id}/`);
  },
  getAction_usingTypePrefix,
  selector: {
    getFieldsThatErroredByKey: function (localState) {
      const fields = localState.fields;
      const errorsByKey = _.reduce(fields, function (collector, fieldObj, fieldKey) {
        if (!_.isNil(fieldObj.error)) {
          collector[fieldKey] = fieldObj;
        }
        return collector;
      }, {});
      return errorsByKey;
    }
  },
  validateField_withConstraints: _validateField_withConstraints
};