"use strict";

const lodash = require('lodash');
const deepdash = require('deepdash');
const _ = deepdash(lodash);
const ERROR_LEVEL_HIERARCHY = ['info', 'warning', 'error'];
function maxErrorLevel_fromLevels(levels) {
  return _.maxBy(levels, el => ERROR_LEVEL_HIERARCHY.indexOf(el));
}

//# object might be deeply nested so iwe should deep walk this
function errorLevel_fromStringOrErrorObject(stringOrObject) {
  if (!stringOrObject) {
    return;
  }
  const defaultErrorLevel = 'error';
  if (_.isString(stringOrObject)) {
    return defaultErrorLevel;
  } else {
    //# stringOrObject might be deeply nested due to deferred rendering
    //# we need ot deeply select "level" 
    const arrayOfLevels = _.reduceDeep(stringOrObject, (acc, value, key, parent, ctx) => {
      if (key == 'level') {
        acc.push(value);
      }
      return acc;
    }, [], {
      leavesOnly: true
    });
    return maxErrorLevel_fromLevels(arrayOfLevels);
  }
}
module.exports = {
  actionTypesFromID: id => ({
    REPLACE_LOCAL_STATE: `@@ruby-app/${id}/REPLACE_LOCAL_STATE`,
    SET_PROPS: `@@ruby-app/${id}/SET_PROPS`,
    SET_COMPONENT_IS_MOUNTED: `@@ruby-app/${id}/SET_COMPONENT_IS_MOUNTED`,
    UPDATE_DISPLAY_VALUE_SPECS: `@ruby-app/${id}/UPDATE_DISPLAY_VALUE_SPECS`,
    UPDATE_RERENDER_TIMESTAMP: `@ruby-app/${id}/UPDATE_RERENDER_TIMESTAMP`
  }),
  maxErrorLevel_fromLevels,
  errorLevel_fromStringOrErrorObject
};