"use strict";

module.exports = {
  modelName: 'changelog',
  columns: [{
    key: 'timestamp',
    displayName: 'Timestamp',
    type: 'date',
    componentParams: {
      format: 'YYYY MMM DD hh:mma zz'
    },
    resizable: false,
    minWidth: 190,
    width: 190
  }, {
    key: 'username',
    displayName: 'User',
    resizable: false,
    sortable: false
  }, {
    key: 'verb',
    displayName: 'Action',
    resizable: false,
    sortable: false
  }, {
    key: 'entity_name',
    displayName: 'Entity',
    resizable: false,
    sortable: false
  }, {
    key: 'entity_id',
    displayName: 'ID',
    resizable: false,
    sortable: false
  }, {
    key: 'data_name',
    displayName: 'Name',
    resizable: false,
    sortable: false
  }],
  defaultSortColumn: 'timestamp',
  defaultSortDirection: 'DESC'
};