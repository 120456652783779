"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const path = require('path');
const rubyLogger = require('@rubyapps/ruby-logger');
const packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
const logger = rubyLogger.getLogger(packageName);
const mixinResize = require('@rubyapps/ruby-react-components/src/client/mixins/resize');
module.exports = _extends({}, mixinResize, {
  componentWillReceiveProps: function (nextProps) {
    //# Handle recentering the dialog from async rerendering of children
    if (nextProps.open == false && this.props.open == true) {
      //console.log('Unbinding on close:', this._componentEl);
      this._unbindBoundElementResize();
    } else if (nextProps.open == true && this.props.open == false) {
      //# rebind cached element
      //console.log('Rebinding on open:', this._componentEl);
      //this._bindElementResize(this._componentEl)
      //# Do not need to rebind
    }
  },
  componentDidUpdate: function (prevProps) {
    const nextProps = this.props;
    if (nextProps.open == true && !prevProps.open) {
      setTimeout(() => {
        //# Manually rerender on open
        this._updateLastRenderedTimestamp();
      }, 500);
      //# NOTE: the timeout is hacky 
      //# but it seems that even though the element is already bound
      //# it's not picking up on the initial resizing when the lister data
      //# renders with data
    }
  },

  componentDidMount: function () {
    this._isMounted = true;
  },
  componentWillUnmount: function () {
    this._isMounted = false;
    this._unbindBoundElementResize();
  }

  //# == Utilities =============================================//
  ,
  _bindElementResize: function (componentEl, callback) {
    mixinResize._bindElementResize.call(this, componentEl, () => {
      logger.debug('_bindElementResize', this);
      this._updateLastRenderedTimestamp();
    }) && this._updateLastRenderedTimestamp();
  },
  _updateLastRenderedTimestamp: function () {
    if (this._isMounted) {
      this.setState({
        delayedLastRenderedTimestamp: new Date()
      });
    }
  }
});