// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Toggle__checkbox___gE_74 {\n}\n\n.Toggle__checkbox_header___joOWE {\n    display: block !important;\n    height: 16px !important;\n    width: 100% !important;\n    position: absolute !important;\n    bottom: 0px !important;\n}\n\n.Toggle__checkbox_header___joOWE > div > div {\n    width: 16px !important;\n    height: 16px !important;\n    margin-right: 8px !important;\n}\n\n.Toggle__checkbox_header___joOWE svg {\n    width: 16px !important;\n    height: 16px !important;\n}\n\n.Toggle__checkbox_header___joOWE label {\n    line-height: 16px !important;\n    font-size: 12px;\n    color: rgba(255, 255, 255, 0.7) !important;\n}\n", "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-field-toggle/src/client/reactComponents/Toggle.cssModule"],"names":[],"mappings":"AAAA;AACA;;AAEA;IACI,yBAAyB;IACzB,uBAAuB;IACvB,sBAAsB;IACtB,6BAA6B;IAC7B,sBAAsB;AAC1B;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,4BAA4B;AAChC;;AAEA;IACI,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,4BAA4B;IAC5B,eAAe;IACf,0CAA0C;AAC9C","sourcesContent":[".checkbox {\n}\n\n.checkbox_header {\n    display: block !important;\n    height: 16px !important;\n    width: 100% !important;\n    position: absolute !important;\n    bottom: 0px !important;\n}\n\n.checkbox_header > div > div {\n    width: 16px !important;\n    height: 16px !important;\n    margin-right: 8px !important;\n}\n\n.checkbox_header svg {\n    width: 16px !important;\n    height: 16px !important;\n}\n\n.checkbox_header label {\n    line-height: 16px !important;\n    font-size: 12px;\n    color: rgba(255, 255, 255, 0.7) !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": "Toggle__checkbox___gE_74",
	"checkbox_header": "Toggle__checkbox_header___joOWE"
};
export default ___CSS_LOADER_EXPORT___;
