"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GRIDDLE_BATCH_MOVE_START = exports.GRIDDLE_BATCH_MOVE_SELECTED_TO_AFTER_ID = exports.GRIDDLE_BATCH_MOVE_SELECTED_ROWS_RELATIVE_TO_ROW_WITH_ID = exports.GRIDDLE_BATCH_MOVE_SELECTED_INSIDE_ID = exports.GRIDDLE_BATCH_MOVE_END = void 0;
const GRIDDLE_BATCH_MOVE_START = 'GRIDDLE_BATCH_MOVE_START';
exports.GRIDDLE_BATCH_MOVE_START = GRIDDLE_BATCH_MOVE_START;
const GRIDDLE_BATCH_MOVE_END = 'GRIDDLE_BATCH_MOVE_END';
exports.GRIDDLE_BATCH_MOVE_END = GRIDDLE_BATCH_MOVE_END;
const GRIDDLE_BATCH_MOVE_SELECTED_INSIDE_ID = 'GRIDDLE_BATCH_MOVE_SELECTED_INSIDE_ID';
exports.GRIDDLE_BATCH_MOVE_SELECTED_INSIDE_ID = GRIDDLE_BATCH_MOVE_SELECTED_INSIDE_ID;
const GRIDDLE_BATCH_MOVE_SELECTED_ROWS_RELATIVE_TO_ROW_WITH_ID = 'GRIDDLE_BATCH_MOVE_SELECTED_ROWS_RELATIVE_TO_ROW_WITH_ID';
exports.GRIDDLE_BATCH_MOVE_SELECTED_ROWS_RELATIVE_TO_ROW_WITH_ID = GRIDDLE_BATCH_MOVE_SELECTED_ROWS_RELATIVE_TO_ROW_WITH_ID;
const GRIDDLE_BATCH_MOVE_SELECTED_TO_AFTER_ID = 'GRIDDLE_BATCH_MOVE_SELECTED_TO_AFTER_ID';
exports.GRIDDLE_BATCH_MOVE_SELECTED_TO_AFTER_ID = GRIDDLE_BATCH_MOVE_SELECTED_TO_AFTER_ID;