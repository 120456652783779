"use strict";

const eventEmitterMixin = require('@rubyapps/ruby-component-mixin-event-emitter');
module.exports = {
  mixinName: 'rubyComponentMixinEditPage',
  mixins: [eventEmitterMixin],
  saveChanges_usingOptions: function (options) {
    const formComponent = this.findDescendentBy(element => element.componentName == 'rubyComponentFieldForm');
    if (formComponent == undefined) {
      console.warn("Form component not found");
      return;
    }
    const {
      generators: formActions
    } = formComponent.getAction();
    const dispatch = this.getStore().dispatch;
    return dispatch(formActions.saveStateToRemote(options));
  },
  canEdit: function () {
    const formComponent = this.findDescendentBy(element => element.componentName == 'rubyComponentFieldForm');
    return typeof _.get(formComponent, 'canEdit') === 'function' && formComponent.canEdit();
  }

  //# NOTE: This checks for all forms (nested) to see if any of them have unsaved changes
  ,
  hasUnsavedChanges: function () {
    const formComponents = this.findDescendentsBy(element => element.componentName == 'rubyComponentFieldForm');
    return formComponents.reduce((collector, formComponent) => {
      if (collector) {
        return collector;
      }
      return typeof _.get(formComponent, 'hasUnsavedChanges') === 'function' && formComponent.hasUnsavedChanges();
    }, false);
  },
  getTypedPageId: function () {
    const routeParams = _.result(this, 'getState.routeParams');
    return _.get(routeParams, 'routeActive') ? parseIfIntegerString(_.get(routeParams, 'id')) : undefined;
  }
};
function parseIfIntegerString(val) {
  const parsedVal = parseInt(val);
  const isNumber = !isNaN(parsedVal);
  return isNumber && parsedVal.toString().length === val.length ? parsedVal : val;
}