"use strict";

var _react = _interopRequireDefault(require("react"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const RubyComponent = require('@rubyapps/ruby-component/src/client/index');
function filterTagsPropTypeFromPropTypes(PropTypes) {
  return PropTypes.arrayOf(PropTypes.shape({
    fieldSpec: PropTypes.shape({
      key: PropTypes.string,
      label: PropTypes.string,
      dataPath: PropTypes.string
    }),
    matchSpec: PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string
    }),
    searchSpec: PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.string
    })
  }));
}
module.exports = {
  RUBY_COMPONENT__FILTER_TAGS_PROPTYPE: filterTagsPropTypeFromPropTypes(RubyComponent.PropTypes),
  REACT__FILTER_TAGS_PROPTYPE: filterTagsPropTypeFromPropTypes(_react.default.PropTypes)
};