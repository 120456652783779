"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GRIDDLE_TOGGLE_COLUMN = exports.GRIDDLE_SORT_ADDITIONAL = exports.GRIDDLE_SORTED_ADDITIONAL = exports.GRIDDLE_SORTED = exports.GRIDDLE_SORT = exports.GRIDDLE_SET_STATIC_DATA = exports.GRIDDLE_SET_PAGE_SIZE = exports.GRIDDLE_SET_COLUMN_RESIZE_PROPERTIES = exports.GRIDDLE_SET_COLUMN_RENDERED_SIZE_PROPERTIES = exports.GRIDDLE_SET_COLUMN_PROPERTIES = exports.GRIDDLE_ROW_TOGGLED = exports.GRIDDLE_ROW_SELECTION_TOGGLED = exports.GRIDDLE_REMOVED = exports.GRIDDLE_REFRESH_CURRENT_PAGE = exports.GRIDDLE_PREVIOUS_PAGE = exports.GRIDDLE_PREPEND_STATIC_DATA = exports.GRIDDLE_PAGE_LOADED = exports.GRIDDLE_NEXT_PAGE = exports.GRIDDLE_LOAD_DATA = exports.GRIDDLE_LOADED_DATA = exports.GRIDDLE_INITIALIZED = exports.GRIDDLE_INITIALIZE = exports.GRIDDLE_GET_PAGE = exports.GRIDDLE_FILTER_REMOVED = exports.GRIDDLE_FILTER_BY_COLUMN = exports.GRIDDLE_FILTER_BY_ADDITIONAL_COLUMN = exports.GRIDDLE_FILTERED_BY_COLUMN = exports.GRIDDLE_FILTERED_BY_ADDITIONAL_COLUMN = exports.GRIDDLE_FILTERED = exports.GRIDDLE_FILTER = void 0;
/*
  It should be noted that the action types that are like
  GRIDDLE_FILTER mean that the operation has started.
  Past tense action types mean that the operation
  has completed.
*/

const GRIDDLE_FILTER = 'GRIDDLE_FILTER';
exports.GRIDDLE_FILTER = GRIDDLE_FILTER;
const GRIDDLE_FILTERED = 'GRIDDLE_FILTERED';
exports.GRIDDLE_FILTERED = GRIDDLE_FILTERED;
const GRIDDLE_FILTER_BY_COLUMN = 'GRIDDLE_FILTER_BY_COLUMN';
exports.GRIDDLE_FILTER_BY_COLUMN = GRIDDLE_FILTER_BY_COLUMN;
const GRIDDLE_FILTERED_BY_COLUMN = 'GRIDDLE_FILTERED_BY_COLUMN';
exports.GRIDDLE_FILTERED_BY_COLUMN = GRIDDLE_FILTERED_BY_COLUMN;
const GRIDDLE_FILTER_BY_ADDITIONAL_COLUMN = 'GRIDDLE_FILTER_BY_ADDITIONAL_COLUMN';
exports.GRIDDLE_FILTER_BY_ADDITIONAL_COLUMN = GRIDDLE_FILTER_BY_ADDITIONAL_COLUMN;
const GRIDDLE_FILTERED_BY_ADDITIONAL_COLUMN = 'GRIDDLE_FILTERED_BY_ADDITIONAL_COLUMN';
exports.GRIDDLE_FILTERED_BY_ADDITIONAL_COLUMN = GRIDDLE_FILTERED_BY_ADDITIONAL_COLUMN;
const GRIDDLE_FILTER_REMOVED = 'GRIDDLE_FILTER_REMOVED';
exports.GRIDDLE_FILTER_REMOVED = GRIDDLE_FILTER_REMOVED;
const GRIDDLE_SORT = 'GRIDDLE_SORT';
exports.GRIDDLE_SORT = GRIDDLE_SORT;
const GRIDDLE_SORTED = 'GRIDDLE_SORTED';
exports.GRIDDLE_SORTED = GRIDDLE_SORTED;
const GRIDDLE_SORT_ADDITIONAL = 'GRIDDLE_SORT_ADDITIONAL';
exports.GRIDDLE_SORT_ADDITIONAL = GRIDDLE_SORT_ADDITIONAL;
const GRIDDLE_SORTED_ADDITIONAL = 'GRIDDLE_SORTED_ADDITIONAL';
exports.GRIDDLE_SORTED_ADDITIONAL = GRIDDLE_SORTED_ADDITIONAL;
const GRIDDLE_LOAD_DATA = 'GRIDDLE_LOAD_DATA';
exports.GRIDDLE_LOAD_DATA = GRIDDLE_LOAD_DATA;
const GRIDDLE_LOADED_DATA = 'GRIDDLE_LOADED_DATA';
exports.GRIDDLE_LOADED_DATA = GRIDDLE_LOADED_DATA;
const GRIDDLE_NEXT_PAGE = 'GRIDDLE_NEXT_PAGE';
exports.GRIDDLE_NEXT_PAGE = GRIDDLE_NEXT_PAGE;
const GRIDDLE_PREVIOUS_PAGE = 'GRIDDLE_PREVIOUS_PAGE';
exports.GRIDDLE_PREVIOUS_PAGE = GRIDDLE_PREVIOUS_PAGE;
const GRIDDLE_GET_PAGE = 'GRIDDLE_GET_PAGE';
exports.GRIDDLE_GET_PAGE = GRIDDLE_GET_PAGE;
const GRIDDLE_REFRESH_CURRENT_PAGE = 'GRIDDLE_REFRESH_CURRENT_PAGE';
exports.GRIDDLE_REFRESH_CURRENT_PAGE = GRIDDLE_REFRESH_CURRENT_PAGE;
const GRIDDLE_PAGE_LOADED = 'GRIDDLE_PAGE_LOADED';
exports.GRIDDLE_PAGE_LOADED = GRIDDLE_PAGE_LOADED;
const GRIDDLE_SET_PAGE_SIZE = 'GRIDDLE_SET_PAGE_SIZE';
exports.GRIDDLE_SET_PAGE_SIZE = GRIDDLE_SET_PAGE_SIZE;
const GRIDDLE_INITIALIZE = 'GRIDDLE_INITIALIZE';
exports.GRIDDLE_INITIALIZE = GRIDDLE_INITIALIZE;
const GRIDDLE_INITIALIZED = 'GRIDDLE_INITIALIZED';
exports.GRIDDLE_INITIALIZED = GRIDDLE_INITIALIZED;
const GRIDDLE_REMOVED = 'GRIDDLE_REMOVED';
exports.GRIDDLE_REMOVED = GRIDDLE_REMOVED;
const GRIDDLE_TOGGLE_COLUMN = 'GRIDDLE_TOGGLE_COLUMN';
exports.GRIDDLE_TOGGLE_COLUMN = GRIDDLE_TOGGLE_COLUMN;
const GRIDDLE_SET_COLUMN_PROPERTIES = 'GRIDDLE_SET_COLUMN_PROPERTIES';
exports.GRIDDLE_SET_COLUMN_PROPERTIES = GRIDDLE_SET_COLUMN_PROPERTIES;
const GRIDDLE_SET_COLUMN_RESIZE_PROPERTIES = 'GRIDDLE_SET_COLUMN_RESIZE_PROPERTIES';
exports.GRIDDLE_SET_COLUMN_RESIZE_PROPERTIES = GRIDDLE_SET_COLUMN_RESIZE_PROPERTIES;
const GRIDDLE_SET_COLUMN_RENDERED_SIZE_PROPERTIES = 'GRIDDLE_SET_COLUMN_RENDERED_SIZE_PROPERTIES';
exports.GRIDDLE_SET_COLUMN_RENDERED_SIZE_PROPERTIES = GRIDDLE_SET_COLUMN_RENDERED_SIZE_PROPERTIES;
const GRIDDLE_ROW_TOGGLED = 'GRIDDLE_ROW_TOGGLED';
exports.GRIDDLE_ROW_TOGGLED = GRIDDLE_ROW_TOGGLED;
const GRIDDLE_ROW_SELECTION_TOGGLED = 'GRIDDLE_ROW_SELECTION_TOGGLED';
exports.GRIDDLE_ROW_SELECTION_TOGGLED = GRIDDLE_ROW_SELECTION_TOGGLED;
const GRIDDLE_PREPEND_STATIC_DATA = 'GRIDDLE_PREPEND_STATIC_DATA';
exports.GRIDDLE_PREPEND_STATIC_DATA = GRIDDLE_PREPEND_STATIC_DATA;
const GRIDDLE_SET_STATIC_DATA = 'GRIDDLE_SET_STATIC_DATA';
exports.GRIDDLE_SET_STATIC_DATA = GRIDDLE_SET_STATIC_DATA;