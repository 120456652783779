// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NoResults__noResultsContent____xVH8 {\n    padding: 20px;\n    width: 100%;\n    box-sizing: border-box;\n    text-align: center;\n    transition: opacity 450ms ease-in-out;\n    background: #fff;\n}\n\n.NoResults__imageWrapper___xnm6A {\n    width: 200px;\n    padding: 67px 67px 0 67px;\n    opacity: 0.7;\n    display: inline-block;\n    position: relative;\n}\n.NoResults__logo___zcait {\n    max-width: 200px;\n    margin-bottom: 20px;\n}\n.NoResults__AnnouncementIcon___iiCrP {\n    width: 100px!important;\n    height: 100px!important;\n    position: absolute;\n    top: 0;\n    right: 0;\n}\n.NoResults__header___b9zLl {\n    font-size: 20px;\n}\n.NoResults__details___L2wcy {\n    \n}\n", "",{"version":3,"sources":["webpack://./src/local_modules/ruby-react-components/src/client/view/NoResults.cssModule"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,qCAAqC;IACrC,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,YAAY;IACZ,qBAAqB;IACrB,kBAAkB;AACtB;AACA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,sBAAsB;IACtB,uBAAuB;IACvB,kBAAkB;IAClB,MAAM;IACN,QAAQ;AACZ;AACA;IACI,eAAe;AACnB;AACA;;AAEA","sourcesContent":[".noResultsContent {\n    padding: 20px;\n    width: 100%;\n    box-sizing: border-box;\n    text-align: center;\n    transition: opacity 450ms ease-in-out;\n    background: #fff;\n}\n\n.imageWrapper {\n    width: 200px;\n    padding: 67px 67px 0 67px;\n    opacity: 0.7;\n    display: inline-block;\n    position: relative;\n}\n.logo {\n    max-width: 200px;\n    margin-bottom: 20px;\n}\n.AnnouncementIcon {\n    width: 100px!important;\n    height: 100px!important;\n    position: absolute;\n    top: 0;\n    right: 0;\n}\n.header {\n    font-size: 20px;\n}\n.details {\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"noResultsContent": "NoResults__noResultsContent____xVH8",
	"imageWrapper": "NoResults__imageWrapper___xnm6A",
	"logo": "NoResults__logo___zcait",
	"AnnouncementIcon": "NoResults__AnnouncementIcon___iiCrP",
	"header": "NoResults__header___b9zLl",
	"details": "NoResults__details___L2wcy"
};
export default ___CSS_LOADER_EXPORT___;
