"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _immutable = _interopRequireDefault(require("immutable"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _default = _immutable.default.fromJS({
  loading: true,
  data: [],
  visibleData: [],
  columnTitles: [],
  allColumns: [],
  renderProperties: {},
  resizeProperties: {},
  //# for columnResizing
  renderedSizeProperties: {},
  //# for columnResizing
  hasNext: false,
  hasPrevious: false,
  pageProperties: {
    currentPage: 0,
    maxPage: 0,
    pageSize: 0
  }
});
exports.default = _default;