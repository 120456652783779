"use strict";

var _bluebird = _interopRequireDefault(require("bluebird"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const RubyComponent = require('@rubyapps/ruby-component');
const {
  PropTypes
} = RubyComponent;
const baseFieldMixin = require('@rubyapps/ruby-component-mixin-field-base');
const fieldOptionsMixin = {
  mixinName: 'rubyComponentMixinFieldOptions',
  propTypes: {
    options: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
    }))
  }

  //== UTILITIES =============================//
  ,
  getDisplayTextFromOption: function (opt) {
    return opt.text || opt.primaryText || opt.label || opt.name || opt.value;
  },
  getOptionsMatchingOptionValueAsync: function (optionValue) {
    const optionValue_normalized = _.castArray(optionValue);
    const options = this.props.options || [];
    const foundOptions = options.filter(option => option && optionValue_normalized.indexOf(this.coercedValue(option.value || option.id, true)) >= 0);
    return _bluebird.default.resolve(foundOptions);
  }

  //== OVERRIDES =============================//
  ,
  _displayValueFromLocalState: function (selfState, entireState, options) {
    const selfKey = this.props.key;

    //# return the text label if possible
    const selectedOptionValue = this.getFieldValueFromLocalState(selfState);
    const promisedSelectedOptions = this.getOptionsMatchingOptionValueAsync(selectedOptionValue);
    return promisedSelectedOptions.then(selectedOptions => {
      const displayTextForOptions = selectedOptions.map(this.getDisplayTextFromOption);
      let displayObject;
      if (_.isArray(selectedOptionValue)) {
        displayObject = {
          [selfKey]: displayTextForOptions
        };
      } else {
        displayObject = {
          [selfKey]: displayTextForOptions[0]
        };
      }
      return displayObject;
    });
  }

  //# NOTE: tokentagger uses this
  //# but Dropdown doesn't
  ,
  formValueFromFieldValue_forKey: function (localState, key) {
    const rawValue = localState;
    if (_.isNil(rawValue)) return rawValue;
    const dataTypeFormattedRawValue = baseFieldMixin.formValueFromFieldValue_forKey.apply(this, arguments);
    const apiExpectsLimit = this.props.limit;
    const apiExpectsArray = _.isArray(this.props.data_type);
    const value = _.flowRight(value => apiExpectsArray ? value : _.get(value, 0, null), value => value.length ? value : null, value => value.slice(0, apiExpectsLimit), _.castArray)(dataTypeFormattedRawValue);
    return value;
  }
};
module.exports = fieldOptionsMixin;