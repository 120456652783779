"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = getSettingsMenuItemsForSelfModule;
//# TODO: see if we can pass the icon component instead of the string
function getSettingsMenuItemsForSelfModule(selfModule) {
  const {
    rubyapps_settings: settingsForm
  } = selfModule.getForms();
  const {
    RUBYAPPS_SETTINGS_ID: settingsInstanceId
  } = selfModule.getFrontendSettings();
  const settingsTemplateId = settingsForm ? settingsForm.id : null;
  return settingsForm ? {
    name: 'Settings',
    id: 'cms-settings',
    icon: 'ruby/icons/content/global-data',
    permissions: [{
      model: 'content',
      template: settingsTemplateId,
      ruby_client: settingsForm.ruby_client_fk,
      subsite: 1,
      action: '*'
    }],
    route: `/app/content/en/rubyapps_settings/${settingsInstanceId}/edit`
  } : null;
}