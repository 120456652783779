"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _lodash = _interopRequireDefault(require("lodash"));
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _Header = _interopRequireDefault(require("./Header"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function generator() {
  const selfModule = this;
  const {
    selfSelector,
    nameSelector
  } = selfModule.getDependencies();
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const nameState = nameSelector && nameSelector(state);
    //# get formValue as backup if name state hasn't rendered yet
    const formValue = selfModule.getParentFormComponent().formValue();
    const listName = _lodash.default.get(selfState, 'props.breadcrumbLabel') || selfModule.getStaticListName();
    return _extends({}, ownProps, selfState, {
      listRoute: selfModule.getListRoute(),
      listName,
      name: _lodash.default.get(nameState, ['fields', ownProps.keyForNameComponent, 'value'], _lodash.default.get(formValue, [ownProps.keyForNameComponent], null))
    });
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
    };
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_Header.default);
}