"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const headerHeight = 46; //# add 2px from 44 because the effective height of the header is actually 46

const styles = {
  Drawer: {
    containerStyle: {
      overflow: 'visible'
    }
  },
  Paper: {
    style: {
      position: 'relative'
    }
  },
  Toolbar: {
    style: {
      height: headerHeight
    }
  },
  ToolbarGroup: {
    style: {
      display: 'block',
      marginTop: -4
    }
  },
  LoadingIndicator: {
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto'
  }
};
var _default = styles;
exports.default = _default;