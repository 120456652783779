"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.DialogHeader = void 0;
var _default = {
  bodyStyle: {
    padding: '24px'
  }
};
exports.default = _default;
const DialogHeader = {
  iconElementRight: {
    color: '#ffffff',
    display: 'flex',
    'align-items': 'center',
    marginTop: '3px'
  }
};
exports.DialogHeader = DialogHeader;