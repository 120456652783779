"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
const RubyComponent = require('@rubyapps/ruby-component');
const PropTypes = RubyComponent.PropTypes;
const persistenceHelper = require('@rubyapps/ruby-persistence-helper');
const action = require('./action');
const reducer = require('./reducer');
const persistenceMixin = {
  mixinName: 'rubyComponentMixinPersistence',
  propTypes: {
    currentUserId: PropTypes.string,
    keysToPersist: PropTypes.arrayOf(PropTypes.string)
    //# NOTE: if null, persist everything
  },

  getDefaultProps: () => ({
    currentUserId: 'rubyComponentCurrentUser',
    keysToPesist: []
  }),
  action,
  reducer

  //# _cachedPersistenceKey
  ,
  onReduxInit: function () {
    const id = this.getID();
    const store = this.getStore();
    const actions = this.getAction().generators;
    const {
      keysToPersist
    } = this.props;
    if (keysToPersist == []) {
      return;
    }
    const currentUser = this.getRoot().findDescendentByID(this.props.currentUserId);
    return store.subscribe(() => {
      const currentUserState = currentUser.getState() || {};
      const {
        username
      } = currentUserState;
      if (username) {
        //# generate the persistenceKey
        const persistenceKey = `${username}::${id}`;
        const previousPersistedData = persistenceHelper.get(persistenceKey);
        if (this._cachedPersistenceKey != persistenceKey) {
          //# retrieve preference and replace localState
          this._cachedPersistenceKey = persistenceKey;
          store.dispatch(actions.replaceReduxState(previousPersistedData));
        } else {
          //# store preference if different
          const selfState = this.getState();
          const potentialDataToPersist = keysToPersist ? _lodash.default.pick(selfState, keysToPersist) : selfState;
          if (!_lodash.default.isEqual(previousPersistedData, potentialDataToPersist)) {
            persistenceHelper.set(persistenceKey, potentialDataToPersist);
          }
        }
      }
    });
  }
};
module.exports = persistenceMixin;