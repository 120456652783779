"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _lodash = _interopRequireDefault(require("lodash"));
var _reactRedux = require("react-redux");
var _ConnectorMappersGenerator = _interopRequireDefault(require("@rubyapps/ruby-component-mixin-field-base/src/client/reactComponents/ConnectorMappersGenerator"));
var _RubyComponentFieldArrayMap = _interopRequireDefault(require("./RubyComponentFieldArrayMap"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const SELFMODULE_ACTION_KEYS = ['requestToAppendChildren', 'requestToDeleteChild', 'requestToUpdateExistingChild', 'requestToEditChild', 'requestToCancelEdit'];

//# NOTE: we're not using addInfoMode props HOC because this is a fairly complex widget
function generator() {
  const selfModule = this;
  //# NOTE: use this if you're writing a field component
  const {
    mapStateToProps: defaultMapStateToProps,
    mapDispatchToProps: defaultMapDispatchToProps
  } = _ConnectorMappersGenerator.default.call(this);
  const selfModuleActions = _lodash.default.reduce(_lodash.default.pick(selfModule, SELFMODULE_ACTION_KEYS), (collector, func, key) => {
    collector[key] = func.bind(selfModule);
    return collector;
  }, {});
  let cachedDisplayValue;
  function mapStateToProps(state, ownProps) {
    const mappedDefaultProps = defaultMapStateToProps.apply(null, arguments);
    const selfState = selfModule.getState(state);
    return _extends({}, mappedDefaultProps, selfModuleActions, {
      actionButtons: selfModule.hydratedActionButtons(mappedDefaultProps.actionButtons),
      selectedValue: selfState.selectedValue,
      formHasData: selfModule.childrenFormValueHasData(),
      displayValue: selfModule.unhydratedDisplayValueByHydratedItems()
    }, _lodash.default.mapValues(_lodash.default.pick(selfModule, ['hashedItem', 'unhashedItem', 'hashedItems', 'unhashedItems']), n => n.bind(selfModule)));
  }

  /*
  function mapDispatchToProps (dispatch, ownProps) {
      return Object.assign({
          delegateActions: {
              mediaGallery: bindActionCreators(mediaGalleryComponent.getAction().generators, dispatch)
          }
      }, defaultMapDispatchToProps.apply(null, arguments));
  }
  */

  //return connect(mapStateToProps, mapDispatchToProps)(RubyComponentFieldArrayMap);
  return (0, _reactRedux.connect)(mapStateToProps, defaultMapDispatchToProps)(_RubyComponentFieldArrayMap.default);
}