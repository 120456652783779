"use strict";

var _reduxBatchedActions = require("redux-batched-actions");
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const path = require('path');
const rubyLogger = require('@rubyapps/ruby-logger');
const packageName = path.basename(__filename.replace(/.*local_modules\//, '').replace(/\//g, ':'), '.js');
const logger = rubyLogger.getLogger(packageName);
const queryString = require('query-string');
const routeParser = require('route-parser');
//== The Middleware ========================================================//

const middlewareInitializer = function (store) {
  const selfModule = this;
  const rootModule = this.getRoot();
  const loadingIndicator = selfModule.getRubyComponentAtPath('.rubyComponentLoadingIndicator');
  const loadingIndicatorActions = loadingIndicator.getAction().generators;
  const allowedPathParams = this.props.allowedPathParams || {};
  const selfPath = selfModule.getRoutePath();
  const selfRouteParser = new routeParser(selfPath);
  const selfAction = selfModule.getAction();
  return next => action => {
    const nextRetVal = next(action); //# still allow @@router to continue propagation
    //# but also need to immediately call on next;

    if (action.type === '@@router/LOCATION_CHANGE') {
      const actionPayload = _extends({}, action.payload);
      //# handle the case where the payload.pathname contains hash because <Redirect/> isn't handling it correctly
      const pathnameSplitOnHash = actionPayload.pathname.split('#');
      actionPayload.pathname = pathnameSplitOnHash[0];
      if (!actionPayload.hash) {
        actionPayload.hash = pathnameSplitOnHash[1] ? `#${pathnameSplitOnHash[1]}` : '';
      }
      const pathname = actionPayload.pathname;
      const parsedRouteInfo = selfRouteParser.match(pathname);
      const parsedRouteInfoKeys = Object.keys(parsedRouteInfo);
      //get other instances of selfModule.componentName;
      const siblingModules = rootModule.findDescendentsBy(node => node.componentName == selfModule.componentName);
      const isSiblingPathMoreSpecific = siblingModules.reduce((collector, node) => {
        if (collector) {
          return collector;
        }
        const siblingRouteInfo = node.getRoutePathParser().match(pathname);
        if (siblingRouteInfo && Object.keys(siblingRouteInfo).length < parsedRouteInfoKeys.length) {
          //console.log('deferring to sibling:', node, siblingRouteInfo);
          return true;
        }
        return collector;
      }, false);
      if (isSiblingPathMoreSpecific) {
        //# defer to sibling
        return;
      }
      const routeInfo = allowedPathParams.action && allowedPathParams.action.filter(action => action == parsedRouteInfo.action).length || allowedPathParams.action == undefined ? parsedRouteInfo : false;
      const routeInfoMatched = routeInfo;

      //console.log(selfModule.getID(), 'PATHNAME VS SELFPATH: ', pathname, selfPath, routeInfo, action);

      const selfState = selfModule.getState();
      const routeActive = selfState.routeParams.routeActive;
      const routeInfoWithQueryParams = _extends({
        query: actionPayload.query
      }, routeInfo);
      const routeInfoKeys = Object.keys(routeInfoWithQueryParams);
      const isRouteDiff = routeInfoMatched && !_.isEqual(_.pick(selfState.routeParams, routeInfoKeys), routeInfoWithQueryParams);

      //const routeParams = selfState.routeParams;
      //const routeInfoDifferent = !_.isEqual(routeInfo, _.omit(routeParams, ['routeActive']));

      //# prefer dispatching leavingRoute FIRST
      //# Do not leave route if it's the same route and only the hash has changed
      //if (routeActive && !routeInfo) {
      //
      //# BUG: need to trigger leaving route if it's active but also need to prevent it if it's current active and htere's route info
      if (routeActive && !routeInfoMatched) {
        //# Only dispatch leavingRoute if the route is active
        //# LOCATION_CHANGE doesn't match, fire an event to allow
        //# the ruby-component to clean out its store

        store.dispatch(selfAction.generators.leavingRoute(actionPayload));
        //# maybe we can check if the route will handle the loading hide itself
      }
      //if (routeInfo && (routeInfoDifferent || !routeActive)) {
      if (routeInfo) {
        //console.log('==== setting route info:', routeInfo, ' for component:', selfModule.getID());
        //# route info is different, ie it's the same route but the routeInfo is differerent
        //# OR the route was inactive

        if (selfModule.componentName == 'rubyComponentEditPage' && isRouteDiff) {
          //# only if the route is already not active
          store.dispatch(loadingIndicatorActions.show());
        }
        const urlHash = actionPayload.hash ? actionPayload.hash.substr(1) : undefined;
        const urlHashObject = urlHash ? _extends(queryString.parse(urlHash), {
          __original: urlHash
        }) : undefined;
        const routeInfoWithQueryParamsAndHash = _extends({
          hash: urlHashObject
        }, routeInfoWithQueryParams);
        const {
          defaultRouteParams = {}
        } = selfModule.props;
        const finalRouteInfo = _extends({}, defaultRouteParams, routeInfoWithQueryParamsAndHash);

        //console.log('==== setting route info:', finalRouteInfo, ' for component:', selfModule.getID(), action);
        const batchActionsArray = [].concat(
        //(selfModule.componentName == 'rubyComponentEditPage' && isRouteDiff)? loadingIndicatorActions.show() :[],
        [{
          type: '@@ruby-app/UPDATE_GLOBAL_ROUTE_PARAMS',
          payload: routeInfo
        }, selfAction.generators.setRouteParams(finalRouteInfo)
        //, loadingIndicatorActions.show()
        ]);

        const batchActionsObject = (0, _reduxBatchedActions.batchActions)(batchActionsArray);
        return selfModule.promiseForDependenciesResolution().then(() => {
          if (selfModule.props.rerouteBasedOnBestUrl) {
            const reroutedPath = rootModule.getBestUrlForComponentName_fromModule_withParams(selfModule.componentName, this, routeInfo);
            logger.trace(`Handling 'rerouteBasedOnBestUrl'. Pathname: ${pathname} vs reroutedPath: ${reroutedPath}`);
            if (reroutedPath != pathname) {
              //# replace path
              if (selfModule.componentName == 'rubyComponentEditPage' && isRouteDiff) {
                //# only if the route is already not active
                store.dispatch(loadingIndicatorActions.hide()); //# need to clean up
              }

              store.dispatch(selfAction.generators.replace(reroutedPath));
            } else {
              store.dispatch(batchActionsObject);
            }
          } else {
            store.dispatch(batchActionsObject);
          }
        });
      }
    }
    return nextRetVal;
  };
};
module.exports = middlewareInitializer;