"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getBoxSize = getBoxSize;
exports.getHeight = getHeight;
exports.getWidth = getWidth;
function _getComputedStyle(browserElement) {
  return window.getComputedStyle(browserElement, null);
}
function getHeight(browserElement, computedStyle) {
  return browserElement.offsetHeight;
  //computedStyle = computedStyle ? computedStyle : _getComputedStyle(browserElement);
  //return ~~computedStyle.getPropertyValue('height').replace('px','');
}

function getWidth(browserElement, computedStyle) {
  return browserElement.offsetWidth;
  //computedStyle = computedStyle ? computedStyle : _getComputedStyle(browserElement);
  //return ~~computedStyle.getPropertyValue('width').replace('px','');
}

function getBoxSize(browserElement) {
  return {
    width: getWidth(browserElement),
    height: getHeight(browserElement)
  };
}