"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const request = require('@rubyapps/ruby-superagent');
const React = require('react');
const _ = require('lodash');
const RubyComponent = require('@rubyapps/ruby-component');
const PropTypes = RubyComponent.PropTypes;
const CONSTANTS = require('../common/constants');
const componentName = CONSTANTS.COMPONENT_NAME;
const reducer = require('./reducer');
const action = require('./action');
const RubyComponentConfirmDialog__CONSTANTS = require('@rubyapps/ruby-component-confirm-dialog/src/common/constants');
const RubyComponentCurrentUser__CONSTANTS = require('@rubyapps/ruby-component-current-user/src/common/constants');
const persistenceMixin = require('@rubyapps/ruby-component-mixin-persistence');
const FRONTEND_SETTINGS_URL = '/ruby/api/v2/settings/frontend';
const LOGIN_FRONTEND_SETTINGS_URL = '/ruby/api/v2/settings/login-frontend';
const BROWSER_SESSION_UUID = require('node-uuid').v4(); //# generate a new UUID once on page load.
//# we do not want this to change on load
const RCFrontendSettings = RubyComponent.createClass({
  componentName: componentName,
  mixins: [persistenceMixin],
  propTypes: {
    confirmDialogID: PropTypes.string,
    currentUserID: PropTypes.string
  },
  getDefaultProps: () => ({
    confirmDialogID: RubyComponentConfirmDialog__CONSTANTS.COMPONENT_NAME,
    currentUserID: RubyComponentCurrentUser__CONSTANTS.COMPONENT_NAME,
    keysToPersist: ['autosavedSearch', 'stickyAutosavedSearch']
  }),
  getInitialState: () => {
    let browserSessionId;
    const performance = window.performance;
    const perfEntries = performance && performance.getEntriesByType("navigation") ? performance.getEntriesByType("navigation") : [];
    const navigationTypes = perfEntries.map(perf => perf.type);

    //# On page load, we want to check if the load type is reload
    //# if it's reload, we can use the existing browserSessionId in sessionStorage
    //# if it's not a reload, we assume it's a  new page load (we cannot differentiate a tab duplication or a tab recovery. So a duplicate tab or a closed tab that you reopen will have the same navigation type)
    //# If it's a new page, we generate a new browserSessionId.
    //# WARNING: if a tab is duplicated, the sessionStorage is also duplicated so there will be an existing browserSessionId
    if (_.includes(navigationTypes, 'reload')) {
      //# if the page is reloaded, then we can recover the stored browserSessionId
      browserSessionId = sessionStorage.getItem('browserSessionId') || BROWSER_SESSION_UUID;
    } else {
      browserSessionId = BROWSER_SESSION_UUID;
    }
    sessionStorage.setItem('browserSessionId', browserSessionId);
    return {
      cmsRoot: '/app',
      restApiRoot: '/ruby/api/v2/'
      //# TODO: we need a better way of setting the initial restApiRoot
      //# we need this before we get the frontend settings because we need to know the root in order to
      //# get the frontend settings
      ,
      browserSessionId
      //# This is used to track the different tabs that are opened
    };
  },

  reducer: reducer,
  action: action,
  onReduxInit: function (dispatch) {
    const selfActions = this.getAction().generators;
    this.retrieveFrontendSettingsAsync().then(settings => {
      dispatch(selfActions.retrievedFrontendSettings(settings));
    });

    //# retrieve user information periodically to get updated role info
    //# but also be automatically redirected to the login page if logged out (session expiration)
    this.pollFrontendSettingsAsync(dispatch);
  },
  CONSTANTS: CONSTANTS

  //== Utility methods ==========================//
  ,

  isResolved: function () {
    return !!this._retrievedFrontendSettings;
  },
  _logOutMessageForState: function (selfState) {
    const rubyBranding = selfState.RUBY_BRANDING;
    const title = `${rubyBranding}: New Version Detected`;
    const content = `Your installation of ${rubyBranding} has been updated to a newer release. To ensure the integrity of your application data, please log out of the system and log back in. You may save your current work, if necessary.`;
    return {
      title,
      content
    };
  },
  _refreshMessageForState: function (selfState) {
    const rubyBranding = selfState.RUBY_BRANDING;
    const title = `${rubyBranding}: New Version Detected`;
    const content = `Your installation of ${rubyBranding} has been updated to a newer release. To ensure the integrity of your application data, please refresh your browser before attempting to log in.`;
    return {
      title,
      content
    };
  },
  retrieveFrontendSettingsAsync: function (isPolling) {
    let self = this;
    return request.get(FRONTEND_SETTINGS_URL + (isPolling ? '?polling' : '')).then(function success(response) {
      return response.body;
    }, function error(err) {
      if (err.response.status != 401) {
        //# only show the error for something that's not
        //# auth error
        //# rubyComponentCurrentUser will handle
        //# redirecting for login
        //self.showErrorNotification({ error: err });
        console.error('Error retrieving frontend settings.', err);
        throw err;
      } else {
        //# If the user is unauthorized,
        //# return a greatly sanitized settings object
        return request.get(LOGIN_FRONTEND_SETTINGS_URL).then(function (response) {
          return response.body;
        }, function (err) {
          //self.showErrorNotification({error: err});
          console.error('Error retrieving frontend settings for login page.', err);
          throw err;
        });
      }
    });
  },
  pollFrontendSettingsAsync: function (dispatch) {
    //# Note: since we have retry logic in ruby-superagent, we would only attempt to hit the  
    //# endpoint again once the previous request is returned. 
    this.retrieveFrontendSettingsAsync(true).then(settings => {
      const selfActions = this.getAction().generators;
      const selfState = this.getState();
      const currentVersion = selfState.version;
      const currentDeployVersion = selfState.DEPLOY_VERSION;
      const nextVersion = settings['frontend-settings'].version;
      const nextDeployVersion = settings['frontend-settings'].DEPLOY_VERSION;
      const hasNewVersion = currentVersion && nextVersion && currentVersion != nextVersion;
      const hasNewDeployVersion = currentDeployVersion && nextDeployVersion && currentDeployVersion != nextDeployVersion;

      //# only do this check if the version numbers are defined
      if (hasNewVersion || hasNewDeployVersion) {
        const rootComponent = this.getRoot();
        const confirmDialogComponent = rootComponent.findDescendentByID(this.props.confirmDialogID);
        const confirmDialogActions = confirmDialogComponent.getAction().generators;
        const currentUserComponent = rootComponent.findDescendentByID(this.props.currentUserID);
        if (currentUserComponent.getState()) {
          //# user is logged in
          //# show confirm dialog asking user to log out
          dispatch(confirmDialogActions.openDialogWithOptions(_extends({
            submitLabel: 'Log Out',
            submitSuccessCallback: response => {
              dispatch(currentUserComponent.getAction().generators.logOut());
            }
          }, this._logOutMessageForState(selfState))));
        } else {
          dispatch(confirmDialogActions.openDialogWithOptions(_extends({
            submitLabel: 'Refresh',
            submitSuccessCallback: response => {
              window.location.reload();
            }
          }, this._refreshMessageForState(selfState))));
        }
      }

      //# if version hasn't changed, we still want to update the local settings state with updated values
      //# e.g. google_api_key if it has been changed
      dispatch(selfActions.retrievedFrontendSettings(settings));
    }).catch(error => {
      //# if we should do something else with the error besides showing the error in the console
    }).then(() => {
      const PING_INTERVAL = 1000 * 60 * 1; //# ms * sec * min; //# every 1 minutes

      setTimeout(() => {
        this.pollFrontendSettingsAsync(dispatch);
      }, PING_INTERVAL);
    });
  }
});
module.exports = RCFrontendSettings;