"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function typesFromID(id) {
  const TYPES = {
    SHOW_LOADING: `@@ruby-app/${id}/SHOW_LOADING`,
    CLEAR_LOADING: `@@ruby-app/${id}/CLEAR_LOADING`,
    SET_ERROR: `@@ruby-app/${id}/SET_ERROR`
  };
  return TYPES;
}
var generators = {
  setError: function (errorPayload) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SET_ERROR,
      payload: errorPayload
    };
  },
  showLoading: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.SHOW_LOADING
    };
  },
  clearLoading: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.CLEAR_LOADING
    };
  },
  logIn: function () {
    const selfModule = this;
    const {
      currentUserActions
    } = this.getDependencies();
    const selfSelectorObj = selfModule.getSelector();
    const generators = selfModule.getAction().generators;
    return (dispatch, getState) => {
      const applicationState = getState();
      const selfState = selfSelectorObj.default(applicationState);
      const routeParamsQuery = _lodash.default.get(selfState, 'routeParams.query', {});
      const nextPage = routeParamsQuery.next_page ? decodeURIComponent(routeParamsQuery.next_page) : selfModule.props.nextPage;
      const data = {
        authenticationToken: routeParamsQuery.authenticationToken
      };
      dispatch(generators.showLoading());
      const thenableArguments = [function onResult(response) {
        //# navigate to page
        window.location.href = nextPage; //# we need to force a page reload to get the rest of the bundle
      }, function onError(err) {
        dispatch(generators.clearLoading());
        const errorResponseBody = err.response.body;

        //# set our own errors
        const errorMessage = _lodash.default.get(errorResponseBody, 'error.message', '');
        const errorMessageHeader = _lodash.default.get(errorResponseBody, 'error.messageHeader', 'Authentication token is invalid');
        dispatch(generators.setError({
          error: {
            message: errorMessage,
            messageHeader: errorMessageHeader
          }
        }));
      }];
      dispatch(currentUserActions.logIn(data, thenableArguments));
    };
  }
};
module.exports = function () {
  const TYPES = typesFromID(this.getID());
  return {
    TYPES: TYPES,
    generators: generators
  };
};