// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".row__wrappedExpandColumn____uxVE {\n    text-align: right;\n}\n", "",{"version":3,"sources":["webpack://./src/local_modules/griddle/local_modules/griddle-subgrid-plugin/src/components/row.cssModule"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB","sourcesContent":[".wrappedExpandColumn {\n    text-align: right;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrappedExpandColumn": "row__wrappedExpandColumn____uxVE"
};
export default ___CSS_LOADER_EXPORT___;
