"use strict";

function typesWithID(id) {
  return {
    UPDATE_REFERENCED_DISPLAY_VALUES: `@@ruby-app/${id}/UPDATE_REFERENCED_DISPLAY_VALUES`
  };
}
const generators = {
  updateReferencedDisplayValues: function (referencedDisplayValues) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.UPDATE_REFERENCED_DISPLAY_VALUES,
      payload: {
        referencedDisplayValues
      }
    };
  }
};
module.exports = function () {
  const TYPES = typesWithID(this.getID());
  return {
    TYPES,
    generators
  };
};