"use strict";

module.exports = {
  COMPONENT_NAME: 'rubyComponentFieldDatePicker',
  GET_DEFAULT_PROPS: function (props) {
    return {
      toHTML: '${data.formattedDateString? data.formattedDateString :"Year: "+(data.year||"")+", Month: "+ ((typeof data.month == "number")? modules.moment().month(data.month).format("MMM") :"") +", Day: "+ (data.day||"")}',
      childrenPropsByKey: {
        year: {
          label: "Year",
          key: "year",
          data_type: "number",
          componentName: "Number"
        },
        month: {
          label: "Month",
          key: "month",
          data_type: "number",
          componentName: "Number"
        },
        day: {
          label: "Day",
          key: "day",
          data_type: "number",
          componentName: "Number"
        },
        date: {
          label: "Date",
          key: "date",
          data_type: "String",
          componentName: "Text",
          mode: "info" //# expect this to be  generated by the server
          //# we rely on this property so that the json-diff doesn't check this
          //# since the browser state excludes this field but pristine form data has this field
        }
      }
    };
  }
};