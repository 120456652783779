"use strict";

var _reduxBatchedActions = require("redux-batched-actions");
var _lodash = _interopRequireDefault(require("lodash"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const Promise = require('bluebird');
module.exports = {
  //rubyApp.findDescendentBy(node => node.props.key == 'arrayMap_object_multiple').testFormValue({"arrayMap_object_multiple":[{"myText":"test","myText2":"asd"}]})
  /* 
  testHelperReduxStore: function(formValue) {
      const collectedActions = [];
      const collectAction = (value) => {collectedActions.push(value)}
       const helperReduxStore = this.helperReduxStore();
       const promisedFormValueToLocalState = this.formValueToLocalState(formValue, collectAction);
       promisedFormValueToLocalState.then((res) => {
          helperReduxStore;
          collectedActions;
           helperReduxStore.dispatch(batchActions(collectedActions));
          console.log(JSON.stringify(helperReduxStore.getLocalState(), null, '  '));
          console.log(JSON.stringify(helperReduxStore.getState(), null, '  '));
          debugger;
      })
  }
  */

  _displayValueFromLocalState: function (selfState, entireState) {
    let options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    //const children = this.getChildren();
    const helperSelf = this.cachedHelperSelf();
    const helperChildren = helperSelf.getChildren();
    //const helperChildren = this.cachedHelperChildren();
    const helperReduxStore = helperSelf.getStore(); //this.cachedHelperReduxStore();

    //# get fieldValue from selfState
    const fieldValueArr = this.getFieldValueFromLocalState(selfState) || [];

    //# seed self's formValue into children
    const promiseArrArr = fieldValueArr.map(fieldValue => {
      const collectorSpec = this.newActionCollectorSpec();

      //# NOTE: cannot call on this on the regular children, we need 
      //# to use helperChildren instances that has been patched. This is because
      //# we have children who might internally call on getStore().dispatch, which we don't want
      return Promise.all(helperSelf.formValueToChildrenLocalState(fieldValue, collectorSpec.collectAction)).then(() => {
        //# dispatch actions in helperReduxStore
        helperReduxStore.dispatch((0, _reduxBatchedActions.batchActions)(collectorSpec.collectedActions));

        //console.log(JSON.stringify(helperReduxStore.getState(), null, '  '));

        //# then create a new selfState for children
        const newSelfState = helperReduxStore.getLocalState()[this.getID()];
        const newEntireState = entireState;
        _lodash.default.set(entireState, this.getKeypathArr(), newSelfState);
        const arrOfReducedChildrenPromises = _lodash.default.reduce(helperChildren, (collector, child, index) => {
          const childID = child.getID();
          const childDisplayValue = child._displayValueFromLocalState ? child._displayValueFromLocalState(newSelfState[childID], newEntireState, options) : undefined;
          if (childDisplayValue != undefined) {
            collector.push(childDisplayValue);
          }
          return collector;
        }, []);
        const reducedChildrenPromise = Promise.all(arrOfReducedChildrenPromises).then(arrOfReducedChildrenState => {
          const mergedDisplayValue = _extends({}, ...arrOfReducedChildrenState);
          return Object.keys(mergedDisplayValue).length == 1 && mergedDisplayValue.hasOwnProperty(undefined) ? mergedDisplayValue[undefined] : mergedDisplayValue;
        });
        return reducedChildrenPromise;
      });
    });
    const promiseArr = [].concat.apply([], promiseArrArr);
    return Promise.all(promiseArr);
  }
};