"use strict";

const ERROR_LEVELS = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info'
};
const ERROR_TYPES = {
  SPELLING: 'spelling',
  LINK: 'link',
  IMAGE: 'image',
  IMAGE_ALT_TEXT: 'image_alt_text',
  FORBIDDEN_TERMS: 'forbidden_terms'
};
module.exports = {
  COMPONENT_NAME: 'rubyComponentPluginSweeper',
  LABEL: 'Integrity',
  ROUTE: 'integrity',
  ERROR_STATUSES: {
    OPEN: 'open',
    FIXED: 'fixed',
    ADDED_TO_DICTIONARY: 'added_to_dictionary',
    IGNORED: 'ignored'
  },
  SCAN_PRIORITIES: {
    SINGLE_PAGE_SCANS: 0,
    COMPLETE_SUBSEQUENT_SCANS: 1,
    COMPLETE_INITIAL_SCANS: 2
  },
  SCAN_STATUSES: {
    PENDING: 'pending',
    QUEUED: 'queued',
    CANCELLED: 'cancelled',
    COMPLETED: 'completed',
    FAILED: 'failed'
  },
  SCAN_TYPES: {
    INITIAL: 'initial',
    RESCAN: 'rescan'
  },
  SWEEPER_FREQUENCIES: {
    MONTHLY: 'monthly',
    WEEKLY: 'weekly',
    TTS: 'tts'
  },
  ERROR_LEVELS,
  ERROR_TYPES,
  ERROR_HIERARCHY: [ERROR_LEVELS.ERROR, ERROR_LEVELS.WARNING, ERROR_LEVELS.INFO],
  DEFAULT_ERROR_LEVELS_BY_ERROR_TYPE: {
    [ERROR_TYPES.SPELLING]: ERROR_LEVELS.WARNING,
    [ERROR_TYPES.LINK]: ERROR_LEVELS.WARNING,
    [ERROR_TYPES.IMAGE]: ERROR_LEVELS.WARNING,
    [ERROR_TYPES.IMAGE_ALT_TEXT]: ERROR_LEVELS.WARNING,
    [ERROR_TYPES.FORBIDDEN_TERMS]: ERROR_LEVELS.WARNING
  },
  DEFAULT_SCAN_FREQUENCY: 'weekly',
  SWEEPER_EXPORT_SYS_EVENT_TYPE: 'sweeper_report_export',
  SWEEPER_RESCAN_SYS_EVENT_TYPE: 'sweeper_report_rescan',
  ERROR_CODES: {
    REPORT_NOT_EXISTS: 'ReportNotExists'
  },
  INTEGRITY_ERRORS_FIELD: '__integrityErrors'
};