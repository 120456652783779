"use strict";

const common = require('../common');
const typesFromID = common.actionTypesFromID;
const generators = {
  replaceLocalState: function (localState) {
    const TYPES = this.getAction().TYPES;
    return {
      type: TYPES.REPLACE_LOCAL_STATE,
      payload: {
        localState
      }
    };
  },
  setProps: function (props) {
    const TYPES = this.getAction().TYPES;
    return {
      type: TYPES.SET_PROPS,
      payload: {
        props
      }
    };
  },
  setComponentIsMounted: function () {
    const TYPES = this.getAction().TYPES;
    return {
      type: TYPES.SET_COMPONENT_IS_MOUNTED
    };
  },
  updateDisplayValueSpecs: function (displayValueSpecs) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.UPDATE_DISPLAY_VALUE_SPECS,
      payload: {
        displayValueSpecs
      }
    };
  },
  requestRerender: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.UPDATE_RERENDER_TIMESTAMP,
      payload: {
        timestamp: new Date()
      }
    };
  }
};
module.exports = function () {
  const TYPES = typesFromID(this.getID());
  return {
    TYPES,
    generators
  };
};