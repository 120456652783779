"use strict";

var _react = _interopRequireDefault(require("react"));
var _lodash = _interopRequireDefault(require("lodash"));
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _Login = _interopRequireDefault(require("./Login"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const ReactDOM = require('react-dom');
const routeParser = require('route-parser');
const PropTypes = _react.default.PropTypes;
module.exports = function editGenerator() {
  const selfModule = this;
  const selfProps = this.props;

  //# Dependencies
  const dependencies = selfModule.getDependencies();
  const {
    selfSelector,
    selfActions,
    feSettingsSelector
  } = dependencies;

  //# Constants

  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const feSettings = feSettingsSelector(state);
    const location__query__next_page = _lodash.default.get(ownProps, 'location.query.next_page') || '/';
    const location__hash__next_page = _lodash.default.get(ownProps, 'location.hash');
    const nextPageArg = location__query__next_page ? decodeURIComponent(location__query__next_page) : location__query__next_page;
    // if next page is index, fall back to default
    const nextPage = nextPageArg && nextPageArg != '/index.html' ? _lodash.default.compact([nextPageArg, location__hash__next_page]).join('') : undefined;
    const location__query__error = _lodash.default.get(ownProps, 'location.query.error');
    let error;
    if (_lodash.default.get(selfState, 'errorPayload.message')) {
      error = selfState.errorPayload.message;
    } else if (location__query__error) {
      error = decodeURIComponent(location__query__error);
    }
    const mappedProps = _extends({}, selfProps, selfState, {
      clientName: feSettings.CLIENT_NAME,
      rubyBranding: feSettings.RUBY_BRANDING,
      rubyContributors: feSettings.RUBY_CONTRIBUTORS,
      error: {
        message: error
      },
      nextPage,
      feSettings
    });
    return mappedProps;
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfActions, dispatch)
    };
  }
  function mergeProps(stateProps, dispatchProps, ownProps) {
    return _extends({}, ownProps, stateProps, dispatchProps, {
      //# setup the delegate callbacks here whcih calls on dispatchProps.actions...
      delegateSubmit: function (options) {
        dispatchProps.actions.validateBeforeSubmitting(stateProps.nextPage, options);
        return;
      },
      delegateForgotPasswordSubmit: function (options) {
        //dispatchProps.actions.submitForgotPassword();
        dispatchProps.actions.validateBeforeSubmittingForgotPassword(options);
        return;
      },
      delegateForgotPasswordClick: function (evt) {
        dispatchProps.actions.reset();
        dispatchProps.actions.switchToForgotPassword();
        return;
      },
      delegateBack: function (evt) {
        dispatchProps.actions.reset();
        return;
      }
    });
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps, mergeProps)(_Login.default);
};