"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _Module = _interopRequireDefault(require("./Module"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function generator() {
  const selfModule = this;
  const {
    selfSelector
  } = selfModule.getDependencies();
  const moduleProps = _extends({}, selfModule.props);
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const formattedError = selfModule.formattedErrorFromState(state);
    const mappedProps = _extends({}, selfState, ownProps, {
      id: selfModule.getID()
    }, formattedError);

    //# hydrate actionButtons
    if (mappedProps.actionButtons) {
      mappedProps.actionButtons = selfModule.hydratedActionButtons(mappedProps.actionButtons);
    }
    return mappedProps;
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfModule.getAction().generators, dispatch)
    };
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_Module.default);
}