"use strict";

function typesWithID(id) {
  return {
    OPEN: `@@ruby-app/${id}/OPEN_POPOVER`,
    CLOSE: `@@ruby-app/${id}/CLOSE_POPOVER`,
    UPDATE_DISPLAY_VALUE_SPECS: `@ruby-app/${id}/UPDATE_DISPLAY_VALUE_SPECS`
  };
}
const generators = {
  open: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.OPEN
    };
  },
  close: function () {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.CLOSE
    };
  },
  updateDisplayValueSpecs: function (displayValueSpecs) {
    const {
      TYPES
    } = this.getAction();
    return {
      type: TYPES.UPDATE_DISPLAY_VALUE_SPECS,
      payload: {
        displayValueSpecs
      }
    };
  }
};
module.exports = function () {
  return {
    TYPES: typesWithID(this.getID()),
    generators
  };
};