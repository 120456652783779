"use strict";

const React = require('react');
const ReactRouter = require('react-router');
const Router = ReactRouter.Router;
const Route = ReactRouter.Route;
const rubyComponent = require('@rubyapps/ruby-component');
const action = require('./action');
const reducer = require('./reducer');
const CONSTANTS = require('../common/constants');
const componentName = CONSTANTS.COMPONENT_NAME;
const routePath = CONSTANTS.ROUTE_PATH;
const ListPageGenerator = require('./reactComponents/ListPage');
const rubyDateManager = require('@rubyapps/ruby-date');
const RCListPage = rubyComponent.createClass({
  componentName: componentName,
  CONSTANTS: CONSTANTS,
  getDefaultProps: () => ({
    path: routePath
  }),
  action: action,
  reducer: reducer,
  getReactClass: function (props, griddleProps) {
    const ListPage = ListPageGenerator.apply(this, arguments);
    return ListPage;
  },
  utils: {
    constructChangelogMessage: function (lastModifiedData) {
      console.warn('[DEPRECATED 20180831] use this method in `ruby-component-mixin-list-page` instead');
      const {
        last_modified_by_user_name: lastModifiedUser,
        last_modified_timestamp: lastModifiedDate
      } = lastModifiedData;
      let message = [];

      // If there is last modified data, start building our string
      if (lastModifiedDate || lastModifiedUser) {
        message.push('Last modified');
        if (lastModifiedDate) {
          message.push(rubyDateManager.formatWithShortenedAdjacentDays(lastModifiedDate));
        }
        if (lastModifiedUser) {
          message.push('by ' + lastModifiedUser);
        }
      }
      return message.join(' ');
    }
  }
});
module.exports = RCListPage;