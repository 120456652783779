"use strict";

var _lodash = _interopRequireDefault(require("lodash"));
var _reduxBatchedActions = require("redux-batched-actions");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
const Promise = require('bluebird');
const request = require('@rubyapps/ruby-superagent');
const urljoin = require('url-join');
const {
  REQUEST_TYPE_OPTIONS_RETRIEVAL
} = require('../common/constants');
function typesWithID(id) {
  return {
    RETRIEVE_OPTIONS: `@@ruby-app/${id}/RETRIEVE_OPTIONS`,
    CLEAR_CACHED_OPTIONS: `@@ruby-app/${id}/CLEAR_CACHED_OPTIONS`,
    CUSTOM_RESET_STATE: `@@ruby-app/${id}/CUSTOM_RESET_STATE`,
    SET_OPTIONS: `@@ruby-app/${id}/SET_OPTIONS`,
    SET_FILTERED_OPTIONS: `@@ruby-app/${id}/SET_FILTERED_OPTIONS`,
    SET_REQUESTED_OPTIONS_URL: `@@ruby-app/${id}/SET_REQUESTED_OPTIONS_URL`,
    SET_REFRESH_REQUEST_TIMESTAMP: `@@ruby-app/${id}/SET_REFRESH_REQUEST_TIMESTAMP`,
    SET_REQUESTED_TIMESTAMP: `@@ruby-app/${id}/SET_REQUESTED_TIMESTAMP`,
    SET_REQUESTED_QUERY: `@@ruby-app/${id}/SET_REQUESTED_QUERY`,
    SET_SEARCH_VALUE: `@@ruby-app/${id}/SET_SEARCH_VALUE`,
    DEFAULT_TO_FIRST_SUCCESSFUL: `@@ruby-app/${id}/DEFAULT_TO_FIRST_SUCCESSFUL`
  };
}
const generators = {
  preloadOptions: function () {
    const {
      TYPES,
      generators
    } = this.getAction();
    const {
      count
    } = this.props;
    return (dispatch, getState) => {
      dispatch(generators.retrieveOptionsWithQuery(this.props.preloadOptionsQuery));
    };
  },
  setSearchValue: function (value, callback) {
    const {
      TYPES,
      generators
    } = this.getAction();
    return (dispatch, getState) => {
      //# get filtered options
      dispatch(generators.setSearchValue_pure(value));
      dispatch(generators.retrieveOptionsWithQuery({
        search_input: value || '',
        count: this.props.count
      }, _objectSpread({
        allowAbort: true
      }, callback ? {
        thenableArguments: [res => {
          callback();
        }, err => {
          callback(err);
        }]
      } : {}) //allowAbort when hitting URLs
      ));
    };
  },
  setSearchValue_pure: function (value) {
    const {
      TYPES,
      generators
    } = this.getAction();
    return {
      type: TYPES.SET_SEARCH_VALUE,
      payload: {
        value
      }
    };
  }
  /*
   * @param {Object} query - query object for filtering
   * @param {Object} options
   * @param {String} options.url - url override
   * @param {Object[]} options.options - hardcoded options override
   * @param {Boolean} options.allowAbort - If we're making an asyn call (like a request), we want to allow abortions so that we honor the most recent request
   * @param {Boolean} options.clearCache - if true, we clear out the options cache
   */,
  retrieveOptionsWithQuery: function (query) {
    let {
      url,
      options,
      allowAbort = false,
      clearCache = false,
      thenableArguments = undefined
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const {
      TYPES,
      generators
    } = this.getAction();
    url = url || this.url();
    const hardcodedOptions = options || this.props.options;
    return (dispatch, getState) => {
      Promise.props({
        hardcodedData: Promise.fromCallback(cb => {
          hardcodedOptions ? dispatch(generators.retrieveOptionsFromHardcodedOptions_withQuery(hardcodedOptions, query, [data => cb(null, data), err => cb(err)])) : cb(null, []);
        }),
        remoteData: Promise.fromCallback(cb => {
          //# call retrieveOptionsFromUrl_withQuery even if !url to handle aborting
          //# requests and clearing state associated with the previous url
          url ? dispatch(generators.retrieveOptionsFromUrl_withQuery(url, query, [data => cb(null, data), err => cb(err)], allowAbort, clearCache)) : cb(null, []);
        })
      }).then(_ref => {
        let {
          hardcodedData,
          remoteData
        } = _ref;
        const normalizedRemoteData = remoteData;
        const dataUnion = hardcodedData.concat(normalizedRemoteData);
        const dataUnion_withoutHiddens = dataUnion.filter(option => !option.isHidden);
        dispatch(generators.setFilteredOptions(dataUnion_withoutHiddens));
        dispatch(generators.setRequestedQuery(query));
        dispatch(generators.updateCachedOptions_withAdditional(normalizedRemoteData));
        //# do not need to add hardcoded options to cache

        if (thenableArguments) {
          return Promise.resolve(dataUnion).then(...thenableArguments);
        }
      });
    };
  },
  retrieveOptionsFromHardcodedOptions_withQuery: function (options) {
    let query = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let thenableArguments = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;
    if (!query) {
      query = {};
    }
    const {
      TYPES,
      generators
    } = this.getAction();
    const completeOptions = this.getDisambiguatedOptionsFromHardcodedOptions(options);
    const data = query.search_input ? _lodash.default.filter(completeOptions, option => {
      const optionText = this.getDisplayTextFromOption(option).toLowerCase();
      const searchText = query.search_input.toLowerCase();
      return optionText.indexOf(searchText) !== -1;
    }) : completeOptions;
    const dataPromise = Promise.resolve(data);
    return (dispatch, getState) => {
      if (thenableArguments) {
        return dataPromise.then.apply(dataPromise, thenableArguments);
      }
      return dataPromise;
    };
  },
  retrieveOptionsFromUrl_withQuery: function (url, query, thenableArguments) {
    let allowAbort = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
    let clearCache = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : false;
    let requestType = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : REQUEST_TYPE_OPTIONS_RETRIEVAL;
    const selfModule = this;
    const selfState = selfModule.getState();
    const {
      TYPES,
      generators
    } = this.getAction();
    const {
      RubyClientFKSelector
    } = this.getDependencies();

    //# kill previous request
    if (allowAbort) {
      _lodash.default.get(selfModule, ['_requestObjectByType', requestType]) && selfModule._requestObjectByType[requestType].abort();
      selfModule.cacheRequestObject(null, requestType);
    }
    return function (dispatch, getState) {
      const applicationState = getState();
      const ruby_client_fk = RubyClientFKSelector(applicationState);
      const additionalQuery = /ruby_client_fk/.test(url) ? {} : {
        ruby_client_fk
      };
      const finalQuery = query ? _extends({}, additionalQuery, query) : additionalQuery;
      dispatch((0, _reduxBatchedActions.batchActions)([generators.setRequestedOptionsUrl(url, requestType), generators.setRequestedTimestamp(new Date())]));
      if (!url) {
        //# clear cached options bc previous url is no longer valid
        clearCache && _lodash.default.get(selfState, 'props.options') && dispatch(generators.clearCachedOptions());
        return Promise.resolve([]);
      }
      const requestObject = request.get(url).query(finalQuery);
      selfModule.cacheRequestObject(requestObject, requestType);
      const requestPromise = requestObject.then(function success(response) {
        selfModule.cacheRequestObject(null, requestType);
        const selfState = selfModule.getState();
        const data = selfModule.normalizedOptions(response.body.data || []);
        if (_lodash.default.result(selfModule, ['getState', 'requestedOptionsUrlByType', requestType]) !== url) {
          // if the options url has changed, don't set this value
          // the response for this request might come after the
          // response for a more recent request and overwrite the data

          console.warn(`Url option has changed from [${url}] to [${_lodash.default.result(selfModule, ['getState', 'requestedOptionsUrlByType', requestType])}]. This should be ok and we should allow the data through since if we didn't want old data, the caller should have passed the allowAbort flag`);
          return data;
        }
        clearCache && _lodash.default.get(selfState, 'props.options') && dispatch(generators.clearCachedOptions());
        return data;
      }, error => {
        selfModule.cacheRequestObject(null, requestType);
        throw error;
      });
      if (thenableArguments) {
        return requestPromise.then.apply(requestPromise, thenableArguments);
      }
      return requestPromise;
    };
  }

  //# DEPRECATED 20180823
  //# should only be called when caching options
  /*
  , cacheOptionsFromUrl_withQuery: function(url, query, shouldClear=false) {
      const selfModule = this;
      const selfState = selfModule.getState();
       const { TYPES, generators } = this.getAction();
      const {
          RubyClientFKSelector
      } = this.getDependencies();
       return generators.retrieveOptionsFromUrl_withQuery(url, query, [(data) => {
          const {dispatch} = this.getStore();
          const selfState = this.getState();
           shouldClear && _.get(selfState, 'props.options') && dispatch(generators.clearCachedOptions());
          dispatch(generators.updateCachedOptions_withAdditional(data));
      }])
  }
  */,

  updateCachedOptions_withAdditional: function (options) {
    const {
      TYPES,
      generators
    } = this.getAction();
    const selfState = this.getState();
    const propOptions = this.props.options ? this.props.options : [];
    const cachedOptions = selfState.props.options || [];
    const remoteOptions = this.normalizedOptions(options ? options : []);
    const mergedOptions = _lodash.default.uniqBy(propOptions.concat(cachedOptions, remoteOptions), 'value');
    const isRequired = _lodash.default.get(this.props, ['verify', 'required']);
    const expandedOptions = isRequired ? mergedOptions : [].concat(mergedOptions);
    const selfSelector = this.getDefaultSelector();
    return (dispatch, getState) => {
      //# merge with prop options
      const applicationState = getState();
      const selfState = selfSelector(applicationState); //# TODO: BUG: this is undefined if you have a DynamicForm + TokenTagger nested under Popover

      const currentPropOptions = this.props.options ? this.props.options : [];
      const cachedOptions = selfState.props.options || [];
      const cachedPropOptions = selfState.props.propOptions || [];
      const remoteOptions = (options ? options : []).map(option => {
        return _extends({}, option, {
          value: option.value || option.id,
          text: option.text || option.name
        });
      });
      const propOptionsToRemoveFromCache = _lodash.default.differenceBy(cachedPropOptions, currentPropOptions);
      const mergedOptions = _lodash.default.differenceBy(_lodash.default.uniqBy(currentPropOptions.concat(cachedOptions, remoteOptions), 'value'), propOptionsToRemoveFromCache);
      const isRequired = _lodash.default.get(this.props, ['verify', 'required']);
      const expandedOptions = isRequired ? mergedOptions : [].concat(mergedOptions);
      const fieldValue = _lodash.default.get(selfState, ['fields', this.props.key, 'value']);
      if (!fieldValue && this.props.defaultToFirstOption && expandedOptions.length) {
        const firstOptionValue = expandedOptions[0].value;
        dispatch(generators.setFieldValueByKey(firstOptionValue, this.props.key));
        dispatch({
          type: TYPES.DEFAULT_TO_FIRST_SUCCESSFUL
        });
      }
      dispatch({
        type: TYPES.SET_OPTIONS,
        payload: {
          options: expandedOptions
          // we need to track prop options in redux state because we cache the
          // full set of options (prop + remote), and the previous cached full
          // options set is used when constructing the merged next set. without
          // keeping track of the old prop options, we'd have no way to diff
          // against current prop options in case some prop options need to be removed
          ,
          propOptions: currentPropOptions
        }
      });
    };
  },
  clearCachedOptions: function (filteredOptions) {
    //# merge with prop options
    const {
      TYPES,
      generators
    } = this.getAction();
    return {
      type: TYPES.CLEAR_CACHED_OPTIONS
    };
  },
  setFilteredOptions: function (filteredOptions) {
    //# merge with prop options
    const {
      TYPES,
      generators
    } = this.getAction();
    return {
      type: TYPES.SET_FILTERED_OPTIONS,
      payload: {
        filteredOptions
      }
    };
  },
  setRequestedOptionsUrl: function (url, type) {
    //# merge with prop options
    const {
      TYPES,
      generators
    } = this.getAction();
    return {
      type: TYPES.SET_REQUESTED_OPTIONS_URL,
      payload: {
        url,
        type
      }
    };
  },
  setRequestedTimestamp: function (timestamp) {
    //# merge with prop options
    const {
      TYPES,
      generators
    } = this.getAction();
    return {
      type: TYPES.SET_REQUESTED_TIMESTAMP,
      payload: {
        timestamp
      }
    };
  },
  setRefreshRequestTimestamp: function (timestamp) {
    //# merge with prop options
    const {
      TYPES,
      generators
    } = this.getAction();
    return {
      type: TYPES.SET_REFRESH_REQUEST_TIMESTAMP,
      payload: {
        timestamp
      }
    };
  },
  setRequestedQuery: function (query) {
    const {
      TYPES,
      generators
    } = this.getAction();
    return {
      type: TYPES.SET_REQUESTED_QUERY,
      payload: {
        query
      }
    };
  }

  //# NOTE: we're batching resetStore, and THUNKS don't work
  //# so you MUST override the rubyComponent.resetStore method and call on store.dispatch immediately 
  //# Hopefully, in the future, we can update how resetStore works and/or the batch plugin to 
  //# allow for batching thunks
  ,
  resetStore: function () {
    const {
      TYPES,
      generators
    } = this.getAction();
    const selfSelector = this.getDefaultSelector();
    return (dispatch, getState) => {
      const applicationState = getState();
      const selfState = selfSelector(applicationState);
      const options = _lodash.default.get(selfState, 'props.options', []) || [];
      this._cachedUrlDependentFieldValues = null; //# NOTE: probably not needed, but we should clear it

      dispatch({
        type: TYPES.CUSTOM_RESET_STATE
      });
      if (!this.props.hasOwnProperty('default') && this.props.defaultToFirstOption && options.length) {
        const firstOptionValue = this.coercedValue(options[0].value);
        dispatch(generators.setFieldValueByKey(firstOptionValue, this.props.key));
      }
    };
  }
};
module.exports = function () {
  return {
    TYPES: typesWithID(this.getID()),
    generators
  };
};