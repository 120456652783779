// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!../../../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[4].use[2]!../../../../ruby-styles/src/client/cssModules/colors.cssModule";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Header__header___n6x1Y {\n    background-color: " + ___CSS_LOADER_ICSS_IMPORT_0___.locals["headerGrey"] + ";\n    position: relative;\n    z-index: 16;\n}\n.Header__Paper___QZqsS {\n    border-bottom: 1px solid #cecece;\n}\n\n.Header__HamburgerIcon___O2plJ {\n    margin-top: 5px;\n}\n", "",{"version":3,"sources":["webpack://./src/local_modules/ruby-component-header/src/client/reactComponents/Header.cssModule"],"names":[],"mappings":"AAEA;IACI,8DAA4B;IAC5B,kBAAkB;IAClB,WAAW;AACf;AACA;IACI,gCAAgC;AACpC;;AAEA;IACI,eAAe;AACnB","sourcesContent":["@value headerGrey from '@rubyapps/ruby-styles/src/client/cssModules/colors.cssModule';\n\n.header {\n    background-color: headerGrey;\n    position: relative;\n    z-index: 16;\n}\n.Paper {\n    border-bottom: 1px solid #cecece;\n}\n\n.HamburgerIcon {\n    margin-top: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerGrey": "" + ___CSS_LOADER_ICSS_IMPORT_0___.locals["headerGrey"] + "",
	"header": "Header__header___n6x1Y",
	"Paper": "Header__Paper___QZqsS",
	"HamburgerIcon": "Header__HamburgerIcon___O2plJ"
};
export default ___CSS_LOADER_EXPORT___;
