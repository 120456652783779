"use strict";

const request = require('@rubyapps/ruby-superagent');
const TYPES = {
  RETRIEVE_FRONTEND_SETTINGS: '@@ruby-app/frontendSettings/RETRIEVE_FRONTEND_SETTINGS',
  SET_FRONTEND_SETTINGS: '@@ruby-app/frontendSettings/SET_FRONTEND_SETTINGS',
  SET_FRONTEND_SETTING: '@@ruby-app/frontendSettings/SET_FRONTEND_SETTING'
};
const FRONTEND_SETTINGS_URL = '/ruby/api/v2/settings/frontend';
const LOGIN_FRONTEND_SETTINGS_URL = '/ruby/api/v2/settings/login-frontend';
const generators = {
  retrievedFrontendSettings: function (settings) {
    const self = this;
    this._retrievedFrontendSettings = true;
    return function (dispatch) {
      dispatch(generators.setFrontendSettings(settings));
    };
  },
  setFrontendSetting_forKey_toValue: function (key, value) {
    return {
      type: TYPES.SET_FRONTEND_SETTING,
      payload: {
        key,
        value
      }
    };
  },
  setFrontendSettings: function (settings) {
    return {
      type: TYPES.SET_FRONTEND_SETTINGS,
      payload: settings
    };
  }
};
module.exports = {
  TYPES: TYPES,
  generators: generators
};