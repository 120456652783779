// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".defaultStyles__ArrowDrop___ZoQ55 {\n    fill: rgba(0,0,0,0.25)!important;\n    position: absolute;\n    top: 9px;\n}\n\n.defaultStyles__ArrowDrop_forTree____L63I {\n    fill: rgba(0,0,0,0.25)!important;\n    vertical-align: middle;\n    cursor: pointer;\n    position: relative;\n    z-index: 1;\n}\n\n.defaultStyles__ArrowDrop_collapsed___MFUW_ {\n    transform: rotate(-90deg);\n}\n.defaultStyles__ArrowDrop_expanded___h3RX0 {\n}\n", "",{"version":3,"sources":["webpack://./src/local_modules/griddle/local_modules/griddle-render/src/defaultStyles.cssModule"],"names":[],"mappings":"AAEA;IACI,gCAAyB;IACzB,kBAAkB;IAClB,QAAQ;AACZ;;AAEA;IACI,gCAAyB;IACzB,sBAAsB;IACtB,eAAe;IACf,kBAAkB;IAClB,UAAU;AACd;;AAEA;IAEI,yBAAyB;AAC7B;AACA;AAEA","sourcesContent":["@value arrowGrey: rgba(0,0,0,0.25);\n\n.ArrowDrop {\n    fill: arrowGrey!important;\n    position: absolute;\n    top: 9px;\n}\n\n.ArrowDrop_forTree {\n    fill: arrowGrey!important;\n    vertical-align: middle;\n    cursor: pointer;\n    position: relative;\n    z-index: 1;\n}\n\n.ArrowDrop_collapsed {\n    composes: ArrowDrop_forTree;\n    transform: rotate(-90deg);\n}\n.ArrowDrop_expanded {\n    composes: ArrowDrop_forTree;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"arrowGrey": "rgba(0,0,0,0.25)",
	"ArrowDrop": "defaultStyles__ArrowDrop___ZoQ55",
	"ArrowDrop_forTree": "defaultStyles__ArrowDrop_forTree____L63I",
	"ArrowDrop_collapsed": "defaultStyles__ArrowDrop_collapsed___MFUW_ defaultStyles__ArrowDrop_forTree____L63I",
	"ArrowDrop_expanded": "defaultStyles__ArrowDrop_expanded___h3RX0 defaultStyles__ArrowDrop_forTree____L63I"
};
export default ___CSS_LOADER_EXPORT___;
