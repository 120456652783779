"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = generator;
var _lodash = _interopRequireDefault(require("lodash"));
var _reactRedux = require("react-redux");
var _GooglePreview = _interopRequireDefault(require("./GooglePreview"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function generator() {
  const selfModule = this;
  const {
    selfSelector,
    nameSelector,
    languageNamespaceSelectorComponent,
    nameRelativeToLanguageSelector,
    browserPageTitleRelativeToLanguageSelector
  } = selfModule.getDependencies();
  const languageNamespaceSelectorSelector = languageNamespaceSelectorComponent ? languageNamespaceSelectorComponent.getDefaultSelector() : state => state;
  const languageNamespaceKey = languageNamespaceSelectorComponent ? languageNamespaceSelectorComponent.key() : undefined;
  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const languageNamespaceState = languageNamespaceSelectorSelector(state);
    const nameState = nameSelector(state);
    const previewUrl = selfModule.getPreviewUrl();
    const formValue = selfModule.formValueFromLocalState(selfState);
    const pageName = _lodash.default.get(nameState, 'fields.name.value', null);
    const browserPageTitle = formValue.browser_page_title;
    let pageName_forDefaultLang;
    let browserPageTitle_forDefaultLang;
    if (languageNamespaceSelectorComponent) {
      const languageNamespaceState = languageNamespaceSelectorComponent.getStateForNamespace('en');
      const nameState_forDefaultLang = nameRelativeToLanguageSelector(languageNamespaceState);
      const browserPageTitleState_forDefaultLang = browserPageTitleRelativeToLanguageSelector(languageNamespaceState);
      pageName_forDefaultLang = _lodash.default.get(nameState_forDefaultLang, 'fields.name.value', null);
      browserPageTitle_forDefaultLang = _lodash.default.get(browserPageTitleState_forDefaultLang, 'fields.browser_page_title.value');
    }
    const mappedProps = _extends({}, ownProps, {
      id: selfModule.getID(),
      value: formValue,
      pageName: pageName || pageName_forDefaultLang,
      browserPageTitle: browserPageTitle || browserPageTitle_forDefaultLang,
      previewUrl
    }, selfState.props, selfState.cachedValues);
    return mappedProps;
  }
  return (0, _reactRedux.connect)(mapStateToProps)(_GooglePreview.default);
}