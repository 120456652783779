// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "\n.styles__ImageFormatter___PCykZ {\n    width: 100%;\n    display: block;\n}\n", "",{"version":3,"sources":["webpack://./src/local_modules/griddle/customComponents/styles.cssModule"],"names":[],"mappings":";AACA;IACI,WAAW;IACX,cAAc;AAClB","sourcesContent":["\n.ImageFormatter {\n    width: 100%;\n    display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ImageFormatter": "styles__ImageFormatter___PCykZ"
};
export default ___CSS_LOADER_EXPORT___;
