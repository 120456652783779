"use strict";

const _ = require('lodash');
const RubyComponent = require('@rubyapps/ruby-component');
const PropTypes = RubyComponent.PropTypes;
const {
  FE_SETTINGS_KEY,
  STICKY_FE_SETTINGS_KEY
} = require('../common/constants');
const mixinName = 'rubyComponentMixinAutosaveSearch';
const autosaveSearchListerMixin = {
  mixinName,
  action: require('./action'),
  propTypes: {
    autosaveSearch_context: PropTypes.string,
    autosaveSearch_griddleStateKeys: PropTypes.arrayOf(PropTypes.string)
  },
  getDefaultProps: function () {
    return {
      autosaveSearch_griddleStateKeys: [
      //# currentPage, maxPage, pageSize, sortColumns, sortAscending 
      'pageProperties'

      //# text query
      , 'filter'

      //# column widths
      , 'resizeProperties', 'utilityBarProperties', 'expandedRows']
    };
  },
  getStickySearchKeys: function () {
    if (this.props.stickySearchKeys) {
      return this.props.stickySearchKeys;
    }
    const {
      feSettingsComponent
    } = this.getDependencies();
    const sticky_search_keys = _.get(feSettingsComponent.getState(), ['RUBYAPPS_SETTINGS.lister_settings', 'sticky_search_keys']) || [];
    return sticky_search_keys;
  },
  getAutosaveSearch_context: function () {
    return this.props.autosaveSearch_context || `${window.location.pathname}___${this.getID()}`;
  },
  getStickySearchState: function () {
    const selfModule = this;
    const {
      feSettingsComponent
    } = selfModule.getDependencies();
    return _.pick(_.get(feSettingsComponent.getState(), [STICKY_FE_SETTINGS_KEY], null), this.getStickySearchKeys());
  },
  getAutosavedSearchState: function () {
    const selfModule = this;
    const {
      feSettingsComponent
    } = selfModule.getDependencies();
    return _.get(feSettingsComponent.getState(), [FE_SETTINGS_KEY, this.getAutosaveSearch_context()], null);
  },
  getInitialGriddleState_withDefault: function (defaultState, options) {
    const autosavedSearchState = this.getAutosavedSearchState();
    const stickySearchState = this.getStickySearchState();
    const initialGriddleState = _.merge({}, autosavedSearchState || defaultState, stickySearchState);
    return initialGriddleState;
  },
  onReduxInit: function (dispatch) {
    const selfModule = this;
    const store = selfModule.getStore();
    const {
      feSettingsComponent
    } = selfModule.getDependencies();
    const prevRouteParams_key = `__${mixinName}_prevRouteParams`;
    return store.subscribe(() => {
      const routeParams = _.get(selfModule.getState(), 'routeParams', {});
      const {
        routeActive
      } = routeParams;
      const prevRouteParams = this.getStatefulCacheForKey(prevRouteParams_key);
      this.setStatefulCacheForKey(prevRouteParams_key, routeParams);
      if (!routeActive) {
        return;
      }
      const navigatedHere = routeActive && !_.isEqual(prevRouteParams, routeParams);
      const {
        reset_search_on_navigate
      } = _.get(feSettingsComponent.getState(), 'RUBYAPPS_SETTINGS.lister_settings', {});
      if (reset_search_on_navigate && navigatedHere) {
        dispatch(selfModule.getAction().generators.clearOtherAutosavedSearches());
      }
    });
  }
};
module.exports = autosaveSearchListerMixin;