"use strict";

var _react = _interopRequireDefault(require("react"));
var _redux = require("redux");
var _reactRedux = require("react-redux");
var _SecureLogin = _interopRequireDefault(require("./SecureLogin"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const ReactDOM = require('react-dom');
const routeParser = require('route-parser');
const PropTypes = _react.default.PropTypes;
module.exports = function editGenerator() {
  const selfModule = this;
  const selfProps = this.props;

  //# Dependencies
  const dependencies = selfModule.getDependencies();
  const {
    selfSelector,
    selfActions,
    feSettingsSelector
  } = dependencies;

  //# Constants

  function mapStateToProps(state, ownProps) {
    const selfState = selfSelector(state);
    const feSettings = feSettingsSelector(state);

    // if next page is index, fall back to default
    const nextPage = selfModule.props.nextPage;
    const mappedProps = _extends({}, selfProps, selfState, {
      clientName: feSettings.CLIENT_NAME,
      rubyBranding: feSettings.RUBY_BRANDING,
      rubyContributors: feSettings.RUBY_CONTRIBUTORS,
      nextPage,
      feSettings
    });
    return mappedProps;
  }
  function mapDispatchToProps(dispatch, ownProps) {
    return {
      actions: (0, _redux.bindActionCreators)(selfActions, dispatch)
    };
  }
  return (0, _reactRedux.connect)(mapStateToProps, mapDispatchToProps)(_SecureLogin.default);
};