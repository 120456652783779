"use strict";

const _ = require('lodash');
function typesFromID(id) {
  const TYPES = {};
  return TYPES;
}
var generators = {};
module.exports = function () {
  const TYPES = typesFromID(this.getID());
  return {
    TYPES: TYPES,
    generators: generators
  };
};