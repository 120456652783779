"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const path = require('path');
const CONSTANTS = require('../../common/constants');
const TYPES = CONSTANTS.ROUTE_ACTION_TYPES;
function routeParamsReducer(state, newRouteParams) {
  return _extends({}, state, {
    routeParams: _extends({}, state.routeParams, newRouteParams)
  });
}

//# TODO: need to update to nest all state mutations to be under 
//# 'routeParams' since we're no longer returning the fake
//# reducerByKey, which used to return this reduce at 'routeParams'
const defaultState = {
  routeActive: false
};
module.exports = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  const selfModule = this;
  const selfID = selfModule.getID();
  const TYPES = selfModule.getAction().TYPES;
  switch (action.type) {
    case TYPES.SET_ROUTE_PARAMS:
      return routeParamsReducer(state, _extends({}, action.payload, {
        routeActive: true
      }));
    case TYPES.SET_HASH:
      return routeParamsReducer(state, {
        hash: action.payload.hash
      });
    case TYPES.LEAVING_ROUTE:
      return routeParamsReducer(state, {
        routeActive: false,
        hash: null
      });
    default:
      return state;
  }
};