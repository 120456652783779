"use strict";

module.exports = function () {
  const selfModule = this;
  const id = this.getID();
  const TYPES = {
    CANCEL_EDITING: `@ruby-app/${id}/CANCEL_EDITING`,
    SET_LISTER_NAME: `@ruby-app/${id}/SET_LISTER_NAME`,
    SET_CHILDREN_PROPS_BY_CLASS: `@ruby-app/${id}/SET_CHILDREN_PROPS_BY_CLASS`,
    RESET_STORE: `@ruby-app/${id}/RESET_STORE`
  };
  return {
    TYPES,
    generators: {
      cancelEditing: function () {
        const actions = selfModule.getAction().generators;
        return (dispatch, getState) => {
          const listRoute = selfModule.getListRoute();
          dispatch(actions.navigateToPathWithOptions({
            path: listRoute
          }));
        };
      },
      setListerName: function (value) {
        const actions = selfModule.getAction().generators;
        return {
          type: TYPES.SET_LISTER_NAME,
          payload: {
            value
          }
        };
      },
      setChildrenPropsByClass: function (childrenPropsByClass) {
        return {
          type: TYPES.SET_CHILDREN_PROPS_BY_CLASS,
          payload: childrenPropsByClass
        };
      },
      resetStore: function () {
        return {
          type: TYPES.RESET_STORE
        };
      }
    }
  };
};