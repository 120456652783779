"use strict";

const DELETED_STATUS = 'deleted';
module.exports = {
  COMPONENT_NAME: 'rubyComponentFieldScheduledStatus',
  commonChildrenPropsByKey: {
    status: {
      default: "inactive"
    }
  },
  DELETED_STATUS
};