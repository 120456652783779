'use strict';

//clean these up
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AFTER_REDUCE = AFTER_REDUCE;
exports.BEFORE_REDUCE = BEFORE_REDUCE;
exports.GRIDDLE_INITIALIZED = GRIDDLE_INITIALIZED;
exports.GRIDDLE_LOADED_DATA = GRIDDLE_LOADED_DATA;
exports.GRIDDLE_PREPEND_STATIC_DATA = GRIDDLE_PREPEND_STATIC_DATA;
exports.GRIDDLE_SET_COLUMN_PROPERTIES = GRIDDLE_SET_COLUMN_PROPERTIES;
exports.GRIDDLE_SET_COLUMN_RENDERED_SIZE_PROPERTIES = GRIDDLE_SET_COLUMN_RENDERED_SIZE_PROPERTIES;
exports.GRIDDLE_SET_COLUMN_RESIZE_PROPERTIES = GRIDDLE_SET_COLUMN_RESIZE_PROPERTIES;
exports.GRIDDLE_SET_STATIC_DATA = GRIDDLE_SET_STATIC_DATA;
exports.GRIDDLE_TOGGLE_COLUMN = GRIDDLE_TOGGLE_COLUMN;
var _immutable = _interopRequireDefault(require("immutable"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
//TODO: Dumb bug requires this to be here for things to work
function BEFORE_REDUCE(state, action, helpers) {
  return state;
}
function AFTER_REDUCE(state, action, helpers) {
  return state;
}
function GRIDDLE_INITIALIZED(state, action, helpers) {
  return state.set('renderProperties', _immutable.default.fromJS(action.properties));
}
function GRIDDLE_LOADED_DATA(state, action, helpers) {
  return state.set('data', helpers.addKeyToRows(_immutable.default.fromJS(action.data))).set('loading', false);
}
function GRIDDLE_TOGGLE_COLUMN(state, action, helpers) {
  const toggleColumn = function (columnId, fromProperty, toProperty) {
    if (state.get('renderProperties').get(fromProperty) && state.get('renderProperties').get(fromProperty).has(columnId)) {
      const columnValue = state.getIn(['renderProperties', fromProperty, columnId]);
      return state.setIn(['renderProperties', toProperty, columnId], columnValue).removeIn(['renderProperties', fromProperty, columnId]);
    }
  };

  //check to see if the column is in hiddenColumnProperties
  //if it is move it to columnProperties
  const hidden = toggleColumn(action.columnId, 'hiddenColumnProperties', 'columnProperties');

  //if it is not check to make sure it's in columnProperties and move to hiddenColumnProperties
  const column = toggleColumn(action.columnId, 'columnProperties', 'hiddenColumnProperties');

  //if it's neither just return state for now
  return helpers.updateVisibleData(hidden || column || state);
}
function GRIDDLE_SET_COLUMN_PROPERTIES(state, action, helpers) {
  const {
    type,
    columnId,
    properties
  } = action;
  const mergeColumnProperties = function (columnId, columnPropertiesKey, properties) {
    if (state.getIn(['renderProperties', columnPropertiesKey]).has(columnId)) {
      const columnValue = state.getIn(['renderProperties', columnPropertiesKey, columnId]);
      const newColumnProperties = _immutable.default.fromJS(properties);
      const mergedColumnProperties = columnValue.merge(newColumnProperties);
      return state.setIn(['renderProperties', columnPropertiesKey, columnId], mergedColumnProperties);
    }
  };
  const column = mergeColumnProperties(columnId, 'columnProperties', properties);
  const hidden = mergeColumnProperties(columnId, 'hiddenColumnProperties', properties);
  return helpers.updateVisibleData(hidden || column || state);
}
function GRIDDLE_SET_COLUMN_RESIZE_PROPERTIES(state, action, helpers) {
  const {
    type,
    propertiesByID
  } = action;

  //# blanket replacement of the resize properties for now)

  const retval = state.set('resizeProperties', _immutable.default.fromJS(propertiesByID));
  return retval;
  //return helpers.updateVisibleData(hidden || column || state);
}

function GRIDDLE_SET_COLUMN_RENDERED_SIZE_PROPERTIES(state, action, helpers) {
  const {
    type,
    propertiesByID
  } = action;

  //# blanket replacement of the resize properties for now)

  const retval = state.set('renderedSizeProperties', _immutable.default.fromJS(propertiesByID));
  return retval;
  //return helpers.updateVisibleData(hidden || column || state);
}

function _setStaticDataInState_withData_andHelpers(state, staticData, helpers) {
  //# add isStatic:true to staticData
  staticData.forEach(dataItem => {
    dataItem.isStatic = true;
  });
  const staticDataAsImmutable = _immutable.default.fromJS(staticData);
  const staticDataWithGriddleKey = helpers.addKeyToRows(staticDataAsImmutable, 'static-');
  const columns = helpers.getDataColumns(state, staticDataWithGriddleKey);
  const visibleStaticData = helpers.getVisibleDataColumns(staticDataWithGriddleKey, columns);
  return state.set('visibleStaticData', visibleStaticData).set('staticData', staticDataWithGriddleKey);
}
function GRIDDLE_SET_STATIC_DATA(state, action, helpers) {
  const {
    staticData
  } = action;
  return _setStaticDataInState_withData_andHelpers(state, staticData, helpers);
}
function GRIDDLE_PREPEND_STATIC_DATA(state, action, helpers) {
  const {
    staticData: prependStaticData
  } = action;
  const existingStaticData = state.get('staticData');
  const staticData = prependStaticData.concat(existingStaticData.toJS());
  return _setStaticDataInState_withData_andHelpers(state, staticData, helpers);
}