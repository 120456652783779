"use strict";

function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
const {
  CONTENT_FORM_KEY
} = require('../common/constants');
const contentFormsMixin = {
  mixinName: 'rubyComponentMixinFormsContent',
  isContentTemplate: function (template) {
    return _.get(template, 'form.key') === CONTENT_FORM_KEY;
  },
  augmentedTemplate: function (template, callingModule) {
    const {
      key: templateKeyword
    } = template;
    if (this.isContentTemplate(template) && this.isValidTopLevelTemplate(template)) {
      const rubyClientId = callingModule.getActiveRubyClientId && callingModule.getActiveRubyClientId();
      const subsiteId = callingModule.getActiveSubsiteId && callingModule.getActiveSubsiteId();
      template.form.hiddenValues = _extends({}, template.form.hiddenValues || {}, {
        ruby_client_fk: Number(rubyClientId) // need to make sure we pass a number (3), not a string ("3")
        ,
        content_subsite_fk: Number(subsiteId) // need to make sure we pass a number (3), not a string ("3")
        ,
        template_keyword: templateKeyword
      });
    }
    return template;
  }
};
module.exports = contentFormsMixin;